<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
         <v-card>
             <v-card-title>
                 {{lang.ManageBimRenderings}}
             </v-card-title>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="lang.Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    >

      <template v-slot:item.default="{ item }">
      <v-chip
        color="light-green"
        dark
        v-if="item.renedering_status == 0"
      >
       {{lang.Default}}
      </v-chip>
    </template>

      <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="newbimboxopen(item)"
         v-if="userdata.user_code == 2 || userdata.user_code == 0"
      >
        mdi-pencil
      </v-icon>

       <v-icon
        small
        class="mr-2"
        @click="deleteitem(item)"
         v-if="userdata.user_code == 2 || userdata.user_code == 0"
      >
        mdi-trash-can-outline
      </v-icon>


      <v-icon
        small
        class="mr-2"
        @click="newbimboxopen(item)"
         v-if="userdata.user_code == 1"
      >
        mdi-eye
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar :activelink="activelink"/>
      </v-col>
    </v-row>
       <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        color="amber accent-3"
         v-model="newbimbox"
      >
        <template >
          <v-card>
            <v-toolbar
               color="amber accent-3"
              dark
            > {{lang.CreatenewBIMRenedering}} </v-toolbar>
            <v-form
    ref="formBIM"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitformBIM"
    >

         <v-container>
          <v-row> 
          <v-col
          cols="12"
         sm="12"
        >
        <v-text-field
          v-model="structure"
          :label="lang.Structure"
          required
        ></v-text-field>
       </v-col>
        <v-col
          cols="12"
          sm="12"
        >
        <v-text-field
          v-model="renedering_name"
          :label="lang.RenederingName"
          required
          
        ></v-text-field>
         </v-col>
        <v-col
          cols="12"
         sm="12"
        >
        <v-text-field
          v-model="rendering_info"
          :label="lang.RenderingInfo"
          required
        ></v-text-field>
       </v-col>
       <v-col
          cols="12"
         sm="12"
        >
        <v-checkbox
      v-model="checkbox"
      label="Make it Default"
    ></v-checkbox>
        <v-lable>{{lang.SelectImage }} </v-lable>
       <input type="file" ref="file"
        required
        accept="image/png, image/jpeg" 
        @change="selectFile" />
       </v-col>
       
      <v-col
          cols="12"
          
        >
        <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        {{lang.Send }}  <v-progress-circular
        v-if="send"
      indeterminate
      color="green"
    ></v-progress-circular>
      </v-btn>
      <v-btn @click="newbimbox = false"
       color="mydarkbutton"
      >
      {{lang.Cancel}}
      </v-btn>
       </v-col>
      </v-row>
    </v-container>
    </v-form> 
          </v-card>
        </template>
      </v-dialog> 
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar,

  },
  name: "Bim_rendering",
  data() {
    return { 
       items: {} ,
      preloader: false,
      projectid : 0,
      current_cam : '',
       activelink :'',
         valid : true,
        search: '',
        headers: [
          {
            text: 'Structure',
            align: 'start',
            value: 'structure',
          },
          { text: 'Rendering Name', value: 'renedering_name' },
          { text: 'Rendering Info', value: 'rendering_info' },
          { text: 'Created At', value: 'created_at' },
          { text: 'Default', value: 'default', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false }
         
],
        desserts: [],
         userdata :'',
         newbimbox :false,
         send: false,
         structure : '',
              renedering_name : '',
              rendering_info : '',
              bimfile : {},
              renedering_id : 0,
              checkbox: false,
              Local :locals,  
              lang : {},
      }
  },
  mounted() {
        var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];
       let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
       this.projectid = currentProject.id;
    
       this.activelink = this.$route.path;
       this.current_cam = this.$store.state.currentCamera;
        this.initViewer();
  },
  watch: {
  '$store.state.currentCamera': function() {

     let currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.projectid = currentProject.id;
     this.current_cam = this.$store.state.currentCamera;
    
  }
  },
    computed: {
    params() {
      return {
        oldpassword: this.oldpassword,
        newpassword: this.confpassword
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
       this.getBimLayouts();
    },
       getBimLayouts(){
         axios.get('/project/'+this.projectid+'/camera/'+this.current_cam.camera_id+'/bim_renedaring')
           .then(resp => {
                 console.log("BIM url resonse", resp);
                 this.desserts = resp.data.success.data;

               console.log("this.bim_renedaring_data",this.desserts);
           })
          .catch(err => {
            console.log("BIM images",err)
           }) 
    },
    newbimboxopen(data){
        this.newbimbox = true;
        console.log(data);

            this.structure = data.structure;
            this.renedering_name = data.structure;
            this.rendering_info = data.structure;
            this.renedering_id = data.renedering_id;

            if(data.renedering_status == 0){
                this.checkbox= true;
            }else{
                this.checkbox = false;
            }


    },
    selectFile(){
     
     this.bimfile = this.$refs.file.files[0];

    },
     submitformBIM(){

    
                this.send = true;
              let formData = new FormData();

              formData.append("file", this.bimfile );
              formData.append("structure", this.structure );
              formData.append("renedering_name", this.renedering_name );
              formData.append("rendering_info", this.rendering_info );

               if(this.checkbox){
                formData.append("defualt", 0 );
            }else{
                formData.append("defualt", 1 );
            }


          console.log("formdata", formData);

            axios.post('/project/'+this.projectid+'/camera/'+this.current_cam.camera_id+'/update_bim_renedaring/'+this.renedering_id, formData, {
            headers: {
            "Content-Type": "multipart/form-data" 
            }
            })
          .then(resp => {
                 console.log("BIM url resonse", resp);
                
               this.send = false;
               this.newbimbox = false; 
               this.getBimLayouts();
             })
          .catch(err => {
            this.send = false;
            this.newbimbox = false; 
            console.log("BIM images",err)
           })
     },
     deleteitem(data){
              this.renedering_id = data.renedering_id;

             var ok = confirm("Are you sure to Delete this item");
              
              if(ok){
              axios.get('/project/'+this.projectid+'/camera/'+this.current_cam.camera_id+'/delete_bim_renedaring/'+this.renedering_id)
          .then(resp => {
                 console.log("BIM url resonse", resp);
               
               this.getBimLayouts();
             })
          .catch(err => {
        
            console.log("BIM images",err)
           })
        }
     }
    
  }
};
</script>
<style>
</style>


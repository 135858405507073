<template>
  <div>
    <Preloader v-if="preloader" />
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab @click="setrouter_timelapse" key="0"> {{lang.DefaultTimelapse}} </v-tab>

        <v-tab @click="setrouter_custom" v-if="custometab" key="1">
          {{lang.CustomTimelapse }}
        </v-tab>
        <v-tab  key="2"> {{lang.ArchiveTimelapse}} </v-tab>
        <v-tab @click="setrouter" class="createtab">
          {{lang.CreateTimelapse}}
        </v-tab>
      </v-tabs>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <v-snackbar v-model="snackbar" right top timeout="3000" type="success">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              {{lang.Ok }}
            </v-btn>
          </template>
        </v-snackbar>
        <table
          class="table"
          border="1"
          id="archive_data"
          style="border-collapse: collapse; width: -webkit-fill-available"
          role="table"
        >
          <tbody role="rowgroup">
            <tr
              role="row"
              style="border-bottom: 3px solid"
              v-for="r in response"
              :key="r.id"
            >
              <td role="cell" align="center" class="video">
                <div class="">
                  <Pano v-if="checkcam360" @on-load="onLoad"  type='video' :source="r.timelapse_url" style="width:484px !important; height: 202px;" class="videothumbnails"></Pano>
                  <video
                    class="videostyle"
                    controls="true"
                    style="height: 36vh !important"
                    v-if="!checkcam360"
                  >
                    <source :src="r.timelapse_url" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </td>
              <td role="cell" align="left" class="testing">
                <p
                  style="font-size: 24px; color: rgb(41 41 41); margin-top: 2%"
                >
                  {{ r.caption }}
                </p>
                <p style="color: rgb(41 41 41); font-size: 0.9em">
                  {{lang.AddedOn}} {{ r.updated_at }}
                </p>
                <br />

                <v-row>
                  <v-col cols="11" sm="5" class="txtcolor"
                    >{{lang.StartDate}}: {{ r.start_datetime }} <br />{{lang.EndDate}}:
                    {{ r.end_datetime }}<br />
                    {{lang.Quality}}: {{ r.resolution }} <br />
                    {{lang.Speed}}: {{ r.speed }}
                  </v-col>

                  <v-col cols="11" sm="5" class="txtcolor" v-if="r.advance_op"
                    >{{lang.AdvanceCustomization}}: {{ r.advance_op }}<br />
                    {{lang.FrameBlending}}: {{ r.frame }}<br />
                    {{lang.TimelapseSmoothing}}: {{ r.smooth }}<br />
                    {{lang.Deflicker}}: {{ r.deflicker }}
                  </v-col>
                </v-row>
              </td>
              <td class="delete" role="cell">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small text v-bind="attrs" v-on="on">
                      <v-icon left>mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-on:click="delete_archive(r.id)">
                      <v-list-item-action>{{lang.Delete}}</v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="openbox(r)">
                      <v-list-item-action> {{lang.Share}}</v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        color="amber accent-3"
        v-model="sharebox"
      >
        <template>
          <v-card>
            <v-toolbar color="amber accent-3" dark> {{lang.ShareByEmail}}
              <span
              class="btn btn-info text-white copy-btn ml-auto"
              @click.stop.prevent="copyTestingCode"
            >
              <v-btn depressed color="primary"> {{lang.Copyurl}} </v-btn>
              <input
                type="hidden"
                id="testing-code"
                :value="custometimelapsurl"
              />
            </span>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submit"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="email"
                      :label="lang.Email"
                      required
                      :rules="emailrules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="subject"
                      :label="lang.Subject"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      class="mr-4"
                      type="submit"
                      :disabled="!valid"
                      color="mylightbutton"
                    >
                      {{lang.Send}}
                      <v-progress-circular
                        v-if="send"
                        indeterminate
                        color="green"
                      ></v-progress-circular>
                    </v-btn>
                    <v-btn @click="sharebox = false" color="mydarkbutton">
                      {{lang.Cancel}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-card>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueSweetalert2 from "vue-sweetalert2";
import { Pano } from 'vuejs-vr'
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
    Pano
  },
  name: "ArchiveTimelapse",
  data() {
    return {
      preloader: true,
      regulertimelaps: "",
      currentcam: {},
      tab: 2,
      details: {},
      response: {},
      custometab: true,
      sharebox: false,
      custometimelapsbox: false,
      movetimelapsebox: false,
      email: "",
      message: "",
      subject: "",
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      valid: true,
      timelapsurl: "",
      custometimelapsurl: "",
      send: false,
      useraccess: false,
      freeuseraccess: false,
      project: "",
      org: "",
      city: "",
      ct_name: "",
      ct_id: "",
      caption: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      checkcam360: false,
      Local :locals,  
      lang : {},
    
    };
  },

  mounted() {
    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    this.initViewer();
    this.getTimelapsVideos();
    this.getdefulttimelapse();
    //this.tab = 'Archive';
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
    //this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.freeuseraccess = true
        }else{
          this.$router.push('/')
        }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getTimelapsVideos();
      this.getdefulttimelapse();
      console.log("camera parameters", this.currentcam);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      //console.log('currentProject', currentProject);

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
    },
  },
  computed: {
    params() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.timelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramscustome() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.custometimelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramsmovearchive() {
      return {
        c_dir: this.currentcam.camera_prefix,
        vid: this.ct_name,
        ct_id: this.ct_id,
        st_dt: this.details.start_date + this.details.start_time,
        end_dt: this.details.end_date + this.details.end_time,
        speed: this.details.timelapse_fps,
        quality: this.details.quality,
        cam_id: this.currentcam.camera_id,
        //usr: '',
        caption: this.caption,
        //t_ad_edit : this.details.Advance Editing,
        //t_frame : this.details.timelapse_fps,
        // t_smooth : this.details.timelapse_fps,
        //t_deflicker : this.details.timelapse_fps,
      };
    },
    deleteparam() {
      return {
        id: this.response,
      };
    },
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
      this.preloader = false;

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.current_cam = this.$store.state.currentCamera;
      //console.log('camera parameters',  this.currentcam);
    },
    getTimelapsVideos() {
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      //console.log("current Camera", this.currentcam.camera_id);
      axios({
        url:
          "/camera/" +
          this.currentcam.camera_id +
          "/project/" +
          currentProject.id +
          "/get/archive_timelapse",
        method: "GET",
      })
        .then((resp) => {
          console.log(
            "projecttimelapse",
            resp.data.success.archive_timelapse.data[0]
          );
          this.response = resp.data.success.archive_timelapse.data;
          if(this.currentcam.cam_360 == '1'){
              this.checkcam360 = true;
               
            }else{
               
              this.checkcam360 = false;
              
             
          }
          var len = 0;
          if (this.response != null) {
            len = this.response.length;
          }
          if (len > 0) {
            console.log(
              "archiveurl",
              resp.data.success.archive_timelapse.data[0].advance_op
            );
            for (var i = 0; i < len; i++) {
              //this.$refs.custome_timelapsvideo.src = resp.data.success.archive_timelapse.data[i].timelapse_url;
              this.custometimelapsurl =
                resp.data.success.archive_timelapse.data[i].timelapse_url;
              console.log(
                "archiveurl",
                resp.data.success.archive_timelapse.data[0].advance_op
              );
              if (
                resp.data.success.archive_timelapse.data[i].advance_op ===
                "problending"
              ) {
                resp.data.success.archive_timelapse.data[i].advance_op =
                  "Blending";
              }
            }
          } else {
            setTimeout(this.$swal({
              icon: "info",
              title: this.lang.notificationtitle,
              html: this.lang.notification,
              showConfirmButton: true,
            }), 3000);
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    getdefulttimelapse() {
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      // console.log("current Camera", this.currentcam);
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/list/timelapse",
        method: "GET",
      })
        .then((resp) => {
          //console.log(resp.data.success);
          //this.response = resp.data.success;
          if (resp.data.success.custom_timelapse == "Not enabled.") {
            this.custometab = false;
            //alert(this.custometab);
          }
          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openbox(r) {
      this.sharebox = true;
      //console.log("data", r);
      this.timelapsurl = r.timelapse_url;
      //alert("hello");
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharetimelaps(this.params); // action to login
      }
    },
    setrouter: function () {
      if(this.useraccess){
          this.$router.push("/dashboard/settings/create-timelapse");
       }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      //console.log(url);
    },
    setrouter_timelapse: function () {
      this.$router.push("/dashboard/timelapse");
      //console.log(url);
    },
    setrouter_custom: function () {
      this.$router.push("/dashboard/timelapse/custom-timelapse");
      //console.log(url);
    },
    async delete_archive_data() {
      this.delete_archive(this.deleteparam); // action to login
    },

    delete_archive: function (id) {
      //alert("hello"+id);
      const data = { id: id };
      //this.$router.push('/dashboard/timelapse/custom-timelapse');
      //console.log(url);
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      //console.log("current Camera", this.currentcam.camera_id);
      axios({
        url:
          "/camera/" + this.currentcam.camera_id + "/delete/archive_timelapse",
        data,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.$swal({
              icon: "success",
              title: "Success",
              html: this.lang.archiveDelete,
              showConfirmButton: true,
            });
             setTimeout(()=>{
               this.$router.go();
               }, 3000);
          } else {
           /*  this.alert = true;
            this.alertmessage =
              "Archive Timelapse not deleted, Please try again.";
            this.alertType = "error"; */
            this.$swal({
            icon: "error",
            title: "Error",
            html: this.lang.archiveError,
            showConfirmButton: true,
          });
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
        
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        //alert("url copied " + msg);
        this.snackbar = true;
        this.text = this.lang.copymsg; //"Copy URL! Link valid for 7 days only";
      } catch (err) {
        //alert("Oops, unable to copy");
        this.snackbar = true;
        this.text =  this.lang.SomethingWentWrong;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    sharetimelaps(sharedata) {
      console.log("data", sharedata);
      this.send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-with-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.sharebox = false;
          this.send = false;
          this.$swal({
            icon: "success",
            title: "Success",
            html: this.lang.timelapsesharesuccess,//"Timelapse shared successfully. Please check your mail.",
            showConfirmButton: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.send = false;
          /* this.snackbar = true;
          this.text = `Email delivery faild.`; */
          this.$swal({
            icon: "error",
            title: "Error",
            html: this.lang.SomethingWentWrong,
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>
<style>
.videostyle {
  margin: auto;
  display: block;
  width: 100%;
}
video {
  max-width: 100%;
  height: 85.6vh;
}
.detailsBox {
  position: absolute;
  right: 0;
  top: 0;
  background: #ebe9e9;
  box-shadow: 0 0 5px 3px #ccc;
  border-radius: 5px;
  padding: 6px 20px;
  opacity: 0.5;
}

.detailsBox:hover {
  opacity: 1;
}
.mixoperation {
  position: absolute;
}
.download {
  top: 36%;
}
.share {
  top: 45%;
}
.move {
  top: 35%;
}
td.video {
  width: 25%;
}
.testing {
  /* font-size: 20px; */
  color: yellow;
  width: 55%;
}
td.delete {
  width: 15%;
  vertical-align: top;
  text-align: right;
}
table#archive_data,
table#archive_data td {
  border: none !important;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.txtcolor {
  color: #736666;
}
.createtab {
  color: #7e7a7a !important;
}
.videothumbnails, .v-pano{
    height:150px;
    width: 100%;
}
</style>


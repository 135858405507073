<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        > 
         <v-card-title>
      {{lang.cameranotification}} 

        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>An email will be sent if the camera goes off for more than 2 hours</span>
          </v-tooltip>
        </span>
    </v-card-title>
         <v-snackbar v-model="snackbar" centered top timeout="3000" type="success">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              {{lang.Ok}}
            </v-btn>
          </template>
        </v-snackbar>  
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
        <v-col
          cols="12"
          md="6" 
        >
         <v-checkbox
      v-model="checkboxactive"
      :label="checkboxactive == 1 ?  lang.ClicktoInActive : lang.ClicktoActive"
     v-validate="'required'"
    ></v-checkbox>
      
        <v-text-field
          v-model="email"
          :label="lang.Email"
          type="email"
          :rules="emailrules"
          multiple
          required
        ></v-text-field> 
        <!-- <input type="email" v-model="email" label="Email" :rules="emailrules" multiple required> -->
       </v-col>
       <v-col
          cols="12"
          md="12"
        >
       <v-btn
        class="mr-4"
        type="submit"
        
        color="mylightbutton"
      >
        {{lang.submit}}
      </v-btn>

       <v-btn @click="Reset"
       color="mydarkbutton"
      >
        {{lang.Reset}}
      </v-btn>
      </v-col>
      </v-row>
    </v-container>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
 import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "Cameranotification",
  data() {
    return { 
       valid: true,
        checkboxactive : false,
        email : '',
        /* emailrules: [
        v => !!v || 'E-mail is required',
        v => /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(v) || this.lang.EmailValid
      ], */
       emailrules: [
        v => !!v || 'E-mail is required',
        v => /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[^\s,]+\.[A-Za-z]{2,4}){0,100}$/.test(v) || this.lang.EmailValid
      ],
         preloader: false,
          alert: false,
          alertmessage: '',
          alertType: '',
          current_cam : {},
        snackbar: false,
      text: "Something went wrong, please try again.", 
       Local :locals,  
        lang : {}, 
     };
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.initViewer();
     this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
      if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }   
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
        this.current_cam = this.$store.state.currentCamera;
        this.checkboxactive  =  this.current_cam.notification_active == 1 ? true: false; 
     this.email  = this.current_cam.notification_email;
    
  },
  watch: {
  '$store.state.currentCamera': function() {
    
     //console.log("Current data get",this.$store.state.currentCamera)

     this.current_cam = this.$store.state.currentCamera;

     this.checkboxactive  =  this.current_cam.notification_active == 1 ? true: false; 
     this.email  = this.current_cam.notification_email;
  }
  },
    computed: {
    params() {
      return {
       
         notification_email : this.email,
         notification_active : this.checkboxactive ? 1 : 0, 
       
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.addNotificaitonSettings(this.params) // action to login
      }
    },
    addNotificaitonSettings(userdata) {
        this.current_cam = this.$store.state.currentCamera;
        // console.log(userdata)
        if(this.checkboxactive == 0){
           this.snackbar = true;
           this.text = this.lang.camranotiStatus;
            console.log("no active notification");
        }else{
          this.snackbar = true;
          this.text = this.lang.cameraActivemsg;
          console.log("active notification");
        }
        axios({ url: '/camera/'+this.current_cam.camera_id+'/notification', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      },

      Reset(){
        this.current_cam = this.$store.state.currentCamera;
        var userdata = {
           notification_email : 'No email Added',
           notification_active : 0, 
          }
         axios({ url: '/camera/'+this.current_cam.camera_id+'/notification', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = this.lang.Resetsuccesful;
                    this.alertType = "success";

                    this.email = '';
                    this.checkboxactive = 0;

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage =  this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      }
     
  }
};
</script>
<style>
</style>


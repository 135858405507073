<template>
  
  <div>
    <Preloader v-if="preloader" />
    <v-snackbar v-model="alert"  top right timeout="45000" type="success">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
      <v-row>
      <v-col
          cols="12"
           xs="12"
           sm="3"
           md="3"
           lg="3"
           xl="3"
      >
<!-----------------For Desktop view-------------------------------->
        <v-card
          class="vhfix desktop"
         >
                <v-card-title>
                     Select Project
                </v-card-title>
                  <v-list class="desktop"
                >
              <v-list-item-group
                v-model="selectedItem"
                color="primary"
              >
              <v-list-item  v-for="(item, i) in items"
                  :key="i"
                  :class="item.id == active_proid ?'myactiaveitem':''"
                  active-class="actiaveitem" color="amber accent-3"   @click="getCamerasbyProject(item)" > 
              <template >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.project_name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>  
            </v-list-item-group>
            </v-list>       
        </v-card>
<!-----------------For Mobile view-------------------------------->
        <v-card
          class="vhfix mobileonly">
            <v-select :items="items" class="ma-2 filterb"  item-text="project_name" item-value="project_name" small label="Select Project" dense solo v-model="selected_project" @change="getCamerasbyProject($event)" return-object single-line title="Select Project"></v-select>
        </v-card>
<!-----------------For Tab view-------------------------------->         
         <v-card
          class="vhfix tabonly">
                <v-card-title>
                      Select Proj
                </v-card-title>
              <v-list class="tabonly">
                <v-list-item-group
                v-model="selectedItem"
                color="primary">
              <v-list-item  v-for="(item, i) in items"
                  :key="i"
                  :class="item.id == active_proid ?'myactiaveitem':''"
                  active-class="actiaveitem" color="amber accent-3"   @click="getCamerasbyProject(item)" > 
              <template >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.project_name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>  
            </v-list-item-group>
            </v-list> 
        </v-card> 
      </v-col>

<!--------------General view for all device------------------------------------>
      <v-col
           cols="12"
           xs="12"
           sm="9"
           md="9"
           lg="9"
           xl="9"
      >
        <v-card
          class="minheights"
         >
             <v-card
      dark
      flat
    >

      <v-card-title class="pa-2 amber accent-3">
        
        <h3 class="title font-weight-light text-center grow">
         {{ CurrentPname }} - Camera(s) Timeline info
        </h3>
        
      </v-card-title>
      
    </v-card>
          <div class="chartpopup"  v-if="showlinechart && loadigndata">
            <v-row>
                      <v-col class="col-sm-9"><h4> <span class="badgem" :class="clickvamd.status?'yellowtt':'pinktt'">.</span> {{ clickvamd.camname }} - Solar Panel & Battery Voltage Graph  </h4> </v-col>
                        <v-col class="col-sm-3">
                            <date-range-picker
                                @update="onValueChange"
                                :ranges="false"
                                opens="left"
                                v-model="chartdate"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                :date-format="dateFormat"
                            ></date-range-picker>
                            <p style="color:#d4425b;font-size:11px">{{ lang.max7 }}</p>
                        </v-col>
                </v-row>
          <apexchart ref="lineChart" type="line" height="350"  @click="clickHandler"  :options="chartOptions" :series="series"></apexchart>
        </div>
            <v-timeline
        align-top
        dense
      >
        <v-timeline-item
             
          v-for="(item, i) in orderedcamdata"
           :key="i"   
           
          :color="item.status?'amber accent-3':'pink'"
          small

        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{item.camname}} </strong>
            </v-col>
            <v-col>
              <strong>{{item.lastdate}} </strong>
              <v-btn class="right_aligh_battery" @click="getchartdata(item)" icon>
                 <v-icon>mdi-car-battery</v-icon>
               </v-btn>      
              <div class="caption">
                {{item.camname}} is {{ item.status? 'Active' : 'Deactive' }}, Last Image Taken on <strong>{{item.lastdate}} </strong>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>

        
        </v-card>
      </v-col>
        <!-- <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar />
      </v-col> -->
    </v-row>
      
        
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
//import Sidebar from '../../../components/SettingSidebar'
//import { orderBy } from 'lodash'
 import { locals } from "../../../utils/locals";
 import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
 import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DateRangePicker

  },
  name: "cameraInfo",
  data() {
    return { 
      preloader : false,
       items: {} ,
       selected_project:'',
       selectedItem:'',
       camers : [],
       camdata : [],
       CurrentPname : '',
       currentProject: "",
      active_proid:"",
      Local :locals,  
        lang : {},
        status:true, 
        chartOptions: {
            chart: {
              height: 350,
              type: 'line',
               toolbar: {
                        show: false
                    },
              zoom: {
                enabled: false
              },
            },
            colors: ['#f8bb03', '#000000'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [3,3],
              curve: 'smooth',
              dashArray: [0, 0]
            },
            title: {
              text: '',
              align: 'left'
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: [],
               tickPlacement: 'between',
               tickAmount: 25,
                labels: {
          show: true,
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
                }
            },
          yaxis: {
              labels: {
              formatter: function(val) {
                return Math.floor(val)
              }
              }
          },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },

          }, // chart option end 
          showlinechart: false,
          loadigndata :  false,  

          series: [
            {
              name: "Person",
              data: []
            },
            {
              name: 'Vehicle',
              data: []
            }
          ],

          chartdates : [],
          alert : false,
          alertmessage :'',
          clickvamd : '',
          chartdate: {},
          selectedSolarCamId:""
    }
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.getPorjects();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
     this.active_proid = this.currentProject.id;  
    },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
       this.camers = [];
       this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
     this.active_proid = this.currentProject.id;
  }

  },
  computed: {
    orderedcamdata: function () {
      //return orderBy(this.camdata, 'camname')

       var imahes =this.camdata;
      var ss = imahes.sort((a, b) => a.camnames - b.camnames);
      console.log(ss);
     return  this.camdata;
    }
 },
  methods: {
     getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

               //this.getCamerasbyProject(this.items[0])
               this.getCamerasbyProject(this.currentProject);
          })
          .catch(err => {
            console.log(err);
          })
      },
   
        getCamerasbyProject(projectId){   

          this.showlinechart = false;
          this.loadigndata = false;
          this.preloader = true;
          this.active_proid = projectId.id || this.selected_project.id;
           
          // console.log(projectId);
            this.CurrentPname = projectId.project_name || this.selected_project.project_name;
            this.camers = {}
            this.camdata = [];
          axios({ url: '/camera/'+this.active_proid, method: 'GET' })
          .then(resp => {
              this.camers = resp.data.success.filter(function(item){
                 return item.camera_active == 1;         
               });

             // console.log("Cameras", this.camers);
               var a = 1;
               this.camers.forEach(cam => {
                       
                       this.getLattersImage(a,cam)

                       a++;
               });
               this.preloader = false;
           })
          .catch(err => {
            console.log(err);
          })
       },

       getLattersImage(a,camdata){
               // console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata.camera_id+'/latest_images', method: 'GET' })
          .then(resp => {
           //  console.log("Lattest Image >>", resp.data.success[0].image_name);
      if(resp.data.success[0].image_name){
                var d = resp.data.success[0].image_name.split("_");
                var datetime = d[0]+"-"+d[1]+"-"+d[2]+" "+d[3]+":"+d[4]+":"+d[5]
                var tz = resp.data.timezonehrs.split(":");
                  let dateOneObj = new Date(datetime);
                let dateTwoObj = new Date();
               // console.log(dateTwoObj);
                let milliseconds = Math.abs(dateTwoObj - dateOneObj);
                let hours = milliseconds / 36e5;
               console.log("get hours", hours, tz[0]);
                let status = true;
                 if( hours > tz[0] ){
                   status = false;
                 }
                 
             this.camdata.push({camid:camdata.camera_id,camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime, status: status });
         }else{
           let status1 = false;
           let datetime1 = "";
           this.camdata.push({camid:camdata.camera_id, camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime1, status: status1 });
         }   /* 
               var timezone = camdata.timezone; 
               var check_pre = timezone.charAt(0);
              if(check_pre == '+'){
                  console.log("diff hrs +", tz[0]);
                  let dateOneObj = new Date(datetime);
                  let dateTwoObj = new Date();
                 // console.log(dateTwoObj);
                  let milliseconds = Math.abs(dateTwoObj - dateOneObj);
                  let hours = milliseconds / 36e5;
                  console.log("get hours +",hours);
                  var status0 = true;
                  if( hours > tz[0] ){
                   status0 = false;
                  }
                  this.camdata.push({camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime, status: status0 });
              }else if(check_pre == '-'){
                   console.log("diff hrs -", tz[0]);
                   let dateOneObj = new Date(datetime);
                  let dateTwoObj = new Date();
                  // console.log(dateTwoObj);
                  let milliseconds = Math.abs(dateTwoObj - dateOneObj);
                  let hours = milliseconds / 36e5;
                  console.log("get hours -",hours);
                  var status1 = true;
                 if( hours > tz[0] ){
                   status1 = false;
                 }
                 this.camdata.push({camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime, status: status1 });
              }else{
                let dateOneObj = new Date(datetime);
                let dateTwoObj = new Date();
               // console.log(dateTwoObj);
                let milliseconds = Math.abs(dateTwoObj - dateOneObj);
                let hours = milliseconds / 36e5;
                console.log("get hours",hours);
                var status2 = true;
                 if( hours > 2 ){
                   status2 = false;
                 }
                 this.camdata.push({camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime, status: status2 });
               }  */ 
             //this.camdata.push({camnames: a, camname : camdata.pro_cam_serial, lastdate : datetime, status: this.status });
           })
          .catch(err => {
            console.log(err);
          })
    },
    getchartdata(data){
      this.selectedSolarCamId = data.camid;
      this.clickvamd = data;

       console.log("data",data);

        this.preloader = true;

      var params =  {
        cam_id : data.camid
      }
      axios({ url: '/get-solar-volt-record?cam_id=', method: 'GET', params : params })
          .then(resp => {

             console.log("records", resp.data.success);

             var data = resp.data.success;

             if(data.length == 0){
               this.preloader = false;
               this.showlinechart = false;
               this.loadigndata = false;
               this.alertmessage = "Either the camera is not solar powered or the data is not yet available";
               this.alert = true;
             }else{
              this.chartDataMaker(data);
             }

            
      
          })
          .catch(err => {
            console.log(err);
          })
    },
    chartDataMaker(data){

        
         this.loadigndata = false;
         var batteryvarray = [];
         var solrvarray = [];
         var xaxis = [];
        

                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;
           // var dd = '';
             data.forEach(item  => {
              batteryvarray.push(item.batteryv);
              solrvarray.push(item.solrv);
                        Xmas95 = new Date(item.create_at);
                        var datep = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');
                        xaxis.push(datep);
             });
           

              this.series = [
            {
              name: "Battery Volt",
              data: batteryvarray
            },
            {
              name: 'Solar Volt',
              data: solrvarray
            }
          ];
            this.chartOptions.xaxis.categories = xaxis;

           //  console.log(xaxis);


         setTimeout(() => {
            this.showlinechart = true;
            this.loadigndata = true;
            console.log("louncher");
            this.preloader = false;
         }, 5000);   
             
          
        },
        dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
      onValueChange: function(args) {
      // var ss =  args.value.toLocaleDateString();

       console.log(args);

      if (args.startDate) {
        this.showChart = false;
        this.preloader = true;

        this.getSelectedCamData(args);
      }
    },

    getSelectedCamData(args) {
      var start = "";
      var end = "";

      var dataurl = this.selectedSolarCamId + "/get-solar-volt-record-by-selected-date"; 

      if (args) {
       
       var checkstart = new Date(this.chartdate.startDate);
       var checkend = new Date(this.chartdate.endDate);


        start = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
        end = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

        let Difference_In_Time =  checkend.getTime() - checkstart.getTime();

// Calculating the no. of days between
// two dates
   let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

   if(Difference_In_Days > 7){
    

        var today = new Date(this.chartdate.startDate);
          today.setDate(today.getDate() + 7);

          end = new Date(today).toISOString().slice(0, 10);

          this.chartdate.endDate = end;
          this.alertmessage = "Max 7 Days data can be shown at a time.";
          this.alert = true;
               
   }
      
      } else {
        const yourDate = new Date();
        var sd = yourDate.toISOString().split("T")[0];

        //console.log("ddddd",sd);
        end = sd;

        var dateOffset = 24 * 60 * 60 * 1000 * 2; //1 days
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);

        var ed = myDate.toISOString().split("T")[0];
        start = ed;

        console.log("start", start);
      }

      axios({
        url: dataurl,
        method: "POST",
        params: {
          start: start,
          end: end
        }
      })
        .then(resp => {
          if (resp.data.success.length == 0) {
            this.preloader = false;
            this.showChart = false;
            this.alertmessage = "No data found for selected date range";
            this.alert = true;
          }else{
            this.chartDataMaker(resp.data.success);
          }
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
      }
};
</script>
<style>
.vhfix{
 
  overflow:auto;
}
.minheights {
    min-height: 91vh;
}
.myactiaveitem{
  color: #ffc400 !important;
    caret-color: #ffc400 !important;
    background-color: #fff8e1 !important;
}
.right_aligh_battery {
    top: 1px;
    right: 13px;
    position: relative;
    float: right;
}
.chartpopup {
    padding: 15px;
}
.badgem{
  display: inline-block;
  height: 20px;
  width :20px;
  border-radius: 50%;
  color:transparent;
  border: 2px solid #ffffff;
  box-shadow: 0 0 13px 9px #ccc;
}
.pinktt{
  background: rgb(192, 55, 78);
}
.yellowtt{
    background: #efc12a;
   
}
.datepicker .daterangepicker {
    min-width: 549px !important;
}
</style>


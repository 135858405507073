<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="pa-5 formcard">
          <div class="stepper_outer">
            <!-- <div class="steper_item " @click="movetotab('/dashboard/planning/uploade-task')">
                        <div class="itcons">
                            <v-icon>mdi-cloud-upload </v-icon>
                        </div>
                        <span class="steperItem"> Upload Task </span>
                    </div> -->
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/edit-task')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.EditTask}} </span>
                <v-icon>mdi-square-edit-outline </v-icon>
              </div>
            </div>
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/compare-events')"
            >
              <div class="itcons">
                <span class="steperItem">{{ lang.CompareEvents }} </span>
                <v-icon>mdi-compare </v-icon>
              </div>
            </div>
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/event-chart')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.EventChart }} </span>
                <v-icon>mdi-chart-gantt </v-icon>
              </div>
            </div>
            <div
              class="steper_item active"
              @click="movetotab('/dashboard/planning/event-timelapse')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.EventTimelapse}} </span>
                <v-icon>mdi-play-circle </v-icon>
              </div>
            </div>
          </div>

          <!-- {{checkboxitems}} -->
          <template>
            <v-data-table
              :headers="headers"
              :items="newtaskdata"
              v-if="newtaskdata.length > 0"
              :items-per-page="50"
              :footer-props="footerpro"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <span
                  v-for="(c, index) in item.xx"
                  :key="item.id + c.camera_id"
                >
                  <span v-if="c.eventvideo">
                    <v-icon @click="playTimelapseVideos(c, item.id)"
                      >mdi-play-circle</v-icon
                    >
                    CAM {{ index + 1 }}
                    <v-icon @click="removeVideos(c, item.id)"
                      >mdi-delete</v-icon
                    >
                  </span>
                  <span v-if="!c.eventvideo && c.event_video_status">
                    <span> Processing CAM- {{ index + 1 }} </span>
                  </span>
                  <span v-if="!c.eventvideo && !c.event_video_status">
                    <span>
                      <v-checkbox
                        v-model="checkboxitems"
                        :label="`CAM - ${index + 1}`"
                        :value="`${item.id}_${c.cam_id}`"
                        @change="checkItemSave(item, c.cam_id)"
                      ></v-checkbox>
                    </span>
                  </span>
                </span>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="newbimbox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{lang.pleaseSelectanyOne}}
          </v-toolbar>
          <v-form
            ref="formBIM"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitformBIM"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-radio-group v-model="videotype" column>
                    <v-radio
                      :label="lang.eventtimenote"
                      value="1"
                    ></v-radio>
                    <v-radio
                      :label="lang.eventtime24"
                      value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send}}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="newbimbox = false" color="mydarkbutton">
                    {{ lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar
      v-model="alert"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
      type="success"
    >
      {{ alertmessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
         {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      color="amber accent-3"
      persistent
      v-model="playerbox"
      v-if="!checkcam360"
    >
      <template>
        <v-btn icon dark class="closebuttons" @click="playerboxClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <video
          class="videostyle"
          controls="true"
          ref="event_timelapsvideo"
          
        ></video>
       
      </template>
    </v-dialog>
    <div class="poppup" v-if="checkcam360 && player360box">
       <v-btn icon dark class="closebuttons" @click="player360boxClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
     <Pano
          class="mypano360"
          v-if="ready"
         type="video"
          :source="eventtimelapseurl"
          style="width: 1000px; height: 550px; display: block;"
        ></Pano>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import { Pano } from 'vuejs-vr'
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Pano
  },
  name: "EventTimelapse",
  data() {
    return {
      preloader: false,
      projectid: 0,
      newbimbox: false,
      taskname: "",
      startdate: new Date().toISOString().substr(0, 10),
      startdatamenu: false,
      enddate: new Date().toISOString().substr(0, 10),
      enddatemenu: false,
      actualstartdatemenu: false,
      actualstartdate: new Date().toISOString().substr(0, 10),
      actualenddatemenu: false,
      actualenddate: new Date().toISOString().substr(0, 10),
      updateTaskId: "",
      send: false,
      menu2: false,
      headers: [
        {
          text: "Task Name",
          align: "start",
          value: "task_name",
        },
        {
          text: "Start Date",
          value: "base_start_date",
        },
        {
          text: "End Date",
          value: "base_end_date",
        },
        {
          text: "Actual Start Date",
          value: "actual_start_date",
        },
        {
          text: "Actual End Date",
          value: "actual_end_date",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      footerpro: {
        itemsPerPageOptions: [50, 100, 150, -1],
      },
      taskdata: [],
      newtaskdata: [],

      taskdatalenth: 0,
      checkboxitems: [],
      valid: true,
      videotype: "1",
      selected: {},
      camid: 0,
      camers: {},
      alertmessage: "",
      alert: false,
      playerbox: false,
      player360box:false,
      checkcam360: false,
      eventtimelapseurl:'',
      ready :false,
       Local :locals,  
       lang : {},
    };
  },
  mounted() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng]; 
      
       this.headers = [{
                    text: this.lang.TaskName,
                    align: 'start',
                    value: 'task_name',
                },
                {
                    text: this.lang.StartDate,
                    value: 'base_start_date'
                },
                {
                    text: this.lang.EndDate,
                    value: 'base_end_date'
                },
                {
                    text: this.lang.ActualStartDate,
                    value: 'actual_start_date'
                },
                {
                    text: this.lang.ActualEndDate,
                    value: 'actual_end_date'
                },
                {
                    text: this.lang.Actions,
                    value: 'actions',
                    sortable: false
                }
            ]

    let currentProject = JSON.parse(localStorage.getItem("currentProject"));

    this.projectid = currentProject.id;

    this.initViewer();
  },
  watch: {
    "$store.state.currentCamera": function () {
      // console.log("Current data get",this.$store.state.currentCamera)
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.projectid = currentProject.id;
      this.getCameres();
    },
  },
  computed: {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      this.preloader = true;
      this.getCameres();
    },
    movetotab: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    getCameres() {
      axios({
        url: "/camera/" + this.projectid,
        method: "GET",
      }).then((resp) => {
        this.preloader = false;
        this.camers = resp.data.success.filter(function (item) {
          return item.camera_active == 1;
        });

        this.getTaskData();
      });
    },
    getTaskData() {
      this.preloader = true;
      axios({
        url: "/project/" + this.projectid + "/get-task ",
        method: "GET",
      })
        .then((resp) => {
          //var _self = this;

          // var aa  =  this.camers;

          var taskdata = resp.data.success;

          this.taskdatalenth = taskdata.length;
          // var a = 0;
          var _self = this;

          var ts = taskdata.filter(function (t) {
            return t.actual_start_date !== null;
          });

          var tasks = ts.filter(function (t) {
            //var b = 0;
            t.xx = [];

            _self.camers.forEach((c, inx) => {
              console.log("t.task_name", c, inx, t.task_name);

              var res = t.task_name.replaceAll(" ", "_");
              console.log("first Part", res);
              var task_name = res.replaceAll("/", "_");
              console.log("second Part", task_name);
              var tname = task_name + "_" + _self.projectid + "_" + c.camera_id;

              axios({
                url: "camera/" + c.camera_id + "/event-timelapse-video",
                params: { event_name: tname },
                method: "GET",
              }).then((aa) => {
                axios({
                  url:
                    "camera/" +
                    c.camera_id +
                    "/event/" +
                    t.id +
                    "/event-timelapse-status",
                  method: "GET",
                }).then((bb) => {
                  if (bb.data.success) {
                    if (aa.data.success.length > 0) {
                      t.xx.push({
                        event_video_status: true,
                        stream: tname,
                        eventvideo: true,
                        cam_id: c.camera_id,
                        cam_prefix: c.camera_prefix,
                      });
                    } else {
                      t.xx.push({
                        event_video_status: true,
                        stream: "",
                        eventvideo: false,
                        cam_id: c.camera_id,
                        cam_prefix: c.camera_prefix,
                      });
                    }
                  } else {
                    if (aa.data.success.length > 0) {
                      t.xx.push({
                        event_video_status: false,
                        stream: tname,
                        eventvideo: true,
                        cam_id: c.camera_id,
                        cam_prefix: c.camera_prefix,
                      });
                    } else {
                      t.xx.push({
                        event_video_status: false,
                        stream: "",
                        eventvideo: false,
                        cam_id: c.camera_id,
                        cam_prefix: c.camera_prefix,
                      });
                    }
                  }
                });
              });
            });

            return t.actual_start_date !== null;

            // var cam = t.cams.filter(function (item) {

            //        console.log("t.task_name",t.task_name);

            //             //   var res = t.task_name.replaceAll(" ", "_");
            //             //   var task_name  = res.replaceAll("/", "_");
            //             //    var tname = task_name+ "_" + _self.projectid + "_" + item.camera_id;
            //             item.stream = t.task_name
            //             item.eventvideo = true;
            //      return item.camera_active == 1;

            // });

            // console.log("cam data->",cam)
            //  return t.id > 0;
            // t.cams.forEach((c, b) => {

            //                 var res = t.task_name.replaceAll(" ", "_");

            //                 var task_name  = res.replaceAll("/", "_");

            //                 var tname = task_name+ "_" + this.projectid + "_" + c.camera_id;
            //     axios({
            //             url: 'camera/' + c.camera_id + '/event-timelapse-video',
            //             params: {event_name : tname},
            //             method: 'GET'
            //         })
            //         .then(resp => {

            //             //  console.log("resp.data.success.length", resp.data.success.length);
            //             if (resp.data.success.length > 0 ) {

            //                 var stream = resp.data.success[0].event_name;

            //                 console.log(stream == tname, b );

            //                     taskdata[inx]['cams'][b].eventvideo = true;
            //                     taskdata[inx]['cams'][b].stream = stream;

            //             }else{
            //                     taskdata[inx]['cams'][b].eventvideo = false;
            //                     taskdata[inx]['cams'][b].stream = '';
            //             }

            //              console.log(inx, taskdata[inx]['cams']);
            //         });

            // axios({
            //         url: 'camera/' + c.camera_id + '/event/' + t.id + '/event-timelapse-status',
            //         method: 'GET'
            //     })
            //     .then(resp => {

            //        // console.log(resp);

            //         if (resp.data.success) {

            //            taskdata[inx]['cams'][b].event_video_status = true;
            //         } else {

            //           taskdata[inx]['cams'][b].event_video_status= false;
            //         }

            //     });
          });

          var _s = this;

          setTimeout(function () {
            _s.newtaskdata = tasks;
            _s.preloader = false;
            console.log("last -->", _s.newtaskdata);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    checkItemSave(data, camid) {
      this.videotype;
      this.selected = data;
      this.camid = camid;
      console.log(this.selected);

      this.newbimbox = true;
    },

    submitformBIM() {
      this.send = true;
      let formData = new FormData();

      console.log(this.videotype);

      formData.append("task_name", this.selected.task_name);
      formData.append("task_id", this.selected.id);
      formData.append("sd", this.selected.actual_start_date);
      formData.append("ed", this.selected.actual_end_date);
      formData.append("cam_id", this.camid);
      formData.append("project_id", this.selected.project_id);
      formData.append("event_type", this.videotype);

      console.log("formdata", formData);

      axios
        .post("/camera/" + this.camid + "/event-timelapse-save", formData)
        .then((resp) => {
          console.log("Task update resonse", resp);

          this.send = false;
          this.newbimbox = false;
          this.getCameres();
        })
        .catch((err) => {
          this.send = false;
          this.newbimbox = false;
          console.log("Update Task Error", err);
        });
    },
    removeVideos(camdata, eventid) {
      var oktest = confirm(this.lang.videoConfirm);

      console.log(eventid);

      var p = {
        eventname: camdata.stream,
      };

      if (oktest) {
        axios({
          url: "/camera/" + camdata.cam_id + "/event-timelapse-remove",
          params: p,
          method: "GET",
        })
          .then((resp) => {
            console.log(resp);
            this.preloader = false;
            this.alertmessage = this.lang.removealert;
            this.alert = true;
            this.getCameres();
          })
          .catch((err) => {
            console.log(err);
            this.preloader = false;
            this.alertmessage = this.lang.SomethingWentWrong
           this.alert = true
          });
      }
    },

    playTimelapseVideos(camdata, eventid) {
      // $value->prefix; ?>/event_timelapse/<?php echo $stream_name ?>_720p.mp4

      console.log(eventid);
        this.preloader = true;
      var key =
        camdata.cam_prefix + "/event_timelapse/" + camdata.stream + "_720p.mp4";

      var p = {
        key: key,
        exp: "30 minutes",
        project_id : this.projectid
      };
      axios({
        url: "/get_sign_url_by_key",
        params: p,
        method: "GET",
      })
        .then((resp) => {
          console.log("Resp image url", resp);
          if (resp.data.success.info) {
            console.log(resp.data.success.url);
            this.playerbox = true;

            var v = this;
            this.currentcam = this.$store.state.currentCamera;
            if (this.currentcam.cam_360 == "1") {
              this.checkcam360 = true;
              this.player360box= true;
              console.log("360pano", resp.data.success.url);
              setTimeout(function () {
                v.preloader = false;
                v.ready = true;
               }, 1000);
            this.eventtimelapseurl = resp.data.success.url;
              } else {
              this.checkcam360 = false;
              setTimeout(function () {
                v.preloader = false;
                v.$refs.event_timelapsvideo.src = resp.data.success.url;
               }, 1000);
            }
          } else {
            this.preloader = false;
            this.alertmessage = this.lang.videonotfound
              this.alert = true
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alert = true;
        });
    },
    playerboxClose() {
      this.playerbox = false;
      this.$refs.event_timelapsvideo.src = "";
    },
    player360boxClose() {
      this.player360box = false;
      this.eventtimelapseurl = "";
    },
  },
};
</script>

<style>
/* .stepper_outer {
    display: flex;
    width: 100%;
    flex-: center;
    justify-content: space-between;
}

.steper_item {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    z-index: 6;
   width: 95%;
    cursor: pointer;
}

.itcons {
   width: 95%;
    height: 50px;
    border-radius:0;
    background: #292929;
    color: #ffc400;
    text-align: center;
    line-height: 47px;
    margin: auto;
    margin-bottom: 11px;
    border: 1px solid #ffc400;
    transition: 0.5s;
    border-radius:4px;
}

.itcons i {
    color: #ffc400 !important;
}

.active .itcons {
    
    height: 50px;
    background: #ffc400;
    border: 2px solid #000;
    box-shadow: 0 0 15px 5px #0000005c;
    transition: 0.5s;
    line-height: 47px;
}

.active .itcons i {
    color: #000 !important;
}

.active .steperItem {
    color: #000000 !important;
    text-shadow: 0px 1px 20px #000;
    font-weight: 500;
}

.stepper_outer {
    padding: 0 19px;
    position: relative;
}

.stepper_outer:after {
    content: "";
    display: block;
    height: 2px;
    background: #000;
    width: 88%;
    position: absolute;
    left: 58px;
    top: 26px;
    z-index: 1;
} */

.centerform {
  text-align: center;
  padding: 51px;
}

input[type="file"] {
  background: #ffc400;
  padding: 5px 17px;
  text-align: center;
}

.formcard {
  min-height: 92vh;
}
.mypano360{
    height:550px !important;
    width: 100%;
}

.poppup{
  position: fixed;
  left: 11%;
  top :11%;
  width: 100%;
  height: 100vh;
  text-align: center;

}

</style>

<template>
    <nav>
         <Notificaton/>
          
              <div class="topheader" >
                <div class="logoh part1">
                  <img class="trinetra logo_tt" :src="Trinetra_Logo" alt="trinetra"/>
                </div>
                <div class="logoh part3">
                
                </div>
                <div class="part2">
                  <img class="trinetra logo_tt" :src="Tatalogonew" alt="Tatalogonew"/>
                  <div class="menusmy" :class="class_name">
              <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        style="z-index: 12"
        class="poppins-medium"
      >
        <template v-slot:activator="{ on }">
          <v-list-item-avatar color="#333399"
                v-on="on"
                size="29" 
                class="useravaertar">
                 <!-- <span class="white--text headline"
                  >{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span
                > -->
               <v-icon dark>
                mdi-account
              </v-icon>
              </v-list-item-avatar>
        </template>

        <v-card class="mysettings poppins-medium">
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="#333399">
                <span class="white--text headline poppins-medium"
                  >{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ userdata.firstname }}
                  {{ userdata.lastname }}</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  userdata.user_code == 2
                    ? 'Super Admin'
                    : userdata.user_code == 1
                    ? 'User'
                    : 'Admin'
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#000"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="logout()"
                    >
                      mdi-logout
                    </v-icon>
                  </template>
                  <span> Logout </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          
         
          <v-list 
              class="mx-auto"
              max-width="300"
              max-height="500"
              dense
              >
          
            <v-list-item-group color="amber accent-3">
              <v-list-item 
                :color="
                  '/dashboard/settings/change-password' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/change-password')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{lang.UpdatePassword}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/settings/project-category' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/project-category')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-family-tree</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Project's Categories </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccess &&  nofreeaccess"
                :color="
                  '/dashboard/settings/adduser' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/adduser')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.AddUser }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :color="
                  '/dashboard/settings/manage-user' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/manage-user')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{lang.Users}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item
                v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/settings/user-logs' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/user-logs')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-target-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.UserLogs}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            
              

           

              <v-list-item
                v-if="billingaccess"
                :color="
                  '/dashboard/settings/billing' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/billing')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.Billing}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item @click="setrouter('/dashboard/settings/security/two-factor-auth-setup')">
                <v-list-item-icon>
                  <v-icon>mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.TwoFactorAuthentication}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          
              <v-list-item @click="support_dialog">
                <v-list-item-icon>
                  <v-icon>mdi-face-agent</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.Support}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccess && nofreeaccess && camconfig_access"
                :color="
                  '/dashboard/settings/camera/configuration' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/camera/configuration')"
                style="background-color: #e3e1e1;"
              >
                <v-list-item-icon>
                  <v-icon>mdi-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>  {{lang.CameraConfiguration}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="useraccess && nofreeaccess && camconfig_access"
                :color="
                  '/dashboard/settings/check_camera/installation' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/check_camera/installation')"
                style="background-color: #e3e1e1;"
              >
                <v-list-item-icon>
                  <v-icon>mdi-check-all</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.ProjectInfo}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>
          </v-list>
           <Languages/>
        </v-card>
      </v-menu> 
    </div>
  </div>
       
              </div>
          </nav>
</template>
<script>
//import axios from "axios";
import Trinetra_Logo from "../../assets/tata/Trinetra_Logo_final@2x.png";
import Tatalogonew from "../../assets/tata/Tatalogonew@2x.png";
import headerNotificaton from './headerNotification';
import { locals } from "../../utils/locals";
export default {
        components: {
           Notificaton : headerNotificaton
        },
  data: () => ({
    Trinetra_Logo : Trinetra_Logo,
    userdata: {},
    openAccessFeature: true,
    menu: false,
    message: false,
    Local :locals, 
    lang : {}, 
    useraccess: false,
    useraccesssuperadmin: false,
    nofreeaccess: false,
    user_material_access: false,
    user_interior_access: false,
    user_linear_access : false,
    linear_edit_access : false,
    user_drone_access: false,
    user_cont_camera_aceess: false,
    Tatalogonew :Tatalogonew,
    class_name : '',
   
  }),
  watch: {
    "$store.state.currentCamera": function () {
      
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
      //console.log("opticvyu user", this.userdata.opticvyu_user);
      if(this.userdata.opticvyu_user == 1){
            this.camconfig_access = true;
      }else{
           this.camconfig_access = false;
      } 
    },
    "$store.state.currentModule": function () {
      var currentModule = this.$store.state.currentModule;
     console.log("currentModule", currentModule);
    },
  },
  created() {
    this.class_name = this.$route.name;
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    //console.log(this.userdata);

    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
   
    this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
    this.useremailadd = this.userdata.email;
    this.usermobile = this.userdata.contactNo;
  },
  mounted() {
this.lang = locals['English'];
setTimeout(() => {
      this.initViewer();
 }, 2000);

},
  methods: {

    initViewer() {

           // console.log("user logs this.$route.--------------------->", this.$route.meta.title); 

       
     
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
      this.camera_name =  this.$store.state.currentCamera.pro_cam_serial;
     
      this.userdata = JSON.parse(localStorage.getItem("userdata"));

//console.log(this.userdata);

if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
  this.useraccess = true;
}
if (this.userdata.user_code == 2) {
  this.useraccesssuperadmin = true;
}
 if (this.userdata.ecam == 1) {
  this.ecamaccess = true;
}
if (this.userdata.billing_access == 1) {
  this.billingaccess = true;
}
if (this.userdata.free_access == 0) {
  this.nofreeaccess = true;
}
if (this.userdata.interior_access == 1) {
  this.user_interior_access = true;
}
if (this.userdata.material_access == 1) {
  this.user_material_access = true;
}
if (this.userdata.drone_access == 1) {
  this.user_drone_access = true;
}
if (this.userdata.cont_camera_aceess == 1) {
  this.user_cont_camera_aceess = true;
}
//console.log("user details", this.userdata);

 if (this.userdata.linear_monitoring_access == 1) {
        this.user_linear_access = true
    }
if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
  this.hasCRMAccess = true;
}
if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
  this.hasDailyReportAccess = true;
}
                   
    },
    
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    setrouter: function (url) {
if (this.$route.path !== url) {
 
 this.activelink = this.$route.path;

 window.location.href = url;
}

//console.log(url);
}

  },
};
</script>

<style>
.topheader {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 88px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 35px;
    /* flex-direction: row-reverse; */
    display: flex;
    flex-direction: row;
    align-items: center;
}
.useravaertar{
  cursor: pointer;
}
.part2, .part1, .part3 {
    width: 33.33%;
    display: block;
    float: left;
}
.part2 {
    text-align: right;
}
img.trinetra.logo_tt {
    
    height: 77px;
    top: 6px;
    padding-left: 21px;
    padding-right: 21px;
}
.logoh.part3 {
    text-align: center;
}
.menusmy {
    position: absolute;
    right: 34px;
    top: 107px;
}
.tpl-module {
    right: 73px;
    top: 105px;
}


</style>

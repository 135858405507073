<template>
<div>
    <Preloader v-if="preloader" />
    <v-row>
        <v-col cols="12" md="9">
            <v-card class="">
                <div class="Ibntegrationbox">

                    <v-btn :loading="loading3" :disabled="loading3" color="#f8bb03" class="black--text procore tab " @click="setrouter('/dashboard/settings/integration/procore_connection')">
                        {{lang.createprocore}}

                    </v-btn>

                    <v-btn :loading="loading3" :disabled="loading3" color="#f8bb03 " class="black--text bimclass tab active" @click="setrouter('/dashboard/settings/integration/bim360')">
                       {{lang.ConnectWithBim360}}
                    </v-btn>

                    <v-btn color="#f8bb03 " class="ma-5 mt-5 black--text" @click="requestEmbaded()">
                      {{lang.requestURL}}
                    </v-btn>
                    <div class="instructions">
                        <h4> {{lang.biminstruction}} </h4>
                        <br />
                        <p> {{lang.instOne}} <a href="https://acc.autodesk.com/insight" target="_blank">https://acc.autodesk.com/insight </a> </p>
                        <p> {{lang.instTwo}} </p>

                        <p>{{lang.instThree}} </p>

                        <p> {{lang.instfour}} </p>

                        <p> {{lang.instFive}} </p>

                        <p> {{lang.instSix}} </p>

                    </div>

                    <v-dialog v-model="project_list" scrollable max-width="450px">
                        <v-card>
                            <h4 class="pa-4"> {{lang.bimQue}} </h4>
                            <v-divider></v-divider>
                            <v-card-text style="height: 300px;">
                                <v-checkbox v-for="p in projectSearchdata" :key="p.project_name" v-model="checked_items" :label="p.project_name" color="#f8bb03" :value="p.id" hide-details></v-checkbox>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="blue darken-1" text @click="snedEmbadedRequest">
                                    {{lang.Send }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="project_list = false">
                                   {{ lang.Cancel }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

            </v-card>
        </v-col>
        <v-col cols="3" md="3" out-line class="setting_sidebar">
            <Sidebar />
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" top right timeout="3000">
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
export default {
    components: {
        Preloader,
        Sidebar
    },
    name: "Intrigration",
    data() {
        return {
            preloader: false,
            project_list: false,
            projectSearchdata: [],
            checked_items: [],
              Local :locals,  
              lang : {},
        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
         var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];
        this.initViewer();
    },
    watch: {
        '$store.state.currentCamera': function () {

            // console.log("Current data get",this.$store.state.currentCamera)

        }
    },
    computed: {

    },
    methods: {

        initViewer() {
            this.getPorjects();
        },
        setrouter: function (url) {
            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
            }

            //console.log(url);
        },
        requestEmbaded() {
            this.project_list = true;
        },

        getPorjects: function () {
            axios({
                    url: '/project',
                    method: 'GET'
                })
                .then(resp => {
                    // console.log("Projects", resp.data.success);
                    // var sself = this;

                    this.projectSearchdata = resp.data.success.filter(function (item) {
                        return item.project_active == 1;
                    })
                    console.log(this.projectSearchdata);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        snedEmbadedRequest() {
            this.preloader = true;
            console.log(this.checked_items);
            var userdata = JSON.parse(localStorage.getItem('userdata'));
            console.log(userdata.email);

            var x = this.checked_items.toString();

            var sharedata = {
                to: this.email,
                message: "This is the BIM 360 Embedded App URL Request for Project id/s " + x + " by Opticvyu user " + userdata.email,
                subject: "BIM 360 Embaded URL Request"
            }

            axios({
                    url: "https://secure.opticvyu.com/api/send-embaded-request",
                    data: sharedata,
                    method: "post",
                })
                .then((resp) => {
                    console.log(resp);
                    this.preloader = false;
                    this.text = this.lang.bimsuccessmsg;
                    this.snackbar = true
                    this.project_list = false
                })
                .catch((err) => {
                    console.log(err)
                    this.text = this.lang.SomethingWentWrong;
                    this.snackbar = true
                    this.project_list = false
                    this.preloader = false;
                });
        }
    }
};
</script>

<style>
.tab {
    width: 50%;
    margin: 0;
    border-radius: 0;
}

.tab.active {
    border-bottom: 2px solid #000;
}

.instructions {
    text-align: left;
    width: 80%;
    margin: auto;
    padding: 50px;
}
</style>

<template>
  <section class="section-container">
    <v-app-bar app dense class="headercolor loginheader" v-if="chanel == 'secure.opticvyu.com'">


      <span class="font-weight-bold shado"><img class="loginlogo" :src="logo"
          @click="setrouter('https://www.opticvyu.com/')" /></span>

      <v-tooltip bottom> </v-tooltip>

      <v-spacer></v-spacer>
      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small
        @click="setrouter('https://www.opticvyu.com/')">
        <span> Home </span>
      </v-btn>

      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="navmenu_items" text color="rgba(255, 255, 255, 0.7)" small v-bind="attrs" v-on="on">
            Solution
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-group no-action v-for='solution_item in solution_items' :key="solution_item.title">
            <v-list-item slot='activator' @click="setrouter(solution_item.route)" :to="solution_item.route">

              <v-list-item-title>{{ solution_item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-for='sub in solution_item.subitems' :key="sub.title" @click="setrouter(sub.route)"
              :to="sub.route">
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>

      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small
        @click="setrouter('https://www.opticvyu.com/dashboard-features')">
        <span> Features </span>
      </v-btn>
      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small
        @click="setrouter('https://www.opticvyu.com/opticvyu-demo')">
        <span> Gallery </span>
      </v-btn>
      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="navmenu_items" text color="rgba(255, 255, 255, 0.7)" small v-bind="attrs" v-on="on">
            About-us <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('https://www.opticvyu.com/faq')">
            <v-list-item-title> FAQs</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('https://www.opticvyu.com/applications')">
            <v-list-item-title> Applications</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('https://www.opticvyu.com/#clients')">
            <v-list-item-title> Our Clients</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!--  <v-btn text color="white" class="navmenu_items" small>
        <span> Become Partner </span>
      </v-btn> -->
      <v-btn text color="rgba(255, 255, 255, 0.7)" class="navmenu_items" small
        @click="setrouter('https://blog.opticvyu.com/')">
        <span> Blog </span>
      </v-btn>
      <v-btn text :color="'/login' == activelink ? activecolor : 'rgba(255, 255, 255, 0.7)'" class="navmenu_items" small>
        <span> Login </span>
      </v-btn>
      <v-btn rounded color="#f8bb03" class="navmenu_items" average dark
        @click="setrouter('https://www.opticvyu.com/contact-us')">
        <span> Contact Us </span>
      </v-btn>
    </v-app-bar>
    <v-row class="signin">
      <Preloader v-if="Preloader" />
      <!-- <v-col cols="9" class="left backgroundimage">
       
      </v-col> -->
      <v-col cols="12" class="right backgroundimage" :class="lgclas">
       

        <!-- Tata login page Design -->
       <div v-if="tpl">
        <div v-if="loginerror" class="loginbox">
          <img :src="Trinetra_Logo" style="max-width: 188px;max-height: 70px; margin: auto; display: block" />

          <p class="poppins-bold logintitle"> Login to Your Dashboard </p>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <p class="labletata poppins-semibold ">Username</p>
              <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                <v-text-field 
                class="tatatextfield" 
                 single-line
                 solo 
                 dense
                 color = "#193155"
                 v-model="email"
                  :error-messages="errors" required
                   prepend-inner-icon="mdi-account"></v-text-field>
              </validation-provider>
              <p class="labletata poppins-semibold ">Password</p>
              <validation-provider v-slot="{ errors }" name="Password" rules="required">
                <v-text-field class="tatatextfield"
                  single-line
                 solo
                 dense
                 color = "#193155"
              v-model="password" :error-messages="errors"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass" required
                   :type="showPass ? 'text' : 'password'"
                  prepend-inner-icon="mdi-lock"></v-text-field>
              </validation-provider>
              <div class="text-center">
                <button class="tatasingbuttons poppins-semibold" type="submit" >
                 Login
                </button>

                <div class="mt-5" style="cursor: pointer; color: #193155">
                  <p  class="poppins-semibold forgotlink" @click="checkgorgorpassword">Forgot password ?</p>
                </div>
                
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <v-alert dense outlined :type="type" class="mt-5" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
        <div v-if="!loginerror" class="loginbox">
          <img :src="Trinetra_Logo" style="max-width: 188px;max-height: 70px; margin: auto; display: block" />

          <p class="poppins-bold logintitle">  Reset Password</p>
          <p class="labletata poppins-semibold ">Enter your registered email address to reset the password</p>
          <validation-observer ref="observer">
            <v-form @submit.prevent="forgotPassword">
              <validation-provider v-slot="{ errors }" name="Name" rules="required|email">
                <v-text-field v-model="email" :error-messages="errors" label="Email" required 
                class="tatatextfield" 
                 single-line
                 solo 
                 dense
                 color = "#193155"
                prepend-inner-icon="mdi-account"></v-text-field>
              </validation-provider>

              <div class="text-center">
                <button class="tatasingbuttons poppins-semibold" type="submit" >
                 Submit
                </button>
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <div class="mt-5" style="cursor: pointer;">
              <p class="poppins-semibold forgotlink" @click="checkgorgorpassword">Back to login </p>
            </div>

            <v-alert dense outlined :type="type" :class="classs" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
     </div>
       <!-- Other all partners login page -->
       <div v-if="!tpl">
        <div v-if="loginerror" style="
            max-width: 100%;
            width: 450px;
            margin: auto;
            position: relative;
            top: 8%;
            background: #000000a3;
            padding: 23px;
            border-radius: 8px;
            box-shadow: rgb(120 120 120) 0px 4px 20px 1px;
          ">
          <img :src="logo" :class="lgclaslogclass" style="max-width: 188px;max-height: 70px; margin: auto; display: block" />

          <h2>Account Login </h2>
          <validation-observer ref="observer">
            <v-form @submit.prevent="submit">
              <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                <v-text-field v-model="email" @keyup="checktata" :error-messages="errors" label="Email" required outlined dark filled rounded
                  dense clearable prepend-inner-icon="mdi-account"></v-text-field>
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="Password" rules="required">
                <v-text-field v-model="password" :error-messages="errors" label="Password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass" required
                  outlined dense rounded dark filled :type="showPass ? 'text' : 'password'" clearable
                  prepend-inner-icon="mdi-lock"></v-text-field>
              </validation-provider>
              <div class="text-center" >
                <v-btn class="signin-btn singbuttons" v-if="!trinetraacative" type="submit" rounded>
                  Sign In
                </v-btn>

                <div class="mt-5" style="cursor: pointer;">
                  <p @click="checkgorgorpassword">Forgot password ?</p>
                </div>
                
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <v-alert dense outlined :type="type" class="mt-5 whitebak" v-if="error">
              <p class="errormsg">  {{ errorMessage }} </p>
              <p class="errormsg" v-if="error && trinetraacative"> Trinetra is a dedicated login page created for all TPL users </p>
              <v-btn class="signin-btn singbuttons" v-if="error && trinetraacative" rounded  @click="setrouter('https://trinetra.tataprojects.com')">
                Go To Trinetra
                </v-btn> 
            </v-alert>
          </div>
        </div>
        <div v-if="!loginerror" style="
            max-width: 100%;
            width: 450px;
            margin: auto;
            background: #000000a3;
            padding: 23px;
            border-radius: 8px;
            box-shadow: rgb(120 120 120) 0px 4px 20px 1px;
            position: relative;
            top: 8%;
          ">
          <img :src="logo" :class="lgclaslogclass" style="max-width: 188px; margin: auto; display: block" />
          <h2>Reset Password</h2>
          <p class="">Enter your registered email address to reset the password</p>
          <validation-observer ref="observer">
            <v-form @submit.prevent="forgotPassword">
              <validation-provider v-slot="{ errors }" name="Name" rules="required|email">
                <v-text-field v-model="email"  :error-messages="errors" label="Email" required outlined dark filled rounded
                  dense clearable prepend-inner-icon="mdi-account"></v-text-field>
              </validation-provider>

              <div class="text-center">
                <v-btn class="signin-btn singbuttons" type="submit" rounded>
                  Send
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <div class="mt-5" style="cursor: pointer;">
              <p @click="checkgorgorpassword">Return to login page</p>
            </div>

            <v-alert dense outlined :type="type" :class="classs" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
       </div>
      </v-col>
    </v-row>
    <v-footer height="auto" color="#CDCDCD" class="font-weight-medium footer_style" style="bottom: 1%;"
      v-if="chanel == 'secure.opticvyu.com'">
      <v-row>
        <v-col class="text-center white--text" cols="12">
          <v-row gutters>
            <v-col cols="12" sm="4" style="text-align:left;">
              <v-card class="pa-2 aboutov" outlined tile>
                <h3 class="small_heading whitet" style="font-size: 18pt;
    line-height: 35px;
    font-weight: initial;"> About Optic<span class="txtyellow">Vyu</span></h3>
                <p class="aboutpara">
                  OpticVyu provides construction monitoring services through construction camera, 360° image based
                  construction monitoring & material tracking. With OpticVyu engineer deployed at your site, we do
                  Exterior Time-lapsing, 360° Based Interior Monitoring, Document Management, Material Tracking, Project
                  Planning Integration, Reporting & Image/Videos documentation of every important activity.
                  <br><br>
                <ul class="ablinksList padding" style="font-size: 13px;">
                  <li><a href="https://www.opticvyu.com/privacy-policy">Privacy Policy</a></li>
                  <li><a href="https://www.opticvyu.com/terms-of-use">Terms of Use</a></li>
                </ul>
                </p>
                <br><br>
                <div class="footer_bottom">
                  <span class="copyright"> © Copyright 2016-21 OpticVyu ®. All rights are reserved.</span>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="5" style="text-align:left;">
              <v-card class="pa-2 aboutov" outlined tile>
                <h3 class="small_heading whitet" style="font-size:22px;">Links</h3>

                <v-row gutters>
                  <v-col cols="12" sm="6" style="text-align:left;">
                    <v-card class="pa-2 aboutov" outlined tile>
                      <p class="aboutpara">
                      <ul class="linksList list padding1" style="font-size: 15px;margin-left: -22%;">
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank" href="https://www.opticvyu.com/"
                            class="left">Home </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/products/timelapse-camera" class="left">Construction Camera
                          </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/products/360-image-based-monitoring" class="left">Interior
                            Monitoring </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/products/material-tracking" class="left">Material Tracking </a>
                        </li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/dashboard-features" class="left">Features </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/opticvyu-demo" class="left">Demonstration Videos </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/sample-time-lapse" class="left">Sample Time-Lapse Videos</a>
                        </li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/sample-images" class="left">Sample Images </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/applications" class="left">Applications</a></li>
                      </ul>
                      </p>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" style="text-align:left;">
                    <v-card class="pa-2 aboutov" outlined tile>
                      <p class="aboutpara">
                      <ul class="linksList list padding1" style="font-size: 15px;margin-left: -22%;">
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://blog.opticvyu.com/opticvyu-procore-integration-steps/" class="left">Integration
                            With Procore</a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://blog.opticvyu.com/opticvyu-construction-camera-integration-with-bim-360/"
                            class="left">Integration With BIM360</a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://blog.opticvyu.com/opticvyu-integration-with-bim-3d-renderings/"
                            class="left">Integration With BIM-Revit 3D Models</a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://blog.opticvyu.com/project-planning-integration/" class="left">Project Planning
                            Integration</a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/faq" class="left">FAQs </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/contact-us" class="left">Contact us/Get a Quote </a></li>
                        <li><v-icon dark left>mdi-arrow-right</v-icon><a target="_blank"
                            href="https://www.opticvyu.com/login" class="left">Login To Dashboard </a></li>
                      </ul>
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3" style="text-align:left;">
              <v-card class="pa-2 aboutov" outlined tile>
                <h3 class="small_heading whitet" style="font-size:22px;">Have a Question?</h3>
                <p class="aboutpara">
                <ul class="linksList padding" style="font-size: 13px;">

                  <li><v-icon dark left>mdi-email</v-icon>admin@opticvyu.com</li>
                </ul>
                </p>
                <br><br>
                <div class="padding">
                  <ul class="social_item">
                    <li><a href="https://www.linkedin.com/company/opticvyu-construction-camera" target="_blank"
                        title="linkedin"><v-icon dark left>mdi-linkedin</v-icon></a></li>
                    <li><a href="https://www.facebook.com/OpticVyu-1806456722922150/?ref=aymt_homepage_panel"
                        target="_blank" title="Facebook"><v-icon dark left>mdi-facebook</v-icon></a></li>

                  </ul>
                </div>
                <div class="padding" style="margin-top: -20%;">
                  <ul class="social_item">
                    <li><a href="https://play.google.com/store/apps/details?id=com.main.opticvyu" target="_blank"
                        title="playstore"><v-icon dark left>mdi-android</v-icon></a></li>
                    <li><a href="https://apps.apple.com/in/app/opticvyu-construction-camera/id1555185085" target="_blank"
                        title="apple store"><v-icon dark left>mdi-apple</v-icon></a></li>

                  </ul>
                </div>

              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </section>
</template>

        
<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";
import axios from "axios";
import logo from "../assets/OpticVyu_logo_HD.png";
import rawadhalogo from "../assets/rawadh.png";
import lisuallogo from "../assets/lisual.png";
import smartvyu from "../assets/smartvyulogo.png";
import tatalogo from "../assets/tatafull.png";
import lntlogo from "../assets/lnt.png";
import globaltimelapselogo from "../assets/globaltimelapse.png";
import equalbaselogo from "../assets/equalbase.png";
import smlogo from "../assets/sm.png";
import locals from "../utils/locals";
import Preloader from "../components/PreLoader";
import Trinetra_Logo from "../assets/tata/Trinetra_Logo_final@2x.png";
import tboxsystemslogo from "../assets/protboxforloginscreen.png";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});



export default {
  components: {
    Preloader,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    tpl : false,
    Preloader: false,
    email: "",
    password: null,
    showPass: false,
    error: false,
    errorMessage: "",
    type: "",
    classs: "",
    loginerror: true,
    activelink: "/login",
    activecolor: "amber accent-3",
    param_up: {},
    resetmsg1: 'Check your email inbox for instructions on resetting your password.',
    resetmsg2: 'Note: make sure to check your spam folder.',
    lang: locals,
    solution_items: [
      {
        icon: "", title: "Construction Camera", subitems: [
          { title: 'Opticvyu Make Time-lapse Cameras', route: "https://www.opticvyu.com/products/timelapse-camera" },
          { title: 'Convert Existing CCTV to Time-lapse Camera', route: "https://www.opticvyu.com/products/cctv-timelapse" },
          { title: 'AI Based PPE Detection Construction Camera', route: "https://blog.opticvyu.com/opticvyu-artificial-intelligence-based-image-processing-solution/" },
          { title: 'Vehicle Detection/People Counting/Material Safety Camera', route: "https://www.opticvyu.com/products/vehicle-detection-people-counting" },

        ]

      },
      {
        icon: '', title: '360 Degree Image-Based Interior Monitoring', route: "https://www.opticvyu.com/products/360-image-based-monitoring"
      },
      {
        icon: '', title: 'Linear Construction Monitoring', route: "https://www.opticvyu.com/products/linear-construction-monitoring"
      },
      {
        icon: '', title: 'Material Tracking', route: "https://www.opticvyu.com/products/material-tracking"
      },
      {
        icon: '', title: 'Drone Service', route: "https://www.opticvyu.com/products/drone-photography-service"
      },

    ],
    logop: logo,
    rawadhalogo: rawadhalogo,
    lisuallogo: lisuallogo,
    smartvyu: smartvyu,
    tatalogo: tatalogo,
    lntlogo: lntlogo,
    globaltimelapselogo: globaltimelapselogo,
    smlogo: smlogo,
    equalbaselogo: equalbaselogo,
    tboxsystemslogo : tboxsystemslogo,
    logo: '',
    chanel: '',
    Trinetra_Logo:Trinetra_Logo,
    trinetraacative : false,
    lgclaslogclass : ''
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
        // grant_type: "password",
        // client_id: 2,
        // client_secret: "adfCEgbQA2ybpuqgLtoJhAuRedAJBQlp651WDElI",
        // scope: "",
      };
    },
    resetmsgparams() {
      return this.resetmsg1 + ' <br> ' + this.resetmsg2;
    },
  },
  created() {
    //  console.log("language",this.lang);
    //  alert(window.location.hostname);
    var chenal_partner = window.location.hostname;
    this.chanel = chenal_partner;
      
    if(chenal_partner == "tpl.opticvyu.com"){
     this.tpl = true;
    }
    if (chenal_partner == "secure.rawadh.com") {
      this.logo = this.rawadhalogo;
      this.lgclas = "rawadh";
      this.lgclaslogclass = "rawadhlogo";
    } else if (chenal_partner == "secure.lisual.com") {
      this.logo = this.lisuallogo;
      this.lgclas = "lisual";
    } else if (chenal_partner == "secure.opticvyu.com") {
      this.logo = this.logop;
      this.lgclas = "opticvyu";
    } else if (chenal_partner == "image.smartvyu.com") {
      this.logo = this.smartvyu;
      this.lgclas = "smartvyu";
    } else if (chenal_partner == "secure.tata.com") {
      this.logo = this.tatalogo;
      this.lgclas = "tata";
    } else if (chenal_partner == "secure.lnt.com") {
      this.logo = this.lntlogo;
      this.lgclas = "lnt";
    } else if (chenal_partner == "secure.spacematrix.com") {
      this.logo = this.smlogo;
      this.lgclas = "spacematrix";
    } else if (chenal_partner == "login.globaltimelapse.com") {
      this.logo = this.globaltimelapselogo;
      this.lgclas = "globaltimelapse";
    } else if (chenal_partner == "equalbase.tboxsystems.com") {
      this.logo = this.equalbaselogo;
      this.lgclas = "equalbase";
      this.lgclaslogclass = "equalbaselogo";
    } else if (chenal_partner == "pro.tboxsystems.com") {
      this.logo = this.tboxsystemslogo;
      this.lgclas = "tboxsystems";
      this.lgclaslogclass = "tboxsystemslogo";
    }
     else {
      this.logo = this.logop;
    }
    this.checklogin();
  },
  methods: {
    checklogin() {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      // console.log(this.userdata);
      if (this.userdata) {
        var redirect;
        if (this.userdata.cont_camera_aceess == 1) {
          redirect = "/dashboard";
          this.$router.push(redirect);
        } else {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login");
          });
        }
        // else if (this.userdata.interior_access == 1) {
        //   redirect = '/dashboard/user-interior-view';
        // }else if (this.userdata.material_access == 1) {
        //   redirect = '/dashboard/material/inventories';
        // }else if (this.userdata.drone_access == 1) {
        //   redirect = '/dashboard/user-drone-view';
        // }
      }
    },
    setrouter: function (url) {
      window.location.href = url;
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        //console.log("Hello i am here.");
        this.login(this.params); // action to login
      }
    },
    checktata(){

      //this.email;
      console.log("click",this.email);
      let result = this.email.includes("tataproject");
       console.log("result",result);
      if(result){
       this.trinetraacative =  true;
        this.error = true;
        this.errorMessage = "Please login through the Trinetra website";
        this.alertMessage = "Trinetra is a dedicated login page created for all TPL users";
      }else{
        this.trinetraacative =  false
      }
       
    },
    checkgorgorpassword() {
      this.loginerror = !this.loginerror;
    },
    clear() {
      // you can use this method to clear login form
      this.email = "";
      this.password = null;
      this.$refs.observer.reset();
    },
    login(data) {
      this.$store
        .dispatch("login", data)
        .then(() => this.getUser())
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorMessage =
            "Incorrect email address or password. Try resetting your password.";
          this.type = "error";

        });
    },
    getUser() {
      this.$store
        .dispatch("getUser")
        .then((data) => {
          //console.log("current datat", data.data.success);
          //var y = Math.floor((Math.random() * 9999) + 1000);
          // console.log("random no", y);

          var userdata = data.data.success;

          if (userdata.User2F_auth == 1) {
            //this.otp_verify(userdata.email);
            this.$router.push("/two-factor-auth-verification");
            this.otp_verify(userdata.email);
            localStorage.setItem('userdata', ""); 
            localStorage.setItem('authemail', userdata.email);
            localStorage.setItem('luser_code', userdata.user_code);
          } else {
            var redirect;
            var chenal_partner = window.location.hostname;

            console.log("chenal_partner",chenal_partner);

            if(chenal_partner == 'tpl.opticvyu.com'){
              window.location.href = "/tpls";
              //redirect = "/dashboard/user-interior-view";
            }else{
            if (userdata.cont_camera_aceess == 1) {
              redirect = "/dashboard";
            } else if (userdata.interior_access == 1) {
              redirect = "/dashboard/user-interior-view";
            } else if (userdata.material_access == 1) {
              redirect = "/dashboard/material/inventories";
            } else if (userdata.drone_access == 1) {
              redirect = "/dashboard/user-drone-view";
            }else if (userdata.linear_monitoring_access == 1) {
              redirect = "/dashboard/gps-map-view";
            }
            localStorage.setItem('luser_code', userdata.user_code);
            this.$router.push(redirect);
          }
          }
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorMessage =
            "Incorrect ID or password. Please check the credentials or click on the 'Forgot Password' link to reset the password.";
          this.type = "error";

        });
    },
    async forgotPassword() {
      this.Preloader = true;
      //console.log(this.email);

      var email = {
        email: this.email,
      };
      axios({ url: "/password/email", data: email, method: "POST" })
        .then((response) => {
          console.log(response, "response Link send Sccessful");
          if (response.data.success) {
            this.error = true;
            this.type = "success";
            this.errorMessage = "Check your email inbox for instructions on resetting your password. Note: make sure to check your spam folder.";
            this.loginerror = !this.loginerror;
            this.class = "mt-5 multi-line";
          } else {
            this.error = true;
            this.type = "error";
            this.errorMessage = "The email address you entered is not registered with OpticVyu. Please contact admin@opticvyu.com.";
            this.classs = "mt-5";
          }
          this.Preloader = false;
        })
        .catch((err) => {

          console.log(err);
          this.error = true;
          this.errorMessage = "The email address you entered is not registered with OpticVyu. Please contact admin@opticvyu.com for any query related to account creation";
          this.type = "error";
          this.classs = "mt-5";
          this.Preloader = false;
        });
    },
    otp_verify(emailadd) {
     // var y = Math.floor(Math.random() * 9999 + 1000);
      //console.log("random no", y);
      var param_up = {
        email: emailadd,
      };

     // console.log("check update otp", paramss);
      axios({
        url: "/user/next_verification",
        data: param_up,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);

        })
        .catch((err) => {
          console.log(err);

        });
      // axios({
      //   url: "https://secure.opticvyu.com/api/send-twofactor-auth-otp",
      //   data: this.param_up,
      //   method: "post",
      // })
      //   .then((resp) => {
      //     console.log(resp);
      //     this.updateotp(this.param_up);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.updateotp(this.param_up);
      //   });
    },
    updateotp(paramss) {
      // var y = Math.floor(Math.random() * 9999 + 1000);
      console.log("check update otp", paramss);
      axios({
        url: "/user/update_twofactor_verification",
        data: this.param_up,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);

        })
        .catch((err) => {
          console.log(err);

        });
    },
  },
};
</script>
<style>
.loginerror {
  color: brown;
}

.loginlogo {
  width: 170px !important;
  max-width: auto;
  max-height: 60px;
  position: relative;
  top: 14px !important;
  left: 2% !important;
}

.logolink {
  cursor: pointer;
}

.footer_style {
  background-color: rgb(12 12 12) !important;
  border-color: rgb(18 19 20) !important;
  bottom: 15%;
  /* margin-bottom: 21%; */
  position: relative !important;
  height: auto;
  width: 100%;
}

.loginheader {
  height: 70px !important;
}

.navmenu_items {
  font-size: 13px !important;
  /* padding: 25px 3px !important; */
  margin-top: 1%;
}

.aboutov {
  background-color: #0c0c0c !important;
  color: #ffff !important;
  font-weight: 200;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-left: 6%;
}

.small_heading {
  font-weight: 400;
}

.txtyellow {
  color: #f8bb03;
}

.aboutpara {
  margin-top: 10%;
  font-size: 16px;
  font-family: emoji;
  text-align: justify;
  color: #a6a6a6 !important;
}

.ablinksList li a {
  color: #fed136 !important;
  font-weight: 400;
}

.linksList li a {
  color: #a6a6a6 !important;
  font-weight: 400;
  text-decoration: none;
}

.linksList {
  list-style: none;
}

.ablinksList {
  list-style: none;
}

.footer_bottom {
  padding: 34px 0px 0;
  overflow: auto;
  font-size: 11px;
  color: #f8bb03;
}

ul.linksList.list.padding1 li {
  padding-left: 30px;
  margin-top: 9px;
  position: relative;
  text-align: initial;
}

ul.social_item {
  padding: 0;
  list-style: none;
  margin-top: 50px;
}

ul.social_item li {
  height: 56px;
  width: 56px;
  line-height: 43px;
  /* background-color: #333333; */
  border-radius: 45%;
  font-size: 44px;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-right: 16px;
}

.v-application a {
  text-decoration: none;
}

.icona {
  font-size: 15px;
  color: #a6a6a6;
  position: absolute;
  left: 8px;
  top: 3px;
}

.multi-line {
  white-space: pre-line;
}

.rawadh {
  max-width: 70px !important;
  width: auto !important;
  height: 50px !important;
}
.rawadhlogo {
  max-width: 70px !important;
  width: auto !important;
  height: 50px !important;
}

img.loginlogo.lnt {
  width: 50px !important;
}

img.loginlogo.tata {
  width: 140px !important;
}
.logintitle{
  font-size: 15px;
  text-align: center;
}

.tatatextfield .v-input__slot:focus{
    padding: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 16px #0014FF5D;
border: 1px solid #333399;
border-radius: 10px;
}
.tatatextfield .v-input__slot   {
  padding: 0px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #DEDEDE;
box-shadow: 0px 0px 0px hsl(0, 0%, 100%) !important;
border-radius: 10px;
}
p.poppins-bold.logintitle {
    color: #193155;
    margin: 34px 0 30px;

}
.labletata {
    color: #193155;
    font-size: 10px;
    margin-bottom: 10px !important;
}
.tatatextfield .v-text-field__details {
    padding-top: 0px;
     margin-bottom: -4px !important;
}

.tatatextfield.v-input--is-focused .v-input__slot{
   
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 16px #0014FF5D !important;
border: 1px solid #333399;

}
.tatatextfield .v-icon{
  font-size: 15px !important;
}
.tatatextfield.v-text-field input {
    font-size: 12px;
}
.v-application .tatatextfield .error--text {
    color: #333399 !important;
    caret-color: #333399 !important;
}
.tatasingbuttons {
    background: #333399 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width : 100%;
    font-size: 12px;
    padding: 5px 0;
    margin-top: 20px;

}
.forgotlink{
  cursor: pointer; color: #193155;
  font-size: 12px;
}
.loginbox {
    max-width: 100%;
    width: 385px;
    margin: auto;
    position: relative;
    top: 23%;
    padding: 20px 53px 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 99px #052D773E;
    border-radius: 10px;
    opacity: 0.99;
}
.v-application p {
    margin-bottom: 16px;
}
.errormsg {
  color :#fff !important;
}
.whitebak{
 border-color: #fff !important;
}
img.equalbaselogo {
    background: #fff;
    padding: 5px;
    border-radius: 3px;
}
</style>
<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="pa-5 formcard">
          <div class="stepper_outer">
            <!-- <div class="steper_item " @click="movetotab('/dashboard/planning/uploade-task')">
                        <div class="itcons">
                            <v-icon>mdi-cloud-upload </v-icon>
                        </div>
                        <span class="steperItem"> Upload Task </span>
                    </div> -->
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/edit-task')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.EditTask}} </span>
                <v-icon>mdi-square-edit-outline </v-icon>
              </div>
            </div>
            <div
              class="steper_item active"
              @click="movetotab('/dashboard/planning/compare-events')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.CompareEvents}} </span>
                <v-icon>mdi-compare </v-icon>
              </div>
            </div>
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/event-chart')"
            >
              <div class="itcons">
                <span class="steperItem"> {{lang.EventChart}} </span>
                <v-icon>mdi-chart-gantt </v-icon>
              </div>
            </div>
            <div
              class="steper_item"
              @click="movetotab('/dashboard/planning/event-timelapse')"
            >
              <div class="itcons">
                <span class="steperItem"> {{ lang.EventTimelapse }} </span>
                <v-icon>mdi-play-circle </v-icon>
              </div>
            </div>
          </div>

          <v-snackbar
            v-model="alert"
            :multi-line="multiLine"
            top
            right
            timeout="3000"
            type="success"
          >
            {{ alertmessage }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-row>
            <v-col cols="3" md="3">
              <div class="listbox">
                <v-card-title> {{lang.selectEvent}} </v-card-title>
                <v-list nav dens>
                  <v-list-item-group
                    color="amber accent-3"
                    v-model="selectedItem"
                  >
                    <v-list-item v-for="(item, i) in taskdata" :key="i">
                      <v-list-item-content @click="selectedTask(item)">
                        <v-list-item-title
                          v-text="item.task_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="listitemicon">
                        <v-icon>mdi-arrow-right-bold-circle</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>
            <v-col cols="9" md="9" class="comparebox">
              <span class="palandate left">
                {{lang.PlannedStartDate}} <br />
                {{ planStartdate }}
              </span>
              <a
                v-if="see_actual"
                @click="seeActualImage"
                class="plann_buttons"
                :title="lang.seeActualStartDate"
              >
                See Actual Date Images
              </a>
              <a
                v-if="see_planned"
                @click="seePlaneImage"
                class="plann_buttons"
                :title="lang.seeActualEndDate"
              >
                {{lang.SeePlannedDateImages}}
              </a>
              <span class="palandate right">
                {{lang.plannedEndDate}} <br />
                {{ planEnddate }}
              </span>
              <VueCompareImage
                v-if="!checkcam360"
                :leftImage="compareimage1"
                :rightImage="compareimage2"
                 @load="loaded"
              />
              <div
                class="splitview"
                v-if="checkcam360"
                style="position: relative"
              >
                <div class="col-md-6" style="float: left">
                  <div v-if="!show_1img360" class="cefull360right">
                    <v-pannellum :src="compareimage1" :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable" :hfov="hfov" :yaw="yaw"  :pitch="pitch"  ref="panaromascreen"></v-pannellum>
                  </div>

                  <v-zoomer v-if="show_1img360" class="">
                    <img
                      v-if="show_1img360"
                      :src="compareimage1"
                      style="object-fit: contain; width: 100%; height: 100%"
                    />
                  </v-zoomer>
                </div>
                <v-btn
                  fab
                  color="red"
                  v-if="checkcam360 && showlockbtn"
                  class="lockbtn"
                  @click="lockScreens"
                  title="Lock"
                >
                  <v-icon dark>
                    {{ lock ? "mdi-lock" : "mdi-lock-open" }}
                  </v-icon>
                </v-btn>
                <div id="cecontrols" v-if="lock">
                    <div class="ctrl" id="pan-up" @click="panUp">&#9650;</div>
                    <div class="ctrl" id="pan-down" @click="panDown">
                      &#9660;
                    </div>
                    <div class="ctrl" id="pan-left" @click="panLeft">
                      &#9664;
                    </div>
                    <div class="ctrl" id="pan-right" @click="panRight">
                      &#9654;
                    </div>
                    <div class="ctrl" id="zoom-in" @click="panIn">&plus;</div>
                    <div class="ctrl" id="zoom-out" @click="panOut">
                      &minus;
                    </div>
                  </div>
                <div class="col-md-6" style="float: right">
                  
                  <div v-if="!show_2img360" class="cefull360left">
                    <v-pannellum :src="compareimage2" :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable" :hfov="hfov" :yaw="yaw"  :pitch="pitch" ref="panaromascreen2"></v-pannellum>
                  </div>

                  <v-zoomer v-if="show_2img360" class="">
                    <img
                      v-if="show_2img360"
                      :src="compareimage2"
                      style="object-fit: contain; width: 100%; height: 100%"
                    />
                  </v-zoomer>
                </div>
              </div>
              <div>
                <v-container>
                  <v-row>
                    <v-col cols="6" md="6" class="navibar" style="float: left">
                      <span class="actualdate left" v-if="see_actual">
                        {{lang.ActualStartDate}} <br />
                        {{ actualStartdate }}
                      </span>

                      <v-btn
                        icon
                        @click="navigation('minusOne', '1')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="navigation('minusSeven', '1')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-double-left</v-icon>
                      </v-btn>

                      <span class="dbuttons"> {{ naviPlanStartdate }} </span>

                      <v-btn
                        icon
                        @click="navigation('plusSeven', '1')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-double-right</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="navigation('plusOne', '1')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-select
                        :items="hours"
                        label="Hrs"
                        outlined
                        class="houseselecrt"
                        v-model="select1"
                        @change="hourlyImages('1')"
                      ></v-select>
                      <br />
                      <br />
                      <v-btn
                        @click="saveActualStartDate"
                        color="#fac003"
                        v-if="useraccess"
                        :disabled="imageOnearror"
                      >
                        {{lang.SaveActualStartDate}}
                      </v-btn>
                    </v-col>

                    <v-col cols="6" md="6" class="navibar" style="float: right">
                      <span class="actualdate left" v-if="see_actual">
                        {{lang.ActualStartDate}} <br />
                        {{ actualEnddate }}
                      </span>

                      <v-btn
                        icon
                        @click="navigation('minusOne', '2')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="navigation('minusSeven', '2')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-double-left</v-icon>
                      </v-btn>

                      <span class="dbuttons"> {{ naviPlanEnddate }} </span>

                      <v-btn
                        icon
                        @click="navigation('plusSeven', '2')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-double-right</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="navigation('plusOne', '2')"
                        class="navi"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-select
                        :items="hours"
                        label="Hrs"
                        outlined
                        class="houseselecrt"
                        v-model="select2"
                        @change="hourlyImages('2')"
                      ></v-select>
                      <br />
                      <br />
                      <v-btn
                        @click="saveActualEndDate"
                        color="#fac003"
                        v-if="useraccess"
                        :disabled="imageTwoarror"
                      >
                        {{lang.SaveActualEndDate}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div class="clayer" v-if="lock"></div>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";

import image1 from "../../../assets/opticvyu_comp_l.png";
import image2 from "../../../assets/opticvyu_comp_r.png";
import VueCompareImage from "vue-compare-image";
import VuePannellum from "vue-pannellum";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    VueCompareImage,
    VPannellum: VuePannellum,
  },
  name: "CompareEvent",
  data() {
    return {
      preloader: false,
      projectid: 0,
      newbimbox: false,
      compareimage1: image1,
      compareimage2: image2,
      taskdata: [],
      planStartdate: "",
      planEnddate: "",
      actualStartdate: "",
      actualEnddate: "",
      oplanStartdate: "",
      oplanEnddate: "",
      oactualStartdate: "",
      oactualEnddate: "",
      see_actual: false,
      see_planned: false,
      alert: false,
      text: "",
      naviPlanStartdate: "",
      naviPlanEnddate: "",
      selectedItem: 0,
      prefix: "",
      cam_prefix: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      select1: "13",
      select2: "13",
      currentTaskId: 0,
      userdata: "",
      useraccess: false,
      imageOnearror: false,
      imageTwoarror: false,
      show_1img360: false,
      show_2img360: false,
      checkcam360: false,
      current_cam: {},
      lock: false,
      hfov: 75,
      yaw: 0,
      pitch: 0,
      draggable: true,
      mouseZoom: true,
      doubleClickZoom: true,
      showZoom: true,
      showFullscreen: true,
      showlockbtn:false,
      Local :locals,  
      lang : {},
    };
  },
  mounted() {
    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.prefix = currentProject.prefix;
    this.initViewer();
    this.current_cam = this.$store.state.currentCamera;
    if (this.current_cam.cam_360 == 1) {
      console.log("Current cam data", this.current_cam);
      this.checkcam360 = true;
    } else {
      this.checkcam360 = false;
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      // console.log("Current data get",this.$store.state.currentCamera)
      this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.projectid = currentProject.id;
      this.prefix = currentProject.pro_dir;
      this.getTaskData();
      this.current_cam = this.$store.state.currentCamera;
      if (this.current_cam.cam_360 == 1) {
        console.log("Current cam data", this.current_cam);
        this.checkcam360 = true;
      } else {
        this.checkcam360 = false;
      }
    },
  },
  computed: {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      this.preloader = true;
      this.getTaskData();
      // this.getSignUrlImage();
    },
    movetotab: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    getTaskData() {
      this.preloader = true;
      axios({
        url: "/project/" + this.projectid + "/get-task ",
        method: "GET",
      })
        .then((resp) => {
          this.preloader = false;
          console.log("Resp", resp);
          this.taskdata = resp.data.success;
          this.selectedTask(resp.data.success[0]);
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    },
    selectedTask(data) {
      console.log("Selecte data = > ", data);
      this.oplanStartdate = data.base_start_date;
      this.oplanEnddate = data.base_end_date;
      this.oactualStartdate = data.actual_start_date;
      this.oactualEnddate = data.actual_end_date;
      this.currentTaskId = data.id;
      this.planStartdate = this.time_convertion(data.base_start_date);
      this.planEnddate = this.time_convertion(data.base_end_date);

      if (data.actual_start_date) {
        this.actualStartdate = this.time_convertion(data.actual_start_date);
      } else {
        this.actualStartdate = "";
      }

      if (data.actual_end_date) {
        this.actualEnddate = this.time_convertion(data.actual_end_date);
      } else {
        this.actualEnddate = "";
      }

      this.naviPlanStartdate = this.date_convertion(data.base_start_date);
      this.naviPlanEnddate = this.date_convertion(data.base_end_date);

      console.log(
        "this.actualStartdate",
        this.actualStartdate || this.actualEnddate
      );

      if (this.actualStartdate || this.actualEnddate) {
        this.see_actual = true;
        console.log(this.see_actual);
      } else {
        console.log("else", this.see_actual);
        this.see_actual = false;
        console.log(this.see_actual);
      }

      this.getFirstImage();
      this.getSecondImage();
    },
    seeActualImage() {
      this.see_planned = true;
      this.see_actual = false;
      this.getSignUrlImage(this.actualStartdate, "1", "13_00");
      this.getSignUrlImage(this.actualEnddate, "2", "18_00");
    },
    seePlaneImage() {
      this.see_actual = true;
      this.see_planned = false;
      this.getSignUrlImage(this.planStartdate, "1", "13_00");
      this.getSignUrlImage(this.planStartdate, "2", "18_00");
    },
    date_convertion(data) {
      var asd = data.split(" ");
      var asddmmyy = asd[0].split("-");

      var date = asddmmyy[2] + "/" + asddmmyy[1] + "/" + asddmmyy[0];

      return date;
    },
    time_convertion(data) {
      var asd = data.split(" ");
      var asddmmyy = asd[0].split("-");
      var ashh = asd[1].split(":");

      var ashrs = ashh[0];
      var asmin = ashh[1];

      var ashrs_time = this.pad(ashrs) + ":" + this.pad(asmin) + " AM";

      if (ashrs > 12) {
        var c_time = ashrs - 12;

        ashrs_time = this.pad(c_time) + ":" + this.pad(asmin) + " PM";
      }

      var times =
        asddmmyy[2] + "/" + asddmmyy[1] + "/" + asddmmyy[0] + " " + ashrs_time;

      return times;
    },
    pad(d) {
      return d < 10 &&
        d != "00" &&
        d != "01" &&
        d != "02" &&
        d != "03" &&
        d != "04" &&
        d != "05" &&
        d != "06" &&
        d != "07" &&
        d != "08" &&
        d != "09"
        ? "0" + d.toString()
        : d.toString();
    },
    getFirstImage() {
      //var datapart = this.planStartdate.split(" ");

      //    var dataitems =  datapart[1].split(":");

      var odatapart = this.oplanStartdate.split(" ");

      var odataitems = odatapart[1].split(":");

      console.log("o", this.oplanStartdate);

      console.log("new", this.planStartdate);

      var d1 = odataitems[0] + "_" + odataitems[1];
      console.log("filesdata", odataitems[0]);
      if (odataitems[0] == "00") {
        d1 = "13_00";
      }

      this.getSignUrlImage(this.planStartdate, "1", d1);
    },
    getSecondImage() {
      // var datapart = this.planEnddate.split(" ");

      var odatapart = this.oplanEnddate.split(" ");

      var odataitems = odatapart[1].split(":");

      console.log("o", this.oplanStartdate);

      console.log("new", this.planStartdate);

      var d2 = odataitems[0] + "_" + odataitems[1];
      console.log("filesdata", odataitems[0]);
      if (odataitems[0] == "00") {
        d2 = "13_00";
      }

      this.getSignUrlImage(this.planEnddate, "2", d2);
    },
    getSignUrlImage(date, index, hrs) {
      this.preloader = true;

      console.log("date == >", date);

      var datapart = date.split(" ");
      var h = hrs.split("_");

      if (h.length == 1) {
        hrs = hrs + "_00";
      }

      var dataitems = datapart[0].split("/");
      //2021_05_11_08_39_00.jpg
      var filename =
        dataitems[2] +
        "_" +
        dataitems[1] +
        "_" +
        dataitems[0] +
        "_" +
        hrs +
        "_00.jpg";

      var key = this.cam_prefix + "/" + filename;

       var filename2 =
        dataitems[2] +
        "_" +
        dataitems[1] +
        "_" +
        dataitems[0] +
        "_" +
        hrs +
        "_10.jpg";

      var key2 = this.cam_prefix + "/" + filename2;

      var p = {
        key: key,
        key2: key2,
        exp: "30 minutes",
        project_id : this.projectid
      };
      axios({
        url: "/get_sign_url_by_key_compare_img",
        params: p,
        method: "GET",
      })
        .then((resp) => {
          console.log("Resp image url", resp);
          if (resp.data.success.info) {
            if (index == 1) {
              this.compareimage1 = resp.data.success.url;
              this.imageOnearror = false;
              this.show_1img360 = false;
              this.preloader = false;
            } else {
              this.showlockbtn = true;
              this.compareimage2 = resp.data.success.url;
              this.imageTwoarror = false;
              this.show_2img360 = false;
              
              var v = this;
              setTimeout(function () {
                v.preloader = false;
              }, 5000);
            }
            
          } else {
            this.alert = true;
            this.alertmessage = this.lang.imagenotFound;
            this.alertType = "error";
            this.preloader = false;
            if (index == 1) {
              this.showlockbtn = false;
              this.compareimage1 = image1;
              this.imageOnearror = true;
              this.show_1img360 = true;
            } else {
              
              this.compareimage2 = image2;
              this.imageTwoarror = true;
              this.show_2img360 = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          this.compareimage1 = image1;
          this.compareimage2 = image2;
        });
    },
    hourlyImages(index) {
      if (index == 1) {
        this.getSignUrlImage(this.naviPlanStartdate, "1", this.select1);
      } else {
        this.getSignUrlImage(this.naviPlanEnddate, "2", this.select2);
      }
    },
    saveActualStartDate() {
      this.preloader = true;

      var d = this.naviPlanStartdate.split("/");

      var saved =
        d[2] + "-" + d[1] + "-" + d[0] + " " + this.select1 + ":00:00";

      axios({
        url: "/project/" + this.projectid + "/save-actual-date",
        params: {
          actual_date: saved,
          id: this.currentTaskId,
        },
        method: "GET",
      })
        .then((resp) => {
          this.preloader = false;
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.StartdateSave; 
          this.alertType = "success";
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    },
    saveActualEndDate() {
      this.preloader = true;

      var d = this.naviPlanEnddate.split("/");

      var saved =
        d[2] + "-" + d[1] + "-" + d[0] + " " + this.select2 + ":00:00";

      axios({
        url: "/project/" + this.projectid + "/save-actual-end-date",
        params: {
          actual_date: saved,
          id: this.currentTaskId,
        },
        method: "GET",
      })
        .then((resp) => {
          this.preloader = false;
          console.log(resp);
          this.alert = true;
          this.alertmessage = this.lang.EnddateSave;
          this.alertType = "success";
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    },
    navigation(days, index) {
      // this.naviPlanStartdate

      this.preloader = true;

      var cd;
      if (index == 1) {
        cd = this.naviPlanStartdate.split("/");
      } else {
        cd = this.naviPlanEnddate.split("/");
      }

      var xm = cd[1];

      var xd = cd[0];

      var xy = cd[2];

      //   alert(clicks);

      var zx = new Date(xm + "/" + xd + "/" + xy);
      console.log(zx);

      var newdate = new Date(zx);

      console.log("current date", newdate);

      if (days == "plusOne") {
        newdate.setDate(newdate.getDate() + 1);
      } else if (days == "plusSeven") {
        newdate.setDate(newdate.getDate() + 7);
      } else if (days == "minusSeven") {
        newdate.setDate(newdate.getDate() - 7);
      } else if (days == "minusOne") {
        newdate.setDate(newdate.getDate() - 1);
      }

      console.log("new date", newdate);

      var dd = newdate.getDate();
      var mm = newdate.getMonth() + 1;
      var y = newdate.getFullYear();

      dd = dd.toString();
      mm = mm.toString();

      dd = this.pad(dd);
      mm = this.pad(mm);
      //alert(dss);

      var someFormattedDate = dd + "/" + mm + "/" + y;

      if (index == 1) {
        this.naviPlanStartdate = someFormattedDate;
        this.getSignUrlImage(someFormattedDate, "1", this.select1);
      } else {
        this.naviPlanEnddate = someFormattedDate;
        this.getSignUrlImage(someFormattedDate, "2", this.select2);
      }
    },
    lockScreens() {
      console.log("Lock Screen");

      var a = this.$refs.panaromascreen;

      console.log("Viewer", a);

      this.lock = !this.lock;
      this.hfov = 75;
      this.yaw = 0;
      this.pitch = 0;
      this.draggable = false;
      this.mouseZoom = false;
      this.doubleClickZoom = false;
      this.showZoom = false;
      this.showFullscreen = false;
      console.log("lock screen", this.lock);
      if (this.lock == true) {
        this.draggable = false;
      } else {
        this.draggable = true;
      }
      a.showControls = false;

      this.imagepanaroma =
        this.slides[this.selected[this.selected.length - 2]].mediapath;
      this.compatedatefirst =
        this.slides[this.selected[this.selected.length - 2]].uploadat;

      //  console.log(this.selected.length);

      this.secondimagepanaroma =
        this.slides[this.selected[this.selected.length - 1]].mediapath;
      this.compatedate =
        this.slides[this.selected[this.selected.length - 1]].uploadat;
    },
    panUp() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setPitch(a.viewer.getPitch() + 20);
      b.viewer.setPitch(b.viewer.getPitch() + 20);
    },

    panDown() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setPitch(a.viewer.getPitch() - 20);
      b.viewer.setPitch(b.viewer.getPitch() - 20);
    },
    panLeft() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setYaw(a.viewer.getYaw() - 20);
      b.viewer.setYaw(b.viewer.getYaw() - 20);
    },
    panRight() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setYaw(a.viewer.getYaw() + 20);
      b.viewer.setYaw(b.viewer.getYaw() + 20);
    },
    panIn() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setHfov(a.viewer.getHfov() - 20);
      b.viewer.setHfov(b.viewer.getHfov() - 20);
    },
    panOut() {
      var a = this.$refs.panaromascreen;
      var b = this.$refs.panaromascreen2;

      a.viewer.setHfov(a.viewer.getHfov() + 20);
      b.viewer.setHfov(b.viewer.getHfov() + 20);
    },
    loaded() {
            var v = this;
            //this.$refs.zoomer.zoomIn(1);
            setTimeout(function () {
                v.preloader = false;
            }, 10);
        },
  },
};
</script>

<style>
/* .stepper_outer {
    display: flex;
    width: 100%;
    flex-: center;
    justify-content: space-between;
    margin-bottom: 33px;
}

.steper_item {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    z-index: 6;
  width: 95%;
  cursor: pointer;
}

.itcons {
    width: 95%;
    height: 50px;
    
    border-radius:0;
    background: #292929;
    color: #fff;
    text-align: center;
    line-height: 47px;
    margin: auto;
    margin-bottom: 11px;
    border: 1px solid #ffc400;
    transition: 0.5s;
}

.itcons i {
    color: #ffc400 !important;
}

.active .itcons {
  
    height: 50px;
    background: #ffc400;
    border: 2px solid #000;
    box-shadow: 0 0 15px 5px #0000005c;
    transition: 0.5s;
    line-height: 47px;
}

.active .itcons i {
    color: #000 !important;
}

.active .steperItem {
    color: #000000 !important;
    text-shadow: 0px 1px 20px #000;
    font-weight: 500;
}

.stepper_outer {
    padding: 0 19px;
    position: relative;
}

.stepper_outer:after {
    content: "";
    display: block;
    height: 2px;
    background: #000;
    width: 88%;
    position: absolute;
    left: 58px;
    top: 26px;
    z-index: 1;
}

.centerform {
    text-align: center;
    padding: 51px;
} */

.formcard {
  min-height: 92vh;
}

.listbox {
  max-height: 100vh;
  overflow: auto;
}

.vci-container img {
  border: 1px solid #1f1f1f45;
}

span.palandate {
  width: 40%;
  text-align: center;

  float: left;
  color: #595858;
}

span.palandate.right {
  float: right;
  width: 40%;
  text-align: center;
}

.plann_buttons {
  padding: 13px 12px;
  background: #fac003;
  color: #000 !important;
  font-weight: bold;
  text-decoration: none;
  width: 20%;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  box-shadow: 1px 1px 20px 1px #7b7b7b;
  border-radius: 5px;
  float: left;
}
.dbuttons {
  padding: 13px 12px;
  background: #ffffff;
  color: #000 !important;
  font-weight: bold;
  text-decoration: none;
  width: 20%;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  box-shadow: 1px 1px 20px 1px #7b7b7b;
  border-radius: 5px;
}

span.actualdate {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  float: left;
  color: #595858;
}

.navi {
  color: #f8bb03 !important;
  background: #000;
  cursor: pointer !important;
  margin: 0 3px;
}
.houseselecrt {
  width: 75px;
  padding: 0 !important;
  position: relative;
  right: 38px;
  top: -9px;
  float: right;
}
.navibar {
  text-align: center;
  position: relative;
  padding-top: 50px;
}
.listitemicon {
  margin: 8px 0;
}
.cefull360right {
  display: block;
  width: 100%;
  height: 55vh;
  background: #000000ab;
  /*  position: absolute; */
}
.cezzoomerright {
  display: block;
  width: 70%;
  /* width:50%; */
}
.cefull360left {
  display: block;
  width: 100%;
  height: 55vh;
  background: #000000ab;
  /* position: absolute; */
}
.cezzoomerleft {
  position: absolute;
  left: 50%;
  /* width:50%; */
  display: inline-block;
  display: block;
  width: 35%;
  height: 70%;
  background: #000000ab;
  position: absolute;
  top: 16.5%;
  left: 65%;
}
.stepper_outer {
  z-index: 0;
}
.clayer {
  display: block;
  position: fixed;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100vh;
  background: #ccc;
  opacity: 0;
  z-index: 10;
}

#cecontrols {
  position: absolute;
  top: 65vh;
  z-index: 20;
  text-align: center;
  width: 100%;
  padding-bottom: 3px;
  left: 0%;
}
.lockbtn {
  position: absolute;
  top: 30vh;
  right: 48%;
  z-index: 12;
  opacity: 0.5;
}
</style>

import enLocals from './Locals/en'
import spLocals from './Locals/sp'
import frLocals from './Locals/fr'
import arLocals from './Locals/ar'
import hiLocals from './Locals/hi'
export const locals = {
    English: enLocals, // English Language
    Arabic: arLocals, // Arabic Language
    Hindi: hiLocals, // Hindi Language
    Spanish : spLocals, // Hindi Language
    French: frLocals, // Hindi Language

    Languages: [
        'English',
        'Arabic',
        'French',
        'Spanish',
        'Hindi',
    ]
}

<template>
  <div class="slides">
    <Preloader v-if="preloader" />
    <v-snackbar
      v-model="snackbarError"
      center
      top
      timeout="10000"
      type="success"
    >
      {{ snackbarErrorText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbarError = false">
          {{ lang.Ok }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-carousel
      :cycle="cycle"
      show-arrows-on-hover
      hide-delimiter-background
      height="100vh"
    >
      <v-carousel-item
        v-for="(CamLatestImage, i) in CamLatestImages"
        :key="i"
        :src="CamLatestImage.src"
        contain
      >
        <div class="carousel-text">
          {{ CamLatestImage.project_name }} - {{ CamLatestImage.cam_serial }}
        </div>
      </v-carousel-item>
      <v-switch v-model="cycle" :color="amber" inset></v-switch>
    </v-carousel>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader
  },
  data() {
    return {
      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",

            usertype: "1",
            projects: {},
            useraccess: false,
            userinfo: false,
            dialog: false,
            ustatus: '',
            param_up: {},
            snackbarError: false,
            snackbarErrorText: "Too many projects to run slideshow",
            Local: locals,
            lang: {},
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],
            CamLatestImages: [],
            timer: '',
            cycle: true,

        };
    },
    mounted() {
        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
        this.initViewer();
        this.getPorjectsImages();
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
        if (this.userdata.user_code == 2 || this.userdata.user_code == 0 || this.userdata.user_code == 1) {
            this.useraccess = true
        } else {
            this.$router.push('/')
        }
        if (this.userdata.free_access == 0) {
            this.useraccess = true;
        } else {
            console.log("Redirect to dashboard");
        }
    },
    watch: {
        "$store.state.currentCamera": function () {
            // console.log("Current data get",this.$store.state.currentCamera)
        },
    },
    created() {
        this.getPorjectsImages();
        this.timer = setInterval(this.getPorjectsImages, 15 * 60 * 1000);
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        initViewer() {
            this.preloader = false;
        },
        getPorjectsImages: function () {
            this.preloader = true;
            axios({ url: "/projects/cameras/images", method: "GET" })
                .then((resp) => {
                    if (resp.status == 504) {
                        this.snackbarError = true;
                    }
                    console.log("images", resp.data.success);
                    this.CamLatestImages = resp.data.success;
                    this.preloader = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style>
.v-application .carousel-text {
  font-weight: 300;
  letter-spacing: -0.0083333333em;
  color: #ffffff;
  position: absolute;
  font-weight: bold;
  bottom: 0;
  background: #000000d4;
  padding: 6px 14px;
  margin-left: 10%;
}
html {
  overflow-y: auto;
}
.slides {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.slides .v-image__image--contain {
  background-size: 80% 100%;
  background-position: bottom center !important;
}
.slides .v-input--selection-controls.v-input {
  flex: 0 1 auto;
  position: absolute;
  bottom: 0;
  margin-left: 48%;
  color: black;
}
.slides .v-input--switch__track {
  background-color: #000;
}
.slides .v-input--switch__thumb {
  background-color: #ebb234;
}
.slides .v-btn--icon.v-size--small .v-icon,
.v-btn--fab.v-size--small .v-icon {
  color: #000;
}
.slides .v-carousel--hide-delimiter-background .v-carousel__controls {
  margin-bottom: 3%;
}
</style>

<template>
<div>
    <Preloader v-if="preloader" />
      
    <v-row class="mobilereverse">
       <v-col cols="12" md="12" class="desktop">
            <v-btn class="ma-4 uploadfile"
                v-if="materialeditaccess"
              @click="importFile = true"
              type="submit"
              color="mylightbutton">
            {{lang.UploadFiles}} 
              <v-icon right >mdi-plus-thick</v-icon>
          </v-btn>
         </v-col>
        <v-col cols="12" md="3" xs="12">
           
                      <v-card
    max-width="300"
    
    class="mx-auto"
  >
    <v-toolbar
      color="#000"
      dark
      @click="addNewHeading()"
     
    >
    
      <span class="ttitle" >{{lang.AddNewMaterialHeading}} </span>

      <v-spacer></v-spacer>

      <v-btn icon v-if="materialeditaccess">
        <v-icon>mdi-plus-box-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list
      two-line
      max-height="500"
    >
      <v-list-item
        v-for="item in headings"
        :key="item.id"
        :class="item.id == activeHeadingid ? 'activeitem' : 'divider'"
      

      >
       
        <v-list-item-content @click="getMaterialSubHeading(item.id)">
           <v-list-item-title v-text="item.material_heading"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="getMaterialSubHeading(item.id)">
            <v-icon color="black lighten-3">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-list-item-action>
         <v-list-item-action v-if="materialeditaccess">
          <v-btn icon @click="editHeading(item)">
            <v-icon color="black lighten-3">mdi-pencil-box-multiple</v-icon>
          </v-btn>
        </v-list-item-action>
         
      </v-list-item>
    </v-list>
  </v-card> 
        </v-col>    

         <v-col cols="12" xl="3" lg="3" md="3" xs="12">
           
                      <v-card
    max-width="300"
    max-height="500"
    class="mx-auto"
    v-if="activeHeadingid !=='' "
  >
    <v-toolbar
        
      color="#000"
      dark
          @click="newsubHeading()"
    >
    
      <span class="ttitle">{{lang.AddNewSubHeading}} </span>

      <v-spacer></v-spacer>

      <v-btn icon v-if="materialeditaccess">
        <v-icon>mdi-plus-box-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list
      two-line
      max-height="500"
    >
      <v-list-item
        v-for="item in subheadings"
        :key="item.id"
        :class="item.id == activeSubHeadingid ? 'activeitem' : 'divider'"
      >
       
        <v-list-item-content @click="getMaterialName(item.id)">
           <v-list-item-title v-text="item.subheading"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="getMaterialName(item.id)">
            <v-icon color="black lighten-3">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-list-item-action>
         <v-list-item-action v-if="materialeditaccess">
          <v-btn icon @click="editsubHeading(item)">
            <v-icon color="black lighten-3">mdi-pencil-box-multiple</v-icon>
          </v-btn>
        </v-list-item-action>
         
      </v-list-item>
    </v-list>
  </v-card> 
        </v-col> 

         <v-col cols="12" xl="3" lg="3" md="3" xs="12">
           
                      <v-card
    max-width="300"
    max-height="500"
    class="mx-auto"
     v-if="activeSubHeadingid !=''"
  >
    <v-toolbar
        
      color="#000"
      dark
      @click="editMaterial"
    >
    
      <span class="ttitle">{{lang.AddNewMaterial}}</span>

      <v-spacer></v-spacer>

      <v-btn icon v-if="materialeditaccess">
        <v-icon>mdi-plus-box-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list
      two-line
      max-height="500"
    >
      <v-list-item
        v-for="item in materialnames"
        :key="item.id"
        :class="item.id == activematerialid ? 'activeitem':'divider'"
      >
        <v-list-item-content @click="selectMaterial(item.id)" >
           <v-list-item-title v-text="item.materila_name"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="selectMaterial(item.id)">
            <v-icon color="black lighten-3">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-list-item-action>
       
         
      </v-list-item>
    </v-list>
  </v-card> 
        </v-col> 
           <v-col cols="12" xl="3" lg="3" md="3" xs="12"  class="mx-auto "  v-if="unitMaterial.materila_name" >
             <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitMaterialUpdate">

                    <v-container>
                        <v-row>
                             <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="unitMaterial.materila_name" :label="lang.MaterialName" required></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="unitMaterial.unit" :label="lang.Unit" required></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="12">
                                <v-autocomplete
                                :rules="requerdRules"
                                :items="units"
                                :label="lang.Unit"
                                 required
                                 v-model="unitMaterial.unit"></v-autocomplete>
                            
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-select
                               v-model="unitMaterial.asset_type" 
                              :items="['Consumable', 'Asset']"
                              :label="lang.AssetType"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="unitMaterial.estimate_qty" :label="lang.Estimateqty"></v-text-field>
                            </v-col>
                      
                            <v-col cols="12" v-if="materialeditaccess">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit }}
                                </v-btn>
                                <v-btn @click="deleteMaterial" color="mydarkbutton">
                                    {{lang.Delete}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
              </v-col>
              <v-col cols="12" md="12" class="smalldevice">
            <v-btn class="ma-4 uploadfile"
                v-if="materialeditaccess"
              @click="importFile = true"
              type="submit"
              color="mylightbutton">
            {{lang.UploadFiles}} 
              <v-icon right >mdi-plus-thick</v-icon>
          </v-btn>
         </v-col>
       </v-row>        
    <v-snackbar v-model="alert" top right timeout="3000" :type="alertType">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
               {{ lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

     <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="headingForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.MaterialHeading}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewsHeading">
                    <v-container>
                        <v-row>
                           <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="editMaterialdata.material_heading" :label="lang.MaterialHeading" required></v-text-field>
                          
                             
                                <v-checkbox
                                  v-model="editMaterialdata.status"
                                  :label="lang.Enable"
                                  class="ml-4"
                          ></v-checkbox>
                            </v-col>
                         
                            
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}} 
                                </v-btn>
                                <v-btn  class="mr-4" @click="deleteheading(editMaterialdata.id)" color="red darken-1">
                                    {{lang.Delete}}
                                </v-btn>
                                <v-btn class="rightclose" @click="headingForm = false" icon>
                                     <v-icon color="black lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>


     <v-dialog v-if="materialeditaccess" transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newheadingForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.MaterialHeading}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="newsHeading">
                    <v-container>
                        <v-row>
                           <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="materialdata.material_heading" :label="lang.MaterialHeading" required></v-text-field>
                            </v-col>
                         
                            
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}}
                                </v-btn>
                                
                                <v-btn class="rightclose" @click="newheadingForm = false" icon>
                                     <v-icon color="black lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

     <v-dialog  v-if="materialeditaccess" transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="subheadingForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.MaterialSubHeading}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="updatesubHeading">
                    <v-container>
                        <v-row>
                           <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="subheadingData.subheading" :label="MaterialSubHeading" required></v-text-field>
                                     <v-checkbox
                                  v-model="subheadingData.status"
                                  :label="lang.Enable"
                                  class="ml-4"
                          ></v-checkbox>
                            </v-col>
                            
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn  class="mr-4" @click="deleteSubheading()" color="red darken-1">
                                    {{lang.Delete}}
                                </v-btn>
                                <v-btn class="rightclose" @click="subheadingForm = false" icon>
                                     <v-icon color="black lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>


     <v-dialog  v-if="materialeditaccess" transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newsubheadingForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.MaterialSubHeading}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitnewsubHeading">
                    <v-container>
                        <v-row>
                           <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="subheading" :label="lang.MaterialSubHeading" required></v-text-field>
                                    
                            </v-col>
                            
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                   {{ lang.submit}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                              
                                <v-btn class="rightclose" @click="newsubheadingForm = false" icon>
                                     <v-icon color="black lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog  v-if="materialeditaccess" transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="materialForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Material </v-toolbar>
              <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewMaterilaItem">

                    <v-container>
                        <v-row>
                             <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="newMaterial.materila_name" :label="lang.MaterialName" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <!-- <v-text-field :rules="requerdRules" v-model="newMaterial.unit" :label="lang.Unit" required></v-text-field> -->
                                <v-autocomplete
                                :rules="requerdRules"
                                :items="units"
                                :label="lang.Unit"
                                 v-model="newMaterial.unit"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-select
                               
                               v-model="newMaterial.asset_type" 
                              :items="['Consumable', 'Asset']"
                              :label="lang.AssetType"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field type="number" v-model="newMaterial.estimate_qty" :label="lang.Estimateqty" required></v-text-field>
                            </v-col>
                      
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="materialForm = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
     <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="importFile">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.ImportMaterial}}  </v-toolbar>
                <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="submit">

                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <input type="file" @change="onChange" class="fileuploads" />

                                 <xlsx-read :file="file" >
                                    <xlsx-json :sheet="selectedSheet">
                                        <template #default="{collection}">
                                            <div ref="filedatamateril">
                                                {{ collection }}
                                            </div>
                                        </template>
                                    </xlsx-json>
                                </xlsx-read>
                            </v-col>
                            
                            <v-col cols="12" md="12">

                                <v-btn class="mr-4" type="submit" color="mylightbutton">
                                    {{lang.submit}}
                                </v-btn>

                            </v-col>
                            <v-col cols="12" md="12">
                                <a href="/Opticvyumaterialmaster.xls" style="color:#000; text-decoration:none">
                                    <h3 style="color:#000; text-decoration:none">
                                        <v-icon>mdi-download-circle </v-icon>
                                        Download Sample.xls file
                                    </h3>
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import { utils, read} from 'xlsx'
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";

import { locals } from "../../utils/locals";

export default {
    components: {
        Preloader,
          XlsxRead,
        XlsxJson
    },

    computed: {
       
    },
    name: "Addentry",
    data() {
        return {
            file: null,
            headingForm : false,
            subheadingForm : false,
            materialForm : false,
            requerdRules: [
            v => !!v || this.lang.required,
            ],
             numberOnlyRules: [
                v => !!v || this.lang.required,
                v => /^[0-9]*[1-9][0-9]*$/.test(v) || this.lang.integetrequre,
            ],
            alert: false,
            alertmessage: '',
            alertType: "error",
            preloader: false,
            project_name: 'ProjectName',
            projectid: 0,
            headings : [],
            subheadings : [],
            materialnames : [],
            unitMaterial : {},
            files: [
        {
          color: 'blue',
          icon: 'mdi-clipboard-text',
          subtitle: 'Jan 20, 2014',
          title: 'Vacation itinerary',
        },
        {
          color: 'amber',
          icon: 'mdi-gesture-tap-button',
          subtitle: 'Jan 10, 2014',
          title: 'Kitchen remodel',
        },
      ],
      folders: [
        {
          subtitle: 'Jan 9, 2014',
          title: 'Photos',
        },
        {
          subtitle: 'Jan 17, 2014',
          title: 'Recipes',
        },
        {
          subtitle: 'Jan 28, 2014',
          title: 'Work',
        },
      ],
       activeHeadingid : '',
       activeSubHeadingid : '',
       activematerialid : '',
       
       activecolor : "#000",
       dividercolor : "#fff",
       send : false,
       valid : false,
       newMaterial : {
           materila_name : '',
           unit : '',
           asset_type : '',
           estimate_qty : '',
           subheading_id : ''
       },
       heading : '',
       subheading : '',
       editMaterialdata : {
        headingid: "",
        status: "",
        material_heading : "",
        id : ""
       },
       materialdata : {
        userid: "",
        projectid: "",
        material_heading : "",
       },
       newheadingForm : false,
       subheadingData : {
              id: '',
              status: '',
              subheading: '',
              },
         newsubheadingForm : false,     
         importFile : false,
         filedata : {},
         materialeditaccess: false,
         Local :locals,  
        lang : {},
         units : [
             'Bags',
             'CFT',
'Chain',
'cm',
'Cubic Mt.',
'Ft.',
'Gram',
'Inch',
'kg',
'kilogram',
'kilometer',
'Litre',
'ltr',
'Megatonne',
'Meters',
'Metric Ton',
'Milligram',
'mm',
'mtr.',
'Nos.',
'Ounce',
'Pairs',
'Pics',
'Pkt',
'Pound',
'Roll',
'Set',
'sq. ft',
'sq. mt',
'String',
'Tonne',
'Unit',
'Yard'],
        };
    },
    created() {
         var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng]; 

        this.userdata = JSON.parse(localStorage.getItem('userdata'));

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;
        this.project_name = currentProject.project_name;
        this.initViewer();
        if(currentProject.material == 1){
          this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
        if (this.userdata.material_access == 1) {
            this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
         if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if(this.userdata.user_code == 2){
                this.materialeditaccess = true;
        }
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.project_name = currentProject.project_name;
            this.getMaterial();
            if(this.userdata.user_code == 2){
                this.materialeditaccess = true;
                }
        }
    },
    // mounted() {
    //     this.userdata = JSON.parse(localStorage.getItem('userdata'));
    //     if (this.userdata.open_access == 1) {
    //         this.$router.push('/dashboard')
    //     }
    // },
    methods: {
      
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = true;
            this.getMaterial();
        },
        getMaterial(){

           this.preloader = true
            var projectid = this.projectid;
            console.log(projectid);
          	var filter = '{"where":{"projectid":'+projectid+'}}';
	       var urlfilter = encodeURI(filter); 
            axios({
                    url: 'material/heading',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp.data);
                    this.headings = JSON.parse(resp.data.success);
                    this.preloader = false;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
         getMaterialSubHeading(id) {

            this.activeHeadingid = id;
            this.subheadings = [];
            this.materialnames = [];
            this.unitMaterial = {},

            // var optionText = event.target.options[event.target.options.selectedIndex].text
             this.preloader = true
            var mhfilter = '{"where": { "material_heading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/subheading',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.subheadings = JSON.parse(resp.data.success);
                    //this.getMaterialName()
                        this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getMaterialName(id) {
             this.activeSubHeadingid = id;
            this.materialnames = [];
            this.unitMaterial = {},
            //   var id = this.updateData.sub_heading_id;
             console.log("subheadign id is", id)
            this.preloader = true
            var mhfilter = '{"where": { "subheading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/materials',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    //console.log("materials", JSON.parse(resp.data.success))
                    this.materialnames = JSON.parse(resp.data.success) 
                    this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
         selectMaterial(id) {
            
             this.activematerialid = id;
           // this.unitMaterial = {},
            //var id = this.updateData.material_name_i
            this.preloader = true
            console.log("materialid", id)
            axios({
                    url: 'material/unitmaterial',
                    method: 'get',
                    params: {
                        materialid: id
                    },
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                   this.unitMaterial = resp.data;
                    this.preloader = false;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        editMaterial(item){
              console.log(item);
              this.materialForm = true;
             
               //this.editMaterialdata=item;
              

        },
        editsubHeading(item){
          this.subheadingForm = true;
          this.subheadingData = item;
          this.subheadingData.status = item.status == 1 ? true : false;
          console.log(item);
        },
        editHeading(item){
           console.log(item);
           this.editMaterialdata = item;
                  
         this.editMaterialdata = {
            headingid: item.id,
            status: item.status == 1 ? true : false,
            material_heading : item.material_heading,
            id : item.id
           }

           this.headingForm = true;
        },
        addNewHeading(){
           this.newheadingForm = true;  
        },
        submitNewsHeading(){
           this.send = true;
            this.editMaterialdata.status = this.editMaterialdata.status ? 1 : 0,
            axios({
                    url: '/material/heading/update',
                    method: 'get',
                    params: this.editMaterialdata,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.headingForm = false;

                    this.alert = true;
                    this.alertmessage = this.lang.HeadingUpdateSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        deleteheading(id){

           var c =confirm(this.lang.confirmdelteheading)
           if(c){
             
             console.log("delete Item", id)
             this.send = true;
            this.editMaterialdata.status = this.editMaterialdata.status ? 1 : 0,
            axios({
                    url: 'material/heading/delete',
                    method: 'get',
                    params: this.editMaterialdata,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                  
                    this.headingForm = false;

                    this.alert = true;
                    this.alertmessage = this.lang.HeadingdeletedSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
             
           }
        },
         newsHeading(){
               this.send = true;
               this.materialdata.userid  = this.userdata.id
               this.materialdata.projectid  = this.projectid

               console.log("this.materialdata", this.materialdata,);
            axios({
                    url: '/material/heading/add',
                    method: 'get',
                    params: this.materialdata,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.newheadingForm = false;

                    this.alert = true;
                    this.alertmessage = this.lang.HeadingAddedSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        updatesubHeading(){
              //   this.subheadingData ;
              //   this.materialdata.userid  = this.userdata.id
              //  this.materialdata.projectid  = this.projectid

               console.log("this.materialdata", this.materialdata);
            axios({
                    url: '/material/subheading/update',
                    method: 'get',
                    params: this.subheadingData,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.subheadingForm = false;
                    this.alert = true;
                    this.alertmessage = "Sub-Heading Update Successfully.";
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        deleteSubheading(){
              //   this.subheadingData ;
              //   this.materialdata.userid  = this.userdata.id
              //  this.materialdata.projectid  = this.projectid
               var conf = confirm("Confirm delete item ?")

            if(conf){
               console.log("this.materialdata", this.materialdata);
            axios({
                    url: '/material/subheading/delete',
                    method: 'get',
                    params: this.subheadingData,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.subheadingForm = false;

                    this.alert = true;
                    this.alertmessage = this.lang.SubheadingdeleteSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            }
        },
        newsubHeading(){
           this.newsubheadingForm = true;
        },
        submitnewsubHeading(){
           // console.log("this.materialdata", this.materialdata);
          
            axios({
                    url: '/material/subheading/add',
                    method: 'get',
                    params: {
                        subheading : this.subheading,
                        material_heading_id : this.activeHeadingid
                    },
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.newsubheadingForm = false;
                    this.subheadings.push(resp.data)
                    this.alert = true;
                    this.alertmessage = this.lang.SubheadingAddedSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            
        },
        submitMaterialUpdate(){
           axios({
                    url: '/material/item/update',
                    method: 'get',
                    params: this.unitMaterial
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.newsubheadingForm = false;
                    this.alert = true;
                    this.alertmessage = this.lang.MaterialUpdateSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })

        },
        deleteMaterial(){
           var conf = confirm("Confirm delete item ?")

            if(conf){

           axios({
                    url: '/material/item/delete',
                    method: 'get',
                    params: this.unitMaterial
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.materialForm = false;
                    this.alert = true;
                    this.alertmessage = this.lang.MaterialDeleteSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                  
                    this.materialnames = [];
                    this.unitMaterial = {}
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            }

        },
        submitNewMaterilaItem(){
            this.newMaterial.subheading_id  = this.activeSubHeadingid;
           axios({
                    url: '/material/item/add',
                    method: 'get',
                    params: this.newMaterial
                })
                .then(resp => {
                    console.log("materialsUnit", resp);

                     this.materialnames.push(resp.data);
                    this.send = false;
                    this.materialForm = false;
                    this.alert = true;
                    this.alertmessage = this.lang.MaterialAddedSuccessfully;
                    this.alertType = "success"
                    this.getMaterial();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
            

        },
         onChange(event) {
            //this.file = event.target.files ? event.target.files[0] : null;

             var oFile = event.target.files ? event.target.files[0] : null;
              var sFilename = oFile.name;

                console.log(sFilename);

            var reader = new FileReader();
      //  var result = {};
              var self = this;
        reader.onload = function (e) {
            var data = e.target.result;
            data = new Uint8Array(data);
            var workbook = read(data, {type: 'array'});
            console.log(workbook);
            var result = {};
            workbook.SheetNames.forEach(function (sheetName) {
                var roa = utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
                if (roa.length) result[sheetName] = roa;
            });
            // see the result, caution: it works after reader event is done.
            console.log(result);
            
             self.filedata = result;
             console.log( "Onchange ", self.filedata);
        };
        reader.readAsArrayBuffer(oFile);
        },

         async submit() {
         //   let childEl = this.$refs.filedatamateril.innerHTML;
            // console.log(childEl);
              this.preloader = true;
              console.log( "onSubmit",this.filedata );
            var userdata = {
                filedata: this.filedata,
                 userid  : this.userdata.id,
                 projectid : this.projectid
            }
            
            console.log(userdata);
            axios({
                    url: '/material/import',
                    data: userdata,
                    method: 'post'
                })
                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = this.lang.Yourtaskuploadedsuccesfully;
                        this.alertType = "success"
                        this.getMaterial();

                    } else {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error"
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
    }
};
</script>

<style>
.remark {
    position: absolute;

    right: 0;
}

.remarkbox {
    position: relative;

}

.mySearchInventory {
    float: left;
    width: calc(100% - 298px);
}
/* .rightbutons {
  margin-top: 9px;
} */
.right_aligh {
    top: -51px;
    right: 13px;
    position: relative;
    float: right;
}
.center{
    text-align:center;
}
.cumulativebox{
    min-height: 344px;
    width:100%;
    text-align:center;
}
.daterangepicker {
    min-width: 750px !important;
}
.vue-daterange-picker.v-text-field__slot {
    width: 96%;
    margin-bottom: 6px;
}
.daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #565656;
    color: #fff;
}
.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    background: #000;
    left: 0;
    padding: 13px;
}
.submmitinventory {
    font-size: 14px;
    background: #fdc001;
    color: #000;
    padding: 12px 44px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
}
.submmitinventory:hover {
    color: #000;
}
.ttitle{
    font-size:15px;
    color:#fff;
}
.divider{
    border-bottom: 1px solid #f1eaea;
     cursor:pointer;
}
.v-list {
    overflow: auto;
}
.activeitem {
    background: #ffc0008c;
    cursor:pointer;
}
.rightclose {
    position: absolute;
    top: 13px;
    right: 8px;
}
.uploadfile{
  float:right;
}
</style>

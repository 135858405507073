<template>
    <div>
        <Preloader v-if="preloader" />
        <div class="mobilerotedouter">
            <img :src="rotate" class="mobileroted detect">
        </div>
        <v-row>
            <v-col cols="12" md="12" class="tohed">
    
            </v-col>
    
            <div class="megaMenus" :class="drawingToggle?'opendrawer':'closedrawer'">
                <v-row>
                    <v-btn fab x-small color="#fff" class="closeDrower" @click="closeMegamenu">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
    
                    <v-col v-for="n in drawings" :key="n.drawingid" :class="`d-flex child-flex drawingitem mega ${n.drawingid == activeDrawing.drawingid?'selected':''}`" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                        <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
                            <div class="drawingname">
                                {{n.name}}
                                <span class="progrssss">( {{n.progress}}%)</span>
    
                                <!-- <v-btn
                class="mx-2 removedrawing"
                fab
                x-small
                color="#ffc400"
                @click="removeDrawing(n.drawingid)"
                >
                <v-icon dark>
                   mdi-delete
                </v-icon>
                </v-btn> -->
    
                            </div>
                            <div class="drawingname dfooter">
                                <v-btn class="mx-2" fab x-small color="#fff">
                                    <v-icon dark>
                                        mdi-axis-z-rotate-clockwise
                                    </v-icon>
                                </v-btn>
                                {{n.numberofrotet}}
                                <v-btn class="mx-2" fab x-small color="#fff">
                                    <v-icon dark>
                                        mdi-camera
                                    </v-icon>
                                </v-btn>
                                {{n.numberofimages}}
                                <v-btn class="mx-2" fab x-small color="#fff">
                                    <v-icon dark>
                                        mdi-play
                                    </v-icon>
                                </v-btn>
                                {{n.numberofvideos}}
    
                            </div>
    
                        </v-img>
    
                    </v-col>
                </v-row>
            </div>
            <!-- <div class="enterinterior notios" v-if="usernativeInterior">
                <v-btn fab x-small color="#fff" class="closeDrower" @click="usernativeInterior = false">
                    <v-icon dark> mdi-close </v-icon>
                </v-btn>
                <v-btn class="mr-4 mobilebuttons notios" v-if="interior == 1" @click="goToModule('interior')" color="secondcolor ">
                    Capture Images
                </v-btn>
            </div> -->
            <v-progress-linear v-if="allavarage > 0" :value="allavarage" class='drawingprogressbar' color="amber" rounded height="15">
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear> 
    
            <v-col class="drawign_outerox" ref="resize" :cols="colum ? 6 : 12" >
                <div class="inerboxss" ref="inerboxss">
                    <v-col ref="draggableContainer" :class="`drawingxx ${paddingActive?'paddingadd':''}`" @mousedown="dragMouseDown" 
                     @touchstart="tdragMouseDown"
      @touchend="tdragMouseDown">
    
                        <vue-photo-zoom-pro :high-url="drawing.drawingpath" :width="200" :selector="magnify" type="circle" :scale="2">
                            <img :src="drawing.drawingpath" alt="Workplace" id="ble" class="map half" usemap="#workmap" :height="ImgHeight">
                        </vue-photo-zoom-pro>
    
                         <img :src="casmangle" id="ang" v-if="activet == 'panaroma' && colum" :class="angle ?'seeangle':'hideangle'" :style="{left: xf +'px', top: yf +'px',  transform: 'rotate('+anglez+'deg)' }">
                        <map name="workmap" id="mymap">
                            <area shape="circle" v-for="(d, index) in cordinates" href="#" :data-maphilight='d.fillcolor' :key="d.coordinateid" @click="openTooltip(d, index)" :title="d.progress" :coords="`${d.x},${d.y},${radius}`" alt="Corridor"> </map>
    
                        
                        <div id="moon" ref="moon" :class="[cordinatInfo ?'openTool': 'closetool', angle && activet == 'panaroma' && colum ?'low':'hiegth']" :style="{left: x +'px', top: y +'px'}">
    
                            <span class="closepop" id="closecordinate"> <i class="fa fa-times" aria-hidden="true"></i> </span>
                            <p class="circledata"> {{ openCoordinate.title }} <br> {{openCoordinate.progress}}% </p>
    
                            <span class="cmenus">
                                <span class="citem" :title="lang.Images360">
    
                                    <v-btn class="xxsmall" fab x-small color="#fff" @click="getNormalImages('panaroma')">
                                        <v-icon  x-small dark>
                                            mdi-axis-z-rotate-clockwise
                                        </v-icon>
    
                                    </v-btn>
                                    <p>{{openCoordinate.count360 === null ? 0 : openCoordinate.count360}}</p>
    
                                </span>
                                <span class="citem" :title="lang.NormalImages">
    
                                    <v-btn  class="xxsmall" fab x-small color="#fff" @click="getNormalImages('normal')">
                                        <v-icon x-small dark>
                                            mdi-camera
                                        </v-icon>
    
                                    </v-btn>
    
                                    <p>{{openCoordinate.countimages === null ? 0 : openCoordinate.countimages }}</p>
                                </span>
                                <span class="citem" :title="lang.Videos360">
    
                                    <p>{{openCoordinate.countvideos === null ? 0 : openCoordinate.countvideos}}</p>
                                    <v-btn class="xxsmall" fab x-small color="#fff" @click="getNormalImages('video')">
                                        <v-icon x-small dark>
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
    
                                </span>
                                <span class="citem">
                                </span>
                                <span class="citem close" :title="lang.Close" @click="cordinateclose()">
                                    <v-btn class="xxsmall" fab x-small color="#fff">
                                        <v-icon x-small dark>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </span>
    
                            </span>
    
                        </div>
                    </v-col>
                      <!-- <v-btn :class="`mx-2  drag-buttons ${zoom < 0.6 ? 'zoomd': ''}`" fab x-small color="#f8bb03" @mousedown="resize">
                            <v-icon dark>
                                mdi-square
                            </v-icon>
                        </v-btn>
                        <v-btn :class="`mx-2 drag-buttons mobileonly tabonly ${zoom < 0.6 ? 'zoomd': ''}`" fab x-small color="#f8bb03" @touchmove="resize">
                            <v-icon dark>
                                mdi-square
                            </v-icon>
                        </v-btn> -->
    
                        <!-- <v-btn class="mx-2 magnify-buttons" fab x-small color="#f8bb03" @click="magnifytogle" v-if="!sliderActive">
                    <v-icon dark>
                        {{magnify ? 'mdi-magnify-minus':'mdi-magnify-plus'}}
                    </v-icon>
                </v-btn>
    
                <v-btn class="mx-2 drawing-edit-buttons" title="Switch To Edit View" fab x-small color="#f8bb03" @click="goToDrawingdirect" v-if="this.userdata.interior_editor_access == 1">
                    <v-icon dark>
                       mdi-border-color
                    </v-icon>
                </v-btn> -->
    
                        <v-btn class="mx-2 drawing-plus" :disabled="zoom >= 4" title="Zoom In" fab x-small color="#fff" @click="goplus">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
    
                        <v-btn class="mx-2 drawing-minus" :disabled="zoom < 0.5" title="Zoom Out" fab x-small color="#fff" @click="gominus">
                            <v-icon dark>
                                mdi-minus
    
                            </v-icon>
                        </v-btn>
                    <div ref="resizss" class="resizeicon" @mousedown="resizebox"
                     @touchstart="resizeTuch"
      @touchend="resizeTuch"
      @touchmove="resizeTuch"        
                    >
                        <v-icon  color="#fff">
                            mdi-resize-bottom-right
                        </v-icon>
                    </div>
    
                     <v-btn ref="movess" class="mx-2 moveicon" fab x-small color="#fff" @mousedown="dragbox"
                      @touchstart="dragboxtuch"
                     @touchmove="dragboxtuch"  
                    >
                        <v-icon color="#000">
                            mdi-arrow-all
                        </v-icon>
                    </v-btn>
                    <!-- <div ref="movess" class="moveicon" @mousedown="dragbox">
                        <v-icon color="#000">
                            mdi-arrow-all
                        </v-icon>
                    </div> -->
               
            </div>
    </v-col>
    <v-col :cols="6" :class="`playerplase compareb autodesk ${autodeskcompare?'show':'hide'}`">
        <v-icon dark color="#000" @click="closeAutodesk()" class="closeautodesk">
            mdi-close
        </v-icon>
        <!-- <forge-vuer v-if="docloaded && !showadd" @documentLoadError="documentLoadError" :getAccessToken="myGetTokenMethodAsync" :urn="myObjectUrn" /> -->
        <div id="forgeViewertc" v-if="docloaded && !showadd"></div>
        <v-col cols="12" md="12" v-if="showadd">
            <div class="msgbox">
                <p>
                    You can integrate BIM model for comparing site images with BIM 3D model. Contact <a href="mailto:admin@opticvyu.com">admin@opticvyu.com </a> for more information
                </p>
            </div>
        </v-col>
    </v-col>
    <v-col :cols="(colum && autodeskcompare) || ( colum && imagenormalcompare) ? 6 : colum ? 12 : 12" v-if="colum" :class="`playerplase ${ autodeskcompare ? 'fulld':'halfd'}`">
        <div class="topbar"><span>{{ openCoordinate.title }}</span> <span> {{compatedatefirst }}</span></div>
        <v-zoomer v-if="activet == 'normal'" ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
            <img :src="imagenormal" style="object-fit: contain; width: 100%; height: 100%;">
    
        </v-zoomer>
    
        <!-- <v-btn fab x-small color="#fff" v-if="activet == 'panaroma'" class="addcomment" @click="addComment" :title="lang.AddComment">
            <v-icon dark>
                mdi-comment-plus
            </v-icon>
        </v-btn> -->
    
        <!-- <v-btn fab x-small color="#fff" v-if="activet == 'panaroma' && autodesk_viewer == 1 && first" class="autodeskviewer" @click="openautodeskviewFirst" title="Auto Desk Viewer">
            <v-icon dark>
                mdi-cube-scan
            </v-icon>
        </v-btn>
    
        <v-btn fab x-small color="#fff" v-if="activet == 'panaroma' && autodesk_viewer == 1 && !first" class="autodeskviewer" @click="openautodeskview" title="Auto Desk Viewer">
            <v-icon dark>
                mdi-cube-scan
            </v-icon>
        </v-btn> -->
    
        <v-btn fab color="red" v-if="activet == 'panaroma'  && imagenormalcompare " class="locks_reen" @click="lockScreens" :title="lang.Lock">
            <v-icon dark>
                {{ lock ? 'mdi-lock':'mdi-lock-open' }}
            </v-icon>
        </v-btn>
        <span @mouseup="onchanges">
            <v-pannellum :showFullscreen="showFullscreen" :showZoom="showZoom" :compass="true" :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom" :draggable="draggable" :hfov="hfov" :yaw="yaw" :pitch="pitch" v-if="activet == 'panaroma'" ref="panaromascreen" class="fullscreen" :src="imagepanaroma" :dynamic="true"></v-pannellum>
        </span>
        <video controls v-if="activet == 'video' && videotype == 'normal'" class="videoplay" >
            <source :src="pvideo"
                    type="video/mp4">
        </video>
         <div class="panaromovideos" v-if="activet == 'video' && videotype == '360Videos'">
        <Pano v-if="activet == 'video' && videotype == '360Videos'" class="videoplay" type='video' :source="pvideo">
        </Pano>
        </div>
        <v-icon dark color="#000" @click="fullscreenexit()" v-if="!sliderActive" class="exitbuttonsbb" style="z-index: 9;">
            mdi-close
        </v-icon>
        <div id="controls" v-if="lock">
            <div class="ctrl" id="pan-up" @click="panUp">&#9650;</div>
            <div class="ctrl" id="pan-down" @click="panDown">&#9660;</div>
            <div class="ctrl" id="pan-left" @click="panLeft">&#9664;</div>
            <div class="ctrl" id="pan-right" @click="panRight">&#9654;</div>
            <div class="ctrl" id="zoom-in" @click="panIn">&plus;</div>
            <div class="ctrl" id="zoom-out" @click="panOut">&minus;</div>
    
        </div>
    </v-col>
    
    <v-col cols="6" v-if="imagenormalcompare" class="playerplase too">
        <div class="topbar"><span>{{ openCoordinate.title }}</span> <span> {{compatedate }}</span></div>
        <v-zoomer v-if="activet == 'normal'" ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
            <img :src="secondimagenormal" style="object-fit: contain; width: 100%; height: 100%;">
    
        </v-zoomer>
        <v-pannellum :showFullscreen="showFullscreen" :showZoom="showZoom" :compass="true" :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom" :draggable="draggable" :hfov="hfov" :yaw="yaw" :pitch="pitch" v-if="activet == 'panaroma'" ref="panaromascreen2" class="fullscreen" :src="secondimagepanaroma"></v-pannellum>
    
        <v-icon dark color="#000" @click="closeCompare" class="exitbuttonscomapre" style="z-index: 9;">
            mdi-close
        </v-icon>
    </v-col>
    
    <v-col cols="6" v-if="commentopenImage" class="playerplase compareb" style="left:50%">
        <v-zoomer ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 100%; position: absolute; top: -47px;z-index: 9999;" pivot='image-center'>
            <img :src="secondimagenormal" style="object-fit: contain; width: 100%; height: 100%;">
    
        </v-zoomer>
    
        <v-icon dark color="#000" @click="commentopenImage = false" class="exitbuttonscomapre" style="z-index: 9999;">
            mdi-close
        </v-icon>
    </v-col>
    
    <!-- =========================== Task list Start container =============================-->
    
    <v-col :cols="expand ? 12 : 6" v-if="taskbox" class="taskbox" style="z-index: 202;">
        <v-img :src="colors" class="coloritem" @click="showcoloritem = !showcoloritem"></v-img>
        <div class="infoboxin" v-if="showcoloritem">
            <span class="itedmm"><span class="roundd delay"></span> {{lang.Delayed}}</span>
            <span class="itedmm"><span class="roundd criticl"></span> {{lang.Critical}}</span>
            <span class="itedmm"><span class="roundd complete"></span> {{lang.Complete}}</span>
            <span class="itedmm"><span class="roundd noprogress"></span> {{lang.Running}} </span>
        </div>
        <v-icon dark color="#000" @click="expand = !expand" class="expandtask">
            mdi-fullscreen
        </v-icon>
        <v-icon dark color="#000" @click="taskbox = false" class="exittask">
            mdi-close
        </v-icon>
    
        <v-select class="standertask mr-4" :items="tasktype" :label="lang.ImportTasks" item-value="task_type" item-text="task_type" dense solo outlined small v-if="showstanderoption" @change="selectTlistItem"></v-select>
    
        <v-btn color="#f8bb03" class=" mr-4" @click="getStanderType" v-if="tasks.length == 0"> {{lang.StandardTaskLists}} </v-btn>
    
        <v-btn color="#f8bb03" class="mr-4" @click="newTaskForm = true">
            <v-icon color="#000">
                mdi-plus
            </v-icon>
        </v-btn>
        <!-- 
                   coordinatename: this.openCoordinate.title,
                    projectname: this.project_name,
                    drawingname: this.drawing.name, -->
    
        <v-btn color="#f8bb03" class="mr-4" @click="removeall" v-if="selectedtask.length > 0"> {{lang.RemoveSelected}} </v-btn>
        <span class="tasklisttitle">{{ openCoordinate.title }} - Tasks</span>
        <download-excel elevation="10" icon small class="icon small rightexcel downloadbutons" :data="exceldata" :fields="coordinatejson_fields" :title="`${project_name}-${currentZoneName}-${drawing.name}-${openCoordinate.title}`" worksheet="Tasks" :name="`${project_name}-${currentZoneName}-${drawing.name}-${openCoordinate.title}.xls`" :before-finish="finishDownload">
            <v-icon dark color="#000">
                mdi-arrow-collapse-down
            </v-icon>
        </download-excel>
        <div class="mt-8">
    
            <v-progress-linear v-if="avarage > 0" :value="avarage" color="amber" rounded height="15">
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
    
            <v-data-table :disable-sort="true" :headers="headers" v-model="selectedtask" item-key="taskid" :single-select="singleSelect" show-select :items="tasks" :items-per-page="50" class="elevation-1">
                <template v-slot:item.taskname="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.taskname }} </span>
                    <v-text-field v-if="item.taskid == updateTask.taskid" v-model="updateTask.taskname" :rules="requerdRules" required></v-text-field>
                </template>
                <template v-slot:item.start_date="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.start_date }} </span>
                    <v-menu v-if="item.taskid == updateTask.taskid" ref="menu5" v-model="menu5" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="updateTask.taskstartdate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="amber accent-3" v-model="updateTask.taskstartdate" @input="menu5 = false"></v-date-picker>
                    </v-menu>
                </template>
    
                <template v-slot:item.end_date="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.end_date }} </span>
    
                    <v-menu v-if="item.taskid == updateTask.taskid" ref="menu6" v-model="menu6" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="updateTask.taskenddate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="amber accent-3" v-model="updateTask.taskenddate" @input="menu6 = false"></v-date-picker>
                    </v-menu>
    
                </template>
    
                <template v-slot:item.actual_start="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.actual_start }} </span>
    
                    <v-menu v-if="item.taskid == updateTask.taskid" ref="menu7" v-model="menu7" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="updateTask.actualstartdate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="amber accent-3" v-model="updateTask.actualstartdate" @input="menu7 = false"></v-date-picker>
                    </v-menu>
                </template>
    
                <template v-slot:item.actual_end="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.actual_end }} </span>
    
                    <v-menu v-if="item.taskid == updateTask.taskid" ref="menu8" v-model="menu8" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="updateTask.actualenddate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="amber accent-3" v-model="updateTask.actualenddate" @input="menu8 = false"></v-date-picker>
                    </v-menu>
    
                </template>
    
                <template v-slot:item.progress="{ item }">
                    <span v-if="item.taskid != updateTask.taskid" :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.progress }} </span>
                    <v-text-field v-if="item.taskid == updateTask.taskid" v-model="updateTask.taskprogress" :rules="requerdRules" required></v-text-field>
                </template>
    
                <template v-slot:item.status="{ item }">
                    <v-chip v-if="item.taskid != updateTask.taskid" :color="getColor(item.end_date,item.status)" light>
                        <span :class="[item.headingtype == 1 ? 'bold':'light']"> {{ item.status }} </span>
                    </v-chip>
                    <v-select v-if="item.taskid == updateTask.taskid" :rules="requerdRules" :items="status" v-model="updateTask.taskstatus"></v-select>
                </template>
    
                <template v-slot:item.id="{ item }">
    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="rgb(26 25 25)" fab x-small icon class="mr-1 black--text white--text" @click="openTaskDiscussion(item)" v-bind="attrs" v-on="on">
                                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                    mdi-forum-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span> Discussion </span>
                    </v-tooltip>
    
                    <span v-if="item.status == 'Completed'">
                        <v-tooltip left v-if="userdata.user_code == 0 || userdata.user_code == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="item.verification == 1" v-bind="attrs" v-on="on" class="mr-2 " color="green" @click="verificationEnventory(item)">
                                    mdi-check-circle
                                </v-icon>
                                <v-icon v-if="item.verification != 1" v-bind="attrs" v-on="on" class="mr-2 " @click="verificationEnventory(item)">
                                    mdi-check-circle
                                </v-icon>
                            </template>
                            <span v-if="item.verification == 1">Verified</span>
                            <span v-if="item.verification != 1">Verify</span>
                        </v-tooltip>
                        <v-tooltip left v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="item.verification == 1" v-bind="attrs" v-on="on" class="mr-2 " color="green">
                                    mdi-check-circle
                                </v-icon>
                                <v-icon v-if="item.verification != 1" v-bind="attrs" v-on="on" class="mr-2 ">
                                    mdi-check-circle
                                </v-icon>
                            </template>
                            <span v-if="item.verification == 1">Verified</span>
                            <span v-if="item.verification != 1">Verify</span>
                        </v-tooltip>
                    </span>
    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="rgb(26 25 25)" fab x-small icon class="mr-1 black--text white--text" @click="openTaskAssign(item)" v-bind="attrs" v-on="on">
                                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                    mdi-email-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.receiver_emails }}</span>
                    </v-tooltip>
                    <v-btn v-if="item.taskid == updateTask.taskid" fab dark icon small color="black" @click="sendupdateTask()" title="Save data">
                        <v-icon dark>
                            mdi-content-save-check
                        </v-icon>
                    </v-btn>
                    <v-btn v-if="item.taskid != updateTask.taskid" fab dark icon small color="black" @click="editTask(item)">
                        <v-icon dark>
                            mdi-pencil-box-multiple
                        </v-icon>
                    </v-btn>
                    <v-btn fab dark icon small color="black" @click="deleteTask(item.taskid)">
                        <v-icon dark>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                    <v-checkbox v-if="item.taskid == updateTask.taskid" v-model="updateTask.headingtype" label="Make as Heading."></v-checkbox>
                </template>
    
            </v-data-table>
        </div>
    </v-col>
    <!-- =========================== Task list End container =============================-->
    
    <!-- ===========all points snaglist resport filter ================================= -->
    
    <v-col cols="6" v-if="reportToggle2" class="taskbox" style="padding: 0.5px;top: 1%;" @keyup.esc="$emit('close')">
        <div class="child_div1">
            <v-card max-width="800" class="mx-auto">
                <v-app-bar dark color="rgb(42 41 41)">
    
                    <v-app-bar-nav-icon class="whitercolor topzindex" @click="filterdrawer = !filterdrawer" title="Filter Comments"></v-app-bar-nav-icon>
    
                    <v-toolbar-title>{{ drawing.name }} - Snag-list</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
    
                    <!-- <v-btn color="#f8bb03" class="mr-1 white--text" fab small outlined  @click="filerReport()" style="margin-left:31%" :title="lang.GenerateReport">
                                 <v-icon dark>
                                    mdi-file-document
                                </v-icon>
                                </v-btn> -->
                    <!-- <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#f8bb03" class="mr-1 white--text" fab small outlined v-bind="attrs" v-on="on" style="margin-left:31%" :title="lang.GenerateReport">
                                 <v-icon dark>
                                    mdi-file-document
                                </v-icon>
                                </v-btn>
                            </template>
                                <v-list>
    
                                    <v-list-item @click="pending_drawingsnaglistreport()">
                                        <v-list-item-title>Pending tasks - snaglist report</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="completed_drawingsnaglistreport()">
                                        <v-list-item-title>Completed tasks - snaglist report</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="generate_drawingsnaglistreport()">
                                        <v-list-item-title>Full snaglist report</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="filerReport()">
                                        <v-list-item-title>Filtered- snaglist report</v-list-item-title>
                                    </v-list-item>
                            </v-list>
                    </v-menu> -->
    
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ma-2 searcheMenu" v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field label="Search Comment title" v-model="searchtext1" :loading='searchloader'></v-text-field>
                            </v-list-item>
                        </v-list>
                    </v-menu>
    
                    <v-btn fab x-small icon class="closecommentb" @click="clsoeReport2" style="margin-right: 0% !important;">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <!-- <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                        </v-btn> -->
    
                </v-app-bar>
                <v-container class="vhfix">
                    <v-row dense>
                        <v-col cols="12" v-for="(comment, index) in filteredList_drawing" :key="comment.cmt_id">
                            <v-card :color="index  % 2 === 0 ?'#585858': 'rgb(239 239 239)'" dark>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-card-title :class="index  % 2 === 0 ?'title_font_white': 'title_font_black'" :title="comment.cmt_title">
                                            {{(comment.cmt_title.substring(0,60)+"..")}}
                                        </v-card-title>
                                        <v-card-subtitle :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">
                                            <b>Due on:</b> {{dateFormate(comment.due_date)| moment("DD-MM-YYYY")}}
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="rgb(26 25 25)" fab icon class="mr-1  white--text" @click="openMoreAssign(comment)" v-bind="attrs" v-on="on">
                                                        <v-icon color="primary" light v-bind="attrs" v-on="on">
                                                            mdi-email-plus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ comment.receiver_emails }}</span>
                                            </v-tooltip>
                                        </v-card-subtitle>
                                        <v-card-text :title="comment.comment_txt" :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">{{(comment.comment_txt.substring(0,135)+"..")}}
                                        </v-card-text>
                                        <v-card-text :title="comment.root_cause" :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'" style="margin-top: -4%;"><b>Root cause: </b>{{(comment.root_cause.substring(0,65)+"..")}}
                                            <p><b :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">Type: </b>{{comment.cmt_type}} &nbsp;&nbsp;&nbsp;
                                                <b>Area: </b>{{ comment.coordinate_name }}
                                            </p>
                                        </v-card-text>
    
                                        <v-card-actions style="margin-top: -5%;">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="comment.user_id == userdata.id" :color="comment.comment_status == 1 ? 'success':'error'" small class="mr-1 black--text white--text" @click="meckReaded(comment)" v-bind="attrs" v-on="on">
    
                                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
                                                    </v-btn>
                                                </template>
                                                <span>Status</span>
                                            </v-tooltip>
    
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="comment.user_id != userdata.id" :color="comment.comment_status == 1 ? 'success':'error'" small class="mr-1 black--text white--text" v-bind="attrs" v-on="on">
    
                                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    <v-icon color="red" class="alerrt" right>mdi-alert</v-icon> Only the assignor,{{comment.sender_name}} can change the status.
                                                </span>
                                            </v-tooltip>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" color="rgb(26 25 25)" class="ma-2 white--text" small>
                                                        {{lang.Images360}}
                                                        <v-icon dark right>mdi-menu-down</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list style="cursor:pointer;">
                                                    <v-list-item>
                                                        <v-list-item-title @click="getcommentImages(comment.url360)">Corresponding Image</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-title @click="getlatestImages()">Latest Image</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="rgb(26 25 25)" class="mr-1 black--text white--text" small @click="get_discussion(comment)" v-bind="attrs" v-on="on">
                                                        Discussion
                                                    </v-btn>
                                                </template>
                                                <span>Start discussion on this</span>
                                            </v-tooltip>
                                            <div style="position: absolute;bottom: 0%;right: 1%;">
                                                <span :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">{{dateFormate(comment.update_at)| moment("DD-MM-YYYY")}}</span>
                                            </div>
                                        </v-card-actions>
                                    </div>
                                    <v-avatar class="ma-3" size="140" tile @click="openComment_image(comment.thumbnail_url)" style="cursor: pointer;">
                                        <v-img :src="comment.thumbnail_url" style="width: 100%"></v-img>
    
                                    </v-avatar>
                                </div>
                            </v-card>
                        </v-col>
                        <v-navigation-drawer class="filterdrawer" v-model="filterdrawer" app temporary width:300>
                            <template>
                                <v-container fluid>
                                    <v-card-title>Filter<v-spacer></v-spacer>
                                        <v-btn class="" small @click="clear_filter()" v-bind="attrs" v-on="on">
                                            Clear All
                                        </v-btn>
                                        <v-btn fab x-small @click="filterdrawer = false" color="#d3d3d3" class="mx-2" style="margin-right: 0% !important;">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <hr>
                                    <v-card-title>Status</v-card-title>
                                    <v-radio-group v-model="filter_status1" row>
                                        <span v-for="ops in cmt_status" :key="ops.cmt_status">
                                            <v-radio :label="ops.title" :value="ops.cmt_status"></v-radio>
                                        </span>
                                    </v-radio-group>
                                    <hr>
                                    <v-card-title>Type</v-card-title>
                                    <v-radio-group v-model="filter_type1" row>
                                        <span v-for="op in cmt_type_op" :key="op.cmt_type_op">
                                            <v-radio :label="op.title" :value="op.title"></v-radio>
                                        </span>
                                    </v-radio-group>
                                    <hr>
                                    <v-card-title>Due Date</v-card-title>
                                    <v-radio-group v-model="filter_duedate1" row>
                                        <v-radio label="Missed and Pending" value="0"></v-radio>
                                    </v-radio-group>
    
                                    <v-btn class="mx-1" dark color="#000000" @click="filerReport()">
                                        Generate Report
                                    </v-btn>
                                </v-container>
                            </template>
                        </v-navigation-drawer>
                    </v-row>
    
                </v-container>
            </v-card>
        </div>
    </v-col>
    <v-col cols="6" v-if="commentbox && commentsdata.length > 0" :class="commentbox ?'commentopentool':'commnetclosetool'" class="taskbox" style="padding: 0.5px;top: 1%;" @keyup.esc="$emit('close')">
        <div class="child_div1">
            <v-card max-width="800" class="mx-auto">
                <v-app-bar dark color="rgb(42 41 41)">
    
                    <v-app-bar-nav-icon class="whitercolor topzindex" @click="filterdrawer = !filterdrawer" title="Filter Comments"></v-app-bar-nav-icon>
    
                    <v-toolbar-title>{{ openCoordinate.title }} - Snag-list</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="#f8bb03" class="mr-1 white--text" fab small @click="filerReportCoordinate()" outlined v-bind="attrs" v-on="on" style="margin-left:48%">
                                 <v-icon dark>
                                    mdi-file-document
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{lang.GenerateReport}}</span>
                        </v-tooltip> -->
    
                    <v-menu bottom offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ma-2 searcheMenu" v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field label="Search Comment title" v-model="searchtext" :loading='searchloader'></v-text-field>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn fab x-small icon @click="commentbox = false" class="closecommentb" style="margin-right: 0% !important;">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <!-- <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                        </v-btn> -->
    
                </v-app-bar>
                <v-container class="vhfix">
                    <v-row dense>
                        <v-col cols="12" v-for="(comment, index) in filteredList" :key="comment.cmt_id">
                            <v-card :color="index  % 2 === 0 ?'#585858': 'rgb(239 239 239)'" dark>
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-card-title :class="index  % 2 === 0 ?'title_font_white': 'title_font_black'" :title="comment.cmt_title">
                                            {{(comment.cmt_title.substring(0,60)+"..")}}
                                        </v-card-title>
                                        <v-card-subtitle :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">
                                            <b>Due on:</b> {{dateFormate(comment.due_date)| moment("DD-MM-YYYY")}}
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="rgb(26 25 25)" fab icon class="mr-1  white--text" @click="openMoreAssign(comment)" v-bind="attrs" v-on="on">
                                                        <v-icon color="primary" light v-bind="attrs" v-on="on">
                                                            mdi-email-plus
                                                        </v-icon>
                                                    </v-btn>
    
                                                </template>
                                                <span>{{ comment.receiver_emails }}</span>
                                            </v-tooltip>
                                        </v-card-subtitle>
                                        <v-card-text :title="comment.comment_txt" :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">{{(comment.comment_txt.substring(0,135)+"..")}}
                                        </v-card-text>
                                        <v-card-text :title="comment.root_cause" :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'" style="margin-top: -4%;"><b>Root cause: </b>{{(comment.root_cause.substring(0,65)+"..")}}
                                            <p><b :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">Type: </b>{{comment.cmt_type}}
    
                                            </p>
                                        </v-card-text>
    
                                        <v-card-actions style="margin-top: -5%;">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="comment.user_id == userdata.id" :color="comment.comment_status == 1 ? 'success':'error'" small class="mr-1 black--text white--text" @click="meckReaded(comment)" v-bind="attrs" v-on="on">
    
                                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
                                                    </v-btn>
                                                </template>
                                                <span>Status</span>
                                            </v-tooltip>
    
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-if="comment.user_id != userdata.id" :color="comment.comment_status == 1 ? 'success':'error'" small class="mr-1 black--text white--text" v-bind="attrs" v-on="on">
    
                                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    <v-icon color="red" class="alerrt" right>mdi-alert</v-icon> Only the assignor, {{comment.sender_name}} can change the status.
                                                </span>
                                            </v-tooltip>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" color="rgb(26 25 25)" class="ma-2 white--text" small>
                                                        {{lang.Images360}}
                                                        <v-icon dark right>mdi-menu-down</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list style="cursor:pointer;">
                                                    <v-list-item>
                                                        <v-list-item-title @click="getcommentImages(comment.url360)">Corresponding Image</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-title @click="getlatestImages()">Latest Image</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="rgb(26 25 25)" class="mr-1 black--text white--text" small @click="get_discussion(comment)" v-bind="attrs" v-on="on">
                                                        Discussion
                                                    </v-btn>
                                                </template>
                                                <span>Start discussion on this</span>
                                            </v-tooltip>
                                            <div style="position: absolute;bottom: 0%;right: 1%;">
                                                <span :class="index  % 2 === 0 ?'text_font_white': 'text_font_black'">{{dateFormate(comment.update_at)| moment("DD-MM-YYYY")}}</span>
                                            </div>
                                        </v-card-actions>
                                    </div>
                                    <v-avatar class="ma-3" size="140" tile @click="openComment_image(comment.thumbnail_url)" style="cursor: pointer;">
                                        <v-img :src="comment.thumbnail_url" style="width: 100%"></v-img>
    
                                    </v-avatar>
                                </div>
                            </v-card>
                        </v-col>
                        <v-navigation-drawer class="filterdrawer" v-model="filterdrawer" app temporary width:300>
                            <template>
                                <v-container fluid>
                                    <v-card-title>Filter<v-spacer></v-spacer>
                                        <v-btn class="" small @click="clear_filter()" v-bind="attrs" v-on="on">
                                            Clear All
                                        </v-btn>
                                        <v-btn fab x-small @click="filterdrawer = false" color="#d3d3d3" class="mx-2" style="margin-right: 0% !important;">
                                            <v-icon dark>mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <hr>
                                    <v-card-title>Status</v-card-title>
                                    <v-radio-group v-model="filter_status" row>
                                        <span v-for="ops in cmt_status" :key="ops.cmt_status">
                                            <v-radio :label="ops.title" :value="ops.cmt_status"></v-radio>
                                        </span>
                                    </v-radio-group>
                                    <hr>
                                    <v-card-title>Type</v-card-title>
                                    <v-radio-group v-model="filter_type" row>
                                        <span v-for="op in cmt_type_op" :key="op.cmt_type_op">
                                            <v-radio :label="op.title" :value="op.title"></v-radio>
                                        </span>
                                    </v-radio-group>
                                    <hr>
                                    <v-card-title>Due Date</v-card-title>
                                    <v-radio-group v-model="filter_duedate" row>
                                        <v-radio label="Missed and Pending" value="0"></v-radio>
                                    </v-radio-group>
    
                                    <v-btn class="mx-1" dark color="#000000" v-bind="attrs" @click="filerReportCoordinate()">
                                        Download Report
                                    </v-btn>
                                </v-container>
                            </template>
                        </v-navigation-drawer>
                    </v-row>
    
                </v-container>
            </v-card>
        </div>
    </v-col>
    </v-row>
    
    <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}
    
        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
    
    <v-pannellum :showFullscreen="showFullscreen" type="cubemap" :autoRotate="true" :showZoom="showZoom" :compass="true" :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom" :draggable="true" v-if="sliderActive" ref="slider" class="sliderPanaroma" :src="activesliderImage"></v-pannellum>
    <div v-if="sliderActive" class="topbar sliderss"><span>{{ titile }}</span> <span> {{ uploadat }}</span></div>
    <v-icon dark color="#000" @click="sliderActive = false" v-if="sliderActive" class="exitbuttonscomapre slider">
        mdi-close
    </v-icon>
    
    <v-icon light color="#f8bb03" @click="minus" x-large v-if="sliderActive && index > 0" class="arrowss leftarrows">
        mdi-chevron-left-circle
    </v-icon>
    
    <v-icon light color="#f8bb03" @click="plus" x-large v-if="sliderActive &&  latestPanaromas.length > index" class="arrowss rightarrows">
        mdi-chevron-right-circle
    </v-icon>
    <div class="timeline3d up" v-if="sliderloader">
        <v-progress-linear color="#f8bb03" indeterminate reverse></v-progress-linear>
    
    </div>
    <div :class="`timeline3d ${down ? 'down':'up'}`" v-if="latesttrue && insids">
    
        <v-btn class="mx-2 arroo leftal" fab x-small color="#f8bb03" @click="downUp">
            <v-icon dark>
                mdi-chevron-up-circle
            </v-icon>
        </v-btn>
        <carousel :key="refresh3" class="timelinec" :autoplay="false" :responsive="{0:{items:2,nav:false},600:{items:3,nav:true},1000:{items:8,nav:true, slideBy:7}}" :dots="false" :margin="8" :nav="true" :navText="['','']">
            <div v-for="(slide, indx) in latestPanaromas" :key="indx+100" :class="`timelaineimagebox ${index == indx ?'active':''}`">
                <p class="disply_title">{{ slide[0].titile }}</p>
                <img :src="slide[0].mediathumpath == '' || slide[0].mediathumpath == 'not available' ? videosthumb : slide[0].mediathumpath" @click="onSelectslider(indx)">
    
                <p class="disply_date">{{ slide[0].uploadat }}</p>
    
            </div>
    
        </carousel>
    </div>
    
    <div :class="`timeline3d ${down ? 'down':'up'}`" v-if="slides.length > 0 && !insids">
    
        <v-select class="dropdatess" :items="uniquedates" label="FilterByDate" dense solo outlined @change="selectDate"></v-select>
    
        <v-btn class="mx-2 arroo" fab x-small color="#f8bb03" @click="downUp">
            <v-icon dark>
                mdi-chevron-up-circle
            </v-icon>
        </v-btn>
        <v-btn class="mx-1 insidesb" color="#f8bb03" @click="insids = true">
            {{lang.LayoutInsights}}
        </v-btn>
        <carousel :key="refresh" class="timelinec" :autoplay="false" :responsive="{0:{items:2,nav:false},600:{items:3,nav:true},1000:{items:8,nav:true, slideBy:7}}" :dots="false" :margin="8" :nav="true" :navText="['','']">
            <div v-for="(slide, index) in slides" :key="index" class="timelaineimagebox">
                <input type="checkbox" v-model="selected" @click="oncompare($event,index)" class="checkboxxsle" :value="index" v-if="activet == 'normal' || activet == 'panaroma'" />
    
                <img :src="slide.mediathumpath == '' || slide.mediathumpath == 'not available' ? videosthumb : slide.mediathumpath" @click="onSelect(index)">
    
                <p class="disply_date">{{ slide.uploadat }}</p>
            </div>
    
        </carousel>
    
    </div>
    
    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" scrollable color="amber accent-3" v-model="commmentForm" style="z-index: 2 !important;" persistent>
    
        <v-card tile>
            <v-toolbar flat dark color="amber accent-3" style="flex: none">
                <v-btn icon dark @click="commmentForm = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{lang.AddNewComment}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendComment">
    
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="8">
                            <br>
                            <v-text-field dense v-model="cmt_title" label="Title" :rules="cmt_titlerules" required></v-text-field>
                            <br>
                            <v-textarea clearable clear-icon="mdi-close-circle" dense v-model="comment" auto-grow :rules="cmt_descrules" label="Comment Description" required>
                            </v-textarea>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <!--                     <v-text-field v-model="cmt_type" :rules="requerdRules" label="Type"  required></v-text-field> -->
                                    <v-select :items="cmt_type_op" label="Type" item-text="title" item-value="cmt_type_op" v-model="cmt_type" return-object single-line @change="selectus(cmt_type_op)" :rules="requerdRules"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-select :items="cmt_status" label="Status" item-text="title" item-value="cmt_status" v-model="cmt_status_val" return-object single-line @change="selectus(cmt_status_val)" :rules="requerdRules"></v-select>
                                </v-col>
    
                                <v-col cols="12" sm="6" md="4" style="margin-top: 3%;">
                                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="date1" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense v-model="date1" label="Due Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date1" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu1 = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu1.save(date1)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
    
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field dense v-model="cmt_rootcause" :rules="cmt_causerules" label="Root Cause (optional)"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-select :items="getusers" label="Please enter assigned to email" item-text="email" item-value="email" v-model="commetnsemail" :rules="emailrules_req" multiple persistent-hint>
                                        <template v-slot:prepend-item>
                                            <!-- <v-list-item>
    
                                    <v-list-item-content>
                                         <v-text-field dense v-model="commetnsemail_extra"
                            :rules="emailrules" label="Add more email" outlined required></v-text-field>
                                    </v-list-item-content>
                                    </v-list-item> -->
                                        </template>
                                    </v-select>
                                    <v-text-field dense label="Add more email" v-model="commetnsemail_extra" :rules="emailrules"></v-text-field>
    
                                </v-col>
                            </v-row>
                            <br><br>
                            <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton" style="margin-left:2%">
                                {{lang.Send}}
                                <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                            </v-btn>
                            <v-btn class="mr-4" @click="commmentForm = false" color="mydarkbutton">
                                {{lang.Cancel}}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                            <div class="commentimage mb-5">
                                <v-img :src="commentImage" aspect-ratio="2" class="black lighten-2" style="height: 300px;">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mx-1" fab dark x-small color="#000000" :title="lang.ImageMarkup" v-bind="attrs" v-on="on">
                                                <v-icon dark color="#f8bb03" @click="annotadeImage">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span> {{ lang.ImageMarkup }} </span>
                                    </v-tooltip>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    
    </v-dialog>
    <div class="imageeditor active" v-if="showannotation" style="z-index: 999;">
        <v-btn icon dark @click="showannotationclose" class="oveicon clsoe">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn dark text @click="SaveandSend()" class="oveicon save">
            {{lang.Done}}
        </v-btn>
        <!-- <v-btn
                    dark
                    text
                   @click="onlySend()"
                    class="oveicon sendd"
                  >
                    Send
                  </v-btn> -->
        <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options" :key="tui_re_render" v-if="showannotation"></tui-image-editor>
    </div>
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newTaskForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.AddNewTask}} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendTask">
    
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-col cols="12" sm="12">
                                    <v-text-field v-model="newTask.taskname" :rules="requerdRules" :label="lang.TaskName" required>
                                    </v-text-field>
    
                                    <v-select :rules="requerdRules" :items="status" :label="lang.Status" v-model="newTask.taskstatus"></v-select>
    
                                    <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.taskstartdate" :label="lang.StartDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.taskstartdate" @input="menu3 = false"></v-date-picker>
                                    </v-menu>
    
                                    <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.taskenddate" :label="lang.EndDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.taskenddate" @input="menu4 = false"></v-date-picker>
                                    </v-menu>
    
                                    <v-menu ref="menu9" v-model="menu9" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.actualstartdate" label="Actual Start Date" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.actualstartdate" @input="menu9 = false"></v-date-picker>
                                    </v-menu>
    
                                    <v-menu ref="menu10" v-model="menu10" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.actualenddate" label="Actual End Date" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.actualenddate" @input="menu10 = false"></v-date-picker>
                                    </v-menu>
    
                                    <v-text-field v-model="newTask.taskprogress" type="number" :rules="requerdRules" :label="lang.Progress" required></v-text-field>
    
                                    <v-text-field v-model="newTask.tasktag" :label="lang.Tag"></v-text-field>
    
                                    <v-container class="px-0 pl-5" fluid>
                                        <v-checkbox v-model="newTask.headingtype" label="Make as Heading."></v-checkbox>
                                    </v-container>
                                </v-col>
    
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}}
                                    <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn class="mr-4" @click="newTaskForm = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
    
    <!-- =================================Task Update form start =============================-->
    
    <!-- <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="updateTaskForm">
            <template>
                <v-card>
                    <v-toolbar color="amber accent-3" dark> {{lang.UpdateTask}} </v-toolbar>
                    <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendupdateTask">
    
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-col cols="12" sm="12">
                                        <v-text-field v-model="updateTask.taskname" :rules="requerdRules" :label="lang.TaskName" required></v-text-field>
                                        <v-select :rules="requerdRules" :items="status" :label="lang.Status" v-model="updateTask.taskstatus"></v-select>
                                        <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="updateTask.taskstartdate" :label="lang.StartDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="amber accent-3" v-model="updateTask.taskstartdate" @input="menu5 = false"></v-date-picker>
                                        </v-menu>
    
                                        <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="updateTask.taskenddate" :label="lang.EndDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="amber accent-3" v-model="updateTask.taskenddate" @input="menu6 = false"></v-date-picker>
                                        </v-menu>
    
                                        <v-menu ref="menu7" v-model="menu7" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="updateTask.actualstartdate" label="Actual Start Date" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="amber accent-3" v-model="updateTask.actualstartdate" @input="menu7 = false"></v-date-picker>
                                        </v-menu>
    
                                        <v-menu ref="menu8" v-model="menu8" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="updateTask.actualenddate" label="Actual End Date" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="amber accent-3" v-model="updateTask.actualenddate" @input="menu8 = false"></v-date-picker>
                                        </v-menu>
    
                                        <v-text-field v-model="updateTask.taskprogress" type="number" :rules="requerdRules" :label="lang.Progress" required></v-text-field>
    
                                        <v-text-field v-model="updateTask.tasktag" :label="lang.Tag"></v-text-field>
    
                                        <v-text-field dense label="Assign to" v-model="updateTask.receiver_emails" :rules="emailrules"></v-text-field>     
                                        <v-container class="px-0 pl-5" fluid>
                                            <v-checkbox v-model="updateTask.headingtype" label="Make as Heading."></v-checkbox>
                                        </v-container>
    
                                    </v-col>
    
                                    <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                        {{lang.Send }}
                                        <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                    </v-btn>
                                    <v-btn class="mr-4" @click="updateTaskForm = false" color="mydarkbutton">
                                        {{lang.Cancel}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card>
            </template>
        </v-dialog> -->
    <!-- =================================Task Update form start =============================-->
    
    
    <!-- ===============upload task  excel file  ===========-->
    
    
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="importFile">
            <template>
                <v-card>
                    <v-toolbar color="amber accent-3" dark> Update Tasks</v-toolbar>
                    <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="updateTaskFile">
    
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="12">
    
                                    <p> Upload an Excel file containing your tasks.</p>
                                    <input type="file" @change="onChange" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
    
                                     <xlsx-read :file="file" class="hide">
                                        <xlsx-json  >
                                            <template #default="{collection}">
                                                <div ref="tasks">
                                                    {{ collection }}
                                                </div>
                                            </template>
                                        </xlsx-json>
                                    </xlsx-read>
                                </v-col>
                                <v-col cols="12" md="12">
                                 
                                </v-col>
                                <v-col cols="12" md="12">
    
                                    <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                        {{lang.submit}}
                                    </v-btn>
                                     <v-btn class="mr-4" @click="importFile = false" color="mydarkbutton">
                                        {{lang.Cancel}}
                                    </v-btn>
    
                                </v-col>
                               
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card>
            </template>
        </v-dialog>
    
    
    <!-- =================================Task Wish Report popup =============================-->
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="reportToggle" persistent>
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.Report}} </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="clsoeReport">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-row>
    
                        <v-col cols="12" sm="12" class="center">
    
                            <h4>Task Wise Reports </h4>
    
                            <download-excel class="btn ma-2 Block--text rightbutons downloadbutons" :data="exceldata" :fields="json_fields" worksheet="Tasks" :name="`${project_name}-${currentZoneName}-${drawing.name}.xls`" :before-finish="finishDownload">
                                {{lang.DownloadExcel}} -
                                <v-icon right dark color="#000">
                                    mdi-file-excel
                                </v-icon>
                            </download-excel>
    
                            {{lang.OR }}
    
                            <v-btn @click="DownloadOverallPDF" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                {{lang.DownloadPDF}}
                                <v-icon right dark color="#000">
                                    mdi-file-pdf-box
                                </v-icon>
                            </v-btn>
    
                            <br />
                            <hr/>
    
                             <v-btn @click="importFile = true"  v-if="userdata.user_code == 2 || userdata.user_code == 0" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                Update Tasks by Excel File
                                <v-icon right dark color="#000">
                                    mdi-file-excel
                                </v-icon>
                            </v-btn>
    
                           
    
                              
                            <!-- <br />
                                <hr />
                                <br />
                                <h4>360 Image Based Report </h4>
                                <v-btn @click="reportloop" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                    Generate Report
                                    <v-icon right dark color="#000">
                                        mdi-file-pdf-box
                                    </v-icon>
                                </v-btn> -->
    
                            <p style="text-align:left">
                                <strong>Notes:</strong> <br />
                                1. Supported browser: Google Chrome <br />
                                2. It might take a few minutes to generate a report. Do not refresh while processing.
                            </p>
    
                        </v-col>
                    </v-row>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    <!--========================== 360 Image Based Report Start ==========================-->
    
    <v-dialog transition="dialog-bottom-transition" max-width="500" color="amber accent-3" v-model="reportToggle1" persistent>
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> 360 Image Based Report </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="clsoeReport1">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-row>
       
                        <v-col cols="12" sm="12"  class="center">
    
                                  <br>
                                  <br>
    
    
                              <v-select :items="reportdates" item-text="unique_dates" item-value="unique_dates" label="Select Date for Report" dense solo outlined v-model="selectReportDate"></v-select>
    
                            <v-btn @click="dowloadReportBydate" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                Download Report
                                <v-icon right dark color="#000">
                                    mdi-file-pdf-box
                                </v-icon>
                            </v-btn>
                            <br>
                            <br>
                              <hr>
                              <br>
                              <br>
                             <p v-if="drawing.emails"> Schedule Email: {{drawing.emails}} <v-icon  @click="schedulereportbox = true"  right dark color="#000">
                                    mdi-email-edit
                                </v-icon></p>
    
                             <v-btn @click="schedulereportbox = true" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                Schedule Email
                                <v-icon right dark color="#000">
                                    mdi-email-edit
                                </v-icon>
                            </v-btn>
    
                              <br><br>
    
                         
                        </v-col>
                    </v-row>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    
    
    <!-- Schedule email for interior 360 report -->
    
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="schedulereportbox" persistent>
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> 360 Image Based Report Email Schedule </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="schedulereportbox = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-row>
          
                        <v-col cols="12" sm="12" class="center">
                             <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="updateEmailforReport"
        >
                            <v-textarea
                                v-model="reportemails"
                                :label="lang.Email"
                                :rules="emailrules"
                                required
                                rows="1"
                                ></v-textarea>
    
                                <p> 
                                   Reports are generated automatically within 24 hours of the capturing and sent to the above emails.
                                </p>
    
                             <v-btn
                              type="submit"
                                 :disabled="!valid"
                                 color="#ffc400" 
                                 class="btn ma-2 Block--text rightbutons downloadbutons">
                                Submit
                                <v-icon right dark color="#000">
                                    mdi-email-edit
                                </v-icon>
                            </v-btn>
    
                              
                      </v-form>
                         
                        </v-col>
                    </v-row>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    <!-- ============================= Discussion Box start =============================-->
    
    <v-dialog content-class="dialog_position" v-model="cmtdiscuss_dialog" persistent max-width="500px" style="position:absolute;right: 1%">
        <v-card>
            <v-card-title>
                <span class="headline">Add Discussion</span>
                <!--  <v-alert dense text :type="alertType" v-if="alert">
                      {{ alertmessage }}
                    </v-alert> -->
                <v-icon class="mailicon" color="black" @click="openMoreAssign(cmt_data_d)" dark>
                    mdi-email-plus
                </v-icon>
            </v-card-title>
    
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" v-for="u in updatecamdata" :key="u.id">
                            <v-card>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="username">
                                        <p class="p_username">{{ u.username }}</p>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <p class="update_date">{{ u.updated_at }}</p>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <p class="p_note">{{ u.dis_note }}</p>
                                    </v-col>
                                </v-row>
                                <br />
                            </v-card>
                        </v-col>
    
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field :label="lang.AddNote" v-model="add_disnote" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cmtdiscuss_dialog = false">
                    {{lang.Close}}
                </v-btn>
                <v-btn color="blue darken-1" text @click="Update_disNote()">
                    {{lang.Save }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog content-class="dialog_position" v-model="taskdiscuss_dialog" persistent max-width="600px" style="position:absolute;right: 1%">
        <v-card>
            <v-card-title>
                <span class="headline">Add Discussion</span>
                <!--  <v-alert dense text :type="alertType" v-if="alert">
                      {{ alertmessage }}
                    </v-alert> -->
                <v-icon class="mailicon" color="black" @click="openTaskAssign(cmt_data_d)" dark>
                    mdi-email-plus
                </v-icon>
            </v-card-title>
    
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" v-for="u in taskDiscData" :key="u.id">
                            <v-card>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="username">
                                        <p class="p_username">{{ u.username }}</p>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <p class="update_date">{{ u.updated_at }}</p>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <p class="p_note">{{ u.disc_note }}</p>
                                    </v-col>
                                </v-row>
                                <br />
                            </v-card>
                        </v-col>
    
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field :label="lang.AddNote" v-model="add_disnote" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="taskdiscuss_dialog = false">
                    {{lang.Close}}
                </v-btn>
                <v-btn color="blue darken-1" text @click="Update_TaskdisNote()">
                    {{lang.Save }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <table class="table hide" id="cumulativeReportDownload">
    
        <thead class="thead-dark">
            <tr>
    
                <th>{{lang.Task}}</th>
                <th>{{lang.Status}}</th>
                <th>Start</th>
                <th>End</th>
                <th>Actual Start</th>
                <th>Actual End</th>
                <th>{{lang.Progress}}</th>
                <th></th>
    
            </tr>
        </thead>
        <tbody>
            <tr v-for="(val, index ) in cumulative_data" :key="index">
                <td v-if="val.title" colspan="7">{{val.title}}</td>
                <td>{{val.Task}}</td>
                <td>{{val.Status}}</td>
                <td>{{val.Start}}</td>
                <td>{{val.End}}</td>
                <td>{{val.Actual_Start}}</td>
                <td>{{val.Actual_End}}</td>
                <td>{{val.Progress}}</td>
                <td>{{val.headingtype}}</td>
    
            </tr>
        </tbody>
    </table>
    <div class="layer" v-if="lock">
    </div>
    <canvas id="canvas" width="800px" height="400px" v-if="reportStart"></canvas>
    
    <div class="reportview">
        <!-- <div v-for="(slide, indx) in latestPanaromas" :key="indx+300">
                       <img :src="cropImg()">
                   </div> -->
    </div>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :margin="[30, 0, 30, 0]" :filename="drawing.name" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="950px" @progress="onProgress($event)" @hasDownloaded="onDownloaded()" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">
        <section slot="pdf-content">
            <div v-if="reportdata.length > 0" style="margin-left:150px">
                <div v-for="(item, indx) in reportdata" :key="indx+300">
                    <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                        <div style="width:50%;float:left;">
                            <div>
                                <h3> {{project_name}} </h3>
                            </div>
                            <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                        </div>
                        <div style="width: 50%;float: right; text-align: right;">
                            <img :src="logourl" style="max-height: 35px;">
                        </div>
    
                    </div>
                    <div style="width:100%;display:block;overflow:auto;  padding: 0 20px;">
                        <div style="width:50%;display:block;overflow:auto; float:left; padding-right:10px; padding-top:20px; max-height:250px; margin-bottom:20px">
                            <img :src="item.point" style="width:100%; float: left; padding:5px;border:0px solid #ccc">
                        </div>
                        <div style="width:50%;display:block;overflow:auto; float:left;padding-left:15px;padding-top:100px">
    
                            <h4>{{ latestPanaromas[indx][0].titile }}</h4>
    
                            <p>Image Captured On {{ latestPanaromas[indx][0].uploadat | moment("DD-MM-YYYY") }}</p>
    
                            <p> Report Created On {{ new Date() | moment("DD-MM-YYYY") }}</p>
                        </div>
                        <div style="width:100%;display:block;overflow:auto;">
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-right:10px;margin-bottom:20px">
                                <img :src="item.image1" style="width:100%;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-left:10px;margin-bottom:20px">
                                <img :src="item.image2" style="width:100%;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-right:10px;margin-bottom:20px">
                                <img :src="item.image3" style="width:100%;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-left:10px;margin-bottom:20px">
                                <img :src="item.image4" style="width:100%;">
                            </div>
                        </div>
                        <a href="https://  " style="float:right; text-align:right;text-decoration:none; color:#000"> </a>
                    </div>
                    <div class="html2pdf__page-break"></div>
                </div>
    
            </div>
    
        </section>
    </vue-html2pdf>
    
    <!-- Report for Coordinates -->
    
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :margin="[30, 0, 30, 0]" :filename="drawing.name" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="900px" @progress="onProgress($event)" @hasDownloaded="onDownloaded()" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2PdfPoint">
        <section slot="pdf-content">
            <div v-if="reportdataPoint.length > 0" style="margin-left:150px">
                <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                    <div style="width:50%;float:left;">
                        <div>
                            <h3> {{project_name}} </h3>
                        </div>
                        <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                    </div>
    
                    <div style="width: 50%;float: right; text-align: right;">
                        <img :src="logourl" style="max-height: 35px;">
                    </div>
                </div>
    
                <div style="width:100%;display:block;overflow:auto; float:left; padding-right:10px; padding-top:20px;  margin-bottom:20px">
                    <h4>{{ openCoordinate.title }}</h4>
                    <p> Report Created On {{ new Date() | moment("DD-MM-YYYY") }}</p>
                    <img :src="reportdataPoint[0].point" style="width:100%; float: left; padding:5px;border:0px solid #ccc; max-height:600px;">
                </div>
                <div class="html2pdf__page-break"></div>
                <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                    <div style="width:50%;float:left;">
                        <div>
                            <h3> {{project_name}} </h3>
                        </div>
                        <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                    </div>
    
                    <div style="width: 50%;float: right; text-align: right;">
                        <img :src="logourl" style="max-height: 35px;">
                    </div>
                </div>
    
                <div style="width:100%;display:block;overflow:auto; float:left; padding-right:10px; padding-top:20px;  margin-bottom:20px">
                    <h4>{{ openCoordinate.title }}</h4>
                    <p> Report Created On {{ new Date() | moment("DD-MM-YYYY") }}</p>
                    <p style="text-align: center;"><b>Notes and Details</b></p>
                    <v-list-item v-for="(u, index) in view_reportnote" :key="index+index">
                        <v-list-item-icon>
                            <v-icon>mdi-checkbox-blank-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <p>{{u}}</p>
                        </v-list-item-content>
                    </v-list-item>
                </div>
    
                <div class="html2pdf__page-break"></div>
    
                <div v-for="(item, indx) in reportdataPoint" :key="indx+390">
                    <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                        <div style="width:50%;float:left;">
                            <div>
                                <h3> {{project_name}} </h3>
                            </div>
                            <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                        </div>
                        <div style="width: 50%;float: right; text-align: right;">
                            <img :src="logourl" style="max-height: 35px;">
                        </div>
    
                    </div>
                    <div style="width:100%;display:block;overflow:auto;  padding: 0 20px;">
    
                        <div style="width:100%;display:block;overflow:auto;">
                            <p style="padding: 10px 0px">Image Captured On {{ slides[indx].uploadat | moment("DD-MM-YYYY") }}</p>
    
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-right:10px;margin-bottom:15px">
                                <img :src="item.image1" style="width:100%; max-height:300px;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-left:10px;margin-bottom:15px">
                                <img :src="item.image2" style="width:100%;  max-height:300px;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-right:10px;margin-bottom:5px">
                                <img :src="item.image3" style="width:100%;  max-height:300px;">
                            </div>
                            <div style="width:50%;display:block;overflow:auto; float:left; padding-left:10px;margin-bottom:5px">
                                <img :src="item.image4" style="width:100%; max-height:300px;">
                            </div>
                        </div>
                        <a href="https://  " style="float:right; text-align:right;text-decoration:none; color:#000"> </a>
                    </div>
                    <div class="html2pdf__page-break"></div>
                </div>
    
            </div>
        </section>
    </vue-html2pdf>
    
    <div class="loaderInfo" v-if="reportStart">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
        <h5> {{progree}}</h5>
        <p class="mgss"> {{msg}} </p>
    
    </div>
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="zoneMessage">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Alert Message </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="zoneMessage = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-row>
    
                        <v-col cols="12" sm="12" class="center">
    
                            <h4> Go to "Edit View" & add zones & drawings to start using the dashboard </h4>
    
                            <v-btn color="#ffc400" class="ma-2 Block--text " v-if="userdata.interior_editor_access == 1" @click="setrouter('/dashboard/edit-drawings')">
                                Add Zone and Drawings
                                <v-icon right dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    <v-dialog transition="dialog-bottom-transition" class="zindex" max-width="600" color="amber accent-3" v-model="assingtoMorebox">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Assigned To: </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="assingtoMorebox = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-form ref="uploadDrawing" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="12" class="center">
                                <p>&nbsp;</p>
                                <v-text-field dense label="Add more email" v-model="moreEmails" :rules="emailrules"></v-text-field>
                                <v-btn color="#ffc400" class="ma-2 Block--text " :disabled="!valid" @click="submitEmailAssign">
                                    Submit
                                </v-btn>
    
                                <v-btn color="red" class="ma-2 Block--text " @click="assingtoMorebox = false">
                                    Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    <v-dialog transition="dialog-bottom-transition" class="zindex" max-width="600" color="amber accent-3" v-model="assingtoTaskbox">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Assigned To </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="assingtoTaskbox = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-form ref="uploadDrawing" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="12" class="center">
                                <p>&nbsp;</p>
                                <v-text-field dense label="Add more email" v-model="moreEmails" :rules="emailrules"></v-text-field>
                                <v-btn color="#ffc400" class="ma-2 Block--text " :disabled="!valid" @click="submitTaskEmailAssign">
                                    Submit
                                </v-btn>
    
                                <v-btn color="red" class="ma-2 Block--text " @click="assingtoTaskbox = false">
                                    Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
    
            </v-card>
        </template>
    </v-dialog>
    
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :margin="[30, 0, 30, 0]" :filename="drawing.name+' '+openCoordinate.title+' snaglist'" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1130px" @progress="onProgress_snaglist($event)" @hasDownloaded="onDownloaded_snaglist()" @hasStartedGeneration="hasStartedGeneration_snaglist()" @hasGenerated="hasGenerated_snaglist($event)" ref="html2Pdfsnaglist">
        <section slot="pdf-content">
            <div v-if="reportdataPointt.length > 0" id="pdf_template" style="margin-left:75px;margin-right:75px">
    
                <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                    <div style="width:50%;float:left;">
                        <div>
                            <h3> {{project_name}} - {{org}} {{city}}</h3>
                        </div>
                        <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                    </div>
                    <div style="width: 50%;float: right; text-align: right;">
                        <img :src="logourl" style="max-height: 35px;">
                    </div>
    
                </div>
                <div style="width:100%;display:block;overflow:auto; float:left; padding-right:10px; padding-top:20px; max-height:800px; margin-bottom:20px">
                    <h4>{{ openCoordinate.title }} Snaglist</h4>
                    <p> Report Created On {{ new Date() | moment("DD-MM-YYYY") }}</p>
                    <img :src="reportdataPointt[0].pointt" style="width:80%; float: left; padding:5px;border:0px solid #ccc">
                    <!-- <canvas id="canvas1" width="800px" height="400px" v-if="reportStart"></canvas> -->
                </div>
                <!-- <a href="https://  " style="float: right;text-align: right;text-decoration: none; color: rgb(0, 0, 0);">   </a>  -->
                <div class="html2pdf__page-break"></div>
                <v-row style="padding: 2%;" v-for="(comment, index) in filterdata" :key="comment.cmt_id">
    
                    <v-col cols="6" md="6" style="">
                        <!-- <v-img class="imgmax_height" :src="comment.thumbnail_url_bs64" style="width: 100% cursor: pointer;" max-height="350" height="350px"> </v-img> -->
                        <img :src="comment.thumbnail_url_bs64" width="100%" height="300" style="max-height:300px;">
                    </v-col>
                    <v-col cols="6" md="6" style="">
                        <span><b>Date: </b> {{dateFormate(comment.creat_at) | moment("DD-MM-YYYY")}} </span>
                        <span style="position: absolute;right: 4%;"><b>Sent by: </b> {{comment.sender_name}}</span>
                        <div>
                            <br>
                            <p><b>Title: </b> {{(comment.cmt_title.substring(0,55)+"..")}}</p><br>
                            <p :title="comment.comment_txt"><b>Description: </b> {{(comment.comment_txt.substring(0,121)+"..")}}</p>
                            <br>
                            <p><b>Root Cause: </b> {{(comment.root_cause.substring(0,55)+"..")}}</p><br>
                            <p><b>Assigned to: </b>{{(comment.receiver_emails.substring(0,45)+"..")}}</p><br>
                        </div>
    
                        <table class="table1">
                            <tr>
                                <td class="td1"><b>Type: </b>{{ comment.cmt_type }}</td>
                            </tr>
                            <tr>
                                <td class="td1"><b>Status </b>
                                    <v-btn :color="comment.comment_status == 1 ? 'green':'red'" small :class="`mr-1 black--text ${comment.user_id == userdata.id ? 'cck':'notclick'}`" @click="meckReaded(comment)">
    
                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
    
                                    </v-btn>
                                </td>
                                <td class="td1"><b>Due Date: </b>{{dateFormate(comment.due_date)| moment("DD-MM-YYYY")}} </td>
                            </tr>
                        </table>
                        <br>
                    </v-col>
                    <div style="position: relative;left: 93%;bottom: 8%;">
                        <a v-if="(index+1) % 2 == 0" href="https://  " style="float: left; text-align: left; text-decoration: none; color: rgb(0, 0, 0);"> </a>
                    </div>
                    <div class="html2pdf__page-break" v-if="(index+1) % 2 == 0">.</div>
                </v-row>
            </div>
        </section>
    </vue-html2pdf>
    
    <!---All points snaglist report ---->
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1400" :margin="[30, 0, 30, 0]" :filename="drawing.name+' '+snagliststatuss+' Snag-list'" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1130px" @progress="onProgress_snaglist($event)" @hasDownloaded="onDownloaded_snaglist()" @hasStartedGeneration="hasStartedGeneration_snaglist()" @hasGenerated="hasGenerated_snaglist($event)" ref="html2Pdfdrawingsnaglist">
        <section slot="pdf-content">
            <div id="pdf_template" style="margin-left:75px;margin-right:75px">
    
                <div style="width:100%;display:block;overflow:auto; height: 80px; border-bottom: 2px solid black; padding: 30px 30px 30px 0px">
                    <div style="width:50%;float:left;">
                        <div>
                            <h3> {{project_name}} - {{org}} {{city}}</h3>
                        </div>
                        <div> {{ drawing.name }} - {{ currentZoneName }} </div>
                    </div>
                    <div style="width: 50%;float: right; text-align: right;">
                        <img :src="logourl" style="max-height: 35px;">
                    </div>
    
                </div>
                <div style="width:100%;display:block;overflow:auto; float:left; padding-right:10px; padding-top:20px; max-height:800px; margin-bottom:20px">
                    <h4>{{ snagliststatuss }} Snag-list Report</h4>
                    <p> Report Created On {{ new Date() | moment("DD-MM-YYYY") }}</p>
                    <img :src="drawing.drawingpath" style="width:80%; float: left; padding:5px;border:0px solid #ccc">
    
                </div>
    
                <div class="html2pdf__page-break"></div>
                <v-row style="padding: 2%;">
                    <v-col cols="12" md="6" style="" v-for="(comment, index) in drawing_commentsdataa" :key="comment.cmt_id">
                        <v-row>
                            <v-col cols="6" md="6" style="">
    
                                <img :src="comment.thumbnail_url_bs64" width="100%" height="200" style="max-height:200px;">
                            </v-col>
                            <v-col cols="6" md="6" style="">
    
                                <div>
                                    <br>
                                    <p><b>Title: </b> {{(comment.cmt_title.substring(0,55)+"..")}}</p><br>
                                    <p :title="comment.comment_txt"><b>Area: </b> {{ comment.coordinate_name }}</p>
                                    <br>
    
                                </div>
    
                                <table class="table2">
                                    <tr>
                                        <td class="td1"><b>Type: </b>{{ comment.cmt_type }}</td>
                                    </tr>
                                    <tr>
                                        <td class="td1"><b>Status </b>
                                            <v-btn :color="comment.comment_status == 1 ? 'green':'red'" small :class="`mr-1 black--text ${comment.user_id == userdata.id ? 'cck':'notclick'}`" @click="meckReaded(comment)">
    
                                                {{ comment.comment_status == 1 ? 'Completed':'Pending' }}
    
                                            </v-btn>
                                        </td>
                                        <td class="td1"><b>Due Dt: </b>{{dateFormate(comment.due_date)| moment("DD-MM-YYYY")}} </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                        <div style="position: relative;left: 90%;bottom: -7%;">
                            <a v-if="(index+1) % 6 == 0" href="https://  " style="float: left; text-align: left; text-decoration: none; color: rgb(0, 0, 0);"> </a>
                        </div>
                        <div class="html2pdf__page-break" v-if="(index+1) % 6 == 0">.</div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </vue-html2pdf>
     <v-snackbar
          timeout="5000"
          v-model="vedioalert"
          absolute
          top
          color="success accent-2"
          right
          tile
        >
           Please wait while the 360° video is loading.
        </v-snackbar>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import logo from "../../assets/ov.png";
    import Preloader from '../../components/PreLoader'
    import image1 from '../../assets/e.png'
    import vthumbnail from '../../assets/uservuethumbnails.jpg'
    import narmalvideos from '../../assets/video_thumbnail.png'
    import casmangle from '../../assets/casmangle.png'
    import rotate from '../../assets/rotedmobilepsd.jpg'
    
    import {
        jsPDF
    } from 'jspdf'
    import 'jspdf-autotable'
    import $ from 'jquery'
    import VuePannellum from 'vue-pannellum';
    import JsonExcel from "vue-json-excel";
    import carousel from 'vue-owl-carousel'
    import {
        locals
    } from "../../utils/locals";
    import {
        ImageEditor
    } from "@toast-ui/vue-image-editor";
    import "tui-image-editor/dist/tui-image-editor.css";
    import image2 from "../../assets/FKUg3r.jpg";
    import colors from "../../assets/color.png";
    import VueHtml2pdf from 'vue-html2pdf'
    import {
        Pano
    } from 'vuejs-vr';
    import vuePhotoZoomPro from 'vue-photo-zoom-pro'
    import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
    import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
    import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
    //import ForgeVuer from 'forge-vuer';
    // import {
    //     Carousel3d,
    //     Slide
    // } from 'vue-carousel-3d';
    
    export default {
        components: {
            Preloader,
            'VPannellum': VuePannellum,
             Pano,
            // Carousel3d,
            // Slide,
            "downloadExcel": JsonExcel,
            carousel,
            "tui-image-editor": ImageEditor,
            VueHtml2pdf,
            vuePhotoZoomPro,
            XlsxRead,
            XlsxJson
            // ForgeVuer
        },
        name: "interiorViewEdit",
        data() {
            return {
                colors: colors,
                zoneMessage: false,
                preloader: false,
                casmangle: casmangle,
                rotate: rotate,
                projectid: 0,
                requerdRules: [
                    v => !!v || this.lang.required,
                ],
                cmt_titlerules: [
                    value => !!value || this.lang.required,
                    value => (value || '').length <= 60 || 'Max 60 characters',
                ],
                cmt_descrules: [
                    value => !!value || this.lang.required,
                    value => (value || '').length <= 1000 || 'Max 1000 characters',
                ],
                cmt_causerules: [
                    value => (value || '').length <= 300 || 'Max 300 characters',
                ],
                zoneid: 0,
                image1: image1,
                loafin: false,
                drawings: {},
                uploadDrawignaForm: false,
                drawing: {
                    name: 'Select Drawing',
                    drawingpath: image1
                },
                proj_dir: '',
                drawingname: '',
                floor: '',
                area: '',
                thumbnail: '',
                send: false,
                valid: true,
                alertmessage: '',
                alert: false,
                addZoneform: false,
                validzone: true,
                newzonename: '',
                currentDrawingName: 'Select Drawing',
                drawingToggle: false,
                activeDrawing: {},
                cordinates: [],
                cordinatInfo: false,
                openCoordinate: {},
                x: 0,
                y: 0,
                xc: 0,
                yc: 0,
                xf: 0,
                yf: 0,
                colum: false,
                slides: [],
                activet: '',
                fulllImage: true,
                full: false,
                imagenormal: '',
                imagepanaroma: '',
                pvideo: '',
               
                videosthumb : '',
               
                down: false,
                selected: [],
                secondimagenormal: '',
                imagenormalcompare: false,
                secondimagepanaroma: '',
                uniquedates: {},
                commmentForm: false,
                commentsdata: false,
                commentsdataa: {},
                drawing_commentsdata: false,
                drawing_commentsdataa: false,
                commentbox: false,
                comment: '',
                userdata: {},
                taskbox: false,
                search: '',
                tasks: [],
                tasktype: {},
                logo: logo,
                headers: [{
                        text: 'Task',
                        align: 'start',
                        value: 'taskname',
                        width: 180,
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        width: 200,
                    },
                    {
                        text: 'Planned Start',
                        value: 'start_date',
                        width: 150,
                    },
                    {
                        text: 'Planned End',
                        value: 'end_date',
                        width: 150,
                    },
                    {
                        text: 'Actual Start',
                        value: 'actual_start',
                        width: 150,
                    },
                    {
                        text: 'Actual End',
                        value: 'actual_end',
                        width: 150,
                    },
                    {
                        text: 'Progress ',
                        value: 'progress',
                        width: 150,
                    },
    
                    {
                        text: 'Assign To',
                        value: 'receiver_emails',
                        width: 100,
                    },
                    {
                        text: 'Action',
                        value: 'id',
                        width: 200,
                    }
                ],
                commentImage: '',
                showstanderoption: false,
                newTaskForm: false,
                newTask: {
                    taskname: '',
                    taskstatus: '',
                    taskstartdate: '',
                    taskenddate: '',
                    actualstartdate: '',
                    actualenddate: '',
                    taskprogress: 0,
                    tasktag: '',
                    headingtype: false
                },
                updateTask: {
                    taskname: '',
                    taskstatus: '',
                    taskstartdate: '',
                    taskenddate: '',
                    actualstartdate: '',
                    actualenddate: '',
                    taskprogress: 0,
                    tasktag: '',
                    receiver_emails: '',
                    headingtype: false
                },
                updateTaskForm: false,
                singleSelect: false,
                selectedtask: [],
                reportToggle: false,
                reportToggle1: false,
                reportToggle2: false,
                menu3: false,
                menu4: false,
                menu5: false,
                menu6: false,
                menu7: false,
                menu8: false,
                menu9: false,
                menu10: false,
                otaskdata: [],
                cumulative_data: [],
                cordinatedata: [],
                exceldata: {},
                json_fields: {
                    id : 'id',
                    Heading : 'Heading',
                    Area: 'Area',
                    Task: 'Task',
                    Status: 'Status',
                    Start: 'Start',
                    End: 'End',
                    Actual_Start: 'Actual Start',
                    Actual_End: 'Actual End',
                    Progress: 'Progress'
    
                },
                coordinatejson_fields: {
                    Area: 'Area',
                    Task: 'Task',
                    Type: 'Type',
                    Status: 'Status',
                    Start: 'Start',
                    End: 'End',
                    Actual_Start: 'Actual Start',
                    Actual_End: 'Actual End',
                    Progress: 'Progress'
    
                },
                events: [],
                input: null,
                nonce: 0,
                commentopenImage: false,
                compatedate: '',
                compatedatefirst: '',
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                refresh: 0,
                refresh3: 100,
                hfov: 75,
                yaw: 0,
                pitch: 0,
                draggable: true,
                mouseZoom: true,
                doubleClickZoom: true,
                showZoom: true,
                showFullscreen: true,
                lock: false,
                status: ['Not started', 'In progress', 'Completed'],
                latestPanaromas: [],
                downlatest: false,
                latesttrue: false,
                insids: true,
                sliderActive: false,
                activesliderImage: '',
                index: 0,
                uploadat: '',
                titile: '',
                cmt_title: '',
                cmt_type: null,
                cmt_status: [{
                        cmt_status: "0",
                        title: "Pending"
                    },
                    {
                        cmt_status: "1",
                        title: "Completed"
                    },
                ],
                cmt_status_val: null,
                cmt_type_op: [{
                        cmt_type_op: "Access Control",
                        title: "Access Control"
                    },
                    {
                        cmt_type_op: "Carpentry",
                        title: "Carpentry"
                    },
                    {
                        cmt_type_op: "CCTV",
                        title: "CCTV"
                    },
                    {
                        cmt_type_op: "Civil & Wet Works",
                        title: "Civil & Wet Works"
                    },
                    {
                        cmt_type_op: "Decoratives",
                        title: "Decoratives"
                    },
                    {
                        cmt_type_op: "Design",
                        title: "Design"
                    },
                    {
                        cmt_type_op: "Electrical & Lighting",
                        title: "Electrical & Lighting"
                    },
                    {
                        cmt_type_op: "FAPA System",
                        title: "FAPA System"
                    },
                    {
                        cmt_type_op: "Finance",
                        title: "Finance"
                    },
                    {
                        cmt_type_op: "Flooring",
                        title: "Flooring"
                    },
                    {
                        cmt_type_op: "Furnishing",
                        title: "Furnishing"
                    },
                    {
                        cmt_type_op: "Glass Partition & Doors",
                        title: "Glass Partition & Doors"
                    },
                    {
                        cmt_type_op: "Gyspsum Partition",
                        title: "Gyspsum Partition"
                    },
                    {
                        cmt_type_op: "HVAC",
                        title: "HVAC"
                    },
                    {
                        cmt_type_op: "Loose & Modular Furniture",
                        title: "Loose & Modular Furniture"
                    },
                    {
                        cmt_type_op: "Planning",
                        title: "Planning"
                    },
                    {
                        cmt_type_op: "Quality",
                        title: "Quality"
                    },
                    {
                        cmt_type_op: "Rework",
                        title: "Rework"
                    },
                    {
                        cmt_type_op: "Safety",
                        title: "Safety"
                    },
                    {
                        cmt_type_op: "Other",
                        title: "Other"
                    }
                ],
    
                cmt_assigned_to: '',
                date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu1: false,
                commetnsemail: [],
                commetnsemail_extra: '',
                emailrules_req: [
                    v => /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)?$/.test(v) || 'E-mail must be valid',
    
                ],
                emailrules: [
                    v => /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(v) || 'E-mail must be valid',
    
                ],
                cmt_rootcause: '',
                project_name: '',
                sliderloader: true,
                angle: false,
                anglez: 0,
                Local: locals,
                lang: {},
                part1: '',
                part2: '',
                part3: '',
                part4: '',
                points: '',
                reportdata: [],
                snaglistdataa: [],
                reportdataPoint: [],
                initvlaue: 0,
                initvlauePoint: 0,
                msg: 'Collecting Images...',
                reportStart: false,
                progree: '0/0',
                currentZoneName: 'Zone Name',
                logourl: '',
                showannotation: false,
                options: {
                    cssMaxWidth: 700,
                    cssMaxHeight: 500,
                    includeUI: {
                        loadImage: {
                            path: image2,
                            name: "SampleImage",
                        },
                        initMenu: "draw",
                        menuBarPosition: "left",
    
                    },
                    ErrorMsg: "Sorry Image is not load",
                },
                reportdialogbox: false,
                org: '',
                city: '',
                progress_per: 0,
                reportdataPointt: [],
                magnify: false,
                imaglossp: 50,
                cordinatesOriginal: [],
                openCoordinateindex: 0,
                paddingActive: false,
                myToken: "",
                myObjectUrn: "",
                currentProject: {},
                autodeskcompare: false,
                getusers: [],
                pointreport_msg: false,
                cmtdiscuss_dialog: false,
                add_disnote: '',
                cmt_project_id: '',
                cmt_coordinate: '',
                cmtt_id: '',
                updatecamdata: {},
                searchloader: false,
                cmtSearchdata: {},
                searchtext: "",
                autodesk_viewer: 0,
                cmt_data_d: {},
                filterdrawer: null,
                filter_status: "",
                filter_type: "",
                filter_duedate: "",
                showadd: false,
                docloaded: false,
                viewerbim: '',
                first: true,
                allcordinatedata: [],
                getdrawingid: '',
                ImgHeight: 600,
                screenH: 768,
                searchtext1: "",
                filter_status1: "",
                filter_type1: "",
                filter_duedate1: "",
                snagliststatuss: '',
                positions: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                },
                positionsouter: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                },
                zoom: 1,
                width: 0,
                interior: 0,
                usernativeInterior: true,
                commetnsemailextermal: '',
                assingtoMorebox: false,
                assingtoTaskbox: false,
                moreEmails: '',
                opencmt: '',
                openTask: '',
                filterdata: {},
                taskdiscuss_dialog: false,
                taskDiscData: {},
                expand: false,
                avarage: 0,
                allavarage: 0,
                showcoloritem: false,
                view_reportnote: [],
                addnote: '',
                radius: 8,
                videotype : 'normal',
                vedioalert :  false,
                importFile : false,
                filedata : {},
                file : null,
                reportdates: {},
                selectReportDate : '',
                schedulereportbox : false,
                reportemails : ''
            };
        },
        mounted() {
            // this.userdata = JSON.parse(localStorage.getItem('userdata'));
            // if (this.userdata.open_access == 1) {
            //     this.$router.push('/dashboard')
            // }
        },
        created() {
            this.$root.$refs.interiorViewEdit = this;
            console.log("window.innerHeight", window.innerHeight);
    
            console.log("window.innerWidth", window.innerWidth);
    
            this.width = window.innerWidth;
    
            // this.$refs.draggableContainer.addEventListener("wheel",this.handleScroll)
            this.screenH = window.innerHeight;
    
            //  this.getToken();
            var selectleng = this.$cookies.get('currentLanguage');
            this.lang = locals[selectleng];
            this.currentZoneName = localStorage.getItem('currentZoneName');
            this.headers = [{
                    text: this.lang.Task,
                    align: 'start',
                    value: 'taskname',
                    width: 180,
                },
                {
                    text: this.lang.Status,
                    value: 'status',
                    width: 200,
                },
                {
                    text: this.lang.PlannedStart,
                    value: 'start_date',
                    width: 150,
                },
                {
                    text: this.lang.PlannedEnd,
                    value: 'end_date',
                    width: 150,
                },
                {
                    text: 'Actual Start',
                    value: 'actual_start',
                    width: 150,
                },
                {
                    text: 'Actual End',
                    value: 'actual_end',
                    width: 150,
                },
                {
                    text: this.lang.Progress,
                    value: 'progress',
                    width: 150,
                },
                {
                    text: 'Action',
                    value: 'id',
                    width: 250,
                }
            ]
    
            var _self = this;
            setTimeout(() => {
                _self.userdata = JSON.parse(localStorage.getItem('userdata'));
                if (_self.userdata.user_code == 2 || _self.userdata.user_code == 0) {
                    _self.useraccess = true
                }
                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                //console.log("Current data get",this.$store.state.currentCamera)
                if (currentProject === null) {
                    _self.$router.go();
                }
                // console.log("projectdata==>", currentProject === null);
                _self.cam_prefix = _self.$store.state.currentCamera.camera_prefix;
                _self.projectid = currentProject.id;
                _self.proj_dir = currentProject.pro_dir;
                _self.project_name = currentProject.project_name;
                _self.zoneid = _self.$store.state.currentZone;
                _self.autodesk_viewer = currentProject.autodesk_viewer;
                _self.interior = currentProject.interior;
                _self.initViewer();
                _self.getDrawings();
    
                document.body.addEventListener('keyup', e => {
                    if (e.keyCode === 27) {
                        _self.cordinatInfo = false;
                    }
                }, 500)
                this.$refs.draggableContainer.addEventListener('wheel', this.handleScroll)
    
            }, 2000)
    
            setTimeout(() => {
                if (_self.width < 750) {
                    this.zoom = 0.3;
                    console.log("evet 750 loade");
                    _self.$refs.draggableContainer.style.transform = 'scale(0.3)';
                    _self.$refs.draggableContainer.style.left = '0px';
                    _self.$refs.draggableContainer.style.top = '70px';
                }
            }, 10000)
    
        },
        watch: {
            '$route': function (to) {
                console.log("Router change", to)
            },
            '$store.state.currentZone': function () {
                // console.log("Current data get",this.$store.state.currentCamera)
                this.zoneid = this.$store.state.currentZone;
                //console.log("current zone", this.zoneid);
                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                this.projectid = currentProject.id;
                this.proj_dir = currentProject.pro_dir;
                this.project_name = currentProject.project_name;
                this.autodesk_viewer = currentProject.autodesk_viewer;
                this.getDrawings();
                this.currentZoneName = localStorage.getItem('currentZoneName');
                this.org = currentProject.company;
                this.city = currentProject.city;
                this.interior = currentProject.interior;
                this.getUsersByProject();
                //this.getToken();
    
            },
            '$store.state.reportToggle': function () {
    
                this.reportToggle = this.$store.state.reportToggle;
                // console.log(this.reportToggle);
    
                this.dataExcel();
    
            },
            '$store.state.reportToggle1': function () {
    
                this.reportToggle1 = this.$store.state.reportToggle1;
                //console.log(this.reportToggle1);
    
            },
            '$store.state.reportToggle2': function () {
                console.log("click by snaglist");
                this.preloader = true;
                this.getCommnets_bydrawing();
                this.reportToggle2 = this.$store.state.reportToggle2;
                // console.log(this.reportToggle2);
    
            },
            '$store.state.drawingToggle': function () {
    
                this.drawingToggle = this.$store.state.drawingToggle;
                // console.log(this.drawingToggle);
    
            },
            hfov(val) {
                console.log("hfov", val);
            },
    
            pitch(val) {
                console.log("pitch", val);
            },
            options: function (nval) {
                this.tui_re_render++;
                console.log(" ttui => ", nval);
            },
        },
        computed: {
            timeline() {
                return this.events.slice().reverse()
            },
            // drag: function (v){
    
            //     console.log("compute scrooling",v);
    
            //       //return a;
    
            // }
            param_add() {
                return {
                    cmt_id: this.cmtt_id,
                    co_id: this.cmt_coordinate,
                    dis_note: this.add_disnote,
                    coordinatename: this.openCoordinate.title,
                    projectname: this.project_name,
                    drawingname: this.drawing.name,
                    zoneid: this.zoneid
    
                };
            },
            taskData() {
                return {
                    task_id: this.task_id,
                    co_id: this.cmt_coordinate,
                    disc_note: this.add_disnote,
                    coordinatename: this.openCoordinate.title,
                    projectname: this.project_name,
                    drawingname: this.drawing.name,
                    zoneid: this.zoneid
    
                };
            },
            filteredList() {
                if (this.searchtext) {
                    //  console.log("search cmt", this.searchtext);
                    return this.commentsdata.filter(comment => {
                        var result = comment.cmt_title.toLowerCase().includes(this.searchtext.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext.toLowerCase())
                        return result;
                    })
                } else if (this.filter_status) {
                    // console.log("cmt status", this.filter_status);
                    //this.filter_status == 1 ? 'Completed':'Pending';
                    return this.commentsdata.filter(comment => {
                        var result = comment.comment_status == this.filter_status && comment.cmt_type.toLowerCase().includes(this.filter_type.toLowerCase());
                        //console.log("filtered data", this.commentsdata);
                        return result;
                    })
                } else if (this.filter_type) {
                    // console.log("cmt type", this.filter_type);
                    return this.commentsdata.filter(comment => {
                        var result = comment.cmt_type.toLowerCase().includes(this.filter_type.toLowerCase())
                        return result;
                    })
                } else if (this.filter_duedate) {
                    // console.log("duedate filter", this.filter_duedate);
                    return this.commentsdata.filter(comment => {
                        var admission = this.$moment(comment.due_date, "YYYY-MM-DD");
                        var discharge = this.$moment(new Date(), "YYYY-MM-DD");
                        //  console.log("check date", admission, discharge);
                        var difference = discharge.diff(admission, 'days');
                        // console.log("date difference", difference);
                        if (difference >= 1) {
                            var result = comment.comment_status == this.filter_duedate
                            return result;
                        }
                    })
                } else {
                    return this.commentsdata.filter(comment => {
                        var result = comment.cmt_title.toLowerCase().includes(this.searchtext.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext.toLowerCase())
                        return result;
                    })
                }
    
            },
            filteredList_drawing() {
                if (this.searchtext1) {
                    //  console.log("search cmt", this.searchtext);
                    return this.drawing_commentsdata.filter(comment => {
                        var result = comment.cmt_title.toLowerCase().includes(this.searchtext1.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext1.toLowerCase())
                        return result;
                    })
                } else if (this.filter_status1) {
                    console.log("cmt status", this.filter_status1);
                    //this.filter_status == 1 ? 'Completed':'Pending';
                    return this.drawing_commentsdata.filter(comment => {
                        var result = comment.comment_status == this.filter_status1 && comment.cmt_type.toLowerCase().includes(this.filter_type1.toLowerCase());
                        //console.log("filtered data", this.commentsdata);
                        return result;
                    })
                } else if (this.filter_type1) {
                    console.log("cmt type", this.filter_type1);
                    return this.drawing_commentsdata.filter(comment => {
                        var result = comment.cmt_type.toLowerCase().includes(this.filter_type1.toLowerCase())
                        return result;
                    })
                } else if (this.filter_duedate1) {
                    console.log("duedate filter", this.filter_duedate1);
                    return this.drawing_commentsdata.filter(comment => {
                        var admission = this.$moment(comment.due_date, "YYYY-MM-DD");
                        var discharge = this.$moment(new Date(), "YYYY-MM-DD");
                        //  console.log("check date", admission, discharge);
                        var difference = discharge.diff(admission, 'days');
                        // console.log("date difference", difference);
                        if (difference >= 1) {
                            var result = comment.comment_status == this.filter_duedate1
                            return result;
                        }
                    })
                } else {
                    return this.drawing_commentsdata.filter(comment => {
                        var result = comment.cmt_title.toLowerCase().includes(this.searchtext.toLowerCase()) || comment.comment_txt.toLowerCase().includes(this.searchtext1.toLowerCase())
                        return result;
                    })
                }
    
            }
        },
        methods: {
    
            updateEmailforReport(){
    
                this.preloader = true;
                  
                  var emails = this.reportemails;
    
                    var  formdata = {
                        drawingid : this.getdrawingid,
                        projectid : this.projectid,
                        email : emails
                    }
    
                   axios({
                        url: '/interior/repot-emails',
                        method: 'post',
                        data : formdata
                    })
                    .then(resp => {
                         console.log(resp);
                          this.schedulereportbox = false;
                          this.preloader = false;
                          this.drawing.emails = emails;
                    })
                    .catch(err => {
                        this.preloader = false;
                         this.schedulereportbox = false;
                        console.log(err);
                    })
            },
    
            Update_reportNote() {
    
                var v = this.addnote;
    
                this.view_reportnote.push(v);
    
                this.addnote = "";
    
            },
            delete_note(i) {
                this.view_reportnote.splice(i, 1);
            },
            handleScrollevent() {
    
                this.$refs.draggableContainer.removeEventListener;
    
            },
            // zoomInOut(event){
    
            // },
            handleScroll(event) {
    
                console.log("event triger.");
    
                var delta = Math.sign(event.deltaY);
                var p = this.zoom;
                console.log("var delta", delta);
                if (delta == -1) {
    
                    if (p < 4) {
                        p = this.zoom + 0.1;
                        console.log("Plus", p);
                        this.zoom = p;
                        this.$refs.draggableContainer.style.transform = 'scale(' + p + ')';
    
                        if (p > 1) {
                            this.$refs.moon.style.transform = 'scale(0.5)';
                            //this.radius = 5;
                        } else if (p > 2) {
                            this.$refs.moon.style.transform = 'scale(0.2)';
                            //this.radius = 2;
                        } else if (p > 3) {
                            this.$refs.moon.style.transform = 'scale(0.1)';
                            //this.radius = 1;
                        }
    
                    }
                } else {
                    // console.log(p);
                    if (p > 0.4) {
                        p = this.zoom - 0.1;
                        this.zoom = p;
                        console.log("minus", p);
                        this.$refs.draggableContainer.style.transform = 'scale(' + p + ')';
                    }
    
                    if (p > 1) {
                        this.$refs.moon.style.transform = 'scale(0.5)';
                      //  this.radius = 5;
                    } else if (p > 2) {
                        this.$refs.moon.style.transform = 'scale(0.2)';
                      //  this.radius = 2;
                    } else if (p > 3) {
                        this.$refs.moon.style.transform = 'scale(0.1)';
                     //   this.radius = 1;
                    }
    
                }
            },
    
            getToken() {
                //  console.log("first");
    
                this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
                var _self = this;
                if (this.currentProject.autodesk_viewer == 1) {
    
                    this.showadd = false;
    
                    axios({
                            url: '/interior/autodesk-token',
                            method: 'post',
                        })
                        .then(resp => {
                            this.myToken = resp.data.access_token;
                            // console.log("token", this.myToken);
    
                            if (this.currentProject.autodesk_urn) {
                                this.docloaded = true;
                            }
                            const Autodesk = window.Autodesk;
                            //  var viewer;
                            var options = {
                                env: 'AutodeskProduction2',
                                api: 'streamingV2', // for models uploaded to EMEA change this option to 'streamingV2_EU'
                                getAccessToken: function (onTokenReady) {
                                    var token = resp.data.access_token;
                                    var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                                    onTokenReady(token, timeInSeconds);
                                }
                            };
    
                            //  console.log("options",options);
    
                            Autodesk.Viewing.Initializer(options, function () {
    
                                var htmlDiv = document.getElementById('forgeViewertc');
                                _self.viewerbim = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                                var startedCode = _self.viewerbim.start();
                                if (startedCode > 0) {
                                    console.error('Failed to create a Viewer: WebGL not supported.');
                                    return;
                                }
    
                                // console.log('Initialization complete, loading a model next...');
    
                                var documentId = 'urn:' + _self.currentProject.autodesk_urn;
                                //    console.log("documentId", documentId);
                                Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
    
                                function onDocumentLoadSuccess(viewerDocument) {
    
                                    var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    
                                    //   console.log("defaultModel",defaultModel);
    
                                    _self.viewerbim.loadDocumentNode(viewerDocument, defaultModel);
                                    _self.viewerbim.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, selectionChanged);
    
                                    function selectionChanged(dd) {
                                        console.log(dd);
                                    }
    
                                    setTimeout(() => {
                                        var dbId = _self.openCoordinate.bim_dbid;
                                        _self.viewerbim.impl.selector.setSelection([dbId], _self.viewerbim.model);
                                        _self.viewerbim.fitToView([dbId]);
                                        _self.viewerbim.select([dbId])
                                    }, 20000)
    
                                }
    
                                function onDocumentLoadFailure() {
                                    console.error('Failed fetching Forge manifest');
                                }
    
                            });
    
                        })
                        .catch(err => {
                            console.log(err);
                            this.alert = true;
                            this.alertmessage = this.lang.SomethingWentWrong;
                            this.alertType = "error"
                            this.preloader = false
                        })
                } else {
                    this.showadd = true;
                }
            },
            myGetTokenMethodAsync: async function (onSuccess) {
    
                // console.log("second");
                // An API call to retrieve a valid token should be
                // done here. A backend service might need to be implemented.
    
                // For testing purposes, a valid token can be hardcoded but will 
                // last a maximum of 1 hour (3600 seconds.)
                // console.log("i am working..");
                let token = this.myToken;
                let expireTimeSeconds = 3599;
                onSuccess(token, expireTimeSeconds);
            },
            openautodeskview() {
                this.autodeskcompare = !this.autodeskcompare;
            },
            openautodeskviewFirst() {
    
                this.first = false;
                this.autodeskcompare = true;
                this.getToken();
            },
            validate() {
                this.$refs.form.validate()
            },
            initViewer() {
                // console.log("init Viewer");
                this.preloader = false;
                this.getOrg()
                this.getUsersByProject();
                // this.getcoordinates();
            },
            magnifytogle() {
                this.magnify = !this.magnify;
            },
            getOrg() {
                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                var org_id = currentProject.organization_id;
                var proj_id = currentProject.id;
    
                var data = {
                    org_id: org_id
                }
    
                var url = "/organization/" + proj_id;
                axios({
                        url: url,
                        method: "GET",
                        params: data
                    })
                    .then((resp) => {
    
                        //   console.log("resp...", resp.data.success);
                        this.logourl = resp.data.success.org_logo_base;
    
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getUsersByProject() {
    
                // console.log("activeProjectData", this.projectid);
    
                axios({
                        url: '/project/' + this.projectid + '/usersemail',
                        method: 'GET'
                    })
                    .then(resp => {
                        //  console.log("User data getting", resp.data.success);
                        this.getusers = resp.data.success;
                        this.preloader = false;
                    })
                    .catch(err => {
                        this.preloader = false;
                        console.log(err);
                    })
            },
            onchanges: function (v) {
    
                console.log("watch scrooling", v);
    
                var a = this.$refs.panaromascreen;
                // var b = this.$refs.panaromascreen2;
    
                var lefrr = a.viewer.getYaw();
                this.anglez = lefrr;
                console.log("lefrr", lefrr);
    
            },
            getDrawings() {
                this.$store.dispatch('drawingname', 'Loading...');
    
                // if(this.zoneid == 0){
                //     this.zoneMessage = true;
                // }
    
                axios({
                        url: '/interior/project/' + this.projectid + '/get-drawings',
                        method: 'get',
                        params: {
                            zone: this.zoneid
                        }
                    })
                    .then(resp => {
                        // console.log("headings", resp);
                        this.drawings = resp.data.success;
                        // console.log("dfasfasdf", resp.data)
    
                        if (resp.data.success.length > 0) {
                            console.log("is In", resp.data.success);
                            //  console.log("is In");
                            this.zoneMessage = false;
    
                            this.getDrawing(resp.data.success[0].drawingid);
                            this.getdrawingid = resp.data.success[0].drawingid;
                            console.log("check drawing id", this.getdrawingid);
                        } else {
                            this.zoneMessage = false;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getDrawingBase64(id){
    
                axios({
                        url: '/interior/get-drawingbyidbase64',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                       // this.refresh3++;
                        console.log("/get-drawingbyidbase64", resp);
                        this.drawing = resp.data.success[0];
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
    
            },
            getDrawing(id) {
                this.preloader = true;
                this.latesttrue = false;
                this.cordinatInfo = false;
                this.commentbox = false;
                this.colum = false;
                this.zoom = 1;
                this.goPositon(1);
                this.getDrawingReport(id);
                localStorage.setItem("openDrawing", id);
                axios({
                        url: '/interior/get-drawingbyid',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                        this.refresh3++;
                        //console.log("Drawing DTA", resp);
                        this.drawing = resp.data.success[0];
    
                         this.reportemails = this.drawing.emails;
    
                        this.radius = this.drawing.point_size;
    
                        var i = new Image();
    
                        var _self = this;
    
                        i.onload = function () {
                            // alert( i.width+", "+i.height );
    
                            // console.log("i.height", i.height);
    
                            _self.ImgHeight = i.height;
    
                            if (i.height > _self.screenH) {
    
                                var hh = _self.screenH - 50;
                                _self.ImgHeight = hh;
    
                                var loss = i.height - hh;
    
                                //    Loss percentage = (Loss × 100) / C.P
    
                                //     Loss % = (5 × 100) / 50
    
                                //     Loss % = 500 / 50
    
                                //     Loss % = 10
    
                                var Losspercentage = (loss * 100) / i.height;
    
                                this.imaglossp = Losspercentage;
    
                                //  console.log("Loss percentage", this.imaglossp);
                                _self.getcoordinates(resp.data.success[0].drawingid, Losspercentage);
                                _self.getcoordinatesOrg(resp.data.success[0].drawingid);
    
                            } else {
                                _self.getcoordinates(resp.data.success[0].drawingid, 0);
                                _self.getcoordinatesOrg(resp.data.success[0].drawingid);
                            }
                        };
                        i.src = this.drawing.drawingpath;
    
                        // this.getcoordinates(resp.data.success[0].drawingid,Losspercentage);
                        this.$store.dispatch('drawingname', resp.data.success[0].name);
                        setTimeout(() => {
                            if (_self.width < 750) {
                                this.zoom = 0.3;
                                console.log("evet 750 loade");
                                _self.$refs.draggableContainer.style.transform = 'scale(0.3)';
                                _self.$refs.draggableContainer.style.left = '0px';
                                _self.$refs.draggableContainer.style.top = '70px';
                            }
    
                            if (_self.width >= 700) {
                                //_self.radius = 5;
                            }
                            if (_self.width > 1000) {
                               // _self.radius = 3;
                            }
                        }, 2000)
    
                        setTimeout(() => {
    
                             _self.getDrawingBase64(id);
                            
                        }, 10000)
    
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            dowloadReportBydate(){
              
                      this.preloader = true;
                      console.log(this.selectReportDate);
                     // this.getReporUrl(this.selectReportDate);
    
                        axios({
                        url: '/interior/report-files/'+ this.projectid,
                        method: 'get',
                        params: {
                            drawingid: this.getdrawingid,
                            selectdate : this.selectReportDate
                        }
                         })
                       .then(resp => {
                          this.preloader = false
                          console.log("resp.data.success",resp.data.success);
    
                           resp.data.success.forEach(c => {
                                 this.getReporUrl(c.report_path);
                           })
                           this.clsoeReport1();
                           this.preloader = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
                        
            },
            getReporUrl(path){
    
                 axios({
                        url: '/get_sign_url_by_key',
                        method: 'get',
                        params: {
                            key: path,  
                            exp : '20 minutes',
                            project_id : this.projectid
                        }
                         })
                    .then(resp => {
                         console.log(resp);
                          //this.reportdates = resp.data.success.resp;
                          window.open(resp.data.success.url, '_blank');
                          
                        this.clsoeReport1();
                           this.preloader = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
    
            },
            getDrawingReport(id){
    
                 axios({
                        url: '/interior/report-date/'+ this.projectid,
                        method: 'get',
                        params: {
                            drawingid: id
                        }
                         })
                    .then(resp => {
                        this.reportdates = resp.data.success;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
    
            },
            goToDrawingdirect() {
    
                var openDrawing = localStorage.getItem("openDrawing");
    
                this.$router.push({
                    name: 'interior_viewedit',
                    params: {
                        id: openDrawing
                    }
                });
            },
            getNormalImages(t) {
                this.preloader = true;
                this.down = false;
                this.commentbox = false;
                //  console.log("Normal Images");
                this.refresh3++;
                this.slides = [];
                this.selected = [];
                axios({
                        url: '/interior/get-media',
                        method: 'get',
                        params: {
                            coordi: this.openCoordinate.coordinateid,
                            type: t
                        }
                    })
                    .then(resp => {
                        //  console.log("Normal Images", resp.data.success);
    
                        this.activet = t;
    
                        this.slides = resp.data.success
    
                        this.saved = resp.data.success
    
                        this.filterdate(resp.data.success)
                        this.insids = false;
                        this.preloader = false;
    
                        // console.log("this.slides", this.slides);
    
                        // console.log("this.latestPanaromas", this.latestPanaromas);
    
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getcommentImages(url) {
                this.colum = true;
                this.autodeskcompare = true;
                this.zoom = 0.5;
                this.goPositon(0.4);
                this.imagepanaroma = url;
                this.activet = 'panaroma';
            },
            getlatestImages() {
                this.preloader = true;
                //this.down = false;
                //this.commentbox = false;
                this.autodeskcompare = true;
                //  console.log("Normal Images");
                this.refresh3++;
                //this.slides = [];
                //this.selected = [];
                axios({
                        url: '/interior/get-media-latest_cmt',
                        method: 'get',
                        params: {
                            coordi: this.openCoordinate.coordinateid,
                            type: "panaroma",
                        }
                    })
                    .then(resp => {
                        //  console.log("Normal Images", resp.data.success[0].mediapath);
                        this.colum = true;
                        this.zoom = 0.5;
                        this.goPositon(0.4);
                        this.imagepanaroma = resp.data.success[0].mediapath;
                        this.activet = "panaroma";
    
                        // this.slides = resp.data.success
    
                        //this.saved = resp.data.success
    
                        // this.filterdate(resp.data.success)
                        // this.insids = false;
                        this.preloader = false;
    
                        // console.log("this.slides", this.slides);
    
                        // console.log("this.latestPanaromas", this.latestPanaromas);
    
                    }).catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            goToDrawing(n) {
                this.getdrawingid = n.drawingid;
                console.log("check drawing id", this.getdrawingid);
                this.currentDrawingName = n.name;
                 this.radius = n.point_size;
    
                this.activeDrawing = n;
    
                this.getDrawing(n.drawingid);
    
                this.drawingToggle = false;
                //this.latesttrue = true;
                this.refresh++;
                this.refresh3++;
    
                this.$store.dispatch('openDrawing', false);
                this.$store.dispatch('drawingname', n.name);
    
            },
            submitZoneform() {
                axios({
                        url: '/interior/project/' + this.projectid + '/add-zone',
                        method: 'get',
                        params: {
                            zonename: this.newzonename
                        }
                    })
                    .then(() => {
                        this.alert = true;
                        this.alertmessage = this.lang.addzone;
                        this.addZoneform = false;
                        this.$router.go();
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            drawingMenuToggle() {
    
                this.drawingToggle = !this.drawingToggle;
            },
            downUp() {
                this.down = !this.down;
    
            },
    
            openTooltip(n, index) {
    
                this.clear_filter();
    
                //  console.log("index", index);
                this.commentsdataa = false;
                if (n.cateone == "360 Videos Walk-through") {
    
                    this.openCoordinate = n;
                    this.cordinatInfo = false;
                    this.commentbox = false;
                    this.getNormalImages('video');
                    this.videotype = "360Videos";
    
                     this.videosthumb = vthumbnail;
    
                } else {
    
                      this.videotype = "normal"
                      this.videosthumb = narmalvideos;
                    //console.log("n",n);
    
                    this.cordinatInfo = false;
                    var _self = this;
                    var p = _self.zoom;
                    setTimeout(() => {
                        _self.cordinatInfo = true;
                        _self.angle = true;
    
                        if (p >= 2) {
                            _self.$refs.moon.style.transform = 'scale(0.4)';
                        } else if (p >= 3) {
                            _self.$refs.moon.style.transform = 'scale(0.05)';
                        } 
                        else if (p >= 4) {
                            _self.$refs.moon.style.transform = 'scale(0.05)';
                        }
                        else {
                            _self.$refs.moon.style.transform = 'scale(1)';
                        }
    
                    }, 150);
    
                    setTimeout(() => {
    
                        _self.x = n.x;
                        _self.y = n.y;
    
                        if (p >= 2) {
                            _self.y = n.y + 8;
                        } else if (p >= 3) {
                            _self.y = n.y + 8;
                        } else {
                            _self.y = n.y;
                        }
    
                        _self.xc = n.x + 100;
                        _self.yc = n.y;
    
                        _self.xf = n.x - 137 + 80 + 60;
                        _self.yf = n.y - 138 + 41 + 86;
    
                        if (n.x < 151) {
                            //  console.log("left side active");
    
                            this.paddingActive = true;
                            _self.x = n.x + 138;
                            _self.xf = n.x - 137 + 80 + 138;
                        } else {
                            this.paddingActive = false;
                        }
    
                    }, 100);
    
                    //  console.log(n);
                    this.openCoordinate = n;
                    this.openCoordinateindex = index;
    
                    if (n.bim_dbid !== null) {
    
                        //  console.log("viwer position change")
    
                        var dbId = n.bim_dbid;
    
                        _self.viewerbim.impl.selector.setSelection([dbId], _self.viewerbim.model);
                        _self.viewerbim.fitToView([dbId]);
                        _self.viewerbim.select([dbId])
    
                    }
                }
            },
            fullscreen() {
                this.full = true;
    
                //console.log("hfghf", this.full);
            },
            fullscreenexit() {
                this.full = false;
                this.fulllImage = false;
                this.colum = false;
                this.zoom = 1;
                this.goPositon(1);
                this.autodeskcompare = false;
                //console.log("hfghf", this.full);
            },
            closeAutodesk() {
                this.autodeskcompare = false;
            },
            getcoordinatesOrg(id) {
                axios({
                        url: '/interior/getcoordinates',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                        //  console.log("headings", resp);
    
                        this.cordinatesOriginal = resp.data;
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getcoordinates(id, pr) {
                axios({
                        url: '/interior/getcoordinates',
                        method: 'get',
                        params: {
                            did: id
                        }
                    })
                    .then(resp => {
                        //  console.log("headings", resp);
    
                        this.imaglossp = pr;
                        this.allcordinatedata = resp.data;
                        console.log("all coordinate data", this.allcordinatedata);
    
                        var numberofdata = 0;
                        var tatal = 0;
    
                        console.log("avarage", avarage);
    
                        this.cordinates = resp.data.filter(function (item) {
    
                            tatal += parseInt(item.progress);
                            numberofdata++;
    
                            var lox = item.x - (item.x * pr) / 100;
                            item.x = lox;
    
                            // item.xoo = item.x;
    
                            var loy = item.y - (item.y * pr) / 100;
                            item.y = loy;
    
                            // item.yoo = item.y;
    
                            if (item.cateone == "360 Videos Walk-through") {
    
                                item.fillcolor = '{"fillColor":"ff3131"}';
    
                            } else {
                                item.fillcolor = '{"fillColor":"fdc004"}';
                            }
    
                            return 1 == 1;
    
                        });
    
                        var avarage = tatal / numberofdata;
    
                        this.allavarage = avarage;
    
                        console.log("cordinates data", tatal, numberofdata);
    
                        this.updatedrawingProgresss(avarage);
    
                        this.coorddinates();
    
                        var _self = this;
                        setTimeout(() => {
                            _self.coorddinates();
                            _self.accorddinateLatestImage();
                            $('.map').maphilight({
                                strokeColor: "000000",
                                strokeWidth: 2,
                                stroke: true,
                                fillOpacity: 0.9,
                                alwaysOn: true
                            });
                            _self.preloader = false;
                        }, 1000);
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            updatedrawingProgresss(progress) {
    
                axios({
                        url: '/interior/update-progress-drawing',
                        method: 'get',
                        params: {
                            did: this.getdrawingid,
                            progress: progress
                        }
                    })
                    .then(resp => {
                        console.log("sssssssssss", resp);
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
    
            },
            accorddinateLatestImage() {
    
                this.latestPanaromas = [];
                this.latesttrue = false;
                this.sliderloader = true;
                // console.log("this.cordinates", this.cordinates);
                // var _self = this;
                this.cordinates.forEach((c, index) => {
    
                    //  console.log(this.cordinatesOriginal[index].coordinateid, c.coordinateid);
                    //  console.log(this.cordinatesOriginal[index].x, c.x);
                    //   console.log(this.cordinatesOriginal[index].y, c.y);
    
                    //  console.log("c.coordinateid", c.coordinateid);
                    axios({
                            url: '/interior/get-media-latest',
                            method: 'get',
                            params: {
                                coordi: c.coordinateid,
                                type: 'panaroma'
                            }
                        })
                        .then(resp => {
                            //   console.log("Laterst Images Images", resp.data.success);
    
                            if (resp.data.success.length > 0) {
                                resp.data.success[0].titile = c.title
                                resp.data.success[0].x = c.x
                                resp.data.success[0].y = c.y
    
                                //   console.log("this.imageLoss", _self.imaglossp);
    
                                // var lox = c.x + (c.x * this.imaglossp)/100;
    
                                resp.data.success[0].xoo = this.cordinatesOriginal[index].x
    
                                //var  loy = c.y + (c.y * _self.imaglossp)/100;
    
                                resp.data.success[0].yoo = this.cordinatesOriginal[index].y
    
                                this.latestPanaromas.push(resp.data.success);
                            }
                                
    
                             var length = this.cordinates.length;
                              
                             var time = length*500;
    
                             // console.log("-",time);
                                 
                            var self = this;
                            setTimeout(() => {
                                self.latesttrue = true
                                self.sliderloader = false
                            }, time);
    
                            //  console.log("ddd = >", this.latestPanaromas);
                        })
                });
    
            },
            onSelectslider(index) {
    
                this.index = index;
                this.goPositon(0.4);
                this.sliderActive = true;
                // console.log("index",index);
                // console.log("dd",this.latestPanaromas[index][0]);
                // this.latestPanaromas[index]; 
                this.activesliderImage = this.latestPanaromas[index][0].mediapath;
    
                this.titile = this.latestPanaromas[index][0].titile;
                this.uploadat = this.latestPanaromas[index][0].uploadat;
    
                var self = this;
                setTimeout(() => {
                    var b = self.$refs.slider;
                    // console.log(" b.viewer", b.viewer);
                    b.viewer.setHfov(b.viewer.getHfov() + 50);
                    //b.viewer.stopAutoRotate(50); 
                    //this.drag = this.$refs.panaromascreen.viewer.getPitch();
    
                }, 2000);
    
            },
            minus() {
    
                this.index = this.index - 1;
                var index = this.index;
                this.activesliderImage = this.latestPanaromas[index][0].mediapath;
                this.titile = this.latestPanaromas[index][0].titile;
                this.uploadat = this.latestPanaromas[index][0].uploadat;
    
                var self = this;
                setTimeout(() => {
                    var b = self.$refs.slider;
                    // console.log(" b.viewer", b.viewer);
                    b.viewer.setHfov(b.viewer.getHfov() + 50);
                    //b.viewer.stopAutoRotate(50); 
                    //this.drag = this.$refs.panaromascreen.viewer.getPitch();
    
                }, 2000);
    
            },
            plus() {
                this.index = this.index + 1;
                var index = this.index;
                this.activesliderImage = this.latestPanaromas[index][0].mediapath;
                this.titile = this.latestPanaromas[index][0].titile;
                this.uploadat = this.latestPanaromas[index][0].uploadat;
    
                var self = this;
                setTimeout(() => {
                    var b = self.$refs.slider;
                    // console.log(" b.viewer", b.viewer);
                    b.viewer.setHfov(b.viewer.getHfov() + 50);
                    //b.viewer.stopAutoRotate(50); 
                    //this.drag = this.$refs.panaromascreen.viewer.getPitch();
    
                }, 2000);
    
            },
            openComment_image(url) {
                //alert("hello");
                this.secondimagenormal = url;
                this.commentopenImage = true;
            },
            onSelect(a) {
                // var a = this.$refs.carousel.currentIndex;
                //  this.image = this.slides[a].mediapath;
                // console.log(this.image);
    
                this.imagenormalcompare = false;
                this.selected = [];
                this.colum = true;
                this.zoom = 0.5;
                this.goPositon(0.4);
                //  this.down = !this.down;
                if (this.activet == 'normal') {
                    this.imagenormal = this.slides[a].mediapath;
    
                    this.compatedatefirst = this.slides[a].uploadat;
    
                }
                if (this.activet == 'panaroma') {
                    this.imagepanaroma = this.slides[a].mediapath;
                    this.compatedatefirst = this.slides[a].uploadat;
                }
                if (this.activet == 'video') {
    
                    // console.log("this.slides[a]", this.slides[a]);
                    this.compatedatefirst = this.slides[a].uploadat;
    
                    this.pvideo = this.slides[a].mediapath;
    
                    if(this.videotype == '360Videos'){
    
                        this.vedioalert = true;
                    }
                    
    
                }
    
                let content = document.querySelector(".drawingxx");
                var _self = this;
                setTimeout(() => {
                    // console.log("content", content);
                    content.scrollLeft = _self.x - 100;
                }, 2000);
            },
            oncompare(event, a) {
    
                // var a = this.$refs.carousel.currentIndex;
                //  this.image = this.slides[a].mediapath;
                // console.log(this.image);
                if (event.target.checked) {
    
                    //  this.selected.push(a);
                    // var _this = this;
                    setTimeout(() => {
    
                        //   console.log(_this.selected);
    
                        // console.log(this.selected.length);
                        if (this.selected.length > 2) {
                            this.selected.shift();
                        }
    
                        if (this.selected.length > 1) {
    
                            this.colum = true;
                            this.zoom = 0.5;
                            this.goPositon(0.4);
                            this.imagenormalcompare = true;
                            if (this.activet == 'normal') {
    
                                this.imagenormal = this.slides[this.selected[this.selected.length - 2]].mediapath;
    
                                this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;
    
                                this.secondimagenormal = this.slides[this.selected[this.selected.length - 1]].mediapath;
                                this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
    
                            }
                        }
    
                        if (this.selected.length > 1) {
                            this.colum = true;
                            if (this.activet == 'panaroma') {
    
                                this.imagepanaroma = this.slides[this.selected[this.selected.length - 2]].mediapath;
                                this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;
                                //  console.log(this.selected.length);
                                this.secondimagepanaroma = this.slides[this.selected[this.selected.length - 1]].mediapath;
                                this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
                                this.imagenormalcompare = true;
                            }
                        }
                        if (this.activet == 'video') {
                            this.colum = true;
                            this.zoom = 0.5;
                            this.goPositon(0.4);
                            // console.log("this.slides[a]", this.slides[a]);
                            this.compatedatefirst = this.slides[a].uploadat;
    
                            this.pvideo = this.slides[a].mediapath;
    
                        }
                    }, 500)
                }
            },
            closeCompare() {
                this.selected = [];
                this.imagenormalcompare = false;
            },
            filterdate(m) {
    
                var a = ['All']
    
                m.forEach(i => {
                    a.push(i.uploadat);
                });
    
                let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
                //  console.log(unique);
    
                this.uniquedates = unique.sort(function (a, b) {
                    var dateA = new Date(a),
                        dateB = new Date(b)
                    return dateB - dateA //sort by date ascending
                })
            },
            selectmultipleDate(d) {
    
                console.log("selecteddates", this.slides.indexOf('All') !== -1);
                var _self = this;
                if (d.indexOf('All') !== -1) {
                    this.selectDate('All');
                } else {
    
                    _self.slides = [];
                    setTimeout(() => {
                        _self.slides = _self.saved.filter(function (item) {
                            if (d.indexOf(item.uploadat) !== -1) {
    
                                return true;
    
                            }
                        })
    
                    }, 500)
                    _self.preloader = false;
    
                }
    
            },
            selectDate(d) {
    
                this.preloader = true;
    
                // console.log(d);
                this.refresh++;
                var _self = this;
    
                if (d == 'All') {
                    // console.log(d);
                    _self.slides = [];
                    setTimeout(() => {
                        _self.slides = _self.saved;
                        _self.preloader = false;
                    }, 500)
    
                } else {
                    _self.slides = [];
                    setTimeout(() => {
                        _self.slides = _self.saved.filter(function (item) {
                            console.log("item", item);
                            return item.uploadat == d;
                        }, 500)
                        _self.preloader = false;
                    });
                }
            },
            panUp() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setPitch(a.viewer.getPitch() + 20);
                b.viewer.setPitch(b.viewer.getPitch() + 20);
            },
    
            panDown() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setPitch(a.viewer.getPitch() - 20);
                b.viewer.setPitch(b.viewer.getPitch() - 20);
            },
            panLeft() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setYaw(a.viewer.getYaw() - 20);
                b.viewer.setYaw(b.viewer.getYaw() - 20);
            },
            panRight() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setYaw(a.viewer.getYaw() + 20);
                b.viewer.setYaw(b.viewer.getYaw() + 20);
            },
            panIn() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setHfov(a.viewer.getHfov() - 20);
                b.viewer.setHfov(b.viewer.getHfov() - 20);
            },
            panOut() {
                var a = this.$refs.panaromascreen;
                var b = this.$refs.panaromascreen2;
    
                a.viewer.setHfov(a.viewer.getHfov() + 20);
                b.viewer.setHfov(b.viewer.getHfov() + 20);
            },
            // addComment() {
    
            //     var a = this.$refs.panaromascreen;
    
            //     // console.log("refrence", a);
    
            //     var img = a.viewer.getRenderer().render(a.viewer.getPitch() / 180 * Math.PI,
            //         a.viewer.getYaw() / 180 * Math.PI,
            //         a.viewer.getHfov() / 180 * Math.PI, {
            //             'returnImage': true
            //         });
            //     //  console.log(img);
    
            //     this.commentImage = img;
    
            //     this.commmentForm = true;
            // },
            meckReaded(comment) {
    
                // console.log(comment);
    
                var status;
                if (comment.comment_status == 1) {
                    status = 0;
                } else {
                    status = 1;
                }
    
                // console.log(status);
    
                axios({
                        url: '/interior/commnent_update',
                        method: 'get',
                        params: {
                            comment_id: comment.cmt_id,
                            status: status
                        }
                    })
                    .then(() => {
                        //  console.log("comments");
                        // this.commentsdata = resp.data.success;
                        // this.commentbox = true;
                        this.getCommnets_bydrawing();
                        //this.getCommnets_bs64();
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getCommnets_bs64() {
                this.preloader = false;
                this.progree = '20' + '/100%';
                this.reportStart = true;
                this.msg = 'Please wait while the report is being generated....'
    
                // console.log("get comments bs64 started");
                axios({
                        url: '/interior/get-commnent_bs64',
                        method: 'get',
                        params: {
                            coordinatid: this.openCoordinate.coordinateid
                        }
                    })
                    .then(resp => {
                        this.reportStart = true;
                        this.progree = '40' + '/100%';
                        this.msg = 'Processing the report...'
                        // console.log("comments dataa bs64", resp);
                        this.commentsdataa = resp.data.success;
                        if (this.commentsdataa) {
                            this.generate_snaglistreport();
                        }
                        //this.commentbox = true;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getCommnets_bydrawing() {
                this.preloader = true;
    
                axios({
                        url: '/interior/get-commnent_bydrawingid',
                        method: 'get',
                        params: {
                            drawingid: this.getdrawingid,
                            coordinatid: this.openCoordinate.coordinateid
                        }
                    })
                    .then(resp => {
                        this.reportToggle2 = true;
                        console.log("comments data", resp);
                        this.drawing_commentsdata = resp.data.success;
                        // this.commentbox = true;
                        console.log("count of drawing snaglist", this.drawing_commentsdata.length);
                        this.cmtSearchdata = resp.data.success.filter(function (item) {
                            return item.cmt_id;
                        });
                        // this.getCommnets_bs64();
    
                        setTimeout(() => {
                            this.preloader = false
                        }, 1000);
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            goToModule(r) {
    
                var ss = localStorage.getItem("activeProject");
    
                console.log("ss", ss);
    
                var fname = this.userdata.firstname;
    
                var lname = this.userdata.lastname;
    
                var userid = this.userdata.id;
    
                var contactNo = this.userdata.contactNo;
    
                var user_code = this.userdata.user_code;
    
                var opticvyu_user = this.userdata.opticvyu_user;
    
                var cam_id = 0;
    
                const myArray = ss.split(",");
    
                // console.log("myArray",myArray);
    
                let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    
                var project_name = currentProject.project_name;
                //  console.log(myArray.indexOf(currentProject.id));
    
                var pi = myArray.indexOf(currentProject.id);
    
                var token = localStorage.getItem("token");
    
                axios({
                        url: "/mobile?type=" + r + "&userid=" + userid + "&firstname=" + fname + "&lastname=" + lname + "&pi=" + pi + "&project_id=" + currentProject.id + "&contactNo=" + contactNo + "&token=" + token + "&user_type=" + user_code + "&opticvyu_user=" + opticvyu_user + "&cam_id=" + cam_id + "&project_name=" + project_name,
                        method: "GET",
                    })
                    .then(() => {
                        console.log("ok");
                    })
            },
            getCommnets_bs64_bydrawing() {
                this.preloader = false;
                this.progree = '20' + '/100%';
                this.reportStart = true;
                this.msg = 'Please wait while the report is being generated....'
    
                console.log("get drawing all comments bs64 started");
                axios({
                        url: '/interior/get-commnent_bs64_bydrawingid',
                        method: 'get',
                        params: {
                            drawingid: this.getdrawingid,
                            coordinatid: this.openCoordinate.coordinateid,
                            activee: "All",
                        }
                    })
                    .then(resp => {
                        this.reportStart = true;
                        this.progree = '40' + '/100%';
                        this.msg = 'Processing the report...'
                        console.log("comments dataa bs64", resp);
                        this.drawing_commentsdataa = resp.data.success;
                        if (this.drawing_commentsdataa) {
                            this.generate_drawingsnaglistreport();
                        }
                        //this.commentbox = true;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getCommnets_bs64_bydrawing_filter(active) {
                this.preloader = false;
                this.progree = '20' + '/100%';
                this.reportStart = true;
                this.msg = 'Please wait while the report is being generated....'
    
                console.log("get drawing filter comments bs64 started");
                axios({
                        url: '/interior/get-commnent_bs64_bydrawingid',
                        method: 'get',
                        params: {
                            drawingid: this.getdrawingid,
                            coordinatid: this.openCoordinate.coordinateid,
                            activee: active,
                        }
                    })
                    .then(resp => {
                        this.reportStart = true;
                        this.progree = '40' + '/100%';
                        this.msg = 'Processing the report...'
                        console.log("comments dataa bs64", resp);
                        this.drawing_commentsdataa = resp.data.success;
                        if (this.drawing_commentsdataa && active == '1') {
                            this.completed_drawingsnaglistreport();
                        } else if (this.drawing_commentsdataa && active == '0') {
                            this.pending_drawingsnaglistreport();
                        }
                        //this.commentbox = true;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getCommnets_bs64_loop(c_id) {
                this.preloader = false;
                this.progree = '20' + '/100%';
                this.reportStart = true;
                this.msg = 'Please wait while the report is being generated....'
    
                console.log("get comments bs64 started");
                axios({
                        url: '/interior/get-commnent_bs64',
                        method: 'get',
                        params: {
                            coordinatid: c_id
                        }
                    })
                    .then(resp => {
                        this.reportStart = true;
                        this.progree = '40' + '/100%';
                        this.msg = 'Processing the report...'
                        console.log("comments dataa bs64", resp);
                        this.commentsdataa = resp.data.success;
    
                        //this.commentbox = true;
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            sendComment() {
                this.preloader = true;
                const url1 = this.imagepanaroma.split("?");
                console.log("image data", url1);
                var ex_email = this.commetnsemail_extra.split(",");
                console.log('count extra email', ex_email);
                if (ex_email.length > 1) {
                    // var ex_email = this.commetnsemail_extra.split(",");
                    var cmtemail = this.commetnsemail;
                    ex_email.forEach(function (ex_email) {
                        console.log("cmt_email extra", ex_email);
                        cmtemail.push(ex_email);
                        console.log("cmt receiver email if con", cmtemail);
                    });
    
                } else if (this.commetnsemail_extra !== "" && ex_email.length == 1) {
                    this.commetnsemail.push(this.commetnsemail_extra);
                    console.log("cmt receiver email if con", this.commetnsemail);
                } else {
                    console.log("cmt receiver email else con", this.commetnsemail);
                }
                if (this.cmt_rootcause) {
                    //   console.log("root cause of comment", this.cmt_rootcause);
                } else {
                    this.cmt_rootcause = 'Not available'
                }
                //alert(this.getdrawingid);
    
                //console.log("cmt receiver email", this.commetnsemail);
                axios({
                        url: '/interior/send-commnent',
                        method: 'post',
                        data: {
                            proj_dir: this.proj_dir,
                            coordinatename: this.openCoordinate.title,
                            projectname: this.project_name,
                            drawingid: this.getdrawingid,
                            drawingname: this.drawing.name,
                            project_id: this.projectid,
                            userid: this.userdata.id,
                            zoneid: this.zoneid,
                            commnettext: this.comment,
                            commentimg: this.commentImage,
                            coordinatid: this.openCoordinate.coordinateid,
                            realimage: url1[0],
                            sender_name: this.userdata.firstname + ' ' + this.userdata.lastname,
                            receiveremails: this.commetnsemail,
                            title: this.cmt_title,
                            type: this.cmt_type.cmt_type_op,
                            status: this.cmt_status_val.cmt_status,
                            assigned: this.cmt_assigned_to,
                            duedate: this.date1,
                            rootcause: this.cmt_rootcause,
                        }
                    })
                    .then(() => {
                        this.commmentForm = false;
                        // console.log("comments", resp);
                        this.preloader = false;
                        this.alert = true;
                        this.alertmessage = this.lang.addcommnetssucess;
                        this.alertType = "success";
                        this.cmt_title = '';
                        this.commetnsemail = [];
                        this.commetnsemail_extra = '';
                        this.cmt_rootcause = '';
                        this.cmt_status_val = null;
                        this.cmt_type = null;
                        this.date1 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                        this.comment = '';
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            taskboxopen() {
                this.taskbox = true;
                this.getListItemss();
            },
            addnewItem() {
                console.log('ok');
            },
            selectTlistItem(d) {
                axios({
                        url: '/interior/get-stander-list',
                        method: 'get',
                        params: {
                            type: d,
                            coordinatid: this.openCoordinate.coordinateid,
                            zoneid: this.zoneid,
                            drawingid: this.activeDrawing.drawingid,
                            userid: this.userdata.id,
                        }
                    })
                    .then(() => {
                        // console.log(resp);
                        this.getListItemss();
    
                        var _self = this;
                        setTimeout(() => {
                            // var c = confirm(this.lang.listconfirmation)
    
                            _self.alert = true;
                            _self.alertmessage = this.lang.standdrlistloaded;
                            _self.alertType = "error"
                            _self.showstanderoption = false
    
                            // if (c) {
    
                            // }
                            //  else {  // currently remove task method is disable 
                            //    /// _self.removeTaskLoaded();
                            // }
    
                        }, 1000);
    
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            getColor(datae, status) {
    
                // console.log(datae, status);
    
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(datae);
                const secondDate = new Date();
    
                //  console.log(firstDate, secondDate);
    
                const diffDays = Math.round((firstDate - secondDate) / oneDay);
    
                //console.log("d -- diffe", status, diffDays);
    
                if (status == 'In progress' && diffDays >= 0 && diffDays <= 7) return '#bf9bff'
                else if (status == 'In progress' && diffDays > 7) return 'orange'
                else if (status == 'In progress' && diffDays <= 0) return '#f37171'
                else if (status == 'Completed') return '#87e38b'
                else if (status == 'Not started') return 'white'
                else return 'white'
            },
            days(datae, status) {
    
                //   console.log(datae, status);
    
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(datae);
                const secondDate = new Date();
    
                //  console.log(firstDate, secondDate);
    
                const diffDays = Math.round((firstDate - secondDate) / oneDay);
    
                //  console.log("d -- diffe", status ,diffDays);
    
                if (status == 'In progress' && diffDays >= 0 && diffDays <= 7) return diffDays
                else if (status == 'In progress' && diffDays <= 0) return diffDays
                else if (status == 'Completed') return diffDays
                else if (status == 'Not started') return diffDays
                else return diffDays
            },
            verificationEnventory(item) {
                this.preloader = true;
    
                var varify = 1;
    
                var m = "Task marked as verified."
    
                // console.log("item",item);
    
                if (item.verification == 1) {
                    varify = 0;
                    m = "Task status not verified."
                }
    
                axios({
                        url: '/interior/verification-task',
                        method: 'get',
                        params: {
                            verification: varify,
                            taskid: item.taskid
                        }
                    })
                    .then(() => {
                        //console.log("Resp", resp);
                        this.alert = true;
                        this.alertmessage = m;
                        this.alertType = "success";
                        var _self = this;
    
                        setTimeout(() => {
                            _self.getListItemss();
                            _self.preloader = false;
    
                        }, 2000);
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false;
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                    })
            },
            removeTaskLoaded() {
                axios({
                        url: '/interior/remove-task',
                        method: 'get',
                        params: {
                            coordinatid: this.openCoordinate.coordinateid,
                        }
                    })
                    .then(() => {
                        // console.log("Remove data", resp.data.success);
    
                        this.getListItemss()
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            sendTask() {
                this.preloader = false
                //console.log("this.newTask");
                axios({
                        url: '/interior/add-task',
                        method: 'get',
                        params: {
                            coordinatid: this.openCoordinate.coordinateid,
                            taskname: this.newTask.taskname,
                            taskStatus: this.newTask.taskstatus,
                            startdate: this.newTask.taskstartdate,
                            enddate: this.newTask.taskenddate,
                            actualstart: this.newTask.actualstartdate,
                            actualend: this.newTask.actualenddate,
                            prograss: this.newTask.taskprogress,
                            tag: this.newTask.tasktag,
                            taskchecked: 0,
                            userid: this.userdata.id,
                            zoneid: this.zoneid,
                            drawingid: this.activeDrawing.drawingid,
                            headingtype: this.newTask.headingtype ? 1 : 0
    
                        }
                    })
                    .then(() => {
                        // console.log("Remove data", resp.data.success);
                        this.newTaskForm = false;
                        this.getListItemss()
                        this.alert = true;
                        this.alertmessage = this.lang.newmsg;
                        this.alertType = "success"
                        this.preloader = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            editTask(d) {
    
                console.log("ddd", d);
    
                this.updateTaskForm = true
                this.updateTask = {
                    taskname: d.taskname,
                    taskstatus: d.status,
                    taskstartdate: d.start_date,
                    taskenddate: d.end_date,
                    actualstartdate: d.actual_start,
                    actualenddate: d.actual_end,
                    taskprogress: d.progress,
                    tasktag: d.tag,
                    taskid: d.taskid,
                    receiver_emails: d.receiver_emails,
                    headingtype: d.headingtype
                }
            },
            sendupdateTask() {
                this.preloader = true
                //console.log("this.newTask");
                axios({
                        url: '/interior/update-task',
                        method: 'get',
                        params: {
    
                            taskname: this.updateTask.taskname,
                            taskStatus: this.updateTask.taskstatus,
                            startdate: this.updateTask.taskstartdate,
                            enddate: this.updateTask.taskenddate,
    
                            actualstart: this.updateTask.actualstartdate,
                            actualend: this.updateTask.actualenddate,
    
                            prograss: this.updateTask.taskprogress,
                            tag: this.updateTask.tasktag,
                            taskchecked: 0,
                            userid: this.userdata.id,
                            taskid: this.updateTask.taskid,
                            receiver_emails: this.updateTask.receiver_emails,
                            headingtype: this.updateTask.headingtype ? 1 : 0
    
                        }
                    })
                    .then(() => {
                        //  console.log("Remove data", resp.data.success);
                        this.updateTaskForm = false;
                        this.updateTask = {};
                        this.getListItemss()
                        this.alert = true;
                        this.alertmessage = 'Task successfully updated!';
                        this.alertType = "success"
                        this.preloader = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            setrouter: function (url) {
    
                if (this.$route.path !== url) {
                    this.$router.push(url)
                    //console.log("current router ", this.$route.path);
    
                }
    
                //console.log(url);
            },
            deleteTask(id) {
    
                var s = confirm(this.lang.sureremove);
    
                if (s) {
                    this.preloader = true
                    //console.log("this.newTask");
                    axios({
                            url: '/interior/delete-task',
                            method: 'get',
                            params: {
                                taskid: id
    
                            }
                        })
                        .then(() => {
                            // console.log("Remove data", resp.data.success);
                            this.updateTaskForm = false;
                            this.getListItemss()
                            this.alert = true;
                            this.alertmessage = this.lang.taskDelete;
                            this.alertType = "success"
                            this.preloader = false
                        })
                        .catch(err => {
                            console.log(err);
                            this.alert = true;
                            this.alertmessage = this.lang.SomethingWentWrong;
                            this.alertType = "error"
                            this.preloader = false
                        })
                }
            },
            removeall() {
                var s = confirm(this.lang.removetask);
    
                if (s) {
                    this.preloader = true
    
                    var ids = [];
    
                    this.selectedtask.forEach(a => {
                        ids.push(a.taskid);
                    });
    
                    axios({
                            url: '/interior/delete-selected-task',
                            method: 'get',
                            params: {
                                taskids: ids
    
                            }
                        })
                        .then(() => {
                            // console.log("Remove data", resp.data.success);
                            this.updateTaskForm = false;
                            this.getListItemss()
                            this.alert = true;
                            this.alertmessage = this.lang.taskDelete;
                            this.alertType = "success"
                            this.preloader = false
                            this.selectedtask = [];
                        })
                        .catch(err => {
                            console.log(err);
                            this.alert = true;
                            this.alertmessage = this.lang.SomethingWentWrong;
                            this.alertType = "error"
                            this.preloader = false
                        })
                }
            },
    
            getColumns() {
                return [{
                        title: "Task",
                        dataKey: "Task"
                    },
                    {
                        title: "Status",
                        dataKey: "Status"
                    },
                    {
                        title: "Start",
                        dataKey: "Start"
                    },
                    {
                        title: "End",
                        dataKey: "End"
                    },
                    {
                        title: "Progress",
                        dataKey: "Progress"
                    }
    
                ]
            },
    
            DownloadOverallPDF() {
                this.preloader = true;
                this.getOrg()
                // var downloadfile = this.drawing.name;
    
                var _self = this;
                this.dataPDF();
                var doc = new jsPDF('p', 'mm', 'a3');
    
                var filettile = _self.project_name + ' - ' + _self.currentZoneName + ' - ' + _self.drawing.name;
    
                var img = new Image(); //this mount a variable to img
                img.src = _self.logourl; //asign the src to the img variable
                img.height = 30;
                doc.addImage(img, 'png', 14, 1, 12, 12);
                doc.text(90, 10, filettile);
    
                setTimeout(() => {
    
                    doc.autoTable({
                        html: '#cumulativeReportDownload',
                        startY: 15,
                        theme: 'grid',
    
                        allSectionHooks: true,
                        didParseCell: function (data) {
    
                            console.log("data interr", data);
    
                            console.log("data.row.raw[0].content", data.row.raw[0].content);
    
                            if (data.row.raw[7].content == 1) {
                                data.cell.styles.fillColor = [210, 219, 77];
                                data.cell.styles.font = 'times';
                                data.cell.styles.fontSize = '13';
                                data.cell.styles.FontWeight = 'bold';
                            }
    
                            if (_self.cordinatedata.includes(data.row.raw[0].content)) {
                                data.cell.styles.fillColor = [284, 187, 3];
                                data.cell.styles.font = 'times';
                                data.cell.styles.halign = 'center';
                                data.cell.styles.fontSize = '14';
                            }
                        }
                    })
                    // doc.addPage("a2", "portrait")
    
                }, 10000);
                setTimeout(() => {
                    _self.preloader = false;
                    doc.save(filettile + '.pdf')
    
                }, 15000);
                this.$store.dispatch('openReport', false)
    
            },
            date_function: function () {
    
                var formatted_date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
                //  console.log(formatted_date);
                return formatted_date;
    
            },
            downloadPDF() {
                this.$refs.html2Pdf.generatePdf();
            },
            //  downloadPDF() {
            //     this.$refs.html2PdfPoint.generatePdf();
            // },
            getLayoutImage_snaglist(index) {
                // console.log("this.reportStart", this.reportStart)
                this.reportStart = true;
                const c = document.getElementById('canvas');
                // console.log("canvas check", c);
                const ctx = c.getContext('2d');
                ctx.clearRect(0, 0, ctx.width, ctx.height);
                ctx.beginPath();
                ctx.moveTo(0, 0);
                var image = new Image();
                image.crossOrigin = "Anonymous";
                // image.crossOrigin = 'use-credentials';
                image.src = this.drawing.drawingpath; //"https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=725&q=80"; 
                //this.drawing.drawingpath
                // this.latestPanaromas
                // var s = this.openCoordinate;
                var os = this.cordinatesOriginal[this.openCoordinateindex];
                // console.log(s);
                // console.log("First cordination", s, s.x, s.y);
    
                image.onload = function () {
                    //  drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
                    //    image - The image itself that we want to crop and display on the browser.
                    // sx (source image x-axis) - This parameter says from where you want to clip or start cropping the image from the x-axis.
                    // sy (source image y-axis) - This parameter says from where you want to clip or start cropping the image from the y-axis.
                    // sWidth - The width of the image starting from sx.
                    // sHeight - The height of the image starting from sy.
                    // dx - The point from which to start drawing the image on the screen from the x-axis.
                    // dy - The point from which to start drawing the image on the screen from the y-axis.
                    // dWidth - The length of the images that should be displayed on the screen.
                    // dHeight - The height of the images that should be displayed on the screen.
                    ctx.canvas.width = image.width;
    
                    var cnh = image.height;
                    var pointh = os.y;
                    if (cnh > 1000) {
                        if (os.y > (image.height / 2)) {
                            cnh = image.height / 2;
                            pointh = os.y / 2;
                        }
                    }
                    ctx.canvas.height = cnh;
                    ctx.drawImage(image, 0, 0, image.width, cnh);
                    ctx.beginPath();
                    ctx.arc(os.x, pointh, 8, 0, 2 * Math.PI);
                    ctx.stroke();
                    ctx.fillStyle = "#f8bb03";
                    ctx.fill();
    
                };
    
                var _self = this;
    
                this.reportdataPointt.push({
                    pointt: '',
                    imagee1: '',
                    imagee2: '',
                    imagee3: '',
                    imagee4: ''
                });
                setTimeout(() => {
    
                    var dataURL = c.toDataURL();
    
                    _self.reportdataPointt[index].pointt = dataURL;
    
                    // console.log(this.reportdata);
    
                    _self.points = dataURL;
                    //  console.log("crop image",dataURL)
    
                }, 1000);
    
            },
            getLayoutImage(index) {
                this.onSelect(index);
                const canvas = document.getElementById('canvas');
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, ctx.width, ctx.height);
                ctx.beginPath();
                ctx.moveTo(0, 0);
                var image = new Image();
                //image.crossOrigin="anonymous"
                // image.crossOrigin = 'use-credentials';
                image.src = this.drawing.drawingpath; //"https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=725&q=80"; 
                //this.drawing.drawingpath
                // this.latestPanaromas
                //  var s = this.openCoordinate;
    
                var os = this.cordinatesOriginal[this.openCoordinateindex];
                // console.log(os, this.openCoordinateindex);
                //  console.log(s);
                // console.log("First cordination", s, os.x, os.y);
    
                image.onload = function () {
                    //  drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
                    //    image - The image itself that we want to crop and display on the browser.
                    // sx (source image x-axis) - This parameter says from where you want to clip or start cropping the image from the x-axis.
                    // sy (source image y-axis) - This parameter says from where you want to clip or start cropping the image from the y-axis.
                    // sWidth - The width of the image starting from sx.
                    // sHeight - The height of the image starting from sy.
                    // dx - The point from which to start drawing the image on the screen from the x-axis.
                    // dy - The point from which to start drawing the image on the screen from the y-axis.
                    // dWidth - The length of the images that should be displayed on the screen.
                    // dHeight - The height of the images that should be displayed on the screen.
    
                    //  var loss = i.height - 600;
                    //       var Losspercentage = (loss * 100) / i.height;
    
                    ctx.canvas.width = image.width;
    
                    var cnh = image.height;
                    var pointh = os.y;
                    if (cnh > 1000) {
                        if (os.y > (image.height / 2)) {
                            cnh = image.height / 2;
                            pointh = os.y / 2;
                        }
                    }
                    ctx.canvas.height = cnh;
                    ctx.drawImage(image, 0, 0, image.width, cnh);
                    ctx.beginPath();
                    ctx.arc(os.x, pointh, 8, 0, 2 * Math.PI);
                    ctx.stroke();
                    ctx.fillStyle = "#f8bb03";
                    ctx.fill();
    
                };
    
                var _self = this;
    
                this.reportdataPoint.push({
                    point: '',
                    image1: '',
                    image2: '',
                    image3: '',
                    image4: ''
                });
                setTimeout(() => {
    
                      var dataURL = canvas.toDataURL("image/png");
                     // var dataURL = dataU.replace(/^data:image\/?[A-z]*;base64,/);
    
                      //console.log(dataURL_r);
    
                    //var dataURL = canvas.toDataURL();
    
    // var canvas_r = document.getElementById("canvas_r");
    // var ctx_r = canvas_r.getContext("2d");
    
    // var image_r = new Image();
    // image_r.onload = function() {
    // ctx_r.drawImage(image_r, 0, 0);
    // };
    // image_r.src = dataURL_r;
    
    //  var dataURL = canvas_r.toDataURL("image/png");
    
    //console.log("dataURL",dataURL);
    
    
    
                    _self.reportdataPoint[index].point = dataURL;
    
                    // console.log(this.reportdata);
    
                    _self.points = dataURL;
                    //  console.log("crop image",dataURL)
                    _self.getImagespartPoint(90, 1, index);
                    _self.getImagespartPoint(180, 2, index);
                    _self.getImagespartPoint(270, 3, index);
                    _self.getImagespartPoint(360, 4, index);
                }, 28000);
                //   setTimeout(() => {
                //     // this.generatePdf()
                //    /// this.$refs.html2Pdf.generatePdf();
                //   },5000)
    
            },
            getImagespartPoint(d, nbr, index) {
    
                var a = this.$refs.panaromascreen;
                //   a.viewer.destroy();
                // console.log("refrence", a);
                // var vv = a.viewer.getPitch();
                // var v = a.viewer.getYaw();
                // var vsv = a.viewer.getHfov()
    
                // var aa  =  a.viewer.isLoaded();
    
                // console.log('getPitch = ', vv);
                // console.log('getYaw = ', v);
                // console.log('getHfov = ', vsv);
    
                var img = a.viewer.getRenderer().render(0 / 180 * Math.PI,
                    d / 180 * Math.PI,
                    120 / 180 * Math.PI, {
                        'returnImage': true
                    });
    
                //   console.log("---", img);
                if (nbr == 1) {
                    // this.part1 = img;
                    this.reportdataPoint[index].image1 = img;
    
                } else if (nbr == 2) {
                    // this.part2 = img;
                    this.reportdataPoint[index].image2 = img;
    
                } else if (nbr == 3) {
                    //   this.part3 = img;
                    this.reportdataPoint[index].image3 = img;
    
                } else if (nbr == 4) {
                    //this.part4 = img;
                    this.reportdataPoint[index].image4 = img;
                }
    
                console.log("report data", this.reportdata);
                // console.log("==>",img);
                // this.commentImage = img;
    
                //this.commmentForm = true;
            },
            reportloop() {
    
                this.reportStart = true;
                this.getOrg();
                this.clsoeReport1();
    
                //var time = 1000;
    
                setTimeout(() => {
    
                    this.cropImg(this.initvlaue);
                    this.progree = this.initvlaue + 1 + ' /' + this.latestPanaromas.length;
    
                    this.msg = 'Generating Report..';
    
                    this.initvlaue++; //  increment the counter 
                    if (this.initvlaue < this.latestPanaromas.length) { // this.latestPanaromas.length  if the counter < 10, call the loop function
                        this.reportloop(); //  ..  again which will trigger another 
                        var a = this.$refs.slider;
                        a.viewer.destroy();
                    } else {
                        this.closingreport();
                    }
                }, 20000)
            },
    
            onProgress() {
                this.reportStart = true;
                this.msg = 'Report is ready to download...';
            },
            onProgress_snaglist(progress) {
    
                this.progress_per = progress;
    
                this.progree = progress + '/100%';
                this.reportStart = true;
                this.msg = 'Report is ready to download...';
                // console.log('PDF generation progress', this.progree)
            },
            hasStartedGeneration() {
                this.sliderActive = false;
                this.reportStart = true;
                this.msg = 'Generating Report...'
            },
            hasStartedGeneration_snaglist() {
                this.sliderActive = false;
                this.reportStart = true;
                this.msg = 'Generating Report...'
            },
            hasGenerated_snaglist(ev) {
                console.log('pdf generated', ev);
            },
            onDownloaded_snaglist() {
                this.sliderActive = false;
                this.reportStart = true;
                this.msg = 'Report Downloaded Successfully.'
    
                setTimeout(() => {
                    this.commentbox = false;
                    this.reportStart = false;
                    this.filterdata = {};
                    this.reportdataPointt = [];
                    this.commentsdataa = false;
                    this.drawing_commentsdataa = false;
                    console.log('Report Downloaded Successfully and drawing commentsdataa', this.drawing_commentsdataa);
                    //window.location.reload();
    
                }, 7000);
            },
            onDownloaded() {
                this.sliderActive = false;
                this.reportStart = false;
                this.msg = 'Report Downloaded Successfully'
    
                setTimeout(() => {
                    this.reportStart = false;
                    this.reportdata = [];
                }, 10000)
            },
            closingreportPoint() {
    
                this.msg = 'Generating Report..'
    
                setTimeout(() => {
                    this.$refs.html2PdfPoint.generatePdf();
                    this.preloader = false;
                    // this.sliderActive = false;
    
                    //  this.msg = 'Report Downloading..'
                }, 30000);
    
                //    setTimeout(() => {
                //       this.reportStart = false;
                //   },30000)
            },
            closingreport() {
    
                this.msg = 'Generating Report..'
    
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.preloader = false;
                    // this.sliderActive = false;
    
                    //  this.msg = 'Report Downloading..'
                }, 30000);
    
                //    setTimeout(() => {
                //       this.reportStart = false;
                //   },30000)
            },
            async beforeDownload({
                html2pdf,
                options,
                pdfContent
            }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
    
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        //console.log(pdf, "generating pdf");
                    }
                }).save()
    
            },
            generate_snaglistreport() {
                //this.preloader = true;
                var _self = this;
                if (this.commentsdataa) {
                    setTimeout(() => {
                        _self.$refs.html2Pdfsnaglist.generatePdf();
                    }, 5000);
                } else {
                    _self.reportStart = true;
                    _self.msg = 'Process started...'
                    _self.getCommnets_bs64();
                    setTimeout(() => {
                        _self.getLayoutImage_snaglist(_self.initvlauePoint);
                    }, 2000);
    
                }
            },
    
            generate_drawingsnaglistreport() {
                //this.preloader = true;
                var _self = this;
                if (this.drawing_commentsdataa) {
                    console.log("all points data collected", this.drawing_commentsdataa);
                    this.reportStart = true;
                    this.progree = '50' + '/100%';
                    this.msg = 'Report Downloaded Successfully.'
                    setTimeout(() => {
                        _self.progree = '60' + '/100%';
                        _self.$refs.html2Pdfdrawingsnaglist.generatePdf();
                    }, 10000);
                } else {
                    _self.reportStart = true;
                    _self.msg = 'Process started...';
                    _self.snagliststatuss = "Full";
                    _self.getCommnets_bs64_bydrawing();
                }
            },
            completed_drawingsnaglistreport() {
                var _self = this;
                var active = 1;
                if (this.drawing_commentsdataa) {
                    console.log("completed data collected", this.drawing_commentsdataa);
                    this.reportStart = true;
                    this.progree = '50' + '/100%';
                    this.msg = 'Report Downloaded Successfully.'
                    setTimeout(() => {
                        _self.progree = '60' + '/100%';
                        _self.$refs.html2Pdfdrawingsnaglist.generatePdf();
                    }, 10000);
                } else {
                    _self.reportStart = true;
                    _self.msg = 'Process started...';
                    _self.snagliststatuss = "Completed Task";
                    _self.getCommnets_bs64_bydrawing_filter(active);
                }
            },
            pending_drawingsnaglistreport() {
                var _self = this;
                var active = 0;
                if (this.drawing_commentsdataa) {
                    console.log("pending data collected", this.drawing_commentsdataa);
                    this.reportStart = true;
                    this.progree = '50' + '/100%';
                    this.msg = 'Report Downloaded Successfully.'
                    setTimeout(() => {
                        _self.progree = '60' + '/100%';
                        _self.$refs.html2Pdfdrawingsnaglist.generatePdf();
                    }, 10000);
                } else {
                    _self.reportStart = true;
                    _self.msg = 'Process started...';
                    _self.snagliststatuss = "Pending Task";
                    _self.getCommnets_bs64_bydrawing_filter(active);
                }
            },
            cropImg(index) {
    
                this.onSelectslider(index);
    
                const canvas = document.getElementById('canvas');
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, ctx.width, ctx.height);
                ctx.beginPath();
                ctx.moveTo(0, 0);
                var image = new Image();
                image.crossOrigin = "Anonymous";
                // image.crossOrigin = 'use-credentials';
                image.src = this.drawing.drawingpath; //"https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=725&q=80"; 
                //this.drawing.drawingpath
                // this.latestPanaromas
                var s = this.latestPanaromas[index][0];
                // console.log(s);
                // console.log("First cordination", s, s.xoo, s.yoo);
    
                image.onload = function () {
                    //  drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
                    //    image - The image itself that we want to crop and display on the browser.
                    // sx (source image x-axis) - This parameter says from where you want to clip or start cropping the image from the x-axis.
                    // sy (source image y-axis) - This parameter says from where you want to clip or start cropping the image from the y-axis.
                    // sWidth - The width of the image starting from sx.
                    // sHeight - The height of the image starting from sy.
                    // dx - The point from which to start drawing the image on the screen from the x-axis.
                    // dy - The point from which to start drawing the image on the screen from the y-axis.
                    // dWidth - The length of the images that should be displayed on the screen.
                    // dHeight - The height of the images that should be displayed on the screen.
                    ctx.canvas.width = image.width;
    
                    var cnh = image.height;
                    var pointh = s.yoo;
                    if (cnh > 999) {
                        if (s.yoo > (image.height / 2)) {
                            cnh = image.height / 2;
                            pointh = s.yoo / 2;
                        }
                    }
                    ctx.canvas.height = cnh;
                    ctx.drawImage(image, 0, 0, image.width, cnh);
                    ctx.beginPath();
                    ctx.arc(s.xoo, pointh, 8, 0, 2 * Math.PI);
                    ctx.stroke();
                    ctx.fillStyle = "#f8bb03";
                    ctx.fill();
    
                };
    
                var _self = this;
    
                this.reportdata.push({
                    point: '',
                    image1: '',
                    image2: '',
                    image3: '',
                    image4: ''
                });
                setTimeout(() => {
    
                    var dataURL = canvas.toDataURL();
    
                    _self.reportdata[index].point = dataURL;
    
                    // console.log(this.reportdata);
    
                    _self.points = dataURL;
                    //  console.log("crop image",dataURL)
                    _self.getImagespart(90, 1, index);
                    _self.getImagespart(180, 2, index);
                    _self.getImagespart(270, 3, index);
                    _self.getImagespart(360, 4, index);
                }, 18000);
                //   setTimeout(() => {
                //     // this.generatePdf()
                //    /// this.$refs.html2Pdf.generatePdf();
                //   },5000)
            },
            getImagespart(d, nbr, index) {
    
                var a = this.$refs.slider;
                //   a.viewer.destroy();
                // console.log("refrence", a);
                // var vv = a.viewer.getPitch();
                // var v = a.viewer.getYaw();
                // var vsv = a.viewer.getHfov()
    
                // var aa  =  a.viewer.isLoaded();
    
                // console.log('getPitch = ', vv);
                // console.log('getYaw = ', v);
                // console.log('getHfov = ', vsv);
    
                var img = a.viewer.getRenderer().render(0 / 180 * Math.PI,
                    d / 180 * Math.PI,
                    120 / 180 * Math.PI, {
                        'returnImage': true
                    });
    
                //   console.log("---", img);
                if (nbr == 1) {
                    // this.part1 = img;
                    this.reportdata[index].image1 = img;
    
                } else if (nbr == 2) {
                    // this.part2 = img;
                    this.reportdata[index].image2 = img;
    
                } else if (nbr == 3) {
                    //   this.part3 = img;
                    this.reportdata[index].image3 = img;
    
                } else if (nbr == 4) {
                    //this.part4 = img;
                    this.reportdata[index].image4 = img;
                }
    
                //console.log("report data", this.reportdata);
                // console.log("==>",img);
                // this.commentImage = img;
    
                //this.commmentForm = true;
            },
            //         generatePdf(){
            //           const doc = new jsPDF();
            //    /** WITH CSS */
            //    var canvasElement = document.createElement('canvas');
            //     html2canvas(this.$refs.testHtml, { canvas: canvasElement 
            //       }).then(function (canvas) {
            //     const img = canvas.toDataURL("image/jpeg", 0.8);
            //     doc.addImage(img,'JPEG',20,20);
            //     doc.save("sample.pdf");
            //    });
            //     },
    
            DownloadreportPDF() {
                // let self = this;
                // this.preloader = true;
                this.$refs.html2Pdf.generatePdf();
            },
            dataPDF() {
    
                var tdata = [];
                this.cordinatedata = [];
                this.cordinates.forEach(item => {
                    axios({
                            url: '/interior/get-list',
                            method: 'get',
                            params: {
                                coordinatid: item.coordinateid,
                            }
                        })
                        .then(resp => {
    
                            this.cordinatedata.push(item.title);
    
                            tdata.push({
                                title: item.title
                            });
                            // console.log("table data---", resp.data.success);
                            resp.data.success.forEach(iv => {
    
                                tdata.push({
                                    Task: iv.taskname,
                                    Status: iv.status,
                                    Start: iv.start_date,
                                    End: iv.end_date,
                                    Actual_Start: iv.actual_start,
                                    Actual_End: iv.actual_end,
                                    Progress: iv.progress,
                                    headingtype: iv.headingtype
                                });
                            });
                        })
                })
                this.cumulative_data = tdata;
    
                // console.log("cordinatedata",cordinatedata);
    
            },
            dataExcel() {
    
                var tdata = [];
    
                this.cordinates.forEach(item => {
                    axios({
                            url: '/interior/get-list',
                            method: 'get',
                            params: {
                                coordinatid: item.coordinateid,
                            }
                        })
                        .then(resp => {
                            // console.log("table data", item);
                            // tdata.push({
                            //     Area: item.title,
                            //     Task: '',
                            //     Status: '',
                            //     Start: '',
                            //     End: '',
                            //     Actual_Start: '',
                            //     Actual_End: '',
                            //     Progress: '',
                            //     AssignTo: ''
    
                            // });
    
                            resp.data.success.forEach(iv => {
                                tdata.push({
                                    id : iv.taskid,
                                    Heading: iv.headingtype == 1 ? 'Heading' : '',
                                    Area: item.title,
                                    Task: iv.taskname,
                                    Status: iv.status,
                                    Start: iv.start_date,
                                    End: iv.end_date,
                                    Actual_Start: iv.actual_start,
                                    Actual_End: iv.actual_end,
                                    Progress: iv.progress,
                                    AssignTo: iv.receiver_emails
    
                                });
                            });
                        })
                })
                this.exceldata = tdata;
    
                // console.log("cordinatedata",cordinatedata);
    
            },
            annotadeImage() {
                this.preloader = true;
                this.showannotation = true;
    
                this.preloader = false;
    
                this.options.includeUI.loadImage.path = this.commentImage;
                // this.$refs.tuiImageEditor.invoke("loadImageFromURL", data, "tempImage")
                // .then((result) => {
                //     console.log(result);
    
                // });
    
                // delete axios.defaults.headers.common["Authorization"];
                // axios({
                //   url: this.image,
                //   method: "GET",
                //   withCredentials: true,
                // })
                //   .then((resp) => {
                //     console.log(resp);
                //     var token = localStorage.getItem("token");
                //     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                //   })
                //   .catch((err) => {
                //     console.log(err);
                //     var token = localStorage.getItem("token");
                //     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                //   });
                // this.showannotation = true;
                // // console.log("clicked");
                // // this.getBase64Image(this.source[0].url);
    
                // // this.$router.push('/dashboard/annotation');
                // //  let routeData = this.$router.resolve({name: 'annolation'});
                // var _self = this;
                // setTimeout(function () {
                //   _self.$refs.tuiImageEditor
                //     .invoke("loadImageFromURL", _self.source[0].url, "tempImage.jpg")
                //     .then((result) => {
                //       console.log(result);
                //     });
                //   localStorage.setItem("annotateimage", _self.source[0].url);
                // }, 1000);
    
                // //  console.log("Annotation opne",this.source[0].url);
            },
            getBase64Image(url) {
                this.showannotation = true;
                var canvas = document.createElement("CANVAS");
                var ctx = canvas.getContext("2d");
                var img = new Image();
                img.crossOrigin = "anonymous";
    
                let self = this;
    
                img.onload = function () {
                    canvas.height = img.height;
                    canvas.width = img.width;
                    ctx.drawImage(img, 0, 0);
    
                    var dataURL = canvas.toDataURL("image/png");
                    //callback.call(this, dataURL);
                    //   this.imagedd = dataURL;
                    // console.log(dataURL)
                    self.$refs.tuiImageEditor
                        .invoke("loadImageFromURL", dataURL, 'tempImage.jpg')
                        .then((result) => {
                            console.log(result);
                        });
    
                    // Clean up
                    canvas = null;
                };
                img.src = url;
    
            },
            /* SaveandSend() {
              this.preloader = true;
                //imgEl.src
                 const myImageb = this.$refs.tuiImageEditor.invoke("toDataURL");
    
            //     // console.log("Edit data", myImage);
    
               const _scale = 2.5;
              var img = document.createElement('img');
              img.setAttribute("src", myImageb);
                 var _self =  this;
              img.onload = () => {
            var canvass = document.createElement('canvas');
            canvass.width = img.width * _scale;
            canvass.height = img.height * _scale;
    
            var ctxx = canvass.getContext("2d");
            //var cw = canvas.width;
            //var ch = canvas.height;
            var maxW = img.width * _scale;
            var maxH = img.height * _scale;
    
            var iw = img.width;
            var ih = img.height;
            var scl = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scl;
            var ihScaled = ih * scl;
            canvass.width = iwScaled;
            canvass.height = ihScaled;
            ctxx.drawImage(img, 0, 0, iwScaled, ihScaled);
            const newBase64 = canvass.toDataURL("image/jpeg", scl);
    
            //   var a = document.createElement("a"); //Create <a>
            //     a.href = newBase64; //Image Base64 Goes here
            //     a.download = this.filename; //File name Here
                this.commentImage = newBase64;
    
                 //this.showannotation = false;
               // a.click(); //Downloaded file
    
              //callBack(newBase64);
              _self.preloader = false;
           }
    
            }, */
            SaveandSend() {
                this.preloader = true;
                //imgEl.src
                const myImageb = this.$refs.tuiImageEditor.invoke("toDataURL");
    
                //     // console.log("Edit data", myImage);
    
                const _scale = 2;
                var img = document.createElement('img');
                img.setAttribute("src", myImageb);
                var _self = this;
                img.onload = () => {
                    var canvass = document.createElement('canvas');
                    canvass.width = img.width * _scale;
                    canvass.height = img.height * _scale;
    
                    var ctxx = canvass.getContext("2d");
                    //var cw = canvas.width;
                    //var ch = canvas.height;
                    var maxW = img.width * _scale;
                    var maxH = img.height * _scale;
    
                    var iw = img.width;
                    var ih = img.height;
                    var scl = Math.min((maxW / iw), (maxH / ih));
                    var iwScaled = iw * scl;
                    var ihScaled = ih * scl;
                    canvass.width = iwScaled;
                    canvass.height = ihScaled;
                    ctxx.drawImage(img, 0, 0, iwScaled, ihScaled);
                    const newBase64 = canvass.toDataURL("image/jpeg", scl);
    
                    //   var a = document.createElement("a"); //Create <a>
                    //     a.href = newBase64; //Image Base64 Goes here
                    //     a.download = this.filename; //File name Here
                    this.commentImage = newBase64;
    
                    //this.showannotation = false;
                    // a.click(); //Downloaded file
    
                    //callBack(newBase64);
                    _self.preloader = false;
                }
    
                setTimeout(() => {
                    _self.showannotation = false;
                }, 1000)
            },
            cordinateclose() {
                this.cordinatInfo = false;
                this.$refs.moon.style.transform = 'scale(0)';
            },
    
            showannotationclose() {
                this.tui_re_render++;
                this.showannotation = false;
                self.$refs.tuiImageEditor.invoke("clearObjects");
            },
            finishDownload() {
                this.preloader = false
                this.reportToggle = false
                this.$store.dispatch('openReport', false)
            },
            clsoeReport() {
                this.reportToggle = false
                this.$store.dispatch('openReport', false)
            },
            clsoeReport1() {
                this.reportToggle1 = false
                this.$store.dispatch('openReport1', false)
            },
            clsoeReport2() {
                this.reportToggle2 = false
                this.$store.dispatch('openReport2', false)
            },
            closeMegamenu() {
                this.$store.dispatch('openDrawing', false)
            },
            dateFormate(d) {
    
                var date = d.split(' ');
                return date[0];
            },
            substring(str) {
    
                var text = str;
                if (str.length > 50) {
    
                    text = str.substr(1, 80) + "...";
                }
    
                return text;
    
            },
            Update_disNote() {
                //console.log("data to update", this.param_add);
                //camera_idd = this.notecam_id;
    
                this.preloader = true;
                axios({
                        url: "/project/" + this.cmt_project_id + "/post_discussion_note",
                        data: this.param_add,
                        method: "POST",
                    })
                    .then((resp) => {
                        if (resp.data.success) {
                            this.alert = true;
                            this.alertmessage = resp.data.success;
                            this.alertType = "success";
                            this.add_disnote = "";
                            this.get_discussion(this.cmt_data_d);
                            this.preloader = false;
                        } else {
                            this.alert = true;
                            this.alertmessage = resp.data.error;
                            this.alertType = "error";
                        }
                        /* var self = this;
                        setTimeout(function () {
                            self.dialog = false;
                            self.alert = false;
                        }, 1000); */
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
    
            Update_TaskdisNote() {
                this.preloader = true;
                axios({
                        url: "/project/" + this.projectid + "/task_discussion_note",
                        data: this.taskData,
                        method: "POST",
                    })
                    .then((resp) => {
                        if (resp.data.success) {
                            this.alert = true;
                            this.alertmessage = resp.data.success;
                            this.alertType = "success";
                            this.add_disnote = "";
                            this.openTaskDiscussion(this.cmt_data_d);
                            this.preloader = false;
                        } else {
                            this.alert = true;
                            this.alertmessage = resp.data.error;
                            this.alertType = "error";
                        }
                        /* var self = this;
                        setTimeout(function () {
                            self.dialog = false;
                            self.alert = false;
                        }, 1000); */
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            openTaskDiscussion(cmt_data) {
                this.taskdiscuss_dialog = true;
                console.log("get discussion Task data", cmt_data);
                this.cmt_data_d = cmt_data;
                this.cmt_coordinate = cmt_data.coordinateid;
                this.task_id = cmt_data.taskid;
                //this.notecam_id = camera_id;
                axios({
                        url: "/interior/task/" + this.projectid + "/" + this.cmt_coordinate + "/get_discussion/" + this.task_id,
                        method: "GET",
                    })
                    .then((resp) => {
                        //console.log("get note api", resp.data.success);
                        if (resp.data.success) {
                            this.taskDiscData = resp.data.success;
                        } else {
                            this.taskDiscData = {};
                            this.alert = true;
                            this.alertmessage = "Start a new discussion";
                            this.alertType = "error";
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            get_discussion(cmt_data) {
                this.cmtdiscuss_dialog = true;
                // console.log("get discussion data", cmt_data);
                this.cmt_data_d = cmt_data;
                this.cmt_project_id = cmt_data.project_id;
                this.cmt_coordinate = cmt_data.coordinate_id;
                this.cmtt_id = cmt_data.cmt_id;
                //this.notecam_id = camera_id;
                axios({
                        url: "/project/" + this.cmt_project_id + "/coordinate/" + this.cmt_coordinate + "/cmt/" + this.cmtt_id + "/get_discussion",
                        method: "GET",
                    })
                    .then((resp) => {
                        //console.log("get note api", resp.data.success);
                        if (resp.data.success) {
                            this.updatecamdata = resp.data.success;
                        } else {
                            this.updatecamdata = {};
                            this.alert = true;
                            this.alertmessage = "Start a new discussion";
                            this.alertType = "error";
    
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            clear_filter() {
                this.filter_status = '';
                this.filter_type = '';
                this.filter_duedate = '';
                this.filter_status1 = '';
                this.filter_type1 = '';
                this.filter_duedate1 = '';
            },
            // tuchdragMouseDown(e) {
            //     var touchLocation = e.targetTouches[0];
            //     console.log(touchLocation);
            //     this.$refs.draggableContainer.style.left = touchLocation.pageX + 'px';
    
            //     var rr = touchLocation.pageY;
    
            //     this.$refs.draggableContainer.style.top = rr + 'px';
            // },
            resizeTuch(event){
                console.log("tuch start");
                event.preventDefault()
              
                    this.positionsouter.clientX = event.targetTouches[0].clientX;
                   this.positionsouter.clientY = event.targetTouches[0].clientY;
              
                var x = event.targetTouches[0].clientX;
                var y = event.targetTouches[0].clientY;
    
                this.$refs.resize.style.height = y + 'px';
                this.$refs.resize.style.width = x + 'px';
              
            },
             touchelementDragResize: function (event) {
                event.preventDefault()
              
                    this.positionsouter.clientX = event.targetTouches[0].clientX;
                   this.positionsouter.clientY = event.targetTouches[0].clientY;
              
                var x = event.targetTouches[0].clientX;
                var y = event.targetTouches[0].clientY;
    
                this.$refs.resize.style.height = y + 'px';
                this.$refs.resize.style.width = x + 'px';
            },
            resizebox() {
                //   this.positionsouter.clientX = event.clientX
                //   this.positionsouter.clientY = event.clientY
                document.onmousemove = this.elementDragResize
                document.onmouseup = this.closeDragElementResize
    
            },
    
            elementDragResize: function (event) {
                event.preventDefault()
                //   this.positionsouter.movementX = this.positionsouter.clientX - event.clientX
                //   this.positionsouter.movementY = this.positionsouter.clientY - event.clientY
                this.positionsouter.clientX = event.clientX
                this.positionsouter.clientY = event.clientY
    
                   // this.currentX = event.targetTouches[0].clientX - this.startX;
                  //  this.currentY = event.targetTouches[0].clientY - this.startY;
                //   // set the element's new position:
                //    var rr = this.$refs.resize.offsetTop - this.positionsouter.movementY;
    
                var x = event.clientX;
                var y = event.clientY;
    
                this.$refs.resize.style.height = y + 'px';
                this.$refs.resize.style.width = x + 'px';
    
                // this.$refs.inerboxss.style.height = y + 'px';
                // this.$refs.inerboxss.style.width = x + 'px';
    
                //    this.$refs.movess.style.top = y +'px';
                //    this.$refs.movess.style.left = x +'px';
    
            },
            closeDragElementResize() {
                document.onmouseup = null
                document.onmousemove = null
            },
            dragboxtuch: function(event){
                   event.preventDefault()
                this.positionsouter.clientX = event.targetTouches[0].clientX; 
                this.positionsouter.clientY = event.targetTouches[0].clientY; 
    
                  document.ontouchmove = this.elementtouchDragouter;
            },
            elementtouchDragouter: function(event){
                
                this.positionsouter.movementX = this.positionsouter.clientX - event.targetTouches[0].clientX;
                this.positionsouter.movementY = this.positionsouter.clientY - event.targetTouches[0].clientY;
                this.positionsouter.clientX = event.targetTouches[0].clientX;
                this.positionsouter.clientY = event.targetTouches[0].clientY;
                // set the element's new position:
                var rr = this.$refs.resize.offsetTop - this.positionsouter.movementY;
    
                this.$refs.resize.style.top = rr + 'px'
                this.$refs.resize.style.left = (this.$refs.resize.offsetLeft - this.positionsouter.movementX) + 'px'
          
            },
    
            dragbox(event) {
                this.positionsouter.clientX = event.clientX
                this.positionsouter.clientY = event.clientY
                document.onmousemove = this.elementDragouter
                document.onmouseup = this.closeDragElementouter
            },
    
            elementDragouter: function (event) {
                event.preventDefault()
                this.positionsouter.movementX = this.positionsouter.clientX - event.clientX
                this.positionsouter.movementY = this.positionsouter.clientY - event.clientY
                this.positionsouter.clientX = event.clientX
                this.positionsouter.clientY = event.clientY
                // set the element's new position:
                var rr = this.$refs.resize.offsetTop - this.positionsouter.movementY;
    
                this.$refs.resize.style.top = rr + 'px'
                this.$refs.resize.style.left = (this.$refs.resize.offsetLeft - this.positionsouter.movementX) + 'px'
            },
            closeDragElementouter() {
                document.onmouseup = null
                document.onmousemove = null
            },
    
             tdragMouseDown: function (event) {
                event.preventDefault()
                // get the mouse cursor position at startup:
    
                this.positions.clientX = event.targetTouches[0].clientX;
                this.positions.clientY = event.targetTouches[0].clientY;
                 document.ontouchmove = this.ttouchmove
                
            },
    
            ttouchmove: function (event) {
                 event.preventDefault();
    
                   this.positions.movementX = this.positions.clientX - event.targetTouches[0].clientX
                this.positions.movementY = this.positions.clientY - event.targetTouches[0].clientY
                this.positions.clientX = event.targetTouches[0].clientX
                this.positions.clientY = event.targetTouches[0].clientY
                // set the element's new position:
                var rr = this.$refs.draggableContainer.offsetTop - this.positions.movementY;
    
                this.$refs.draggableContainer.style.top = rr + 'px'
                this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
            
            },
    
            dragMouseDown: function (event) {
                event.preventDefault()
                // get the mouse cursor position at startup:
    
                this.positions.clientX = event.clientX
                this.positions.clientY = event.clientY
                document.onmousemove = this.elementDrag
                document.onmouseup = this.closeDragElement
            },
            elementDrag: function (event) {
                event.preventDefault()
                this.positions.movementX = this.positions.clientX - event.clientX
                this.positions.movementY = this.positions.clientY - event.clientY
                this.positions.clientX = event.clientX
                this.positions.clientY = event.clientY
                // set the element's new position:
                var rr = this.$refs.draggableContainer.offsetTop - this.positions.movementY;
    
                this.$refs.draggableContainer.style.top = rr + 'px'
                this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
            },
            closeDragElement() {
                document.onmouseup = null
                document.onmousemove = null
            },
            resize() {
                this.$refs.draggableContainer.style.transform = 'scale(1)';
                this.$refs.moon.style.transform = 'scale(1)';
            },
            goplus() {
    
                var p = this.zoom + 0.1;
                console.log(p);
                this.zoom = p;
    
                if (p > 1) {
                    this.$refs.moon.style.transform = 'scale(0.5)';
                   // this.radius = 5;
                } else if (p > 2) {
                    this.$refs.moon.style.transform = 'scale(0.2)';
                  //  this.radius = 2;
                }
    
                this.$refs.draggableContainer.style.transform = 'scale(' + p + ')';
    
            },
            gominus() {
    
                var p = this.zoom - 0.1;
                console.log(p);
                this.zoom = p;
                if (p > 1) {
                    this.$refs.moon.style.transform = 'scale(0.5)';
                    //this.radius = 5;
                } else if (p > 2) {
                    this.$refs.moon.style.transform = 'scale(0.2)';
                  //  this.radius = 2;
                }
    
                this.$refs.draggableContainer.style.transform = 'scale(' + p + ')';
                this.$refs.draggableContainer.style.top = '0';
                this.$refs.draggableContainer.style.left = '0';
            },
    
            goPositon(p) {
    
                  console.log(p); 
    
            //    // this.$refs.draggableContainer.style.transform = 'scale(' + p + ')';
            //     if (p == 1) {
            //         // this.$refs.draggableContainer.style.top = '0px'
            //         // this.$refs.draggableContainer.style.left = '0px'
            //         //  this.$refs.resize.style.height = '100%';
            //         //  this.$refs.resize.style.width = '100%';
            //      }
            //      // else {
            //     //     this.$refs.draggableContainer.style.top = '0px'
            //     //     this.$refs.draggableContainer.style.left = '50px'
    
            //     //     //  this.$refs.resize.style.height = '250px';
            //     //     //  this.$refs.resize.style.width = '250px';
            //     // }
            },
            lockScreens() {
    
                // console.log("Lock Screen");
    
                var a = this.$refs.panaromascreen;
    
                console.log("Viewer", a);
    
                this.lock = !this.lock;
                //     this.hfov = 75;
                //     this.yaw = 0;
                //     this.pitch  = 0;
                //  this.draggable = false;
                //  this.mouseZoom  = false;
                //  this.doubleClickZoom = false;
                //  this.showZoom = false;
                //  this.showFullscreen  = false;
    
                // if(this.lock){
    
                //  a.showControls = false
    
                // }
    
                // this.imagepanaroma = this.slides[this.selected[this.selected.length - 2]].mediapath;
                // this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;
    
                // //  console.log(this.selected.length);
    
                // this.secondimagepanaroma = this.slides[this.selected[this.selected.length - 1]].mediapath;
                // this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
    
            }
            //  ---------------------------------------------------------
            ,
            coorddinates() {
                var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
                    canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;
    
                has_canvas = !!document.createElement('canvas').getContext;
    
                // VML: more complex
                has_VML = (function () {
                    var a = document.createElement('div');
                    a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
                    var b = a.firstChild;
                    b.style.behavior = "url(#default#VML)";
                    return b ? typeof b.adj == "object" : true;
                })();
    
                if (!(has_canvas || has_VML)) {
                    $.fn.maphilight = function () {
                        return this;
                    };
                    return;
                }
    
                if (has_canvas) {
                    hex_to_decimal = function (hex) {
                        return Math.max(0, Math.min(parseInt(hex, 16), 255));
                    };
                    css3color = function (color, opacity) {
                        return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
                    };
                    create_canvas_for = function (img) {
                        var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
                        c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
                        return c;
                    };
                    var draw_shape = function (context, shape, coords, x_shift, y_shift) {
                        x_shift = x_shift || 0;
                        y_shift = y_shift || 0;
    
                        context.beginPath();
                        if (shape == 'rect') {
                            // x, y, width, height
                            context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
                        } else if (shape == 'poly') {
                            context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
                            for (var i = 2; i < coords.length; i += 2) {
                                context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
                            }
                        } else if (shape == 'circ') {
                            // x, y, radius, startAngle, endAngle, anticlockwise
                            context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
                        }
                        context.closePath();
                    };
                    add_shape_to = function (canvas, shape, coords, options) {
                        var context = canvas.getContext('2d');
    
                        ///console.log(name,);
    
                        // Because I don't want to worry about setting things back to a base state
    
                        // Shadow has to happen first, since it's on the bottom, and it does some clip /
                        // fill operations which would interfere with what comes next.
                        if (options.shadow) {
                            context.save();
                            if (options.shadowPosition == "inside") {
                                // Cause the following stroke to only apply to the inside of the path
                                draw_shape(context, shape, coords);
                                context.clip();
                            }
    
                            // Redraw the shape shifted off the canvas massively so we can cast a shadow
                            // onto the canvas without having to worry about the stroke or fill (which
                            // cannot have 0 opacity or width, since they're what cast the shadow).
                            var x_shift = canvas.width * 100;
                            var y_shift = canvas.height * 100;
                            draw_shape(context, shape, coords, x_shift, y_shift);
    
                            context.shadowOffsetX = options.shadowX - x_shift;
                            context.shadowOffsetY = options.shadowY - y_shift;
                            context.shadowBlur = options.shadowRadius;
                            context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);
    
                            // Now, work out where to cast the shadow from! It looks better if it's cast
                            // from a fill when it's an outside shadow or a stroke when it's an interior
                            // shadow. Allow the user to override this if they need to.
                            var shadowFrom = options.shadowFrom;
                            if (!shadowFrom) {
                                if (options.shadowPosition == 'outside') {
                                    shadowFrom = 'fill';
                                } else {
                                    shadowFrom = 'stroke';
                                }
                            }
                            if (shadowFrom == 'stroke') {
                                context.strokeStyle = "rgba(0,0,0,1)";
                                context.stroke();
                            } else if (shadowFrom == 'fill') {
                                context.fillStyle = "rgba(0,0,0,1)";
                                context.fill();
                            }
                            context.restore();
    
                            // and now we clean up
                            if (options.shadowPosition == "outside") {
                                context.save();
                                // Clear out the center
                                draw_shape(context, shape, coords);
                                context.globalCompositeOperation = "destination-out";
                                context.fillStyle = "rgba(0,0,0,1);";
                                context.fill();
                                context.restore();
                            }
                        }
    
                        context.save();
    
                        draw_shape(context, shape, coords);
    
                        // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
                        // which mostly looks weird when the shadow has a high opacity
                        if (options.fill) {
                            context.fillStyle = css3color(options.fillColor, options.fillOpacity);
                            context.fill();
                        }
                        // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
                        // shadow or the shadow-background if it's present.
                        if (options.stroke) {
                            context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
                            context.lineWidth = options.strokeWidth;
                            context.stroke();
                        }
    
                        context.restore();
    
                        if (options.fade) {
                            $(canvas).css('opacity', 0).animate({
                                opacity: 1
                            }, 100);
                        }
                    };
                    clear_canvas = function (canvas) {
                        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                    };
                } else { // ie executes this code
                    create_canvas_for = function (img) {
                        return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
                    };
                    add_shape_to = function (canvas, shape, coords, options, name) {
                        var fill, stroke, opacity, e;
                        for (var i in coords) {
                            coords[i] = parseInt(coords[i], 10);
                        }
                        fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
                        stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
                        opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
                        if (shape == 'rect') {
                            e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
                        } else if (shape == 'poly') {
                            e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
                        } else if (shape == 'circ') {
                            e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
                        }
                        e.get(0).innerHTML = fill + opacity;
                        $(canvas).append(e);
                    };
                    clear_canvas = function (canvas) {
                        // jquery1.8 + ie7
                        var $html = $("<div>" + canvas.innerHTML + "</div>");
                        $html.children('[name=highlighted]').remove();
                        $(canvas).html($html.html());
                    };
                }
    
                shape_from_area = function (area) {
                    var i, coords,
                        shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
                    if (shape === 'defa') {
                        // 'default' doesn't really apply to what we're doing; it's the background state
                        return;
                    }
                    coords = (area.getAttribute('coords') || '').split(',');
                    for (i = 0; i < coords.length; i++) {
                        coords[i] = parseFloat(coords[i]);
                    }
                    return [shape, coords];
                };
    
                options_from_area = function (area, options) {
                    var $area = $(area);
                    return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
                };
    
                is_image_loaded = function (img) {
                    if (!img.complete) {
                        return false;
                    } // IE
                    if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
                        return false;
                    } // Others
                    return true;
                };
    
                canvas_style = {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    padding: 0,
                    border: 0
                };
    
                var ie_hax_done = false;
                $.fn.maphilight = function (opts) {
                    opts = $.extend({}, $.fn.maphilight.defaults, opts);
    
                    if (!has_canvas && !ie_hax_done) {
                        $(window).ready(function () {
                            document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
                            var style = document.createStyleSheet();
                            var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
                            $.each(shapes,
                                function () {
                                    style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
                                }
                            );
                        });
                        ie_hax_done = true;
                    }
    
                    return this.each(function () {
                        var img, wrap, options, map, canvas, canvas_always, usemap;
                        img = $(this);
    
                        //  console.log(highlighted_shape,imgSrc);
    
                        if (!is_image_loaded(this)) {
                            // If the image isn't fully loaded, this won't work right.  Try again later.
                            return window.setTimeout(function () {
                                img.maphilight(opts);
                            }, 200);
                        }
    
                        options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));
    
                        // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
                        // So use raw getAttribute instead.
                        usemap = img.get(0).getAttribute('usemap');
    
                        if (!usemap) {
                            return;
                        }
    
                        map = $('map[name="' + usemap.substr(1) + '"]');
    
                        if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
                            return;
                        }
    
                        if (img.hasClass('maphilighted')) {
                            // We're redrawing an old map, probably to pick up changes to the options.
                            // Just clear out all the old stuff.
                            var wrapper = img.parent();
                            img.insertBefore(wrapper);
                            wrapper.remove();
                            $(map).unbind('.maphilight');
                        }
    
                        //Formating the image source. IE > 9 has issue with new line characters
                        // var imgSrc = this.src.replace(/[\n\r]/g, '');
                        //  console.log(imgSrc);
                        wrap = $('<div></div>').css({
                            display: 'block',
                            background: 'url("' + this.src + '")',
                            "background-size": 'contain',
                            "background-repeat": 'no-repeat',
                            position: 'relative',
                            padding: 0,
                            width: this.width,
                            height: this.height
                        });
                        if (options.wrapClass) {
                            if (options.wrapClass === true) {
                                wrap.addClass($(this).attr('class'));
                            } else {
                                wrap.addClass(options.wrapClass);
                            }
                        }
                        // Firefox has a bug that prevents tabbing into the image map if
                        // we set opacity of the image to 0, but very nearly 0 works!
                        img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
                        if (has_VML) {
                            img.css('filter', 'Alpha(opacity=0)');
                        }
                        wrap.append(img);
    
                        canvas = create_canvas_for(this);
                        $(canvas).css(canvas_style);
                        canvas.height = this.height;
                        canvas.width = this.width;
    
                        $(map).bind('alwaysOn.maphilight', function () {
                                // Check for areas with alwaysOn set. These are added to a *second* canvas,
                                // which will get around flickering during fading.
                                if (canvas_always) {
                                    clear_canvas(canvas_always);
                                }
                                if (!has_canvas) {
                                    $(canvas).empty();
                                }
                                $(map).find('area[coords]').each(function () {
                                    var shape, area_options;
                                    area_options = options_from_area(this, options);
                                    if (area_options.alwaysOn) {
                                        if (!canvas_always && has_canvas) {
                                            canvas_always = create_canvas_for(img[0]);
                                            $(canvas_always).css(canvas_style);
                                            canvas_always.width = img[0].width;
                                            canvas_always.height = img[0].height;
                                            img.before(canvas_always);
                                        }
                                        area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                                        shape = shape_from_area(this);
                                        if (!shape) {
                                            return;
                                        }
                                        if (has_canvas) {
                                            add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                                        } else {
                                            add_shape_to(canvas, shape[0], shape[1], area_options, "");
                                        }
                                    }
                                });
                            }).trigger('alwaysOn.maphilight')
                            .bind('mouseover.maphilight focusin.maphilight', function (e) {
                                console.log(e);
                                var shape, area_options, area = e.target;
                                area_options = options_from_area(area, options);
                                if (!area_options.neverOn && !area_options.alwaysOn) {
                                    shape = shape_from_area(area);
                                    if (!shape) {
                                        return;
                                    }
                                    add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                                    if (area_options.groupBy) {
                                        if (typeof area_options.groupBy == 'string') {
                                            area_options.groupBy = [area_options.groupBy];
                                        }
                                        var el = $(this);
                                        $.each(area_options.groupBy, function (index, groupitem) {
                                            var areas;
                                            // two ways groupBy might work; attribute and selector
                                            if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                                                areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                                            } else {
                                                areas = map.find(groupitem);
                                            }
                                            var first = this;
                                            areas.each(function () {
                                                if (this != first) {
                                                    var subarea_options = options_from_area(this, options);
                                                    if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                                                        var shape = shape_from_area(this);
                                                        add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                                                    }
                                                }
                                            });
                                        });
                                    }
                                    // workaround for IE7, IE8 not rendering the final rectangle in a group
                                    if (!has_canvas) {
                                        $(canvas).append('<v:rect></v:rect>');
                                    }
                                }
                            }).bind('mouseout.maphilight focusout.maphilight', function (e) {
                                console.log(e);
                                clear_canvas(canvas);
                            });
    
                        img.addClass('maphilighted');
                        img.before(canvas); // if we put this after, the mouseover events wouldn't fire.
                    });
                };
                $.fn.maphilight.defaults = {
                    fill: true,
                    fillColor: 'fdc004',
                    fillOpacity: 0.2,
                    stroke: true,
                    strokeColor: '000000',
                    strokeOpacity: 1,
                    strokeWidth: 1,
                    fade: true,
                    alwaysOn: true,
                    neverOn: false,
                    groupBy: false,
                    wrapClass: true,
                    // plenty of shadow:
                    shadow: true,
                    shadowX: 0,
                    shadowY: 0,
                    shadowRadius: 6,
                    shadowColor: '000000',
                    shadowOpacity: 0.8,
                    shadowPosition: 'outside',
                    shadowFrom: false
                }
            },
            opendialogbox() {
                //  console.log(value);
                this.reportdialogbox = true
            },
    
            filerReportCoordinate() {
    
                this.reportStart = true;
    
                var ids = [];
    
                this.filterdata = {}
    
                this.filteredList.forEach(c => {
                    console.log("id", c.cmt_id, c);
    
                    ids.push(c.cmt_id);
    
                });
    
                console.log("ids", ids);
    
                //   var data = ids.toString();
    
                axios({
                        url: '/interior/get-commnent-byfilter',
                        method: 'get',
                        params: {
                            cmds: ids
    
                        }
                    })
                    .then((resp) => {
    
                        console.log(resp);
                        this.filterdata = resp.data.success;
                        this.generate_snaglistreport();
                        this.commentsdataa = true;
                        this.filterdrawer = false;
    
                    }).catch((err) => {
                        console.log(err);
                    });
            },
            filerReport() {
                this.reportStart = true;
                var ids = [];
    
                this.filteredList_drawing.forEach(c => {
                    console.log("id", c.cmt_id, c);
    
                    ids.push(c.cmt_id);
    
                });
    
                console.log("ids", ids);
    
                //   var data = ids.toString();
    
                axios({
                        url: '/interior/get-commnent-byfilter',
                        method: 'get',
                        params: {
                            cmds: ids
    
                        }
                    })
                    .then((resp) => {
    
                        console.log(resp);
                        this.drawing_commentsdataa = resp.data.success;
                        this.completed_drawingsnaglistreport();
                        // this.drawing_commentsdataa= true;
                        this.filterdrawer = false;
    
                    }).catch((err) => {
                        console.log(err);
                    });
            },
            openMoreAssign(cmt) {
    
                console.log("openMoreAssign", cmt);
                this.opencmt = cmt;
                this.moreEmails = cmt.receiver_emails
                this.assingtoMorebox = true;
    
            },
            openTaskAssign(cmt) {
                console.log("openTaskAssign", cmt);
                this.openTask = cmt;
                this.moreEmails = cmt.receiver_emails
                this.assingtoTaskbox = true;
            },
            submitEmailAssign() {
    
                console.log("this.moreEmails", this.moreEmails, this.opencmt);
    
                // console.log(comment);
    
                var comment = this.opencmt
    
                // console.log(status);
    
                axios({
                        url: '/interior/commnent_emails_update',
                        method: 'get',
                        params: {
                            comment_id: comment.cmt_id,
                            receiver_emails: this.moreEmails
                        }
                    })
                    .then(() => {
    
                        this.assingtoMorebox = false;
                        this.getCommnets_bydrawing();
                        //this.getCommnets_bs64();
                    })
                    .catch(err => {
                        console.log(err);
                        this.assingtoMorebox = false;
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
    
            submitTaskEmailAssign() {
    
                // console.log(comment);
    
                var comment = this.openTask
    
                // console.log(status);
    
                axios({
                        url: '/interior/task_emails_update',
                        method: 'get',
                        params: {
                            taskid: comment.taskid,
                            receiver_emails: this.moreEmails
                        }
                    })
                    .then(() => {
    
                        this.assingtoTaskbox = false;
                        this.getListItemss();
                    })
                    .catch(err => {
                        console.log(err);
                        this.assingtoTaskbox = false;
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            },
            onChange(event) {
                 this.file = event.target.files ? event.target.files[0] : null;
    
            },
            async updateTaskFile() {
                let childEl = this.$refs.tasks.innerHTML;
    
                console.log("childEl",childEl);
    
                this.preloader = true;
                var userdata = {
                     filedata: childEl
                }
                
                 console.log(userdata);
                axios({
                        url: 'interior/update-tasks/excel-file',
                        data: userdata,
                        method: 'post'
                    })
                    .then(resp => {
                        console.log("Resp", resp);
                        if (resp.data.success) {
                            this.preloader = false;
                            this.importFile = false;
                            this.alert = true;
                            this.alertmessage = "Tasks updated successfully";
                            this.alertType = "success";
                        } else {
                            this.preloader = false;
                            this.importFile = false;
                            this.alert = true;
                            this.alertmessage = resp.data.error;
                            this.alertType = "error"
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
    
        }
    };
    </script>
    
    <style>
    .searcheMenu {
        position: absolute;
        right: 28px;
    }
    span.citem p {
        font-size: 9px;
    }
    video {
        height: 100vh;
        width: 100vw;
        object-fit: fill;
    }
    .panaromovideos{
        height: 200px;
        width: 400px;
        position: fixed;
        right:10px;
        bottom : 200px;
    }
    </style>
    
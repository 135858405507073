export default {
       welcome: 'Bienvenidas',
        SelectCamera :'Seleccionar cámara',
        Projects : 'Proyectos',
        AIData : 'PPE',
        AIDataAnalysis : 'Gráficos de PPE',
        PpeReports : 'Informes de EPI',
        Aqi :'Aqi',
        PeopleDetection :'Detección de personas',
        Archive : 'Archivo',
        Live : 'Vivir',
        Timelapse :'Lapso de tiempo',
        WatchTimelapse : 'Ver lapso de tiempo',
        CreateTimelapse :'Crear lapso de tiempo',
        Compare :'Comparar',
        MobileUpload  :'Carga móvil',
        Planning :'Planificación',
        EditData :'Editar datos',
        CompareEvents :'Comparar eventos',
        EventChart : 'Gráfico de eventos',
        EventTimelapse :'Lapso de tiempo del evento',
        Report :'Reporte',
        FavoriteList : 'Lista favorita',
        More : 'Más',
        EventTimeline : 'Cronología del evento',
        SplitScreen : 'Pantalla dividida',
        Back :'atrás',
        Menu : 'Menú',
        Options : 'Opciones',  
        CreateDetailedReport : 'Crear informe detallado',
        InstantReport : 'Informe instantáneo',
        CreateNow : 'Crea ahora',
        Scheduleit : 'Programarla',
        PleaseReloadPage : 'Por favor recarga la página',
        ImageLoadError : 'Lo siento, la imagen no se carga',
        imagenotFound : 'Imágenes no encontradas en la fecha seleccionada',
        ImageShareSuccessful :'Compartir imagen exitoso',
        SomethingWentWrong  :'Algo salió mal. Por favor, vuelva a intentarlo.',
        ImageShareProcoreSuccessful :'Imagen compartida con Procore exitoso.',
        LayoutSuccessfullyUploaded :'El diseño se ha subido correctamente',
        LayoutSuccessfullyDeleted :'El diseño se ha eliminado correctamente',
        Logout : 'Cerrar sesión',
        SuperAdmin : 'Superadministrador',
        User : 'Usuario',
        Admin : 'Administrador',
        SelectLanguage :'Seleccione el idioma',
        UpdatePassword : 'Actualiza contraseña',
        AddUser : 'Agregar usuario',
        Users : 'Administrar usuarios',
        UserLogs :'Registros de usuario',
        EmailTrigger : 'Desencadenador de correo electrónico',
        CameraOffalert : 'Alerta de cámara apagada',
        CameraStatus : 'Estado de la cámara',
        CameraNameAndTag : 'Nombre y etiqueta de la cámara',
        CreateCustomTimelapse : 'Crear lapso de tiempo personalizado',
        Billing : 'Facturación',
        ManageBimRenderings :'Administrar renderizados BIM',
        Integration : 'Integración',
        TwoFactorAuthentication :'Two-Factor Authentication',
        DemoVideos :'Vídeos de demostración',
        Support : 'Apoyo',
        CameraConfiguration :'Configuración de la cámara',
        ProjectInfo : 'Información del proyecto',
        SearchProjects : 'Buscar Proyectos',
        ContactSupport :'Soporte de contacto',
        SendEmail :'Enviar correo electrónico',
        MapView : 'Vista del mapa',
        ConstructionCamera : 'cámara de construcción',
        InteriorMonitoring : 'Monitoreo Interior',
        MaterialTracking : 'Seguimiento de materiales',
        DroneMonitoring : 'Monitoreo de drones',
        LinearConstructionMonitoring : 'Monitoreo de Construcción Lineal',
        InteractiveDashboard : 'Tablero interactivo',
        To:'A',
        OtherEmails:'Agregar más correos electrónicos aquí',
        Subject : 'Sujeto',
        Message : 'Mensaje',
        SelectImage : 'Seleccionar imagen',
        EmailRequired : 'Correo electronico es requerido',
        EmailValid : 'El email debe ser válido',
         required: 'Este campo es obligatorio',
        Send : 'Enviar',
        Cancel : 'Cancelar',
        Close :'Cerca',
        Done : 'Hecho',
        AddNew : 'Agregar nuevo',
        Ok :'OK',
        Save : 'Ahorrar',
        LiveImage : 'Imagen en vivo',
        ImageMarkup : 'Marcado de imagen',
        ShareImage : 'Compartir imagen',
        AddEventTimeline :'Agregar a la línea de tiempo del evento',
        RemoveFavorite : 'Eliminar favorito',
        AddToFavorite :  'Agregar a "Favoritos',
        Weather : 'Clima',
        DownloadImage : 'Descargar imagen',
        FullScreen : 'Pantalla completa',
        Pleasestandby : 'Por favor espere',
        UpdateCameraTag : 'Actualizar etiqueta de cámara',
        BIM : 'BIM',
        FullTimeline : 'Cronología completa',
        ShareToProcore :'Compartir con Procore',
        WeatherInfo : 'Información meteorológica',
        CreateNewBIM: 'Crear nueva representación BIM',
        OpticVyuUpdates : 'Actualizaciones de OpticVyu',
        InstallationChecklist : 'Lista de verificación de instalación',
        InstallationDetails: 'Detalles de instalación',
        SimCardAdded : 'Tarjeta SIM añadida',
        title :'título',
        ItemRequired : 'El artículo es obligatorio',
        Remoteit :'Remoto',
        InstallationSnap :'Broche de instalación',
        CamConfigSnap :'Instantánea de configuración de cámara',
        SpeedTest :'Prueba de velocidad',
        ActivateReport : 'Activar informe',
        SiteLayout : 'Plano del sitio',
        CamNameTag :  'Nombre y etiqueta de la cámara',
        DeliveryChallan :'Entrega Challán',
        LocationWhatapps : 'Ubicación Whatapps',
        ImageDeletion : 'Eliminación de imagen',
        NolayoutimageAvailable : 'No hay imagen de diseño disponible',
        Deletelayout : 'Eliminar diseño',
        GoToBilling : 'Ir a Facturación',
        NotifyTeam:  'Notificar al equipo',
        billingNote : 'Nota: Pague todas las facturas pendientes para eliminar esta notificación.',
        mediaUploadTitle : 'Haga clic para cargar un diseño',
        DragDrop : 'Arrastrar y soltar',
        EmailFaild : 'Entrega de correo electrónico fallida',
        Liveimagenotfound : 'Imagen en vivo no encontrada',
        removedFavorites :'Imagen eliminada de Favoritos',
        addedFavourites : 'Imagen añadida a Favoritos',
        WhomtoNotify : '¿A quién notificar (correo electrónico)?',
        ShareByEmail :'Compartir por correo electrónico',
        Structure : 'Estructura',
        RenederingName : 'Nombre de reproducción',
        RenderingInfo : 'Rendering Info',
        DiscribeTheEvent : 'Describe el evento',
        Imagenotfound : 'Imagen no encontrada',
        MainView : 'Vista principal',
        EditView :'Editar vista',
        SurveyDate : 'Fecha de la encuesta',
        SelectZone : 'Seleccionar Zona',
        Inventories : 'Inventarios',

        Draft : 'Sequía',
        AddMaterial :'Agregar material',
        Suppliers :'Proveedores',
        EditTask : 'Editar tarea',
        selectEvent: "SELECCIONE EVENTO", 
        PlannedStartDate  : 'Fecha de inicio planificada',
        seeActualStartDate:'haga clic aquí para ver las imágenes reales de la fecha de inicio y finalización',
        seeActualEndDate : 'haga clic aquí para ver las imágenes reales de la fecha de inicio y finalización',
        SeePlannedDateImages : 'Ver imágenes de fecha planificada',
        plannedEndDate:'Fecha de finalización planificada',
        ActualStartDate :'Fecha de inicio real',
        SaveActualStartDate:'Guardar fecha de inicio real',
        SaveActualEndDate : 'Guardar fecha de finalización real',
        StartdateSave : 'Fecha de inicio real Guardar con éxito',
        EnddateSave : 'Fecha de finalización real Guardar con éxito',
        Search : 'Búsqueda',
        Import :'importar',
        Export : 'Exportar',
        TaskName : 'Nombre de la tarea',
        startDateTime:"Fecha-hora de inicio",
        endDatetime:'Fecha y hora de finalización',
        SelectDatetime : 'Seleccione Fecha-hora',
        AddNewTask : 'Agregar nueva tarea',
        ImportExcelFile : 'Importar archivo de Excel',
        Steps : 'Pasos',
        stepOne:'1. Exporte el Excel (.xls) del proyecto Primavera / Microsoft, etc.',
        stepTwo: '2. Copie los datos en sample.xls sin cambiar su formato o etiquetas de columna',
        stepThree : '3. Cargue el archivo sample.xls.',
        Downloadfile:'Descargar archivo Muestra.xls',


        RecordUpdatesuccessfully:'Registro de actualización con éxito',
        NewEventsuccess:'Nueva tarea de evento enviada con éxito',
        taskUplaod : 'Su tarea se cargó con éxito.',
        submit : 'Entregar',
        StartDate : 'Start Date',
        EndDate : 'Fecha final',
        ActualEndDate :'Fecha de finalización real',
        Actions :'Comportamiento',
        pleaseSelectanyOne :'Seleccione cualquiera',
        eventtimenote:'Lapso de tiempo del evento: solo durante el día (8 a. m. a 5 p. m.)',
        eventtime24 : 'Time-lapse del evento: 24 Hrs',
        videoConfirm : '¿Confirmar eliminar estos videos?',
      removealert:'Video de lapso de tiempo eliminado con éxito',
      videonotfound : 'No se encuentra el video de lapso de tiempo.',
      AddNewUser : 'Agregar nuevo usuario',
      Checkinfo : 'comprobar información',
      ManageUser :'Administrar usuario',
      FirstName : 'Primer nombre',
      LastName : 'Apellido',
      Email : 'Correo electrónico',
      ContactNumber :'Contact Number',
       selectproject: 'Seleccionar proyectos para el usuario',
      selectProjectOpenAccess: 'Seleccionar proyectos',
      newUserOpenAccess: 'Nuevo usuario con acceso abierto creado con éxito',
      updateUserErrorOpenAccess: 'No se puede actualizar el estado del usuario',
      createOpenAccessHeading: 'Crear enlace de acceso abierto',
      createOpenAccessLinkButton: 'Crear enlace',
      createOpenAccessLinkNavButton:'Enlace de acceso abierto',
      MakeNormalUser : 'Hacer usuario normal',
      Privileges : 'Privilegios',
      SeeExistingUsers : 'Ver usuarios existentes',
      MakeAdmin : 'Hacer administrador',
      ChangePassword : 'Cambia la contraseña',
      EmailScheduler : 'Programador de correo electrónico',
      CameraTag : 'Etiqueta de cámara',
      CameraInfo : 'Información de la cámara',
      CameraNotification : 'Notificación de cámara',
      CustomizeTimelapseVideo : 'Personalizar video de lapso de tiempo',
      Userinfo : 'Información de usuario',
      Name : 'Nombre',
      CreatedDate: 'Fecha de creación',
      Contact : 'Contacto',
      Status : 'Estado',
      ProjectName :'Nombre del proyecto',
      Company :'Compañía',
      City :'Ciudad',
      AddMorePorject :'Agregar más proyecto',
      usermsgone : 'Acceso dado para cámara de obra. Para administrar el acceso a Monitoreo de interiores y seguimiento de materiales, vaya a administrar usuarios',
      usermsgtwo: 'Acceso dado para módulo de vigilancia interior. Para administrar el acceso para el seguimiento de materiales, vaya a administrar usuarios',
      usermsgthree :'Usuario añadido con éxito. Las instrucciones de inicio de sesión se han enviado por correo electrónico al nuevo usuario.',
      usererrorp1 :'El usuario ya existe. Solicite a su superadministrador que vaya a Opciones-->Usuarios y otorgue acceso a',
      usererrorp2 : 'para el proyecto actual',
      Default : 'Defecto',
      CreatenewBIMRenedering :'Crear nueva representación BIM',
      createprocore:'Conéctese con Procore',
      ConnectWithBim360 : ' Conectar con Bim 360',
      requestURL:'Solicitar URL de aplicación integrada',
      biminstruction : 'Para usar la "URL de la aplicación integrada" en el panel de BIM360 Insight, siga las instrucciones a continuación',
      instOne: '1. Vaya al panel de BIM 360 Insight',
      instTwo: '2. Ir a Personalizar',
      instThree : '3. Haga clic en Biblioteca de tarjetas',
      instfour :'4. Busque una tarjeta llamada "OpticVyu" o "Tarjeta de socio" y agréguela.',
      instFive :'5. Configure la tarjeta con el título apropiado y OpticVyu "URL de aplicación integrada" ',
      instSix:'6. Las imágenes de la cámara deben mostrarse automáticamente.',
      bimQue :'¿Qué proyectos desea mostrar en la aplicación integrada?',
      bimsuccessmsg:'¡Solicitud registrada! Recibirá la URL de la aplicación incrustada dentro de los 2 días por correo electrónico.',
      Sim :'Sim',
      Notes: 'notas',
      CameraReboot :'Reinicio de cámara',
      ImageInterval :'Intervalo de imagen',
      CamType :'Tipo de leva',
      BackImageTime : 'Atrás Imagen Tiempo',
      ViewAddNote:'Ver/Agregar nota',
      SimManage:'Administrar Sim',
      AddCameraNote :'Agregar nota de cámara',
      AddNote :'Añadir la nota',
      AssignSimcard : 'Asignar tarjeta SIM',
      Addsimno :'Añadir sim no.',
      simprovider : 'proveedor de sim',
      Simusagestatus :'Estado de uso de la simulación',
      ifsimfree : 'si es libre, con quien?',
      UpdatedBy : 'Actualizado por',
      camsetting: 'Configuración de la cámara actualizada con éxito',
      camfialdsetting :'No se pudo actualizar la configuración de la cámara. ¡Inténtalo de nuevo!',
      EditCameraNameandTag:'Editar nombre y etiqueta de la cámara',
      CameraName : 'Nombre de la cámara',
      Reset :'Reiniciar',
      cameranotification:'Notificación de encendido/apagado de la cámara',
      NotificationActive : 'Notificación activa',
      camranotiStatus:'El estado de notificación de la cámara está inactivo, para activarlo, haga clic en la casilla de verificación',
      cameraActivemsg :'Notificación de cámara activada',
      Resetsuccesful:'Restablecimiento exitoso.',

      ChangeYourPassword :'cambia tu contraseña',
      OldPassword :'Contraseña anterior',
      NewPassword : 'Nueva contraseña',
      ConfirmPassword:'Confirmar contraseña',
      passwordvalid:'Ingrese al menos un carácter mayúscula, pequeño, numérico y espacial',
      passwordsame: 'La contraseña debe ser la misma',
      UploadSpeed : 'Velocidad de carga',
      OpticVyuPresentation : 'Presentación OpticVyu',
      DateofInstallation :'Fecha de instalación',
      Designation : 'Designacion',
      Person1 : 'Persona 1',
      Person2 : 'Persona 2',
      Person3 : 'Persona 3',
      Person4 : 'Persona 4',
      AccountPerson :'Persona de la cuenta',
      InstallationNote:'Nota de instalación',
      OVEnginner : 'Ingeniero AV',
      OneTimeTimelapse : 'Lapso de tiempo único',
      AdvanceTimelapse : 'Avance de lapso de tiempo',
      RecurringTimelapse : 'Lapso de tiempo recurrente',
      ProjectTimelapse : 'Lapso de tiempo del proyecto',
      Refresh : 'Actualizar',
      StartTime :'Hora de inicio',
      Endtime : 'Hora de finalización',
      TimelapseSpeed:'Velocidad de lapso de tiempo',
      TimelapseQuality :'Calidad de lapso de tiempo',
      TimelapseAdvancesettings : 'Configuración avanzada de lapso de tiempo',
      Selectframeblending : 'Seleccione la combinación de fotogramas',
      SelectVideoSmoothing :'Seleccione Suavizado de video',
      SelectDeflicker : 'Seleccione Eliminador de parpadeo',
      EnterOverlayText:'Ingrese texto superpuesto (opcional)',
      timelapsenote1 : '1. El lapso de tiempo avanzado puede demorar hasta 8 horas. Se le notificará una vez que esté listo.',
      timelapsenote2 : '2. Para una personalización rápida, utilice la opción de lapso de tiempo personalizado único.',
      projectnote1 : '1. El lapso de tiempo del proyecto puede demorar hasta 12 horas. Se le notificará una vez que esté listo.',
      selectop1 :'Use las opciones de fusión avanzadas solo cuando la cámara esté fija. Cuando se instala en una grúa torre o en cualquier soporte inestable, la opción de mezcla deteriorará la calidad de la salida.',
      selectop2 :'Si una cámara está instalada en un soporte inestable como una grúa torre o un poste delgado, etc., use esta opción para estabilizar el video de lapso de tiempo inestable.',
      Adalertmessage : 'La fecha y hora de inicio y finalización no debe ser mayor que la fecha y hora de hoy',
      adrequired :'La fecha y hora de inicio y la fecha y hora de finalización son campos obligatorios.',
      adrequired2 : 'La fecha y hora de inicio debe ser anterior a la fecha y hora de finalización',
      adrequired3 : 'Seleccione velocidad y calidad,',
      adrequired4 : 'Seleccione la opción de personalización avanzada y sus subopciones respetadas.',
      adsuccess : 'Se está procesando el lapso de tiempo avanzado. Se le notificará a través del correo electrónico una vez que esté listo',
      addnotschedule :'Avance de lapso de tiempo no programado, inténtelo de nuevo.',
      upto8 : '1. El lapso de tiempo avanzado puede demorar hasta 8 horas. Se le notificará una vez que esté listo.<br>2. Para una personalización rápida, utilice la opción de lapso de tiempo personalizado único.',
      adarror : 'No se encontró ningún lapso de tiempo personalizado existente para esta cámara.',
      resetsetting: 'Su configuración se ha restablecido.',
      adnotcreate :'El lapso de tiempo avanzado no se reinicia, ¡inténtalo de nuevo!',
      info1 : 'Para ver videos de lapso de tiempo de múltiples cámaras en un video, use esta opción.',
      info2 :'Se pueden seleccionar un mínimo de 2 y un máximo de 4 cámaras para crear un video de lapso de tiempo del proyecto.',
      ptnotifiy :'El proyecto Time-lapse puede tardar hasta 12 horas en procesarse. Se le notificará una vez que esté listo.',
      proprocess :'Se está procesando el lapso de tiempo del proyecto. Se le notificará a través del correo electrónico una vez que esté listo',
      projectTiError :'El lapso de tiempo del proyecto no está programado, ¡inténtalo de nuevo!',
      alertmessageminmax :"Elige mínimo 2 y máximo 4 cámaras",
      projectready :'Project Time-lapse may take up to 12 hours to process. You will be notified once it is ready',
       reseterror:'Lapso de tiempo personalizado una vez no reiniciado, ¡inténtalo de nuevo!',
       Daily :'A diario',
       Weekly : 'Semanalmente',
       Monthly :'Mensual',
       info_rec : 'El sistema creará un video de lapso de tiempo diariamente según la configuración enviada.<br /> Ejemplo: una configuración de lapso de tiempo recurrente para "últimos 7 días" creará un video de lapso de tiempo para los últimos 7 días diariamente .',
       Infovidoss :'Cree un video de lapso de tiempo para la fecha de inicio/finalización personalizada, la hora de inicio/finalización, la velocidad y la calidad del video requeridas.',
      createTimelapseInfo : 'Lapso de tiempo del proyecto: para ver videos de lapso de tiempo de varias cámaras en un video, use esta opción. <br />Se pueden seleccionar un mínimo de 2 y un máximo de 4 cámaras para crear un video de lapso de tiempo del proyecto.',
      reccuringtimelapsecheck :'Por favor, marque el tipo de lapso de tiempo recurrente',
       alerrecurring : 'Por favor, marque el tipo de lapso de tiempo recurrente',
       recurringsave :'La configuración de lapso de tiempo recurrente se guardó correctamente',
       timelapsenotsave:'Lapso de tiempo no enviado, inténtalo de nuevo.',
       timelapsebingpressed :'Se está procesando un lapso de tiempo personalizado una vez. Se le notificará a través del correo electrónico una vez que esté listo',
       timelapsenotexit:'No se encontró ningún lapso de tiempo personalizado existente para esta cámara',
       custometimelapseresrt :'El lapso de tiempo personalizado no se restableció, intente nuevamente',
       recurringsettingsave :'Recurring Timelapse settings save successfully',
       onetimesetting:'La configuración única de Timelapse se guarda correctamente',
       ConnectwithProcore : 'Conéctese con Procore',
       UserName : 'Nombre de usuario',
       Module : 'Módulo',
       Page : 'Página',
       Date :'Fecha',
       UserType :'Tipo de usuario',
       selectRange:'Seleccione un rango',
       ManageUsers : 'Administrar usuarios',
       UserProfile : 'Perfil del usuario',
       emailhint : 'El correo electrónico no se puede actualizar',
        selectuserHint:'Seleccionar proyectos para el usuario',
        Active : 'Activo',
        conscamaccess:'Acceso a cámara de construcción',
        MaterialAccess : 'Acceso a materiales',
        InteriorviewAccess : 'Vista interior Acceso',
        InteriorEditAccess:'Acceso de edición interior',
        DroneEditAccess : 'Acceso de edición de drones',
        LinearEditAccess : 'Acceso de edición lineal',
        UserAccess : 'Acceso de usuario',
        PPEAIDataScheduleReport: "Informe de programación de datos de PPE AI",
        Reporttype : 'Tipo de informe',
        DaiDay:'El informe diario se enviará todos los días.',
        DaiDay1:'El informe semanal se enviará todos los lunes.',
        Dailyreport: 'Reporte diario',
        Weeklyreport : 'Reporte semanal',
        ppeReport : 'El estado del informe PPE está inactivo, para activarlo, haga clic en la casilla de verificación',
         ppeActive : 'Reporte de EPI activado',
         procoreconnection : 'Puede conectar su cuenta de Procore a OpticVyu. Esto le permitirá invitar fácilmente a los usuarios a unirse a Opticvyu y compartir imágenes en Procore.',
         opticvyuProject:'Proyectos OpticVyu',
         linkproject : 'Proyectos de Procore para vincular',
         selectprocoreproject : 'Seleccionar proyecto Procore',
         configprocore :'Configurar proyectos con Procore',
         procoreConnectionSuccess : 'Proyecto conectado a Procore exitoso.',
         requestembadedcode : 'Solicitar código de aplicación integrado',
         SendImagery : 'Enviar imágenes',
         procoreissue : "¿Problema de conexión de Procore?",
         procorealreadyconnected  : "Este proyecto de proceso ya está conectado a OpticVyu Project, vaya a su proyecto Procore y cambie el nombre o elimine los álbumes actuales y vuelva a intentarlo.",
          whichpro : '¿Qué proyectos desea mostrar en la aplicación integrada?',
          procoreconfirm  : '¿Estás seguro de que conectas este proyecto con Opticvyu?',
          procoreDisconnect: 'Proyecto desconectado con éxito para sedingin imágenes',
          procorediconfigure : '¿Quieres desconfigurar todos los proyectos conectados con Procore?',
          deconfigure : 'Desconfigurar correctamente',
          embadedRequest : 'La solicitud de código de la aplicación integrada se registró correctamente. Recibirás el código por correo electrónico en un plazo de 2 días laborables.',
          procoremsg :'Ha autorizado a Opticvyu a comunicarse con Procore. Puede deshacer esto y desautorizar a OpticVyu de Procore haciendo clic en',
          here : "aquí",
          proopticvy : 'Para obtener más información sobre OpticVyu - Integración de Procore',
          EmailSchedule:'Calendario de correo electrónico',
          Selectdays:'Seleccionar días',
          Selecttime:'Seleccionar hora (IST)*',
          resetsuccessfull : 'Restablecer con éxito',
          twofactoreAuthentication : 'Se enviará un código de autenticación a su identificación de correo electrónico mientras intenta iniciar sesión',
          ClicktoInActive :'Haga clic para inactivo',
          ClicktoActive :'Haga clic para activo',
          Device : 'Dispositivo',
          Emailstatement : 'Estado de cuenta por correo electrónico',
          Reload : 'Recargar',
          Statementsentsuccessfully : 'Declaración enviada con éxito',
          InvoiceDate : 'Fecha de la factura',
          Invoice : 'Invoice',
          WorkOrder : 'Orden de trabajo',
          Amount : 'Monto',
          BalanceDue : 'Saldo adeudado',
          DueDate : 'Fecha de vencimiento',
          OverdueBy : 'Vencido por', 
          DefaultTimelapse : 'Lapso de tiempo predeterminado',
          CustomTimelapse :'Lapso de tiempo personalizado',
          ArchiveTimelapse : 'Lapso de tiempo de archivo',
         
          Advance : 'Avance',
          Project : 'Proyecto',
          Type : 'Escribe',
          Advanceediting :'Edición avanzada',
          Deflicker : 'antiparpadeo',
          Copyurl :'Copiar URL',
          MoveTimelapseToArchive:'Mover lapso de tiempo al archivo',
          Addcaptionforarchivetimelapse:'Agregar título para el lapso de tiempo de archivo',

          Graphs :'gráficos',
          max7 : 'máx. Se pueden seleccionar 7 días',
          last7days:'Nota: No hay datos disponibles en los últimos 7 días. Seleccione un intervalo de fechas personalizado para ver los datos con fechas pasadas.',
          
          clicktogetrelated : 'Haga clic en la barra del gráfico para obtener imágenes relacionadas.',
          PersonsWithRequiredEquipment :'Personas con equipo requerido',
          PersonsWithoutRequiredEquipment : 'Personas sin equipo requerido',
          PersonUnrecognizable :'Persona Irreconocible',
          PersonsIdentified : 'Persons Identified',
          Remark : 'Observación',
          PossibleHealthImpacts :'Posibles impactos en la salud',
          ColorCode : 'Codigo de color',
          poor :"Pobre",
          VeryPoor :"Muy pobre",
          Good : 'Bueno',
          Satisfactory : 'Satisfactorio',
          Moderate : 'Moderado',
          Severe  :'Severo',

          Minimalimpact :'Impacto mínimo',
          Minorbreathing:'Molestias respiratorias menores para personas sensibles.',
          breathing1:'Molestias respiratorias para la mayoría de las personas en exposición prolongada',
          breathing2 : 'Molestias respiratorias para la mayoría de las personas en exposición prolongada',
          breathing3 : 'Respiratory illness on prolonged exposure',
          breathing4 :'Affects healthy people and seriously impacts those with existing diseases',
          AddedOn : 'Añadido',
          AdvanceCustomization : 'Personalización avanzada',
          Speed : 'Velocidad',
          FrameBlending : 'Fusión de fotogramas',
          TimelapseSmoothing : 'Fusión de fotogramas',
          notification :'No se encontró ningún lapso de tiempo de archivo. Cree lapsos de tiempo personalizados y agregue videos importantes para archivar para futuras referencias',
          notificationtitle : 'No se encontró ningún lapso de tiempo de archivo. Cree lapsos de tiempo personalizados y agregue videos importantes para archivar para futuras referencias',
          archiveDelete: 'Archivo Timelapse eliminado con éxito.',
          archiveError : 'Archivo-Timelapse no eliminado. Inténtalo de nuevo.',
          copymsg : '¡Copiar URL! Enlace válido solo por 7 días',
          timelapsesharesuccess:'Timelapse compartido con éxito. Por favor revise su correo',
          Quality : 'Calidad',
          Delete : 'Borrar',
          Share : 'Cuota',
          Editor :'Editor',
          SavetoCart : 'Guardar en el carrito',
          CartImages : 'Imágenes del carrito',
          Deleteallcartimages : 'Eliminar todas las imágenes del carrito',
          selectImagescard :'Seleccione una imagen del editor para agregar al carrito',
          Info :"Info",
          GenerateReport : 'Generar informe',
          PreviousReports :'Informes anteriores',
          CartImageInfo : "Información de la imagen del carrito",
          Addedby :'Añadido por',
          Addedat : 'Added at',
          SwitchMode : 'Modo interruptor',
          CheckDifference : 'Comprobar diferencia',
          OnetimeRecurring :'Única/Recurrente',
          ShowAll : 'Mostrar todo',
          Favourites : 'Favoritos',
          ImageCapturedTime: 'Tiempo de captura de la imagen',
          ImageAddedTime : 'Tiempo añadido de la imagen',
          favouriteremove : 'Imagen eliminada con éxito de favoritos.',
          favoriterror :'Obteniendo error al eliminar la imagen de favoritos',   
          ReportDemo : 'Reportar demostración',
          ScheduleReport : 'Informe de programación',
          GeneratePdfReport : 'Informe de programación',
          MailPdfReport : 'Enviar Informe PDF',

          emailsuccessMsg : 'Informe compartido con éxito. Por favor revise su correo',
          emailFailsMSG: 'No se pudo compartir el informe. ¡Vuelve a cargar y vuelve a intentarlo!',
          SelectDate :'Seleccione fecha',
          Allprojects :'Todos los proyectos',
          Runningprojects : 'Ejecutando proyectos',
          Completedprojects : 'Proyectos completados',
          Albums : 'Álbumes',
          ImagesVideos :'Imágenes/Videos',
         mobilessupload:'Descargue la aplicación OpticVyu de playstore y cargue fotos de proyectos capturadas a través de dispositivos móviles',
         nodirectry : 'No se agregó ningún directorio en este proyecto',
         noImages : 'Ninguna imagen o video cargado en este directorio',
         nodaavailble :'Nota: No hay datos disponibles en esta fecha. Consulte los datos anteriores.',
         Smoothing : 'alisado',
         archivetimelapsedone:'Timelapse movido con éxito al archivo timelapse',

         biweekly : 'Los informes quincenales se enviarán los días 1 y 16 del mes.',
         monthly : "El informe mensual se enviará el primer día del mes.",
         Weeklyprogressreport :'Informe de progreso semanal',
         Biweeklyprogressreport :'Informe de progreso quincenal',
         Monthlyprogressreport : 'No se encontró ningún informe de cronograma activo existente para este proyecto.',
          nosechedulereport: "sin reporte de horario",
          checkreport :'Verifique el tipo de informe',
          EnterEmail: 'Por favor, introduzca ID de correo electrónico',
          selectCamera : 'Seleccione la cámara',

          settingsave :'La configuración del informe de programación se guardó correctamente',
           schedulenotsubmit: 'Programación-Informe no enviado, por favor inténtalo de nuevo',
           reportReset : 'Restablecimiento de programa-informe con éxito',
         reportnotseset : 'Informe de programación no reiniciado, inténtelo de nuevo',
        copysuccess : 'Copiado correctamente para archivar lapso de tiempo',

        //  Interior 

        AddCoordinate : 'Agregar coordenada',
        WorkStatus : 'Situación laboral',
        VisitingDate : 'Fecha de visita',
        Category : 'Categoría',
        Gallery : 'Galería',
        UploadFiles:'Subir archivos',
        NormalImage : 'Imagen normal',
        Image : 'Imagen',
        Video : 'Video',
        RemoveSelected : 'Eliminar selección',
        click360video : 'Haga clic para cargar videos 360',
        UpdateCoordinate : 'Actualizar coordenada',
        cordinatConfirm : '¿Estás seguro de añadir estas coordenadas?',
        removeCoordinate: '¿Estás seguro de eliminar esta coordenada? en caso afirmativo, todas las imágenes y videos cargados se eliminarán permanentemente de esta coordenada.',
        cordianteUpdate :'Actualización de coordenadas con éxito.',
        videoupload : 'Video Uploaded Successfully',
        deleteConfirm : 'Confirmar eliminar este(s) archivo(s)',
        deletefilesuccess : 'Archivos seleccionados eliminados con éxito',
        Edit : 'Editar',
        UploadDrawing : 'Subir dibujo',
        AddZone : 'Agregar zona',
        DrawingName : 'Nombre del dibujo',
        Floor : 'Piso',
        Area: 'Área',
        zoneName : 'Nombre de zona',
        NewZone : 'Nueva Zona',
        drawingupload : 'Carga de dibujo exitosa',
        drawingconfirm : '¿Confirmar eliminar este dibujo?',
        drawingremove : 'Eliminación de dibujo exitosa',
        addezone : 'Nueva zona añadida con éxito',
        Images360 : 'Imagen 360', 
        NormalImages : 'Imágenes normales',
         videos360 : 'Vídeos 360',
         Comments : 'Comments',
         Task : 'Tarea',
         AddComment : 'Add Comment',
         Lock : 'Cerrar con llave',
         ImportTasks : 'Importar tareas',
         StandardTaskLists:'Listas de tareas estándar',
         FilterByDate : 'Filtrar por fecha',
         AddNewComment : 'Agregar nuevo comentario',
         Comment : 'Comentario',
         Progress : 'Progreso %',
         Tag : 'Etiqueta',
         UpdateTask : 'Actualizar tarea',
         DownloadExcel : 'Descargar excel',
         OR : 'O',
         DownloadPDF : 'Descargar PDF',
         start : 'Comienzo',
         end : 'Fin',
         addzone :'Nueva zona añadida con éxito.',
         addcommnetssucess :'Comentario añadido con éxito',
         listconfirmation : '¿Estás seguro de importar la lista de tareas?',
         standdrlistloaded: 'Lista de tareas estándar cargada',
         newmsg :'Nueva tarea agregada con éxito.',
         tasksuccess : 'Actualización de tareas con éxito',
         sureremove : '¿Estás seguro de eliminar esta tarea?',
         taskDelete : 'Tarea eliminada con éxito',
         removetask :"¿Estás seguro de eliminar las tareas seleccionadas?",
         PlannedEnd : ' Fin planeado',
         PlannedStart : 'Inicio planificado',
         DroneImages : 'Imágenes de drones',
         DroneVideo : 'Vídeo de dron',
         UploadMedia : 'Subir medios',
         VisitDate : 'Fecha de visita',
         AddNewVisitingDate : 'Agregar nueva fecha de visita',
         Upload : 'Subir',
         OpenGallery : 'Open Gallery',
         srtfile:'Upload srt File',
         AddVideoInfo : 'Agregar información de video',
         MetaList : 'Meta lista',
         latlongnotavailble :'Imagen Latitud y Longitud no disponible',
         imagesnotavailble : 'Imágenes no disponibles',
         recordadded : 'Registro añadido con éxito',
         visitdateadded :'Fecha de visita agregada con éxito',
         selecteddelte :'Archivos seleccionados eliminados con éxito',
         taskconfirmation : '¿Estás seguro de eliminar esta información?',
          recordDeleted : 'Registro eliminado con éxito',
          BulkUpload : 'Carga masiva',
          DroneVideos : 'Vídeos de drones',
          ReviewDate :'Fecha de revisión',
          ReviewImages : 'Revisar imágenes',
           reviewImagesandSubmit : 'Imágenes cargadas. Haga clic en `Revisar imágenes` para enviar',
           Delayed : 'Demorado',
           Critical : 'Crítico',
           Complete : 'Completo',
           Running : 'Corriendo',
           LayoutInsights : 'Perspectivas de diseño',
           SelectDrawing : 'Seleccionar dibujo',
           AddNewMaterialHeading : 'Agregar nuevo encabezado de material',
           AddNewSubHeading : 'Agregar nuevo subtítulo',
           AddNewMaterial: 'Agregar nuevo material',
           MaterialName : 'Nombre del material',
           Unit : 'Unidad',
           AssetType : 'Tipo de activo',
           Estimateqty : 'Cantidad estimada',
           MaterialHeading :'Encabezado de materiales',
           Enable : 'Permitir',
           MaterialSubHeading : 'Subtítulo de material',
           integetrequre :'Este campo solo permite números enteros',
           HeadingUpdateSuccessfully:'Actualización de rumbo con éxito',
           confirmdelteheading:'¿Confirmar eliminar este título?',
           HeadingdeletedSuccessfully: 'Encabezado eliminado con éxito',
           HeadingAddedSuccessfully  :'Encabezado agregado con éxito', 
           SubHeadingUpdateSuccessfully: 'Actualización del subtítulo con éxito',
           SubheadingdeleteSuccessfully:'Eliminación de subtítulos con éxito',
           SubheadingAddedSuccessfully:'Subtítulo agregado con éxito',
           MaterialUpdateSuccessfully : 'Actualización de material con éxito',
           MaterialDeleteSuccessfully: 'Eliminación de material con éxito',
           MaterialAddedSuccessfully : 'Material Added Successfully',
           Yourtaskuploadedsuccesfully: 'Tu tarea cargada con éxito',
           DraftsInventories : 'Inventarios de giros',
           Confirmremovethisitem:'¿Confirmar eliminar este elemento?',
           EnteryDeletedSuccessfully : 'Entrada eliminada con éxito',
           goback: 'Regresa', 
           Discription : 'Descripción',
           GoToInventory : 'Ir al inventario',
           UpdateInventory : 'Actualizar inventario',
           DCNo : 'No. de CC / Deslizamiento de peso',
           PONo : 'Número de orden de compra',
           VehicleNo : 'Vehicle No',
           SupplierName : 'Nombre del proveedor',
           Rate : 'Calificar',
           Quantity : 'Cantidad',
           Update : 'Actualizar',
           NewInventory : 'Nuevo inventario',
           InventoryUpdateSuccessfully : 'Actualización de inventario con éxito',
           InventoryDeletedSuccessfully : 'Inventario eliminado con éxito',
           InventoryAddedSuccessfully : 'Inventario agregado con éxito',
           mobileapp : 'Cargue las imágenes desde la aplicación móvil para enviar inventarios.',
           InventorySubmitSuccessfull : 'Envío de inventario exitoso',
           EditRequestNote :'Editar nota de solicitud',
           EditRequest : 'Editar solicitud',
           InventoryEditRequest:'Solicitud de edición de inventario',
           Commentsforinventoryupdate:'Comentarios para actualización de inventario',
           RequestSuperadmin:'Solicitar superadministrador',
         
           OverallReport:'Informe general',
           CumulativeReport : 'Informe acumulativo',
           MaterialwiseReport : 'Informe de materiales',
           Requestsuccessfullysent:'Solicitud enviada con éxito',
           Images : 'Imágenes',
           VehicleEntry : 'Entrada de vehículos',
           offtheproject:'fuera del sitio del proyecto',
          
           VehicleNumberPlate : 'Matrícula del vehículo',
           deliverychalaimage : 'Chalán de entrega y boleta de pesas',
           vehivleunloading:'Descarga de vehículos',
           VehicleEmpty : 'Vehículo vacío',
           other : 'Otro',
           reflected : 'Si los datos de la tabla no se reflejan, intente volver a cargar o actualizar la página',
           ImportSuppliers : 'Importar Proveedores',
           SupplieraddedSuccessfull : 'Proveedor añadido con éxito',
           Suppliersfileimportsuccessfully : 'Importación de archivos de proveedores con éxito',
           deleteconfirm:'Confirmar Eliminar Proveedor',
            removesucces:'Proveedor Eliminar con éxito',
            clear : 'clear',
            Profile : 'Información del proyecto',
            SaveandSend : 'Guardar y enviar',
            CRM:'CRM',
            DailyReport:'Reporte diario',
            EveryMonthprogressreport:'reporte de progreso mensual',
            BiMonthlyprogressreport:'Informe de progreso bimestral',
            Quarterlyprogressreport:'informe de progreso trimestral',
            HalfYearlyprogressreport:'informe de progreso semestral',
      }
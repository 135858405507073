<template>
<div>
    <Preloader v-if="preloader" />
        <h3 class="center pagetitle">  {{lang.DraftsInventories}} </h3>
        <v-data-table :headers="headers" :search="search" :items="taskdata" :items-per-page="10" :footer-props="footerpro" class="elevation-1 nowwfooter">
          
                      <template v-slot:item.entry_name="{ item }">
                                   <a @click="gotoInventory(item.id)"> {{ item.entry_name }}</a>
                      </template>  
                      <template v-slot:item.supplier_name="{ item }">
                                   <a @click="gotoInventory(item.id)"> {{ item.supplier_name }}</a>
                      </template>  
                      <template v-slot:item.create_at="{ item }">
                                   <a @click="gotoInventory(item.id)"> <p>{{ dateFormate(item.create_at) }}</p></a>
                      </template>  
                      <template v-slot:item.entry_name="{ item }">
                                   <a @click="gotoInventory(item.id)"> {{ item.entry_name }}</a>
                      </template>  
                      <template v-slot:item.UserName="{ item }">
                                   <a @click="gotoInventory(item.id)"> {{ item.UserName }}</a>
                      </template>  
          <template v-slot:item.actions="{ item }">

                           <v-btn
                            
                            fab
                            dark
                            small
                            color="black"
                            icon
                            @click="gotoInventory(item.id)"
                            >
                            <v-icon dark>
                            mdi-arrow-right-circle
                            </v-icon>
                            </v-btn>

                            <v-btn
                            
                            fab
                            dark
                            small
                            color="black"
                            icon
                            @click="removeInventory(item.id)"
                            >
                            <v-icon dark>
                             mdi-delete
                            </v-icon>
                            </v-btn>
                      </template>      
        </v-data-table>

        <v-snackbar v-model="alert" top right timeout="3000">
        
          {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lanf.Close}}
            </v-btn>
        </template>
    </v-snackbar>
    <!-- <v-snackbar v-model="alert" top right timeout="3000" :type="alertType" :color="alertType=='success'? '#ffc400':''">
        
          <span :color="alertType=='success'? '#000':'red'">{{ alertmessage }}</span>

        <template v-slot:action="{ attrs }">
            <v-btn :color="alertType=='success'? '#000':'red'" text v-bind="attrs" @click="alert = false">
                Close
            </v-btn>
        </template>
    </v-snackbar> -->
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import { locals } from "../../utils/locals";
export default {
    components: {
        Preloader,
    },

    computed: {
       
    },
    name: "Draft",
    data() {
        return {
            alert: false,
            
            alertmessage: '',
            alertType: "error",
            preloader: false,
            project_name: 'ProjectName',
            projectid: 0,
            search: '',
             headers: [{
                    text: 'Entry',
                    align: 'start',
                    value: 'entry_name',
                  
                },
                {
                    text: 'Supplier Name',
                    value: 'supplier_name',
                   
                },
                {
                    text: 'Date',
                    value: 'create_at',
                    
                },
                {
                    text: 'User',
                    value: 'UserName',
                    
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    
                }
            ],
            taskdata: [],
             Local :locals,  
        lang : {},

        };
    },
    created() {

         var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng]; 

        this.userdata = JSON.parse(localStorage.getItem('userdata'));

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;
        this.project_name = currentProject.project_name;
        this.initViewer();
        if(currentProject.material == 1){
          this.user_material_access = true 
        }else{
                 this.$router.push('/')
        }
        if (this.userdata.material_access == 1) {
            this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
         if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.project_name = currentProject.project_name;
            this.getTaskData();
        }
    },
    // mounted() {
    //     this.userdata = JSON.parse(localStorage.getItem('userdata'));
    //     if (this.userdata.open_access == 1) {
    //         this.$router.push('/dashboard')
    //     }
    // },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = true;
            this.getTaskData();
        },

        getTaskData() {
            this.taskdata = [];
           
            this.preloader = true;
            // var projectid = '372';
            var filter = '{"where": {"and": [{ "projectid": '+this.projectid+'},{"enterystatus": "Draft"}]},"order": "id DESC"}' ;  
             var urlfilter = encodeURI(filter);
            axios({
                    url: '/material/get-entery',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = JSON.parse(resp.data);
                    
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        gotoInventory(id){
               this.$router.push({
                name: 'material_draftinventory',
                params: {
                    id: id
                }
            });
        },
         removeInventory(id){
               console.log(id);
                 
            var conf = confirm(this.lang.Confirmremovethisitem);
                   
                    if(conf){
                           axios({
                    url: '/material/entery/remove',
                    method: 'get',
                    params: {
                        id: id
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = resp.data;
                    this.getTaskData();
                     this.alert = true;
                    this.alertmessage = this.lang.EnteryDeletedSuccessfully;
                    this.alertType = "success"
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;;
                    this.alertType = "error"
                })  
                    }
        },
         dateFormate(d) {
            var n = new Date(d).toLocaleString('en-GB', {
                timeZone: 'Asia/Kolkata'
            });
            return n;
        },
        
    }
};
</script>

<style>
.v-application a {
    color: #262c31 !important;
}
.pagetitle{
    margin: 10px 0 10px 0;
}

.nowwfooter  .v-data-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    background: #fff;
    left: 0;
}
</style>

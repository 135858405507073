<template>
  <section class="section-container">

    <v-row class="signin">
      <Preloader v-if="Preloader" />
      <!-- <v-col cols="9" class="left backgroundimage">
       
      </v-col> -->
      <v-col cols="12" class="right backgroundimage">
        <div  
        v-if="resetform"
        style="max-width: 100%;
        width: 450px;
        margin: auto;
        background: #000000a3;
        padding: 23px;
        border-radius: 8px;
        box-shadow: rgb(120 120 120) 0px 4px 20px 1px;">

     <img src="../assets/OpticVyu_logo_HD.png" style="max-width: 188px;
    margin: auto;
    display: block;"> 

        <h2>Reset Password </h2>
      <validation-observer ref="observer">
          <v-form  ref="form"
           v-model="valid"
          lazy-validation @submit.prevent="submit">
           
           <validation-provider v-slot="{ errors }" name="email" rules="required|email">
              <v-text-field
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
           </validation-provider>

              <v-text-field
                v-model="new_password"
                
                label="Enter New Password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                ref="newpassword"
                :type="showPass ? 'text' : 'password'"
                :rules="newpasswordrules"
              ></v-text-field>
            
            <div class="text-center">

             
              <v-text-field
                v-model="password_confirmation"
                
                label="Confirm New Password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                 data-vv-as="newpassword"
                :type="showPass ? 'text' : 'password'"
                :rules="[(new_password === password_confirmation) || 'Password must match']"
              ></v-text-field>
           

              <v-btn class="signin-btn singbuttons" :disabled="!valid" type="submit" rounded>
                Submit
              </v-btn>
                <div class="mt-5">
              <h2 class="hrh2"><span>Token expired ?</span></h2></div>
              <div class="mt-5" style="cursor: pointer;">
                  <v-btn class="ma-2" outlined color="#ffff" @click="checkgorgorpassword">Get reset-password link again</v-btn>
                </div>
            </div>
             
          </v-form>
          </validation-observer>
                 
            
       
        <div class="text-center">
               <v-alert
      dense
      outlined
      :type="type"
      class="mt-5"
      v-if="error"
    >
     {{errorMessage}}
    </v-alert>

    
     </div>
     </div>
     <div
          v-if="!resetform"
          style="
            max-width: 100%;
            width: 450px;
            margin: auto;
            background: #000000a3;
            padding: 23px;
            border-radius: 8px;
            box-shadow: rgb(120 120 120) 0px 4px 20px 1px;
            position: relative;
            top: 8%;
          "
        >
          <img
            src="../assets/OpticVyu_logo_HD.png"
            style="max-width: 188px; margin: auto; display: block"
          />
          <h2>Reset Password</h2>
          <p class="">Enter your registered email address to reset the password</p>
          <validation-observer ref="observer">
            <v-form @submit.prevent="forgotPassword">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <v-text-field
                  v-model="remail"
                  :error-messages="errors"
                  label="Email"
                  required
                  outlined
                  dark
                  filled
                  rounded
                  dense
                  clearable
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </validation-provider>

              <div class="text-center">
                <v-btn class="signin-btn singbuttons" type="submit" rounded>
                  Send
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
          <div class="text-center">
            <div class="mt-5" style="cursor: pointer;">
              <p @click="loginpage()">Goto login page</p>
            </div>

            <v-alert dense outlined :type="type" :class="classs" v-if="error">
              {{ errorMessage }}
            </v-alert>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required, email } from 'vee-validate/dist/rules';
import { extend, ValidationProvider, setInteractionMode, ValidationObserver} from 'vee-validate';
import axios from 'axios';
import Preloader from "../components/PreLoader";
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  components: {
    Preloader,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    Preloader: false,
    valid: true,
    email: '',
    new_password: '',
    showPass: false,
    error : false,
    errorMessage : '',
    type : '',
    loginerror: true,
    resetform:true,
    password_confirmation:'',
    newpasswordrules: [
        v => !!v || 'This Field is required',
        v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) || 'Please enter at least one Capital, Small, Numeric and Special character',
      ],
    confirmpasswordrules: [
        v => !!v || 'This Field is required',
        v => v == this.new_password  || 'Password should be same',
      ],
    remail:'',  
  }),
  computed: {
    params() {
      return {
        token : this.token,
        email: this.email,
        password: this.new_password,
        password_confirmation: this.password_confirmation,
        
      }
    }
  },
   mounted() {
var url_string = window.location.href
var url = new URL(url_string);
  this.token = url.searchParams.get("token");
   this.email= url.searchParams.get("email");

    console.log(this.params);
  },
  methods: {
    validate () {
        this.$refs.form.validate()
      },
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
         //console.log("Hello i am here.");
        this.login(this.params) // action to login
      }
    },
  
   
    login(data) {
       this.Preloader = true,
         axios({ url: '/password/reset', data : data, method: 'POST' })
.then(response => {
    console.log(response, "response Link send Sccessful");
     var v = this;
      if(response.data.success){
         this.error = true;
         this.type = 'success';
           this.errorMessage = 'Password Reset Successful.';
           
            
              setTimeout(function () {
                  v.$router.push('/login')
              }, 3000);
      }else{
        this.error = true;
         this.type = 'error';
         this.errorMessage = response.data.error;
        
         setTimeout(function () {
                  v.$router.push('/login')
              }, 3000);
      }
       this.Preloader = false;
      }) 
       .catch(err => {
         console.log(err)
         //  var v = this;
         console.log("catch error", err.response.data.error.message);
         this.error = true;
         this.errorMessage = err.response.data.error.message+ ' Please reset the password again.';
         this.type = 'error';
        this.Preloader = false;
        //  setTimeout(function () {
        //           v.$router.push('/login')
        //       }, 3000);
         })
      },
    checkgorgorpassword() {
      this.resetform = !this.resetform;
    },
    loginpage() {
      window.location.href = "/login"
    },
    async forgotPassword() {
      this.Preloader= true;
      console.log(this.remail);

      var email = {
        email: this.remail,
      };
      axios({ url: "/password/email", data: email, method: "POST" })
        .then((response) => {
          console.log(response, "response Link send Sccessful");
          if (response.data.success) {
            this.error = true;
            this.type = "success";
            this.errorMessage = "Check your email inbox for instructions on resetting your password. Note: make sure to check your spam folder.";
            this.loginerror = !this.loginerror;
            this.class= "mt-5 multi-line";
          } else {
            this.error = true;
            this.type = "error";
            this.errorMessage = "The email address you entered is not registered with OpticVyu. Please contact admin@opticvyu.com.";
            this.classs= "mt-5";
          }
          this.Preloader= false;
        })
        .catch((err) => {
          
          console.log(err);
          this.error = true;
          this.errorMessage = "The email address you entered is not registered with OpticVyu. Please contact admin@opticvyu.com for any query related to account creation";
          this.type = "error";
          this.classs= "mt-5";
          this.Preloader= false;
        });
    },
    }
  } 
</script>
<style>
  .hrh2 { width:100%; text-align:center; border-bottom: 1px solid #ffff; line-height:0.1em; margin:10px 0 20px; } 
  .hrh2 span { background:rgb(21 20 19); color:#ffff; padding:-1px 10px;; font-size:14px }
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" out-line>
        <v-snackbar v-model="snackbar" right top timeout="3000" type="success">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              {{ lang.Ok }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-card class="pa-4">
          <!-- <div cols="6" style="position: absolute; left: 24%; top: 8px;">
          
            </div> -->
          <v-dialog v-model="dialog" scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div cols="6" class="ButtonBox">
                <v-btn
                  class="ma-2"
                  color="#f5cd49 "
                  v-bind="attrs"
                  v-on="on"
                  label="Select Image"
                >
                  <!-- {{ d1 }} -->
                  Select Image
                  <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="#f6cc49"
                  @click="open_note_dialog"
                  title="Add notes to pdf"
                >
                  {{ lang.ViewAddNote }}
                  <v-icon right v-bind="attrs">mdi-pen</v-icon>
                </v-btn>
                <span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <span
                      v-bind="attrs"
                       v-on="on"
                       style="font-size: larger;" >{{currentcam_name}} </span> -->
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span
                      >Switch camera from camera dropdown on top. You can add
                      images from multiple cameras for creating overall progress
                      report.</span
                    >
                  </v-tooltip>
                </span>
              </div>
            </template>
            <v-card>
              <v-date-picker
                :color="activecolor"
                @change="getSelectedDate($event)"
                v-model="picker"
                :max="new Date().toISOString().substr(0, 10)"
                :allowed-dates="dateActiver ? allowedDates : alldates"
                :picker-date.sync="pickerDate"
              ></v-date-picker>
              <v-divider></v-divider>
              <div class="timeList">
                <v-list mandatory rounded max-height="341px">
                  <v-list-item-group>
                    <v-dialog v-model="timeslotloader" persistent width="300">
                      <v-card :color="activecolor" dark>
                        <v-card-text>
                          {{ lang.Pleasestandby }}
                          <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-list-item
                      v-for="(item, i) in timeslots"
                      :key="i"
                      @change="selectTimeSlot(item.image_id)"
                      class="itemBottomMargin"
                    >
                      <v-list-item-content
                        active-class="amber accent-3-darken-3--text text--accent-4"
                      >
                        <v-chip>{{ item.updated_time }}</v-chip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
              <v-card-actions>
                <v-btn color="green darken-1" text @click="dialog = false">
                  {{ lang.Close }}
                </v-btn>
                <v-btn color="green darken-1" text @click="dialog = false">
                  {{ lang.Done }}
                </v-btn>
                <v-snackbar
                  v-model="snackbarDatesWithImages"
                  :multi-line="multiLine"
                  top
                  right
                  timeout="3000"
                  type="success"
                >
                  {{ archiveCalenderyearMonth }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      {{ lang.Close }}
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2 dateWithImageButton"
                      color="amber"
                      @click="datesWithImage()"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Capturing Insights</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
      <Preloader v-if="preloader" />
      <v-card height="70vh" class="pa-4 dir_scroll cartSize" :key="refreshCart">
        <v-card>
          <div
            v-ripple="{ center: true }"
            class="elevation-1 pa-0 headline"
            style="padding-left: 1% !important; font-size: 16px !important;"
          >
            <!-- {{lang.CartImages}} {{imgcount}}/15  -->
            <v-btn
              icon
              color="rgb(241 51 8)"
              @click="delete_all"
              :title="lang.Deleteallcartimages"
              style="position: relative;"
              v-bind:disabled="this.checkselected.length < 1"
            >
              <v-icon
                v-bind="attrs"
                style="font-size: 2rem;"
                v-if="this.checkselected.length > 0"
                >mdi-delete</v-icon
              >
            </v-btn>
          </div>
        </v-card>
        <v-row>
          <v-col
            cols="12"
            md="12"
            style="text-align:center"
            v-if="!showcartdata"
          >
            <v-col cols="12" md="12">
              <v-card-text>
                <div class="text-h3 pa-12">{{ lang.selectImagescard }}</div>
              </v-card-text>
            </v-col>
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="showcartdata"
            style="margin-top: 10px;"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
                xs="12"
                v-for="cart in cartdata"
                :key="cart.id"
              >
                <!-- {{ cartdata }} -->
                <input
                  type="checkbox"
                  name="imgcheck"
                  v-model="checkselected"
                  class="checkboxPosition"
                  :value="cart.img_id"
                />
                <v-img
                  :src="cart.img_url"
                  @click="openimage(cart.img_url)"
                  style="object-fit: contain; width: 100%; height: 100%; cursor: pointer;"
                >
                  <v-spacer></v-spacer>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2 cartImageInfo"
                        right
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-format-list-bulleted-square</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="OpenInfo(cart)">
                        <v-list-item-action>
                          {{ lang.Info }}</v-list-item-action
                        >
                      </v-list-item>
                      <v-list-item v-on:click="delete_cart_image(cart.id)">
                        <v-list-item-action>{{
                          lang.Delete
                        }}</v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-row class="reportButtons">
          <v-btn small class="ma-2" color="success" @click="opendialogbox()">
            {{ lang.GenerateReport }}
          </v-btn>
          <v-btn
            small
            class="ma-2"
            color="success"
            @click="open_and_show_pdf()"
          >
            {{ lang.PreviousReports }}
          </v-btn>
        </v-row>
      </v-card>
      <div class="imageeditor active" v-if="showannotation">
        <v-btn
          fab
          dark
          small
          icon
          @click="showannotationclose"
          class="mx-2 oveicon clsoe"
          color="amber accent-3"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <v-btn
          color="secondary"
          @click="SaveandSend()"
          class="oveicon saveShiftLeft save ma-2"
        >
          {{ lang.Done }}</v-btn
        > -->
        <v-btn
          class="oveicon save ma-2"
          color="amber accent-3"
          @click="SaveandSend()"
        >
          {{ lang.SavetoCart }}
          <v-icon right v-bind="attrs">mdi-content-save</v-icon>
        </v-btn>
        <tui-image-editor
          ref="tuiImageEditor"
          :include-ui="useDefaultUI"
          :options="options"
          :key="tui_re_render"
          v-if="showannotation"
        ></tui-image-editor>
      </div>
      <v-icon v-if="imgdialog" class="closebuttons" @click="closetoggle"
        >mdi-window-close</v-icon
      >
    </v-row>
    <v-dialog v-model="info_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ lang.CartImageInfo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="username">
                      <p class="p_username">
                        {{ lang.Addedby }} : {{ addedby }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <p class="update_date">
                        {{ lang.Addedat }} : {{ addedon }}
                      </p>
                    </v-col>
                  </v-row>
                  <br />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="info_dialog = false">
            {{ lang.Close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="800"
      :filename="pdfname"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1130px"
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div id="pdf_template">
          <!-- <div class="pdflogo_b">
            <p class="brspace" style="color: black;text-align: left;">
              Pdf Generated on: {{ todaydate }} <br />
              Generated By: {{ userdata.email }}
            </p> 
             <p class="web_address">
              <a
                href="https://www.opticvyu.com/"
                style="color:black;text-decoration: none; "
                ><b>OpticVyu Construction Camera</b></a
              ><br /><a href="mailto:admin@opticvyu.com">admin@opticvyu.com</a>
            </p>
          </div> -->
          <div class="pdflogo">
            <p style="color:white;">
              <font size="4" color="white"
                >{{ project_name }} - {{ org }} - {{ city }}</font
              >
            </p>
            <p
              style="color: white; margin-bottom: 49%;position: absolute;left: 1px;margin-top: 1%;"
            >
              Pdf Generated on: {{ todaydate }} | Generated By:
              {{ userdata.email }}
            </p>
            <!-- <p
              style="color: white; margin-bottom: 49%;position: absolute;left: 1%;margin-top: 3%;"
            >
              {{ city }}
            </p> -->

            <p class="web_address">
              <img v-if="base64_url" :src="base64_url" />
              <img v-else :src="logo" />
            </p>
          </div>
          <v-row style="padding: 2%;" v-if="show_noteblock">
            <v-col cols="12" md="12">
              <p style="text-align: center;"><b>Notes and Details</b></p>
              <v-list-item v-for="u in view_reportnote" :key="u.id">
                <v-list-item-icon>
                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>{{ u.note }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <div class="html2pdf__page-break" v-if="show_noteblock">
            <p style="text-align: center;"><b>Images on the next page</b></p>
          </div>
          <v-row style="padding: 2%;">
            <v-col
              cols="6"
              md="6"
              v-for="(cart, index) in cartdata"
              :key="cart.id"
            >
              <v-img
                class="imgmax_height"
                :src="cart.img_url"
                style="width: 500px; cursor: pointer;"
                max-height="250"
              >
              </v-img>
              <div class="html2pdf__page-break" v-if="(index + 1) % 4 == 0">
                <!-- _ -->
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="800"
      :filename="pdfname"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1130px"
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
      @beforeDownload="beforeDownload_blob($event)"
      ref="html2Pdfblob"
    >
      <section slot="pdf-content">
        <div id="pdf_template">
          <!-- <div class="pdflogo_b">
            <p class="brspace" style="color: black;text-align: left;">
              Pdf Generated on: {{ todaydate }} <br />
              Generated By: {{ userdata.email }}
            </p> 
            <p class="web_address">
              <a
                href="https://www.opticvyu.com/"
                style="color:black;text-decoration: none; "
                ><b>OpticVyu Construction Camera</b></a
              ><br /><a href="mailto:admin@opticvyu.com">admin@opticvyu.com</a>
            </p>
          </div> -->
          <div class="pdflogo">
            <p style="color:white;">
              <font size="4" color="white"
                >{{ project_name }} - {{ org }} - {{ city }}</font
              >
            </p>
            <p
              style="color: white; margin-bottom: 49%;position: absolute;left: 1px;margin-top: 2%;"
            >
              Pdf Generated on: {{ todaydate }} | Generated By:
              {{ userdata.email }}
            </p>
            <!-- <p
              style="color: white; margin-bottom: 49%;position: absolute;left: 1%;margin-top: 3%;"
            >
              {{ city }}
            </p> -->
            <p class="web_address">
              <img v-if="base64_url" :src="base64_url" />
              <img v-else :src="logo" />
            </p>
          </div>
          <v-row style="padding: 2%;" v-if="show_noteblock">
            <v-col cols="12" md="12">
              <p style="text-align: center;"><b>Notes and Details</b></p>
              <v-list-item v-for="u in view_reportnote" :key="u.id">
                <v-list-item-icon>
                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>{{ u.note }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <div class="html2pdf__page-break" v-if="show_noteblock">
            <p style="text-align: center;"><b>Images on the next page</b></p>
          </div>
          <v-row style="padding: 2%;">
            <v-col
              cols="6"
              md="6"
              v-for="(cart, index) in cartdata"
              :key="cart.id"
            >
              <img
                class="imgmax_height"
                :src="cart.img_url"
                style="width: 500px; cursor: pointer;"
                max-height="250"
              />
              <div class="html2pdf__page-break" v-if="(index + 1) % 4 == 0">
                <!-- _ -->
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
    </vue-html2pdf>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="reportdialogbox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            Generate Project Report</v-toolbar
          >
          <v-btn class="right_aligh" icon dark @click="reportdialogbox = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" class="center">
                <v-btn
                  @click="SavereportPDF"
                  :disabled="pdf_notready"
                  class="btn ma-2 Block--text rightbutons downloadbutons"
                >
                  Download & Save to Server
                  <v-icon right dark color="#000">
                    mdi-content-save
                  </v-icon>
                </v-btn>
                OR
                <v-btn
                  :progress="progress"
                  @click="DownloadreportPDF"
                  :disabled="pdf_notready"
                  color="#ffc400"
                  class="btn ma-2 Block--text rightbutons downloadbutons"
                >
                  Download Now
                  <v-icon right dark color="#000">
                    mdi-download
                  </v-icon>
                </v-btn>
                <v-col cols="12" sm="12" class="center" v-if="pdf_notready">
                  <p>
                    Preparing the report. Please wait for {{ showtimesec }} sec.
                  </p>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="show_pdfdialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="amber accent-3" style="flex: none">
          <v-btn icon dark @click="show_pdfdialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Saved PDF Report</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text v-if="showpdfdata">
          <v-list three-line subheader>
            <v-list-item v-for="pdf in pdfdata" :key="pdf.id">
              <v-list-item-action>
                <v-icon right dark color="#000">
                  mdi-check-all
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ pdf.pdfname }}</v-list-item-title>
                <v-list-item-subtitle
                  >Pdf added at {{ pdf.created_at }}</v-list-item-subtitle
                >
                <v-col cols="4" md="4">
                  <v-btn
                    color="amber accent-3"
                    dark
                    class="ma-2"
                    @click="downloadpdflist(pdf)"
                  >
                    Download Pdf
                    <v-icon right dark color="#000">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" md="4">
                  <v-btn
                    color="amber accent-3"
                    dark
                    class="ma-2"
                    @click="openshareboxpdf(pdf)"
                  >
                    Share Pdf
                    <v-icon right dark color="#000">
                      mdi-share-variant
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" md="4">
                  <v-btn
                    color="amber accent-3"
                    dark
                    class="ma-2"
                    @click="deletepdfreport(pdf)"
                  >
                    Delete Pdf
                    <v-icon right dark color="#000">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-card-text>
        <v-card-text v-if="!showpdfdata">
          <div class="text-h3 pa-12">
            No data is available. First try to download and save the report.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="shareboxpdf"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> Share By Email </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitcartreportdata"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="shareemail"
                    label="Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="sharesubject"
                    label="Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    Send
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="shareboxpdf = false" color="mydarkbutton">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="add_notes_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Report Notes</span>
          <v-alert dense text :type="alertType" v-if="alert">
            {{ alertmessage }}
          </v-alert>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-for="u in view_reportnote"
                :key="u.id"
              >
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-btn
                        icon
                        color="rgb(241 51 8)"
                        @click="delete_note(u.id)"
                        title="Delete this note"
                        style="position: relative;left: 92%;"
                      >
                        <v-icon v-bind="attrs" style="font-size: 2rem;"
                          >mdi-delete</v-icon
                        >
                      </v-btn>
                      <p class="p_note">{{ u.note }}</p>
                    </v-col>
                  </v-row>
                  <br />
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Add Note"
                  v-model="addnote"
                  Required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="add_notes_dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="Update_reportNote()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueSweetalert2 from "vue-sweetalert2";
import image1 from "../../assets/opticvyu_comp_l.png";
import logo from "../../assets/ov.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import VueHtml2pdf from "vue-html2pdf";
import $ from "jquery";
import { ImageEditor } from "@toast-ui/vue-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import { locals } from "../../utils/locals";
//  import moment from 'moment'
export default {
  components: {
    Preloader,
    "tui-image-editor": ImageEditor,
    VueHtml2pdf
  },
  name: "MobileUpload",
  data() {
    return {
      preloader: true,
      camers: {},
      mresponse: {},
      miresponse: {},
      dirdata: {},
      content: "",
      created: "",
      dir_name: "",
      img_src: "",
      imagetag: true,
      vidthumb: false,
      dialog: false,
      img: "",
      dialog1: false,
      vidurl: "",
      username: "",
      useremail: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      alert: false,
      show: false,
      d1: "Select Date",
      timeslots: [],
      compareimage1: image1,
      logo: logo,
      activecolor: "amber accent-3",
      timeslotloader: false,
      current_cam: {},
      options: {
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        includeUI: {
          loadImage: {
            path: image1,
            name: "SampleImage"
          },
          initMenu: "draw",
          menuBarPosition: "left"
        },
        ErrorMsg: "Sorry Image is not load"
      },
      showannotation: false,
      useDefaultUI: true,
      newimagebs: false,
      cartnewbase64: "",
      proj_dir: "",
      project_name: "",
      projectid: 0,
      userdata: {},
      imageid: "",
      cartdata: {},
      loading2: false,
      picker: new Date().toISOString().substr(0, 10),
      snackbar: false,
      text: "Something is going wrong. Please try again.",
      imgdialog: false,
      info_dialog: false,
      addedby: "",
      addedon: "",
      todaydate: "",
      pdfname: "",
      reportdialogbox: false,
      pdfDownloaded: false,
      imgcount: "",
      pdfbase64: "",
      show_pdfdialog: false,
      pdfdata: {},
      pdfcount: "",
      shareboxpdf: false,
      progress: 0,
      emailrules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      valid: true,
      shareemail: "",
      sharesubject: "",
      send: false,
      showpdfdata: true,
      userfullname: "",
      showcartdata: false,
      add_notes_dialog: false,
      alertmessage: "",
      view_reportnote: {},
      addnote: "",
      Local: locals,
      lang: {},
      show_noteblock: false,
      pdf_notready: true,
      timercounter: "",
      showtimesec: 30,
      currentcam_name: "",
      arrayDates: [],
      dateActiver: false,
      pickerDate: null,
      datesWithImages: [],
      archiveCalenderyearMonth: false,
      snackbarDatesWithImages: false,
      checkselected: [],
      deleteAllButton: false,
      base64_url: ""
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    this.current_cam = this.$store.state.currentCamera;
    this.currentcam_name = this.current_cam.pro_cam_serial;
    this.initViewer();
    let currentProject = JSON.parse(localStorage.getItem("currentProject"));

    //console.log('currentProject', currentProject);
    this.projectid = currentProject.id;
    this.proj_dir = currentProject.pro_dir;
    this.project_name = currentProject.project_name;
    this.project = currentProject.project_name;
    this.org = currentProject.company;
    this.city = currentProject.city;
    this.getCartimg();
    this.add_reportnotes();
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    // this.countDownTimer();
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
    this.getOrgPDF();
  },
  watch: {
    options: function(nval) {
      this.tui_re_render++;
      console.log(" ttui => ", nval);
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
    "$store.state.currentCamera": function() {
      //this.preloader = true
      //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.current_cam = this.$store.state.currentCamera;
      this.currentcam_name = this.current_cam.pro_cam_serial;
      console.log("camera parameters", this.currentcam);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      //console.log('currentProject', currentProject);
      this.projectid = currentProject.id;
      this.proj_dir = currentProject.pro_dir;
      this.project_name = currentProject.project_name;
      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.timeslots = [];
      this.getCartimg();
      this.add_reportnotes();
      this.userfullname =
        this.userdata.firstname + " " + this.userdata.lastname;
      this.countDownTimer();
    }
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: this.pdfname,

        image: {
          type: "jpeg",
          quality: 0.5
        },
        enableLinks: true,
        html2canvas: {
          scale: window.devicePixelRatio,
          useCORS: true
        },

        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "p"
        }
      };
    },
    paramsreport() {
      return {
        to: this.shareemail,
        userfullname: this.userfullname,
        message: "",
        subject: this.sharesubject,
        attachment: this.savedpdfurl,
        project: this.project_name,
        org: this.org,
        city: this.city
      };
    },
    param_add_note() {
      return {
        report_note: this.addnote,
        project_id: this.projectid,
        project_name: this.project_name,
        email_id: this.userdata.id,
        email_add: this.userdata.email
      };
    }
  },

  methods: {
    allowedDates(val) {
      var values = [];
      this.datesWithImages.forEach(item => {
        var daywithimage = item.image_date;
        values.push(daywithimage);
      });

      if (values.includes(val)) return true;
      if (values.includes(val)) return ["red", "#00f"];
      return false;
    },
    alldates(val) {
      return val;
    },
    datesWithImage() {
      this.timeslotloader = true;
      this.dateActiver = !this.dateActiver;
      var camera_id = this.current_cam.camera_id;
      this.snackbarDatesWithImages = true;
      if (this.dateActiver) {
        this.archiveCalenderyearMonth =
          "Filtered dates where images are available.";
      } else {
        this.archiveCalenderyearMonth = "Showing all dates.";
      }

      axios({
        url: "/camera/" + camera_id + "/dates_with_images",
        method: "GET"
      })
        .then(resp => {
          this.datesWithImages = resp.data.success;
          this.timeslotloader = false;
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    initViewer() {
      //console.log("init Viewer");
      this.preloader = false;

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.current_cam = this.$store.state.currentCamera;
      //console.log('camera parameters',  this.currentcam);
      this.todaydate = this.titledateFormate(new Date());
      this.pdfname = this.project + "_" + this.todaydate;
      console.log("pdf name", this.pdfname);
      this.userfullname =
        this.userdata.firstname + " " + this.userdata.lastname;
      //this.getCartPDF();
    },

    getSelectedDate(data) {
      this.timeslotloader = true;
      this.d1 = data;
      var d = data.split("-");
      //console.log(d);
      var nd = d[0] + d[1] + d[2];

      //  console.log(nd, this.current_cam.camera_id);

      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.error) {
            this.snackbar = true;
            this.text = `No images were found on the selected date. Please try again!`;
          }

          var dd = resp.data.success;

          this.timeslots = dd;

          // console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch(err => {
          console.log(err);
          this.snackbar = true;
        });
    },
    selectTimeSlot(imageID) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.imageid = imageID;
      this.getImageUrlfirst(imageID);
      this.preloader = true;
    },
    getImageUrlfirst(id) {
      this.preloader = true;
      axios({
        url:
          "/camera/" +
          this.current_cam.camera_id +
          "/image/" +
          id +
          "/list/url",
        method: "GET"
      })
        .then(resp => {
          this.current_cam = this.$store.state.currentCamera;
          if (this.current_cam.cam_360 == 1) {
            this.show_1img360 = true;
            this.compare_1img360 = resp.data.success.image_url;
          } else {
            this.show_1img360 = false;
            this.compareimage1 = resp.data.success.image_url;
            this.annotadeImage(this.compareimage1);

            //this.annotadeImage();
          }
          this.dialog = false;
          var v = this;
          setTimeout(function() {
            v.preloader = false;
          }, 6000);

          //this.viewer.tileSources = this.source;
        })
        .catch(err => {
          console.log(err);
          this.snackbar = true;
        });
    },
    annotadeImage() {
      this.preloader = true;
      axios({
        url: "/image/baseimage",
        method: "GET",
        params: {
          image: this.compareimage1
        }
      })
        .then(resp => {
          if (resp.data.success) {
            this.showannotation = true;
            var data = resp.data.success;
            this.preloader = false;
            console.log(resp.data.success);
            this.options.includeUI.loadImage.path = data;
            // this.$refs.tuiImageEditor.invoke("loadImageFromURL", data, "tempImage")
            // .then((result) => {
            //     console.log(result);

            // });
          } else {
            this.preloader = false;
            this.snackbar = true;
            this.text =
              "Can not markup an already edited image or the default image. Please select a new image from the calendar for editing.";
          }
        })
        .catch(err => {
          console.log(err);

          this.preloader = false;
          this.snackbar = true;
          this.text =
            "Can not markup an already edited image or the default image. Please select a new image from the calendar for editing.";
        });
    },
    getBase64Image(url) {
      this.showannotation = true;
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var img = new Image();
      img.crossOrigin = "anonymous";

      let self = this;

      img.onload = function() {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        //callback.call(this, dataURL);
        //   this.imagedd = dataURL;
        console.log(dataURL);
        self.$refs.tuiImageEditor
          .invoke("loadImageFromURL", dataURL, "tempImage.jpg")
          .then(result => {
            console.log(result);
          });

        // Clean up
        canvas = null;
      };
      img.src = url;
    },
    SaveandSend() {
      this.preloader = true;
      //imgEl.src
      const myImageb = this.$refs.tuiImageEditor.invoke("toDataURL");

      // console.log("Edit data", myImage);

      const _scale = 0.5;
      var img = document.createElement("img");
      img.setAttribute("src", myImageb);

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width * _scale;
        canvas.height = img.height * _scale;

        var ctx = canvas.getContext("2d");
        var cw = canvas.width;
        var ch = canvas.height;
        var maxW = img.width * _scale;
        var maxH = img.height * _scale;

        var iw = img.width;
        var ih = img.height;
        var scl = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scl;
        var ihScaled = ih * scl;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        const newBase64 = canvas.toDataURL("image/jpeg", scl);

        //  return newBase64;
        var a = document.createElement("a"); //Create <a>
        a.href = newBase64; //Image Base64 Goes here
        a.download = this.filename; //File name Here
        this.compareimage1 = newBase64;
        this.cartnewbase64 = newBase64;
        //    this.showannotation = false;
        // a.click(); //Downloaded file
        // this.showannotation = false;
        //callBack(newBase64);
        // this.preloader = false;
        // this.snackbar = true;
        // this.text = `Changes Applied Successfully.`;
        setTimeout(() => {
          this.showannotation = false;
          this.SavetoCart();
          // this.$router.go();
        }, 3000);
      };
    },
    showannotationclose() {
      this.tui_re_render++;
      this.showannotation = false;
      self.$refs.tuiImageEditor.invoke("clearObjects");
    },
    SavetoCart() {
      // this.preloader = true;
      if (this.cartnewbase64) {
        //    this.preloader = true;
        // console.log(url1);
        axios({
          url: "/project/" + this.projectid + "/cart-report",
          method: "post",
          data: {
            proj_dir: this.proj_dir,
            projectname: this.project_name,
            project_id: this.projectid,
            userid: this.userdata.id,
            usermail: this.userdata.email,
            reportimg: this.cartnewbase64,
            imgid: this.imageid,
            camid: this.current_cam.camera_id
          }
        })
          .then(resp => {
            // console.log("image added to cart", resp.data.success);
            if (resp.data.success) {
              // this.commmentForm = false;
              this.snackbar = true;
              this.text = `Image successfully added to the cart.`;
              setTimeout(() => {
                this.showannotation = false;
                this.getCartimg();
                this.snackbar = true;
                this.text = `Refreshing Cart.`;
              }, 1000);
              this.snackbar = false;
              this.preloader = false;
            } else {
              this.preloader = false;
              this.snackbar = true;
              this.text = resp.data.error;
              setTimeout(() => {
                this.showannotation = false;
                this.getCartimg();
              }, 1000);
            }
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = "Something is going wrong. Please try again.";
            this.alertType = "error";
            this.preloader = false;
          });
      }
    },
    getCartimg() {
      this.preloader = true;
      axios({
        url: "/project/" + this.projectid + "/get_cart_report_img",
        method: "get",
        params: {
          proj_id: this.projectid,
          usermail: this.userdata.email
        }
      })
        .then(resp => {
          this.preloader = false;
          console.log("get images data", resp);
          this.cartdata = resp.data.success;
          this.imgcount = resp.data.imgcount;
          console.log("imgcount", this.imgcount);
          this.timercounter = this.imgcount * 2;
          /*  for (let i = 0; i < this.timercounter; i++) {
                      this.timercounter -= 1;
                      } */
          /*  let self = this;
                     setTimeout(()=>{
                        self.pdf_notready = false;
                        }, (self.imgcount)*2000); */

          if (this.imgcount > 0) {
            this.showcartdata = true;
          } else {
            this.showcartdata = false;
          }
          // this.commentbox = true;
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "Something is going wrong. Please try again.";
          this.alertType = "error";
          this.preloader = false;
        });
    },
    openimage(url) {
      this.imgdialog = true;
      this.img = url;
    },
    closetoggle() {
      this.imgdialog = false;
    },
    OpenInfo(data) {
      this.info_dialog = true;
      console.log("info of cart", data);
      this.addedby = data.user_email;
      this.addedon = data.updated_at;
    },
    delete_cart_image: function(id) {
      this.preloader = true;
      //alert("hello"+id);
      const data = { id: id };
      //this.$router.push('/dashboard/timelapse/custom-timelapse');
      //console.log(url);
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;

      //var camid = this.currentcam.camera_id;
      //console.log("current Camera", this.currentcam.camera_id);
      axios({
        url: "/project/" + this.currentProject.id + "/delete/cart_report_image",
        data,
        method: "POST"
      })
        .then(resp => {
          if (resp.data.success) {
            this.preloader = false;
            this.$swal({
              icon: "success",
              title: "Success",
              html: "Cart image deleted successfully.",
              showConfirmButton: true
            });
            setTimeout(() => {
              this.getCartimg();
              //  this.$router.go();
            }, 4000);
          } else {
            this.preloader = false;
            this.$swal({
              icon: "error",
              title: "Error",
              html: "Cart image not deleted. Please try again.",
              showConfirmButton: true
            });
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
        });
    },
    titledateFormate(d) {
      var n = new Date(d).toLocaleDateString("en-GB", {
        timeZone: "Asia/Kolkata"
      });

      return n;
    },

    DownloadreportPDF() {
      this.reportdialogbox = false;
      this.$swal({
        icon: "info",
        title: "Note",
        html:
          "This feature works best on Windows OS. Apple users may have trouble using this feature.",
        showConfirmButton: true
      });
      let self = this;
      this.preloader = true;
      this.$refs.html2Pdfblob.generatePdf();
      setTimeout(() => {
        self.preloader = false;
      }, 5000);
    },
    SavereportPDF() {
      this.reportdialogbox = false;
      this.$swal({
        icon: "info",
        title: "Note",
        html:
          "This feature works best on Windows OS. Apple users may have trouble using this feature.",
        showConfirmButton: true
      });
      this.preloader = true;
      // this.getOrgPDF();
      let self = this;
      this.$refs.html2Pdfblob.generatePdf();
      setTimeout(() => {
        self.preloader = false;
        self.Save_pdf_report();
      }, 9000);
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
            console.log(pdfContent, "generating pdf");
          }
        })
        .save();
    },
    async beforeDownload_blob({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
            // console.log(pdfContent, "generating pdf");
          }
        });
    },

    opendialogbox() {
      //  console.log(value);
      this.reportdialogbox = true;
    },
    hasDownloaded(blobPdf) {
      console.log(`PDF has downloaded yehey`);
      this.pdfDownloaded = true;
      console.log("pdf blob", blobPdf);
      let self = this;
      var reader = new window.FileReader();
      reader.readAsDataURL(blobPdf);
      reader.onloadend = function() {
        var base64data = reader.result;

        console.log("blob file to base64 file", base64data);
        self.Save_pdf_reportdata(base64data);
      };
    },
    Save_pdf_reportdata(pdfbase64data) {
      this.pdfbase64 = pdfbase64data;
    },
    Save_pdf_report() {
      if (this.pdfbase64) {
        this.preloader = true;
        // this.getOrgPDF();
        console.log("testing variable");
        // console.log(url1);
        axios({
          url: "/project/" + this.projectid + "/cart_report/save_pdf",
          method: "post",
          data: {
            proj_dir: this.proj_dir,
            projectname: this.project_name,
            project_id: this.projectid,
            userid: this.userdata.id,
            usermail: this.userdata.email,
            reportpdf: this.pdfbase64,
            pdfname: this.pdfname,
            camid: this.current_cam.camera_id
          }
        })
          .then(resp => {
            console.log("pdf added to cart", resp.data.success);
            if (resp.data.success) {
              this.preloader = false;
              // this.commmentForm = false;
              this.snackbar = true;
              this.text = `Pdf successfully saved.`;
              setTimeout(() => {
                // this.getCartimg();
                this.$router.go();
              }, 2000);
            } else {
              this.preloader = false;
              this.snackbar = true;
              this.text = resp.data.error;
              setTimeout(() => {
                this.$router.go();
              }, 3000);
            }
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = "Something is going wrong. Please try again.";
            this.alertType = "error";
            this.preloader = false;
          });
      } else {
        console.log("failed blob");
      }
    },

    getCartPDF() {
      // this.preloader = true;
      axios({
        url: "/project/" + this.projectid + "/get_cart_report_pdf",
        method: "get",
        params: {
          proj_id: this.projectid,
          usermail: this.userdata.email
        }
      })
        .then(resp => {
          this.preloader = false;
          this.show_pdfdialog = true;
          console.log("get images data", resp);
          this.pdfdata = resp.data.success;
          this.pdfcount = resp.data.pdfcount;
          console.log("imgcount", this.pdfcount);
          if (this.pdfcount > 0) {
            this.showpdfdata = true;
          } else {
            this.showpdfdata = false;
          }
          // this.commentbox = true;
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
          this.alert = true;
          this.alertmessage = "Something is going wrong. Please try again.";
          this.alertType = "error";
          this.preloader = false;
        });
    },
    downloadpdflist(pdfdata) {
      //imgEl.src
      var pdfdata = pdfdata;
      const mypdf = pdfdata.pdf_url;
      // console.log("Edit data", myImage);

      console.log(mypdf);

      var a = document.createElement("a"); //Create <a>
      a.href = mypdf; //Image Base64 Goes here
      a.download = pdfdata.pdfname; //File name Here
      a.click(); //Downloaded file
    },
    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`);
    },
    openshareboxpdf(r) {
      this.shareboxpdf = true;
      //console.log("data", r);
      this.savedpdfurl = r.pdf_url;
      //alert("hello");
    },
    async submitcartreportdata() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharesaved_reportdata(this.paramsreport); // action to login
      }
    },
    sharesaved_reportdata(sharedata) {
      //console.log("data", sharedata);
      this.send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-with-reportlink",
        data: sharedata,
        method: "post"
      })
        .then(resp => {
          //console.log(resp);
          this.shareboxpdf = false;
          this.send = false;
          this.snackbar = true;
          this.text = `Email successfully sent.`;
        })
        .catch(err => {
          //console.log(err)
          this.send = false;
          this.snackbar = true;
          this.text = `Email delivery failed.`;
        });
    },
    delete_all() {
      var selectedImages = this.checkselected.toString();
      const data = { email_id: this.userdata.email, images_id: selectedImages };
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      this.$swal({
        title: "Are you sure?",
        text: "Selected cart images will be deleted",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete Selected Cart Images",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          console.log("testing delete confirmation");
          axios({
            url:
              "/project/" +
              this.currentProject.id +
              "/delete_all/cart_report_image",
            data,
            method: "POST"
          })
            .then(resp => {
              if (resp.data.success) {
                this.preloader = false;
                this.$swal({
                  icon: "success",
                  title: "Success",
                  html: "All cart images were deleted successfully.",
                  showConfirmButton: true
                });
                this.checkselected.splice(0);
                setTimeout(() => {
                  // this.$router.go();
                  this.getCartimg();
                }, 2000);
              } else {
                this.preloader = false;
                this.$swal({
                  icon: "error",
                  title: "Error",
                  html: "Data not deleted. Please try again.",
                  showConfirmButton: true
                });
                this.checkselected.splice(0);
              }
              this.preloader = false;
            })
            .catch(err => {
              this.preloader = false;
              this.checkselected.splice(0);
              console.log(err);
            });
        } else {
          console.log("Cancel delete all cart images.");
        }
      });
    },

    open_and_show_pdf() {
      //  console.log(value);
      this.preloader = true;
      this.getCartPDF();
      if (this.pdfcount) {
        this.preloader = false;
        this.show_pdfdialog = true;
      } else {
        this.getCartPDF();
      }
    },
    open_note_dialog() {
      this.add_notes_dialog = true;
      this.alert = false;
      this.add_reportnotes();
    },
    add_reportnotes() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;

      axios({
        url: "/project/" + this.currentProject.id + "/get_report_note",
        method: "GET",
        params: {
          proj_id: this.projectid,
          email_id: this.userdata.email
        }
      })
        .then(resp => {
          console.log("get note api", resp.data.success);
          this.view_reportnote = resp.data.success;
          //alert(resp.data.success);
          if (resp.data.notecount > 0) {
            this.show_noteblock = true;
            //alert("condition true");
          } else {
            this.show_noteblock = false;
            // alert("condition false");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    Update_reportNote() {
      if (this.addnote) {
        //console.log("data to update", this.param_add);
        //camera_idd = this.notecam_id;
        let currentProject = JSON.parse(localStorage.getItem("currentProject"));
        this.currentProject = currentProject;
        axios({
          url: "/project/" + this.currentProject.id + "/post_report_note",
          data: this.param_add_note,
          method: "POST"
        })
          .then(resp => {
            if (resp.data.success) {
              this.alert = true;
              this.alertmessage = resp.data.success;
              this.alertType = "success";
              this.snackbar = true;
              this.text = resp.data.success;
              this.addnote = "";
              this.add_reportnotes();
            } else {
              this.alert = true;
              this.alertmessage = resp.data.error;
              this.alertType = "error";
              this.snackbar = true;
              this.text = resp.data.error;
            }
            /* var self = this;
            setTimeout(function () {
              self.dialog = false;
              self.alert = false;
            }, 1000); */
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        /*  this.alert = true;
              this.alertmessage = "Note text area should not be empty";
              this.alertType = "error";  */
        this.snackbar = true;
        this.text = `Note text area should not be empty.`;
      }
    },
    delete_note: function(id) {
      this.preloader = true;
      //alert("hello"+id);
      const data = { id: id };
      //this.$router.push('/dashboard/timelapse/custom-timelapse');
      //console.log(url);
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;

      //var camid = this.currentcam.camera_id;
      //console.log("current Camera", this.currentcam.camera_id);
      axios({
        url: "/project/" + this.currentProject.id + "/delete/cart_report_note",
        data,
        method: "POST"
      })
        .then(resp => {
          if (resp.data.success) {
            this.preloader = false;
            this.$swal({
              icon: "success",
              title: "Success",
              html: "Note deleted successfully.",
              showConfirmButton: true
            });
            setTimeout(() => {
              this.$router.go();
            }, 3000);
          } else {
            this.preloader = false;
            this.$swal({
              icon: "error",
              title: "Error",
              html: "Note not deleted. Please try again.",
              showConfirmButton: true
            });
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
        });
    },
    deletepdfreport: function(pdf) {
      this.preloader = true;
      //alert("hello"+id);
      const data = { id: pdf.id };
      //this.$router.push('/dashboard/timelapse/custom-timelapse');
      //console.log(url);
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;

      //var camid = this.currentcam.camera_id;
      //console.log("current Camera", this.currentcam.camera_id);
      axios({
        url: "/project/" + this.currentProject.id + "/delete/cart_report_pdf",
        data,
        method: "POST"
      })
        .then(resp => {
          if (resp.data.success) {
            this.preloader = false;
            this.$swal({
              icon: "success",
              title: "Success",
              html: "Pdf deleted successfully.",
              showConfirmButton: true
            });
            setTimeout(() => {
              this.$router.go();
            }, 4000);
          } else {
            this.preloader = false;
            this.$swal({
              icon: "error",
              title: "Error",
              html: "Pdf not deleted. Please try again.",
              showConfirmButton: true
            });
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch(err => {
          this.preloader = false;
          console.log(err);
        });
    },
    countDownTimer() {
      // console.log("check timer", this.showtimesec);
      let self = this;
      if (this.showtimesec > 0) {
        setTimeout(() => {
          self.showtimesec -= 1;

          //self.showtimesec = this.timercounter;
          self.countDownTimer();
        }, 1000);
      } else {
        this.pdf_notready = false;
      }
    },
    getOrgPDF() {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      var org_id = this.currentProject.organization_id;
      var proj_id = this.currentProject.id;

      var data = {
        org_id: org_id
      };

      var url = "/organization/" + proj_id;
      axios({ url: url, method: "GET", params: data })
        .then(resp => {
          this.orgdata = resp.data.success;
          this.base64_url = resp.data.success.org_logo_base;
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
          this.alert = true;
          this.alertmessage = "Somethings went wrong please reload page.";
        });
    }
  }
};
</script>
<style>
.diricon {
  font-size: 62px !important;
  color: #ffc400 !important;
}
.c_pointer {
  cursor: pointer;
}
.content {
  margin-top: 0.5%;
}
.minfo {
  color: #f8bb03;
}
.minfoSpan {
  position: absolute;
  right: 2%;
  top: 6%;
}
.dir_scroll {
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}
.zzoomerright_old {
  position: absolute;
  right: -3%;
  /* width: 50%; */
  display: inline-block;
  height: 436px;
  width: 700px;
}
.zzoomerright_new {
  position: absolute;
  right: 1%;
  /* width: 50%; */
  display: inline-block;
  height: 100%;
  top: 0%;
  width: 98%;
}
.c_image_menus {
  position: fixed;
  bottom: 30%;
  right: 33%;
  /* z-index: 2; */
  width: 50px;
}
.zzoomerfull {
  width: 100%;
  height: 100vh;
  border: solid 1px silver;
  position: fixed;
  top: 0;
  left: 0;
  background: #515b61b0 !important;
  display: BLOCK;
}
.closebuttons {
  position: fixed !important;
  right: 20px;
  top: 60px;
  z-index: 999999;
  color: #fff !important;
}
.pdflogo {
  height: 85px;
  background-color: black;
  /* margin: 1% 0; */
}
.pdflogo p {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  display: inline-block;
  line-height: 80px;
  vertical-align: top;
}

.pdflogo .web_address {
  position: absolute;
  right: 1%;
}
.pdflogo img {
  width: 70px;
  margin-top: 14%;
}
.pdflogo_b {
  height: 100px;

  bottom: 0;
  margin: 1% 0 -4%;
}
.pdflogo_b p {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  display: inline-block;
}

.pdflogo_b .web_address {
  position: absolute;
  right: 1%;
}

.imgmax_height {
  max-height: 250px;
  width: 500px;
}
.brspace br {
  content: "";
  display: block;
  font-size: 200%;
  margin-top: -1.5em;
  color: #fff;
}
.info_editor {
  position: absolute;
  right: 25%;
}
.timeList {
  position: absolute;
  width: 110px;
  top: 89px;
  right: 0px;
  height: 340px;
  display: block;
  overflow: hidden;
}

.dateWithImageButton {
  position: absolute !important;
  top: 237px;
  left: 48px;
}
@media only screen and (max-width: 600px) {
  .dateWithImageButton {
    position: unset !important;
    top: unset;
    left: unset;
  }
}
.itemBottomMargin {
  margin-bottom: -9px !important;
}
.reportButtons {
  left: 1%;
  position: fixed;
  bottom: 12px;
}
.saveShiftLeft {
  right: 221px !important;
}
.cartSize {
  width: 100% !important;
}
.checkboxPosition {
  position: absolute;
  z-index: 1;
  margin-top: 3px;
  margin-left: 3px;
  accent-color: #f6cc49;
}
.cartImageInfo {
  position: absolute;
  float: right;
  right: -9px;
  background: #02020236;
  padding: 3px 7px;
  border-radius: 3px;
  top: 0px;
  z-index: 2;
  color: #fff;
}
</style>

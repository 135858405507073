<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <!-- <v-col cols="12" md="12" class="tohed">

            <span class="fixheader" @click="drawingMenuToggle">
                {{drawing.name}}

                <v-icon right dark>
                    mdi-menu-down
                </v-icon>

            </span>

        </v-col> -->
        <div class="megaMenus" :class="drawingToggle?'opendrawer':'closedrawer'">
            <v-row>
                <v-btn fab x-small color="#fff" class="closeDrower" @click="closeMegamenu">
                    <v-icon dark>
                        mdi-close
                    </v-icon>
                </v-btn>
                <v-col v-for="n in drawings" :key="n.drawingid" :class="`d-flex child-flex drawingitem mega ${n.drawingid == activeDrawing.drawingid?'selected':''}`" cols="12"
           xs="12"
           sm="6"
           md="6"
           lg="3"
           xl="3">
                    <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
                        <div class="drawingname">
                            {{n.name}}

                            <!-- <v-btn
            class="mx-2 removedrawing"
            fab
            x-small
            color="#ffc400"
            @click="removeDrawing(n.drawingid)"
            >
            <v-icon dark>
               mdi-delete
            </v-icon>
            </v-btn> -->

                        </div>
                        <div class="drawingname dfooter">
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-axis-z-rotate-clockwise
                                </v-icon>
                            </v-btn>
                            {{n.numberofrotet}}
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-camera
                                </v-icon>
                            </v-btn>
                            {{n.numberofimages}}
                            <v-btn class="mx-2" fab x-small color="#fff">
                                <v-icon dark>
                                    mdi-play
                                </v-icon>
                            </v-btn>
                            {{n.numberofvideos}}

                        </div>

                    </v-img>
                </v-col>
            </v-row>
        </div>

        <v-col :cols="colum ? 6 : 12" class="drawingxxs">
            <img :src="drawing.drawingpath" alt="Workplace" id="ble" class="map half" usemap="#workmap">
            <map name="workmap" id="mymap">
                <area shape="circle" v-for="d in cordinates" href="#" :data-maphilight='d.fillcolor' :key="d.coordinateid" @click="openTooltip(d)" :coords="`${d.x},${d.y},8`" alt="Corridor"> </map>

            <div id="moon" :class="cordinatInfo ?'openTool':'closetool'" :style="{left: x +'px', top: y +'px' }">

                <span class="closepop" id="closecordinate"> <i class="fa fa-times" aria-hidden="true"></i> </span>
                <p class="circledata"> {{ openCoordinate.title }} <br> {{openCoordinate.updateat}} </p>

                <span class="cmenus">
                    <span class="citem" :title="lang.Images360">

                        <v-btn fab x-small color="#fff" @click="getNormalImages('panaroma')">
                            <v-icon dark>
                                mdi-axis-z-rotate-clockwise
                            </v-icon>

                        </v-btn>
                        <p>{{openCoordinate.count360 === null ? 0 : openCoordinate.count360}}</p>

                    </span>
                    <span class="citem" :title="lang.NormalImages">

                        <v-btn fab x-small color="#fff" @click="getNormalImages('normal')">
                            <v-icon dark>
                                mdi-camera
                            </v-icon>

                        </v-btn>

                        <p>{{openCoordinate.countimages === null ? 0 : openCoordinate.countimages }}</p>
                    </span>
                    <span class="citem" :title="lang.videos360">

                        <p>{{openCoordinate.countvideos === null ? 0 : openCoordinate.countvideos}}</p>
                        <v-btn fab x-small color="#fff" @click="getNormalImages('video')">
                            <v-icon dark>
                                mdi-play
                            </v-icon>
                        </v-btn>

                    </span>

                    <span class="citem" :title="lang.Comments">
                        <p>{{openCoordinate.comment_count === null ? 0 : openCoordinate.comment_count }}</p>
                        <v-btn fab x-small color="#fff" @click="getCommnets">
                            <v-icon dark>
                                mdi-chat-processing
                            </v-icon>
                        </v-btn>

                    </span>

                    <span class="citem" :title="lang.Task">
                        <v-btn fab x-small color="#fff" @click="taskboxopen">
                            <v-icon dark>
                                mdi-format-list-checks
                            </v-icon>
                        </v-btn>
                    </span>

                    <span class="citem" :title="lang.Close" @click="cordinatInfo = false">
                        <v-btn fab x-small color="#fff">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </span>

                </span>

            </div>

            <div id="comments" v-if="commentbox && commentsdata.length > 0" :class="commentbox ?'commentopentool':'commnetclosetool'" :style="{left: xc +'px', top: yc +'px' }">
                <v-btn fab x-small color="#fdc004" @click="commentbox = false" class="closecomment">
                    <v-icon dark>
                        mdi-close
                    </v-icon>
                </v-btn>
                  <h3 class="commnet_titlees"> Snaglist </h3>
                  <div class="commnet_item" v-for="comment in commentsdata" :key="comment.cmt_id">
                     
                    <div class="commnet_image" @click="openComment_image(comment.thumbnail_url)">
                        <img :src="comment.thumbnail_url" style="width: 100%">
                    </div>
                    <div class="commnet_text">
                              
                                <span>{{dateFormate(comment.creat_at)}} </span>
                                <span>{{comment.sender_name}}</span>
                         
                          <p :title="comment.comment_txt" > {{ substring(comment.comment_txt)}}</p>
                           <v-btn :color="comment.comment_status == 1 ? 'green':'red'"  small class="mr-1 black--text" @click="meckReaded(comment)">
                                        <v-icon left dark>
                                            {{ comment.comment_status == 1 ? 'mdi-check-all':'mdi-check' }}
                                        </v-icon>
                                        {{ comment.comment_status == 1 ? 'Completed':'Pending' }}

                                    </v-btn>

                                    <v-btn color="#fdc004" class="black--text" small @click="getcommentImages(comment.url360)">
                                        <v-icon left dark>
                                            mdi-axis-z-rotate-counterclockwise
                                        </v-icon>
                                        360 {{lang.Image}}
                                    </v-btn>
                        </div>
                </div>

               
                    <!-- <h3 class="commnet_titlees"> Snaglist </h3> -->
                    <!-- <v-timeline align-top dense v-for="comment in commentsdata" :key="comment.cmt_id">
                        <v-timeline-item :color="comment.comment_status == 1 ? 'green':'red'">
                            <v-row class="pt-1">
                                <v-col cols="12" >
                                    <strong>{{dateFormate(comment.creat_at)}} </strong>
                                    <div class="mb-2">
                                        <img :src="comment.thumbnail_url" style="width: 100%" class="imageds" @click="openComment_image(comment.thumbnail_url)">

                                    </div>
                                    <div class="mb-2">
                                        {{comment.comment_txt}}
                                    </div>
                                    <v-btn :color="comment.comment_status == 1 ? 'green':'red'" class="ma-2 black--text" @click="meckReaded(comment)">
                                        <v-icon left dark>
                                            {{ comment.comment_status == 1 ? 'mdi-check-all':'mdi-check' }}
                                        </v-icon>
                                        Status

                                    </v-btn>

                                    <v-btn color="#fdc004" class="ma-2 black--text" @click="getcommentImages(comment.url360)">
                                        <v-icon left dark>
                                            mdi-axis-z-rotate-counterclockwise
                                        </v-icon>
                                        360 Image
                                    </v-btn>

                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </v-timeline> -->
            </div>

        </v-col>

        <v-col :cols="colum ? 6 : 12" v-if="colum" class="playerplase">
            <div class="topbar"><span>{{ openCoordinate.title }}</span> <span> {{compatedatefirst }}</span></div>
            <v-zoomer v-if="activet == 'normal'" ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
                <img :src="imagenormal" style="object-fit: contain; width: 100%; height: 100%;">

            </v-zoomer>

            <v-btn fab  x-small  color="#fff" v-if="activet == 'panaroma'" class="addcomment" @click="addComment" :title="lang.AddComment">
                <v-icon dark>
                    mdi-comment-plus
                </v-icon>
            </v-btn>

            <v-btn fab  color="red" v-if="activet == 'panaroma'  && imagenormalcompare " class="locks_reen" @click="lockScreens" :title="lang.Lock">
                <v-icon dark>
                    {{ lock ? 'mdi-lock':'mdi-lock-open' }}
                </v-icon>
            </v-btn>


            <v-pannellum  :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable"    :hfov="hfov" :yaw="yaw"  :pitch="pitch"   v-if="activet == 'panaroma'" ref="panaromascreen" class="fullscreen" :src="imagepanaroma"    :dynamic="true" ></v-pannellum>

            <Pano v-if="activet == 'video'" class="videoplay" type='video' :source="pvideo">
            </Pano>
            <v-icon dark color="#000" @click="fullscreenexit()" class="exitbuttonsbb">
                mdi-close
            </v-icon>
    <div id="controls" v-if="lock">
        <div class="ctrl" id="pan-up" @click="panUp">&#9650;</div>
        <div class="ctrl" id="pan-down" @click="panDown">&#9660;</div>
        <div class="ctrl" id="pan-left" @click="panLeft">&#9664;</div>
        <div class="ctrl" id="pan-right" @click="panRight">&#9654;</div>
        <div class="ctrl" id="zoom-in" @click="panIn">&plus;</div>
        <div class="ctrl" id="zoom-out" @click="panOut">&minus;</div>
      
    </div>
        </v-col>

        <v-col cols="6" v-if="imagenormalcompare" class="playerplase compareb">
            <div class="topbar"><span>{{ openCoordinate.title }}</span> <span> {{compatedate }}</span></div>
            <v-zoomer v-if="activet == 'normal'" ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
                <img :src="secondimagenormal" style="object-fit: contain; width: 100%; height: 100%;">

            </v-zoomer>
            <v-pannellum :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable"  :hfov="hfov" :yaw="yaw"  :pitch="pitch"  v-if="activet == 'panaroma'"  ref="panaromascreen2" class="fullscreen" :src="secondimagepanaroma"   ></v-pannellum>

            <v-icon dark color="#000" @click="closeCompare" class="exitbuttonscomapre">
                mdi-close
            </v-icon>
        </v-col>

        <v-col cols="6" v-if="commentopenImage" class="playerplase compareb">
            <v-zoomer ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
                <img :src="secondimagenormal" style="object-fit: contain; width: 100%; height: 100%;">

            </v-zoomer>

            <v-icon dark color="#000" @click="commentopenImage = false" class="exitbuttonscomapre">
                mdi-close
            </v-icon>
        </v-col>

        <v-col cols="6" v-if="taskbox" class="taskbox">
            <v-icon dark color="#000" @click="taskbox = false" class="exittask">
                mdi-close
            </v-icon>

             <v-select class="standertask mr-4" :items="tasktype" :label="lang.ImportTasks" item-value="task_type" item-text="task_type" dense solo outlined small v-if="showstanderoption" @change="selectTlistItem"></v-select>

            <v-btn color="#f8bb03" class=" mr-4" @click="getStanderType" v-if="tasks.length == 0"> {{lang.StandardTaskLists}} </v-btn>

            <v-btn color="#f8bb03" class="mr-4" @click="newTaskForm = true"> 
                <v-icon color="#000">
                mdi-plus
            </v-icon>
            </v-btn>

            <v-btn color="#f8bb03" class="mr-4" @click="removeall" v-if="selectedtask.length > 0"> {{lang.RemoveSelected}} </v-btn>
            <span class="tasklisttitle">{{ openCoordinate.title }} - Tasks</span>
            <div class="mt-8">

                <v-data-table :headers="headers" v-model="selectedtask" item-key="taskid" :single-select="singleSelect" show-select :items="tasks" :items-per-page="50" class="elevation-1">

                    <template v-slot:item.id="{ item }">

                        <v-btn fab dark icon small color="black" @click="editTask(item)">
                            <v-icon dark>
                                mdi-pencil-box-multiple
                            </v-icon>
                        </v-btn>
                        <v-btn fab dark icon small color="black" @click="deleteTask(item.taskid)">
                            <v-icon dark>
                                mdi-delete
                            </v-icon>
                        </v-btn>

                    </template>

                </v-data-table>
            </div>
        </v-col>

    </v-row>

    <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

    <div :class="`timeline3d ${down ? 'down':'up'}`" v-if="slides.length > 0">

        <v-select class="dropdates" :items="uniquedates" :label="lang.FilterByDate" dense solo outlined @change="selectDate"></v-select>

        <v-btn class="mx-2 arrowr" fab x-small color="#f8bb03" @click="downUp">
            <v-icon dark>
                mdi-chevron-up-circle
            </v-icon>
        </v-btn>
        <carousel :key="refresh" class="timelinec" :autoplay="false" :items="8" :slideBy="7" :dots="false" :margin="8" :nav="true" :navText="['','']">
            <div v-for="(slide, index) in slides" :key="index" class="timelaineimagebox">
                <input type="checkbox" v-model="selected" @click="oncompare($event,index)" class="checkboxxsle" :value="index" v-if="activet == 'normal' || activet == 'panaroma'" />

                <img :src="slide.mediathumpath == '' || slide.mediathumpath == 'not available' ? vthumbnail : slide.mediathumpath" @click="onSelect(index)">

                <p class="disply_date">{{ slide.uploadat }}</p>
            </div>

        </carousel>
        <!-- <carousel-3d :disable3d="true" :controls-visible="true" :controls-prev-html="'&#10092; '" :controls-next-html="'&#10093;'" :controls-width="30" :controls-height="60" ref="carousel" :on-main-slide-click="onSelect" :clickable="true" :count="slides.length" :display="15" :height="140" :width="200"  :inverse-scaling="1500" :space="200">
            <slide v-for="(slide, i) in slides" :index="i" :key="i">
                <input type="checkbox" v-model="selected" @change="onSelect(slide)" class="checkboxxsle" :value="i" v-if="activet == 'normal' || activet == 'panaroma'" />
                <img :src="slide.mediathumpath == '' || slide.mediathumpath == 'not available' ? vthumbnail : slide.mediathumpath">
                <figcaption>

                    {{slide.uploadat}}
                </figcaption>
            </slide>
        </carousel-3d> -->
    </div>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="commmentForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.AddNewComment}} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendComment">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <div class="commentimage mb-5">
                                    <v-img :src="commentImage" aspect-ratio="2" class="black lighten-2"> </v-img>
                                </div>
                                <v-textarea v-model="comment" :rules="requerdRules" :label="lang.Comment" outlined required></v-textarea>

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send }} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn class="mr-4" @click="commmentForm = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newTaskForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.AddNewTask}} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendTask">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-col cols="12" sm="12">
                                    <v-text-field v-model="newTask.taskname" :rules="requerdRules" :label="lang.TaskName" required></v-text-field>

                                      <v-select
                                        :rules="requerdRules"
                                        :items="status"
                                        :label="lang.Status"
                                        v-model="newTask.taskstatus"
                                        ></v-select>

                                    <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.taskstartdate" :label="lang.StartDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.taskstartdate" @input="menu3 = false"></v-date-picker>
                                    </v-menu>

                                    <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="newTask.taskenddate" :label="lang.EndDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="newTask.taskenddate" @input="menu4 = false"></v-date-picker>
                                    </v-menu>

                                    <v-text-field v-model="newTask.taskprogress" type="number" :rules="requerdRules" :label="lang.Progress" required></v-text-field>

                                    <v-text-field v-model="newTask.tasktag" :label="lang.Tag"></v-text-field>
                                </v-col>

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn class="mr-4" @click="newTaskForm = false" color="mydarkbutton">
                                   {{ lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="updateTaskForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.UpdateTask }} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="sendupdateTask">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-col cols="12" sm="12">
                                    <v-text-field v-model="updateTask.taskname" :rules="requerdRules" :label="lang.TaskName" required></v-text-field>
                                     <v-select
                                        :rules="requerdRules"
                                        :items="status"
                                        :label="lang.Status"
                                        v-model="updateTask.taskstatus"
                                        ></v-select>
                                    <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="updateTask.taskstartdate" :label="lang.StartDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="updateTask.taskstartdate" @input="menu5 = false"></v-date-picker>
                                    </v-menu>

                                    <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="updateTask.taskenddate" :label="lang.EndDate" apend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="amber accent-3" v-model="updateTask.taskenddate" @input="menu6 = false"></v-date-picker>
                                    </v-menu>

                                    <v-text-field v-model="updateTask.taskprogress" type="number" :rules="requerdRules" :label="lang.Progress" required></v-text-field>

                                    <v-text-field v-model="updateTask.tasktag" label="Tag"></v-text-field>
                                </v-col>

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send }} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn class="mr-4" @click="updateTaskForm = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="reportToggle">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.Report}} </v-toolbar>
                <v-btn class="right_aligh" icon dark @click="clsoeReport">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container>
                    <v-row>

                        <v-col cols="12" sm="12" class="center">

                            <download-excel class="btn ma-2 Block--text rightbutons downloadbutons" :data="exceldata" :fields="json_fields" :title="drawing.name" worksheet="Tasks" :name="`${drawing.name}.xls`" :before-finish="finishDownload">
                                {{lang.DownloadExcel}} <v-icon right dark color="#000">
                                    mdi-file-excel
                                </v-icon>
                            </download-excel>

                            {{lang.OR}}

                            <v-btn @click="DownloadOverallPDF" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
                                {{lang.DownloadPDF}}
                                <v-icon right dark color="#000">
                                    mdi-file-pdf
                                </v-icon>
                            </v-btn>

                        </v-col>
                    </v-row>
                </v-container>

            </v-card>
        </template>
    </v-dialog>

    <table class="table hide" id="cumulativeReportDownload">

        <thead class="thead-dark">
            <tr>

                <th>{{lang.Task}}</th>
                <th>{{lang.Status}}</th>
                <th>{{lang.start}}</th>
                <th>{{lang.end}}</th>
                <th>{{lang.Progress}}</th>
                <th>{{lang.Tag}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(val, index ) in cumulative_data" :key="index">
                <td v-if="val.title" colspan="6">{{val.title}}</td>
                <td>{{val.Task}}</td>
                <td>{{val.Status}}</td>
                <td>{{val.Start}}</td>
                <td>{{val.End}}</td>
                <td>{{val.Progress}}</td>
                <td>{{val.Tag}}</td>
            </tr>
        </tbody>
    </table>
    <div class="layer" v-if="lock">
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import image1 from '../../assets/e.png'
import vthumbnail from '../../assets/uservuethumbnails.jpg'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import $ from 'jquery'
import VuePannellum from 'vue-pannellum';
import JsonExcel from "vue-json-excel";
import carousel from 'vue-owl-carousel'
import { locals } from "../../utils/locals";
import {
    Pano
} from 'vuejs-vr'
// import {
//     Carousel3d,
//     Slide
// } from 'vue-carousel-3d';
export default {
    components: {
        Preloader,
        'VPannellum': VuePannellum,
        Pano,
        // Carousel3d,
        // Slide,
        "downloadExcel": JsonExcel,
        carousel
    },
    name: "droneViewEdit",
    data() {
        return {
            preloader: false,
            projectid: 0,
            requerdRules: [
                v => !!v ||  this.lang.required,
            ],
            zoneid: 0,
            image1: image1,
            loafin: false,
            drawings: {},
            uploadDrawignaForm: false,
            drawing: {
                name: 'Select Drawing',
                drawingpath: image1
            },
            proj_dir: '',
            drawingname: '',
            floor: '',
            area: '',
            thumbnail: '',
            send: false,
            valid: true,
            alertmessage: '',
            alert: false,
            addZoneform: false,
            validzone: true,
            newzonename: '',
            currentDrawingName: "Select Drawing",
            drawingToggle: false,
            activeDrawing: {},
            cordinates: [],
            cordinatInfo: false,
            openCoordinate: {},
            x: 0,
            y: 0,
            xc: 0,
            yc: 0,
            colum: false,
            slides: [],
            activet: '',
            fulllImage: true,
            full: false,
            imagenormal: '',
            imagepanaroma: '',
            pvideo: '',
            vthumbnail: vthumbnail,
            down: false,
            selected: [],
            secondimagenormal: '',
            imagenormalcompare: false,
            secondimagepanaroma: '',
            uniquedates: {},
            commmentForm: false,
            commentsdata: false,
            commentbox: false,
            comment: '',
            userdata: {},
            taskbox: false,
            search: '',
            tasks: [],
            tasktype: {},
            headers: [{
                    text: 'Task',
                    align: 'start',
                    value: 'taskname',
                    width: 200,
                },
                {
                    text: 'Status',
                    value: 'status',
                   width: 150,
                },
                {
                    text: 'Planned Start',
                    value: 'start_date',
                    width: 150,
                },
                {
                    text: 'Planned End',
                    value: 'end_date',
                    width: 150,
                },
                {
                    text: 'Progress ',
                    value: 'progress',
                     width: 150,
                },
                {
                    text: 'Tag ',
                    value: 'tag',
                     width: 100,
                },
                {
                    text: 'Action',
                    value: 'id',
                    width: 150,
                }
            ],
            commentImage: '',
            showstanderoption: false,
            newTaskForm: false,
            newTask: {
                taskname: '',
                taskstatus: '',
                taskstartdate: '',
                taskenddate: '',
                taskprogress: 0,
                tasktag: '',
            },
            updateTask: {
                taskname: '',
                taskstatus: '',
                taskstartdate: '',
                taskenddate: '',
                taskprogress: 0,
                tasktag: '',
            },
            updateTaskForm: false,
            singleSelect: false,
            selectedtask: [],
            reportToggle: false,

            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            otaskdata: [],
            cumulative_data: [],
            cordinatedata: [],
            exceldata: {},
            json_fields: {
                Area: 'Area',
                Task: 'Task',
                Status: 'Status',
                Start: 'Start',
                End: 'End',
                Progress: 'Progress',
                Tag: 'Tag'
            },
            events: [],
            input: null,
            nonce: 0,
            commentopenImage: false,
            compatedate: '',
            compatedatefirst: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            refresh: 0,
            hfov: 75,
            yaw : 0,
            pitch  : 0,
            draggable : true,
            mouseZoom  : true,
            doubleClickZoom  : true,
            showZoom  : true,
            showFullscreen  : true,
            lock : false,
            status : ['Not started', 'In progress', 'Completed'],
            Local :locals,  
            lang : {},
        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
    },
    created() {
         var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng]; 

         this.headers = [{
                    text: this.lang.Task,
                    align: 'start',
                    value: 'taskname',
                    width: 200,
                },
                {
                    text: this.lang.Status,
                    value: 'status',
                   width: 150,
                },
                {
                    text: this.lang.PlannedStart,
                    value: 'start_date',
                    width: 150,
                },
                {
                    text: this.lang.PlannedEnd,
                    value: 'end_date',
                    width: 150,
                },
                {
                    text: this.lang.Progress,
                    value: 'progress',
                     width: 150,
                },
                {
                    text: this.lang.Tag,
                    value: 'tag',
                     width: 100,
                },
                {
                    text: this.lang.Action,
                    value: 'id',
                    width: 150,
                }
            ]
     


         var _self  = this;
         setTimeout(() => {
        _self.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (_self.userdata.user_code == 2 || _self.userdata.user_code == 0) {
            _self.useraccess = true
        }
        let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        //console.log("Current data get",this.$store.state.currentCamera)
          if(currentProject === null){
                  _self.$router.go();
          }
            console.log("projectdata==>", currentProject === null);
        _self.cam_prefix = _self.$store.state.currentCamera.camera_prefix;
        _self.projectid = currentProject.id;
        _self.proj_dir = currentProject.pro_dir;
        _self.zoneid = _self.$store.state.currentZone;
        _self.initViewer();
        _self.getDrawings();
       
        document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                _self.cordinatInfo = false;
            }
        })
         },500)
    },
    watch: {
        '$store.state.currentZone': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.proj_dir = currentProject.pro_dir;
            this.getDrawings();
        },
        '$store.state.reportToggle': function () {

            this.reportToggle = this.$store.state.reportToggle;
            console.log(this.reportToggle);

            this.dataExcel();

        },
        '$store.state.drawingToggle': function () {

            this.drawingToggle = this.$store.state.drawingToggle;
            console.log(this.drawingToggle);

        }
    },
    computed: {
        timeline() {
            return this.events.slice().reverse()
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false;

            // this.getcoordinates();
        },
        getDrawings() {
            axios({
                    url: '/dron/project/' + this.projectid + '/get-drawings',
                    method: 'get',
                    params: {
                        zone: this.zoneid
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.drawings = resp.data.success;
                    console.log("dfasfasdf", resp.data)

                    if (resp.data.success.length > 0) {

                        this.getDrawing(resp.data.success[0].drawingid);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = "Something is went wrong please try again.";
                    this.alertType = "error"
                    this.preloader = false
                })
        },

        getDrawing(id) {
            this.preloader = true;
            this.cordinatInfo = false
            this.commentbox = false
            axios({
                    url: '/dron/get-drawingbyid',
                    method: 'get',
                    params: {
                        did: id
                    }
                })
                .then(resp => {

                    console.log("Drawing DTA", resp);
                    this.drawing = resp.data.success[0];
                    this.getcoordinates(resp.data.success[0].drawingid);

                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getNormalImages(t) {
            this.preloader = true;
            this.down = false;
            this.commentbox = false;
            console.log("Normal Images");
            this.refresh++;
            this.slides = [];
            this.selected = [];
            axios({
                    url: '/dron/get-media',
                    method: 'get',
                    params: {
                        coordi: this.openCoordinate.coordinateid,
                        type: t
                    }
                })
                .then(resp => {
                    console.log("Normal Images", resp.data.success);

                    this.activet = t;

                    this.slides = resp.data.success

                    this.saved = resp.data.success

                    this.filterdate(resp.data.success)
                    this.preloader = false

                }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage =  this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getcommentImages(url) {
            this.colum = true;
            this.imagepanaroma = url;
            this.activet = 'panaroma';
        },
        goToDrawing(n) {
            this.currentDrawingName = n.name;

            this.activeDrawing = n;

            this.getDrawing(n.drawingid);

            this.drawingToggle = false;

            this.$store.dispatch('openDrawing', false);
            this.$store.dispatch('drawingname', n.name);

        },
        submitZoneform() {
            axios({
                    url: '/dron/project/' + this.projectid + '/add-zone',
                    method: 'get',
                    params: {
                        zonename: this.newzonename
                    }
                })
                .then(() => {
                    this.alert = true;
                    this.alertmessage = this.lang.addzone;
                    this.addZoneform = false;
                    this.$router.go();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage =  this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        drawingMenuToggle() {

            this.drawingToggle = !this.drawingToggle;
        },
        downUp() {
            this.down = !this.down;

        },

        openTooltip(n) {

            if (n.cateone == "360 Videos Walk-through") {
                this.openCoordinate = n;
                this.cordinatInfo = false;
                this.commentbox = false;
                this.getNormalImages('video');

            } else {

                //console.log("n",n);

                this.cordinatInfo = false;
                var _self = this;
                setTimeout(() => {
                    _self.cordinatInfo = true;

                }, 150);

                setTimeout(() => {

                    _self.x = n.x;
                    _self.y = n.y;

                    _self.xc = n.x + 100;
                    _self.yc = n.y;
                }, 50);

                console.log(n);
                this.openCoordinate = n;
            }
        },
        fullscreen() {
            this.full = true;

            //console.log("hfghf", this.full);
        },
        fullscreenexit() {
            this.full = false;
            this.fulllImage = false;
            this.colum = false;
            //console.log("hfghf", this.full);
        },
        getcoordinates(id) {
            axios({
                    url: '/dron/getcoordinates',
                    method: 'get',
                    params: {
                        did: id
                    }
                })
                .then(resp => {
                    console.log("headings", resp);

                    this.cordinates = resp.data.filter(function (item) {

                        if (item.cateone == "360 Videos Walk-through") {

                            item.fillcolor = '{"fillColor":"ff3131"}';

                        } else {
                            item.fillcolor = '{"fillColor":"fdc004"}';
                        }

                        return 1 == 1;

                    });

                    console.log(this.cordinates);

                    this.coorddinates();

                    var _self = this;
                    setTimeout(() => {
                        _self.coorddinates();
                        $('.map').maphilight({
                            strokeColor: "000000",
                            strokeWidth: 2,
                            stroke: true,
                            fillOpacity: 0.9,
                            alwaysOn: true
                        });
                        _self.preloader = false;
                    }, 1000);

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        openComment_image(url) {
            this.secondimagenormal = url;
            this.commentopenImage = true;
        },
        onSelect(a) {
            // var a = this.$refs.carousel.currentIndex;
            //  this.image = this.slides[a].mediapath;
            // console.log(this.image);

            this.imagenormalcompare = false;
            this.selected = [];
            this.colum = true;
            //  this.down = !this.down;
            if (this.activet == 'normal') {
                this.imagenormal = this.slides[a].mediapath;

                this.compatedatefirst = this.slides[a].uploadat;

            }
            if (this.activet == 'panaroma') {
                this.imagepanaroma = this.slides[a].mediapath;
                this.compatedatefirst = this.slides[a].uploadat;
            }
            if (this.activet == 'video') {

                console.log("this.slides[a]", this.slides[a]);
                this.compatedatefirst = this.slides[a].uploadat;

                this.pvideo = this.slides[a].mediapath;

            }
        },
        oncompare(event, a) {

            // var a = this.$refs.carousel.currentIndex;
            //  this.image = this.slides[a].mediapath;
            // console.log(this.image);
            if (event.target.checked) {

                //  this.selected.push(a);
                var _this = this;
                setTimeout(() => {

                    console.log(_this.selected);

                    console.log(this.selected.length);
                    if (this.selected.length > 2) {
                        this.selected.shift();
                    }

                    if (this.selected.length > 1) {

                        this.colum = true;
                        this.imagenormalcompare = true;
                        if (this.activet == 'normal') {

                            this.imagenormal = this.slides[this.selected[this.selected.length - 2]].mediapath;

                            this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;

                            this.secondimagenormal = this.slides[this.selected[this.selected.length - 1]].mediapath;
                            this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;

                        }
                    }

                    if (this.selected.length > 1) {
                        this.colum = true;
                        if (this.activet == 'panaroma') {

                            this.imagepanaroma = this.slides[this.selected[this.selected.length - 2]].mediapath;
                            this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;

                            //  console.log(this.selected.length);

                            this.secondimagepanaroma = this.slides[this.selected[this.selected.length - 1]].mediapath;
                            this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
                            this.imagenormalcompare = true;
                        }
                    }
                    if (this.activet == 'video') {
                        this.colum = true;
                        console.log("this.slides[a]", this.slides[a]);
                        this.compatedatefirst = this.slides[a].uploadat;

                        this.pvideo = this.slides[a].mediapath;

                    }
                }, 500)
            }
        },
        closeCompare() {
            this.selected = [];
            this.imagenormalcompare = false;
        },
        filterdate(m) {

            var a = ['All']

            m.forEach(i => {
                a.push(i.uploadat);
            });

            let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
            console.log(unique);

            this.uniquedates = unique.sort(function (a, b) {
                var dateA = new Date(a),
                    dateB = new Date(b)
                return dateB - dateA //sort by date ascending
            })
        },
        selectDate(d) {

            this.preloader = true;

            console.log(d);
            this.refresh++;
            var _self = this;

            if (d == 'All') {
                console.log(d);
                _self.slides = [];
                setTimeout(() => {
                    _self.slides = _self.saved;
                    _self.preloader = false;
                }, 500)

            } else {
                _self.slides = [];
                setTimeout(() => {
                    _self.slides = _self.saved.filter(function (item) {
                        return item.uploadat == d;
                    }, 500)
                    _self.preloader = false;
                });
            }
        },
        panUp(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() + 20);
             b.viewer.setPitch(b.viewer.getPitch() + 20);   
        },

        panDown(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() - 20);
             b.viewer.setPitch(b.viewer.getPitch() - 20);   
        },
        panLeft(){
        var a = this.$refs.panaromascreen;
        var b = this.$refs.panaromascreen2;

            a.viewer.setYaw(a.viewer.getYaw() - 20);
            b.viewer.setYaw(b.viewer.getYaw() - 20);   
        },
        panRight(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setYaw(a.viewer.getYaw() + 20);
             b.viewer.setYaw(b.viewer.getYaw() + 20);   
        },
        panIn(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() - 20);
             b.viewer.setHfov(b.viewer.getHfov() - 20);   
        },
        panOut(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() + 20);
             b.viewer.setHfov(b.viewer.getHfov() + 20);   
        },
        addComment() {

            var a = this.$refs.panaromascreen;

            console.log("refrence", a);

            var img = a.viewer.getRenderer().render(a.viewer.getPitch() / 180 * Math.PI,
                a.viewer.getYaw() / 180 * Math.PI,
                a.viewer.getHfov() / 180 * Math.PI, {
                    'returnImage': true
                });
            console.log(img);

            this.commentImage = img;

            this.commmentForm = true;
        },
        meckReaded(comment) {

            console.log(comment);

            var status;
            if (comment.comment_status == 1) {
                status = 0;
            } else {
                status = 1;
            }

            console.log(status);

            axios({
                    url: '/dron/commnent_update',
                    method: 'get',
                    params: {
                        comment_id: comment.cmt_id,
                        status: status
                    }
                })
                .then(resp => {
                    console.log("comments", resp);
                    // this.commentsdata = resp.data.success;
                    // this.commentbox = true;
                    this.getCommnets();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getCommnets() {

            axios({
                    url: '/dron/get-commnent',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid
                    }
                })
                .then(resp => {
                    console.log("comments", resp);
                    this.commentsdata = resp.data.success;
                    this.commentbox = true;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        sendComment() {
            const url1 = this.imagepanaroma.split("?");
            console.log(url1);
            axios({
                    url: '/dron/send-commnent',
                    method: 'post',
                    data: {
                        proj_dir: this.proj_dir,
                        project_id: this.projectid,
                        userid: this.userdata.id,
                        zoneid: this.zoneid,
                        commnettext: this.comment,
                        commentimg: this.commentImage,
                        coordinatid: this.openCoordinate.coordinateid,
                        realimage: url1[0],
                        sender_name: this.userdata.firstname+' '+this.userdata.lastname,
                    }
                })
                .then(() => {
                    // console.log("comments", resp);
                    this.alert = true;
                    this.alertmessage = this.lang.addcommnetssucess;
                    this.alertType = "success"
                    this.commmentForm = false;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        taskboxopen() {
            this.taskbox = true;
            this.getListItemss();
        },
        getStanderType() {
            axios({
                    url: '/dron/get-stander-type',
                    method: 'get'
                })
                .then(resp => {

                    console.log(resp);

                    this.tasktype = resp.data.success;

                    this.showstanderoption = true;

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        addnewItem() {
            console.log('ok');
        },
        selectTlistItem(d) {
            axios({
                    url: '/dron/get-stander-list',
                    method: 'get',
                    params: {
                        type: d,
                        coordinatid: this.openCoordinate.coordinateid,
                        zoneid: this.zoneid,
                        drawingid: this.activeDrawing.drawingid,
                        userid: this.userdata.id,
                    }
                })
                .then(resp => {
                    console.log(resp);
                    this.getListItemss();

                    var _self = this;
                    setTimeout(() => {
                        var c = confirm(this.lang.listconfirmation)

                        if (c) {
                            _self.alert = true;
                            _self.alertmessage = "Standard task list loaded";
                            _self.alertType = "error"
                             _self.showstanderoption = false
                        } else {
                            _self.removeTaskLoaded();
                        }

                    }, 1000);

                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        getListItemss() {
            this.preloader = true;
            axios({
                    url: '/dron/get-list',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                    }
                })
                .then(resp => {
                    console.log("table data", resp.data.success);

                    this.tasks = resp.data.success;
                    this.preloader = false;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        removeTaskLoaded() {
            axios({
                    url: '/dron/remove-task',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);

                    this.getListItemss()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        sendTask() {
            this.preloader = false
            //console.log("this.newTask");
            axios({
                    url: '/dron/add-task',
                    method: 'get',
                    params: {
                        coordinatid: this.openCoordinate.coordinateid,
                        taskname: this.newTask.taskname,
                        taskStatus: this.newTask.taskstatus,
                        startdate: this.newTask.taskstartdate,
                        enddate: this.newTask.taskenddate,
                        prograss: this.newTask.taskprogress,
                        tag: this.newTask.tasktag,
                        taskchecked: 0,
                        userid: this.userdata.id,
                        zoneid: this.zoneid,
                        drawingid: this.activeDrawing.drawingid,
                        
                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);
                    this.newTaskForm = false;
                    this.getListItemss()
                    this.alert = true;
                    this.alertmessage = this.lang.newmsg;
                    this.alertType = "success"
                    this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        editTask(d) {

            this.updateTaskForm = true
            this.updateTask = {
                taskname: d.taskname,
                taskstatus: d.status,
                taskstartdate: d.start_date,
                taskenddate: d.end_date,
                taskprogress: d.progress,
                tasktag: d.tag,
                taskid: d.taskid
            }
        },
        sendupdateTask() {
            this.preloader = true
            //console.log("this.newTask");
            axios({
                    url: '/dron/update-task',
                    method: 'get',
                    params: {

                        taskname: this.updateTask.taskname,
                        taskStatus: this.updateTask.taskstatus,
                        startdate: this.updateTask.taskstartdate,
                        enddate: this.updateTask.taskenddate,
                        prograss: this.updateTask.taskprogress,
                        tag: this.updateTask.tasktag,
                        taskchecked: 0,
                        userid: this.userdata.id,
                        taskid: this.updateTask.taskid

                    }
                })
                .then(resp => {
                    console.log("Remove data", resp.data.success);
                    this.updateTaskForm = false;
                    this.getListItemss()
                    this.alert = true;
                    this.alertmessage = this.lang.tasksuccess;
                    this.alertType = "success"
                    this.preloader = false
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        deleteTask(id) {
            var s = confirm(this.lang.sureremove);
            if (s) {
                this.preloader = true
                //console.log("this.newTask");
                axios({
                        url: '/dron/delete-task',
                        method: 'get',
                        params: {
                            taskid: id

                        }
                    })
                    .then(resp => {
                        console.log("Remove data", resp.data.success);
                        this.updateTaskForm = false;
                        this.getListItemss()
                        this.alert = true;
                        this.alertmessage = "Task Deleted Succssfully.";
                        this.alertType = "success"
                        this.preloader = false
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }
        },
        removeall() {
            var s = confirm(this.lang.removetask);

            if (s) {
                this.preloader = true

                var ids = [];

                this.selectedtask.forEach(a => {
                    ids.push(a.taskid);
                });

                axios({
                        url: '/dron/delete-selected-task',
                        method: 'get',
                        params: {
                            taskids: ids

                        }
                    })
                    .then(resp => {
                        console.log("Remove data", resp.data.success);
                        this.updateTaskForm = false;
                        this.getListItemss()
                        this.alert = true;
                        this.alertmessage = this.lang.taskDelete;
                        this.alertType = "success"
                        this.preloader = false
                        this.selectedtask =[];
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }
        },

        getColumns() {
            return [{
                    title: "Task",
                    dataKey: "Task"
                },
                {
                    title: "Status",
                    dataKey: "Status"
                },
                {
                    title: "Start",
                    dataKey: "Start"
                },
                {
                    title: "End",
                    dataKey: "End"
                },
                {
                    title: "Progress",
                    dataKey: "Progress"
                },
                {
                    title: "Tag",
                    dataKey: "Tag"
                }
            ]
        },

        DownloadOverallPDF() {

            // var downloadfile = this.drawing.name;

            var _self = this;
            this.dataPDF();

            setTimeout(() => {
                var doc = new jsPDF('p', 'mm', 'a4');

                doc.autoTable({
                    html: '#cumulativeReportDownload',
                    startY: 15,
                    theme: 'grid',

                    allSectionHooks: true,
                    didParseCell: function (data) {

                        if (_self.cordinatedata.includes(data.row.raw[0].content)) {
                            data.cell.styles.fillColor = [284, 187, 3];
                            data.cell.styles.font = 'times';
                            data.cell.styles.halign = 'center';
                            data.cell.styles.fontSize = '14';
                        }
                    }
                })
                // doc.addPage("a2", "portrait")
                doc.save(_self.drawing.name + '.pdf')
            }, 1500);

            this.$store.dispatch('openReport', false)

        },
        dataPDF() {

            var tdata = [];
            this.cordinatedata = [];
            this.cordinates.forEach(item => {
                axios({
                        url: '/dron/get-list',
                        method: 'get',
                        params: {
                            coordinatid: item.coordinateid,
                        }
                    })
                    .then(resp => {
                        // console.log("table data", item);

                        this.cordinatedata.push(item.title);

                        tdata.push({
                            title: item.title
                        });

                        resp.data.success.forEach(iv => {
                            tdata.push({
                                Task: iv.taskname,
                                Status: iv.status,
                                Start: iv.start_date,
                                End: iv.end_date,
                                Progress: iv.progress,
                                Tag: iv.tag
                            });
                        });
                    })
            })
            this.cumulative_data = tdata;

            // console.log("cordinatedata",cordinatedata);

        },
        dataExcel() {

            var tdata = [];

            this.cordinates.forEach(item => {
                axios({
                        url: '/dron/get-list',
                        method: 'get',
                        params: {
                            coordinatid: item.coordinateid,
                        }
                    })
                    .then(resp => {
                        // console.log("table data", item);
                        tdata.push({
                            Area: item.title,
                            Task: '',
                            Status: '',
                            Start: '',
                            End: '',
                            Progress: '',
                            Tag: ''
                        });

                        resp.data.success.forEach(iv => {
                            tdata.push({
                                Area: '',
                                Task: iv.taskname,
                                Status: iv.status,
                                Start: iv.start_date,
                                End: iv.end_date,
                                Progress: iv.progress,
                                Tag: iv.tag
                            });
                        });
                    })
            })
            this.exceldata = tdata;

            // console.log("cordinatedata",cordinatedata);

        },
        finishDownload() {
            this.preloader = false
            this.reportToggle = false
            this.$store.dispatch('openReport', false)
        },
        clsoeReport() {
            this.reportToggle = false
            this.$store.dispatch('openReport', false)
        },
        closeMegamenu() {
            this.$store.dispatch('openDrawing', false)
        },
        dateFormate(d) {

            var date = d.split(' ');
            return date[0];
        },
        substring(str){

              var text = str;
            if(str.length > 50){
                
               text =  str.substr(1, 80) + "...";
            }

            return text;
             
        },
        lockScreens(){ 

                 console.log("Lock Screen");

                  var a = this.$refs.panaromascreen;

                  console.log("Viewer",a);
                  
                 
                 this.lock = !this.lock;  
            //     this.hfov = 75;
            //     this.yaw = 0;
            //     this.pitch  = 0;
            //  this.draggable = false;
            //  this.mouseZoom  = false;
            //  this.doubleClickZoom = false;
            //  this.showZoom = false;
            //  this.showFullscreen  = false;


            // if(this.lock){

            //  a.showControls = false

            // }

            // this.imagepanaroma = this.slides[this.selected[this.selected.length - 2]].mediapath;
            // this.compatedatefirst = this.slides[this.selected[this.selected.length - 2]].uploadat;

            // //  console.log(this.selected.length);

            // this.secondimagepanaroma = this.slides[this.selected[this.selected.length - 1]].mediapath;
            // this.compatedate = this.slides[this.selected[this.selected.length - 1]].uploadat;
                           

        }
        //  ---------------------------------------------------------
        ,
        coorddinates() {
            var has_VML, has_canvas, create_canvas_for, add_shape_to, clear_canvas, shape_from_area,
                canvas_style, hex_to_decimal, css3color, is_image_loaded, options_from_area;

            has_canvas = !!document.createElement('canvas').getContext;

            // VML: more complex
            has_VML = (function () {
                var a = document.createElement('div');
                a.innerHTML = '<v:shape id="vml_flag1" adj="1" />';
                var b = a.firstChild;
                b.style.behavior = "url(#default#VML)";
                return b ? typeof b.adj == "object" : true;
            })();

            if (!(has_canvas || has_VML)) {
                $.fn.maphilight = function () {
                    return this;
                };
                return;
            }

            if (has_canvas) {
                hex_to_decimal = function (hex) {
                    return Math.max(0, Math.min(parseInt(hex, 16), 255));
                };
                css3color = function (color, opacity) {
                    return 'rgba(' + hex_to_decimal(color.substr(0, 2)) + ',' + hex_to_decimal(color.substr(2, 2)) + ',' + hex_to_decimal(color.substr(4, 2)) + ',' + opacity + ')';
                };
                create_canvas_for = function (img) {
                    var c = $('<canvas style="width:' + $(img).width() + 'px;height:' + $(img).height() + 'px;"></canvas>').get(0);
                    c.getContext("2d").clearRect(0, 0, $(img).width(), $(img).height());
                    return c;
                };
                var draw_shape = function (context, shape, coords, x_shift, y_shift) {
                    x_shift = x_shift || 0;
                    y_shift = y_shift || 0;

                    context.beginPath();
                    if (shape == 'rect') {
                        // x, y, width, height
                        context.rect(coords[0] + x_shift, coords[1] + y_shift, coords[2] - coords[0], coords[3] - coords[1]);
                    } else if (shape == 'poly') {
                        context.moveTo(coords[0] + x_shift, coords[1] + y_shift);
                        for (var i = 2; i < coords.length; i += 2) {
                            context.lineTo(coords[i] + x_shift, coords[i + 1] + y_shift);
                        }
                    } else if (shape == 'circ') {
                        // x, y, radius, startAngle, endAngle, anticlockwise
                        context.arc(coords[0] + x_shift, coords[1] + y_shift, coords[2], 0, Math.PI * 2, false);
                    }
                    context.closePath();
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var i, context = canvas.getContext('2d');

                    console.log(name, i);

                    // Because I don't want to worry about setting things back to a base state

                    // Shadow has to happen first, since it's on the bottom, and it does some clip /
                    // fill operations which would interfere with what comes next.
                    if (options.shadow) {
                        context.save();
                        if (options.shadowPosition == "inside") {
                            // Cause the following stroke to only apply to the inside of the path
                            draw_shape(context, shape, coords);
                            context.clip();
                        }

                        // Redraw the shape shifted off the canvas massively so we can cast a shadow
                        // onto the canvas without having to worry about the stroke or fill (which
                        // cannot have 0 opacity or width, since they're what cast the shadow).
                        var x_shift = canvas.width * 100;
                        var y_shift = canvas.height * 100;
                        draw_shape(context, shape, coords, x_shift, y_shift);

                        context.shadowOffsetX = options.shadowX - x_shift;
                        context.shadowOffsetY = options.shadowY - y_shift;
                        context.shadowBlur = options.shadowRadius;
                        context.shadowColor = css3color(options.shadowColor, options.shadowOpacity);

                        // Now, work out where to cast the shadow from! It looks better if it's cast
                        // from a fill when it's an outside shadow or a stroke when it's an interior
                        // shadow. Allow the user to override this if they need to.
                        var shadowFrom = options.shadowFrom;
                        if (!shadowFrom) {
                            if (options.shadowPosition == 'outside') {
                                shadowFrom = 'fill';
                            } else {
                                shadowFrom = 'stroke';
                            }
                        }
                        if (shadowFrom == 'stroke') {
                            context.strokeStyle = "rgba(0,0,0,1)";
                            context.stroke();
                        } else if (shadowFrom == 'fill') {
                            context.fillStyle = "rgba(0,0,0,1)";
                            context.fill();
                        }
                        context.restore();

                        // and now we clean up
                        if (options.shadowPosition == "outside") {
                            context.save();
                            // Clear out the center
                            draw_shape(context, shape, coords);
                            context.globalCompositeOperation = "destination-out";
                            context.fillStyle = "rgba(0,0,0,1);";
                            context.fill();
                            context.restore();
                        }
                    }

                    context.save();

                    draw_shape(context, shape, coords);

                    // fill has to come after shadow, otherwise the shadow will be drawn over the fill,
                    // which mostly looks weird when the shadow has a high opacity
                    if (options.fill) {
                        context.fillStyle = css3color(options.fillColor, options.fillOpacity);
                        context.fill();
                    }
                    // Likewise, stroke has to come at the very end, or it'll wind up under bits of the
                    // shadow or the shadow-background if it's present.
                    if (options.stroke) {
                        context.strokeStyle = css3color(options.strokeColor, options.strokeOpacity);
                        context.lineWidth = options.strokeWidth;
                        context.stroke();
                    }

                    context.restore();

                    if (options.fade) {
                        $(canvas).css('opacity', 0).animate({
                            opacity: 1
                        }, 100);
                    }
                };
                clear_canvas = function (canvas) {
                    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                };
            } else { // ie executes this code
                create_canvas_for = function (img) {
                    return $('<var style="zoom:1;overflow:hidden;display:block;width:' + img.width + 'px;height:' + img.height + 'px;"></var>').get(0);
                };
                add_shape_to = function (canvas, shape, coords, options, name) {
                    var fill, stroke, opacity, e;
                    for (var i in coords) {
                        coords[i] = parseInt(coords[i], 10);
                    }
                    fill = '<v:fill color="#' + options.fillColor + '" opacity="' + (options.fill ? options.fillOpacity : 0) + '" />';
                    stroke = (options.stroke ? 'strokeweight="' + options.strokeWidth + '" stroked="t" strokecolor="#' + options.strokeColor + '"' : 'stroked="f"');
                    opacity = '<v:stroke opacity="' + options.strokeOpacity + '"/>';
                    if (shape == 'rect') {
                        e = $('<v:rect name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + coords[0] + 'px;top:' + coords[1] + 'px;width:' + (coords[2] - coords[0]) + 'px;height:' + (coords[3] - coords[1]) + 'px;"></v:rect>');
                    } else if (shape == 'poly') {
                        e = $('<v:shape name="' + name + '" filled="t" ' + stroke + ' coordorigin="0,0" coordsize="' + canvas.width + ',' + canvas.height + '" path="m ' + coords[0] + ',' + coords[1] + ' l ' + coords.join(',') + ' x e" style="zoom:1;margin:0;padding:0;display:block;position:absolute;top:0px;left:0px;width:' + canvas.width + 'px;height:' + canvas.height + 'px;"></v:shape>');
                    } else if (shape == 'circ') {
                        e = $('<v:oval name="' + name + '" filled="t" ' + stroke + ' style="zoom:1;margin:0;padding:0;display:block;position:absolute;left:' + (coords[0] - coords[2]) + 'px;top:' + (coords[1] - coords[2]) + 'px;width:' + (coords[2] * 2) + 'px;height:' + (coords[2] * 2) + 'px;"></v:oval>');
                    }
                    e.get(0).innerHTML = fill + opacity;
                    $(canvas).append(e);
                };
                clear_canvas = function (canvas) {
                    // jquery1.8 + ie7
                    var $html = $("<div>" + canvas.innerHTML + "</div>");
                    $html.children('[name=highlighted]').remove();
                    $(canvas).html($html.html());
                };
            }

            shape_from_area = function (area) {
                var i, coords,
                    shape = (area.getAttribute('shape') || 'rect').toLowerCase().substr(0, 4);
                if (shape === 'defa') {
                    // 'default' doesn't really apply to what we're doing; it's the background state
                    return;
                }
                coords = (area.getAttribute('coords') || '').split(',');
                for (i = 0; i < coords.length; i++) {
                    coords[i] = parseFloat(coords[i]);
                }
                return [shape, coords];
            };

            options_from_area = function (area, options) {
                var $area = $(area);
                return $.extend({}, options, $.metadata ? $area.metadata() : false, $area.data('maphilight'));
            };

            is_image_loaded = function (img) {
                if (!img.complete) {
                    return false;
                } // IE
                if (typeof img.naturalWidth != "undefined" && img.naturalWidth === 0) {
                    return false;
                } // Others
                return true;
            };

            canvas_style = {
                position: 'absolute',
                left: 0,
                top: 0,
                padding: 0,
                border: 0
            };

            var ie_hax_done = false;
            $.fn.maphilight = function (opts) {
                opts = $.extend({}, $.fn.maphilight.defaults, opts);

                if (!has_canvas && !ie_hax_done) {
                    $(window).ready(function () {
                        document.namespaces.add("v", "urn:schemas-microsoft-com:vml");
                        var style = document.createStyleSheet();
                        var shapes = ['shape', 'rect', 'oval', 'circ', 'fill', 'stroke', 'imagedata', 'group', 'textbox'];
                        $.each(shapes,
                            function () {
                                style.addRule('v\\:' + this, "behavior: url(#default#VML); antialias:true");
                            }
                        );
                    });
                    ie_hax_done = true;
                }

                return this.each(function () {
                    var img, wrap, options, map, canvas, canvas_always, usemap;
                    img = $(this);

                    //  console.log(highlighted_shape,imgSrc);

                    if (!is_image_loaded(this)) {
                        // If the image isn't fully loaded, this won't work right.  Try again later.
                        return window.setTimeout(function () {
                            img.maphilight(opts);
                        }, 200);
                    }

                    options = $.extend({}, opts, $.metadata ? img.metadata() : false, img.data('maphilight'));

                    // jQuery bug with Opera, results in full-url#usemap being returned from jQuery's attr.
                    // So use raw getAttribute instead.
                    usemap = img.get(0).getAttribute('usemap');

                    if (!usemap) {
                        return;
                    }

                    map = $('map[name="' + usemap.substr(1) + '"]');

                    if (!(img.is('img,input[type="image"]') && usemap && map.length > 0)) {
                        return;
                    }

                    if (img.hasClass('maphilighted')) {
                        // We're redrawing an old map, probably to pick up changes to the options.
                        // Just clear out all the old stuff.
                        var wrapper = img.parent();
                        img.insertBefore(wrapper);
                        wrapper.remove();
                        $(map).unbind('.maphilight');
                    }

                    //Formating the image source. IE > 9 has issue with new line characters
                    var imgSrc = this.src.replace(/[\n\r]/g, '');
                    console.log(imgSrc);
                    wrap = $('<div></div>').css({
                        display: 'block',
                        background: 'url("' + this.src + '")',
                        "background-size": 'contain',
                        "background-repeat": 'no-repeat',
                        position: 'relative',
                        padding: 0,
                        width: this.width,
                        height: this.height
                    });
                    if (options.wrapClass) {
                        if (options.wrapClass === true) {
                            wrap.addClass($(this).attr('class'));
                        } else {
                            wrap.addClass(options.wrapClass);
                        }
                    }
                    // Firefox has a bug that prevents tabbing into the image map if
                    // we set opacity of the image to 0, but very nearly 0 works!
                    img.before(wrap).css('opacity', 0.0000000001).css(canvas_style).remove();
                    if (has_VML) {
                        img.css('filter', 'Alpha(opacity=0)');
                    }
                    wrap.append(img);

                    canvas = create_canvas_for(this);
                    $(canvas).css(canvas_style);
                    canvas.height = this.height;
                    canvas.width = this.width;

                    $(map).bind('alwaysOn.maphilight', function () {
                            // Check for areas with alwaysOn set. These are added to a *second* canvas,
                            // which will get around flickering during fading.
                            if (canvas_always) {
                                clear_canvas(canvas_always);
                            }
                            if (!has_canvas) {
                                $(canvas).empty();
                            }
                            $(map).find('area[coords]').each(function () {
                                var shape, area_options;
                                area_options = options_from_area(this, options);
                                if (area_options.alwaysOn) {
                                    if (!canvas_always && has_canvas) {
                                        canvas_always = create_canvas_for(img[0]);
                                        $(canvas_always).css(canvas_style);
                                        canvas_always.width = img[0].width;
                                        canvas_always.height = img[0].height;
                                        img.before(canvas_always);
                                    }
                                    area_options.fade = area_options.alwaysOnFade; // alwaysOn shouldn't fade in initially
                                    shape = shape_from_area(this);
                                    if (!shape) {
                                        return;
                                    }
                                    if (has_canvas) {
                                        add_shape_to(canvas_always, shape[0], shape[1], area_options, "");
                                    } else {
                                        add_shape_to(canvas, shape[0], shape[1], area_options, "");
                                    }
                                }
                            });
                        }).trigger('alwaysOn.maphilight')
                        .bind('mouseover.maphilight focusin.maphilight', function (e) {
                            console.log(e);
                            var shape, area_options, area = e.target;
                            area_options = options_from_area(area, options);
                            if (!area_options.neverOn && !area_options.alwaysOn) {
                                shape = shape_from_area(area);
                                if (!shape) {
                                    return;
                                }
                                add_shape_to(canvas, shape[0], shape[1], area_options, "highlighted");
                                if (area_options.groupBy) {
                                    if (typeof area_options.groupBy == 'string') {
                                        area_options.groupBy = [area_options.groupBy];
                                    }
                                    var el = $(this);
                                    $.each(area_options.groupBy, function (index, groupitem) {
                                        var areas;
                                        // two ways groupBy might work; attribute and selector
                                        if (/^[a-zA-Z][a-zA-Z]+$/.test(groupitem)) {
                                            areas = map.find('area[' + groupitem + '="' + el.attr(groupitem) + '"]');
                                        } else {
                                            areas = map.find(groupitem);
                                        }
                                        var first = this;
                                        areas.each(function () {
                                            if (this != first) {
                                                var subarea_options = options_from_area(this, options);
                                                if (!subarea_options.neverOn && !subarea_options.alwaysOn) {
                                                    var shape = shape_from_area(this);
                                                    add_shape_to(canvas, shape[0], shape[1], subarea_options, "highlighted");
                                                }
                                            }
                                        });
                                    });
                                }
                                // workaround for IE7, IE8 not rendering the final rectangle in a group
                                if (!has_canvas) {
                                    $(canvas).append('<v:rect></v:rect>');
                                }
                            }
                        }).bind('mouseout.maphilight focusout.maphilight', function (e) {
                            console.log(e);
                            clear_canvas(canvas);
                        });

                    img.before(canvas); // if we put this after, the mouseover events wouldn't fire.

                    img.addClass('maphilighted');
                });
            };
            $.fn.maphilight.defaults = {
                fill: true,
                fillColor: 'fdc004',
                fillOpacity: 0.2,
                stroke: true,
                strokeColor: '000000',
                strokeOpacity: 1,
                strokeWidth: 1,
                fade: true,
                alwaysOn: true,
                neverOn: false,
                groupBy: false,
                wrapClass: true,
                // plenty of shadow:
                shadow: true,
                shadowX: 0,
                shadowY: 0,
                shadowRadius: 6,
                shadowColor: '000000',
                shadowOpacity: 0.8,
                shadowPosition: 'outside',
                shadowFrom: false
            }
        }

    }

};
</script>

<style>
</style>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
var FormData = require("form-data");

// import qs from "qs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: "070101",
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    currentCamera: {},
    currentZone: "",
    ArchiveImage: "",
    pcToken: "",
    pcrefreshToken: "",
    currentModule: "constructionCamera",
    zone: "",
    reportToggle: false,
    reportToggle1: false,
    reportToggle2: false,
    archiveToggle: false,
    drawingToggle: false,
    currentDrawing: "select Drawing",
    currentvisitId: ""
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    camsetup(state, cam) {
      state.currentCamera = cam;
    },
    zonesetup(state, zone) {
      state.currentZone = zone;
    },
    visitdatesetup(state, id) {
      state.currentvisitId = id;
    },
    openreport(state, open) {
      state.reportToggle = open;
    },
    openreport1(state, open) {
      console.log("navbar checking openreport1");
      state.reportToggle1 = open;
    },
    openreport2(state, open) {
      console.log("navbar checking openreport2");
      state.reportToggle2 = open;
    },
    openarchive(state, open) {
      state.archiveToggle = open;
    },
    openDrawing(state, open) {
      state.drawingToggle = open;
    },
    drawingname(state, drawingname) {
      state.currentDrawing = drawingname;
    },
    archiveimage(state, imageid) {
      state.ArchiveImage = imageid;
    },
    procoretoken(state, token) {
      state.pcToken = token;
    },
    procoreRefreshToken(state, pcrefreshToken) {
      state.pcrefreshToken = pcrefreshToken;
    },
    currentModuleSet(state, moduless) {
      state.currentModule = moduless;
    }
  },
  actions: {
    procoreRefreshToken(code, procoreRefreshToken) {
      return new Promise((resolve, reject) => {
        console.log(code);
        //console.log(procoreRefreshToken)
        var data = new FormData();
        // data.append('client_id', '5b291e76b22b638a03363b6324e41fa524422723cf8498c1657e3953714308df');
        // data.append('client_secret', 'ce0121612648bd5cc49c893f464da3d31dde71f9e8586f69861c5d896df88cfe');
        data.append("refresh_token", procoreRefreshToken);

        var config = {
          method: "POST",
          url: "http://localhost:5000/token/",
          // url: 'https://login-sandbox.procore.com/oauth/token',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + code
          },
          data: data
        };
        axios(config)
          .then(function(response) {
            // console.log(JSON.stringify(response.data));
            localStorage.setItem("pc_refreshtoken", code);
            // commit('procoretoken', code)
            resolve(response.data);
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },
    procoreToken({ commit }, procorecode) {
      return new Promise((resolve, reject) => {
        console.log(procorecode);

        axios
          .get("/procore/getProcoreTokens", {
            params: { code: procorecode }
          })
          .then(function(response) {
            // console.log("Procore access and Refresh Tokens", response);
            localStorage.setItem("pc_token", response.data.access_token);

            localStorage.setItem(
              "pc_refreshtoken",
              response.data.refresh_token
            );

            commit("procoretoken", procorecode);
            resolve(response.data);
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },
    curentModule({ commit }, module) {
      return new Promise(resolve => {
        commit("currentModuleSet", module);
        resolve(module);
      });
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({ url: "/login", data: user, method: "POST" })
          .then(resp => {
            const obj = JSON.parse(resp.data);
            const token = obj.access_token;
            //  console.log("resp.data.access_token",obj.access_token);
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success", token, "");
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    opentoken({ commit }, openToken) {
      return new Promise(resolve => {
        commit("auth_request");
        const token = openToken;
        //  console.log("resp.data.access_token",obj.access_token);
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        commit("auth_success", token, "");
        resolve(true);
      });
    },
    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({ url: "/user", method: "GET" })
          .then(resp => {
            // console.log("Get User data", resp.data.success);
            // const user = resp.data.user
            // JSON.stringify(resp.data.success);

            if (
              resp.data.success.cctvtimelapse_user == 0 &&
              resp.data.success.active == 1
            ) {
              localStorage.setItem(
                "userdata",
                JSON.stringify(resp.data.success)
              );
              /* if (resp.data.success.User2F_auth == 0) {
                  var token =  localStorage.getItem('token');
                  commit('auth_success', token, '') 
                 } else{
                  commit('auth_error')
                 } */
              resolve(resp);
            } else {
              commit("auth_error");
              localStorage.removeItem("token");
              reject("You are not authorised user.");
            }
          })
          .catch(err => {
            console.log("tis error ", err);
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        commit("auth_error");
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        localStorage.clear();
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    setCamera({ commit }, camdata) {
      return new Promise(resolve => {
        commit("camsetup", camdata);
        resolve(camdata);
      });
    },
    setZone({ commit }, zone) {
      return new Promise(resolve => {
        commit("zonesetup", zone);
        resolve(zone);
      });
    },
    setVistId({ commit }, id) {
      return new Promise(resolve => {
        commit("visitdatesetup", id);
        resolve(id);
      });
    },
    openReport({ commit }, zone) {
      return new Promise(resolve => {
        commit("openreport", zone);
        resolve(zone);
      });
    },
    openReport1({ commit }, zone) {
      return new Promise(resolve => {
        commit("openreport1", zone);
        resolve(zone);
      });
    },
    openReport2({ commit }, zone) {
      return new Promise(resolve => {
        commit("openreport2", zone);
        resolve(zone);
      });
    },
    openarchive({ commit }, open) {
      return new Promise(resolve => {
        commit("openarchive", open);
        resolve(open);
      });
    },
    openDrawing({ commit }, t) {
      return new Promise(resolve => {
        commit("openDrawing", t);
        resolve(t);
      });
    },
    drawingname({ commit }, t) {
      return new Promise(resolve => {
        commit("drawingname", t);
        resolve(t);
      });
    },
    archiveSelectTimeSlotImage({ commit }, camdata) {
      return new Promise(resolve => {
        commit("archiveimage", camdata);
        resolve(camdata);
      });
    }
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    currentCamera: state => state.currentCamera,
    currentZone: state => state.currentZone,
    currentvisitId: state => state.currentvisitId,
    ArchiveImage: state => state.ArchiveImage,
    pcToken: state => state.pcToken,
    reportToggle: state => state.reportToggle,
    reportToggle1: state => state.reportToggle1,
    reportToggle2: state => state.reportToggle2,
    archiveToggle: state => state.archiveToggle,
    drawingToggle: state => state.drawingToggle,
    currentDrawing: state => state.currentDrawing,
    packageVersion: state => state.packageVersion
  }
});

<template>
  
  <div>
    <Preloader v-if="preloader" />

       <h3> Welcome to Archive </h3>
   </div>
</template>
<script>
//import axios from 'axios'
import Preloader from '../../components/PreLoader'

export default {
  components: {
    Preloader
  },
  name: "Archive",
  data() {
    return { 
       preloader: true
    };
  },
  mounted() {
     this.initViewer();
  },
  watch: {
  '$store.state.currentCamera': function() {
   //  this.preloader = false
    // console.log("Current data get",this.$store.state.currentCamera)
    
  }
  }, 
  methods: {
    initViewer() {
      console.log("init Viewer");
       this.preloader = false 
    }
  }
};
</script>
<style>
</style>


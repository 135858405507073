<template>
  <div>
    <Preloader v-if="preloader" />
    <v-btn class="mr-4 homelocation" small v-if="userdata.user_code == 2 && docloaded" @click="setPosition" color="mylightbutton">
                  Home
              </v-btn>
    <v-btn class="mr-4 setHomecompare" small v-if="userdata.user_code == 2 && docloaded" @click="getviwerid" color="mylightbutton">
                Set Home View
            </v-btn>
    <div
      class="text-center  pa-0 headline"
      v-if="!showadd"
    >
    <v-btn class="mr-4 switch" small  @click="setrouter('/dashboard/compare-bim-overlay')" color="mylightbutton">
               Switch 
            </v-btn>
      <v-dialog v-model="dialogtwo" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2" 
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            {{ d2 }}
            <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-card>
       
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDatetwo($event)"
            v-model="pickertwo"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>
         
          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslotstwo" :key="i" @change="selectTimeSlottwo(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group
            active-class="amber accent-3-darken-3--text text--accent-4"
            multiple
            :color="activecolor"
          >
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslotstwo"
              :key="i"
              @change="selectTimeSlottwo(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Close }}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Done }}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
     
    </div>
    <div class="splitview"  v-if="!showadd">   
         <v-row>
          <v-col :cols="compare?'12':'6'"  class="bimcomparessccc">
           <!-- <forge-vuer v-if="docloaded" @documentLoadError="documentLoadError" :getAccessToken="myGetTokenMethodAsync" :urn="myObjectUrn" /> -->
           <div id="forgeViewer23" v-if="docloaded"></div>
          </v-col> 
        <v-col cols="6" v-if="!compare">
      
            <v-zoomer class="zzoomerleft">
                <img
                :src="compareimage2"
                style="object-fit: contain; width: 100%; height: 90vh"
                />
            </v-zoomer>
       </v-col>
      </v-row>
    </div> 
    <v-col cols="12" md="12" v-if="showadd">
               <div class="msgbox">
                        <p>Upload BIM model to compare 3d model with the camera images</p>
                </div>
        </v-col>
        <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Preloader from "../../components/PreLoader";
import image1 from "../../assets/opticvyu_transparent.png";
import image2 from "../../assets/opticvyu_comp_r.png";

//import ForgeVuer from 'forge-vuer';
import VueZoomer from "vue-zoomer";
import { locals } from "../../utils/locals";
import * as THREE from 'three'; 
// import moment from 'moment'
Vue.use(VueZoomer);
export default {
  props:{
    value: { default: 50 },
    step: { default: '.1' }
  },
  components: {
    Preloader
  },
  name: "Compare",
  data() {
    return {
      width: null,
      compareWidth: this.value,
      myToken: "",
      myObjectUrn: "",
      preloader: false,
      dialog: false,
      dialogtwo: false,
      picker: new Date().toISOString().substr(0, 10),
      pickertwo: new Date().toISOString().substr(0, 10),
      activecolor: "amber accent-3",
      timeslots: [],
      timeslotstwo: [],
      compareimage1: image1,
      compareimage2: image2,
      timeslotloader: false,
      comapreview: true,
      d1: " Select Date",
      d2: "Select Date",
      multiLine: false,
      snackbar: false,
      text: `Images not found on the selected date.`,
      checkcam360: false,
      compare_1img360: "",
      compare_2img360: "",
      show_1img360: false,
      show_2img360: false,
      current_cam: {},
      lock: false,
      hfov: 75,
      yaw: 0,
      pitch: 0,
      draggable: true,
      mouseZoom: true,
      doubleClickZoom: true,
      showZoom: true,
      showFullscreen: true,
      diffeimagedialog : false,
      diffeimage : '',
        Local :locals,  
        lang : {},
        userdata : {},
        currentProject : {},
          docloaded: false,
          autodesk_viewer:0,
      showadd: true,
      arrayDates: [],
      dateActiver: false,
      pickerDate: null,
      datesWithImages: [],
      archiveCalenderyearMonth: false,
      snackbarDatesWithImages: false,
      viewer : '',
      alertmessage: '',
            alert: false,
            compare : false,
            
    };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.initViewer();
    this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
    this.autodesk_viewer = this.currentProject.autodesk_viewer;
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    this.current_cam = this.$store.state.currentCamera;
    if (this.current_cam.cam_360 == 1) {
      console.log("Current cam data", this.current_cam);
      this.checkcam360 = true;
    } else {
      this.checkcam360 = false;
    }
    this.width = this.getContainerWidth();
  },
    created() {
       
      
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
        this.projectid = this.currentProject.id;
            this.autodesk_viewer = this.currentProject.autodesk_viewer;
            this.getTokenz();
            this.getLattersImage(this.current_cam);
    },
  watch: {
    value(){
      this.compareWidth= this.value
    },
    "$store.state.currentCamera": function () {

               this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
                   this.autodesk_viewer = this.currentProject.autodesk_viewer;
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera)
      this.timeslots = [];
      this.timeslotstwo = [];
      this.current_cam = this.$store.state.currentCamera;
      this.compareimage1 = image1;
      this.compareimage2 = image2;
      if (this.current_cam.cam_360 == 1) {
        console.log("Current cam data", this.current_cam);
        this.checkcam360 = true;
      } else {
        this.checkcam360 = false;
      }
      this.getTokenz();
       this.getLattersImage(this.current_cam.camera_id);
    },
  },
  methods: {

    getTokenz(){
            console.log("first", this.currentProject.autodesk_viewer);
            //  var self = this;
        
         if(this.currentProject.autodesk_viewer == 1){
             
             this.showadd = false;

           var _self = this;

            axios({
                    url: '/interior/autodesk-token',
                    method: 'post',
                })
                .then(resp => {
                    this.myToken = resp.data.access_token;
                    console.log("token", this.myToken);

                    if (this.currentProject.autodesk_urn) {
                        this.docloaded = true;
                        this.NoHaveDocId = false;
                        this.myObjectUrn = this.currentProject.autodesk_urn;
                    

                      const Autodesk = window.Autodesk;
                   // var viewer;
                        var options = {
                            env: 'AutodeskProduction2',
                            api: 'streamingV2',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
                            getAccessToken: function(onTokenReady) {
                                var token = resp.data.access_token;
                                var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                                onTokenReady(token, timeInSeconds);
                            }
                        };

                    console.log("options",options);

                    Autodesk.Viewing.Initializer(options, function() {

                        _self.preloader = true;

                        var htmlDiv = document.getElementById('forgeViewer23');
                        _self.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                        var startedCode = _self.viewer.start();
                        if (startedCode > 0) {
                            console.error('Failed to create a Viewer: WebGL not supported.');
                            return;
                        }

                        console.log('Initialization complete, loading a model next...');

                        var documentId = 'urn:'+_self.currentProject.autodesk_urn;
                         console.log("documentId", documentId);
                         Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

                            function onDocumentLoadSuccess(viewerDocument) {
                              
                            var defaultModel = viewerDocument.getRoot().getDefaultGeometry();//viewerDocument.getRoot().getDefaultGeometry();
                     //(viewableId ? doc.getRoot().findByGuid(viewableId) : doc.getRoot().getDefaultGeometry())
                               console.log("defaultModel",defaultModel);
                               
                               _self.viewer.loadDocumentNode(viewerDocument, defaultModel);
                               _self.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT);
                                
                               setTimeout(() => {
                                _self.setPosition();
                               }, 2000);

                               setTimeout(() => {
                                    _self.onDocumentLoadS();
                                    _self.preloader = false;
                                 }, 10000);
                              
                            }

                            function onDocumentLoadFailure() {
                              console.error('Failed fetching Forge manifest');
                            }

                    });

                   }else{
                     this.preloader = false
                   }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
         }else{
            this.showadd = true;
            this.preloader = false
         }
        },  
        onDocumentLoadS() {
            var df =  this.viewer.setActiveNavigationTool('bimwalk');
                                 console.log("df",df);
        },
    allowedDates(val) {
      var values = [];
      this.datesWithImages.forEach((item) => {
        var daywithimage = item.image_date;
        values.push(daywithimage);
      });

      if (values.includes(val)) return true
      if (values.includes(val)) return ['red', '#00f']
      return false
    },
    alldates(val) {
      return val;
    },
    datesWithImage() {
      this.timeslotloader = true;
      this.dateActiver = !this.dateActiver;
      var camera_id = this.current_cam.camera_id;
      this.snackbarDatesWithImages = true;
      if (this.dateActiver) {
        this.archiveCalenderyearMonth = "Filtered dates where images are available.";
      } else {
        this.archiveCalenderyearMonth = "Showing all dates.";
      }

      axios({
        url: "/camera/" + camera_id + "/dates_with_images",
        method: "GET",
      })
        .then((resp) => {
          this.datesWithImages = resp.data.success;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
      // documentLoadError(eventData) {
      //       console.log(`Progress: ${eventData}`);
      //       this.docloaded = false;
      //       this.NoHaveDocId = true;
      //   },
      //  getToken() {
      //      var c = JSON.parse(localStorage.getItem('currentProject'));
      //       console.log("first",c.autodesk_viewer);
      //       //  var self = this;
      //        if(c.autodesk_viewer == 1){

      //           this.showadd = false;
      //       axios({
      //               url: '/interior/autodesk-token',
      //               method: 'post',
      //           })
      //           .then(resp => {
      //               this.myToken = resp.data.access_token;
      //               console.log("token", this.myToken);

      //               if (this.currentProject.autodesk_urn) {
      //                    this.docloaded = true;
      //                   this.myObjectUrn = this.currentProject.autodesk_urn;
      //               }else{
      //               this.alert = true;
      //               this.alertmessage = "Auto Desk  Model is not available";
      //               this.alertType = "error"
      //               this.preloader = false
      //               }

                   
      //           })
      //           .catch(err => {
      //               console.log(err);
      //               this.alert = true;
      //               this.alertmessage = this.lang.SomethingWentWrong;
      //               this.alertType = "error"
      //               this.preloader = false
      //           })
      //        }else{
      //         this.showadd = true;
      //      }
      //   },
      //   myGetTokenMethodAsync: async function (onSuccess) {

      //       // console.log("second");
      //       // An API call to retrieve a valid token should be
      //       // done here. A backend service might need to be implemented.

      //       // For testing purposes, a valid token can be hardcoded but will 
      //       // last a maximum of 1 hour (3600 seconds.)
      //       console.log("i am working..");
      //       let token = this.myToken;
      //       let expireTimeSeconds = 3599;
      //       onSuccess(token, expireTimeSeconds);
      //   },
      //   onDocumentLoadSuccess(viewerDocument) {
      //       console.log('viewerDocument is an instance of Autodesk.Viewing.Document', viewerDocument);
      //       setTimeout(() => {
      //                           this.setPosition();
      //                          }, 2000);
      //     },
      //   onDocumentLoadFailure() {
      //       console.error('Failed fetching Forge manifest');
      //   },
    initViewer() {
      this.current_cam = this.$store.state.currentCamera;
      //console.log("init Viewer");
      this.preloader = false;
      this.getLattersImage(this.current_cam.camera_id);
    },
    getSelectedDate(data) {
      this.timeslotloader = true;
      this.d1 = data;
      var d = data.split("-");
      //console.log(d);
      var nd = d[0] + d[1] + d[2];

      //  console.log(nd, this.current_cam.camera_id);

      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }

          var dd = resp.data.success;

          this.timeslots = dd;

          // console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    selectTimeSlot(imageID) {
        console.log("selectTimeSlotImage id", imageID);
      this.getImageUrlfirst(imageID);
      this.preloader = true;
    },
    getImageUrlfirst(id) {
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
          this.current_cam = this.$store.state.currentCamera;
          if (this.current_cam.cam_360 == 1) {
            this.show_1img360 = true;
            this.compare_1img360 = resp.data.success.image_url;
          } else {
            this.show_1img360 = false;
             console.log(resp.data.success.image_url);
            this.compareimage1 = resp.data.success.image_url;
          }
          this.dialog = false;
          var v = this;
          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    getSelectedDatetwo(data) {
      this.d2 = data;
      this.timeslotloader = true;
      var d = data.split("-");
      // console.log(d);
      var nd = d[0] + d[1] + d[2];

      //console.log(nd, this.current_cam.camera_id);

      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }
          var dd = resp.data.success;

          this.timeslotstwo = dd;

          //console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectTimeSlottwo(imageID) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.getImageUrlSecond(imageID);
      this.preloader = true;
    },
    getImageUrlSecond(id) {
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
          this.current_cam = this.$store.state.currentCamera;
          if (this.current_cam.cam_360 == 1) {
            this.show_2img360 = true;
            this.compare_2img360 = resp.data.success.image_url;
          } else {
            this.show_2img360 = false;
            this.compareimage2 = resp.data.success.image_url;
          }
          //this.compareimage2 = resp.data.success.image_url;
          //this.compare_2img360 = resp.data.success.image_url;
          this.dialogtwo = false;

          var v = this;
          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    swithmode() {
      this.preloader = true;
      this.comapreview = !this.comapreview;
      //  console.log("Swith Mode",this.comapreview)
      var v = this;
      setTimeout(function () {
        v.preloader = false;
      }, 2000);
    },
    getDiffrence(){

       this.preloader = true;

          var key_1 = this.compareimage1.split('?');
          var key_2 = this.compareimage2.split('?');

          var file_key_1 =  key_1[0].split('001/');
          var file_key_2 = key_2[0].split('001/');
          axios({
        url:"/image-compare/diffrence",
        method: "POST",
        data : {
           file_key_1 : file_key_1[1],
           file_key_2 : file_key_2[1]
        }
      })
        .then((resp) => {
         
          var dd = resp.data.success;
           console.log("diffe image url",dd);
           this.diffeimage = dd
          this.preloader = false;
          this.diffeimagedialog = true;

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    lockScreens() {
      console.log("Lock Screen");

      var a = this.$refs.panaromascreen;

      console.log("Viewer", a);

      this.lock = !this.lock;
      this.hfov = 75;
      this.yaw = 0;
      this.pitch = 0;
      this.draggable = false;
      this.mouseZoom = false;
      this.doubleClickZoom = false;
      this.showZoom = false;
      this.showFullscreen = false;
      console.log("lock screen", this.lock);
      if(this.lock == true){
          this.draggable = false;
      }else{
          this.draggable = true;
      }
      a.showControls = false;

      this.imagepanaroma = this.slides[
        this.selected[this.selected.length - 2]
      ].mediapath;
      this.compatedatefirst = this.slides[
        this.selected[this.selected.length - 2]
      ].uploadat;

      //  console.log(this.selected.length);

      this.secondimagepanaroma = this.slides[
        this.selected[this.selected.length - 1]
      ].mediapath;
      this.compatedate = this.slides[
        this.selected[this.selected.length - 1]
      ].uploadat;
    },
     panUp(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() + 20);
             b.viewer.setPitch(b.viewer.getPitch() + 20);   
        },

        panDown(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() - 20);
             b.viewer.setPitch(b.viewer.getPitch() - 20);   
        },
        panLeft(){
        var a = this.$refs.panaromascreen;
        var b = this.$refs.panaromascreen2;

            a.viewer.setYaw(a.viewer.getYaw() - 20);
            b.viewer.setYaw(b.viewer.getYaw() - 20);   
        },
        panRight(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setYaw(a.viewer.getYaw() + 20);
             b.viewer.setYaw(b.viewer.getYaw() + 20);   
        },
        panIn(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() - 20);
             b.viewer.setHfov(b.viewer.getHfov() - 20);   
        },
        panOut(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() + 20);
             b.viewer.setHfov(b.viewer.getHfov() + 20);   
        },
        getviwerid(){
                        
                        console.log("viewableId ==",this.viewer.getCamera());
                      
                         var confirms =  confirm("Do you want to change the Home Position of 3D Model?");
                      
                         if(confirms){
                      var cam = this.viewer.navigation.getCamera();
       
                         const position =  cam.position;
                         const target =  cam.target;
                         const upVector  =  cam.up;
       
                     console.log(position, target, upVector);
       
                    var proid = this.currentProject.id;
       
                     var  p = position.x+","+position.y+","+position.z;
                     var  t = target.x+","+target.y+","+target.z;
                     var  u = upVector.x+","+upVector.y+","+upVector.z;
                     var prs = p+","+t+","+u;
                     console.log("prs",prs);
                     var data = {
                         position : prs,
                         target : t,
                           camid : this.current_cam.camera_id
                     };
       
                     var url = "/update-autodesk-position/"+proid;
                   axios({ url: url , data: data, method: "POST" })
               .then((resp) => {
                      console.log("Project autodesk position update",resp);  
                         this.preloader =  false;
                       this.alert = true;
                       this.alertmessage = "Auto desk position set successfully";
                     //  this.getCurrentProject();
                       // location.reload(true);
                }) .catch((err) => {
                   console.log(err);
                    this.preloader =  false;
                });
               }
                             
               },
             getCurrentProject(){
       
       let currentProject = JSON.parse(localStorage.getItem("currentProject"));
     
       var projid = currentProject.id;

      axios({
                   url: "/projec/"+projid,
                   method: "get",
               })
               .then((resp) => {
                   console.log("project update date", resp.data);
                  // this.initialSetup(resp.data);
                  localStorage.setItem("currentProject", JSON.stringify(resp.data));

                 this.$router.go();
                    
                    
               })
               .catch((err) => {
                   console.log("project update date",err);
                 
               });
    },
    setPosition(){
           var autodesk_position =  this.current_cam.c_position;
           //var autodesk_target =  this.current_cam.o_target;
               
           var _self = this;
            console.log("autodesk_position", autodesk_position);
           
           if(autodesk_position){

            var ap = autodesk_position.split(",");

// Create a new camera object with the saved information

 var position = new THREE.Vector3(parseFloat(ap[0]),parseFloat(ap[1]),parseFloat(ap[2]));
 var target = new THREE.Vector3(parseFloat(ap[3]),parseFloat(ap[4]),parseFloat(ap[5]));
 var up = new THREE.Vector3(parseFloat(ap[6]),parseFloat(ap[7]),parseFloat(ap[8]));
//up: new THREE.Vector3(parseInt(ap[6]),parseInt(ap[7]),parseInt(ap[8]))
// console.log("savedCamera", savedCamera);
// Set the camera view to the saved camera information
 //this.viewer.navigation.setView(savedCamera);
 //this.viewer.navigation.setCamera(savedCamera);
            // this.viewer.utilities.goHome();
               
               setTimeout(function () {
                  
                _self.alert = true;
                _self.alertmessage = 'Click "First Person" view for model alignment if needed.';

                _self.viewer.navigation.setView(position, target,up);

                 console.log("s--");
            //  var ap = autodesk_position.split(",");
            //  var at = autodesk_target.split(",");

            //   console.log(ap, at);

                  // var position = new THREE.Vector3(ap[0],ap[1],ap[2]); // Example camera position
                  //              var target = new THREE.Vector3(at[0],at[1],at[2]); // Example camera target
                  //              _self.viewer.navigation.setView(position, target);  
           
                }, 2000);
              
              }
         },
       swithcmode(){
         this.compare = !this.compare;
          console.log("compare",this.compare);
       },
       handleInput(e){
      this.compareWidth = e.target.value
      this.$emit('input', e.target.value)
    },
    handleResize(){
      const w = this.getContainerWidth();
      if(w === this.width)
        return;
      this.width = w
      console.log(this.width)
    },
    getContainerWidth(){
      return window.getComputedStyle(this.$el,null).getPropertyValue('width')
    },
    getLattersImage(camera_id) {
            this.preloader = true;
            this.image = "";

             console.log("lates images cam data",camera_id);
            axios({
                    url: "/camera/" + camera_id + "/op_dashboard_latest_images",
                    method: "GET",
                })
                .then((resp) => {
                         console.log("resp.data.success",resp.data);
                    if (resp.data.success) {
                       // console.log("latest Image data", resp.data);
                        this.compareimage2 = resp.data.url;
                        this.preloader = false;
                    }
                })
                .catch((err) => {
                    console.log("Error --> ", err);
                    // console.log("Error --> ",this.preloader);
                    this.preloader = false;
                });
        },
        setrouter: function (url) {

this.showmobilemenu = false;
if (this.$route.path !== url) {
 this.$router.push(url);
 this.drawer = false;
 //console.log("current router ", this.$route.path);
 this.activelink = this.$route.path;
}

//console.log(url);
},
  }
       
};
</script>
<style>

.timeList {
  position: absolute;
  width: 110px;
  top: 89px;
  right: 0px;
  height: 340px;
  display: block;
  overflow: hidden;
}

.dateWithImageButton {
  position: absolute !important;
  top: 237px;
  left: 48px;
}
.setHomecompare {
    position: absolute;
    left: 74px;
    z-index: 5;
    top: 5px;
}
.bimcomparessccc{
     height: calc(100vh - 96px);
    position: relative;
    padding: 5px;
}

@media only screen and (max-width: 600px) {
  .dateWithImageButton {
    position: unset !important;
    top: unset;
    left: unset;
  }
}
.itemBottomMargin {
  margin-bottom: -9px !important;
}
</style>


<template>
  <div>
    <Preloader v-if="preloader" />
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="0" @click="setrouter('/dashboard/reports/instant-report')">
          {{lang.InstantReport}}
        </v-tab>
        <v-tab key="1"> {{lang.ScheduleReport}} </v-tab>
        <v-tab key="2" @click="setrouter('/dashboard/reports/report-demo')">
          {{lang.ReportDemo}}
        </v-tab>
      </v-tabs>
      <v-card flat>
        <v-alert dense text :type="alertType" v-if="alert">
          {{ alertmessage }}
        </v-alert>

        <v-form ref="form" @submit.prevent="addschedulereport"  v-model="valid" lazy-validation>
          <v-card class="pa-5">
            <p class="reportinfo">
              {{lang.Reporttype}}
              <span class="info_rec">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-progress-question
                    </v-icon>
                  </template>
                  <span
                    >1. {{lang.DaiDay1}}<br />
                    2. {{lang.biweekly}}<br />
                    3. {{lang.monthly}}
                  </span>
                </v-tooltip>
              </span>
            </p>
            <v-radio-group v-model="reporttype" column>
              <v-radio
                :label="lang.Weeklyprogressreport"
                color="amber accent-3"
                value="last7days"
              ></v-radio>
              <v-radio
                :label="lang.Biweeklyprogressreport"
                color="amber accent-3"
                value="last15days"
              ></v-radio>

              <v-radio
                :label="lang.Monthlyprogressreport"
                color="amber accent-3"
                value="lastonemonth"
                title="Monthly progress report"
              ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="12" sm="3" v-for="c in camers" :key="c.cameraid">
                <v-checkbox
                  v-model="choosecamera"
                  :label="c.camName"
                  color="orange"
                  :value="c.camera_id"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="email"
                :label="lang.Email"
                required
                :rules="emailrules"
              ></v-text-field>
            </v-col>
             </v-row>
             <!-- {{choosecamera}} -->
            <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
              {{lang.submit }}
            </v-btn>
            <v-btn @click="clearreport" color="mydarkbutton"> {{lang.Reset}} </v-btn>
          </v-card>
        </v-form>
      </v-card>
    </v-card>
  </div>
</template>
<script>

import axios from "axios";
import Preloader from "../../components/PreLoader";
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
  },
  name: "schedule_report",
  data() {
    return {
      currentProject: {},
      current_cam: {},
      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      tab: 1,
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      reporttype: "",
      camers: [],
      choosecamera: [],
      email: "",
      emailrules: [
         v => !!v || this.lang.required,
        v => /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[^\s,]+\.[A-Za-z]{2,4}){0,100}$/.test(v) || this.lang.EmailValid
       ],
      reportdata: {},
      valid: true,
        Local :locals,  
        lang : {},
    };
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng];

    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.current_cam = this.$store.state.currentCamera;
    this.getProjects();
    this.initViewer();
    this.getCurrentProjectData();
    this.getreportSettings();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //console.log("Current data get", this.$store.state.currentCamera);
      //this.camers = [];
       let currentProject = JSON.parse(localStorage.getItem("currentProject"));
       this.currentProject = currentProject;
      this.current_cam = this.$store.state.currentCamera;
      this.getCurrentProjectData();
      this.getreportSettings();
      //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
      // this.img_interval  = this.current_cam.image_interval;
    },
  },
  computed: {
    params() {
      return {};
    },
    param_add() {
      return {};
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    getProjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });

          this.getCamerasbyProject(this.items[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    getCurrentProjectData() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      /* let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject; */
      // console.log(this.currentProject);
      this.drawer = false;
      this.camers = {};
      axios({ url: "/camera/" +  this.currentProject.id, method: "GET" })
        .then((resp) => {
          //console.log("camrs==================>",resp);
          this.preloader = false;
          var a = 0;
          var b = 1;
          this.camers = resp.data.success.filter(function (item) {
            item.camName = item.pro_cam_serial;
            item.camNames = "CAM " + b;
            item.iindex = a;
            a++;
            b++;

            return item.camera_active == 1;
          });
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
     getreportSettings() {
      //var currentdt = new Date();
      //console.log("currentcam", this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/project/"+ this.currentProject.id + "/get_scheduledreport",
        method: "GET",
      })
        .then((resp) => {
          console.log("report Resp", resp);
          if (resp.data.success) {

               this.choosecamera = JSON.parse("["+resp.data.success.cameras+"]");
              this.email = resp.data.success.emails;
              this.reporttype = resp.data.success.scheduletype;
          }else if (
            resp.data.error ==
            "No existing active schedule report found for this project."
          ) {
           
            this.choosecamera = [];
            this.email = "";
            this.reporttype = "";
            
          }
          
        })
        .catch((err) => {
          console.log(err);
          /* this.alert = true;
          this.alertmessage =
            "Something went wrong please reload page and try again.";
          this.alertType = "error"; */
        });
    },
    async submit() {
      this.addschedulereport(); // action to login
    },
    addschedulereport() {
      var rttype = this.reporttype;
      var usermail = this.email;
      //var checkcam = this.choosecamera;

      if (!rttype) {
        this.alert = true;
        this.alertmessage = this.lang.checkreport;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }

      if (!usermail) {
        this.alert = true;
        this.alertmessage = this.lang.EnterEmail;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (this.choosecamera.length < 1) {
        this.alert = true;
        this.alertmessage = this.lang.selectCamera;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }

      this.reportdata = {
        _token: "BAuKNLC1DoOHxiBl7bJxe5MuipZPgmRc5skKRQTx",
        filename: "OpticVyu_" + this.currentProject.project_name,
        orgname: this.currentProject.company,
        proj_id: this.currentProject.id,
        projectname:
          this.currentProject.project_name + ", " + this.currentProject.city,
        reporttype: "Comparison Report",
        scheduletype: this.reporttype,
        subject:
          "OpticVyu Reports" +
          this.currentProject.project_name +
          ", " +
          this.currentProject.city,
         cams: this.choosecamera,
        email: this.email,
      };
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/camera/" + this.currentProject.id + "/save_schedule_report",
        data: this.reportdata,
        method: "POST",
      })
        .then((resp) => {
          console.log("Resp", resp);
          if (resp.data.success) {
            /* this.alert = true;
            this.alertmessage =
              "Recurring time-lapse setting saved successfully";
            this.alertType = "success"; */
            this.$swal({
              icon: "success",
              title: "Success",
              html: this.lang.settingsave,
              showConfirmButton: true,
            });
          } else {
            /* this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error"; */
            this.$swal({
              icon: "error",
              title: "Error",
              html: this.lang.schedulenotsubmit,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          /*  this.alert = true;
          this.alertmessage =
            "Something went wrong. Please reload page and try again.";
          this.alertType = "error"; */
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },
    clearreport() {
      axios({
        url:
          "/camera/"+ this.currentProject.id + "/reset_schedule_report",
        method: "POST",
      }).then((resp) => {
        if (resp.data.success) {
          /* this.alert = true;
          this.alertmessage = resp.data.success;
          this.alertType = "success"; */
          this.$swal({
            icon: "success",
            title: "Success",
            html: this.lang.reportReset,
            showConfirmButton: true,
          });
          this.choosecamera = [];
          this.email = "";
          this.reporttype = "";
         
        } else {
          /*  this.alert = true;
          this.alertmessage = "Something went wrong please try again.";
          this.alertType = "error"; */
          this.$swal({
            icon: "error",
            title: "Error",
            html: this.lang.reportnotseset,
            showConfirmButton: true,
          });
        }
      });
    },
  },
};
</script>
<style>
.reportinfo {
  font-size: larger;
  font-weight: 500;
  color: #ffc400;
}
.info_rec {
  position: relative;
  left: 2%;
}
.mdi-progress-question {
  font-size: 28px !important;
}
</style>
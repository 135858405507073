<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">

            <v-btn color="#ffc400" class="ma-2 Block--text uploadDrawing" @click="uploadDrawignaForm = true">
                {{lang.UploadDrawing}}
                <v-icon right dark>
                    mdi-arrow-up-bold
                </v-icon>
            </v-btn>
            <v-btn color="#ffc400" class="ma-2 Block--text uploadDrawing" @click="addZoneform = true">
                {{lang.AddZone }}
                <v-icon right dark>
                    mdi-plus
                </v-icon>
            </v-btn>

        </v-col>
        <v-col v-for="n in drawings" :key="n.drawingid" class="d-flex child-flex drawingitem" cols="12"
           xs="12"
           sm="6"
           md="6"
           lg="3"
           xl="3">
            <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
                <div class="drawingname">
                    {{n.name}}

                    <v-btn class="mx-2 removedrawing" fab x-small color="#ffc400" @click="removeDrawing(n.drawingid)">
                        <v-icon dark>
                            mdi-delete
                        </v-icon>
                    </v-btn>

                </div>
                <div class="drawingname dfooter">
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-axis-z-rotate-clockwise
                        </v-icon>
                    </v-btn>
                    {{n.numberofrotet}}
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-camera
                        </v-icon>
                    </v-btn>
                    {{n.numberofimages}}
                    <v-btn class="mx-2" fab x-small color="#fff">
                        <v-icon dark>
                            mdi-play
                        </v-icon>
                    </v-btn>
                    {{n.numberofvideos}}

                </div>

            </v-img>
        </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="uploadDrawignaForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.UploadDrawing}} </v-toolbar>
                <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="submitDrawingform">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="drawingname" :rules="requerdRules" :label="lang.DrawingName" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="floor" :label="lang.Floor" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="area" label="Area" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-lable>{{lang.SelectImage}} </v-lable>
                                <input type="file" ref="file" required :rules="requerdRules" accept="image/png, image/jpeg" @change="selectFile" />
                            </v-col>
                            <img id="small-image" width="150px">

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send }} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="uploadDrawignaForm = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="addZoneform">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.NewZone}} </v-toolbar>
                <v-form v-model="validzone" lazy-validation @submit.prevent="submitZoneform">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="newzonename" :rules="requerdRules" :label="lang.zoneName" required></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!validzone" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="addZoneform = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import image1 from '../../assets/e.png'
import { locals } from "../../utils/locals";
export default {
    components: {
        Preloader
    },
    name: "droneViewEdit",
    data() {
        return {
            preloader: false,
            projectid: 0,
            requerdRules: [
                v => !!v || this.lang.required,
            ],
            zoneid: 0,
            image1: image1,
            loafin: false,
            drawings: {},
            uploadDrawignaForm: false,
            drawing: {},
            proj_dir: '',
            drawingname: '',
            floor: '',
            area: '',
            thumbnail: '',
            send: false,
            valid: true,
            alertmessage: '',
            alert: false,
            addZoneform: false,
            validzone: true,
            newzonename: '',
            Local :locals,  
            lang : {},
        };
    },
    mounted() {
          var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];  

        this.userdata = JSON.parse(localStorage.getItem('userdata'));
        if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
            this.useraccess = true
        }
          if (this.userdata.drone_edit_access == 0) {
              this.$router.push('/dashboard/user-drone-view')
        }
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        //console.log("Current data get",this.$store.state.currentCamera)
        this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
        this.projectid = currentProject.id;
        this.prefix = currentProject.pro_dir;
        this.zoneid = this.$store.state.currentZone;
        this.initViewer();
        this.getDrawings();
    },
    watch: {
        '$store.state.currentZone': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.prefix = currentProject.pro_dir;
            this.getDrawings();
        }
    },
    computed: {

    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false;

            // this.getcoordinates();
        },
        getDrawings() {
            axios({
                    url: '/dron/project/' + this.projectid + '/get-drawings',
                    method: 'get',
                    params: {
                        zone: this.zoneid
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.drawings = resp.data.success;
                    console.log("dfasfasdf", resp.data)
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },

        selectFile() {

            this.drawing = this.$refs.file.files[0];

            var width = 450;

            var _self = this;
            var reader = new FileReader();
            reader.onload = function (event) {
                var img = new Image();
                img.onload = function () {
                    if (img.width > width) {
                        var oc = document.createElement('canvas'),
                            octx = oc.getContext('2d');
                        oc.width = img.width;
                        oc.height = img.height;
                        octx.drawImage(img, 0, 0);
                        while (oc.width * 0.5 > width) {
                            oc.width *= 0.5;
                            oc.height *= 0.5;
                            octx.drawImage(oc, 0, 0, oc.width, oc.height);
                        }
                        oc.width = width;
                        oc.height = oc.width * img.height / img.width;
                        octx.drawImage(img, 0, 0, oc.width, oc.height);
                        document.getElementById('small-image').src = oc.toDataURL();
                        _self.thumbnail = oc.toDataURL();
                    } else {
                        document.getElementById('small-image').src = img.src;

                        _self.thumbnail = img.src;
                    }
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(this.$refs.file.files[0]);

        },
        submitDrawingform() {
            this.send = true;
            let formData = new FormData();

            formData.append("file", this.drawing);
            formData.append("projectId", this.projectid);
            formData.append("proj_dir", this.prefix);
            formData.append("drawingname", this.drawingname);
            formData.append("floor", this.floor);
            formData.append("area", this.area);
            formData.append("zoneid", this.zoneid);
            formData.append("thumbnail", this.thumbnail);

            console.log("formdata", this.thumbnail);

            axios.post('/dron/project/' + this.projectid + '/add-drawings', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(resp => {
                    console.log("Drawing resonse", resp);

                    this.send = false;
                    this.uploadDrawignaForm = false;
                    this.alert = true;
                    this.alertmessage = this.lang.drawingupload;
                    this.getDrawings();

                })
                .catch(err => {
                    this.send = false;
                    this.uploadDrawignaForm = false;
                    console.log("Drawing resonse Error", err)
                })
        },
        removeDrawing(id) {
            var conf = confirm(this.lang.drawingconfirm);

            if (conf) {
                axios({
                        url: 'dron/remove-drawings',
                        method: 'post',
                        data: {
                            did: id
                        }
                    })
                    .then(resp => {
                        console.log("headings", resp);
                        this.alert = true;
                        this.alertmessage = this.lang.drawingremove;
                        this.getDrawings();
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                        this.preloader = false
                    })
            }
        },
        goToDrawing(n) {
            this.$router.push({
                name: 'drone_viewedit',
                params: {
                    id: n.drawingid
                }
            });
        },
        submitZoneform() {
            axios({
                    url: '/dron/project/' + this.projectid + '/add-zone',
                    method: 'get',
                    params: {
                        zonename: this.newzonename
                    }
                })
                .then(() => {
                    this.alert = true;
                    this.alertmessage = this.lang.addezone;
                    this.addZoneform = false;
                    this.$router.go();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        }
    }

};
</script>

<style>
.uploadDrawing {
    float: right;
}

.drawingitem .v-image {
    border: 3px solid #292929 !important;
    border-radius: 3px;
}

.drawingname {
    background: #000000d9;
    color: #fff;
    text-align: center;
    padding: 6px 0;
    text-shadow: 0px 0px 2px black;
}

.drawingname.dfooter {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.removedrawing {
    position: absolute;
    right: 0;
    top: 0px;
}
</style>

<template>
  
  <div>
    <Preloader v-if="preloader" />
      <div class="pa-5">
        <v-row > 
    <v-col
         v-for="(image, index) in orderedcamdata"
      :key="index"
      class="d-flex child-flex camimage"
      cols="6"
    >
     <span class="camname"> 
        {{ image.cam_serial}} {{image.cam_name}}
     </span>
    <div class="camDashboard">
      <v-icon dark @click="selectConstructionwithCame(image.cam_id, index)">
        mdi-arrow-right-box</v-icon>
      </div>
    <div class="split360" v-if="checkcam360">
       <v-pannellum v-if="checkcam360" :src="image.url" @click="openimage(image.url)"></v-pannellum>
    </div>
      <v-img
        :src="image.url"
        aspect-ratio="1.8"
        class="grey lighten-2"
        @click="openimage(image.url)"
        v-if="!checkcam360"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <span class="camtag"> 
       {{image.status}}
     </span>
    </v-col>
  </v-row>
   </div>           
   <v-zoomer class="zzoomerfull" v-if="dialog">      
  <img
    :src="img"
    style="object-fit: contain; width: 100%; height: 100%;"
  >
</v-zoomer>
  <v-icon  v-if="dialog" class="closebuttons" @click="closetoggle"
         >mdi-window-close</v-icon> 
   </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import Preloader from '../../components/PreLoader';
import VueZoomer from 'vue-zoomer';
import VuePannellum from "vue-pannellum";
//import { orderBy } from 'lodash'
import { locals } from "../../utils/locals";
Vue.use(VueZoomer)
export default {
  components: {
    Preloader,
    'VPannellum': VuePannellum,
  },
  name: "Splitscreen",
  data() {
    return { 
        preloader: true,
        camers : [],
        images : [],
        dialog: false,
        img : '',
        checkcam360: false,
        Local :locals,  
        lang : {},
        current_cam:{},
        camstatus:'',
        camname:'',
    };
  },
  mounted() {
       var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 
     this.initViewer();
     if(this.images.length == 0){
        this.getCurrentProjectData()
     }
     this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
         this.current_cam = this.$store.state.currentCamera;
     this.camstatus = this.current_cam.status;
     //this.camname = this.current_cam.cam_name;
      if (this.current_cam.cam_name == "ov") {
               console.log("camname empty", this.current_cam.cam_name);
             this.camname = "";
          } else {
            console.log("camname defined", this.current_cam.cam_name);
              this.camname = this.current_cam.cam_name;
          }
  },
  watch: {
  '$store.state.currentCamera': function() {
     //this.preloader = true
     //   console.log("Current data get",this.$store.state.currentCamera)
       this.images = [];
      this.getCurrentProjectData();
     this.current_cam = this.$store.state.currentCamera;
     this.camstatus = this.current_cam.status;
     this.camname = this.current_cam.cam_name;
     console.log("split screen cam info", this.current_cam);
  }
  }, 
    computed: {
    orderedcamdata: function () {
      var imahes =this.images;
    var ss =  imahes.sort((a, b) => a.cam - b.cam);
      console.log(ss);
     return  this.images;
    }
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
       this.preloader = false 
       this.images = [];
    },
    getCurrentProjectData(){   // Moduletype variable store value of marial, interior or constructuion cameras.
            this.images = [];
            let currentProject = JSON.parse(localStorage.getItem('currentProject'))
            this.currentProject = currentProject;
            this.currentcam = this.$store.state.currentCamera;
           // console.log(this.currentProject);
            this.drawer = false;
            this.camers = []
          axios({ url: '/camera/'+currentProject.id, method: 'GET' })
          .then(resp => {
            if (this.currentcam.cam_360 == 1) {
              this.checkcam360 = true;
            } else {
              this.checkcam360 = false;
            }
              this.camers = resp.data.success.filter(function(item){
                 return item.camera_active == 1;         
               }); 
                
            console.log(resp.data.success+'>>>>>>>>>>>>');
                   var a = 1;
                   this.camers.forEach(item => {
                        this.getLattersImage(a, item)
                      a++;
                   }); 
                   console.log("checking data", this.images);
           })
          .catch(err => {
            console.log(err);
          })
       },
       getLattersImage(a, camdata){
                console.log("lates images cam data",camdata.camera_id);
        axios({ url: '/camera/'+camdata.camera_id+'/latest_images', method: 'GET' })
          .then(resp => {
             console.log("Lattest Image >>", resp);
             this.getImageUrl(a, camdata, resp.data.success[0].id);
           })
          .catch(err => {
            console.log(err);
          })
    },
     getImageUrl(a, cdata,id){
         
            this.preloader = true
             axios({ url: '/camera/' + cdata.camera_id + '/image/'+id+'/list/url', method: 'GET' })
          .then(resp => {
             console.log("Lattest Image Url >>", resp.data.success.image_url);
          if (cdata.cam_name == "ov") {
               console.log("camname empty",cdata.cam_name);
             cdata.cam_name = "";
          } else {
            console.log("camname defined", cdata.cam_name);
              
          }
         var ii = 
            {
              cam:a,
             cam_serial: cdata.pro_cam_serial,
             status:cdata.cam_status,
             cam_name:cdata.cam_name,
           url: resp.data.success.image_url ,
             cam_id: cdata.camera_id
            } 
            ;

        this.images.push(ii);  

         //console.log(this.images);  

         var uniqueArray = this.images.filter(function(arr, index, self) {

            //console.log(self.indexOf(item), pos)
             return index === self.findIndex((t) => (t.cam_id === arr.cam_id))
         })

          console.log("uniqueArray", uniqueArray);

         this.images = uniqueArray;
             
              var v = this;
              setTimeout(function () {
                  v.preloader = false
              }, 2000); 
            
          })
          .catch(err => {
            console.log(err);
          })
    },
    openimage(url){
           this.dialog= true;
           this.img = url
    },
    closetoggle(){
       this.dialog= false;
    },

    selectConstructionwithCame: function (camid, index) {
      localStorage.setItem("cid", camid);
      this.setCamera(this.camers[index]);
      localStorage.setItem("currentCamIndex", index);
      window.location.href = "/dashboard";
    },
    setCamera(data) {
      this.$store
        .dispatch("setCamera", data)
        .then(() => console.log("camera set data"))
        .catch((err) => console.log(err));
    },
  }
};
</script>
<style>
.zzoomerfull {
    width: 100%;
    height: 100VH;
    border: solid 1px silver;
    position: fixed;
    top: 0;
    left: 0;
    background: #515b61b0 !IMPORTANT;;
    DISPLAY: BLOCK;
    z-index: 12;
}
.closebuttons {
    position: fixed !important;
    right:20px;
    top: 60px;
    z-index: 999999;
    color: #fff !important;
}
.split360 {
  display: block;
  overflow:auto;
  height :50vh;
  /*width: 48%;
  height: 50%;
  background: #000000ab;
  
  left: 20px;
  z-index: 1; */
}
span.camname {
    position: absolute;
    left: 20px;
    background: #02020236;
    padding: 3px 7px;
    border-radius: 3px;
    top: 20px;
    z-index: 2;
    color: #fff;
}
span.camtag {
    position: absolute;
    left: 12px;
    background: #02020236;
    padding: 3px 7px;
    border-radius: 3px;
    bottom: 12px;
    z-index: 2;
    color: #fff;
}
.camimage{
  position:relative;
}
.camDashboard{
  position: absolute;
    float: right;
    right: 21px;
    background: #02020236;
    padding: 3px 7px;
    border-radius: 3px;
    top: 20px;
    z-index: 2;
    color: #fff;
}
</style>


<template>
  <div>
    <Preloader v-if="preloader" />
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab @click="setrouter_timelapse" key="0"> {{lang.DefaultTimelapse}} </v-tab>

        <v-tab key="1"> {{lang.CustomTimelapse}} </v-tab>
        <v-tab @click="setrouter_archive" key="2">
          {{lang.ArchiveTimelapse}}
        </v-tab>
        <v-tab @click="setrouter" key="3" class="createtab">
         {{ lang.CreateTimelapse}}
        </v-tab>
      </v-tabs>

      <v-tabs v-model="sub_tab" centered icons-and-text>
        <v-tabs-slider></v-tabs-slider>

        <v-tab @click="setrouter_custom" v-if="custometab" key="0">
          One-time/Recurring
          <v-icon>mdi-calendar-clock</v-icon>
        </v-tab>

        <v-tab v-if="advancetab" key="1">
          {{lang.Advance}}
          <v-icon>mdi-calendar-plus</v-icon>
        </v-tab>

        <v-tab @click="setrouter_project" v-if="projecttab" key="2">
          {{lang.Project}}
          <v-icon>mdi-video</v-icon>
        </v-tab>
      </v-tabs>

      <v-card flat>
        <v-snackbar v-model="snackbar" right top timeout="3000" type="success">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              {{lang.Ok}}
            </v-btn>
          </template>
        </v-snackbar>
        <Pano class="mypano360a" v-if="checkcam360" @on-load="onLoad"  type='video' :source="custometimelapsurl"></Pano>
        <div class="videostyle">
          <video
            class="videostyle"
            controls="true"
            ref="custome_timelapsvideo"
          ></video>
          <!-- <v-btn
      class="mx-2 mixoperation download"
      fab
      dark
      small
      color="#ff5566"
       @click ="downloadCustomeTimelaps"
    >
      <v-icon dark>
        mdi-download-circle
      </v-icon>
    </v-btn> -->

          <v-btn
            class="mx-2 mixoperation share btndisplay"
            fab
            dark
            small
            color="#ff5566"
            @click="openboxSecond"
          >
            <v-icon dark> mdi-share-variant </v-icon>
          </v-btn>
          <v-btn
            class="mx-2 mixoperation move btndisplay"
            fab
            dark
            small
            aria-label="Move to archive"
            title="Move to archive timelapse"
            color="#ff5566"
            @click="openboxthird"
          >
            <v-icon dark> mdi-folder-move </v-icon>
          </v-btn>
          <div class="detailsBox">
            <table>
              <tr>
                <td>{{lang.Type}}</td>
                <td>&nbsp; : {{ this.details.type }}</td>
              </tr>
              <tr>
                <td>{{lang.StartDate}}</td>
                <td>&nbsp; : {{ this.details.start_date }}</td>
              </tr>
              <tr>
                <td>{{lang.EndDate}}</td>
                <td>&nbsp; : {{ this.details.end_date }}</td>
              </tr>
              <tr>
                <td>{{lang.StartTime}}</td>
                <td>&nbsp; : {{ this.details.start_time }}</td>
              </tr>
              <tr>
                <td>{{lang.EndTime}}</td>
                <td>&nbsp;: {{ this.details.end_time }}</td>
              </tr>
              <tr>
                <td>{{lang.TimelapseSpeed}}</td>
                <td>&nbsp; : {{ this.details.timelapse_fps }}</td>
              </tr>
              <tr>
                <td>{{lang.TimelapseQuality}}</td>
                <td>&nbsp; : {{ this.details.quality }}</td>
              </tr>
              <tr>
                <td>{{lang.Advanceediting}}</td>
                <td>&nbsp; : {{ this.details["Advance Editing"] }}</td>
              </tr>
              <tr>
                <td>{{lang.Frameblending}}</td>
                <td>&nbsp; : {{ this.details["Frame Blending"] }}</td>
              </tr>
              <tr>
                <td>{{lang.Smoothing}}</td>
                <td>&nbsp; : {{ this.details["Timelapse smoothing"] }}</td>
              </tr>
              <tr>
                <td>{{lang.Deflicker}}</td>
                <td>&nbsp; : {{ this.details["Deflicker"] }}</td>
              </tr>
            </table>
          </div>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="sharebox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{lang.ShareByEmail}}
            <span
              class="btn btn-info text-white copy-btn ml-auto"
              @click.stop.prevent="copyTestingCode"
            >
              <v-btn depressed color="primary"> {{lang.Copyurl}} </v-btn>
              <input
                type="hidden"
                id="testing-code"
                :value="custometimelapsurl"
              />
            </span>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{ lang.Send }}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="sharebox = false" color="mydarkbutton">
                    {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="custometimelapsbox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{lang.ShareByEmail}}
            <span
              class="btn btn-info text-white copy-btn ml-auto"
              @click.stop.prevent="copyTestingCode"
            >
              <v-btn depressed color="primary"> {{lang.Copyurl}} </v-btn>
              <input
                type="hidden"
                id="testing-code"
                :value="custometimelapsurl"
              />
            </span>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitcustometimelaps"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send}}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn
                    @click="custometimelapsbox = false"
                    color="mydarkbutton"
                  >
                    {{lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="movetimelapsebox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{lang.MoveTimelapseToArchive}}</v-toolbar
          >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitmovetimelaps"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="caption"
                    :label="lang.Addcaptionforarchivetimelapse"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Done }}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="movetimelapsebox = false" color="mydarkbutton">
                   {{ lang.Cancel }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueSweetalert2 from "vue-sweetalert2";
import { Pano } from 'vuejs-vr'
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
    Pano
  },
  name: "CustomTimelapse",
  data() {
    return {
      preloader: true,
      regulertimelaps: "",
      currentcam: {},
      tab: 1,
      sub_tab: 1,
      details: {},
      response: {},
      custometab: false,
      advancetab: true,
      projecttab: false,
      sharebox: false,
      custometimelapsbox: false,
      movetimelapsebox: false,
      email: "",
      message: "",
      subject: "",
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      valid: true,
      timelapsurl: "",
      custometimelapsurl: "",
      send: false,
      useraccess: false,
      freeuseraccess: false,
      project: "",
      org: "",
      city: "",
      ct_name: "",
      ct_id: "",
      caption: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      checkcam360: false,
      Local :locals,  
      lang : {},
    };
  },

  mounted() {
        var selectleng = this.$cookies.get('currentLanguage');
       this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    this.initViewer();
    this.getTimelapsVideos();
    this.getcustomTimelapsVideos();
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
    //this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0 ){
          this.freeuseraccess = true
        }else{
          this.$router.push('/')
        }
         this.currentcam = this.$store.state.currentCamera;
     /* if(this.currentcam.cam_360 == '1'){
          this.checkcam360 = true;
      }else{
        this.checkcam360 = false;
      } */
    
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getTimelapsVideos();
      this.getcustomTimelapsVideos();
      console.log("camera parameters", this.currentcam);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      //console.log('currentProject', currentProject);

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
       //this.currentcam = this.$store.state.currentCamera;
     /* if(this.currentcam.cam_360 == '1'){
          this.checkcam360 = true;
      }else{
        this.checkcam360 = false;
      } */
    },
  },
  computed: {
    params() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.timelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramscustome() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.custometimelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramsmovearchive() {
      return {
        c_dir: this.currentcam.camera_prefix,
        vid: this.ct_name,
        ct_id: this.ct_id,
        st_dt: this.details.start_date+' '+this.details.start_time,
        end_dt: this.details.end_date+' '+this.details.end_time,
        speed: this.details.timelapse_fps,
        quality: this.details.quality,
        cam_id: this.currentcam.camera_id,
        //usr: '',
        caption: this.caption,
        t_ad_edit: this.details["Advance Editing"],
        t_frame: this.details["Deflicker"],
        t_smooth: this.details["Frame Blending"],
        t_deflicker: this.details["Timelapse smoothing"],
      };
    },
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
      this.preloader = false;

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.current_cam = this.$store.state.currentCamera;
      //console.log('camera parameters',  this.currentcam);
    },
    getcustomTimelapsVideos() {
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      console.log("current Camera", this.currentcam.camera_id);
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/list/timelapse",
        method: "GET",
      })
        .then((resp) => {
          //console.log('timelapse', resp.data.success.custom_timelapse.url);
          this.response = resp.data.success;
          //this.$refs.timelapsvideo.src = resp.data.success.timelapse.url;
          this.timelapsurl = resp.data.success.timelapse.url;
          if (resp.data.success.custom_timelapse.ct_id) {
            //console.log('timelapseeeeee', resp.data.success.custom_timelapse.url);
            this.custometab = true;
          } else {
            //console.log('timelapse url', resp.data.success.custom_timelapse.url);
            this.custometab = false;
          }
          if (resp.data.success.advance_timelapse.ad_id == "success") {
            this.advancetab = true;
          } else {
            this.advancetab = false;
            this.$router.push("/dashboard/timelapse");
          }
          if (resp.data.success.project_timelapse.pr_id == "success") {
            //console.log('project_timelapse id', resp.data.success.project_timelapse.pr_id);
            this.projecttab = true;
          } else {
            this.projecttab = false;
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTimelapsVideos() {
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      // console.log("current Camera", this.currentcam.camera_id);
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/list/timelapse",
        method: "GET",
      })
        .then((resp) => {
          //console.log('timelapse', resp.data.success.custom_timelapse.url);
          this.response = resp.data.success;
          //this.$refs.timelapsvideo.src = resp.data.success.timelapse.url;
          this.timelapsurl = resp.data.success.timelapse.url;
          if (resp.data.success.custom_timelapse == "Not enabled.") {
            //console.log('timelapseeeeee', resp.data.success.custom_timelapse.url);
            //this.custometab = false;
          } else {
            console.log(
              "ad timelapse url",
              resp.data.success.advance_timelapse.url
            );
            //this.custometab = true;
             var _self = this;
            if(this.currentcam.cam_360 == '1'){
              console.log("360pano", resp.data.success.advance_timelapse.url);
              this.checkcam360 = true;
            }else{
              this.checkcam360 = false;
              setTimeout(() => {
                _self.$refs.custome_timelapsvideo.src =
              resp.data.success.advance_timelapse.url;
               }, 500);
            }
            this.custometimelapsurl = resp.data.success.advance_timelapse.url;
            this.details = resp.data.success.advance_timelapse.details;
            this.ct_name = resp.data.success.advance_timelapse.ad_name;
            this.ct_id = resp.data.success.advance_timelapse.adv_id;
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadTimelaps() {
      const myImage = this.response.timelapse.url;

      var res = myImage.split("?");

      var filenames = res[0].split("/");

      var filename = filenames[filenames.length - 1];

      // console.log(filename);

      //  console.log("Edit data", myImage);
      var a = document.createElement("a"); //Create <a>
      a.href = "data:video/mp4," + myImage; //Image Base64 Goes here
      a.download = filename; //File name Here
      a.target = "_blank";
      a.download;
      a.click(); //Downloaded file
    },
    downloadCustomeTimelaps() {
      const myImage = this.response.custom_timelapse.url;

      var res = myImage.split("?");

      var filenames = res[0].split("/");

      var filename = filenames[filenames.length - 1];

      //console.log(filename);

      //  console.log("Edit data", myImage);
      var a = document.createElement("a"); //Create <a>
      a.href = "data:video/mp4," + myImage; //Image Base64 Goes here
      a.download = filename; //File name Here
      a.target = "_blank";
      a.download;
      a.click(); //Downloaded file
    },
    setrouter: function () {
      if(this.useraccess){
      this.$router.push("/dashboard/settings/create-timelapse");
       }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      //console.log(url);
    },
    setrouter_archive: function () {
      this.$router.push("/dashboard/timelapse/archive-timelapse");
      //console.log(url);
    },
    setrouter_timelapse: function () {
      this.$router.push("/dashboard/timelapse");
      //console.log(url);
    },
    setrouter_custom: function () {
      this.$router.push("/dashboard/timelapse/custom-timelapse");
      //console.log(url);
    },
    setrouter_project: function () {
      this.$router.push("/dashboard/timelapse/project-timelapse");
      //console.log(url);
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharetimelaps(this.params); // action to login
      }
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        //alert("url copied " + msg);
        this.snackbar = true;
        this.text = "Copy URL! Link valid for 7 days only";
      } catch (err) {
        //alert("Oops, unable to copy");
        this.snackbar = true;
        this.text = "Oops, unable to copy !";
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    sharetimelaps(sharedata) {
      //console.log("data", sharedata);
      this.send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-with-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          //console.log(resp);
          this.sharebox = false;
          this.send = false;
        })
        .catch((err) => {
          //console.log(err)
          this.send = false;
          this.snackbar = true;
          this.text = `Email delivery faild.`;
        });
    },
    openbox() {
      this.sharebox = true;
    },
    openboxSecond() {
      this.custometimelapsbox = true;
    },
    openboxthird() {
      this.movetimelapsebox = true;
    },
    async submitcustometimelaps() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharecustometimelaps(this.paramscustome); // action to login
      }
    },
    sharecustometimelaps(sharedata) {
      //console.log("data", sharedata);
      this.send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-with-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          //console.log(resp);
          this.custometimelapsbox = false;
          this.send = false;
        })
        .catch((err) => {
          //console.log(err)
          this.send = false;
          this.snackbar = true;
          this.text = `Email delivery faild.`;
        });
    },
    async submitmovetimelaps() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.movecustometimelapse(this.paramsmovearchive); // action to login
      }
    },
    movecustometimelapse(movedata) {
      //console.log("data", movedata);
      //this.send = true;
      this.movetimelapsebox = false;
      this.preloader = true;
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/move/archive_timelapse",
        data: movedata,
        method: "post",
      })
        .then((resp) => {
          //console.log(resp);
          //this.movetimelapsebox = false;
          //this.send = false;
          if (resp.data.success) {
            this.preloader = false;
            this.$swal({
              icon: "success",
              title: "Success",
              html: "Timelapse successfully moved to archive timelapse",
              showConfirmButton: true,
            });
          } else {
            this.preloader = false;
            this.$swal({
              icon: "error",
              title: "Error",
              html: resp.data.error,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          this.preloader = false;
          //console.log(err)
          //this.send = false;
          //this.snackbar = true;
          //this.text = `Copy to archive failed`;
          this.$swal({
            icon: "error",
            title: "Error",
            html: "Getting error while moving, please try after sometime.",
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>
<style>
.videostyle {
  margin: auto;
  display: block;
  width: 100%;
}
video {
  max-width: 100%;
  height: 85.6vh;
}
.detailsBox {
  position: absolute;
  right: 0;
  top: 0;
  background: #ebe9e9;
  box-shadow: 0 0 5px 3px #ccc;
  border-radius: 5px;
  padding: 6px 20px;
  opacity: 0.5;
}

.detailsBox:hover {
  opacity: 1;
}
.mixoperation {
  position: absolute;
}
.download {
  top: 36%;
}
.share {
  top: 45%;
}
.move {
  top: 35%;
}
.createtab {
  color: #7e7a7a !important;
}
.btndisplay{
  z-index: 1;
  display: block;
}
.mypano360a{
    height:500px !important;
    width: 100%;
}
</style>


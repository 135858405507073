<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="pa-5">
      <v-row>
        <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
          <h2 class="hetitle">{{ lang.Graphs }}</h2>
        </v-col>

        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
          <!-- <ejs-daterangepicker :change="onValueChange"  :max="maxDate"  :startDate="startVal" :endDate="endVal"  :minDays="min" :maxDays="max" :strictMode="mode" :placeholder="waterMark">

                </ejs-daterangepicker>
                <p style="color:#d4425b;font-size:11px"> Max. 7 day's can be selected. </p> -->
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="10"
            transition="scale-transition"
            offset-y
            small
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="lang.SelectDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
              @change="onValueChange"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="series.length == 0">
          <p style="color:red;text-align:center">{{ lang.nodaavailble }}</p>
        </v-col>
        <v-col cols="12">
          <div id="chartbar" v-if="showChart">
            <apexchart
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueApexCharts from "vue-apexcharts";
import { locals } from "../../utils/locals";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
//import lodash from 'lodash'
Vue.use(DateRangePickerPlugin);

//var self = this
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts
  },
  name: "PeopleCount",
  data() {
    return {
      currentcam: {},
      preloader: true,
      showChart: false,
      preshowChart: false,
      currentProject: {},
      records: {},
      series: [
        {
          name: "Session Duration",
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
        },
        {
          name: "Page Views",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
        },
        {
          name: "Total Visits",
          data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: ["#00e396", "#e23b3b", "#dba70b"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          width: 3,
          lineCap: "butt"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct"
          ]
        },
        yaxis: {
          title: {
            text: "People Counting",
            align: "left"
          }
        },

        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        }
      },
      dates: [],

      time3: "",
      max: "2",
      min: "1",
      waterMark: "Select a Range",
      mode: true,
      menu2: false,
      menu3: false,
      presets: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
      listtype: [
        "Persons With Required PPE",
        "Persons Without Required PPE",
        "All"
      ],
      aidate: "",
      firstnamerules: [v => !!v || this.lang.required],
      valid: true,
      presetid: "",
      image_type: "All",
      ai_data_images: [],
      dialog: false,
      img: "",
      personsWithRequiredPPET: 0,
      WithoutRequiredPPET: 0,
      UnrecognizedT: 0,
      selectbarIndex: 0,
      selectbar: 0,
      presetlist: [],
      cimi: 0,
      alldata: [],
      startVal: new Date(),
      endVal: new Date(),
      maxDate: new Date(),
      modal: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      Local: locals,
      lang: {}
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.currentcam = this.$store.state.currentCamera;

    document.body.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        this.dialog = false;
      }
    });
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    console.log("this.userdata", this.userdata);

    if (this.currentcam.people_count == 0) {
      this.$router.push("/dashboard");
    }
  },
  created() {
    this.initViewer();
    this.presets = this.$store.state.currentCamera.preset_ids.split(",");
  },
  watch: {
    "$store.state.currentCamera": function() {
      this.currentcam = this.$store.state.currentCamera;

      if (this.currentcam.people_count == 0) {
        this.$router.push("/dashboard");
      }

      console.log("this.currentcam =", this.currentcam);
      this.getCurrentProjectData("");

      this.presets = this.$store.state.currentCamera.preset_ids.split(",");

      this.initViewer();
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    params() {
      return {
        aidate: this.aidate
      };
    }
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = true;
      this.getCurrentProjectData();
    },
    onValueChange() {
      // var ss =  this.date;

      // if (args.value) {

      this.showChart = false;
      this.preloader = true;

      // }
      this.modal = false;
      this.getCurrentProjectData();
    },

    getCurrentProjectData() {
      var start = this.date;
      var end = this.date;

      let currentcam = this.currentcam.camera_id;

      var dataurl = "/ai/" + currentcam + "/get-ai-people-count";

      console.log(start, end);

      axios({
        url: dataurl,
        method: "GET",
        params: {
          start: start,
          end: end
        }
      })
        .then(resp => {
          // console.log("resp -->> ", resp.data.success);

          // var ss = resp.data.success;

          this.columchartData(resp.data.success);
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },

    columchartData(a) {
      //  console.log("item.persons_indetermina__", a);
      var entered = [];
      var exited = [];
      var total = [];
      var xaxis = [];
      a.forEach((item, index) => {
        if (index % 6 == 0) {
          entered.push(item.object_entered);
          exited.push(item.object_exited);
          total.push(item.sum);

          var Xmas95 = new Date(item.end_time);

          // var options = {
          //     hour: 'medium'
          // };
          // console.log(new Intl.DateTimeFormat('en-US', options).format(Xmas95));

          xaxis.push(
            new Intl.DateTimeFormat("en-US", {
              timeStyle: "short",
              dateStyle: "short"
            }).format(Xmas95)
          );
        }
      });

      // console.log("total_indeterminate", total_persons_indeterminate);

      this.series = [
        {
          name: "Entered",
          data: entered
        },
        {
          name: "Exited ",
          data: exited
        },
        {
          name: "Total",
          data: total
        }
      ];

      this.chartOptions.xaxis.categories = xaxis;
      console.log("xaxis category", xaxis);

      //    console.log("this.chartOptions",this.series);
      let self = this;
      setTimeout(function() {
        self.showChart = true;
        self.preloader = false;
      }, 2000);
    }
  }
};
</script>

<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.zzoomerfull {
  width: 100%;
  height: 100vh;
  border: solid 1px silver;
  position: fixed;
  top: 0;
  left: 0;
  background: #515b61b0 !IMPORTANT;
  display: BLOCK;
}

.closebuttons {
  position: fixed !important;
  right: 10px;
  top: 50px;
  z-index: 999999;
  color: #fff !important;
  cursor: pointer;
}

.aiinfobox {
  position: fixed !important;
  right: 40px;
  top: 80px;
  z-index: 10;
  color: #000 !important;
  background: #ffffff9c;
  padding: 15px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  border-radius: 5px;
  transition: 0.5s;
}

.aiinfobox:hover {
  transition: 0.5s;
  color: #000 !important;
  background: #ffffff;
  box-shadow: 2px 2px 2px #000;
}

.split360 {
  display: block;
  overflow: auto;
  height: 50vh;
  /*width: 48%;
  height: 50%;
  background: #000000ab;

  left: 20px;
  z-index: 1; */
}

span.camname {
  position: absolute;
  left: 20px;
  background: #02020236;
  padding: 3px 7px;
  border-radius: 3px;
  top: 20px;
  z-index: 2;
  color: #fff;
}

.camimage {
  position: relative;
}

.camimage .v-responsive__content {
  cursor: pointer;
}

.menufooter.ai {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #00000075;
  padding: 6px 0;
  font-weight: bold;
  color: #fff;
}

h2.hetitle {
  background: #f8bb03;
  padding: 0px 15px;
  text-align: center;
  font-size: 17px;
  box-shadow: -2px 7px 16px 0px #0000004a;
}

#chartbar {
  cursor: pointer;
}
</style>

<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="12"
         class="billingtable"
      >
         <v-card>
             <h1 class="title_e">
                 {{name}} 
             </h1>
             
              <v-btn
           dark  color="#000000" 
           class="stament_bu"
           @click="sendzohoStatment()"
        >
           {{lang.Emailstatement}} 
             <v-icon   right dark  color ="#f8bb03" >mdi-email-send</v-icon> 

        </v-btn>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="lang.Search"
        single-line
        hide-details
      ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
       fixed-header
       height="470"
       :items-per-page="100" :footer-props="footerpro" class="elevation-1 tablemar"
    >
    
      <template v-slot:item.invoice_number="{ item }">
        <a :href="item.invoice_url" target="_blank" >{{item.invoice_number}}</a>
    </template>

      <template v-slot:item.status="{ item }">
      <v-chip
        :color="getColor(item.status)"
        dark
        class="itemsx"
      >
        {{ item.status }}   
      </v-chip>
    </template>

      <template v-slot:item.invoice_url="{ item }">
      <a :href="item.invoice_url" target="_blank" class="arroww">
          <v-icon
        class="mr-2"
      >
        mdi-arrow-right-bold-circle
      </v-icon>
      </a>
    </template>
    </v-data-table>
    <v-btn small color="black"  v-if="desserts.length == 0" dark class="reloadbuttons" @click="reloadeDataAction" title="If table data is not reflected so please try to reload or hard refres the page.">
                               {{lang.Reload }}
       </v-btn>
  </v-card>
      </v-col>

    </v-row>
      <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
      type="success"
    >
       {{lang.Statementsentsuccessfully}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          
          @click="snackbar = false"
        >
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
//import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader

  },
  name: "Invoices",
  data() {
    return { 
       items: {} ,
      preloader: false,
      projectid : 0,
      current_cam : '',
       activelink :'',
         valid : true,
        search: '',
        headers: [
          {
            text: 'Invoice Date',
            align: 'start',
            value: 'date',
            width:150,
          },
          { text: 'Invoice', value: 'invoice_number', width:200},
          { text: 'Work Order', value: 'reference_number', width:250 },
          { text: 'Amount', value: 'total', width:120},
          { text: 'Balance Due', value: 'balance' , width:200},
          { text: 'Due Date', value: 'due_date' , width:150},
          { text: 'Overdue By', value: 'due_days', width:200 },
          { text: 'Status', value: 'status', width:150},
          { text: 'Action', value: 'invoice_url', sortable: false, width:200 }
         
],
        desserts: [],
         userdata :'',
         newbimbox :false,
         send: false,
         structure : '',
              renedering_name : '',
              rendering_info : '',
              bimfile : {},
              renedering_id : 0,
              checkbox: false,
              name : '',
              snackbar : false,
               reloadeData :  false,
            Local :locals,  
            lang : {},
            footerpro: {
                itemsPerPageOptions: [100, 250, 500, -1],
            },
      }
  },
  mounted() {

       var selectleng = this.$cookies.get('currentLanguage');
       this.lang = locals[selectleng];

       this.headers = [
          { text: this.lang.InvoiceDate, align: 'start', value: 'date',   width:150 },
          { text: this.lang.Invoice, value: 'invoice_number', width:150},
          { text: this.lang.WorkOrder, value: 'reference_number', width: 170 },
          { text: this.lang.Amount, value: 'total', width:150},
          { text: this.lang.BalanceDue, value: 'balance' , width:170},
          { text: this.lang.DueDate, value: 'due_date' , width:120},
          { text: this.lang.OverdueBy, value: 'due_days', width:170 },
          { text: this.lang.Status, value: 'status', width:120},
          { text: 'View & Pay', value: 'invoice_url', sortable: false, width: 120 }
         ]

       let currentProject = JSON.parse(localStorage.getItem('currentProject'));
        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
       this.projectid = currentProject.id;

        //console.log("currentProject", currentProject);

       this.name =  currentProject.project_name+" - "+currentProject.company;
    
       this.activelink = this.$route.path;
       this.current_cam = this.$store.state.currentCamera;

  //console.log(this.currentProject.zoho_id);
        if(this.userdata.billing_access == 1 && currentProject.zoho_id !== ''){
              console.log("billing access");
        }else{
            this.$router.push('/dashboard');
        }
       this.initViewer();
      
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
  },
  watch: {
  '$store.state.currentCamera': function() {
  this.userdata = JSON.parse(localStorage.getItem('userdata')); 
     let currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.projectid = currentProject.id;
     this.current_cam = this.$store.state.currentCamera;

     this.name = "Invoice - "+currentProject.project_name+" - "+currentProject.company;
    
     if(this.userdata.billing_access == 1 && currentProject.zoho_id !== ''){
              console.log("billing access");
        }else{
            this.$router.push('/dashboard');
        }
         this.preloader = true;
         this.getBimLayouts();
  }
  },
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
       this.getBimLayouts();
    },
    reloadeDataAction(){
         this.initViewer();
    },
       getBimLayouts(){
         this.preloader= true
         axios.get('/project/'+this.projectid+'/zoho-invoices-data')
           .then(resp => {

                this.desserts = [];
                // console.log("invoices", resp.data);
                   
                    if(resp.data.error){
                          console.log("resp.data.error", resp.data.error);
                    }else{

                 var ii = 0

                 var  ittems = resp.data.invoices;
                 resp.data.invoices.forEach(i => {
                       i.balance
                     var s =  this.formatter();
                      var b = s.format(i.balance);
                      var t = s.format(i.total);
                      //console.log(v);
                       ittems[ii].balance = b;
                       ittems[ii].total = t;
                      ii++;
                 });


                 //this.desserts = resp.data.invoices;
                 
                 this.desserts = resp.data.invoices.filter(function (item) {
           
                     return item.status !== "draft";
                  });

               //console.log("this.bim_renedaring_data",this.desserts);
                }
               this.preloader = false;
           })
          .catch(err => {
            console.log("BIM images",err)
            this.preloader = false;
           }) 
    },
    getColor (calories) {
        if (calories == 'sent') return '#ff660f'
        else if (calories == 'paid' ) return '#029745'
        else return '#ec665cfc'
      },
       formatter() {
        return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'INR',
		});	
       },
       sendzohoStatment(){
          var person = prompt(this.lang.WhomtoNotify);
	   if(person){
		    this.preloader =true;
	    var to_mail_ids = person;
	    var subject = "OpticVyu Account Statement:"+this.name;
	    //var body = "Dear Customer,<br/><br/>Find attachment of all transactions from your project.<br/>Admin, OpticVyu";
		
   var body = '{"send_from_org_email_id": false,"to_mail_ids": [ '+to_mail_ids+'],"subject": "'+subject+'","body": "Dear Customer,     <br/> <br/>Attached is the statement of all transactions from your project. <br/><br/>~ Admin, OpticVyu"}';
		


     axios({ url: 'https://www.opticvyu.com/dashboard/user/zoho-email-estatmant', params: body, method: 'get' })
           .then(resp => {
                  console.log(resp);
                  this.preloader =true;
                  this.snackbar = true;
         })
          .catch(err => {
            this.preloader =true;
            console.log(err)
           }) 
	       }
       }

  }
};
</script>
<style>
 .arroww {
     text-decoration:none;
 }
 .itemsx {
    text-transform: capitalize;
}
.v-text-field {
    padding-top: 12px;
    margin-top: 4px;
    margin: 0 16px;
 }
 .title_e{
   text-align:center;
   font-size:22px;
 }
 .stament_bu {
    float: right;
    position: absolute;
    right: 12px;
    top: 5px;
}
 .billingtable .v-data-footer{
   width:100% !important;
}
</style>


<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card class="pa-5">
          <v-card-title> Company Info </v-card-title>

           <v-snackbar
            v-model="alert"
            center
            top
            timeout="3000"
            type="success"
          >
            {{ alertmessage }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Ok}}
              </v-btn>
            </template>
          </v-snackbar>
         <!--  <v-btn
            v-if="userinfo"
            @click="userstatus"
            color="mydarkbutton"
            class="userinfonote"
          >
            Check info
          </v-btn> -->
          <v-form
            ref="form"
            v-model="validlogo"
            lazy-validation
            @submit.prevent="submitlogo"
          >
            <v-container>
              <v-row>
                  <v-col cols="4" md="4">
                      <div id="preview">
                        <img v-if="url" :src="url" />
                      </div>
                    <template>
                        <v-file-input
                        show-size
                        :rules="rules"
                        accept="image/png, image/jpeg, image/jpg"
                        prepend-icon="mdi-camera"
                        label="Upload Logo"
                        model="logoImage"
                        @change="logoSelected"
                        ></v-file-input>
                    </template>
                  </v-col>
                <v-col cols="6" md="6">
                       <h3>{{orgdata.org_name}}</h3>
                       
                        <p>{{orgdata.address1}}</p>
                        <p>{{orgdata.address2}} {{orgdata.org_city}},</p>
                        <p>{{orgdata.org_state}} ({{orgdata.org_pin}}) </p> 
                        <p>{{orgdata.org_country}} </p>
                </v-col>

                    <v-col cols="12" md="12">
                <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="!validlogo"
                  color="mylightbutton"
                >
                  {{lang.submit}}
                </v-btn>
                
               </v-col>
              </v-row>
            </v-container>
          </v-form>
  <hr />
    <v-card-title> Project Info </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="currentProjectup.project_name"
                    :label="lang.Name"
                    required
                    :rules="firstnamerules"
                     hint="note: Name might be visible on image timestamp."
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                     v-model="currentProjectup.locality"
                    label="Project Locality/Area"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                     v-model="currentProjectup.city"
                     label="Project City"
                     @blur="keyHandler($event)"
                    required
                    hint="Please enter valid project city name."
                    :rules="firstnamerules"
                     :error="cityerror"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                     v-model="currentProjectup.country"
                    label="Country"
                   
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                   v-model="currentProjectup.latitude"
                    label="Latitude"
                  ></v-text-field>
                </v-col>


                 <v-col cols="12" md="4">
                  <v-text-field
                   v-model="currentProjectup.longitude"
                    label="Longitude"
                  ></v-text-field>
                </v-col>


                   <v-col cols="12" md="4">
                    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="currentProjectup.started_on"
             label="Project Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="currentProjectup.started_on"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                </v-col>
                 <div class="locationpicker">
  <GmapMap
    :center="center"
    :zoom="18"
    map-style-id="roadmap"
    :options="mapOptions"
     style="width: 100%; height: 300px"
    ref="mapRef"
    @click="handleMapClick"
  >
    <GmapMarker
      :position="marker.position"
      :clickable="true"
      :draggable="true"
      @drag="handleMarkerDrag"
      @click="panToMarker"
    />
  </GmapMap>

  <p>Selected Position: {{ marker.position }}</p>
</div>
                <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="!valid"
                  color="mylightbutton"
                >
                  {{lang.submit}}
                </v-btn>
                <v-btn @click="clear" color="mydarkbutton"> {{lang.Cancel}} </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
       
      </v-col>
      <v-col class="setting_sidebar" cols="3" md="3" out-line>
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar,
  },
  name: "Profile",
  data() {
    return {
      valid: true,
      validlogo : true,
      firstname: "",
      firstnamerules: [(v) => !!v || this.lang.required], 
      alert: false,
      alertmessage: "Something went wrong. Please reload and try again.",
      Local :locals,  
      lang : {},
      logoImage : '',
      url : '',
      rules: [
        value => !value || value.size < 2000000 || 'Logo size should be less than 2 MB!',
      ],
      currentProject : '',
      orgdata : '',
      currentProjectup : {
          project_name : '',
          locality : '',
          city : '',
          country : '',
          latitude : '',
          longitude : '',
          started_on : '',
          
      },
      cityerror : false,
      preloader: true,
      menu: false,
       marker: { position: { lat: 10, lng: 10 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: true,
      },
    };
  },
   created() {
       this.getOrg();
   },
  
  mounted() {

      var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];
    this.initViewer();
    this.getPorjects();

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

     console.log("this.currentProject",this.currentProject);
   setTimeout(() => {
   // this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    // this.currentProjectup = {
    //      project_name : this.currentProject.project_name,
    //       locality : this.currentProject.locality,
    //       city : this.currentProject.city,
    //       country : this.currentProject.country,
    //       latitude : this.currentProject.latitude,
    //       longitude : this.currentProject.longitude,
    //       cityid : '',
    //       started_on : this.currentProject.started_on,
    // }
      this.getOrg()
    }, 1000); 
 
     console.log("userdata" ,this.userdata);

     if(this.userdata.user_code == 2){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }     

        this.geolocate();
  },
  watch: {
    "$store.state.currentCamera": function () {
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
       console.log("Current data get",this.$store.state.currentCamera);
         setTimeout(() => {
            this.getOrg();
             
          }, 2000); 
       
    },
  },
  computed: {
    params() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        contact: this.constactNumber,
        project: this.e7[0],
        project_ids: this.e7.toString(),
        user_code: this.usertype,
        cctvtimelapse_user: 0,
        currentProject: {},
        added_by: this.userdata.email,
        
      };
    },
   
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      this.preloader = true;
    },
    logoSelected(e){
       
       this.logoImage = e;
      //const file = e.target.file[0];
      this.url = URL.createObjectURL(e);
    },
    getOrg(){
       this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
       this.currentProjectup = {
              project_name : this.currentProject.project_name,
              locality : this.currentProject.locality,
              city : this.currentProject.city,
              country : this.currentProject.country,
              latitude : this.currentProject.latitude,
              longitude : this.currentProject.longitude,
              cityid : '',
              started_on : this.currentProject.started_on,

           }
               
          var latitude =  parseFloat(this.currentProject.latitude);   

           var longitude =  parseFloat(this.currentProject.longitude);   
               
           this.marker = {position: { lat: latitude, lng: longitude } },
           this.center =  {lat: latitude, lng: longitude},

       this.preloader = true;
        console.log("profile org",this.currentProject);
       var org_id = this.currentProject.organization_id;
       var proj_id = this.currentProject.id;
       
       var data = {
           org_id : org_id
        }

       var url = "/organization/"+proj_id;
       axios({ url: url, method: "GET", params: data })
        .then((resp) => {
             console.log("resp...", resp.data.success);
             this.orgdata = resp.data.success;
             this.url = resp.data.success.org_logo;
             this.preloader = false;
         })
        .catch((err) => {
           console.log(err);
          // this.getOrg();
          // this.$router.go()
            this.preloader = false; 
             this.alert = true;
             this.alertmessage = "Somethings went wrong please reload page."
        });
    },
    keyHandler(e) {

        console.log("country",e.target.value);
        var cityname = e.target.value;
        var url = "/searchCity";
         axios({ url: url, method: "GET",  params: { cityname : cityname } })
        .then((resp) => {
               console.log("resp",resp);
               if(resp.data.cod == 200){
                   
                   this.currentProjectup.cityid = resp.data.id;
                   
                   this.currentProjectup.latitude = resp.data.coord.lat;
                   this.currentProjectup.longitude = resp.data.coord.lon;
                   this.cityerror = false;  
               }else{
                 this.alert =  true;
                 this.alertmessage = 'Please enter valid city name';
                 this.cityerror = true;
               }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    submitlogo(){
       console.log("=====", this.logoImage);

       let formData = new FormData();
       formData.append("logo", this.logoImage);
        formData.append("org_id", this.currentProject.organization_id);
         formData.append("proj_id",this.currentProject.id);

        axios({ url: "/organization/upload_logo", data: formData, method: "POST" })
        .then((resp) => {
               console.log("resp",resp);
        })
        .catch((err) => {
          console.log(err);
        });


    },
    async submit() {
      
      const valid = await this.$refs.form.validate();
     var dd = this.currentProjectup;
      if (valid) {
         this.preloader =  true;
          var proid =  this.currentProject.id;
            var url = "/project-update/"+proid;
            axios({ url: url , data: dd, method: "POST" })
        .then((resp) => {
               console.log("Project Update",resp);  
                  this.preloader =  false;
                this.alert = true;
                this.alertmessage = "Project details update successfully";
                this.getCurrentProject()


             
                // location.reload(true);
         }) .catch((err) => {
            console.log(err);
             this.preloader =  false;
         });
    }
  },
  getCurrentProject(){
       
        let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      
        var projid = currentProject.id;

       axios({
                    url: "/projec/"+projid,
                    method: "get",
                })
                .then((resp) => {
                    console.log("project update date", resp.data);
                   // this.initialSetup(resp.data);
                   localStorage.setItem("currentProject", JSON.stringify(resp.data));

                    setTimeout(() => {
                        location.reload(true);
                    }, 2000); 
                     
                     
                })
                .catch((err) => {
                    console.log("project update date",err);
                  
                });
    },
     geolocate() {
       var _self = this;
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
         _self.currentProjectup.latitude = position.coords.latitude; 
         _self.currentProjectup.longitude = position.coords.longitude;
      });
    },
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
      // this.marker = { position: { lat: this.currentProject.latitude, lng: this.currentProject.longitude } },
      // this.center =  { lat: this.currentProject.latitude, lng: this.currentProject.longitude},
      
       this.currentProjectup.latitude = e.latLng.lat(); 
       this.currentProjectup.longitude = e.latLng.lng();
    }
  }
};
</script>
<style>
.userinfonote {
  position: relative;
  left: 85%;
  margin-top: -13%;
  font-size: 12px !important;
}
.heading {
  color: #252525;
  font-weight: 500;
  font-size: 16px;
}
.username {
  color: #f3bd08;
  font-weight: 500;
  font-size: 16px;
}
#preview{
    height:200px;
    width: 200px;
    background : #ccc;
    border : 1px solid #ccc;
    overflow : hidden;
}
#preview img{
    border : 1px solid #ccc;
    max-width: 100%;
    width :100%;
}
.locationpicker {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    height: 350px;
}
</style>


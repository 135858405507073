export default {
       welcome: 'Welcome',
        SelectCamera :'Select Camera',
        Projects : 'Projects',
        AIData : 'PPE',
        AIDataAnalysis : 'PPE Charts',
        PpeReports : 'PPE Reports',
        Aqi :'Aqi',
        PeopleDetection :'People Detection',
        Archive : 'Archive',
        Live : 'Live',
        Timelapse :'Timelapse',
        WatchTimelapse : 'Watch Timelapse',
        CreateTimelapse :'Create Timelapse',
        Compare :'Compare',
        MobileUpload  :'Mobile Upload',
        Planning :'Planning',
        EditData :'Edit Data',
        CompareEvents :'Compare Events',
        EventChart : 'Event Chart',
        EventTimelapse :'Event Timelapse',
        Report :'Report',
        FavoriteList : 'Favorite List',
        More : 'More',
        EventTimeline : 'Event Timeline',
        SplitScreen : 'Split Screen',
        Back :'Back',
        Menu : 'Menu',
        Options : 'Options',  
        CreateDetailedReport : 'Create Detailed Report',
        InstantReport : 'Instant Report',
        CreateNow : 'Create Now',
        Scheduleit : 'Schedule it',
        PleaseReloadPage : 'Please Reload page',
        ImageLoadError : 'Sorry Image is not load',
        imagenotFound : 'Images not found on the selected date',
        ImageShareSuccessful :'Image Share Successful',
        SomethingWentWrong  :'Something went wrong please try again.',
        ImageShareProcoreSuccessful :'Image share with Procore successful.',
        LayoutSuccessfullyUploaded :'Layout has been successfully uploaded',
        LayoutSuccessfullyDeleted :'Layout has been successfully deleted',
        Logout : 'Logout',
        SuperAdmin : 'Super Admin',
        User : 'User',
        Admin : 'Admin',
        SelectLanguage :'Select Language',
        UpdatePassword : 'Update Password',
        AddUser : 'Add User',
        Users : 'Manage Users',
        UserLogs :'User Logs',
        EmailTrigger : 'Email Trigger',
        CameraOffalert : 'Camera-Off Alerts',
        CameraStatus : 'Camera Status',
        CameraNameAndTag : 'Camera Name and Tag',
        CreateCustomTimelapse : 'Create Custom Timelapse',
        Billing : 'Billing',
        ManageBIMRenderings:'Manage BIM Renderings',
        Integration : 'Integration',
        TwoFactorAuthentication :'Two-Factor Authentication',
        DemoVideos :'Demo Videos',
        Support : 'Support',
        CameraConfiguration :'Camera Configuration',
        ProjectInfo : 'Project Info',
        SearchProjects : 'Search Projects',
        ContactSupport :'Contact support',
        SendEmail :'Send Email',
        MapView: 'Map View',
        SlideShow: 'Slide Show',
        slideShowHeading:'Slide Show',
        ConstructionCamera : 'Construction Camera',
        InteriorMonitoring : 'Interiors Monitoring',
        MaterialTracking : 'Material Tracking',
        DroneMonitoring : 'Drone Monitoring',
        LinearConstructionMonitoring : 'Linear Construction Monitoring',
        InteractiveDashboard : 'Interactive Dashboard',
        To:'To',
        OtherEmails:'Add more emails Here',
        Subject : 'Subject',
        Message : 'Message',
        SelectImage : 'Select Image',
        EmailRequired : 'E-mail is required',
        EmailValid : 'E-mail must be valid',
         required: 'This Field is required',
        Send : 'Send',
        Cancel : 'Cancel',
        Close :'Close',
        Done : 'Done',
        AddNew : 'Add New',
        Ok :'Ok',
        Save : 'Save',
        LiveImage : 'Live Image',
        ImageMarkup : 'Image Markup',
        ShareImage : 'Share Image',
        AddEventTimeline :'Add To Event Timeline',
        RemoveFavorite : 'Remove Favorite',
        AddToFavorite :  'Add To Favorite',
        Weather : 'Weather',
        DownloadImage : 'Download Image',
        FullScreen : 'Full Screen',
        Pleasestandby : 'Please stand by',
        UpdateCameraTag : 'Update Camera Tag',
        BIM : 'BIM',
        FullTimeline : 'Full Timeline',
        ShareToProcore :'Share To Procore',
        WeatherInfo : 'Weather Info',
        CreateNewBIM: 'Create new BIM Renedering',
        OpticVyuUpdates : 'OpticVyu Updates',
        InstallationChecklist : 'Installation Checklist',
        InstallationDetails: 'Installation Details',
        SimCardAdded : 'Sim card added',
        DroneHeading :'Heading',
        ItemRequired : 'Item is required',
        Remoteit :'Remote it',
        InstallationSnap :'Installation Snap',
        CamConfigSnap :'Cam Config Snap',
        SpeedTest :'Speed Test',
        ActivateReport : 'Activate Report',
        SiteLayout : 'Site Layout',
        CamNameTag :  'Cam Name & Tag',
        DeliveryChallan :'Delivery Challan',
        LocationWhatapps : 'Location Whatapps',
        ImageDeletion : 'Image Deletion',
        NolayoutimageAvailable : 'No layout image available',
        Deletelayout : 'Delete layout',
        GoToBilling : 'Go To Billing',
        NotifyTeam:  'Notify Team',
        billingNote : 'Note: Pay all the pending invoice/s to remove this notification.',
        mediaUploadTitle : 'Click to upload a layout',
        DragDrop : 'Drag & Drop',
        EmailFaild : 'Email delivery faild',
        Liveimagenotfound : 'Live image not found',
        removedFavorites :'Image removed from Favorites',
        addedFavourites : 'Image added to Favourites',
        WhomtoNotify : 'Whom to notify (Email)?',
        ShareByEmail :'Share By Email',
        Structure : 'Structure',
        RenederingName : 'RenederingName',
        RenderingInfo : 'Rendering Info',
        DiscribeTheEvent : 'Discribe The Event',
        Imagenotfound : 'Image not found',
        MainView : 'Main View',
        EditView :'Edit View',
        SurveyDate : 'Survey Date',
        SelectZone : 'Select Zone',
        Inventories : 'Inventories',

        Draft : 'Draft',
        AddMaterial :'Add Material',
        Suppliers :'Suppliers',
        EditTask : 'Edit Task',
        selectEvent: "SELECT EVENT", 
        PlannedStartDate  : 'Planned Start Date',
        seeActualStartDate:'click here to see actual start and end date images',
        seeActualEndDate : 'click here to see Planned start and end date images',
        SeePlannedDateImages : 'See Planned Date Images',
        plannedEndDate:'Planned End Date',
        ActualStartDate :'Actual Start Date',
        SaveActualStartDate:'Save Actual Start Date',
        SaveActualEndDate : 'Save Actual End Date',
        StartdateSave : 'Actual Start Date saved successfully',
        EnddateSave : 'Actual End Date saved Successfully',
        Search : 'Search',
        Import :'Import',
        Export : 'Export',
        TaskName : 'Task Name',
        startDateTime:"Start Date-Time",
        endDatetime:'End Date-Time',
        SelectDatetime : 'Select Date-time',
        AddNewTask : 'Add New Task',
        ImportExcelFile : 'Import Excel File',
        Steps : 'Steps',
        stepOne:'1. Export the Excel (.xls) from Primavera / Microsoft project etc.',
        stepTwo: '2. Copy the data in sample.xls without changing its format or column labels',
        stepThree : '3. Upload the sample.xls file.',
        Downloadfile:'Download Sample.xls file',


        RecordUpdatesuccessfully:'Record successfully updated.',
        NewEventsuccess:'New task successfully submitted.',
        taskUplaod : 'Your task was uploaded successfully.',
        submit : 'Submit',
        StartDate : 'Start Date',
        EndDate : 'End Date',
        ActualEndDate :'Actual End Date',
        Actions :'Actions',
        pleaseSelectanyOne :'Please Select any one',
        eventtimenote:'Event time-lapse: Day time only (8AM to 5PM)',
        eventtime24 : 'Event time-lapse: 24 Hrs ',
        videoConfirm : 'Confirm remove this videos ?',
      removealert:'Timelapse video remove Successfully',
      videonotfound : 'Timelapse video is not found.',
      AddNewUser : 'Add New User',
      Checkinfo : 'Check info',
      ManageUser :'Manage User',
      FirstName : 'First Name',
      LastName : 'Last Name',
      Email : 'Email',
      ContactNumber :'Contact Number',
      selectproject: 'Select Projects for user',
      selectProjectOpenAccess: 'Select Projects',
      newUserOpenAccess: 'New user with open access created successfully.',
      updateUserErrorOpenAccess: 'Unable to update user status.',
      createOpenAccessHeading: 'Create Open Access Link',
      createOpenAccessLinkButton: 'Create Link',
      createOpenAccessLinkNavButton:'Open-access Link',
      MakeNormalUser : 'Make Normal User',
      Privileges : 'Privileges',
      SeeExistingUsers : 'See Existing Users',
      MakeAdmin : 'Make Admin',
      ChangePassword : 'Change Password',
      EmailScheduler : 'Email Scheduler',
      CameraTag : 'Camera Tag',
      CameraInfo : 'Camera Info',
      CameraNotification : 'Camera Notification',
      CustomizeTimelapseVideo : 'Customize Time-lapse Video',
      Userinfo : 'User info',
      Name : 'Name',
      CreatedDate :'Created Date',
      Contact : 'Contact',
      Status : 'Status',
      ProjectName :'Project Name',
      Company :'Company',
      City :'City',
      AddMorePorject :'Add More Project',
      usermsgone : 'Access given for construction camera. To manage access for Interior Monitoring & Material tracking, go to manage users',
      usermsgtwo: 'Access given for Interior monitoring module. To manage access for Material tracking, go to manage users',
      usermsgthree :'User added successfully. Login instructions have been emailed to the new user.',
      usererrorp1 :'User already exists. Request your superadmin to go to Options-->Users & give access to',
      usererrorp2 : 'for the current project',
      Default : 'Default',
      CreatenewBIMRenedering :'Create new BIM Renedering',
      createprocore:'Connect with Procore',
      ConnectWithBim360 : ' Connect With Bim 360',
      requestURL:'Request Embedded App URL',
      biminstruction : 'To use the "Embedded App URL" on BIM360 Insight dashboard, follow below instructions',
      instOne: '1. Go to BIM 360 Insight dashboard',
      instTwo: ' 2. Go to Customize',
      instThree : '3. Click on Card Library',
      instfour :'4. Search for a card named "OpticVyu" or "Partner Card" & add.',
      instFive :' 5. Configure the card with appropriate title & OpticVyu "Embedded App URL" ',
      instSix:'6. The camera feeds should be automatically displayed.',
      bimQue :'Which projects you want to show on embedded app ?',
      bimsuccessmsg:'Request registered! You`ll receive the embedded App URL within 2 days via Email.',
      Sim :'Sim',
      Notes: 'Notes',
      CameraReboot :'Camera Reboot',
      ImageInterval :'Image Interval',
      CamType :'Cam Type',
      BackImageTime : 'Back Image Time',
      ViewAddNote:'View/Add Note',
      SimManage:'Sim Manage',
      AddCameraNote :'Add Camera Note',
      AddNote :'Add Note',
      AssignSimcard : 'Assign Sim card',
      Addsimno :'Add sim no.',
      simprovider : 'sim provider',
      Simusagestatus :'Sim usage status',
      ifsimfree : 'if free, with whom?',
      UpdatedBy : 'Updated By',
      camsetting: 'Succesully updated camera setting',
      camfialdsetting :'Failed to update camera setting. Please try again!',
      EditCameraNameandTag:'Edit Camera Name and Tag',
      CameraName : 'Camera Name',
      Reset :'Reset',
      cameranotification:'Camera ON/OFF Notification',
      NotificationActive : 'Notification Active',
      camranotiStatus:'Camera Notification status is in-active, to activate it please click on checkbox',
      cameraActivemsg :'Camera notification activated',
      Resetsuccesful:'Reset succesful.',

      ChangeYourPassword :'Change your password',
      OldPassword :'Old Password', 
      NewPassword : 'New Password',
      ConfirmPassword:'Confirm Password',
      passwordvalid:'Please enter at least one Capital, Small, Numeric and Special character',
      passwordsame: 'Password should be same',
      UploadSpeed : 'Upload Speed',
      OpticVyuPresentation : 'OpticVyu Presentation',
      DateofInstallation :'Date of Installation',
      Designation : 'Designation',
      Person1 : 'Person 1',
      Person2 : 'Person 2',
      Person3 : 'Person 3',
      Person4 : 'Person 4',
      AccountPerson :'Account Person',
      InstallationNote:'Installation Note',
      OVEnginner :'OV Enginner',
      OneTimeTimelapse : 'One Time Timelapse',
      AdvanceTimelapse : 'Advance Timelapse',
      RecurringTimelapse : 'Recurring Timelapse',
      ProjectTimelapse : 'Project Timelapse',
      Refresh : 'Refresh',
      StartTime :'Start Time',
      Endtime : 'End Time',
      TimelapseSpeed:'Timelapse Speed',
      TimelapseQuality :'Timelapse Quality',
      TimelapseAdvancesettings : 'Timelapse Advance settings',
      Selectframeblending : 'Select frame blending',
      SelectVideoSmoothing :' Select Video Smoothing',
      SelectDeflicker : 'Select Deflicker',
      EnterOverlayText:'Enter Overlay Text (optional)',
      timelapsenote1 : '1. Advanced Time-lapse may take up to 8 hours. You will be notified once it is ready.',
      timelapsenote2 : '2. For fast customization, please use the one-time custom timelapse option.',
      projectnote1 : '1. Project Time-lapse may take up to 12 hours. You will be notified once it is ready.',
      selectop1 :'Use advanced blending options only when the camera is steady. When installed at a tower cranes or any unstable support, blending option will detoriate the output quality.',
      selectop2 :'If a camera is installed on unstable support like a tower crane or slender pole etc, use this option to stabilize the shaky time-lapse video.',
      Adalertmessage : 'Start and end date-time should not be greater than today date-time',
      adrequired :'Start date-time and end date-time are mandatory fields.',
      adrequired2 : 'Start date-time should be earlier than end date-time',
      adrequired3 : 'Please select speed and Quality,',
      adrequired4 : 'Please select Advance customization option and it`s respected sub options.',
      adsuccess : 'Advance time-lapse is being processed. You will be notified through the email once it is ready',
      addnotschedule :'Advance time-lapse not scheduled, please try again!',
      upto8 : '1. Advanced Time-lapse may take up to 8 hours. You will be notified once it is ready.<br>2. For fast customization, please use the one-time custom timelapse option.',
      adarror : 'No existing custom timelapse found for this camera.',
      resetsetting: 'Your Setting has been Reset.',
      adnotcreate :'Advance time-lapse not reset, please try again!',
      info1 : 'To view time-lapse videos of multiple cameras in one video, use this option.',
      info2 :'Minimum 2 and maximum 4 cameras can be selected for creating a project  time-lapse video.',
      ptnotifiy :'Project Time-lapse may take up to 12 hours to process. You will be notified once it is ready.',
      proprocess :'Project time-lapse is being processed. You will be notified through the email once it is ready',
      projectTiError :'Project time-lapse not scheduled, please try again!',
      alertmessageminmax :"Choose minimum 2 and maxium 4 cameras",
      projectready :'Project Time-lapse may take up to 12 hours to process. You will be notified once it is ready',
       reseterror:'One time Custom time-lapse not reset, please try again!',
       Daily :'Daily',
       Weekly : 'Weekly',
       Monthly :'Monthly',
       info_rec : 'The system will create a time-lapse video on daily basis as per submitted settings.<br /> Ex: A recurring time-lapse setting for "last 7 days" will create a time-lapse video for the last 7 days on daily basis.',
       Infovidoss :'Create a time-lapse video for custom start/end date, start/end time, video speed & quality required.',
      createTimelapseInfo : 'Project time-lapse: To view time-lapse videos of multiple cameras in one video, use this option. <br />Minimum 2 and maximum 4 cameras can be selected for creating a project  time-lapse video.',
      reccuringtimelapsecheck :'Please check recurring timelapse type',
       alerrecurring : 'Please check recurring timelapse type',
       recurringsave :'Recurring time-lapse setting saved successfully',
       timelapsenotsave:'Timelapse not submitted, please try again!',
       timelapsebingpressed :'One time Custom time-lapse is being processed. You will be notified through the email once it is ready',
       timelapsenotexit:'No existing custom timelapse found for this camera',
       custometimelapseresrt :'Custom time-lapse not reset, please try again',
       recurringsettingsave :'Recurring Timelapse settings save successfully',
       onetimesetting:'One time Timelapse settings save successfully',
       ConnectwithProcore : 'Connect with Procore',
       UserName : 'User Name',
       Module : 'Module',
       Page : 'Page',
       Date :'Date',
       UserType :'User Type',
       selectRange:'Select a Range',
       ManageUsers : 'Manage Users',
       UserProfile : 'User Profile',
       emailhint : 'Email can`t be update',
        selectuserHint:'Select Projects for user',
        Active : 'Active',
        conscamaccess:'Contruction Camera Access',
        MaterialAccess : 'Material Access',
        InteriorviewAccess : 'Interior view Access',
        InteriorEditAccess:'Interior Edit Access',
        DroneEditAccess : 'Drone Edit Access',
        LinearEditAccess : 'Linear Edit Access',
        UserAccess : 'User Access',
        PPEAIDataScheduleReport: "PPE AI Data Schedule Report",
        Reporttype : 'Report type',
        DaiDay:'Daily report will be sent every Day',
        DaiDay1:'Weekly report will be sent every Monday.',
        Dailyreport: 'Daily report',
        Weeklyreport : 'Weekly report',
        ppeReport : 'PPE Report status is in-active, to activate it please click on checkbox',
         ppeActive : 'PPE Repot activated',
         procoreconnection : 'You may connect your Procore account to OpticVyu. This will allow you to easily invite users to join Opticvyu and share images back into Procore',
         opticvyuProject:'OpticVyu Projects',
         linkproject : 'Procore Projects To Link',
         selectprocoreproject : 'Select Procore Project',
         configprocore :'Configure Projects with Procore',
         procoreConnectionSuccess : 'Project Connected to Procore successful.',
         requestembadedcode : 'Request Embedded App Code',
         SendImagery : 'Send Imagery',
         procoreissue : "Procore Connection issue?",
         procorealreadyconnected  : "This proce project aleady connected to OpticVyu Project please go to your Procore project and Rename or Remove current albums and than try again.",
          whichpro : 'Which projects you want to show on embedded app ?',
          procoreconfirm  : 'Are you sure you connect this project with Opticvyu ?',
          procoreDisconnect: 'Project succesfully disconnected for sedingin imagery',
          procorediconfigure : 'Do you want to deconfigure all the connected projects with Procore?',
          deconfigure : 'Deconfigure successfully',
          embadedRequest : 'Request for Embedded App code is successfully registered. You`ll receive the code via email within 2 working days.',
          procoremsg :' You have authorized Opticvyu to communicate with Procore. You may undo this and deauthorize OpticVyu from Procore by clicking',
          here : "here",
          proopticvy : 'For more information on OpticVyu - Procore Integration',
          EmailSchedule:'Email Schedule',
          Selectdays:'Select days', 
          Selecttime:'Select time*',
          resetsuccessfull : 'Reset Successfully',
          twofactoreAuthentication : 'An authentication code will be sent to your email-id while you try to login',
          ClicktoInActive :'Click to In-Active',
          ClicktoActive :'Click to Active',
          Device : 'Device',
          Emailstatement : 'Email statement',
          Reload : 'Reload',
          Statementsentsuccessfully : 'Statement sent successfully',
          InvoiceDate : 'Invoice Date',
          Invoice : 'Invoice',
          WorkOrder : 'Work Order',
          Amount : 'Amount',
          BalanceDue : 'Balance Due',
          DueDate : 'Due Date',
          OverdueBy : 'Overdue By', 
          DefaultTimelapse : 'Default Timelapse',
          CustomTimelapse :'Custom Timelapse',
          ArchiveTimelapse : 'Archive Timelapse',
         
          Advance : 'Advance',
          Project : 'Project',
          Type : 'Type',
          Advanceediting :'Advance editing',
          Deflicker : 'Deflicker',
          Copyurl :'Copy URL',
          MoveTimelapseToArchive:'Move Time-lapse To Archive',
          Addcaptionforarchivetimelapse:'Add caption for archive time-lapse',

          Graphs :'Graphs',
          max7 : 'Max. 7 day`s can be selected',
          last7days:'Note: No data available in last 7 days. Select custom date range to see past dated data.',
          
          clicktogetrelated : 'Click on the chart Bar for getting related Images.',
          PersonsWithRequiredEquipment :'Persons With Required Equipment',
          PersonsWithoutRequiredEquipment : 'Persons Without Required Equipment',
          PersonUnrecognizable :'Person Unrecognizable',
          PersonsIdentified : 'Persons Identified',
          Remark : 'Remark',
          PossibleHealthImpacts :'Possible Health Impacts',
          ColorCode : 'Color Code',
          poor :"Poor",
          VeryPoor :"Very Poor",
          Good : 'Good',
          Satisfactory : 'Satisfactory',
          Moderate : 'Moderate',
          Severe  :'Severe',

          Minimalimpact :'Minimal impact',
          Minorbreathing:'Minor breathing discomfort to sensitive people',
          breathing1:'Breathing discomfort to the people with lungs, asthma and heart diseases',
          breathing2 : 'Breathing discomfort to most people on prolonged exposure',
          breathing3 : 'Respiratory illness on prolonged exposure',
          breathing4 :'Affects healthy people and seriously impacts those with existing diseases',
          AddedOn : 'Added on',
          AdvanceCustomization : 'Advance Customization',
          Speed : 'Speed',
          FrameBlending : 'Frame Blending',
          TimelapseSmoothing : 'Timelapse Smoothing',
          notification :'No archive time-lapse found. Create custom time-lapse & add important videos to archive for future reference',
          notificationtitle : 'Notification',
          ctnotification: 'No custom timelapse available.',
          archiveDelete: 'Archive Timelapse deleted successfully.',
          archiveError : 'Archive-Timelapse not deleted. Please try again.',
          copymsg : 'Copy URL! Link valid for 7 days only',
          timelapsesharesuccess:'Timelapse shared successfully. Please check your mail',
          Quality : 'Quality',
          Delete : 'Delete',
          Share : 'Share',
          Editor :'Editor',
          SavetoCart : 'Save to Cart',
          CartImages : 'Cart Images',
          Deleteallcartimages : 'Delete all cart images',
          selectImagescard :'Select an image from editor to add to cart',
          Info :"Info",
          GenerateReport : 'Generate Report',
          PreviousReports :'Previous Reports',
          CartImageInfo : "CartImage Info",
          Addedby :'Added by',
          Addedat : 'Added at',
          SwitchMode : 'Switch Mode',
          CheckDifference : 'Check Difference',
          OnetimeRecurring :'One-time/Recurring',
          ShowAll : 'Show All',
          Favourites : 'Favourites',
          ImageCapturedTime: 'Image Captured Time',
          ImageAddedTime : 'Image Added Time',
          favouriteremove : 'Successfully image removed from favorite.',
          favoriterror :'Getting error while removing image from favorite',   
          ReportDemo : 'Report Demo',
          ScheduleReport : 'Schedule Report',
          GeneratePdfReport : 'Generate PdfReport',
          MailPdfReport : 'Mail Pdf Report',

          emailsuccessMsg : 'Succesully report shared. Please check your mail',
          emailFailsMSG: 'Failed to share report. Please reload and try again!',
          SelectDate :'Select Date',
          Allprojects :'All projects',
          Runningprojects : 'Running projects',
          Completedprojects : 'Completed projects',
          Albums : 'Albums',
          ImagesVideos :'Images/Videos',
         mobilessupload:'Download OpticVyu app from playstore and upload project photos captured through mobile',
         nodirectry : 'No directory added under this project',
         noImages : 'No image or video uploaded under this directory',
         nodaavailble :'Note: No data available in this date please see past dated data',
         Smoothing : 'Smoothing',
         archivetimelapsedone:'Timelapse successfully moved to archive timelapse',

         biweekly : 'Bi-weekly reports will be sent on 1st & 16th dates of the month',
         monthly : "Monthly report will be sent on 1st date of the month",
         Weeklyprogressreport :'Weekly progress report',
         Biweeklyprogressreport :'Bi-weekly progress report',
         Monthlyprogressreport : 'Monthly progress report',
          nosechedulereport: "No existing active schedule report found for this project.",
          checkreport :'Please check report type',
          EnterEmail: 'Please enter email-id',
          selectCamera : 'Please select camera',

          settingsave :'Schedule-Report setting saved successfully',
           schedulenotsubmit: 'Schedule-Report not submitted, please try again',
           reportReset : 'Schedule-Report reset successfully',
         reportnotseset : 'Schedule-Report not reset, please try again',
        copysuccess : 'Copied successful to archive timelapse',

        //  Interior 

        AddCoordinate : 'Add Coordinate',
        WorkStatus : 'Work Status',
        VisitingDate : 'Visiting Date',
        Category : 'Category',
        Gallery : 'Gallery',
        UploadFiles:'Upload Files',
        NormalImage : 'Normal Image',
        Image : 'Image',
        Video : 'Video',
        RemoveSelected : 'Remove Selected',
        click360video : 'Click to upload 360 Videos',
        UpdateCoordinate : 'Update Coordinate',
        cordinatConfirm : 'Are you sure to add this coordinates.',
        removeCoordinate: 'Are you sure to delete this coordinate.? if yes your all uploaded image and video will be remove permanently from this coordinate.',
        cordianteUpdate :'Coordinate Update Successfully.',
        videoupload : 'Video Uploaded Successfully',
        deleteConfirm : 'Confirm delete this file(s)',
        deletefilesuccess : 'Selected files deleted Successfully',
        Edit : 'Edit',
        UploadDrawing : 'Upload Drawing',
        AddZone : 'Add Zone',
        DrawingName : 'Drawing Name',
        Floor : 'Floor',
        Area: 'Area',
        zoneName : 'Zone Name',
        NewZone : 'New Zone',
        drawingupload : 'Drawing upload Successfull',
        drawingconfirm : 'Confirm delete this Drawing ?',
        drawingremove : 'Drawing Remove Successfull',
        addezone : 'New zone Added Successfull',
        Images360 : '360 Image', 
        NormalImages : 'Normal Images',
         videos360 : '360 Videos',
         Comments : 'Comments',
         Task : 'Task',
         AddComment : 'Add Comment',
         Lock : 'Lock',
         ImportTasks : 'Import Tasks',
         StandardTaskLists:'Standard Task Lists',
         FilterByDate : 'Filter By Date',
         AddNewComment : 'Add New Comment',
         Comment : 'Comment',
         Progress : 'Progress %',
         Tag : 'Tag',
         UpdateTask : 'Update Task',
         DownloadExcel : 'Download Excel',
         OR : 'OR',
         DownloadPDF : ' Download PDF',
         start : 'Start',
         end : 'End',
         addzone :'New zone Added Successfull.',
         addcommnetssucess :'Comment successfully added',
         listconfirmation : 'Are you sure to import the task list ?',
         standdrlistloaded: 'Standard task list loaded',
         newmsg :'New Task Added Succssfully.',
         tasksuccess : 'Task Update Succssfully',
         sureremove : 'Are you sure remove this task ?',
         taskDelete : 'Task Deleted Succssfully',
         removetask :"Are you sure remove selected tasks ?",
         PlannedEnd : 'Planned End ',
         PlannedStart : 'Planned Start',
         DroneImages : 'Drone Images',
         DroneVideo : 'Drone Video',
         UploadMedia : 'Upload Media',
         VisitDate : 'Visit Date',
         AddNewVisitingDate : 'Add New Visiting Date',
         Upload : 'Upload',
         OpenGallery : 'Open Gallery',
         srtfile:'Upload srt File',
         AddVideoInfo : 'Add Video Info',
         MetaList : 'Meta List',
         latlongnotavailble :'Image Latitude and Longitude not available',
         imagesnotavailble : 'Images not available',
         recordadded : 'Record added successfully',
         visitdateadded :'Visit date added Successfully',
         selecteddelte :'Selected files deleted Successfully',
         taskconfirmation : 'Are you sure remove this Info ?',
          recordDeleted : 'Record Deleted Succssfully',
          BulkUpload : 'Bulk Upload',
          DroneVideos : 'Drone Videos',
          DroneDescription:'Description',
          DroneTime:'Time',
          DroneDistance:'Distance',
          DroneHeight:'Height',
          DroneInfoAdd:'Add',
          ReviewDate :'Review Date',
          ReviewImages : 'Review Images',
           reviewImagesandSubmit : 'Images uploaded. Please click on `Review Images` to submit',
           Delayed : 'Delayed',
           Critical : 'Critical',
           Complete : 'Complete',
           Running : 'Running',
           LayoutInsights : 'Layout Insights',
           SelectDrawing : 'Select Drawing',
           AddNewMaterialHeading : 'Add New Material Heading',
           AddNewSubHeading : 'Add New SubHeading',
           AddNewMaterial: 'Add New Material',
           MaterialName : 'Material Name',
           Unit : 'Unit',
           AssetType : 'Asset Type',
           Estimateqty : 'Estimate qty',
           MaterialHeading :'Material Heading',
           Enable : 'Enable',
           MaterialSubHeading : 'Material Sub-Heading',
           integetrequre :'This field Allow only Integers',
           HeadingUpdateSuccessfully:'Heading Update Successfully',
           confirmdelteheading:'Confirm Delete this Heading ?',
           HeadingdeletedSuccessfully: 'Heading deleted Successfully',
           HeadingAddedSuccessfully  :'Heading Added Successfully', 
           SubHeadingUpdateSuccessfully: 'Sub Heading Update Successfully',
           SubheadingdeleteSuccessfully:'Sub-heading delete Successfully',
           SubheadingAddedSuccessfully:'Sub-heading Added Successfully',
           MaterialUpdateSuccessfully : 'Material Update Successfully',
           MaterialDeleteSuccessfully: 'Material Delete Successfully',
           MaterialAddedSuccessfully : 'Material Added Successfully',
           Yourtaskuploadedsuccesfully: 'Your task uploaded succesfully',
           DraftsInventories : 'Drafts Inventories',
           Confirmremovethisitem:'Confirm remove this item ?',
           EnteryDeletedSuccessfully : 'Entery Deleted Successfully',
           goback: 'Go Back', 
           Discription : 'Discription',
           GoToInventory : 'Go To Inventory',
           UpdateInventory : 'Update Inventory',
           DCNo : 'DC No. / Weight Slip',
           PONo : 'PO No',
           VehicleNo : 'Vehicle No',
           SupplierName : 'Supplier Name',
           Rate : 'Rate',
           Quantity : 'Quantity',
           Update : 'Update',
           NewInventory : 'New Inventory',
           InventoryUpdateSuccessfully : 'Inventory Update Successfully',
           InventoryDeletedSuccessfully : 'Inventory Deleted Successfully',
           InventoryAddedSuccessfully : 'Inventory Added Successfully',
           mobileapp : 'Please upload the images from the mobile app to submit inventories.',
           InventorySubmitSuccessfull : 'Inventory Submit Successfull',
           EditRequestNote :'Edit Request Note',
           EditRequest : 'Edit Request',
           InventoryEditRequest:'Inventory Edit Request',
           Commentsforinventoryupdate:'Comments for inventory update',
           RequestSuperadmin:'Request Super-admin',
         
           OverallReport:'Overall Report',
           CumulativeReport : 'Cumulative Report',
           MaterialwiseReport : 'Material-wise Report',
           Requestsuccessfullysent:'Request successfully sent',
           Images : 'Images',
           VehicleEntry : 'Vehicle Entry',
           offtheproject:'off the project site',
          
           VehicleNumberPlate : 'Vehicle Number Plate',
           deliverychalaimage : 'Delivery Chalan & Weight Slip',
           vehivleunloading:'Vehicle Unloading',
           VehicleEmpty : 'Vehicle Empty',
           other : 'Other',
           reflected : 'If table data is not reflected so please try to reload or hard refresh the page',
           ImportSuppliers : 'Import Suppliers',
           SupplieraddedSuccessfull : 'Supplier added Successfully',
           Suppliersfileimportsuccessfully : 'Suppliers file import successfully',
           deleteconfirm:'Confirm Delete Supplier',
            removesucces:'Supplier Remove successfully',
            clear : 'clear',
            Profile : 'Project Info',
            SaveandSend : 'Save and Send',
            SaveandShare : 'Save and Share',
            imgsent:'Image successfully sent',
            CRM:'CRM',
            DailyReport:'Daily Report',
            EveryMonthprogressreport:'Monthly Progress Report',
            BiMonthlyprogressreport:'Bi-Monthly Progress Report',
            Quarterlyprogressreport:'Quarterly Progress Report',
            Halfyearlyprogressreport:'Semi Annual Progress Report',
            
      }
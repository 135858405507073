<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class=""
          
        >
       <v-btn
      color="#f8bb03"
      class="black--text procore tab active"
     @click="setrouter('/dashboard/settings/integration/procore_connection')"
    >
      {{lang.ConnectwithProcore}}
    
    </v-btn>

    <v-btn
      color="#f8bb03 "
      class="black--text bimclass tab"
      @click="setrouter('/dashboard/settings/integration/bim360')"
    >
      {{lang.ConnectWithBim360}}
    </v-btn>

           <div v-if="projectSearchdata.length > 0" class="projectsbox">
               <v-row align="center">
                 <div  class="pa-6"> 

           <span style="color:green" > <span style="position:relative; top:-2px;"> <v-icon color="green" > mdi-check-circle </v-icon> </span> CONNECTED WITH PROCORE ACCOUNT </span>  

    <v-btn
       color="#f8bb03 "
      class="ma-5 mt-5 black--text"
      @click="requestEmbaded()"
    >
         {{lang.requestembadedcode}}
    </v-btn> 
                    
       <p  class="mt-5">
        {{lang.procoremsg}} <a  style="color:blue !important" @click="delinkProcre()">{{lang.here}} </a>  
      </p>

     <p  class="mt-5">
        {{this.lang.proopticvy }}, <span>click <a href="https://blog.opticvyu.com/opticvyu-procore-integration-steps/"  style="color:blue !important" target="_blank">here.</a> </span>
     </p>
     
    </div>
    
      </v-row>
            <table v-if="projectSearchdata.length > 0" class="table">
                <tr>
                   <th> {{lang.OpticVyuProjects}} </th>
                   <th> {{lang.linkproject }} </th>
                   <th> {{lang.SendImagery}} </th>
                   <th>
                      Reset
                  </th>
                </tr>
               <tr v-for="(i, index ) in projectSearchdata" :key="i.id">
                   <td ><strong>{{i.project_name}}</strong>
                            
                 <span v-for="procores in procorProjects" :value="index+'_'+i.id+'_'+procores.project_id+'_'+procores.company_id" :key="procores.id+'_'+procores.company_id"><span v-if="i.procore_proj_id == procores.project_id && i.procore_status == 1">  &nbsp; <v-icon color="green"> mdi-chevron-triple-right </v-icon> <strong>{{procores.company_name}} - {{ procores.projet_name }}</strong></span></span>
              
                   </td>
                   <td>
                   
                  <select class="form-control selectoptns" @change="slectedProjects($event)">
                  <option value="" selected disabled>Select Procore Project </option>
                 
                    <option  v-for="procore in procorProjects" :key="procore.id" :value="index+'_'+i.id+'_'+procore.project_id+'_'+procore.company_id" :selected="i.procore_proj_id == procore.project_id?true:false">{{procore.company_name}} - {{ procore.projet_name }}</option>
                 
                  </select>
                   </td>
                   <td>

                    <v-switch @change="remove(i.id, i.procore_status)"  :value="i.id"  v-model="cororeProjectes" v-if="i.procore_proj_id > 0 "></v-switch>
                   </td>
                   <td>
                    
                    <v-btn
      color="#f8bb03 "
      class="black--text"
      @click="resetproject(i.id)"
    >
      Reset
    </v-btn>
                   </td>
               </tr> 
            </table>

                    
         </div>
             <div class="procorebuttonss" v-if="switches.length > 0 ">
            <!-- <v-btn
      class="ma-2 procorec"
      :loading="loading"
      :disabled="loading"
      @click="connectingProccess"
      
    >
      Configure Projects with Procore   
    </v-btn> -->
    </div>
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
      <v-snackbar
      v-model="snackbar"
      top
      right
      timeout="5000"
      :multi-line="true"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
         P{{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>
    </v-row>
      
        <v-dialog
      v-model="albimwarmnning"
      persistent
      max-width="350"
    >

      <v-card>
        <v-card-title class="text-h5">
          {{lang.procoreissue }}
        </v-card-title>
        <v-card-text> 
          This Procore project is already connected to the OpticVyu project. Please login to your Procore account and "Rename" / "Remove" the below albums/folder and than try again.
 <br> "Timeline Cam#" & "Important" photo albums
 <br> "OpticVyu Camera Reports" document folder
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="green darken-1"
            text
            @click="albimwarmnning = false"
          >
           {{ lang.Ok }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog
      v-model="project_list"
      scrollable
      max-width="450px"
    >
      <v-card>
        <h4 class="pa-4"> {{lang.whichpro}} </h4>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
           <v-checkbox
             v-for="p in projectSearchdata"
              :key="p.project_name"
              v-model="checked_items"
              :label="p.project_name"
              color="#f8bb03"
              :value="p.id"
              hide-details
            ></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="snedEmbadedRequest"
          >
            {{lang.Send}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="project_list = false"
          >
           {{ lang.Cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar
  },
  name: "ProcoreUpdate",
  data() {
    return {
         preloader: false,
         company :'',  
         code : '',
         projectSearchdata : [],
         procorProjects : [],
         switch1 : false,
         connections : [],
         switches : [],
         loading : false,
         coonecount : 0,
         count : 0,
         procore_url: process.env.VUE_APP_PROCORE_URL,
         redirect_url: process.env.VUE_APP_PROCORE_REDIRECT_URL,
         refreshtoken : '',
         companies : {},
         company_selected  :{},
         snackbar : false,
         text : '',
         currentProject : {},
         selected: '562949953603208',
         albimwarmnning : false,
         cororeProjectes : [],
         remoreitems :[],
         project_list : false,
         checked_items : [],
         procoerProject_item : '',
         Local :locals,  
         lang : {}, 
         
     };
  },
  mounted() {
    var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];

     this.initViewer();
     this.userdata = JSON.parse(localStorage.getItem('userdata')); 
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
  },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
    
  }
  },
    computed: {
  
  }, 
  methods: {
   
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false ;

       axios({ url: '/project', method: 'GET' })
          .then(resp => {
           
            var self = this;
             resp.data.success.filter(function(item){
                   
                   if(item.procore_status == 1){
                        
                     //  self.procoerProject_item = item.id;
                         localStorage.setItem('pc_token', item.procore_token);
                         localStorage.setItem('pc_refreshtoken', item.refresh_token);
                        self.code = item.procore_token;
                         self.refreshtoken = item.refresh_token;
                   }

                return item.project_active == 1;         
              });

               this.getCompany();
          })
          .catch(err => {
            console.log(err);
          })

        // var p = localStorage.getItem('procer_project');
        // console.log(p);
        //  if(p == 0){
        //      var url = "/dashboard/settings/integration/procore_connection";
        //       this.$router.push(url);
        //  } 
         
           //this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
         // var proj_id = this.procoerProject_item;
          
          

        //     if(p > 0){
        //    axios({ url: '/project/'+p+'/procore/get-procore-access-tokens', method: 'GET' })
        //   .then(resp => {
        //      console.log("Projects", resp.data);
        //       // this.refreshtoken = resp.data.refresh_token;
        //        localStorage.setItem('pc_token', resp.data.access_token)

        //        this.code = resp.data.access_token;
           
        //         this.getCompany();
        //   })
        //   .catch(err => {
        //     console.log(err);
        //   })
        // }

    },
    
    getCompany(){
              this.preloader = true;
            var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: '/procore/getCompany',
        data : {token:this.code }
      };
      axios(config)
       .then(resp => {

            console.log(resp.data);
   
            this.companies = resp.data;
             this.getPorjects();
             resp.data.forEach(c => {

               if(c.is_active){
                     this.GetProcoreProjects(c);
                     
               }
               
             });
           })
          .catch(err => {
            console.log("Get Project token Expire",err);
            this.preloader = false
          })
    },

    GetProcoreProjects(company){
       
        
       // console.log(this.code);

       

         var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: '/procore/getproject',
        data : {token:this.code, company:company.id} 
      };
      axios(config)
       .then(resp => {
                   this.procorProjects = [];
                this.preloader = false;
               console.log("Procore projects", resp);

              //this.procorProjects = resp.data;
               
               resp.data.forEach(proj => {
                    this.procorProjects.push({
                     project_id: proj.id, 
                     projet_name: proj.display_name,
                     company_id: company.id, 
                     company_name: company.name
                  });
               });

                 console.log(this.procorProjects);
           })
          .catch(err => {
            console.log(err);
            this.preloader = false
          })
    },

    getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            var sself = this;
            this.items = resp.data.success.filter(function(item){

               

                if(item.procore_status == 1){

                   sself.cororeProjectes.push(item.id);

                  sself.remoreitems.push(item.id);
                    sself.code = item.procore_token;
                    sself.refreshtoken =  item.procore_refresh_token;
                    localStorage.setItem('procer_project', item.id);
                  
                   
                }
                return item.project_active == 1;         
              });

              this.projectSearchdata = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              })
              console.log(this.projectSearchdata);
          })
          .catch(err => {
            console.log(err);
          })
      },
      slectedProjects(event){
         // console.log("ok");

         var actiion = confirm(this.lang.procoreconfirm);

           if(actiion){

          var data = event.target.options[event.target.options.selectedIndex].value;
          var res = data.split("_");
          // console.log("slectedProjects",this.connections);
          this.checkProject(res[2], data)
              
          // this.connectingProccess(a);
          }
        
      },
      checkProject(id,data){

         var projectid = { procoerpid : id }

         axios({ url: '/project/check-procore-project',  params : projectid, method: 'GET'})
          .then(resp => {
                //console.log(resp);
              //  return resp;

                  if(resp.data.data){
                    this.albimwarmnning = true;
                  }else{
                    
                    var res = data.split("_");
                    var a  = [{"opticp":res[1], "procorep":res[2], "procore_c_id": res[3]}];
                    this.connections[res[0]] = a;
                    //  var ind = res[0]+"_"+res[1];
                    // this.switches.push(ind);
                    //this.connectingProccess(a);
                  }
              })
          .catch(err => {
            console.log(err);
          })
      },     
      remove(item, procore_status){
               
             //var actiion = confirm("Are you sure you disconnect this project with Opticvyu ?");
             var status = 0;
             if(procore_status == 0){
                 
                 status = 1;
             } 

                var d = {
                   status : status,
                }
           //console.log(item, params);
                
            axios({ url: '/project/'+item+'/procore_remove_project', params : d, method: 'GET' })
            .then(resp => {
             console.log(resp);

              if(status == 0 ){
                 this.text = this.lang.procoreDisconnect;

              }else{
                 this.text =  this.lang.procoreDisconnect; //"Project succesfully connected for sedingin imagery.";

              }
              
             this.snackbar = true;
               this.initViewer();
            })
            .catch(err => {
            console.log(err);
            })
             // delete this.connections[item];
          
      },
      connectingProccess(projectdata){
            this.loading = true;
            this.preloader = true;
                 var cc = 1;
             projectdata.forEach( ()=> {

                 
                   cc++;

                   this.count = cc;
             })


       //     console.log("slectedProjects",this.connections);
          var coonecount = 1;
            projectdata.forEach(item => {

                  console.log("Selected project item", item);

                 var d = {
                     
                     "proj_id": item.opticp,
                     "procore_proj_id": item.procorep,
                     "procore_token": this.code,
                     "refresh_token": this.refreshtoken,
                     "procore_comp_id": item.procore_c_id,
                 };

                    axios({ url: '/project/'+item.opticp+'/procore_project_update', params : d, method: 'GET' })
          .then(resp => {
                console.log(resp);
                this.getCammeras(item.opticp, item.procorep, item.procore_c_id);
          })
          .catch(err => {
            console.log(err);
          })
               coonecount++;

           this.coonecount = coonecount;
            });
      },
       getCammeras(opticvyu_p_id, procore_proj_id, procore_c_id){
       axios({ url: '/camera/'+opticvyu_p_id, method: 'GET' })
          .then(resp => {
               
               

               var camdata = resp.data.success;

                // var d = new Date();
               //var n = d.getTime();
               var mm = (Math.random() + 1).toString(36).substring(7);
                var impconfig = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/image_categories?project_id='+procore_proj_id,
        data : {
            "image_category": {
              "name": "Important-"+mm
            }
          },
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(impconfig)
       .then(resp => {
               
                   console.log("imp album data ", resp);  
                   
                     this.camloop(resp, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                   
                }).catch(err => {
                   this.albimwarmnning = true;
                    console.log("save album error",err);
             })    

         })
       },
       camloop(impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id){

           //console.log("camloop running",camdata);
             var b = 1;
              //  var d = new Date();
              // var n = d.getTime();


            camdata.forEach(a => {

              console.log("camloop running", a);

              var kk = (Math.random() + 1).toString(36).substring(7);
                         if(a.camera_active){
                             
                               var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/image_categories?project_id='+procore_proj_id,
        data : {
             "image_category": { 
              "name": "Timeline Cam"+b+"-"+kk
            }
          },
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(config)
       .then(resp => {
               
                   console.log("Timelapse album data",resp);    
                   
                   this.addAlbum(resp ,impdata, a, opticvyu_p_id,procore_proj_id, procore_c_id)
                })
                 b++;
               }
            });

       },
      addAlbum(albumdata,impdata, camdata, opticvyu_p_id, procore_proj_id, procore_c_id){
           
                     console.log("camdata", impdata)
              
               var dd = {
                     "opticvyu_pro_id": opticvyu_p_id,
                     "procore_comp_id":  procore_c_id,
                     "procore_proj_id": procore_proj_id,
                     "cam_id": camdata.camera_id,
                     "imp_album_id": impdata.data.id,
                     "timeline_album_id" : albumdata.data.id
                 };

                    axios({ url: '/project/'+opticvyu_p_id+'/add_procore_album', params : dd, method: 'GET' })
          .then(resp => {
                console.log(resp);
                console.log("save album data ----> ", this.count, this.coonecount );

                if(this.count == this.coonecount){
                            this.loading = false;
                            this.preloader = false;
                            this.text = this.lang.procoreConnectionSuccess;
                            this.snackbar = true
                }
          })
          .catch(err => {
                   this.albimwarmnning = false;
                    console.log("save album error",err);
             }) 
      },
      setrouter: function (url) {
        if(this.$route.path !== url){
             this.$router.push(url)
          //console.log("current router ", this.$route.path);
        //this.activelink = this.$route.path;
        } 
         
          //console.log(url);
      },
      delinkProcre(){
          var cc = confirm(this.lang.procorediconfigure);
          
            console.log(cc);

          if(cc){
           console.log(this.remoreitems);
           var l = this.remoreitems.length;
           var a = 1
            this.remoreitems.forEach(item => {
              axios({ url: '/project/'+item+'/anuthorize-Procore', method: 'GET'})
          .then(resp => {
                console.log(resp);
                 a++;
                 if(a == l){
                     this.snackbar = true;
                     this.text = this.lang.deconfigure;
                    var url = "/dashboard/settings/integration/procore_connection";
                    this.$router.push(url);
        
                 }
              })
          .catch(err => {
            console.log(err);
             this.snackbar = true;
                     this.text = this.lang.SomethingWentWrong;
          })
              
            });
          }
      },
      requestEmbaded(){
        this.project_list = true;
      },
      resetproject(id){
       
          var cc = confirm("Do you want to disconnect OpticVyu integration with Procore?");
          
            console.log("id--<",id);

          if(cc){
           //console.log(this.remoreitems);
         
               var item =  id;
              axios({ url: '/project/'+item+'/anuthorize-Procore', method: 'GET'})
          .then(resp => {
                  this.currentProject.procore_status = 0;
                  var initialidata =  this.currentProject;
                   localStorage.setItem("currentProject", JSON.stringify(initialidata));
                   localStorage.setItem("procer_project", 0);
                   console.log(resp);
                     this.snackbar = true;
                     this.text = "Project successfully disconnected with Procore.";
                    var url = "/dashboard/settings/integration/procore_connection";
                     setTimeout(() => {
                       this.$router.push(url);
                     }, 2000);
                    
        
               
              })
          .catch(err => {
            console.log(err);
             this.snackbar = true;
                     this.text = this.lang.SomethingWentWrong;
          })
              
           
          }
      
      },
      snedEmbadedRequest(){
          this.preloader = true;
        console.log(this.checked_items);
         var userdata = JSON.parse(localStorage.getItem('userdata'));
          console.log(userdata.email);

             var x = this.checked_items.toString();

          
      var  sharedata = {
        to: this.email,
        message: "This is the Embedded App Code Request for Project id/s "+x+" by Opticvyu user "+userdata.email,
        subject: "Embaded Code Request"
      } 

            axios({
        url: "https://secure.opticvyu.com/api/send-embaded-request",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
             this.preloader = false;
                            this.text = this.lang.embadedRequest; //"Request for Embedded App code is successfully registered. You'll receive the code via email within 2 working days.";
                            this.snackbar = true
                            this.project_list = false
        })
        .catch((err) => {
          console.log(err)
          this.text = this.lang.SomethingWentWrong;
          this.snackbar = true
           this.project_list = false
          this.preloader = false;
        });
      }
    
  }
};
</script>
<style>
.procorebuttons {
    
    margin: 50px;
    height: 49vh;
}
.procorebuttonss{
  text-align: center;
}
a.procoreconnect {
    text-decoration: none;
    text-align: center;
    padding: 23px 14px;
    display: block;
    background: #ffc400;
    width: 198px;
    margin: auto;
    color: #000;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0px 2px 19px 0px #0000006e;
    border: 1px solid #efb804;
}
.table{
  max-width: 1200px;
  margin:auto;
}
.table, td, th {  
  text-align: left;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 5px 15px;
}
.selectoptns {
    border: 1px solid #ccc;
    width: 250px;
    padding: 6px;
}
.projectsbox {
    height: 78vh;
    overflow: auto;
        overflow-x: hidden;
}
.procorec {
    text-align: center;
    background-color: #ffc400 !important;
    color: #000 !important;
    margin: auto !important;
}
.tab {
    width: 50%;
    margin: 0;
    border-radius: 0;
}
.tab.active {
 border-bottom: 2px solid #000;
}
</style>
export default {
    welcome: 'مرحبا',
     SelectCamera :'حدد الكاميرا',
     Projects : 'المشاريع',
     AIData : 'PPE',
     AIDataAnalysis : 'PPE Charts',
     PpeReports : 'PPE التقارير',
     Aqi :'Aqi',
     PeopleDetection :'كشف الناس',
     Archive : 'أرشيف',
     Live : 'حي',
     Timelapse :'تايم لابس',
     WatchTimelapse : 'مشاهدة Timelapse',
     CreateTimelapse :'إنشاء Timelapse',
     Compare :'يقارن',
     MobileUpload  :'تحميل المحمول',
     Planning :'تخطيط',
     EditData :'تحرير البيانات',
     CompareEvents :'قارن الأحداث',
     EventChart : 'مخطط الحدث',
     EventTimelapse :'حدث Timelapse',
     Report :'نقل',
     FavoriteList : 'قائمة المفضلة',
     More : 'أكثر',
     EventTimeline : 'الجدول الزمني للحدث',
     SplitScreen : 'تقسيم الشاشة',
     Back :'عودة',
     Menu : 'قائمة الطعام',
     Options : 'خيارات',  
     CreateDetailedReport : 'إنشاء تقرير مفصل',
     InstantReport : 'تقرير فوري',
     CreateNow : 'اصنع الان',
     Scheduleit : 'الجدول الزمني لها',
     PleaseReloadPage : 'الرجاء إعادة تحميل الصفحة',
     ImageLoadError : 'عذرا لم يتم تحميل الصورة',
     imagenotFound : 'الصور غير موجودة في التاريخ المحدد',
     ImageShareSuccessful :'تم مشاركة الصور بنجاح',
     SomethingWentWrong  :'حدث خطأ ما. أعد المحاولة من فضلك',
     ImageShareProcoreSuccessful :'نجحت مشاركة الصور مع Procore',
     LayoutSuccessfullyUploaded :'تم تحميل التنسيق بنجاح',
     LayoutSuccessfullyDeleted :'تم حذف التخطيط بنجاح',
     Logout : 'تسجيل خروج',
     SuperAdmin : 'مشرف فائق',
     User : 'مستخدم',
     Admin : 'مشرف',
     SelectLanguage :'اختار اللغة',
     UpdatePassword : 'تطوير كلمة السر',
     AddUser : 'إضافة المستخدم',
     Users : 'ادارة المستخدمين',
     UserLogs :'سجلات المستخدم',
     EmailTrigger : 'مشغل البريد الإلكتروني',
     CameraOffalert : 'تنبيه إيقاف تشغيل الكاميرا',
     CameraStatus : 'حالة الكاميرا',
     CameraNameAndTag : 'اسم الكاميرا والعلامة',
     CreateCustomTimelapse : 'إنشاء Timelapse مخصص',
     Billing : 'الفواتير',
     ManageBIMRenderings :'إدارة عروض BIM',
     Integration : 'دمج',
     TwoFactorAuthentication :'توثيق ذو عاملين',
     DemoVideos :'فيديو تجريبي',
     Support : 'يدعم',
     CameraConfiguration :'تكوين الكاميرا',
     ProjectInfo : 'معلومات المشروع',
     SearchProjects : 'البحث عن المشاريع',
     ContactSupport :'اتصل بالدعم',
     SendEmail :'ارسل بريد الكتروني',
     MapView : 'عرض الخريطة',
     ConstructionCamera : 'كاميرا البناء',
     InteriorMonitoring : 'المراقبة الداخلية',
     MaterialTracking : 'تتبع المواد',
     DroneMonitoring : 'مراقبة الطائرات بدون طيار',
     LinearConstructionMonitoring : 'مراقبة البناء الخطي',
     InteractiveDashboard : 'لوحة القيادة التفاعلية',
     To:'ل',
     OtherEmails:'أضف المزيد من رسائل البريد الإلكتروني هنا',
     Subject : 'موضوع',
     Message : 'رسالة',
     SelectImage : 'اختر صورة',
     EmailRequired : 'البريد الالكتروني مطلوب',
     EmailValid : 'يجب ان يكون البريد الاكتروني صحيح',
      required: 'هذه الخانة مطلوبه',
     Send : 'إرسال',
     Cancel : 'يلغي',
     Close :'قريب',
     Done : 'فعله',
     AddNew : 'اضف جديد',
     Ok :'موافق',
     Save : 'يحفظ',
     LiveImage : 'صورة حية',
     ImageMarkup : 'توصيف الصورة',
     ShareImage : 'مشاركة الصورة',
     AddEventTimeline :'إضافة إلى الجدول الزمني للحدث',
     RemoveFavorite : 'إزالة المفضلة',
     AddToFavorite :  'أضف إلى المفضلة',
     Weather : 'طقس',
     DownloadImage : 'تنزيل الصورة',
     FullScreen : 'شاشة كاملة',
     Pleasestandby : 'فضلا ابقى مستعدا',
     UpdateCameraTag : 'تحديث علامة الكاميرا',
     BIM : 'BIM',
     FullTimeline : 'الجدول الزمني الكامل',
     ShareToProcore :'شارك في Procore',
     WeatherInfo : 'معلومات الطقس',
     CreateNewBIM: 'إنشاء BIM Renedering جديد',
     OpticVyuUpdates : 'تحديثات OpticVyu',
     InstallationChecklist : 'قائمة التحقق الخاصة بالتثبيت',
     InstallationDetails: 'تفاصيل التثبيت',
     SimCardAdded : 'تمت إضافة بطاقة SIM',
     title :'لقب',
     ItemRequired : 'العنصر مطلوب',
     Remoteit :'عن بعد',
     InstallationSnap :'تثبيت الخاطف',
     CamConfigSnap :'كاميرا التكوين المفاجئة',
     SpeedTest :'اختبار السرعة',
     ActivateReport : 'تفعيل التقرير',
     SiteLayout : 'تخطيط الموقع',
     CamNameTag :  'اسم الكاميرا والعلامة',
     DeliveryChallan :'تسليم شالان',
     LocationWhatapps : 'موقع Whatsapp',
     ImageDeletion : 'حذف الصورة',
     NolayoutimageAvailable : 'لا توجد صورة تخطيط متاحة',
     Deletelayout : 'حذف التخطيط',
     GoToBilling : 'اذهب إلى الفواتير',
     NotifyTeam:  'أبلغ الفريق',
     billingNote : 'ملاحظة: ادفع جميع الفواتير المعلقة لإزالة هذا الإشعار',
     mediaUploadTitle : 'انقر لتحميل تخطيط',
     DragDrop : 'السحب والإفلات',
     EmailFaild : 'فشل تسليم البريد الإلكتروني',
     Liveimagenotfound : 'لم يتم العثور على الصورة الحية',
     removedFavorites :'تمت إزالة الصورة من المفضلة',
     addedFavourites : 'تمت إضافة الصورة إلى المفضلة',
     WhomtoNotify : 'لمن يخطر (البريد الإلكتروني)؟',
     ShareByEmail :'نشر عن طريق البريد الإلكتروني',
     Structure : 'بنية',
     RenederingName : 'الاسم',
     RenderingInfo : 'تقديم المعلومات',
     DiscribeTheEvent : 'وصف الحدث',
     Imagenotfound : 'الصورة غير موجودة',
     MainView : 'شاشة العرض الرئيسية',
     EditView :'تحرير عرض',
     SurveyDate : 'تاريخ المسح',
     SelectZone : 'حدد المنطقة',
     Inventories : 'الاختبارات',

     Draft : 'مسودة',
     AddMaterial :'أضف مادة',
     Suppliers :'أضف مادة',
     EditTask : 'تحرير المهمة',
     selectEvent: "حدد الحدث", 
     PlannedStartDate  : 'تاريخ البدء المخطط',
     seeActualStartDate:'انقر هنا لمشاهدة صور تاريخ البدء والانتهاء الفعلي',
     seeActualEndDate : 'انقر هنا لمشاهدة صور تاريخ البدء والانتهاء الفعلي',
     SeePlannedDateImages : 'انظر صور التاريخ المخطط',
     plannedEndDate:'تاريخ الانتهاء المخطط',
     ActualStartDate :'تاريخ البدء الفعلي',
     SaveActualStartDate:'احفظ تاريخ البدء الفعلي',
     SaveActualEndDate : 'احفظ تاريخ الانتهاء الفعلي',
     StartdateSave : 'تم حفظ بيانات البدء الفعلية بنجاح',
     EnddateSave : 'تم حفظ تاريخ الانتهاء الفعلي بنجاح',
     Search : 'بحث',
     Import :'يستورد',
     Export : 'يصدر',
     TaskName : 'اسم المهمة',
     startDateTime:"تاريخ البدء - الوقت",
     endDatetime:'تاريخ الانتهاء',
     SelectDatetime : 'حدد التاريخ والوقت',
     AddNewTask : 'إضافة مهمة جديدة',
     ImportExcelFile : 'استيراد ملف Excel',
     Steps : 'خطوات',
     stepOne:'1. تصدير ملف Excel (.xls) من مشروع Primavera / Microsoft وما إلى ذلك.',
     stepTwo: '2. انسخ البيانات في sample.xls دون تغيير تنسيقها أو تسميات الأعمدة',
     stepThree : '3. تحميل ملف sample.xls.',
     Downloadfile:'قم بتنزيل ملف Sample.xls',


     RecordUpdatesuccessfully:'سجل التحديث بنجاح',
     NewEventsuccess:'تم إرسال مهمة الحدث الجديد بنجاح',
     taskUplaod : 'تم تحميل مهمتك بنجاح',
     submit : 'إرسال',
     StartDate : 'تاريخ البدء',
     EndDate : 'تاريخ الانتهاء',
     ActualEndDate :'تاريخ الانتهاء الفعلي',
     Actions :'أجراءات',
     pleaseSelectanyOne :'الرجاء تحديد أي واحد',
     eventtimenote:'الفاصل الزمني للحدث: وقت النهار فقط (من 8 صباحًا إلى 5 مساءً)',
     eventtime24 : 'الفاصل الزمني للحدث: 24 ساعة ',
     videoConfirm : 'تأكيد إزالة مقاطع الفيديو هذه؟',
   removealert:'تمت إزالة فيديو Timelapse بنجاح',
   videonotfound : 'لم يتم العثور على فيديو Timelapse.',
   AddNewUser : 'إضافة مستخدم جديد',
   Checkinfo : 'تحقق من المعلومات',
   ManageUser :'إدارة المستخدم',
   FirstName : 'الاسم الاول',
   LastName : 'اسم العائلة',
   Email : 'بريد الالكتروني',
   ContactNumber :'رقم الاتصال',
  selectproject: 'حدد المشاريع للمستخدم',
   selectProjectOpenAccess: 'حدد المشاريع',
  newUserOpenAccess: 'مستخدم جديد مع وصول مفتوح تم إنشاؤه بنجاح',
  updateUserErrorOpenAccess: 'غير قادر على تحديث حالة المستخدم',
  createOpenAccessHeading: 'إنشاء ارتباط مفتوح الوصول',
  createOpenAccessLinkButton: 'إنشاء رابط',
  createOpenAccessLinkNavButton:'رابط الوصول المفتوح',
   MakeNormalUser : 'جعل المستخدم العادي',
   Privileges : 'الامتيازات',
   SeeExistingUsers : 'انظر المستخدمون الحاليون',
   MakeAdmin : 'جعل المسؤول',
   ChangePassword : 'تغيير كلمة المرور',
   EmailScheduler : 'برنامج جدولة البريد الإلكتروني',
   CameraTag : 'علامة الكاميرا',
   CameraInfo : 'معلومات الكاميرا',
   CameraNotification : 'إخطار الكاميرا',
   CustomizeTimelapseVideo : 'تخصيص فيديو الفاصل الزمني',
   Userinfo : 'معلومات المستخدم',
   Name : 'اسم',
   CreatedDate :'تاريخ الإنشاء',
   Contact : 'اتصل',
   Status : 'حالة',
   ProjectName :'اسم المشروع',
   Company :'شركة',
   City :'مدينة',
   AddMorePorject :'أضف المزيد من المشروع',
   usermsgone : 'تم منح الوصول لكاميرا البناء. لإدارة الوصول للمراقبة الداخلية وتتبع المواد ، انتقل إلى إدارة المستخدمين',
   usermsgtwo: 'منح الوصول لوحدة المراقبة الداخلية. لإدارة الوصول إلى تتبع المواد ، انتقل إلى إدارة المستخدمين',
   usermsgthree :'تمت إضافة المستخدم بنجاح. تم إرسال تعليمات تسجيل الدخول بالبريد الإلكتروني إلى المستخدم الجديد',
   usererrorp1 :'المستخدم موجود اصلا. اطلب من المشرف المميز الخاص بك الانتقال إلى خيارات -> المستخدمون ومنح حق الوصول إلى',
   usererrorp2 : 'للمشروع الحالي',
   Default : 'تقصير',
   CreatenewBIMRenedering :'إنشاء BIM Renedering جديد',
   createprocore:'تواصل مع Procore',
   ConnectWithBim360 : 'تواصل مع Bim 360',
   requestURL:'طلب عنوان URL للتطبيق المضمن',
   biminstruction : 'لاستخدام "عنوان URL للتطبيق المضمن" في لوحة معلومات BIM360 Insight ، اتبع الإرشادات أدناه',
   instOne: '1. انتقل إلى لوحة معلومات BIM 360 Insight',
   instTwo: '2. انتقل إلى تخصيص',
   instThree : '3. انقر فوق مكتبة البطاقات',
   instfour :'4. ابحث عن بطاقة باسم "OpticVyu" أو "بطاقة الشريك" وأضفها.',
   instFive :' 5. تكوين البطاقة بعنوان مناسب & OpticVyu "عنوان URL للتطبيق المضمن" ',
   instSix:'6. يجب أن يتم عرض تغذية الكاميرا تلقائيًا.',
   bimQue :'ما المشاريع التي تريد عرضها في التطبيق المضمن؟',
   bimsuccessmsg:'طلب مسجل! ستتلقى عنوان URL للتطبيق المضمن في غضون يومين عبر البريد الإلكتروني.',
   Sim :'Sim',
   Notes: 'ملحوظات',
   CameraReboot :'إعادة تشغيل الكاميرا',
   ImageInterval :'الفاصل الزمني للصورة',
   CamType :'نوع الكاميرا',
   BackImageTime : 'رجوع وقت الصورة',
   ViewAddNote:'عرض / إضافة ملاحظة',
   SimManage:'إدارة سيممدير سيم',
   AddCameraNote :'أضف ملاحظة الكاميرا',
   AddNote :'اضف ملاحظة',
   AssignSimcard : 'تعيين بطاقة Sim',
   Addsimno :'إضافة سيم لا.',
   simprovider : 'مزود سيم',
   Simusagestatus :'حالة استخدام سيم',
   ifsimfree : 'إذا كانت حرة ، مع من؟',
   UpdatedBy : 'تم التحديث بواسطة',
   camsetting: 'تم تحديث إعدادات الكاميرا بنجاح',
   camfialdsetting :'فشل تحديث إعدادات الكاميرا. حاول مرة اخرى!',
   EditCameraNameandTag:'تحرير اسم الكاميرا والعلامة',
   CameraName : 'اسم الكاميرا',
   Reset :'إعادة ضبط',
   cameranotification:'إخطار تشغيل / إيقاف الكاميرا',
   NotificationActive : 'الإعلام نشط',
   camranotiStatus:'حالة إخطار الكاميرا غير نشطة ، لتنشيطها ، يرجى النقر فوق مربع الاختيار',
   cameraActivemsg :'تم تفعيل إشعار الكاميرا',
   Resetsuccesful:'تمت إعادة التعيين بنجاح',

   ChangeYourPassword :'غير كلمة المرور الخاصة بك',
   OldPassword :'كلمة سر قديمة',
   NewPassword : 'كلمة السر الجديدة',
   ConfirmPassword:'تأكيد كلمة المرور',
   passwordvalid:'الرجاء إدخال حرف كبير وصغير ورقمي وخاص واحد على الأقل',
   passwordsame: 'يجب أن تكون كلمة المرور هي نفسها',
   UploadSpeed : 'سرعة التحميل',
   OpticVyuPresentation : 'عرض OpticVyu',
   DateofInstallation :'تاريخ التثبيت',
   Designation : 'تعيين',
   Person1 : 'الشخص 1',
   Person2 : 'الشخص 2',
   Person3 : 'الشخص 3',
   Person4 : 'الشخص 4',
   AccountPerson :'شخص الحساب',
   InstallationNote:'ملاحظة التثبيت',
   OVEnginner :'مهندس OV',
   OneTimeTimelapse : 'مرة واحدة Timelapse',
   AdvanceTimelapse : 'تقدم Timelapse',
   RecurringTimelapse : 'متكرر Timelapse',
   ProjectTimelapse : 'مشروع Timelapse',
   Refresh : 'ينعش',
   StartTime :'وقت البدء',
   Endtime : 'وقت النهاية',
   TimelapseSpeed:'سرعة الفاصل الزمني',
   TimelapseQuality :'جودة Timelapse',
   TimelapseAdvancesettings : 'الإعدادات المتقدمة لـ Time Lapse',
   Selectframeblending : 'حدد مزج الإطار',
   SelectVideoSmoothing :' حدد تنعيم الفيديو',
   SelectDeflicker : 'حدد Deflicker',
   EnterOverlayText:'أدخل نص تراكب (اختياري)',
   timelapsenote1 : '1. قد يستغرق الفاصل الزمني المتقدم ما يصل إلى 8 ساعات. سيتم إخطارك بمجرد أن يصبح جاهزًا',
   timelapsenote2 : '2. من أجل التخصيص السريع ، يرجى استخدام خيار الفاصل الزمني المخصص لمرة واحدة.',
   projectnote1 : '1. قد تستغرق الفاصل الزمني للمشروع ما يصل إلى 12 ساعة. سيتم إخطارك بمجرد أن يصبح جاهزًا.',
   selectop1 :'استخدم خيارات المزج المتقدمة فقط عندما تكون الكاميرا ثابتة. عند تركيبه على رافعة برجية أو أي دعم غير مستقر ، فإن خيار المزج سوف يقلل من جودة الخرج.',
   selectop2 :'إذا تم تثبيت الكاميرا على دعم غير مستقر مثل رافعة برجية أو عمود رفيع وما إلى ذلك ، فاستخدم هذا الخيار لتثبيت فيديو الفاصل الزمني المهتز.',
   Adalertmessage : 'يجب ألا يكون وقت تاريخ البدء والانتهاء أكبر من وقت تاريخ اليوم',
   adrequired :'يعد تاريخ البدء ووقت التاريخ ووقت تاريخ الانتهاء حقلين إلزاميين.',
   adrequired2 : 'يجب أن يكون تاريخ ووقت البدء قبل تاريخ الانتهاء',
   adrequired3 : 'الرجاء تحديد السرعة والجودة',
   adrequired4 : 'يرجى تحديد خيار التخصيص المتقدم وهو خيارات فرعية محترمة',
   adsuccess : 'يتم معالجة الفاصل الزمني المتقدم. سيتم إخطارك عبر البريد الإلكتروني بمجرد أن يصبح جاهزًا',
   addnotschedule :'الفاصل الزمني المسبق غير مجدول ، يرجى المحاولة مرة أخرى!',
   upto8 : '1. قد يستغرق الفاصل الزمني المتقدم ما يصل إلى 8 ساعات. سيتم إخطارك بمجرد أن يصبح جاهزًا. <br> 2. للتخصيص السريع ، يرجى استخدام خيار الفاصل الزمني المخصص لمرة واحدة.',
   adarror : 'لم يتم العثور على اللقطات المتتابعة المخصصة الحالية لهذه الكاميرا',
   resetsetting: 'تم إعادة تعيين الإعداد الخاص بك',
   adnotcreate :'لم يتم إعادة تعيين الفاصل الزمني المسبق ، يرجى المحاولة مرة أخرى!',
   info1 : 'لعرض مقاطع فيديو بفاصل زمني لكاميرات متعددة في فيديو واحد ، استخدم هذا الخيار.',
   info2 :'يمكن تحديد الحد الأدنى 2 و 4 كاميرات كحد أقصى لإنشاء فيديو بفاصل زمني للمشروع.',
   ptnotifiy :'قد تستغرق معالجة الفاصل الزمني للمشروع ما يصل إلى 12 ساعة. سيتم إخطارك بمجرد أن يصبح جاهزًا.',
   proprocess :'يتم معالجة الفاصل الزمني للمشروع. سيتم إخطارك عبر البريد الإلكتروني بمجرد أن يصبح جاهزًا',
   projectTiError :'الفاصل الزمني للمشروع غير مجدول ، يرجى المحاولة مرة أخرى!',
   alertmessageminmax :"اختر كاميراتين بحد أدنى و 4 كاميرات كحد أقصى",
   projectready :'قد تستغرق معالجة الفاصل الزمني للمشروع ما يصل إلى 12 ساعة. سيتم إخطارك بمجرد أن يصبح جاهزًا',
    reseterror:'لم يتم إعادة تعيين الفاصل الزمني المخصص لمرة واحدة ، يرجى المحاولة مرة أخرى!',
    Daily :'اليومي',
    Weekly : 'أسبوعي',
    Monthly :'شهريا',
    info_rec : 'سينشئ النظام فيديو بفاصل زمني على أساس يومي وفقًا للإعدادات المرسلة. <br /> مثال: سيؤدي إعداد الفاصل الزمني المتكرر لـ "آخر 7 أيام" إلى إنشاء فيديو بفاصل زمني لآخر 7 أيام على أساس يومي.',
    Infovidoss :'قم بإنشاء فيديو بفاصل زمني لتاريخ البدء / الانتهاء المخصص ، ووقت البدء / الانتهاء ، وسرعة الفيديو والجودة المطلوبة.',
   createTimelapseInfo : 'الفاصل الزمني للمشروع: لعرض مقاطع فيديو بفواصل زمنية لكاميرات متعددة في فيديو واحد ، استخدم هذا الخيار. <br /> يمكن تحديد الحد الأدنى 2 و 4 كاميرات كحد أقصى لإنشاء فيديو بفاصل زمني للمشروع.',
   reccuringtimelapsecheck :'يرجى التحقق من نوع الفاصل الزمني المتكرر',
    alerrecurring : 'يرجى التحقق من نوع الفاصل الزمني المتكرر',
    recurringsave :'تم حفظ إعدادات الفاصل الزمني المتكرر بنجاح',
    timelapsenotsave:'لم يتم تقديم الفاصل الزمني ، يرجى المحاولة مرة أخرى!',
    timelapsebingpressed :'تتم معالجة الفاصل الزمني المخصص لمرة واحدة. سيتم إخطارك عبر البريد الإلكتروني بمجرد أن يصبح جاهزًا',
    timelapsenotexit:'لم يتم العثور على اللقطات المتتابعة المخصصة الحالية لهذه الكاميرا',
    custometimelapseresrt :'لم يتم إعادة ضبط الفاصل الزمني المخصص ، يرجى المحاولة مرة أخرى',
    recurringsettingsave :'حفظ إعدادات Timelapse المتكررة بنجاح',
    onetimesetting:'تم حفظ إعدادات Timelapse مرة واحدة بنجاح',
    ConnectwithProcore : 'تواصل مع Procore',
    UserName : 'اسم االمستخدم',
    Module : 'وحدة',
    Page : 'صفحة',
    Date :'تاريخ',
    UserType :'نوع المستخدم',
    selectRange:'حدد النطاق',
    ManageUsers : 'ادارة المستخدمين',
    UserProfile : 'ملف تعريفي للمستخدم',
    emailhint : 'لا يمكن تحديث البريد الإلكتروني',
     selectuserHint:'حدد المشاريع للمستخدم',
     Active : 'نشيط',
     conscamaccess:'الوصول إلى كاميرا البناء',
     MaterialAccess : 'الوصول المادي',
     InteriorviewAccess : 'الوصول إلى الرؤية الداخلية',
     InteriorEditAccess:'الوصول إلى التعديل الداخلي',
     DroneEditAccess : 'الوصول إلى تحرير الطائرة بدون طيار',
     LinearEditAccess : 'الوصول إلى التحرير الخطي',
     UserAccess : 'وصول المستخدم',
     PPEAIDataScheduleReport: "تقرير جدول بيانات PPE AI",
     Reporttype : 'نوع التقرير',
     DaiDay:'سيتم إرسال التقرير اليومي كل يوم',
     DaiDay1:'سيتم إرسال التقرير الأسبوعي كل يوم اثنين.',
     Dailyreport: 'تقرير يومي',
     Weeklyreport : 'تقرير أسبوعي',
     ppeReport : 'حالة تقرير معدات الوقاية الشخصية نشطة ، لتنشيطها ، يرجى النقر فوق مربع الاختيار',
      ppeActive : 'تم تفعيل معدات الوقاية الشخصية',
      procoreconnection : 'يمكنك توصيل حساب Procore الخاص بك بـ OpticVyu. سيسمح لك ذلك بدعوة المستخدمين بسهولة للانضمام إلى Opticvyu ومشاركة الصور مرة أخرى في Procore',
      opticvyuProject:'مشاريع OpticVyu',
      linkproject : 'معالجة المشاريع للارتباط',
      selectprocoreproject : 'حدد مشروع Procore',
      configprocore :'تكوين المشاريع مع Procore',
      procoreConnectionSuccess : 'المشروع متصل بـ Procore ناجح',
      requestembadedcode : 'طلب رمز التطبيق المضمن',
      SendImagery : 'أرسل الصور',
      procoreissue : "مشكلة في اتصال المعالج؟",
      procorealreadyconnected  : "هذا المشروع العملي متصل بالفعل بمشروع OpticVyu ، يرجى الانتقال إلى مشروع Procore وإعادة تسمية الألبومات الحالية أو إزالتها ثم المحاولة مرة أخرى.",
       whichpro : 'ما هي المشاريع التي تريد عرضها في التطبيق المضمن؟',
       procoreconfirm  : 'هل أنت متأكد من توصيل هذا المشروع بـ Opticvyu؟',
       procoreDisconnect: 'تم قطع اتصال المشروع بنجاح لإرسال الصور',
       procorediconfigure : 'هل تريد إلغاء تكوين جميع المشاريع المتصلة باستخدام Procore؟',
       deconfigure : 'تفكيك التكوين بنجاح',
       embadedRequest : 'تم تسجيل طلب رمز التطبيق المضمن بنجاح. ستتلقى الرمز عبر البريد الإلكتروني في غضون يومين عمل.',
       procoremsg :' لقد فوضت Opticvyu بالاتصال بـ Procore. يمكنك التراجع عن هذا وإلغاء ترخيص OpticVyu من Procore بالنقر فوق',
       here : "هنا",
       proopticvy : 'لمزيد من المعلومات حول OpticVyu - Procore Integration',
       EmailSchedule:'جدول البريد الإلكتروني',
       Selectdays:'حدد الأيام',
       Selecttime:'حدد الوقت (IST) *',
       resetsuccessfull : 'إعادة التعيين بنجاح',
       twofactoreAuthentication : 'سيتم إرسال رمز المصادقة إلى معرف البريد الإلكتروني الخاص بك أثناء محاولة تسجيل الدخول',
       ClicktoInActive :'انقر على غير نشط',
       ClicktoActive :'انقر للنشط',
       Device : 'جهاز',
       Emailstatement : 'بيان البريد الإلكتروني',
       Reload : 'إعادة تحميل',
       Statementsentsuccessfully : 'تم إرسال البيان بنجاح',
       InvoiceDate : 'تاريخ الفاتورة',
       Invoice : 'فاتورة',
       WorkOrder : 'طلب العمل',
       Amount : 'مقدار',
       BalanceDue : 'الرصيد المستحق',
       DueDate : 'تاريخ الاستحقاق',
       OverdueBy : 'تأخر', 
       DefaultTimelapse : 'Timelapse افتراضي',
       CustomTimelapse :'Timelapse مخصص',
       ArchiveTimelapse : 'أرشفة Timelapse',
      
       Advance : 'يتقدم',
       Project : 'المشروع',
       Type : 'نوع',
       Advanceediting :'التحرير المتقدم',
       Deflicker : 'معاد الوميض',
       Copyurl :'إنسخ الرابط',
       MoveTimelapseToArchive:'نقل الفاصل الزمني إلى الأرشيف',
       Addcaptionforarchivetimelapse:'أضف تعليقًا لأرشيف الفاصل الزمني',

       Graphs :'الرسوم البيانية',
       max7 : 'الأعلى. يمكن تحديد 7 أيام',
       last7days:'ملاحظة: لا توجد بيانات متاحة في آخر 7 أيام. حدد نطاقًا زمنيًا مخصصًا لمشاهدة البيانات المؤرخة السابقة.',
       
       clicktogetrelated : 'انقر فوق شريط الرسم البياني للحصول على الصور ذات الصلة.',
       PersonsWithRequiredEquipment :'الأشخاص بالمعدات المطلوبة',
       PersonsWithoutRequiredEquipment : 'الأشخاص بدون المعدات المطلوبة',
       PersonUnrecognizable :'لا يمكن التعرف على الشخص',
       PersonsIdentified : 'الأشخاص المحددين',
       Remark : 'ملاحظة',
       PossibleHealthImpacts :'الآثار الصحية المحتملة',
       ColorCode : 'رمز اللون',
       poor :"فقير",
       VeryPoor :"فقير جدا",
       Good : 'حسن',
       Satisfactory : 'مرض',
       Moderate : 'معتدل',
       Severe  :'شديدة',

       Minimalimpact :'تأثير ضئيل',
       Minorbreathing:'ضيق التنفس البسيط للأشخاص الحساسين',
       breathing1:'ضيق التنفس للأشخاص المصابين بالرئتين والربو وأمراض القلب',
       breathing2 : 'الشعور بعدم الراحة في التنفس لمعظم الناس عند التعرض لفترة طويلة',
       breathing3 : 'أمراض الجهاز التنفسي عند التعرض لفترات طويلة',
       breathing4 :'تصيب الأشخاص الأصحاء وتؤثر بشكل خطير على المصابين بأمراض موجودة',
       AddedOn : 'وأضاف في',
       AdvanceCustomization : 'التخصيص المتقدم',
       Speed : 'سرعة',
       FrameBlending : 'إطار مزج',
       TimelapseSmoothing : 'تجانس Timelapse',
       notification :'لم يتم العثور على أرشيف الفاصل الزمني. قم بإنشاء فاصل زمني مخصص وإضافة مقاطع فيديو مهمة للأرشفة للرجوع إليها في المستقبل',
       notificationtitle : 'تنبيه',
       archiveDelete: 'تم حذف أرشيف Timelapse بنجاح.',
       archiveError : 'الأرشيف - Timelapse لم يتم حذفه. حاول مرة اخرى.',
       copymsg : 'إنسخ الرابط! الرابط صالح لمدة 7 أيام فقط',
       timelapsesharesuccess:'تمت مشاركة Timelapse بنجاح. رجاء تفحص بريدك الإلكتروني',
       Quality : 'جودة',
       Delete : 'حذف',
       Share : 'شارك',
       Editor :'محرر',
       SavetoCart : 'حفظ إلى السلة',
       CartImages : 'صور عربة',
       Deleteallcartimages : 'احذف جميع صور سلة التسوق',
       selectImagescard :'حدد صورة من المحرر لإضافتها إلى عربة التسوق',
       Info :"معلومات",
       GenerateReport : 'انشاء تقرير',
       PreviousReports :'التقارير السابقة',
       CartImageInfo : "معلومات صورة عربة التسوق",
       Addedby :'أضيفت من قبل',
       Addedat : 'تمت الإضافة في',
       SwitchMode : 'تغيير النظام',
       CheckDifference : 'تحقق من الفرق',
       OnetimeRecurring :'لمرة واحدة / متكرر',
       ShowAll : 'عرض الكل',
       Favourites : 'المفضلة',
       ImageCapturedTime: 'وقت التقاط الصورة',
       ImageAddedTime : 'وقت إضافة الصورة',
       favouriteremove : 'تمت إزالة الصورة بنجاح من المفضلة.',
       favoriterror :'الحصول على خطأ أثناء إزالة الصورة من المفضلة',   
       ReportDemo : 'تقرير تجريبي',
       ScheduleReport : 'تقرير الجدول',
       GeneratePdfReport : 'إنشاء تقرير PDF',
       MailPdfReport : 'تقرير Mail Pdf',

       emailsuccessMsg : 'تمت مشاركة التقرير بنجاح. رجاء تفحص بريدك الإلكتروني',
       emailFailsMSG: 'فشل في مشاركة التقرير. يرجى إعادة التحميل وحاول مرة أخرى!',
       SelectDate :'حدد تاريخ',
       Allprojects :'كل المشاريع',
       Runningprojects : 'المشاريع الجارية',
       Completedprojects : 'المشاريع المنجزة',
       Albums : 'ألبومات',
       ImagesVideos :'الصور / الفيديو',
      mobilessupload:'قم بتنزيل تطبيق OpticVyu من playstore وقم بتحميل صور المشروع الملتقطة عبر الهاتف المحمول',
      nodirectry : 'لم يضاف دليل تحت هذا المشروع',
      noImages : 'لم يتم تحميل أي صورة أو فيديو تحت هذا الدليل',
      nodaavailble :'ملاحظة: لا توجد بيانات متاحة في هذا التاريخ ، يرجى الاطلاع على البيانات القديمة',
      Smoothing : 'التنعيم',
      archivetimelapsedone:'تم نقل Timelapse بنجاح إلى أرشيف اللقطات المتتابعة',

      biweekly : 'سيتم إرسال التقارير نصف الأسبوعية في اليوم الأول والسادس عشر من الشهر',
      monthly : "سيتم إرسال التقرير الشهري في اليوم الأول من الشهر",
      Weeklyprogressreport :'تقرير التقدم الأسبوعي',
      Biweeklyprogressreport :'تقرير التقدم نصف الأسبوعي',
      Monthlyprogressreport : 'تقرير الإنجاز الشهري',
       nosechedulereport: "لم يتم العثور على تقرير جدول نشط موجود لهذا المشروع.",
       checkreport :'الرجاء التحقق من نوع التقرير',
       EnterEmail: 'الرجاء إدخال معرف البريد الإلكتروني',
       selectCamera : 'الرجاء تحديد الكاميرا',

       settingsave :'تم حفظ إعدادات الجدول الزمني للتقرير بنجاح',
        schedulenotsubmit: 'لم يتم تقديم تقرير الجدول الزمني ، يرجى المحاولة مرة أخرى',
        reportReset : 'تمت إعادة تعيين الجدول الزمني للتقرير بنجاح',
      reportnotseset : 'لم يتم إعادة تعيين تقرير الجدول الزمني ، يرجى المحاولة مرة أخرى',
     copysuccess : 'تم النسخ بنجاح لأرشفة الفاصل الزمني',

     //  Interior 

     AddCoordinate : 'أضف الإحداثيات',
     WorkStatus : 'حالة العمل',
     VisitingDate : 'تاريخ الزيارة',
     Category : 'فئة',
     Gallery : 'صالة عرض',
     UploadFiles:'تحميل الملفات',
     NormalImage : 'صورة عادية',
     Image : 'صورة',
     Video : 'فيديو',
     RemoveSelected : 'ازل المحدد',
     click360video : 'انقر لتحميل 360 فيديو',
     UpdateCoordinate : 'تنسيق التحديث',
     cordinatConfirm : 'هل أنت متأكد من إضافة هذه الإحداثيات.',
     removeCoordinate: 'هل أنت متأكد من حذف الإحداثيات.؟ نعم ، ستتم إزالة جميع الصور والفيديو المحملين نهائيًا من هذا الإحداثي.',
     cordianteUpdate :'تنسيق التحديث بنجاح.',
     videoupload : 'تم تحميل الفيديو بنجاح',
     deleteConfirm : 'تأكيد حذف هذا الملف (الملفات)',
     deletefilesuccess : 'تم حذف الملفات المحددة بنجاح',
     Edit : 'تعديل',
     UploadDrawing : 'تحميل الرسم',
     AddZone : 'أضف منطقة',
     DrawingName : 'اسم الرسم',
     Floor : 'الأرض',
     Area: 'مساحة',
     zoneName : 'اسم المنطقة',
     NewZone : 'منطقة جديدة',
     drawingupload : 'تم تحميل الرسم بنجاح',
     drawingconfirm : 'تأكيد حذف هذا الرسم؟',
     drawingremove : 'رسم إزالة Successfull',
     addezone : 'تمت إضافة منطقة جديدة بنجاح',
     Images360 : '360 صورة', 
     NormalImages : 'صور عادية',
      videos360 : '360 فيديو',
      Comments : 'تعليقات',
      Task : 'مهمة',
      AddComment : 'أضف تعليق',
      Lock : 'قفل',
      ImportTasks : 'مهام الاستيراد',
      StandardTaskLists:'قائمة المهام القياسية',
      FilterByDate : 'تصفية حسب التاريخ',
      AddNewComment : 'أضف تعليق جديد',
      Comment : 'تعليق',
      Progress : 'تقدم ٪',
      Tag : 'بطاقة شعار',
      UpdateTask : 'تحديث المهمة',
      DownloadExcel : 'قم بتنزيل Excel',
      OR : 'أو',
      DownloadPDF : ' تحميل PDF',
      start : 'يبدأ',
      end : 'نهاية',
      addzone :'تمت إضافة منطقة جديدة بنجاح.',
      addcommnetssucess :'تمت إضافة التعليق بنجاح',
      listconfirmation : 'هل أنت متأكد من استيراد قائمة المهام؟',
      standdrlistloaded: 'تم تحميل قائمة المهام القياسية',
      newmsg :'تمت إضافة مهمة جديدة بنجاح.',
      tasksuccess : 'تم تحديث المهمة بنجاح',
      sureremove : 'هل أنت متأكد من إزالة هذه المهمة؟',
      taskDelete : 'تم حذف المهمة بنجاح',
      removetask :"هل أنت متأكد من إزالة المهام المحددة؟",
      PlannedEnd : 'نهاية مخطط لها ',
      PlannedStart : 'البداية المخطط لها',
      DroneImages : 'صور بدون طيار',
      DroneVideo : 'فيديو بدون طيار',
      UploadMedia : 'تحميل الوسائط',
      VisitDate : 'تاريخ الزيارة',
      AddNewVisitingDate : 'إضافة تاريخ زيارة جديد',
      Upload : 'تحميل',
      OpenGallery : 'افتح المعرض',
      srtfile:'تحميل ملف srt',
      AddVideoInfo : 'إضافة معلومات الفيديو',
      MetaList : 'قائمة التعريف',
      latlongnotavailble :'خط الطول وخط العرض للصورة غير متاحين',
      imagesnotavailble : 'الصور غير متوفرة',
      recordadded : 'تمت إضافة السجل بنجاح',
      visitdateadded :'تمت إضافة تاريخ الزيارة بنجاح',
      selecteddelte :'تم حذف الملفات المحددة بنجاح',
      taskconfirmation : 'هل أنت متأكد من إزالة هذه المعلومات؟',
       recordDeleted : 'تم حذف السجل بنجاح',
       BulkUpload : 'تحميل مجمع',
       DroneVideos : 'مقاطع فيديو بدون طيار',
       ReviewDate :'تاريخ المراجعة',
       ReviewImages : 'مراجعة الصور',
        reviewImagesandSubmit : 'تم تحميل الصور. الرجاء النقر فوق "مراجعة الصور" للإرسال',
        Delayed : 'تأخير',
        Critical : 'حرج',
        Complete : 'إكمال',
        Running : 'ادارة',
        LayoutInsights : 'رؤى التخطيط',
        SelectDrawing : 'حدد الرسم',
        AddNewMaterialHeading : 'إضافة عنوان مادة جديد',
        AddNewSubHeading : 'إضافة عنوان فرعي جديد',
        AddNewMaterial: 'أضف مادة جديدة',
        MaterialName : 'اسم المادة',
        Unit : 'وحدة',
        AssetType : 'نوع الأصول',
        Estimateqty : 'تقدير الكمية',
        MaterialHeading :'العنوان المادي',
        Enable : 'يمكن',
        MaterialSubHeading : 'العنوان الفرعي المادي',
        integetrequre :'هذا المجال يسمح فقط بالأرقام الصحيحة',
        HeadingUpdateSuccessfully:'تحديث العنوان بنجاح',
        confirmdelteheading:'تأكيد حذف هذا العنوان؟',
        HeadingdeletedSuccessfully: 'تم حذف العنوان بنجاح',
        HeadingAddedSuccessfully  :'تمت إضافة العنوان بنجاح', 
        SubHeadingUpdateSuccessfully: 'تم تحديث العنوان الفرعي بنجاح',
        SubheadingdeleteSuccessfully:'تم حذف العنوان الفرعي بنجاح',
        SubheadingAddedSuccessfully:'تمت إضافة العنوان الفرعي بنجاح',
        MaterialUpdateSuccessfully : 'تم تحديث المواد بنجاح',
        MaterialDeleteSuccessfully: 'تم حذف المادة بنجاح',
        MaterialAddedSuccessfully : 'تمت إضافة المواد بنجاح',
        Yourtaskuploadedsuccesfully: 'مهمتك تم تحميلها بنجاح',
        DraftsInventories : 'مشروع الجرد',
        Confirmremovethisitem:'تأكيد إزالة هذا العنصر؟',
        EnteryDeletedSuccessfully : 'تم حذف الإدخال بنجاح',
        goback: 'عد', 
        Discription : 'وصف',
        GoToInventory : 'اذهب إلى الجرد',
        UpdateInventory : 'تحديث الجرد',
        DCNo : 'رقم DC / وزن الانزلاق',
        PONo : 'PO لا',
        VehicleNo : 'السيارة لا',
        SupplierName : 'اسم المورد',
        Rate : 'معدل',
        Quantity : 'كمية',
        Update : 'تحديث',
        NewInventory : 'جرد جديد',
        InventoryUpdateSuccessfully : 'تحديث المخزون بنجاح',
        InventoryDeletedSuccessfully : 'تم حذف المخزون بنجاح',
        InventoryAddedSuccessfully : 'تمت إضافة المخزون بنجاح',
        mobileapp : 'يرجى تحميل الصور من التطبيق المحمول لتقديم قوائم الجرد.',
        InventorySubmitSuccessfull : 'تم إرسال المخزون بنجاح',
        EditRequestNote :'تحرير ملاحظة الطلب',
        EditRequest : 'تحرير الطلب',
        InventoryEditRequest:'طلب تحرير المخزون',
        Commentsforinventoryupdate:'تعليقات لتحديث المخزون',
        RequestSuperadmin:'طلب مشرف متميز',
      
        OverallReport:'التقرير العام',
        CumulativeReport : 'تقرير تراكمي',
        MaterialwiseReport : 'تقرير مادي',
        Requestsuccessfullysent:'تم إرسال الطلب بنجاح',
        Images : 'الصور',
        VehicleEntry : 'دخول السيارة',
        offtheproject:'خارج موقع المشروع',
       
        VehicleNumberPlate : 'رقم لوحة المركبة',
        deliverychalaimage : 'تسليم Challan & الوزن زلة',
        vehivleunloading:'تفريغ السيارة',
        VehicleEmpty : 'المركبة فارغة',
        other : 'آخر',
        reflected : 'إذا لم تنعكس بيانات الجدول ، فالرجاء محاولة إعادة تحميل الصفحة أو تحديثها',
        ImportSuppliers : 'استيراد الموردين',
        SupplieraddedSuccessfull : 'تمت إضافة المورد بنجاح',
        Suppliersfileimportsuccessfully : 'تم استيراد ملف الموردين بنجاح',
        deleteconfirm:'تأكيد حذف المورد',
         removesucces:'تمت إزالة المورد بنجاح',
         clear : 'صافي',
         Profile : 'معلومات المشروع',
         SaveandShare : 'احفظ وأرسل',
         CRM:'CRM',
         DailyReport:'تقرير يومي',
        EveryMonthprogressreport:'Monthly Progress Report',
        BiMonthlyprogressreport:'Bi Monthly Progress Report',
        Quarterlyprogressreport:'Quarterly Progress Report',
        Halfyearlyprogressreport:'Semi Annual Progress Report',
   }
<template>
  <div class="languagebox">
      <v-select
          max-width="250"
          v-model="currentLang"
          :items="items"
          item-text='text'
          item-value='value'
          menu-props="auto"
          label="Select"
          @change="selecteLang"
          
          prepend-icon="mdi-translate"
          single-line
        ></v-select>
 </div>
</template>
<script>

export default {
    data: () => ({
       items: [
        {
        text: "English",
        value: "English"
        },
         {
        text: "French",
        value: "French"
        },
         {
        text: "Spanish",
        value: "Spanish"
        },
        {
        text: "عربى",
        value: "Arabic"
        },
        {
        text: "हिन्दी",
        value: "Hindi"
        }
       ],
      currentLang : 'English'
    }),
    mounted() {

         var cl = this.$cookies.get('currentLanguage');
            console.log("currentLanguage nav", cl)
            if (cl === null) {
                this.$cookies.set("currentLanguage", 'English', -1);
                   
            }else{
                this.currentLang = cl;
            }
          
    },
    methods: {
        selecteLang(){
           this.$cookies.set("currentLanguage", this.currentLang, -1);
            this.$router.go();

        }
    }  
}
</script>

<style>
.v-select__selections{
        line-height: 21px;
}
.languagebox{
    padding: 0px 28px;
}
</style>

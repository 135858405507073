<template>
<div>
    <Preloader v-if="preloader" />
     <v-row>
      <v-col cols="12" md="9">
       <v-card
          class="pa-5"
          
        >
        <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1"> Instant alert </v-tab>

         <v-tab href="#tab-2"> Recurring reporting </v-tab>

          <v-tab href="#tab-3"> Patterns report  </v-tab>

         

      </v-tabs>

       <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
              <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitInstantReport"
    >

         <v-container>
          <v-row> 
       
      
      <v-col cols="12" md="12" sm="12" class="rowitem">      
          
        <v-row 
         v-for="(instant_applicant, counter) in instant_applicants"
            :key="counter">             
        <v-col
          cols="4"
          md="4"
        >
        <v-select
          v-model="instant_applicant.object"

             label="Objects"
            :items="['Person','Car','Bus','Excavators','Telehandler','Loader','Dump Truck','Concrete Mixer','Pile Driver','Crane','Rollers','Helmet','Jacket','Belt','Pile','Pile Cap','Pier Cage','Pier','Pier Cap','Deck']"
          color="amber accent-3"  
        >
        </v-select>

      </v-col> 

    <v-col
          cols="4"
          md="4"
        >
        <v-select
             v-model="instant_applicant.condition"
             label="Condition"
            :items="[ {
               value:'gte',
               text:'Greater than or equal to'
               },
               {
               value:'lte',
               text:'Less than or equal to'
               }]"
               item-value='value'
               item-text='text'
            color="amber accent-3" 
        > </v-select>
        
      </v-col> 
         
       <v-col
          cols="3"
          md="3"
        >
        <v-text-field
                    v-model="instant_applicant.qty"
                    :label="Qty"
                    required
                    type="number"
                  ></v-text-field>
        
       </v-col> 
              <v-col
          cols="1"
          md="1"
        >
          <span @click="instant_applicant_removeRow(counter)" class="closerow"> <v-icon> mdi-close </v-icon></span>
        </v-col>
      </v-row>  
           <v-col
          cols="12"
          md="12"
          sm="12"
        >
        <v-btn
        class="mr-4"
        color="mylightbutton" 
        @click="instant_applicant_addmorerows"
      >
        Add More Conditions
      </v-btn>
        </v-col>
    </v-col> 
       <v-col
          cols="12"
          md="12"
          sm="12"
            class="dddxer"
        >
         <v-checkbox v-model="instant_applicant_night_images" label="Include night images"></v-checkbox>
           <div class="helpinfo_report">
                <v-icon dark class="helps_report" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                
                 <p>
                  <ul>
                   <li>Enable this to get notifications on all the images captured 24x7, which might reduce the accuracy of reporting due to the unclear object detection at night.</li>
                   <li>Leave it unchecked to enable the notification from 6 AM to 6 PM only.</li>
                  </ul>
                </p>  
              </div>
       <v-textarea
          v-model="instant_emails"
          :label="lang.Email"
          :rules="emailrules"
          required
          rows="1"
        ></v-textarea>
        </v-col>
        <p class="ma-5"> Note: As soon as the above conditions are met, the user will be notified via email along with the respective images. Users might receive multiple emails daily whenever the above conditions are met. </p>
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton" 
      >
        {{lang.submit}}
      </v-btn>
      <v-btn @click="clearform"
       color="mydarkbutton"
      >
        {{lang.Reset}}
      </v-btn>
      </v-row>
    </v-container>
    </v-form>
        </v-tab-item>

         <!-- // Second Tab start -->
         <v-tab-item value="tab-2">
                  <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitRecuringReport"
    >

         <v-container>
          <v-row> 
       
        

        <v-col cols="12" md="12" sm="12">
                        <v-select
                          :items="pattern_reporttype"
                          v-model="recuring_selecttype"
                          label="Report Type"
                         
                        ></v-select>
                      </v-col>

      <v-col cols="12" md="12" sm="12" class="rowitem">      
          
        <v-row 
         v-for="(applicant, counter) in applicants"
            :key="counter">             
        <v-col
          cols="4"
          md="4"
        >
        <v-select
          v-model="applicant.object"

             label="Objects"
            :items="['Person','Car','Bus','Excavators','Telehandler','Loader','Dump Truck','Concrete Mixer','Pile Driver','Crane','Rollers','Helmet','Jacket','Belt','Pile','Pile Cap','Pier Cage','Pier','Pier Cap','Deck']"
          color="amber accent-3"  
        >
        </v-select>

      </v-col> 

    <v-col
          cols="4"
          md="4"
        >
        <v-select
             v-model="applicant.condition"
             label="Condition"
            :items="[ {
               value:'gte',
               text:'Greater than or equal to'
               },
               {
               value:'lte',
               text:'Less than or equal to'
               }]"
               item-value='value'
               item-text='text'
            color="amber accent-3" 
        > </v-select>
        
      </v-col> 
         
       <v-col
          cols="3"
          md="3"
        >
        <v-text-field
                    v-model="applicant.qty"
                    :label="Qty"
                    required
                    type="number"
                  ></v-text-field>
        
       </v-col> 
              <v-col
          cols="1"
          md="1"
        >
          <span @click="removeRow(counter)" class="closerow"> <v-icon> mdi-close </v-icon></span>
        </v-col>
      </v-row>  
           <v-col
          cols="12"
          md="12"
          sm="12"
        >
        <v-btn
        class="mr-4"
        color="mylightbutton" 
        @click="addmorerows"
      >
        Add More Conditions
      </v-btn>
        </v-col>
    </v-col> 
       <v-col
          cols="12"
          md="12"
          sm="12"
          class="dddxer"
        >
         <v-checkbox v-model="night_images" label="Include night images"></v-checkbox>
           <div class="helpinfo_report">
                <v-icon dark class="helps_report" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                
                 <p>
                   <ul>
                   <li>Enable this to get notifications on all the images captured 24x7, which might reduce the accuracy of reporting due to the unclear object detection at night.</li>
                   <li>Leave it unchecked to enable the notification from 6 AM to 6 PM only.</li>
                  </ul>
                </p>  
              </div>
         
          <v-textarea
          v-model="recuring_emails"
          :label="lang.Email"
          :rules="emailrules"
          required
          rows="1"
        ></v-textarea>
        </v-col>
    
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton" 
      >
        {{lang.submit}}
      </v-btn>

            <v-btn
            @click="resetRecuringReport"
        class="mr-4"
        color="mylightbutton" 
      >
       Reset
      </v-btn>

     
     
      </v-row>
    </v-container>
    </v-form>
        </v-tab-item>
      <!-- third Tab Start -->
         <v-tab-item value="tab-3">
               
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitPatternReport"
    >

         <v-container>
          <v-row> 
       
        

        <v-col cols="12" md="12" sm="12">
                        <v-select
                          :items="pattern_reporttype"
                          v-model="reporttype"
                          label="Report Type"
                         
                        ></v-select>
                      </v-col>
      <v-col
          cols="12"
          md="6"
        >
        <v-combobox
          v-model="vehicle_object"
        
             label="Person / Vehicles Objects"
            :items="['Person','Car','Bus','Excavators','Telehandler','Loader','Dump Truck','Concrete Mixer','Pile Driver','Crane','Rollers','Helmet','Jacket','Belt','Pile','Pile Cap','Pier Cage','Pier','Pier Cap','Deck']"
          color="amber accent-3"  
          multiple
          chips

        >
          <template v-slot:selection="data" >
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                class="accent amber accent-3 white--text"
                left
               
                v-text="data.item.slice(0, 1).toUpperCase()"
              ></v-avatar>
              {{ data.item }}
            </v-chip>
          </template>
        </v-combobox>

      </v-col> 

      <v-col
          cols="12"
          md="6"
        >
        <v-combobox
             v-model="weather_object"
             label="Weather Objects"
            :items="['Temperature','Wind','Rain','Clouds','Snow']"

          color="amber accent-3"  
          multiple
          chips

        >
          <template v-slot:selection="data" >
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                class="accent amber accent-3 white--text"
                left
               
                v-text="data.item.slice(0, 1).toUpperCase()"
              ></v-avatar>
              {{ data.item }}
            </v-chip>
          </template>
        </v-combobox>
        
      </v-col> 

       <v-col
          cols="12"
          md="12"
          sm="12"
        >
       <v-textarea
          v-model="emails"
          :label="lang.Email"
          :rules="emailrules"
          required
          rows="1"
        ></v-textarea>
        </v-col>
    
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton" 
      >
        {{lang.submit}}
      </v-btn>

         <v-btn
        class="mr-4"
        @click="resetPatternReport"
        color="mylightbutton" 
      >
        Reset
      </v-btn>

        <a class="sample_but" id="samplecolor" href="/Weekly_Patterns_report.pdf" target="_blank">Sample Report </a>

      </v-row>
    </v-container>
    </v-form>
        </v-tab-item>
        
      </v-tabs-items>
      </v-card>
    </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>  
 </div>
</template>

<script>
// import Vue from 'vue';
import axios from 'axios';
import Preloader from '../../components/PreLoader';
import { locals } from "../../utils/locals";
import Sidebar from "../../components/SettingSidebar";

export default {
    components: {
        Preloader,
         Sidebar,
    },
    name: "AiData",
    data() {
        return {
            currentcam: {},
          
            Local :locals,  
            lang : {},
            tab: null,
            email : '',
                emailrules: [
                v => !!v || this.lang.required,
                v => /^[-\w+.%]+@[\w-.]+\.[A-Za-z]{2,4}(?:,[-\w+.%]+@[^\s,]+\.[A-Za-z]{2,4}){0,100}$/.test(v) || this.lang.EmailValid
                ],
                preloader: false,
                alert: false,
                alertmessage: '',
                alertType: '',
                valid: true,
               
                weather_object:[],
                vehicle_object:[],
                pattern_reporttype: [ 'Weekly', 'Bi-weekly','Monthly'],
                reporttype : 'Weekly',
                recuring_selecttype: 'Weekly',
                reportobject : 'Temperature',
                condition :'Greater than or equal to',
                numbers : '5',
                applicants : [{  
                       object:'',
                       condition: '',
                        qty:''
                     }],
                 night_images : 0,

                  instant_applicants : [{  
                       object:'',
                       condition: '',
                        qty:''
                     }],
                 instant_applicant_night_images : 0,
                 
                 
            
            
          }
    
       },
    mounted() {
        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        this.currentcam = this.$store.state.currentCamera;

        
        this.userdata = JSON.parse(localStorage.getItem('userdata'))
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
      
       var _self = this;

        setTimeout(function () {
            _self.getInstantReport();
            _self.getPatternReport();
            _self.getRecuringReport();
            }, 2000);

        console.log('mounted');
         
    },
    created() {

          this.currentcam = this.$store.state.currentCamera;

         this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
             
        this.presets = this.$store.state.currentCamera.preset_ids.split(",");
        var _self = this;

       setTimeout(function () {
            _self.getInstantReport();
            _self.getPatternReport();
            _self.getRecuringReport();
            }, 2000);

    },
    watch: {
        '$store.state.currentCamera': function () {


            this.applicants = [{  
                       object:'',
                       condition: '',
                        qty:''
                     }];
                 this.night_images = 0;

                  this.instant_applicants = [{  
                       object:'',
                       condition: '',
                        qty:''
                     }];
                 this.instant_applicant_night_images = 0;


             console.log('cam watch active');

            this.currentcam = this.$store.state.currentCamera;
            var _self = this;
               setTimeout(function () {
            _self.getInstantReport();
            _self.getPatternReport();
            _self.getRecuringReport();
            }, 2000);
        }
    },
    methods: {
         submitPatternReport(){
             this.preloader = true;

             var params = {
                  reporttype : this.reporttype,
                  emails :  this.emails,
                  user_id : this.userdata.id,
                  cam_id : this.currentcam.camera_id,
                  project_id : this.currentProject.id,
                  vehicle_object : this.vehicle_object,
                  weather_object : this.weather_object,
                  status : 1
             };

               axios({ url: "/add/ai/patterns-report", data : params, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
          
                      if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = 'Patterns report successfully scheduled';
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         },

         resetPatternReport(){
             this.preloader = true;

             var params = {
                  reporttype : '',
                  emails :  '',
                  user_id : this.userdata.id,
                  cam_id : this.currentcam.camera_id,
                  project_id : this.currentProject.id,
                  vehicle_object : [],
                  weather_object : [],
                  status : 0
             };

               axios({ url: "/add/ai/patterns-report", data : params, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
          
                      if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = 'Patterns report successfully reset';
                    this.alertType = "success";

                    this.reporttype='';
                   this.emails = '';
                   this.vehicle_object = [];
                   this.weather_object = [];
                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         },

         getPatternReport(){
             this.preloader = true;
             console.log("this.currentcam",this.currentcam);
              this.cid = localStorage.getItem('cid');
             var params = {
                  cam_id : this.cid,
                  project_id : this.currentProject.id
               };

               axios({ url: "/get/ai/patterns-report", params : params, method: 'get' })
          .then(resp => {
                      console.log(resp.data);

                //          reporttype : this.reporttype,
                //   emails :  this.emails,
                //   user_id : this.userdata.id,
                //   cam_id : this.currentcam.camera_id,
                //   project_id : this.currentProject.id,
                //   vehicle_object : this.vehicle_object,
                //   weather_object : this.weather_object


                      this.emails = resp.data.emails;
                      this.vehicle_object = resp.data.vehicle_object.split(",");
                      this.weather_object = resp.data.weather_object.split(",");
                      this.reporttype = resp.data.report_type;
                      
                      this.preloader = false;

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         },
         addmorerows(){

                  this.applicants.push({
                  object:'',
                  condition: '',
                  qty:''
            })

            console.log("rows",this.applicants);
         },
           removeRow(counter){
            this.applicants.splice(counter,1);
        },

        instant_applicant_addmorerows(){

                  this.instant_applicants.push({
                  object:'',
                  condition: '',
                  qty:''
            })

            console.log("rows",this.instant_applicants);
         },
           instant_applicant_removeRow(counter){
            this.instant_applicants.splice(counter,1);
        },
        submitInstantReport(){

          this.preloader = true;
             
             var data = {
                 instant :'1',
                 recuring :'0',
                 report_type : '',
                 conditions : this.instant_applicants,
                 emails : this.instant_emails,
                 camera_id : this.currentcam.camera_id,
                 project_id : this.currentProject.id,
                 night_images : this.instant_applicant_night_images,
                 created_by : this.userdata.id,
                 status : 1
             }
             

                axios({ url: "/set/ai/instant-conditional-report", data : data, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
                if(resp.data.success){
                    this.alert = true;
                    this.alertmessage = 'Instant ppe report successfully scheduled';
                    this.alertType = "success"
                 }else{
                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          }) .catch(err => {
            console.log(err);
             this.preloader = false
          })
        },
        submitRecuringReport(){
           this.preloader = true;
             
             var data = {
                 recuring :'1',
                 instant :'0',
                 conditions : this.applicants,
                 report_type : this.recuring_selecttype,
                 emails : this.recuring_emails,
                 camera_id : this.currentcam.camera_id,
                 project_id : this.currentProject.id,
                 night_images : this.night_images,
                 created_by : this.userdata.id,
                  status : 1
             };

             axios({ url: "/set/ai/conditional-report", data : data, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
                if(resp.data.success){
                    this.alert = true;
                    this.alertmessage = 'Recurring report successfully scheduled.';
                    this.alertType = "success"
                 }else{
                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          }) .catch(err => {
            console.log(err);
             this.preloader = false
          })
        },
          clearform(){
            
            this.preloader = true;
             
             var data = {
                 instant :'1',
                 recuring :'0',
                 report_type : '',
                 conditions : [],
                 emails : '',
                 camera_id : this.currentcam.camera_id,
                 project_id : this.currentProject.id,
                 night_images : 0,
                 created_by : this.userdata.id,
                 status : 0
             };

            axios({ url: "/set/ai/instant-conditional-report", data : data, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
                if(resp.data.success){
                    this.alert = true;
                    this.alertmessage = 'Recurring report successfully reset';
                    this.alertType = "success"
                
                  this.instant_applicants = [];
                  this.instant_emails = '';
                  this.instant_applicant_night_images = 0;

                 }else{
                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          }) .catch(err => {
            console.log(err);
             this.preloader = false
          })

          },   

           resetRecuringReport(){

           this.preloader = true;
             
             var data = {
                 recuring :'1',
                 instant :'0',
                 conditions : [],
                 report_type : '',
                 emails : '',
                 camera_id : this.currentcam.camera_id,
                 project_id : this.currentProject.id,
                 night_images : 0,
                 created_by : this.userdata.id,
                 status : 0
             };

             axios({ url: "/set/ai/conditional-report", data : data, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
                if(resp.data.success){
                    this.alert = true;
                    this.alertmessage = 'Recurring report successfully reset.';
                    this.alertType = "success";

                      this.recuring_emails = '';
                      this.applicants = [];
                      this.night_images = 0;
                 }else{
                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
              this.preloader = false

          }) .catch(err => {
            console.log(err);
             this.preloader = false
          })
        },
         getInstantReport(){
            
                this.cid = localStorage.getItem('cid');
             var params = {
                  cam_id : this.cid,
                  project_id : this.currentProject.id
               };

               axios({ url: "/get/ai/conditional-instant-report", params : params, method: 'get' })
          .then(resp => {
                      console.log("conditional-instant-report",resp.data);

                //         conditions : this.applicants,
                //  report_type : this.recuring_selecttype,
                //  emails : this.recuring_emails,
                //  camera_id : this.currentcam.camera_id,
                //  project_id : this.currentProject.id,
                //  night_images : this.instant_applicant_night_images

  
                      this.instant_emails = resp.data.emails;
                      this.instant_applicants = JSON.parse(resp.data.conditions);
                      this.instant_applicant_night_images = resp.data.night_images;
                      
                      this.preloader = false;

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         },

         getRecuringReport(){
            
                 this.cid = localStorage.getItem('cid');
             var params = {
                  cam_id : this.cid,
                  project_id : this.currentProject.id
               };

               axios({ url: "get/ai/conditional-recuring-report", params : params, method: 'get' })
          .then(resp => {
                      console.log("conditional-recuring-report",resp.data);

              
                      this.recuring_emails = resp.data.emails;
                      this.applicants = JSON.parse(resp.data.conditions);
                      this.night_images = resp.data.night_images;
                      
                      this.preloader = false;

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         }
         
    } // end of mathos

};
</script>

<style>
.rowitem {
    background: #e3e3e3;
}
.closerow {
 cursor:pointer;
 position:relative;
 top:15px;
}

 .helps_report {
    position: absolute !important;
    left: 210px;
    z-index: 5;
    cursor: pointer;
    top: 31px;

}
.helpinfo_report p {
    position: absolute;
    left: 226px;
    width: 350px;
    background: #fff;
    top: 0px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition: 0.5s;
}

.helpinfo_report:hover p{
  display:block;
  transition:0.5s;
}
.dddxer{
  position: relative;
}
.sample_but {
    display: block;
    padding: 8px;
    float: right;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: #000;
 
    height: 39px;
    border-radius: 3px;
}
#samplecolor{
  color:#fff !important;
}
</style>



<template>
  <div>
     <Preloader v-if="preloader" />
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class=""
          
        >
        <div class="Ibntegrationbox">
                
                <v-btn
      :loading="loading3"
      :disabled="loading3"
      color="#f8bb03"
      class="black--text procore tab active"
     @click="setrouter('/dashboard/settings/integration/procore_connection')"
    >
      {{lang.ConnectwithProcore}}
    
    </v-btn>

    <v-btn
      :loading="loading3"
      :disabled="loading3"
      color="#f8bb03 "
      class="black--text bimclass tab"
      @click="setrouter('/dashboard/settings/integration/bim360')"
    >
      {{lang.ConnectWithBim360}}
    </v-btn>

        </div>
 
    </v-card>
       </v-col>
        <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col> 
      </v-row>    
   </div>
</template>
<script>
//import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar
  },
  name: "Intrigration",
  data() {
    return {
         preloader: false,
         userdata :'',
     };
  },
  mounted() {

     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.initViewer();
     this.userdata = JSON.parse(localStorage.getItem('userdata')); 
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
  '$store.state.currentCamera': function() {
    this.userdata = JSON.parse(localStorage.getItem('userdata')); 
    // console.log("Current data get",this.$store.state.currentCamera)
   }
  },
    computed: {
  
  }, 
  methods: {
   
    initViewer() {
  
    },
      setrouter: function (url) {
        if(this.$route.path !== url){
             this.$router.push(url)
          //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
        }
         
          //console.log(url);
      },
  }
};
</script>
<style>
.tab {
    width: 50%;
    margin: 0;
    border-radius: 0;
}
.tab.active {
 border-bottom: 2px solid #000;
}
</style>


<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                <template>
                    <v-row>
            <v-col cols="12" md="12">
                    <v-text-field  v-model="search" append-icon="mdi-magnify" :label="lang.Search" class="mySearchInventorys" single-line hide-details ></v-text-field>
                        <v-btn v-if="materialeditaccess" color="#f8bb03" class="rightbutonss" @click="addnewItem"> {{lang.AddNew}} </v-btn>
                        <v-btn v-if="materialeditaccess" color="#f8bb03" class="rightbutonss ml-4" @click="importSuppleir"> {{lang.ImportSuppliers}} </v-btn>
                   </v-col>  
                    </v-row>
                    <v-data-table :headers="headers" :search="search" :items="suppliers" :items-per-page="50" :footer-props="footerpro" class="elevation-1">

                        <template v-slot:item.id="{ item }">
                                <v-btn
                                v-if="materialeditaccess"
                                fab
                                dark
                                icon
                                small
                                color="black"
                                @click="deleteSupplier(item.id)"
                                >
                                <v-icon dark>
                                mdi-delete
                                </v-icon>
                                </v-btn>

                        </template>
                     

                    </v-data-table>

                </template>

            </v-card>
        </v-col>
    </v-row>
    

    <v-snackbar v-model="alert" top right timeout="3000" :type="alertType">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newSupplier">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.NewSupplier}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitnewSupplier">
                    <v-container>
                        <v-row>
                           <v-col cols="12" sm="12">
                                <v-text-field :rules="requerdRules" v-model="suppliername" :label="lang.SupplierName" required></v-text-field>
                                    
                            </v-col>
                            
                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send }} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                              
                                <v-btn class="rightclose" @click="newSupplier = false" icon>
                                     <v-icon color="black lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
     <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="importFile">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.ImportSuppliers}}  </v-toolbar>
                <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="submit">

                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <input type="file" @change="onChange"  />

                                 <xlsx-read :file="file" class="hide" >
                                    <xlsx-json  >
                                        <template #default="{collection}">
                                            <div ref="supplies">
                                                {{ collection }}
                                            </div>
                                        </template>
                                    </xlsx-json>
                                </xlsx-read>
                            </v-col>
                            <v-col cols="12" md="12">
                             
                            </v-col>
                            <v-col cols="12" md="12">

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}}
                                </v-btn>

                            </v-col>
                            <v-col cols="12" md="12">
                                <a href="/supplier_import_sample.xlsx" style="color:#000; text-decoration:none">
                                    <h3 style="color:#000; text-decoration:none">
                                        <v-icon>mdi-download-circle </v-icon>
                                        Download Sample.xls file
                                    </h3>
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import { locals } from "../../utils/locals";
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
export default {
    components: {
        Preloader,
          XlsxRead,
        XlsxJson
    },

    computed: {
       
    },
    name: "EditTask",
    data() {
        return {
            requerdRules: [
            v => !!v || this.lang.required,
            ],
            
            alert: false,
            send : false,
            alertmessage: '',
            alertType: "error",
            preloader: false,
            project_name: 'ProjectName',
            projectid: 0,
            search: '',
            suppliers: [],
            valid : false,
            userdata: {},
            headers: [{
                    text: 'Supplier Name',
                    align: 'start',
                    value: 'supplier_name',
                    width: 150,
                },
                {
                    text: 'Action',
                    value: 'id',
                    width: 150,
                }
            ],
             suppliername :'',
            footerpro: {
                itemsPerPageOptions: [50, 100, 150, -1],
            },
            newSupplier : false,
            filedata : {},
            file : null,
            importFile : false,
            materialeditaccess:false,
            Local :locals,  
         lang : {},
        };
    },
    created() {

         var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        this.userdata = JSON.parse(localStorage.getItem('userdata'));

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;
        this.project_name = currentProject.project_name;
        this.initViewer();
        if(currentProject.material == 1){
          this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
        if (this.userdata.material_access == 1) {
            this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
         if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if(this.userdata.user_code == 2){
                this.materialeditaccess = true;
        }
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.project_name = currentProject.project_name;
            this.getSupplier();
            if(this.userdata.user_code == 2){
                this.materialeditaccess = true;
                }
        }
    },
    // mounted() {
    //     this.userdata = JSON.parse(localStorage.getItem('userdata'));
    //     if (this.userdata.open_access == 1) {
    //         this.$router.push('/dashboard')
    //     }
    // },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
             

            // console.log("init Viewer");
            this.preloader = true;
            this.getSupplier();
        },
        getSupplier(){
            this.preloader = true;
                 var projectid = this.projectid;
                //  var filter = '{"where":{ "project_id": '+projectid+'}}';
                //  var urlfilter = encodeURI(filter);
        
             axios({
                    url: '/material/suppliers',
                    method: 'get',
                    params: {f:projectid}
                })
                .then(resp => {
                    console.log("Suppliers", resp);
                     this.preloader = false;
                     this.suppliers = resp.data; 
                   
                })
                .catch(err => {
                    console.log(err);
                     this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        addnewItem(){
             this.newSupplier = true;
        },
        submitnewSupplier(){

        var data = {
           suppliername : this.suppliername,
           project_id : this.projectid
        }
           axios({
                    url: '/material/supplier/add',
                    method: 'get',
                    params: data
                })
                .then(resp => {
                    console.log("Suppliers", resp);
                     this.preloader = false;
                    
                    this.alert = true;
                    this.alertmessage = this.lang.SupplieraddedSuccessfull;
                    this.alertType = "success";
                     this.newSupplier = false;
                    
                        this.getSupplier();
                   
                })
                .catch(err => {
                    console.log(err);
                     this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })    
        },
        importSuppleir(){
            this.importFile = true;
        },
         onChange(event) {
             this.file = event.target.files ? event.target.files[0] : null;

        },
         async submit() {
            let childEl = this.$refs.supplies.innerHTML;
            this.preloader = true;
            var userdata = {
                 filedata: childEl,
                 projectid : this.projectid
            }
            
             console.log(userdata);
            axios({
                    url: 'material/supplier/import',
                    data: userdata,
                    method: 'post'
                })
                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = this.lang.Suppliersfileimportsuccessfully;
                        this.alertType = "success";
                        this.getSupplier();
                    } else {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error"
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
         deleteSupplier(id) {

         var conf = confirm(this.lang.deleteconfirm);
          
          if(conf){
            axios({
                    url: 'material/supplier/delete',
                    params: {
                        id:id
                    },
                    method: 'get'
                })
                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {
                        this.preloader = false;
                       
                        this.alert = true;
                        this.alertmessage = this.lang.removesucces;
                        this.alertType = "success"
                        this.getSupplier();

                    } else {
                        this.preloader = false;
                       
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error"
                    }
                })
                .catch(err => {
                    console.log(err);
                })
          }
        },
    }
};
</script>

<style>
.remark {
    position: absolute;

    right: 0;
}

.remarkbox {
    position: relative;

}

.mySearchInventorys {
    float: left;
    width: calc(100% - 340px);
}
.rightbutonss {
  margin-top: 9px;
}
.right_aligh {
    top: -51px;
    right: 13px;
    position: relative;
    float: right;
}
.center{
    text-align:center;
}
.cumulativebox{
    min-height: 450px;
    width:100%;
    text-align:center;
}
.daterangepicker {
    min-width: 750px !important;
}
.vue-daterange-picker.v-text-field__slot {
    width: 96%;
    margin-bottom: 6px;
}
.daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #565656;
    color: #fff;
}
.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    background: #000;
    left: 0;
    padding: 13px;
}
.submmitinventory {
    font-size: 14px;
    background: #fdc001;
    color: #000;
    padding: 12px 44px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
}
.submmitinventory:hover {
    color: #000;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
     font-size: 0.95rem;
}
</style>

<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                <div class="stepper_outer">
                    <!-- <div class="steper_item " @click="setrouter('/dashboard/planning/uploade-task')">
                        <div class="itcons">
                            <v-icon>mdi-cloud-upload </v-icon>
                        </div>
                        <span class="steperItem"> Upload Task </span>
                    </div> -->
                    <div class="steper_item " @click="setrouter('/dashboard/planning/edit-task')">
                        <div class="itcons">
                            <span class="steperItem"> {{lang.EditTask}} </span>
                            <v-icon>mdi-square-edit-outline </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item" @click="setrouter('/dashboard/planning/compare-events')">
                        <div class="itcons">
                             <span class="steperItem"> {{lang.CompareEvents }} </span>
                            <v-icon>mdi-compare </v-icon>
                        </div>
                       
                    </div>
                    <div class="steper_item active" @click="setrouter('/dashboard/planning/event-chart')">
                        <div class="itcons">
                             <span class="steperItem"> {{lang.EventChart}} </span>
                            <v-icon>mdi-chart-gantt </v-icon>
                        </div>
                       
                    </div>
                    <div class="steper_item" @click="setrouter('/dashboard/planning/event-timelapse')">
                        <div class="itcons">
                             <span class="steperItem"> {{lang.EventTimelapse }} </span>
                            <v-icon>mdi-play-circle </v-icon>
                        </div>
                       
                    </div>

                </div>

                <v-alert dense text :type="alertType" v-if="alert">
                    {{ alertmessage }}
                </v-alert>
                  
                  <gantt-elastic
      :options="options"
      :tasks="chrtdata"
      @tasks-changed="tasksUpdate"
      @options-changed="optionsUpdate"
      @dynamic-style-changed="styleUpdate"
    >
        <gantt-header slot="header"></gantt-header>
    </gantt-elastic>

            </v-card>
        </v-col>

    </v-row>

</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";
import { locals } from "../../../utils/locals";

let options = {
  taskMapping: {
    progress: "percent"
  },
  maxRows: 300,
  maxHeight: 500,
  title: {
    label: "<h3>  Project List </h3>",
    html: true
  },
  row: {
    height: 24
  },
  calendar: {
    hour: {
      display: false
    }
  },
  chart: {
    progress: {
      bar: false
    },
    expander: {
      display: false
    }
  },
  taskList: {
    expander: {
      straight: false
    },
    columns: [
      {
        id: 1,
        label: "ID",
        value: "id",
        width: 40
      },
      {
        id: 2,
        label: "Task Name",
        value: "label",
        width: 200,
        expander: true,
        html: true
        
      },
      
      {
        id: 3,
        label: "Start",
        value: task => dayjs(task.start).format("YYYY-MM-DD"),
        width: 78
      },
       {
        id: 4,
        label: "End",
        value: task => dayjs(task.end).format("YYYY-MM-DD"),
        width: 78
      }
     
    ]
  }
};

export default {
    components: {
        Preloader,
        GanttHeader,
        GanttElastic
    },
    name: "UploadeTasks",
    data() {
        return {
            valid: true,
            file: null,
            projectid: 0,
            preloader: false,
            alert: false,
            alertmessage: '',
            alertType: '',
            options,
            dynamicStyle: {},
            lastId: 16,
            chrtdata : [],
            Local :locals,  
            lang : {},
        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];
         
        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;

        this.initViewer();
        this.getTaskData();
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.getTaskData();
        }
    },
    computed: {

    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false
        },

        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;

        },
        setrouter: function (url) {
            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
            }

            //console.log(url);
        },
        async submit() {
            let childEl = this.$refs.filedata.innerHTML;
            // console.log(childEl);
            var userdata = {
                filedata: childEl
            }
            axios({
                    url: '/project/' + this.projectid + '/uplaod-task',
                    data: userdata,
                    method: 'POST'
                })

                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {

                        this.alert = true;
                        this.alertmessage = this.lang.RecordUpdatesuccessfully;
                        this.alertType = "success"

                    } else {

                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error"
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        getTaskData() {
            this.preloader = true;
            axios({
                    url: '/project/' + this.projectid + '/get-task ',
                    method: 'GET'
                })
                .then(resp => {
                    this.preloader = false;
                    //console.log("Resp", resp);
                    var taskd = resp.data.success;
                       this.chrtdata = [];
                       var j = 1;
                       var k = 1;

                        taskd.forEach(i => {

                           var duration =    this.dateDiffrence(i.base_end_date, i.base_start_date);
                            console.log(duration);

                           this.chrtdata.push({
                            'label' : i.task_name,
                            'start' : i.base_start_date,
                            'end' : i.base_end_date,
                            id: j,
                            duration: duration * 24 * 60 * 60 * 1000,
                            percent: 0,
                            type: "milestone",
                            collapsed: false,
                            style: {
                            base: {
                            fill: "#f8bb03",
                            stroke: "#f8bb03"
                            }
                            }
                           
                           }) 

                             if(i.actual_start_date){
                                  var duration2 =  this.dateDiffrence(i.actual_end_date, i.actual_start_date);
                                   console.log(duration2);
                                k++;  
                           this.chrtdata.push({
                            'label' : i.task_name + ' Actual Start',
                            'start' : i.actual_start_date,
                            'end' : i.actual_end_date,
                              'parentId': j,
                                id: k,
                                duration: duration2 * 24 * 60 * 60 * 1000,
                                percent: 0,
                                type: "task"
                               //collapsed: true,
                           }) 
                              j++;
                             }

                              console.log(j,k)

                           j++;  
                           k++;  
                         
                      });
                       console.log(this.chrtdata);
                     
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        dateDiffrence(d2, d1){
                var date1 = new Date(d1);
                var date2 = new Date(d2);
                var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10); 
               //console.log(diffDays);
                 return diffDays
        }
    }
};
</script>

<style>
/* .stepper_outer {
    display: flex;
    width: 100%;
    flex-: center;
    justify-content: space-between;
}

.steper_item {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    z-index: 6;
    width: 95%;
     cursor:pointer;
}

.itcons {
    width: 95%;
    height: 50px;
    
    border-radius:0;
    background: #292929;
    color: #fff;
    text-align: center;
    line-height: 47px;
    margin: auto;
    margin-bottom: 11px;
    border: 1px solid #ffc400;
    transition: 0.5s;
}

.itcons i {
    color: #ffc400 !important;
}

.active .itcons {
   
    height: 50px;
    background: #ffc400;
    border: 2px solid #000;
    box-shadow: 0 0 15px 5px #0000005c;
    transition: 0.5s;
    line-height: 47px;
}

.active .itcons i {
    color: #000 !important;
}

.active .steperItem {
    color: #000000 !important;
    text-shadow: 0px 1px 20px #000;
    font-weight: 500;
}

.stepper_outer {
    padding: 0 19px;
    position: relative;
}

.stepper_outer:after {
    content: "";
    display: block;
    height: 2px;
    background: #000;
    width: 88%;
    position: absolute;
    left: 58px;
    top: 26px;
    z-index: 1;
}

.centerform {
    text-align: center;
    padding: 51px;
} */

input[type="file"] {
    background: #ffc400;
    padding: 5px 17px;
    text-align: center;
}

.formcard {
    min-height: 92vh;
}
.gantt-elastic__header-title, .gantt-elastic__header-task-list-switch--wrapper{
  display:none;
}
.gantt-elastic__header-label:nth-child(4){
    display:none;
}
</style>

     
<script>
// import { required, email } from "vee-validate/dist/rules";
import {
  //   extend,
  ValidationProvider,
  //   setInteractionMode,
  ValidationObserver,
} from "vee-validate";
import axios from "axios";
import logo from "../assets/OpticVyu_logo_HD.png";
import locals from "../utils/locals";
import Preloader from "../components/PreLoader";


export default {
  components: {
    Preloader,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    Preloader: false,
    email: "",
    password: null,
    showPass: false,
    error: false,
    errorMessage: "",
    type: "",
    classs: "",
    loginerror: true,
    logo: logo,
    activelink: "/openAccessDashboard",
    activecolor: "amber accent-3",
    lang: locals,
  }),
  computed: {

    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  created() {
    //  console.log("language",this.lang);
    // this.checklogin();
  },
  mounted: function () {
    var authToken = this.getAuthToken();
    this.authorizeUser(authToken);
  },
  methods: {
    authorizeUser(authToken) {
      if (authToken.length > 10) {
        this.$store
          .dispatch("opentoken", authToken)
          .then(() => this.getUser())
          .catch((err) => {
            console.log(err);
            this.error = true;
            this.errorMessage =
              "Issue while redirecting";
            this.type = "error";

          });
      } else {
        this.getOpenAccessTokenUsingShortString(authToken)
          .then(value => {
            this.$store
              .dispatch("opentoken", value)
              .then(() => this.getUser())
              .catch((err) => {
                console.log(err);
                this.error = true;
                this.errorMessage =
                  "Issue while redirecting";
                this.type = "error";

              });
          })
          .catch(error => {
            alert(error); // "Unauthorized access"
          });
      }
      // localStorage.setItem('token', authToken)
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken
      // commit('auth_success', authToken, '') 
    },
    getUser() {
      return new Promise((resolve, reject) => {
        // commit('auth_request')
        axios({ url: '/user', method: 'GET' })
          .then(resp => {
            if (resp.data.success.active == 1) {
              localStorage.setItem('userdata', JSON.stringify(resp.data.success));
              resolve(resp)
              this.checklogin();
            } else {
              // commit('auth_error')
              localStorage.removeItem('token')
              reject("You are not authorised user.")
            }
          })
          .catch(err => {
            console.log("tis error ", err);
            reject(err)
          })
      })
    },
    checklogin() {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      if (this.userdata) {
        var redirect;
        if (this.userdata.active == 1) {
        
          if (this.userdata.cont_camera_aceess == 1) {
              redirect = "/dashboard";
            } else if (this.userdata.interior_access == 1) {
              redirect = "/dashboard/user-interior-view";
            } else if (this.userdata.material_access == 1) {
              redirect = "/dashboard/material/inventories";
            } else if (this.userdata.drone_access == 1) {
              redirect = "/dashboard/user-drone-view";
            }else if (this.userdata.linear_monitoring_access == 1) {
              redirect = "/dashboard/gps-map-view";
            }

            console.log("redirect",redirect);
          //this.$router.push(redirect).catch(() => { });
          window.location.href = redirect;
        } else {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/login");
          });
        }
      }
    },
    setrouter: function (url) {
      window.location.href = url;
    },
    getAuthToken() {

      const token = localStorage.getItem('token');

      if (this.$route.params.auth !== token) {

        localStorage.removeItem("activeProject");
        localStorage.removeItem("currentProject");
        localStorage.removeItem("userdata");
        localStorage.removeItem("currentCamIndex");
        localStorage.removeItem("procer_project");
        localStorage.removeItem("procer_project");

      }

      return this.$route.params.auth;


    },
    getOpenAccessTokenUsingShortString(authToken) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/fetch_oa_token",
          params: { short_string: authToken },
          method: "POST"
        }).then(resp => {
          if (resp.data.success == true) {
            resolve(resp.data.token[0]);
          } else {
            reject("Unauthorized access");
          }
        });
      });
    }
  },
};
</script>
<template>
  <div>
    <Preloader v-if="preloader" />
    <v-row>
      <v-col cols="12" md="12">
        
          <v-card class="pa-1 openAccessUsers">
            <v-row>  
            <v-col cols="2">
           <v-card-title> {{ lang.DailyReport }} </v-card-title>
           </v-col>
          <v-col cols="2" class="camStatusFilter">
              <v-btn class="mr-4" color="mylightbutton" @click="generateReport">
              Generate Report
          </v-btn>
          </v-col>
          <v-col cols="2" v-if="showTable" class="camStatusFilter">
                  <v-select label="Cam Status" v-model="colorFilterValue" :items="items" return-object
                    item-text="camera_status" outlined clearable dense>
                  </v-select>
                </v-col>
                <v-col cols="2" v-if="showTable" class="camStatusFilter">  
                <download-excel class="btn Block--text rightbutons downloadbutons" :data="nonWorkingCamList"
                  :fields="json_fields" worksheet="Cameras Status" :name="reportName">DownloadExcel
                  <v-icon right dark color="#000">mdi-file-excel</v-icon></download-excel>
                </v-col>     
              </v-row>   
          <v-alert dense text :type="alertType" v-if="alert">
            <v-row align="center">
              <v-col class="grow">
                {{ alertmessage }}
              </v-col>
            </v-row>
          </v-alert>
          <v-snackbar v-model="snackbar" center top timeout="3000" type="success">
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
         
        <v-row>
          <v-col :cols="imageurl ? '6':'12'">
           <v-data-table v-if="showTable" :headers="headers" :items="camerasList" :items-per-page="-1"  sort-by="firstname" class="elevation-1"
            :search="search">
            <template v-slot:item.project_name="{ item }">
              <span class="openimage" @click="imageOpen(item.url)">
                {{ item.project_name}}
              </span>
            </template>

            <template v-slot:item.camname="{ item }">
              <span  class="openimage" @click="imageOpen(item.url)">
                {{ item.camname}}
              </span>
            </template>
          
            <template v-slot:item.lastdate="{ item }">
              <span class="openimage" @click="imageOpen(item.url)">
                {{ item.lastdate}}
              </span>
            </template>
            <template v-slot:item.status="{ item }" dark>
              <v-timeline>
                <v-timeline-item @click="imageOpen(item.url)" :color="item.status ? 'amber accent-3' : 'red'" small></v-timeline-item>
              </v-timeline>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary">
                {{ lang.Reset }}
              </v-btn>
            </template>
             
          </v-data-table>
        </v-col>
            <v-col cols="6" md="6" class="fiximageitem">
                 <img :src="imageurl" v-if="imageurl" class="siteopenimage">
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    
    </v-row>
  </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
// import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    Preloader,
    "downloadExcel": JsonExcel,
  },
  name: "daily_report",
  data() {
    return {
      valid: true,
      checkboxactive: false,
      preloader: false,
      alert: false,
      alertmessage: '',
      alertType: '',
      Local: locals,
      lang: {},
      userdata: "",
      camerasList: [],
      status: true,
      showTable: false,
      colorFilterValue: null,
      items: [
        { value: "red", camera_status: "Red" },
        { value: "yellow", camera_status: "Yellow" }
      ],
      snackbar: false,
      search: "",
      text: "",
      dailyReportExcelData: {},
      json_fields: {
        "Project Name": "project_name",
        "Camera Number": "camname",
        "Last Image Take Time": "lastdate",
        "Remarks": " ",
      },
      nonWorkingCamList: [],
      reportName: "",
      imageurl : ''
    };
  },
  mounted() {

    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];

    // this.initViewer();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    const currentDate = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    this.reportName = "Daily-Report-" + currentDate.toLocaleDateString('en-IN', options);

  },
  computed: {
    headers() {
      return [
        {
          text: "Project",
          value: "project_name",
          align: "center"
        },
        {
          text: "Camera",
          value: "camname",
          align: "center"
        },
        {
          text: "Image Time",
          value: "lastdate",
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          filter: this.colorFilter,
          align: "center"
        },
        {
          text: "Status",
          value: "url",
          align: "d-none",
          class: "hidden", cellClass: "hidden"
        }
      ]
    },
  },
  methods: {
    colorFilter(value) {
      if (!this.colorFilterValue) {
        return true;
      }
      var swappedValue;
      if (value == true) {
        swappedValue = "yellow";
      } else {
        swappedValue = "red";
      }
      if (value != null) {
        var stringVal = JSON.parse(JSON.stringify(this.colorFilterValue));
        return swappedValue.includes(stringVal.value);
      }
    },
    imageOpen(itemurl){
     this.imageurl = itemurl;
    },
    generateReport() {
      axios({ url: '/projects/cameras/latest_status', method: 'GET' })
        .then(resp => {
          if (resp.data.success) {
            this.camerasList.length = 0;
            this.nonWorkingCamList.length = 0;
            var data = resp.data.success;
            data.forEach(element => {
              var d = element.image_name.split("_");
              var datetime = d[0] + "-" + d[1] + "-" + d[2] + " " + d[3] + ":" + d[4] + ":" + d[5]
              var tz = element.timezonehrs.split(":");
              let dateOneObj = new Date(datetime);
              let dateTwoObj = new Date();
              let milliseconds = Math.abs(dateTwoObj - dateOneObj);
              let hours = milliseconds / 36e5;
              let status = true;
              if (hours > tz[0]) {
                status = false;
              }

              this.camerasList.push({ project_name: element.project_name, camname: element.cam_serial, lastdate: datetime, status: status, url: element.url });
              this.showTable = true;
            });
            this.camerasList.forEach(element => {
              if (element.status === false) {
                this.nonWorkingCamList.push(element);
              }
            });
          } else {
            this.snackbar = true;
            this.text = "Cannot Generate Report.";
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
  }
};
</script>
<style>
.generateReportButton {
  position: absolute;
  right: 19px;
  top: 18px;
}

.camStatusFilter {
  margin-top: 20px;
}
.text-d-none.hidden {
    display: none;
}
.openimage{
  cursor:pointer;
}
.siteopenimage{
 width:100%;
}
.fiximageitem{
  position: fixed;
  top:115px;
  right:0;
}
</style>
  
  
<template>
    <div id="routew">
      <Preloader v-if="preloader" />
      <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="lang.Search"
        single-line
        hide-details
        class="mySearch"
      ></v-text-field>
                
                <download-excel
  class="btn ma-2 Block--text rightbutons downloadbutons"
  :data="taskdata"
  :fields="json_fields"
  :title="currentProject.project_name"
  worksheet="Tasks"
  :name="`${currentProject.project_name}.xlsx`"
>
   {{ lang.Export }}       <v-icon right dark color="#000">
                        mdi-arrow-down-bold
                    </v-icon>         
</download-excel>
                 <v-btn color="#ffc400" class="ma-2 Block--text rightbutons" @click="importFile =  true">
                    {{lang.Import }} 
                   <v-icon right dark>
                        mdi-arrow-up-bold
                    </v-icon>
                </v-btn>
                 <v-btn color="#ffc400" class="ma-2 Block--text rightbutons"
                 @click="addNewTask()">
                    {{ lang.AddNew }}  
                    <v-icon right dark>
                        mdi-clipboard-plus
                    </v-icon>
                </v-btn>
                
                <template>
                   
                    <v-data-table :headers="headers" :search="search" :items="taskdata" :items-per-page="50" :footer-props="footerpro" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="updateroutepoint(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </template>

      <!-- <v-btn @click="importFile = true" v-if="userdata.user_code == 2 || userdata.user_code == 0" color="#ffc400" class="btn ma-2 Block--text rightbutons downloadbutons">
    Update Route by Excel File
    <v-icon right dark color="#000">
    mdi-file-excel
    </v-icon>
    </v-btn> -->

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="addnewitem">
    <template>
     <v-card>
     <v-toolbar color="amber accent-3" dark> Add New Route Pointe </v-toolbar>
     <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="pointsubmit"
          >
            <v-container>
              <v-row>
               

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.piername"
                    label="Pier Name"
                    required
                    :rules="firstnamerules" ></v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <!-- <v-text-field
                    v-model="form.pointstatus"
                    label="Point Status"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field> -->
                  <v-select
          :items="statusitems"
          label="Point Status"
          v-model="form.pointstatus"
          required
          :rules="firstnamerules"
        ></v-select>
                </v-col>

                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.piermark"
                    label="Pier Mark"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>


                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.chainage"
                    label="Chainage"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>


                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.easting"
                    label="Easting"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>


                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.northing"
                    label="Northing"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>

                   <v-col cols="6" md="6">
                    <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="date1"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.startdate"
             label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.startdate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(date1)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                </v-col>

                <v-col cols="6" md="6">
                    <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="date2"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.enddate"
             label="End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.enddate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date2)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                </v-col>

                <v-col cols="6" md="6">
                    <v-menu
        ref="menu3"
        v-model="menu3"
        :close-on-content-click="false"
        :return-value.sync="date3"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.actualstart"
             label="Actual Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.actualstart"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu3 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu3.save(date3)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                </v-col>

                <v-col cols="6" md="6">
                    <v-menu
        ref="menu4"
        v-model="menu4"
        :close-on-content-click="false"
        :return-value.sync="date4"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.actualend"
             label="Actual End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.actualend"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu4 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu4.save(date4)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
                </v-col>
            
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.latitude"
                    label="Latitude"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>


                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="form.longitude"
                    label="Longitude"
                    required
                    :rules="firstnamerules"
                   
                  ></v-text-field>
                </v-col>
            
                 <!-- <div class="locationpicker">
  <GmapMap
    :center="center"
    :zoom="18"
    map-style-id="roadmap"
    :options="mapOptions"
     style="width: 100%; height: 300px"
    ref="mapRef"
    @click="handleMapClick"
  >
    <GmapMarker
      :position="marker.position"
      :clickable="true"
      :draggable="true"
      @drag="handleMarkerDrag"
      @click="panToMarker"
    />
  </GmapMap>

  <p>Selected Position: {{ marker.position }}</p>
</div> -->
  <v-col cols="12" md="12" class="center">
                <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="!valid"
                  color="mylightbutton"
                >
                  {{lang.submit}}
                </v-btn>
                <v-btn @click="addnewitem = false" color="mydarkbutton"> {{lang.Cancel}} </v-btn>
            </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
    </template>
    </v-dialog>

      <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="importFile">
    <template>
    <v-card>
    <v-toolbar color="amber accent-3" dark> Update Tasks</v-toolbar>
    <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="updateTaskFile">
    <v-container>
    <v-row>
    <v-col cols="12" md="12">
    <p> Upload an Excel file containing your route point.</p>
    <input type="file" @change="onChange" />
    <xlsx-read :file="file">
        <xlsx-sheets>
          <template #default="{sheets}">
            <select v-model="selectedSheet">
              <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                {{ sheet }}
              </option>
            </select>
          </template>
        </xlsx-sheets>
       
        <xlsx-json :sheet="sheet">
          <template #default="{collection}">
            <div ref="pointjson">
              {{ collection }}
            </div>
          </template>
        </xlsx-json>
      </xlsx-read>
    </v-col>
    <v-col cols="12" md="12">
    </v-col>
    <v-col cols="12" md="12">
    <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
    {{ lang.submit }}
    </v-btn>
    <v-btn class="mr-4" @click="importFile = false" color="mydarkbutton">
    {{ lang.Cancel }}
    </v-btn>
    </v-col>
    </v-row>
    </v-container>
    </v-form>
    </v-card>
    </template>
    </v-dialog>
</div>
</template>

<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";

import { locals } from "../../utils/locals";
import JsonExcel from "vue-json-excel";
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
import XlsxSheets from "vue-xlsx/dist/components/XlsxSheets";
import rotate from '../../assets/rotedmobilepsd.jpg';
//import XlsxTable from "vue-xlsx/dist/components/XlsxTable";
//import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson} from "../../dist/vue-xlsx.es.js"

export default {
  components: {
    Preloader,
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    "downloadExcel" : JsonExcel,
  },
  name: "Dron Route",
  data() {
    return {
      rotate: rotate,
      preloader: false,
      files: [],
      Local: locals,
      lang: {},
      userdata : '',
      firstnamerules: [(v) => !!v || this.lang.required], 
      useraccess : false,
      currentProject : {},
        importFile: false,
        filedata: {},
        file: null,
        alert : false,
        alertmessage : '',
        alertType : "success",
        menu: false,
       marker: { position: { lat: 20.708319218771653, lng: 72.99684861030222 } },
      center: { lat: 20.708319218771653, lng: 72.99684861030222 },
      mapOptions: {
        disableDefaultUI: true,
      },
      form : {
        piername : "testname",
        pointstatus : "start",
        piermark : "piermark",
        chainage : "1234566",
        easting :  "124444",
        northing : "123131",
        latitude : "12.343534",
        longitude : "1231.23123",
        startdate : "2024-02-01",
        enddate : "2024-02-05",
        actualstart : "2024-02-05",
        actualend : "2024-02-09"
       },
       menu1 : false,
       menu2: false,
       menu3: false,
       menu4 : false,
       XlsxRead,
        XlsxJson,
        valid: true,
        selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      headers: [{
                    text: 'Pier Mark',
                    align: 'start',
                    value: 'piermark',
                },
                {
                    text: 'Status',
                    value: 'pointstatus'
                },
                {
                    text: 'Chainage',
                    value: 'chainage'
                },
                 {
                    text: 'Easting',
                    value: 'easting'
                },
                {
                    text: 'Northing',
                    value: 'northing'
                },
                {
                    text: 'Latitude',
                    value: 'latitude'
                },
                {
                    text: 'Longitude',
                    value: 'longitude'
                },
                {
                    text: 'Startdate',
                    value: 'startdate'
                },
                
                {
                    text: 'Enddate',
                    value: 'enddate'
                },
                {
                    text: 'Actual_Startdate',
                    value: 'actualstart'
                },
                 {
                    text: 'Actual_Enddate',
                    value: 'actualend'
                },
                {
                    text: 'Action',
                    value: 'actions',
                    sortable: false
                }
            ],
            taskdata :[],
            addnewitem : false,
            statusitems : [
                'not startde',
                'pier_cage',
                'pier',
                'pier_cap',
                'deck'
            ],
            search: '',
            json_fields: {
      "Pier Mark": "piermark",
      "Status": "pointstatus",
      "Chainage": "chainage",
       "Easting": "easting",
       "Northing": "northing",
       "Latitude" : "latitude",
       "Longitude": "longitude",
       "Startdate" : "startdate",
       "Enddate" : "enddate",
       "Actual Startdate" : "actualstart",
       "Actual Enddate":"actualend"
    },

            
    }
},
mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    // if (this.userdata.open_access == 1) {
    //   this.$router.push("/dashboard");
    // }
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.getTaskData(); 

  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
        async updateTaskFile() {

             console.log("collection",this.sheets[0].data);

             let childEl = this.$refs.pointjson.innerHTML;

console.log("childEl", childEl);
            // let childEl = this.$refs.points.innerHTML;

            // console.log("childEl", childEl);

            this.preloader = true;
            var userdata = {
                filedata: childEl
            }
            var proid =  this.currentProject.id;
            console.log(userdata);
            axios({
                url: 'project/'+proid+'/drone/routesfile', // 'interior/update-tasks/excel-file',
                data: userdata,
                method: 'post'
            })
                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = "File updated successfully";
                        this.alertType = "success";
                    } else {
                        this.preloader = false;
                        this.importFile = false;
                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error";
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
async  pointsubmit() {
                        const valid = await this.$refs.form.validate();
                       
                        if (valid) {
                    this.preloader =  true;
                    var proid =  this.currentProject.id;
                        var url = "/project/"+proid+"/drone/routes";
                        axios({ url: url , data: this.form, method: "POST" })
                    .then((resp) => {
                        console.log("Project Update",resp);  
                            this.preloader =  false;
                            this.alert = true;
                            this.alertmessage = "Project details update successfully";
                            this.getCurrentProject()
                            // location.reload(true);
                    }) .catch((err) => {
                        console.log(err);
                        this.preloader =  false;
                    });
         }
  },
  handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
  handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
      // this.marker = { position: { lat: this.currentProject.latitude, lng: this.currentProject.longitude } },
      // this.center =  { lat: this.currentProject.latitude, lng: this.currentProject.longitude},
      
       this.form.latitude = e.latLng.lat(); 
       this.form.longitude = e.latLng.lng();
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },

    getTaskData() {
            this.preloader = true;
            var proid =  this.currentProject.id;
            axios({
                    url: 'get/'+proid+'/drone/routes',
                    method: 'GET'
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = resp.data.success;
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        updateroutepoint(item){

            this.form = item; 

               if(item.startdate == '0000-00-00'){
                this.form.startdate = '';
               }
               if(item.enddate == '0000-00-00'){
                this.form.enddate = '';
               }
               if(item.actualstart == '0000-00-00'){
                this.form.actualstart = '';
               }
               if(item.actualend == '0000-00-00'){
                this.form.actualend = '';
               }

            this.addnewitem = true;

            this.marker = { position: { lat: item.longitude, lng: item.latitude } }

        } 
        ,addNewTask(){
            this.form = {};
            this.addnewitem = true;
        }
}
}
</script>

<style>
  #routew .v-data-footer {
    position: flex !important;
    bottom: 0;
    width: 100% !important;
    background: #fff;
    left: 0;
}
</style>
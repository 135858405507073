<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="amber accent-3"
            fixed-tabs
            dark
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
              @click="setrouter('/dashboard/settings/create-timelapse')"
              key="0"
            >
             {{lang.OneTimeTimelapse}}
            </v-tab>
            <v-tab
              @click="setrouter('/dashboard/settings/create-timelapse')"
              key="1"
            >
              {{lang.RecurringTimelapse}}
            </v-tab>

            <v-tab
              key="2"
              @click="setrouter('/dashboard/settings/create-advance-timelapse')"
            >
              {{lang.AdvanceTimelapse }}
            </v-tab>

            <v-tab key="3"> {{lang.ProjectTimelapse}} </v-tab>
          </v-tabs>
          <v-card flat>
            <v-card flat>
              <v-alert dense text :type="alertType" v-if="alert">
                {{ alertmessage }}
              </v-alert>
              <v-snackbar
                v-model="snackbar"
                :multi-line="multiLine"
                top
                centered
                timeout="5000"
                type="success"
              >
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="setrouter_project()"
                  >
                   {{lang.Reload }}
                  </v-btn>
                </template>
              </v-snackbar>
              <v-form ref="projectform" @submit.prevent="addProjecttime">
                <v-card class="pa-5">
                  <v-row>
                    <v-col cols="11" sm="5">
                      <v-menu
                        v-model="pro_startDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                          color="amber accent-3"
                        >
                          <v-text-field
                            v-model="pro_startdate"
                            :label="lang.StartDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="amber accent-3"
                          v-model="pro_startdate"
                          @input="pro_startDatemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="11" sm="5">
                      <v-menu
                        v-model="pro_enddatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        color="amber accent-3"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="pro_enddate"
                            :label="lang.EndDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="amber accent-3"
                          v-model="pro_enddate"
                          @input="pro_enddatemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="11" sm="5">
                      <v-menu
                        ref="pro_starttimemenu"
                        v-model="pro_starttimemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="pro_starttimetime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        color="amber accent-3"
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                          color="amber accent-3"
                        >
                          <v-text-field
                            v-model="pro_starttimetime"
                            :label="lang.StartTime"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            format="24hr"
                            required
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          color="amber accent-3"
                          v-if="pro_starttimemenu"
                          v-model="pro_starttimetime"
                          full-width
                          format="24hr"
                          @click:minute="
                            $refs.pro_starttimemenu.save(pro_starttimetime)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="11" sm="5">
                      <v-menu
                        ref="pro_endtimemenu"
                        v-model="pro_endtimemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="pro_endtimetime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        color="amber accent-3"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="pro_endtimetime"
                            :label="lang.Endtime"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="pro_endtimemenu"
                          v-model="pro_endtimetime"
                          full-width
                          color="amber accent-3"
                          format="24hr"
                          @click:minute="
                            $refs.pro_endtimemenu.save(pro_endtimetime)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="11" sm="5">
                      <v-select
                        :items="speed"
                        v-model="pro_fps"
                        :label="lang.TimelapseSpeed"
                        item-text="title"
                        item-value="speed"
                        required
                      ></v-select>
                    </v-col>

                    <v-col cols="11" sm="5">
                      <!-- <v-select
                        :items="quality"
                        v-model="pro_resolution"
                        :label="lang.TimelapseQuality"
                        item-text="title"
                        item-value="quality"
                        required
                      ></v-select> -->
                    </v-col>
                   <!--  <v-col cols="11" sm="10">
                      <v-select
                        :items="pro_advance"
                        v-model="pro_advance_op"
                        label="Timelapse Advance settings"
                        item-text="title"
                        item-value="pro_advance"
                        @change="selectop(pro_advance_op)"
                      ></v-select>
                    </v-col> -->

                    <v-col
                      cols="11"
                      sm="5"
                      v-if="pro_advance_op === 'problending'"
                    >
                      <v-select
                        :items="ad_frame"
                        v-model="pro_frame_op"
                        :label="lang.Selectframeblending"
                        item-text="title"
                        item-value="ad_frame"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      sm="5"
                      v-if="pro_advance_op === 'problending'"
                    >
                      <v-select
                        :items="ad_smoothing"
                        v-model="pro_smoothing_op"
                        :label="lang.SelectVideoSmoothing"
                        item-text="title"
                        item-value="ad_smoothing"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      sm="5"
                      v-if="pro_advance_op === 'problending'"
                    >
                      <v-select
                        :items="ad_deflicker"
                        v-model="pro_deflicker_op"
                        :label="lang.SelectDeflicker"
                        item-text="title"
                        item-value="ad_deflicker"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      sm="5"
                      v-if="pro_advance_op === 'problending'"
                    >
                      <v-text-field
                        v-model="pro_text"
                        :label="lang.EnterOverlayText"
                      ></v-text-field>
                    </v-col>
                    <!-- {{choosecamera.length}} -->
                    <v-col
                      cols="3"
                      sm="3"
                      v-for="c in camers"
                      :key="c.cameraid"
                    >
                      <v-checkbox
                        v-model="choosecamera"
                        :label="c.pro_cam_serial"
                        color="orange"
                        :value="c.camera_id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-btn class="mr-4" type="submit" color="mylightbutton">
                    {{lang.submit}}
                  </v-btn>
                  <v-btn @click="clearproject" color="mydarkbutton">
                    {{lang.Reset }}
                  </v-btn>
                  <span class="info_rec">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-progress-question
                        </v-icon>
                      </template>
                      <span
                        >{{lang.info1}} <br />{{lang.info2}}</span
                      >
                    </v-tooltip>
                  </span>
                </v-card>
              </v-form>
            </v-card>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import VueSweetalert2 from "vue-sweetalert2";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar,
  },
  name: "CreateProjectTimelapse",
  data() {
    return {
      preloader: true,

      currentcam: {},
      timelapsdata: {},
      tab: 3,
      time1: null,
      time2: null,

      starttimetime: null,
      endtimetime: null,

      ad_starttimetime: null,
      ad_endtimetime: null,
      pro_starttimetime: null,
      pro_endtimetime: null,

      startdate: null,
      enddate: null,

      ad_startdate: null,
      ad_enddate: null,
      pro_startdate: null,
      pro_enddate: null,

      menu2: false,
      menu3: false,

      starttimemenu: false,
      endtimemenu: false,

      startDatemenu: false,
      enddatemenu: false,

      ad_starttimemenu: false,
      ad_endtimemenu: false,

      ad_startDatemenu: false,
      ad_enddatemenu: false,

      pro_starttimemenu: false,
      pro_endtimemenu: false,

      pro_startDatemenu: false,
      pro_enddatemenu: false,

      speed: [
        { speed: 24, title: "Fast" },
        { speed: 15, title: "Medium" },
        { speed: 10, title: "Slow" },
      ],
      // quality: [
      //   { quality: "1280x720", title: "SD Video(720p)" },
      //   { quality: "1920x1080", title: "HD Video(1080p)" },
      // ],
      advance: [
        { advance: "blending", title: "Time-lapse Blending" },
        { advance: "Stabilization", title: "Time-lapse Stabilization" },
        {
          advance: "deflicker_Stabilization",
          title: "Time-lapse Stabilization with deflicker",
        },
      ],
      pro_advance: [
        { pro_advance: "problending", title: "Time-lapse Blending" },
        { pro_advance: "none", title: "No advance customization" },
      ],
      ad_frame: [
        { ad_frame: "None", title: "None" },
        { ad_frame: "Average", title: "Average" },
        { ad_frame: "Overlay", title: "Overlay" },
      ],
      ad_smoothing: [
        { ad_smoothing: "None", title: "None" },
        { ad_smoothing: "Low", title: "Low" },
        { ad_smoothing: "Medium", title: "Medium" },
        { ad_smoothing: "High", title: "High" },
      ],
      ad_deflicker: [
        { ad_deflicker: "Yes", title: "Yes" },
        { ad_deflicker: "NO", title: "No" },
      ],
      recurringtype: "",
      currentProject: {},
      alert: false,
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      multiLine: false,
      fps: "",
      resolution: "",
      ad_fps: "",
      ad_resolution: "",
      pro_fps: "",
      pro_resolution: "",
      advance_op: "",
      frame_op: "",
      smoothing_op: "",
      deflicker_op: "",
      ad_text: "",
      pro_advance_op: "none",
      pro_frame_op: "",
      pro_text: "",
      pro_deflicker_op: "",
      pro_smoothing_op: "",
      camers: [],
      choosecamera: [],
      Local :locals,  
      lang : {},  
    };
  },
  computed: {
    params() {
      return {
        type: this.recurringtype,
        proj_id: this.currentProject.id,
        start_time: this.time1,
        end_time: this.time2,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
    },

    onetimeparmas() {
      return {
        type: "",
        proj_id: this.currentProject.id,
        start_date: this.startdate,
        end_date: this.enddate,
        start_time: this.starttimetime,
        end_time: this.endtimetime,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
    },

    projecttimeparmas() {
      return {
        type: "project_timelapse",
        proj_id: this.currentProject.id,
        camers: this.choosecamera,
        start_date: this.pro_startdate,
        end_date: this.pro_enddate,
        start_time: this.pro_starttimetime,
        end_time: this.pro_endtimetime,
        timelapse_fps: this.pro_fps,
        ct_resolution: this.pro_resolution,
        ad_custom: this.pro_advance_op,
        frame_blending: this.pro_frame_op,
        smoothing: this.pro_smoothing_op,
        deflicker: this.pro_deflicker_op,
        overlay_text: this.pro_text,
      };
    },

    adancetimeparmas() {
      return {
        type: "advance",
        proj_id: this.currentProject.id,
        start_date: this.ad_startdate,
        end_date: this.ad_enddate,
        start_time: this.ad_starttimetime,
        end_time: this.ad_endtimetime,
        timelapse_fps: this.ad_fps,
        ct_resolution: this.ad_resolution,
        ad_custom: this.advance_op,
        frame_blending: this.frame_op,
        smoothing: this.smoothing_op,
        deflicker: this.deflicker_op,
        overlay_text: this.ad_text,
      };
    },
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    this.getprojecttimelapseSettings();
    this.getCurrentProjectData();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
      // console.log("camera length",this.camers);
    } else {
      this.$router.push("/");
    }
    if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    /*  if(this.camers.length > 1){
              //this.useraccess = true
              console.log("user params",this.userdata);
        }else {
          this.$router.push('/dashboard/settings/create-timelapse')
        } */
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getCurrentProjectData();
      this.getprojecttimelapseSettings();
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        console.log("current router ", this.$route.path);
        //this.activelink = this.$route.path;
      }

      //console.log(url);
    },

    async submitprojecttime() {
      //const valid = await this.$refs.form.validate()

      this.addProjecttime(this.projecttimeparmas); // action to login
    },

    project_note: function () {
      this.$swal({
        icon: "info",
        title: "Note",
        html: this.lang.ptnotifiy,
        showConfirmButton: true,
      });
    },
    setrouter_project: function () {
      //this.$router.push("/dashboard/settings/create-project-timelapse");
      //this.$router.go();
      window.location.reload(true);
      //console.log(url);
    },

    addProjecttime() {
      var stdate = this.pro_startdate;
      var eddate = this.pro_enddate;
      var sttime = this.pro_starttimetime;
      var edtime = this.pro_endtimetime;
      var ctfps = this.pro_fps;
      var ctresolution = this.pro_resolution;
      var advance_custom = this.pro_advance_op;
      var CurrentDate = new Date();

      if (eddate > CurrentDate) {
        this.alert = true;
        this.alertmessage = this.lang.Adalertmessage;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (!stdate || !eddate || !sttime || !edtime) {
        this.alert = true;
        this.alertmessage =
         this.lang.adrequired; // "Start date-time and end date-time are mandatory fields.";
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (stdate > eddate || sttime > edtime) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired2;
        //  "Start date-time should be earlier than end date-time";
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (!ctfps) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired3;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
     /*  if (!advance_custom) {
        this.alert = true;
        this.alertmessage =
          "Please select Advance customization option and it's respected sub options.";
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      } */
      this.timelapsdata = {
        type: "project_timelapse",
        proj_id: this.currentProject.id,
        camers: this.choosecamera,
        start_date: this.pro_startdate,
        end_date: this.pro_enddate,
        start_time: this.pro_starttimetime,
        end_time: this.pro_endtimetime,
        timelapse_fps: this.pro_fps,
        // ct_resolution: this.pro_resolution,
        ct_resolution: "1280x720",
        ad_custom: this.pro_advance_op,
        frame_blending: this.pro_frame_op,
        smoothing: this.pro_smoothing_op,
        deflicker: this.pro_deflicker_op,
        overlay_text: this.pro_text,
      };
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      //console.log(timelapsdata);
      if (this.choosecamera.length > 1 && this.choosecamera.length < 5) {
        axios({
          url: "/project/" + currentProject.id + "/post_project_timelapse",
          data: this.timelapsdata,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.success) {
              console.log("Resp", resp);
              this.$swal({
                icon: "success",
                title: "Success",
                html: this.lang.proprocess,
                showConfirmButton: true,
              });
            } else {
              /* this.alert = true;
              this.alertmessage = "Error in schedule project timelapse";
              this.alertType = "error"; */
              this.$swal({
                icon: "error",
                title: "Error",
                html: this.lang.projectTiError,
                showConfirmButton: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            //setTimeout(()=>{alert("hello"); },2000);
            /*  this.alert = true;
                this.alertmessage =
              "Something went wrong. Please reload page and try again.";
                this.alertType = "error";
              var vv = this;
            setTimeout(()=>{
               vv.alert = false;
               }, 3000); */
            this.snackbar = true;
            this.text = this.lang.SomethingWentWrong;
          });
      } else {
        var v = this;
        v.alert = true;
        v.alertmessage = this.lang.alertmessageminmax;
        v.alertType = "error";

        setTimeout(function () {
          v.alert = false;
        }, 3000);
      }
    },
    getCurrentProjectData() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      // console.log(this.currentProject);
      this.drawer = false;
      this.camers = {};
      axios({ url: "/camera/" + currentProject.id, method: "GET" })
        .then((resp) => {
          //console.log("camrs==================>",resp);
          this.preloader = false;
          var a = 0;
          var b = 1;
          this.camers = resp.data.success.filter(function (item) {
            item.camName = "CAM " + b;
            item.iindex = a;
            a++;
            b++;

            return item.camera_active == 1;
          });
          if (this.camers.length > 1) {
            //this.useraccess = true
            this.$swal({
              icon: "info",
              title: "Note",
              html: this.lang.projectready,
              showConfirmButton: true,
            });
            console.log("camera length", this.camers);
          } else {
            this.$router.push("/dashboard/settings/create-timelapse");
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },

    getprojecttimelapseSettings() {
      /* if(this.camers.length > 1){
              //this.useraccess = true
              console.log("user params",this.userdata);
       
      this.$swal({
        icon: "info",
        title: "Note",
        html:
          "Project Time-lapse may take up to 12 hours to process. You will be notified once it is ready.",
        showConfirmButton: true,
      });
       }else {
          this.$router.push('/')
        } */
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      axios({
        url: "/project/" + currentProject.id + "/get_project_timelapse",
        method: "GET",
      })
        .then((resp) => {
          console.log("Resp", resp.data.success);

          if (resp.data.success) {
            var res = resp.data.success[0];
            //this.recurringtype = res.type;

            this.pro_startdate = res.start_time.split(" ")[0];
            this.pro_enddate = res.end_time.split(" ")[0];
            this.pro_starttimetime = res.start_time.split(" ")[1];
            this.pro_endtimetime = res.end_time.split(" ")[1];
            this.pro_fps = res.fps;
            this.pro_resolution = res.resolution;
            this.pro_advance_op = res.ad_customization;
            this.pro_frame_op = res.frame_blending;
            this.pro_smoothing_op = res.smoothing;
            this.pro_deflicker_op = res.deflicker;
            //this.pro_text = res.overlay_text;
          } else if (resp.data.error == "No existing custom timelapse found.") {
            //this.recurringtype = res.type;
            this.pro_startdate = "";
            this.pro_enddate = "";
            this.pro_starttimetime = "";
            this.pro_endtimetime = "";
            this.pro_fps = "";
            this.pro_resolution = "";
            this.pro_advance_op = "";
            this.pro_frame_op = "";
            this.pro_smoothing_op = "";
            this.pro_deflicker_op = "";
            //this.pro_text = res.overlay_text;
          }
        })
        .catch((err) => {
          console.log(err);
          /* this.alert = true;
                    this.alertmessage = "Something went wrong please reload page and try again.";
                    this.alertType = "error" */
          /* this.snackbar = true;
          this.text = `Something went wrong. Please reload page and try again.`; */
        });
    },
    clearproject() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      axios({
        url: "/project/" + currentProject.id + "/resetting_project_timelapse",
        method: "POST",
      }).then((resp) => {
        if (resp.data.success) {
          this.$swal({
            icon: "success",
            title: "Reset successfully!",
            html: "Your Setting has been Reset.",
            showConfirmButton: true,
          });

          /* this.alert = true;
                    this.alertmessage = resp.data.success; 
                    this.alertType = "success";*/
          this.pro_startdate = "";
          this.pro_enddate = "";
          this.pro_starttimetime = "";
          this.pro_endtimetime = "";
          this.pro_fps = "";
          this.pro_resolution = "";
          this.pro_advance_op = "";
          this.pro_frame_op = "";
          this.pro_smoothing_op = "";
          this.pro_deflicker_op = "";
          this.pro_text = "";
        } else {
          /* this.alert = true;
          this.alertmessage = "Something went wrong. Please try again.";
          this.alertType = "error"; */
          this.$swal({
            icon: "error",
            title: "Error",
            html: this.lang.reseterror,
            showConfirmButton: true,
          });
        }
      });
    },
  },
};
</script>
<style>
.info_rec {
  position: relative;
  left: 2%;
}
.mdi-progress-question {
  font-size: 28px !important;
}
</style>


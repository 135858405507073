<template>
     <v-alert
      prominent
      class="notificationclearcash"
      max-height='40'
      :icon="false"
      v-if="showNotification"
    >
      <v-row align="center">
        <v-col class="grow">
           {{ message }}
            <v-btn x-small color="#f8bb03" @click="eraseCache"> {{lang.Refresh }}  </v-btn>
        </v-col>
       
      </v-row>
    </v-alert>
</template>

<script>
import axios from 'axios'
import { locals } from "../../utils/locals";
export default {
    data: () => ({
       showNotification : false,
       message : 'Pease Realod page.',
         Local :locals,  
         lang : {}, 
    }),
    mounted() {

       var selectleng = this.$cookies.get('currentLanguage');

       this.lang = locals[selectleng];
      
        var v = this.$store.state.packageVersion;
        
        axios({
                    url: '/version/new_version',
                    method: 'GET'
                })
                .then(resp => {
                      
                      console.log("Current Version",resp.data.success[0].version);
                       console.log(" Version",v);
                       if(v == resp.data.success[0].version){
                           this.showNotification = false;
                          
                       }else{
                            this.showNotification = true;
                             this.message = resp.data.success[0].message
                       }

                }).catch(err => {
                    console.log(err);
                    
                })
    },
    methods: {
        eraseCache(){
          location.reload(true);
        //window.location = window.location.href+'?eraseCache=true';
         }
      }
    
}
</script>

<style>
.notificationclearcash {
    z-index: 9;
    padding: 6px 15px !important;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-align: center;
    background: #ead6b7 !important;
    border-bottom: 1px solid #2929292e !important;
    margin: 0;
    font-weight: 300;
}
</style>

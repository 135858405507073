<template>
    <div>
      <!-- Display UTM coordinates -->

      <v-file-input
            v-model="dronefile"
            placeholder="srtfile"
            label="srtfile"
            prepend-icon="mdi-paperclip"
            @change="dronefileupload"
            
          >
        </v-file-input>

      <div v-if="utmCoordinates">
        <p>UTM Coordinates (Zone {{ utmZone }}): {{ utmCoordinates[0] }}, {{ utmCoordinates[1] }}</p>
        <br>
        <p>Latitude (Decimal Degrees): {{ latitudeDecimal }}</p>
        <br>
        <p>Longitude (Decimal Degrees): {{ longitudeDecimal }}</p>
      </div>
    </div>
  </template>
  
  <script>
 // import proj4 from 'proj4';
  //import parseSRT from "parse-srt";
  export default {
    data() {
      return {
        utmZone: '43N',
        isNorthernHemisphere: true,
        // latitudeDMS: "21°53'28.8407\"N", //21° 54´ 19.127" N  72° 59´ 11.911" E
        // longitudeDMS: "72°59'48.4277\"E",
        utmCoordinates: null,
        latitudeDecimal: null,
        longitudeDecimal: null,
        dronefile: "",
      };
    },
    mounted() {
      //this.convertDmsToDecimal();
    },
    methods: {
      convertDmsToDecimal(latitudeDMS,longitudeDMS) {
        // Function to convert DMS (degrees, minutes, seconds) to Decimal 
         console.log(latitudeDMS, longitudeDMS);
        const dmsToDecimal = (dms) => {
          const [degrees, minutes, seconds] = dms.match(/(\d+)°( \d+)'([ \d.]+)"/).slice(1).map(Number);
          return degrees + minutes / 60 + seconds / 3600;
        };
  
        // Convert DMS to Decimal Degrees
        var latitudeDecimal = dmsToDecimal(latitudeDMS);
        var longitudeDecimal = dmsToDecimal(longitudeDMS);
  
        // Define the projection strings for WGS84 (Decimal Degrees) and UTM
       // const wgs84Projection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
       // const utmProjection = `+proj=utm +zone=${this.utmZone} ${this.isNorthernHemisphere ? '+north' : '+south'} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
  
        // Use proj4 to perform the conversion from Decimal Degrees to UTM
        //this.utmCoordinates = proj4(wgs84Projection, utmProjection, [this.longitudeDecimal, this.latitudeDecimal]);
         
        return [latitudeDecimal,longitudeDecimal]
      },
      dronefileupload(f) {
      this.file = f;
      const reader = new FileReader();
     
         // const desiredKeys = Object.keys({ "id": null, "start": null, "end": null, "text": null, "long": null, "lat": null, "distance": null, "height": null });
          reader.onload = res => {
            // res.target.result;

            var xc = res.target.result.split('-->');
           // const fileContentKeys = Object.keys(jsonSubs[0]);

             //console.log(JSON.stringify(fileContentKeys));
             var jsonSubs = xc.slice(1);
              //console.log("jsonSubs",jsonSubs);
      
            // if (JSON.stringify(fileContentKeys) === JSON.stringify(desiredKeys)) {
              var temp = [];
              jsonSubs.forEach((item, index )=> {
               //  console.log("items",item);

                 let blocks = item.trim().split('\n');

                console.log("blocks",blocks);
 
       
              //  // var pars = item.text.split(",");
              //   var lat = item.split("/n");
               
              //    console.log(lat);

               var latlongs =  this.convertDmsToDecimal(blocks[1], blocks[2]);

                console.log("latlongs",latlongs);
         
                // if(latlongs){

                //   item.long = latlongs[0];
                //  item.lat = latlongs[1];

                // }
                var timeString = blocks[0];   // your input string
                let parts = timeString.split(':');
    let milliseconds = parts[2].split(',')[1];
    parts[2] = parts[2].split(',')[0];
    
    // Calculate the total number of seconds
    let totalSeconds = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]) + parseFloat("0." + milliseconds);

                 temp.push({ "id": index, "start": totalSeconds, "end": null, "text": null, "long": latlongs[1], "lat": latlongs[0], "distance": null, "height": null });

            });
              
             console.log("temppp", temp);
              this.havedata = true;
            // var content = JSON.stringify(temp)

           

             let textArray = temp.map(obj => JSON.stringify(obj));

    // Join the array of string representations into a single string separated by newline characters
    let text = textArray.join('\n');

    // Create a Blob object to hold the data
    let blob = new Blob([text], { type: 'text/plain' });

    // Create a temporary anchor element
    let a = document.createElement('a');
    a.href = URL.createObjectURL(blob);

   var tiem = new Date().getTime();

// Set the filename
a.download = 'converted'+tiem+'.srt';

// Programmatically trigger a click event on the anchor element
a.click();

// Clean up
URL.revokeObjectURL(a.href);

//               var filename = "log.txt";
// var text = content;
// var element = document.createElement('a');
// element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text.join("\n")));
// element.setAttribute('download', filename);

// element.style.display = 'none';
// document.body.appendChild(element);

// element.click();

// document.body.removeChild(element);
            // } else { 
            //   this.alert = true;
            //   this.alertmessage = "Wrong Srt File.";
            // }
          }
        reader.onerror = err => console.log(err);
        reader.readAsText(this.file);
     },
    }
  };
  </script>
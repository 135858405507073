<template>
  <div class="graybg">
    <Preloader v-if="preloader" />
<!--------------- Desktop View button ----------------------->
    <div
      v-ripple="{ center: true }"
      class="text-center elevation-1 pa-0 headline vhfix desktop"
    >
      <v-dialog v-model="dialog" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2 vhfix desktop"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            {{ d1 }}
            <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
          
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDate($event)"
            v-model="picker"
            :max="new Date().toISOString().substr(0, 10)" :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslots" :key="i" @change="selectTimeSlot(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group active-class="amber" multiple :color="activecolor">
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}} 
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslots"
              :key="i"
              @change="selectTimeSlot(item.image_id)"
              >{{ item.updated_time }}</v-chip
            > 
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Close}}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Done}}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn class="vhfix desktop" v-if="!checkcam360" color="success" dark @click="swithmode">
       {{ lang.SwitchMode }}
      </v-btn>
      

      <v-dialog v-model="dialogtwo" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2 vhfix desktop"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            {{ d2 }}
            <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
          
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDatetwo($event)"
            v-model="pickertwo"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslotstwo" :key="i" @change="selectTimeSlottwo(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group
            active-class="amber accent-3-darken-3--text text--accent-4"
            multiple
            :color="activecolor"
          >
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslotstwo"
              :key="i"
              @change="selectTimeSlottwo(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Close }}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Done }}
            </v-btn>
            <v-snackbar v-model="snackbar" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- <v-btn color="#f8bb03" light @click="getDiffrence">
        {{ lang.CheckDifference }}
      </v-btn> -->
      <div style="position: absolute;right: 1%;top: 0%;">
        <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
           class="ma-2"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
           Compare for<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="getimges(days=7)">
            <v-list-item-title>Last 7 days</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="getimges(days=15)"
          >
            <v-list-item-title> Last 15 days</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=30)">
            <v-list-item-title>Last 1 Month</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=90)">
            <v-list-item-title>Last 3 Months</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=180)">
            <v-list-item-title>Last 6 Months</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2 vhfix desktop"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            Report<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
          
        </template>
        <v-list>
          <v-list-item  @click="getpdfreport()">
            <v-list-item-title> Download Report</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="opensharebox"
          >
            <v-list-item-title> Email Report</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/reports/schedule-report')">
            <v-list-item-title>Schedule Report</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
     </div>
    </div>
<!---------------- Mobile View button ----------------------->
    <div
      v-ripple="{ center: true }"
      class="text-center elevation-1 pa-0 headline vhfix mobileonly"
      style="overflow: hidden;">
    <v-row>
      <v-col
        cols="4"
        md="4"
        sm="4"
      >
      <v-dialog v-model="dialog" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          
          <v-btn
            class="mx-2 vhfix mobileonly"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
            small
            >
            {{ d1 }}
           <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDate($event)"
            v-model="picker"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslots" :key="i" @change="selectTimeSlot(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group active-class="amber" multiple :color="activecolor">
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslots"
              :key="i"
              @change="selectTimeSlot(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Close}}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Done}}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
     <v-col
        cols="4"
        md="4"
        sm="4"
      > 
      <v-btn class="vhfix mobileonly" v-if="!checkcam360" color="success" dark small @click="swithmode">
        {{ lang.SwitchMode }}
        <!-- <v-icon right v-bind="attrs">mdi-camera-switch</v-icon> -->
       </v-btn>
    </v-col>
<v-col
        cols="4"
        md="4"
        sm="4"
        style="position: relative;right: 6%;">
      <v-dialog v-model="dialogtwo" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
         
          <v-btn
            class="mx-2 vhfix mobileonly"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
            small
            >
            {{ d2 }}
            <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDatetwo($event)"
            v-model="pickertwo"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslotstwo" :key="i" @change="selectTimeSlottwo(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group
            active-class="amber accent-3-darken-3--text text--accent-4"
            multiple
            :color="activecolor"
          >
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslotstwo"
              :key="i"
              @change="selectTimeSlottwo(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Close }}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Done }}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>  
  </v-row>
      <!-- <v-btn color="#f8bb03" light @click="getDiffrence">
        {{ lang.CheckDifference }}
      </v-btn> -->
   
   <v-row>
    <v-col
        cols="4"
        md="4"
        sm="4"
      >
    </v-col> 
      <v-col
        cols="4"
        md="4"
        sm="4"
      >
        <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
           class="ma-2 vhfix mobileonly"
            outlined
            small
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
           Compare for<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="getimges(days=7)">
            <v-list-item-title>Last 7 days</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="getimges(days=15)"
          >
            <v-list-item-title> Last 15 days</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=30)">
            <v-list-item-title>Last 1 Month</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=90)">
            <v-list-item-title>Last 3 Months</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=180)">
            <v-list-item-title>Last 6 Months</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col
       cols="4"
        md="4"
        sm="4"
      >
      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
         
          <v-btn
            class="ma-2 vhfix mobileonly"
            outlined
            small
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            Report<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item  @click="getpdfreport()">
            <v-list-item-title> Download Report</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="opensharebox"
          >
            <v-list-item-title> Email Report</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/reports/schedule-report')">
            <v-list-item-title>Schedule Report</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
     </v-row>
    
</div>
<!----- Tab only--------------->
<div
      v-ripple="{ center: true }"
      class="text-center elevation-1 pa-0 headline vhfix tabonly"
      style="overflow: hidden;">
    <v-row>
      <v-col
        cols="3"
        md="3"
        sm="3"
      >
      <v-dialog v-model="dialog" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          
          <v-btn
            class="mx-2 vhfix tabonly"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
            small
            >
            {{ d1 }}
           <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDate($event)"
            v-model="picker"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslots" :key="i" @change="selectTimeSlot(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group active-class="amber" multiple :color="activecolor">
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslots"
              :key="i"
              @change="selectTimeSlot(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Close}}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{lang.Done}}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
     <v-col
        cols="2"
        md="2"
        sm="2"
      > 
      <v-btn class="vhfix tabonly" v-if="!checkcam360" color="success" dark small @click="swithmode">
        {{ lang.SwitchMode }}
        <!-- <v-icon right v-bind="attrs">mdi-camera-switch</v-icon> -->
       </v-btn>
    </v-col>
<v-col
        cols="3"
        md="2"
        sm="3"
        >
      <v-dialog v-model="dialogtwo" scrollable max-width="600px">
        <template v-slot:activator="{ on, attrs }">
         
          <v-btn
            class="mx-2 vhfix tabonly"
            outlined
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
            small
            >
            {{ d2 }}
            <v-icon right v-bind="attrs">mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-date-picker
            :color="activecolor"
            @change="getSelectedDatetwo($event)"
            v-model="pickertwo"
            :max="new Date().toISOString().substr(0, 10)"
            :allowed-dates="dateActiver?allowedDates:alldates" :picker-date.sync="pickerDate"
          ></v-date-picker>

          <v-divider></v-divider>
          <div class="timeList">
            <!-- <div class="timeList" v-if="timeslots.length > 0"> -->
            <v-list mandatory rounded max-height="341px">
              <v-list-item-group>
                <v-dialog v-model="timeslotloader" persistent width="300">
                  <v-card :color="activecolor" dark>
                    <v-card-text>
                      {{ lang.Pleasestandby }}
                      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list-item v-for="(item, i) in timeslotstwo" :key="i" @change="selectTimeSlottwo(item.image_id)" class="itemBottomMargin">
                  <v-list-item-content active-class="amber accent-3-darken-3--text text--accent-4">
                    <v-chip>{{ item.updated_time }}</v-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          
          </div>
          <!-- <v-chip-group
            active-class="amber accent-3-darken-3--text text--accent-4"
            multiple
            :color="activecolor"
          >
            <v-dialog v-model="timeslotloader" persistent width="300">
              <v-card :color="activecolor" dark>
                <v-card-text>
                  {{lang.Pleasestandby}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-chip
              v-for="(item, i) in timeslotstwo"
              :key="i"
              @change="selectTimeSlottwo(item.image_id)"
              >{{ item.updated_time }}</v-chip
            >
          </v-chip-group> -->
          <v-card-actions>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Close }}
            </v-btn>
            <v-btn color="green darken-1" text @click="dialogtwo = false">
             {{ lang.Done }}
            </v-btn>
            <v-snackbar v-model="snackbarDatesWithImages" :multi-line="multiLine" top right timeout="3000" type="success">
              {{ archiveCalenderyearMonth }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{lang.Close}}
                </v-btn>
              </template>
            </v-snackbar>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 dateWithImageButton" color="amber" @click="datesWithImage()" dark v-bind="attrs" v-on="on">
                  <v-icon dark>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>Capturing Insights</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>  
  <!-- </v-row> -->
      <!-- <v-btn color="#f8bb03" light @click="getDiffrence">
        {{ lang.CheckDifference }}
      </v-btn> -->
   
   <!-- <v-row>
    <v-col
        cols="4"
        md="4"
        sm="4"
      > 
    
    </v-col> -->
      <v-col
        cols="2"
        md="2"
        sm="2"
      >
        <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
           class="ma-2 vhfix tabonly"
            outlined
            small
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
           Compare for<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="getimges(days=7)">
            <v-list-item-title>Last 7 days</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="getimges(days=15)"
          >
            <v-list-item-title> Last 15 days</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=30)">
            <v-list-item-title>Last 1 Month</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=90)">
            <v-list-item-title>Last 3 Months</v-list-item-title>
          </v-list-item>
          <v-list-item @click="getimges(days=180)">
            <v-list-item-title>Last 6 Months</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col
       cols="2"
        md="2"
        sm="2"
      >
      <v-menu offset-y open-on-hover :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
         
          <v-btn
            class="ma-2 vhfix tabonly"
            outlined
            small
            color="amber accent-3"
            v-bind="attrs"
            v-on="on"
          >
            Report<v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item  @click="getpdfreport()">
            <v-list-item-title> Download Report</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="opensharebox"
          >
            <v-list-item-title> Email Report</v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/reports/schedule-report')">
            <v-list-item-title>Schedule Report</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
     </v-row>
    
</div>

<!------------- General view for all device --------------------->
    <div class="comarebox" v-if="comapreview && !checkcam360">
      <VueCompareImage :leftImage="compareimage1" :rightImage="compareimage2" />
    </div>
    <div class="splitview" v-if="!comapreview || checkcam360">
      <v-btn
        fab
        color="red"
        v-if="checkcam360"
        class="locks_reen"
        @click="lockScreens"
        title="Lock"
      >
        <v-icon dark>
          {{ lock ? "mdi-lock" : "mdi-lock-open" }}
        </v-icon>
      </v-btn>
      <div v-if="show_1img360" class="full360right">
        <v-pannellum :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable" :hfov="hfov" :yaw="yaw"  :pitch="pitch" :src="compare_1img360" ref="panaromascreen"></v-pannellum>
      </div>

      <div id="ccontrols" v-if="lock">
        <div class="ctrl" id="pan-up" @click="panUp">&#9650;</div>
        <div class="ctrl" id="pan-down" @click="panDown">&#9660;</div>
        <div class="ctrl" id="pan-left" @click="panLeft">&#9664;</div>
        <div class="ctrl" id="pan-right" @click="panRight">&#9654;</div>
        <div class="ctrl" id="zoom-in" @click="panIn">&plus;</div>
        <div class="ctrl" id="zoom-out" @click="panOut">&minus;</div>
      </div>
      
      <v-zoomer ref="zoomer" v-if="!show_1img360" class="zzoomerright">
        <img
          v-if="!show_1img360"
          :src="compareimage1"
          v-on:load="loaded"
          style="object-fit: contain; width: 100%; height: 100%"
        />
      </v-zoomer>
      <div v-if="show_2img360" class="full360left">
        <v-pannellum :showFullscreen="showFullscreen" :showZoom="showZoom"   :doubleClickZoom="doubleClickZoom " :mouseZoom="mouseZoom"  :draggable="draggable" :hfov="hfov" :yaw="yaw"  :pitch="pitch" :src="compare_2img360" ref="panaromascreen2"></v-pannellum>
      </div>
      <v-zoomer ref="zoomer1" v-if="!show_2img360" class="zzoomerleft">
        <img
          v-if="!show_2img360"
          :src="compareimage2"
          @load="loaded1"
          style="object-fit: contain; width: 100%; height: 100%"
        />
      </v-zoomer>
    </div>
    <div class="c_timeline" :class="{ active: showtimeline }">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <a class="timelinetoggle vhfix desktop" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="showtimeline = !showtimeline" style="margin-top: -3%;">
                    <v-icon color="#000">mdi-arrow-up-drop-circle</v-icon>
                </a>
                <a class="timelinetoggle vhfix mobileonly" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="showtimeline = !showtimeline" style="margin-top: -10%;">
                    <v-icon color="#000">mdi-arrow-up-drop-circle</v-icon>
                </a>
                <a class="timelinetoggle vhfix tabonly" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="showtimeline = !showtimeline" style="margin-top: -4%;">
                    <v-icon color="#000">mdi-arrow-up-drop-circle</v-icon>
                </a>
            </template>
            <span> {{lang.FullTimeline}} </span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <a class="timelinetoggle vhfix desktop" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="resetcompare()" style="margin-top: -3%;position: relative;left: -4%;">
                    <v-icon color="#000">mdi-repeat-off</v-icon>
                </a>
                <a class="timelinetoggle vhfix mobileonly" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="resetcompare()" style="margin-top: -10%;position: relative;left: -4%;">
                    <v-icon color="#000">mdi-repeat-off</v-icon>
                </a>
                <a class="timelinetoggle vhfix tabonly" v-bind="attrs" v-on="on" :class="{ active: showtimeline }" @click="resetcompare()" style="margin-top: -4%;position: relative;left: -1%;">
                    <v-icon color="#000">mdi-repeat-off</v-icon>
                </a>
            </template>
            <span>Reset comparison</span>
        </v-tooltip>
       
        <carousel :key="comrefresh" class="timelinec" :autoplay="false" :responsive="{0:{items:2,nav:false},600:{items:3,nav:true},1000:{items:8,nav:true, slideBy:7}}"  :dots="false" :margin="8" :nav="true" :navText="['','']">
            <div v-for="(image, index) in daywiseimageidsarray" :key="index" class="timelaineimagebox">
                <input type="checkbox" name="imgcheck" v-model="checkselected" @click="oncompare($event,index,image.prefix, image.image_name)" class="checkboxxsle" :value="index" />
                <div class="fixImageBox">
                <img for ="imgcheck" :src="image.url">
                  </div>
                <p class="disply_date">{{ image.display_date }}</p>
            </div>

        </carousel>
    </div>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>
    <div class="clayer" v-if="lock"></div>
     
      <v-zoomer class="zzoomerfull" v-if="diffeimagedialog">      
  <img
    :src="diffeimage"
    style="object-fit: contain; width: 100%; height: 100%;"
  >
</v-zoomer>
  <v-icon  v-if="diffeimagedialog" class="closebuttons" @click="diffeimagedialog = false"
         >mdi-window-close</v-icon> 
   <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="sharebox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> {{lang.ShareByEmail}}</v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send}}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="sharebox = false" color="mydarkbutton">
                    {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Preloader from "../../components/PreLoader";
import image1 from "../../assets/opticvyu_comp_l.png";
import image2 from "../../assets/opticvyu_comp_r.png";
import VueCompareImage from "vue-compare-image";
import VueZoomer from "vue-zoomer";
import VuePannellum from "vue-pannellum";
import { locals } from "../../utils/locals";
import carousel from "vue-owl-carousel";
// import moment from 'moment'
Vue.use(VueZoomer);
export default {
  components: {
    Preloader,
    VueCompareImage,
    carousel,
    VPannellum: VuePannellum,
  },
  name: "Compare",
  data() {
    return {
      preloader: false,
      dialog: false,
      dialogtwo: false,
      picker: new Date().toISOString().substr(0, 10),
      pickertwo: new Date().toISOString().substr(0, 10),
      activecolor: "amber accent-3",
      timeslots: [],
      timeslotstwo: [],
      compareimage1: image1,
      compareimage11: "",
      compareimage2: image2,
      timeslotloader: false,
      comapreview: true,
      d1: " Select Date",
      d2: "Select Date",
      multiLine: false,
      snackbar: false,
      text: `Images not found on the selected date.`,
      checkcam360: false,
      compare_1img360: "",
      compare_2img360: "",
      show_1img360: false,
      show_2img360: false,
      current_cam: {},
      lock: false,
      hfov: 75,
      yaw: 0,
      pitch: 0,
      draggable: true,
      mouseZoom: true,
      doubleClickZoom: true,
      showZoom: true,
      showFullscreen: true,
      diffeimagedialog : false,
      diffeimage : '',
        Local :locals,  
           lang : {},
      daywiseimageidsarray: [],
      ex3: {
                val: 0,
                color: "#f8bb03"
            },
      comrefresh:0,
      showtimeline: true,
      reporttype: "Comparison Report",
      currentdt:'',
      currentProject: "",
      sharebox: false,
      valid: true,
      email: "",
      message: "",
      subject: "",
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      imgarr:[0,1],
      checkselected:[],
      image1:'',
      image2:'',
      send: false,
      dateActiver: false,
      arrayDates: [],
      pickerDate: null,
      datesWithImages: [],
      archiveCalenderyearMonth: false,
      snackbarDatesWithImages: false,
    };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.initViewer();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.current_cam = this.$store.state.currentCamera;
    if (this.current_cam.cam_360 == 1) {
      console.log("Current cam data", this.current_cam);
      this.checkcam360 = true;
    } else {
      this.checkcam360 = false;
    }
    this.nativagationImaegsThumnailList(
                this.$store.state.currentCamera.camera_id
            );
            console.log("111111",this.$store.state.currentCamera.camera_id)
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera)
      this.timeslots = [];
      this.timeslotstwo = [];
      this.current_cam = this.$store.state.currentCamera;
      this.compareimage1 = image1;
      this.compareimage2 = image2;
      if (this.current_cam.cam_360 == 1) {
        console.log("Current cam data", this.current_cam);
        this.checkcam360 = true;
      } else {
        this.checkcam360 = false;
      }
      this.nativagationImaegsThumnailList(
                this.$store.state.currentCamera.camera_id
            );
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
    },
  },
  methods: {

    allowedDates(val) {
      var values = [];
      this.datesWithImages.forEach((item) => {
        var daywithimage = item.image_date;
        values.push(daywithimage);
      });

      if (values.includes(val)) return true
      if (values.includes(val)) return ['red', '#00f']
      return false
    },
    alldates(val) {
      return val;
    },
    datesWithImage() {
      this.timeslotloader = true;
      this.dateActiver = !this.dateActiver;
      var camera_id = this.current_cam.camera_id;
      this.snackbarDatesWithImages = true;
      if (this.dateActiver) {
        this.archiveCalenderyearMonth = "Filtered dates where images are available.";
      } else {
        this.archiveCalenderyearMonth = "Showing all dates.";
      }

      axios({
        url: "/camera/" + camera_id + "/dates_with_images",
        method: "GET",
      })
        .then((resp) => {
          this.datesWithImages = resp.data.success;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    initViewer() {
      this.current_cam = this.$store.state.currentCamera;
      //console.log("init Viewer");
      this.preloader = false;
      this.nativagationImaegsThumnailList(
                this.$store.state.currentCamera.camera_id
            );
    },
    getSelectedDate(data) {
      this.timeslotloader = true;
      this.d1 = data;
      var d = data.split("-");
      //console.log(d);
      var nd = d[0] + d[1] + d[2];

      //  console.log(nd, this.current_cam.camera_id);

      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }

          var dd = resp.data.success;

          this.timeslots = dd;

          // console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    selectTimeSlot(imageID) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.getImageUrlfirst(imageID);
      this.preloader = true;
    },
    getImageUrlfirst(id) {
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
          this.current_cam = this.$store.state.currentCamera;
          if (this.current_cam.cam_360 == 1) {
            this.show_1img360 = true;
            this.compare_1img360 = resp.data.success.image_url;
          } else {
            this.compareimage1 = "";
            this.show_1img360 = false;
            this.compareimage1 = resp.data.success.image_url;
          }
          this.dialog = false;
          var v = this;
          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
        });
    },
    getSelectedDatetwo(data) {
      this.d2 = data;
      this.timeslotloader = true;
      var d = data.split("-");
      // console.log(d);
      var nd = d[0] + d[1] + d[2];

      //console.log(nd, this.current_cam.camera_id);

      // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
      axios({
        url:
          "/camera/" + this.current_cam.camera_id + "/list/images/date/" + nd,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.error) {
            this.snackbar = true;
          }
          var dd = resp.data.success;

          this.timeslotstwo = dd;

          //console.log(this.timeslots);
          //this.regulertimeline = false;
          //this.dialog = false;
          this.timeslotloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectTimeSlottwo(imageID) {
      //  console.log("selectTimeSlotImage id", imageID);
      this.getImageUrlSecond(imageID);
      this.preloader = true;
    },
    getImageUrlSecond(id) {
      this.preloader = true;
      axios({ url: "/camera/" + this.current_cam.camera_id + "/image/" + id + "/list/url", method: "GET" })
        .then((resp) => {
          this.current_cam = this.$store.state.currentCamera;
          if (this.current_cam.cam_360 == 1) {
            this.show_2img360 = true;
            this.compare_2img360 = resp.data.success.image_url;
          } else {
            this.show_2img360 = false;
            this.compareimage2 = resp.data.success.image_url;
          }
          //this.compareimage2 = resp.data.success.image_url;
          //this.compare_2img360 = resp.data.success.image_url;
          this.dialogtwo = false;

          var v = this;
          setTimeout(function () {
            v.preloader = false;
          }, 3000);

          //this.viewer.tileSources = this.source;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    swithmode() {
      this.preloader = true;
      this.comapreview = !this.comapreview;
      //  console.log("Swith Mode",this.comapreview)
      var v = this;
      setTimeout(function () {
        v.preloader = false;
      }, 2000);
    },
    getDiffrence(){

       this.preloader = true;

          var key_1 = this.compareimage1.split('?');
          var key_2 = this.compareimage1.split('?');

          var file_key_1 =  key_1[0].split('001/');
          var file_key_2 = key_2[0].split('001/');
          axios({
        url:"/image-compare/diffrence",
        method: "POST",
        data : {
           file_key_1 : file_key_1[1],
           file_key_2 : file_key_2[1]
        }
      })
        .then((resp) => {
         
          var dd = resp.data.success;
           console.log("diffe image url",dd);
           this.diffeimage = dd
          this.preloader = false;
          this.diffeimagedialog = true;

        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    lockScreens() {
      console.log("Lock Screen");

      var a = this.$refs.panaromascreen;

      console.log("Viewer", a);

      this.lock = !this.lock;
      this.hfov = 75;
      this.yaw = 0;
      this.pitch = 0;
      this.draggable = false;
      this.mouseZoom = false;
      this.doubleClickZoom = false;
      this.showZoom = false;
      this.showFullscreen = false;
      console.log("lock screen", this.lock);
      if(this.lock == true){
          this.draggable = false;
      }else{
          this.draggable = true;
      }
      a.showControls = false;

      this.imagepanaroma = this.slides[
        this.selected[this.selected.length - 2]
      ].mediapath;
      this.compatedatefirst = this.slides[
        this.selected[this.selected.length - 2]
      ].uploadat;

      //  console.log(this.selected.length);

      this.secondimagepanaroma = this.slides[
        this.selected[this.selected.length - 1]
      ].mediapath;
      this.compatedate = this.slides[
        this.selected[this.selected.length - 1]
      ].uploadat;
    },
     panUp(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() + 20);
             b.viewer.setPitch(b.viewer.getPitch() + 20);   
        },

        panDown(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setPitch(a.viewer.getPitch() - 20);
             b.viewer.setPitch(b.viewer.getPitch() - 20);   
        },
        panLeft(){
        var a = this.$refs.panaromascreen;
        var b = this.$refs.panaromascreen2;

            a.viewer.setYaw(a.viewer.getYaw() - 20);
            b.viewer.setYaw(b.viewer.getYaw() - 20);   
        },
        panRight(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setYaw(a.viewer.getYaw() + 20);
             b.viewer.setYaw(b.viewer.getYaw() + 20);   
        },
        panIn(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() - 20);
             b.viewer.setHfov(b.viewer.getHfov() - 20);   
        },
        panOut(){
            var a = this.$refs.panaromascreen;
            var b = this.$refs.panaromascreen2;

             a.viewer.setHfov(a.viewer.getHfov() + 20);
             b.viewer.setHfov(b.viewer.getHfov() + 20);   
        },
         nativagationImaegsThumnailList(camera_id) {
            this.preloader = true;
            //  console.log("lates images cam data",camdata);
            axios({
                    url: "/camera/" + camera_id + "/get_thumnail_images",
                    method: "GET",
                })
                .then((resp) => {
                  console.log("rresp",resp);
                  if (resp.data.success) {
                    var daywiseimageidsarray = resp.data.success;
                    var i = 0;
                    daywiseimageidsarray.forEach((item) => {
                        // console.log(item.image_name);
                        var date = item.image_name.split("_");

                        var d = date[2] + "-" + date[1] + "-" + date[0];
                        //  console.log(d,date);
                        daywiseimageidsarray[i].display_date = d;
                        i++;
                    });
                    this.daywiseimageidsarray = daywiseimageidsarray.reverse();
                    this.comrefresh++;
                    var v = this;
                      setTimeout(function () {
                        v.preloader = false;
                      }, 2000);
                  }else{
                    this.preloader = false;
                    this.snackbar = true;
                    this.text = "No Images Found For Comparison";
                  }
                    //  console.log("Lattest Image get_thumnail_images >>", this.daywiseimageidsarray);
                })
                .catch((err) => {
                    //  this.preloader = false;
                    console.log("eror", err);
                });
        },
  setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }
      //console.log(url);
    },
  getpdfreport() {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.preloader = true;
    var res = this.compareimage1.split("?");
    var res2 = this.compareimage2.split("?");
     console.log("responce Images", res);
     var cloud = '';
     var img1 = '';
     var img2 = '';
     if(this.currentProject.s3bucket == "default") {      
        // $bucket = 'aws.opticvyu.com.001';
        // $region = 'ap-southeast-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';
       

        img1 = res[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
    

      }else if(this.currentProject.s3bucket == "india") {         
        // $bucket = 'aws.opticvyu.com.india.001';
        // $region = 'ap-south-1';
        cloud = 'https://d3fpj3owe0806r.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001");

      }else if(this.currentProject.s3bucket == "usa") {         
        // $bucket = 'aws.opticvyu.com.usa.001';
        // $region = '';
        cloud = 'https://dsh9smw3t8bth.cloudfront.net';

        img1 = res[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001"); 
        img2 = res2[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001");

      }else if(this.currentProject.s3bucket == "europe ") {         
        // $bucket = 'aws.opticvyu.com.eu.001';
        // $region = 'eu-west-2';
        cloud = 'https://dkg8sn4ditt1q.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001"); 
        img2 = res2[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001");


      }else if(this.currentProject.s3bucket == "saudi") {         
        // $bucket = 'aws.opticvyu.com.ksa.001';
        // $region = 'us-east-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';

        // img1 = res[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001"); 
        // img2 = res2[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001");
           
         img1 = res[0];
         img2 = res2[0];
      }

      console.log(img2);
      var currentdate = new Date();
      var currentmonth = currentdate.getMonth() + 1;
      this.currentdt =
        currentdate.getDate() +
        "_" +
        currentmonth +
        "_" +
        currentdate.getFullYear();
    const formData = new URLSearchParams();
      formData.append(
        "filename",
        this.currentProject.project_name + "_" + this.currentdt
      );
      formData.append("orgname", this.currentProject.company);
      formData.append("projectname", this.currentProject.project_name);
      formData.append("reporttype", this.reporttype);
      formData.append("camid", this.current_cam.camera_id);
      formData.append("camera", this.current_cam.pro_cam_serial);
      formData.append("1", [img1,img2]);

      var filename = this.currentProject.project_name + "_" + this.currentdt;
      axios({
        crossOrigin: true,
        url: "https://www.opticvyu.com/api/get/instant-report",
        params: formData,
        method: "get"
        
      })
        .then((response) => {
          // let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.target = "_blank";
          link.href = response.data.success.reports[0];
          link.download = filename + ".pdf";
          link.click();
          this.preloader = false;
        })
        .catch((err) => {
          console.log("Report Error", err);
          this.preloader = false;
           this.snackbar = true;
          this.text = `Getting technical issue. Please try again later`;
        });
    },
    opensharebox() {
      this.sharebox = true;
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharepdfreport(); // action to login
      }
    },
    sharepdfreport() {
      //window.location.href="/dashboard"
     var res = this.compareimage1.split("?");
   
    var res2 = this.compareimage2.split("?");

    var cloud = '';
     var img1 = '';
     var img2 = '';
     if(this.currentProject.s3bucket == "default") {      
        // $bucket = 'aws.opticvyu.com.001';
        // $region = 'ap-southeast-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';
       

        img1 = res[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-southeast-1.amazonaws.com/aws.opticvyu.com.001");
    

      }else if(this.currentProject.s3bucket == "india") {         
        // $bucket = 'aws.opticvyu.com.india.001';
        // $region = 'ap-south-1';
        cloud = 'https://d3fpj3owe0806r.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001"); 
        img2 = res2[0].replace(cloud,"https://s3-ap-south-1.amazonaws.com/aws.opticvyu.com.india.001");

      }else if(this.currentProject.s3bucket == "usa") {         
        // $bucket = 'aws.opticvyu.com.usa.001';
        // $region = '';
        cloud = 'https://dsh9smw3t8bth.cloudfront.net';

        img1 = res[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001"); 
        img2 = res2[0].replace(cloud,"https://s3.amazonaws.com/aws.opticvyu.com.usa.001");

      }else if(this.currentProject.s3bucket == "europe ") {         
        // $bucket = 'aws.opticvyu.com.eu.001';
        // $region = 'eu-west-2';
        cloud = 'https://dkg8sn4ditt1q.cloudfront.net';

         img1 = res[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001"); 
        img2 = res2[0].replace(cloud,"https://s3-eu-west-2.amazonaws.com/aws.opticvyu.com.eu.001");


      }else if(this.currentProject.s3bucket == "saudi") {         
        // $bucket = 'aws.opticvyu.com.ksa.001';
        // $region = 'us-east-1';
        cloud = 'https://d1zsmm4yi50872.cloudfront.net';

        // img1 = res[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001"); 
        // img2 = res2[0].replace(cloud,"https://s3-us-east-1.amazonaws.com/aws.opticvyu.com.ksa.001");
       
         img1 = res[0];
         img2 = res2[0];
      }
   
      const formData = new URLSearchParams();
      formData.append("to", this.email);
      formData.append("subject", this.subject);
      formData.append(
        "filename",
        this.currentProject.project_name + "_" + this.currentdt
      );
      formData.append("orgname", this.currentProject.company);
      formData.append("projectname", this.currentProject.project_name);
      formData.append("reporttype", this.reporttype);
      formData.append("camid", this.current_cam.camera_id);
      formData.append("camera", this.camnumber);
      formData.append("1", [img1,img2]);
      

      //var camid = this.currentcam.camera_id;
      this.send = true;
      axios({
        crossOrigin: true,
        url: "https://www.opticvyu.com/api/get/instant-report",
        params: formData,
        method: "get",
      })
        .then((resp) => {
          console.log("share resp", resp);
          this.sharebox = false;
          this.send = false;
         
            this.snackbar = true;
            this.text =  this.lang.emailsuccessMsg;
          
        })
        .catch((err) => {
          console.log(err);
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },
  getimges(dd){
    this.preloader = true;
      console.log("get how many days:", dd);
      axios({
                    url: "/camera/" + this.current_cam.camera_id + "/get_images_comparsion",
                    method: 'get',
                    params: {
                            days: dd,
                        }
                })
                .then((resp) => {
                  if(resp.data.success){
                    if(resp.data.lat_url){
                        this.compareimage2 = resp.data.lat_url;
                    }else{
                      this.snackbar = true;
                      this.text = "No latest image available";
                    }
                    if(resp.data.diff_url){
                        this.compareimage1 = resp.data.diff_url;
                        this.compareimage11 = resp.data.diff_url;
                    }else{
                      this.compareimage1 = image1;
                      this.snackbar = true;
                      this.text = "No image available for "+dd+" days comparison";
                    }
                    
                    
                  }
                  var v = this;
                    setTimeout(() => { 
                      v.preloader = false;
                    }, 6000)   
                })
                .catch((err) => {
                    this.preloader = false;
                    console.log("eror", err);
                });
  },
  oncompare(event,index,prefix,imgname){
      
    if (event.target.checked) {
        console.log(index,prefix,imgname);
        console.log("check lenght", this.checkselected.length);
        
                

                    //   console.log(_this.selected);

                    // console.log(this.selected.length);
                    if (this.checkselected.length > 1) {
                        this.checkselected.shift();
                    }

                   if(this.checkselected.length == 0){
                     this.getSignUrlBykey(prefix, imgname, 1); 
                      this.snackbar = true;
                      this.text = "Select two images for comparison";
                    }
                    console.log("check fun",this.checkselected.length)
                    if(this.checkselected.length > 0){
                       this.getSignUrlBykey(prefix, imgname, 2);
                    }
                   /*  if (this.checkselected.length > 1) {
                      console.log("check slected", this.checkselected);
                          //this.compareimage1 = this.daywiseimageidsarray[this.checkselected[this.checkselected.length - 2]].url;
                          //this.compareimage1 = img1;
                            //this.compareimage2 = this.daywiseimageidsarray[this.checkselected[this.checkselected.length - 1]].url;
                           //this.compareimage2 = img2;
                          } */
                    
            }
  },
  getSignUrlBykey(prefix, name, num) {
            this.preloader = true;
            this.image = "";
            axios({
                    url: "/get_sign_url_by_key?key=" +
                        prefix +
                        "/" +
                        name +
                        ".jpg&exp=5minutes&project_id="+this.currentProject.id,
                    method: "GET",
                })
                .then((resp) => {
                    console.log("check return", resp.data.success.url);
                    if(num === 1){
                    this.compareimage1 = resp.data.success.url;
                    }
                    if(num === 2){
                      this.compareimage2 = resp.data.success.url;
                    }
                    var v = this;
                   setTimeout(() => { 
                      v.preloader = false;
                    }, 6000)  
                })
                .catch((err) => {
                    console.log(err);
                    this.preloader = false;
                });
        },
    resetcompare(){
      this.checkselected=[];
      this.compareimage1 = image1;
      this.compareimage2 = image2;
    },
    loaded() {
     // alert("hello");
        console.log("asdf");
            var v = this;
            //this.$refs.zoomer.zoomIn(1);
            setTimeout(function () {
                v.preloader = false;
            }, 2000);
    },
    loaded1() {
     // alert("hello1");
            var v = this;
            //this.$refs.zoomer1.zoomIn(1);
            setTimeout(function () {
                v.preloader = false;
            }, 2000);
    },
  },
};
</script>
<style>
.comarebox {
  display: block;
  width: 80%;
  margin: auto;
}
.zzoomerleft {
  position: absolute;
  left: 50%;
  /* width:50%; */
  display: inline-block;
}
.zzoomerright {
  position: absolute;
  right: 50%;
  /* width:50%; */
  display: inline-block;
}
.graybg {
  background: #565656;
}
.full360right {
  display: block;
  width: 50%;
  height: 80%;
  background: #000000ab;
  position: absolute;
  top: 8%;
  right: 50%;
  /* z-index: 1; */
}
.full360left {
  display: block;
  width: 50%;
  height: 80%;
  background: #000000ab;
  position: absolute;
  top: 8%;
  left: 50%;
  /* z-index: 1; */
}
.clayer {
  display: block;
  position: fixed;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100vh;
  background: #ccc;
  opacity: 0;
  z-index: 10;
}

#ccontrols {
  position: absolute;
  bottom: 20%;
  z-index: 20;
  text-align: center;
  width: 100%;
  padding-bottom: 3px;
}
.timeList {
    position: absolute;
    width: 110px;
    top: 89px;
    right: 0px;
    height: 340px;
    display: block;
    overflow: hidden;
}

.dateWithImageButton{
    position: absolute !important;
    top: 237px;
    left: 48px;
}
@media only screen and (max-width: 600px) {
  .dateWithImageButton {
    position: unset !important;
    top: unset;
    left: unset;
  }
}
.itemBottomMargin {
  margin-bottom: -9px !important;
}
</style>


<template>
  
  <div>
    <Preloader v-if="preloader" />
     
        <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card>
         
    <v-tabs
      v-model="tab"
      background-color="amber accent-3"
      fixed-tabs
      dark
      
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        {{lang.RecurringTimelapse}}
       </v-tab>

      <v-tab href="#tab-2" >
       {{lang.OneTimeTimelapse}}
        
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-1"
      >
        <v-card flat>
               <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
   
    @submit.prevent="submit"
  > 
       <v-card  class="pa-5" >
      <v-radio-group
              v-model="recurringtype"
              column
            >
              <v-radio
                :label="lang.Daily"
                color="amber accent-3"
                value="lastoneday"
              ></v-radio>
               <v-radio
                :label="lang.Weekly"
                color="amber accent-3"
                value="last7days"
              ></v-radio>
              
               <v-radio
                :label="lang.Monthly"
                color="amber accent-3"
                value="lastonemonth"
              ></v-radio>
               
            </v-radio-group>
        <v-row>
        <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time1"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        color="amber accent-3"
      >
        <template v-slot:activator="{ on, attrs }" color="amber accent-3">
          <v-text-field
            v-model="time1"
            :label="lang.StartTime "
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            
            v-on="on"
            format="24hr"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time1"
          full-width
          format="24hr"
          @click:minute="$refs.menu.save(time1)"
          color="amber accent-3"
        ></v-time-picker>
      </v-menu>
    </v-col>

    <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="menu3"
        v-model="menu3"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time2"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        color="amber accent-3"
      >
        <template v-slot:activator="{ on, attrs }" color="amber accent-3"> 
          <v-text-field
            v-model="time2"
            :label="lang.EndTime"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu3"
          v-model="time2"
          full-width
          color="amber accent-3"
          format="24hr"
          @click:minute="$refs.menu3.save(time2)"
        ></v-time-picker>
      </v-menu>
    </v-col>
   
    <v-col
      cols="11"
      sm="5"
    >
      <v-select
          :items="speed"
          v-model="fps"
          :label="lang.TimelapseSpeed"
          item-text="title"
                  item-value="speed"
        ></v-select>
    </v-col>

    <v-col
      cols="11"
      sm="5"
    >
      <v-select
          :items="quality"
          v-model="resolution"
          :label="lang.TimelapseQuality"
          item-text="title"
          item-value="quality"
        ></v-select>
    </v-col>

      </v-row>  
      <v-btn
        class="mr-4"
        type="submit"
       
        color="mylightbutton"
      >
        {{lang.submit}}
      </v-btn>
      <v-btn @click="clear"
       color="mydarkbutton"
      >
       {{ lang.Reset }}
      </v-btn>
       </v-card >
    </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="tab-2"
        
      >
        <v-card flat>
          <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>
          <v-form
    ref="onetimeform"
    @submit.prevent="submitontime"
  > 
       <v-card  class="pa-5" >
        <v-row>
        <v-col
        cols="11"
        sm="5"
        >
           <v-menu
        v-model="startDatemenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
        <template v-slot:activator="{ on, attrs }" color="amber accent-3">
          <v-text-field
            v-model="startdate"
            :label="lang.StartDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
           
          ></v-text-field>
        </template>
        <v-date-picker
          color="amber accent-3"
          v-model="startdate"
          @input="startDatemenu = false"
        ></v-date-picker>
      </v-menu>
        </v-col>

        <v-col
        cols="11"
        sm="5"
        >
           <v-menu
        v-model="enddatemenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        color="amber accent-3"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="enddate"
            :label="lang.EndDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          color="amber accent-3"
          v-model="enddate"
          @input="enddatemenu = false"
        ></v-date-picker>
      </v-menu>
        </v-col>

        <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="starttimemenu"
        v-model="starttimemenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="starttimetime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        color="amber accent-3"
      >
        <template v-slot:activator="{ on, attrs }" color="amber accent-3">
          <v-text-field
            v-model="starttimetime"
            label="Start time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            format="24hr"
          ></v-text-field>
        </template>
        <v-time-picker
         color="amber accent-3"
          v-if="starttimemenu"
          v-model="starttimetime"
          full-width
          format="24hr"
          @click:minute="$refs.starttimemenu.save(starttimetime)"
        ></v-time-picker>
      </v-menu>
    </v-col>

    <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="endtimemenu"
        v-model="endtimemenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="endtimetime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        color="amber accent-3"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endtimetime"
            :label="lang.EndTime"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="endtimemenu"
          v-model="endtimetime"
          full-width
          color="amber accent-3"
          format="24hr"
          @click:minute="$refs.endtimemenu.save(endtimetime)"
        ></v-time-picker>
      </v-menu>
    </v-col>
   
    <v-col
      cols="11"
      sm="5"
    >
      <v-select
          :items="speed"
          v-model="fps"
          :label="lang.TimelapseSpeed"
          item-text="title"
                  item-value="speed"
        ></v-select>
    </v-col>

    <v-col
      cols="11"
      sm="5"
    >
      <v-select
          :items="quality"
          v-model="resolution"
          :label="lang.TimelapseQuality"
          item-text="title"
          item-value="quality"
        ></v-select>
    </v-col>

      </v-row>  
      <v-btn
        class="mr-4"
        type="submit"
        
        color="mylightbutton"
      >
        {{lang.submit}}
      </v-btn>
      <v-btn @click="clear"
       color="mydarkbutton"
      >
        {{lang.Reset }}
      </v-btn>
       </v-card >
    </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
 </v-card>
    </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
    </v-row>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
      Sidebar
         
  },
  name: "Customiztimelpas",
  data() {
    return { 
        preloader: true,
     
        currentcam : {},
         tab: null,
         time1: null,
         time2: null,
         
         starttimetime: null,
         endtimetime: null,

         startdate : null,
         enddate : null,

        menu2: false,
        menu3: false,

         starttimemenu: false,
        endtimemenu: false,

        startDatemenu : false,

        enddatemenu :false,

        speed: [{speed:24, title: 'Fast'},{speed:15,title: 'Medium'},{speed:10,title: 'Slow'}],
        quality: [{quality:'1280x720', title: 'SD Video(720p)'},{speed:'1920x1080',title: 'HD Video(1080p)'}],
        recurringtype : '',
        currentProject : {},
        alert : false,
        fps : '',
        resolution : '',
         Local :locals,  
      lang : {}, 
      
  }
  },
    computed: {
    params() {
      return {
        type : this.recurringtype, 
        proj_id : this.currentProject.id,
        start_time : this.time1,
        end_time : this.time2,
        timelapse_fps : this.fps,
        ct_resolution : this.resolution
      }
    },

    onetimeparmas() {
      return {
        type : '', 
        proj_id : this.currentProject.id,
        start_date : this.startdate,
        end_date :  this.enddate,
        start_time : this.starttimetime,
        end_time : this.endtimetime,
        timelapse_fps : this.fps,
        ct_resolution : this.resolution
      }
    }
  }, 
  mounted() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];

     this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.initViewer();
      
     
  },
  watch: {
  '$store.state.currentCamera': function() {
     //this.preloader = true
    // console.log("Current data get",this.$store.state.currentCamera);
     this.currentcam = this.$store.state.currentCamera;

      this.gettimeplaseSettings(); 
  }
  }, 
  methods: {
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    
    },
      async submit() {
      //const valid = await this.$refs.form.validate()
         
        this.addrecuring(this.params) // action to login
      
    },
     async submitontime() {
      //const valid = await this.$refs.form.validate()
         
        this.addOnetime(this.onetimeparmas) // action to login
      
    },
    addrecuring(timelapsdata) {
        
        // console.log(timelapsdata)

        axios({ url: '/camera/'+this.currentcam.camera_id+'/timelapse/custom', data: timelapsdata, method: 'POST' })
          .then(resp => {
                    console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = this.lang.recurringsettingsave;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
              this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      },

      addOnetime(timelapsdata) {
        
         //console.log(timelapsdata)

        axios({ url: '/camera/'+this.currentcam.camera_id+'/timelapse/custom', data: timelapsdata, method: 'POST' })
          .then(resp => {
                    console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = this.lang.onetimesetting;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                     this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      },

      gettimeplaseSettings() {
        
        axios({ url: '/camera/'+this.currentcam.camera_id+'/timelapse/custom', method: 'GET' })
          .then(resp => {
                   console.log("Resp", resp);
                   
                   if(resp.data.success){
                var res =  resp.data.success;
               this.recurringtype = res.type;
             
              this.startdate = res.start_date;
              this.enddate =res.end_date;
              this.starttimetime = res.start_time;
              this.endtimetime = res.end_time;
              this.fps = res.timelapse_fps;
              this.resolution = res.ct_resolution;

               this.time1 =  res.start_time;
               this.time2 =  res.end_time;
                   }
                 
       })
          .catch(err => {
            console.log(err);
             this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      },
      clear(){
            axios({ url: '/camera/'+this.currentcam.camera_id+'/reset_timelapse_setting', method: 'GET' })
          .then(resp => {
              if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success";
                    this.startdate = '';
              this.enddate = '';
              this.starttimetime = '';
              this.endtimetime = '';
              this.fps = '';
              this.resolution = '';

               this.time1 = '';
               this.time2 = '';

                 }else{

                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                 }
          })
      }
  }
}
</script>
<style>
 
</style>


<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12">
          <v-col cols="12" md="2" class="datepicker">
              <!-- <ejs-daterangepicker
                :change="chartValueChange"
                :placeholder="lang.selectRange"
                minDays="1"
                maxDays="31"
              >
              </ejs-daterangepicker> -->

              <date-range-picker
          @update="chartValueChange"
          :ranges = "false"
          opens="right"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      v-model="chartdate"
      :date-format="dateFormat"
  ></date-range-picker>
            </v-col>
          </v-col>   
       <v-col cols="12" md="6">
        <apexchart type="bar" height="480"  v-if="showcharts" :options="barchartOptions" :series="barseries"></apexchart>
        <v-col cols="12" md="12">   
        <p> <v-icon>mdi-information-outline</v-icon> Multiple daily accesses per user count as one entry. </p>
        <p> <v-icon>mdi-information-outline</v-icon> Bar chart unavailable on no-access dates. </p>
       </v-col>
      </v-col>
        <v-col cols="12" md="6">
          <apexchart type="donut" height="480" v-if="showcharts" :options="chartOptions" :series="series"></apexchart>
    
        </v-col>
      <v-col cols="12" md="12">
        <v-card class="vhfix">
          <v-card-title>
            {{ lang.UserLogs }}
          </v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="lang.Search" 
              single-line
              hide-details
            ></v-text-field>
            <v-col class="datepicker"  cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <!-- <ejs-daterangepicker
                :change="onValueChange"
                :minDays="min"
                :maxDays="max"
                :strictMode="mode"
                :placeholder="lang.selectRange"
              >
              </ejs-daterangepicker> -->
              <date-range-picker
          @update="onValueChange"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      v-model="chartdatevalue"
      :date-format="dateFormat"
  ></date-range-picker>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:item.user_name="{ item }">
              <a href="#" class="llikn" @click="logs(item)">{{
                item.user_name
              }}</a>
            </template>

            <template v-slot:item.created_at="{ item }">
              <!-- {{ gmttoisd(item.created_at) }} -->
              {{ item.created_at }}
            </template>

            <template v-slot:item.user_type="{ item }">
              <v-chip :color="getColor(item.user_type)" dark>
                {{
                  item.user_type == 1
                    ? lang.User
                    : item.user_type == 0
                    ? lang.Admin
                    : lang.SuperAdmin
                }}
              </v-chip>
            </template>
          </v-data-table>

          <v-btn @click="getUserLogs('')" v-if="desserts.length == 0">
            {{ lang.Reset }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="logstable" persistent max-width="1280">
      <v-btn class="closerlogs" @click="logstable = false">
        <v-icon>mdi-window-close</v-icon></v-btn
      >
      <v-card class="myeditform">
        <v-card-title>
          <h3 class="uurlogin">{{ logitem.user_name }}</h3>
          <v-text-field
            v-model="logssearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="tolp"
          ></v-text-field>
          <v-col  class="datepicker" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
            <!-- <ejs-daterangepicker
              :change="onValueChangesingle"
              :minDays="min"
              :maxDays="max"
              :strictMode="mode"
              :placeholder="lang.selectRange"
            >
            </ejs-daterangepicker> -->

            <date-range-picker
          @update="onValueChangesingle"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      v-model="chartdatesingle"
      :date-format="dateFormat"
  ></date-range-picker>

          </v-col>
        </v-card-title>
        <v-data-table
          :headers="logsheaders"
          :items="logsdesserts"
          class="elevation-1"
          :search="logssearch"
        >
          <template v-slot:item.created_at="{ item }">
            <!-- {{ gmttoisd(item.created_at) }} -->
            {{ item.created_at }}
          </template>
        </v-data-table>

        <v-btn @click="getsingleUserLogs('')" v-if="logsdesserts.length == 0">
          {{ lang.Reset }}
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
//import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
import VueApexCharts from 'vue-apexcharts';

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DateRangePicker
  },
  name: "UserLogs",
  data() {
    return {
      preloader: false,
      items: {},
      search: "",

      headers: [
        {
          text: "User Name",
          align: "start",
          value: "user_name"
        },
        { text: "Module", value: "project_type" },
        { text: "Page", value: "visit_page" },
        { text: "Date", value: "created_at" },
        { text: "User Type ", value: "user_type" }
      ],
      current_cam: "",
      currentProject: "",
      desserts: [],
      max: "8",
      min: "1",
      waterMark: "Select a Range",
      currentcam: "",
      logstable: false,

      logssearch: "",

      logsheaders: [
        {
          text: "Project",
          align: "start",
          value: "project_name"
        },
        { text: "Module", value: "project_type" },
        { text: "Page", value: "visit_page" },
        { text: "Date", value: "created_at" }
      ],
      logsdesserts: [],
      logitem: {},
      Local: locals,
      lang: {},
      series: [],
          chartOptions: {
            chart: {
              width: 380,
              type: 'donut',
            },
            labels: [],
            colors : ["#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" , "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9" ,"#a4e43f" ,"#d298e2" ,"#6119d0", "#d2737d" ,"#c0a43c" ,"#f2510e" ,"#651be6" ,"#79806e" ,"#61da5e" ,"#cd2f00" , "#9348af" ,"#01ac53" ,"#c5a4fb" ,"#996635","#b11573" ,"#4bb473" ,"#75d89e" , "#2f3f94" ,"#2f7b99" ,"#da967d" ,"#34891f" ,"#b0d87b" ,"#ca4751" ,"#7e50a8" , "#c4d647" ,"#e0eeb8" ,"#11dec1" ,"#289812" ,"#566ca0" ,"#ffdbe1" ,"#2f1179" , "#935b6d" ,"#916988" ,"#513d98" ,"#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d", "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977", "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b", "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf", "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234", "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158", "#fb21a3", "#51aed9", "#5bb32d", "#807fb"],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          showcharts: false,
      
          barseries: [{
            name: "Visit",
            data: []
          }],
          barchartOptions: {
            chart: {
              type: 'bar',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: '50%',
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
          },
          chartdate: {}, 
          chartdatesingle: {},
          chartdatevalue : {}
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    // this.getUserLogs();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    if (this.userdata.user_code == 2) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
  },
  watch: {
    "$store.state.currentCamera": function() {
      this.currentcam = this.$store.state.currentCamera;
      console.log("Current data get");
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
       this.CurrentDateFunction();
      this.getUserLogs();
      this.getUserLogschart();
    }
  },
  created() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.currentcam = this.$store.state.currentCamera;
    this.CurrentDateFunction();
    this.getUserLogs();
    this.getUserLogschart();
  },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
     
      CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 7);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

           this.chartdatesingle = {
            startDate : start,
            endDate : end
           }
            this.chartdate = {  
              startDate : start,
               endDate : end
            }

             this.chartdatevalue = {
                startDate : start,
               endDate : end
             }

      },
    onValueChange: function(args) {
      if (args.startDate) {
        this.getUserLogs(args);
      }
    },
    chartValueChange: function(args) {
      if (args.startDate) {
        this.getUserLogschart(args);
      }
    },
    getColor(calories) {
      if (calories == 0) return "#ec665cfc";
      else if (calories == 1) return "#8bc34ac2";
      else return "#ff9800";
    },
    gmttoisd(date) {
      var dateUTC = new Date(date);
      var dateUTCdd = dateUTC.getTime();
      var dateIST = new Date(dateUTCdd);
      //date shifting for IST timezone (+5 hours and 30 minutes)
      dateIST.setHours(dateIST.getHours() + 5);
      dateIST.setMinutes(dateIST.getMinutes() + 30);

      var result = new Date(dateIST).toLocaleString(undefined, {
        timeZone: "Asia/Kolkata"
      });

      return result;
    },
    getUserLogs: function(args) {
      this.CurrentDateFunction();
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.preloader = true;
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      console.log("user Logs Dataaa- ");
      this.current_cam = this.$store.state.currentCamera;
      var data = {};
      if (args) {
        // console.log("args", args);

        // var datas = args.text.split(" - ");

        // //   $start = '2021-09-01';

        // var s = datas[0].split("/");

        // var e = datas[1].split("/");

        // var start = s[2] + "-" + s[0] + "-" + s[1];

        // var end = e[2] + "-" + e[0] + "-" + e[1];

        // console.log(start);
        // console.log(end);


        var checkstart = new Date(this.chartdatevalue.startDate);
       var checkend = new Date(this.chartdatevalue.endDate);


       var start = new Date(this.chartdatevalue.startDate).toISOString().slice(0, 10);
        var end = new Date(this.chartdatevalue.endDate).toISOString().slice(0, 10);

        let Difference_In_Time =  checkend.getTime() - checkstart.getTime();

// Calculating the no. of days between
// two dates
let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

   if(Difference_In_Days > 7){
    

        var today = new Date(this.chartdatevalue.startDate);
          today.setDate(today.getDate() + 7);

          end = new Date(today).toISOString().slice(0, 10);

          this.chartdate.endDate = end;

          alert("Only 7 Days data between("+ start +" to  "+end+")  will show's on chart" );
               
   }



        data = {
          cam_id: this.current_cam.camera_id,
          start: start,
          end: end,
          project_type: "Interior Monitoring",
          opticvyu_user: this.userdata.opticvyu_user
        };
      } else {
        data = {
          cam_id: this.current_cam.camera_id,
          project_type: "Interior Monitoring",
          opticvyu_user: this.userdata.opticvyu_user
        };
      }

      axios({
        url: `/user-logs/${this.currentProject.id}`,
        method: "GET",
        params: data
      })
        .then(resp => {
          console.log("User Logs", resp.data.success);
          this.desserts = resp.data.success;
          this.preloader = false;
        })
        .catch(err => {
          console.log("user Logs error", err);
          this.preloader = false;
        });
    },
    logs(item) {
      this.logitem = item;

      this.getsingleUserLogs("");

      console.log("User Logs", item);
    },

    onValueChangesingle: function(args) {
      if (args.startDate) {
        this.getsingleUserLogs(args);
      }
    },

    getsingleUserLogs: function(args) {
      this.preloader = true;
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      console.log("user Logs Dataaa- ");
      this.current_cam = this.$store.state.currentCamera;
      var data = {};
      if (args) {

       var start = new Date(this.chartdatevalue.startDate).toISOString().slice(0, 10);
       var end = new Date(this.chartdatevalue.endDate).toISOString().slice(0, 10);

        console.log(start);
        console.log(end);
        data = {
          userid: this.logitem.user_id,
          start: start,
          end: end,
          opticvyu_user: this.userdata.opticvyu_user
        };
      } else {
        data = {
          userid: this.logitem.user_id,
          opticvyu_user: this.userdata.opticvyu_user
        };
      }

      axios({ url: `/single-user-logs`, method: "GET", params: data })
        .then(resp => {
          console.log("User Logs", resp);
          this.preloader = false;
          this.logstable = true;
          this.logsdesserts = resp.data.success;
        })
        .catch(err => {
          console.log("user Logs error", err);
          this.preloader = false;
        });
    },
    getUserLogschart: function(args) {

this.showcharts = false;
this.userdata = JSON.parse(localStorage.getItem("userdata"));

this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
console.log("user Logs Dataaa- ");
this.current_cam = this.$store.state.currentCamera;
var data = {};
if (args) {
  console.log("args", args);

      var  startd = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
      var  endd = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

  var start = startd+" 00:00:00";

  var end = endd+" 23:59:59";

  console.log(start);
  console.log(end);
  data = {
    cam_id: this.current_cam.camera_id,
    start: start,
    end: end,
    project_type: "Interior Monitoring",
    opticvyu_user: this.userdata.opticvyu_user
  };
} else {
  data = {
    cam_id: this.current_cam.camera_id,
    project_type: "Interior Monitoring",
    opticvyu_user: this.userdata.opticvyu_user
  };
}
   var series_arr = [];
   var label_arr = [];

   var bar_series_arr = [];
   var bar_label_arr = [];
axios({
  url: `/user-logs-for-chart/${this.currentProject.id}`,
  method: "GET",
  params: data
})
  .then(resp => {
    console.log("User Logs chart data", resp);
   // this.desserts = resp.data.success;

       resp.data.pai.forEach(i => {
        series_arr.push(i.page_count);
        label_arr.push(i.visit_page);
      });
      resp.data.bar.forEach(i => {
        bar_series_arr.push(i.user_count);
        bar_label_arr.push(i.day);
      });
      
      this.series = series_arr;
      this.chartOptions.labels = label_arr;

      this.barseries[0].data = bar_series_arr;
      this.barchartOptions.xaxis.categories = bar_label_arr;
     
      this.showcharts = true;

    
  })
  .catch(err => {
    console.log("user Logs error", err);
    this.preloader = false;
  }); 
   }
  }
};
</script>
<style>
.vhfix {
  max-height: 91vh;
  overflow: auto;
}
.myeditform .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.myeditform .v-card__text {
  padding-bottom: 0 !important;
}
table a.llikn {
  color: #1976d2 !important;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 1px;
}
.datepicker {
    font-size: 12px;
}
</style>

<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
        Update Project Status
       </v-card-title>
 
        <div class="projectHistory"  v-if="currentProjectStatus == 'Completed'">
             <p>Start Date:  {{currentProject.interior_start}}</p>

             <p>End Date:  {{currentProject.interior_end}}</p>

             <p> Project Duration {{daydiff()}} Days.</p>
             
        </div>

        <div class="projectHistory"  v-if="currentProjectStatus == 'Running'">
             <p>Start Date:  {{currentProject.interior_start}}</p>

             <p> Project Duration:  {{daydiff()}} Days.</p>
             
        </div>

         <v-snackbar v-model="snackbar" centered top timeout="3000" type="success">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              {{lang.Ok}}
            </v-btn>
          </template>
        </v-snackbar>  
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

       <p class="pa-5" v-if="currentProjectStatus == 'not started'"> 
       
       <strong> The project is not yet started. Do you want to start the project interior 360° monitoring? </strong>
       <br/>
       <br/>
       <strong>  Notes: </strong>
        <br/>
       <span class="note">1. The billing will start once the project is marked as started. </span>
      <br/>
       <span class="note">2. Once started, you'll get the editor access to upload the drawing & create strategic points.</span>
      </p>


      <p class="pa-5" v-if="currentProjectStatus == 'Running'"> 
       
       <strong>Do you want to stop the project's interior 360° monitoring?</strong>
       <br/>
       <br/>
        <strong>  Notes: </strong>
        <br/>
       <span class="note"> 1. The billing stops once the project is marked as completed. </span>
        <br/>
       <span class="note"> 2. Once completed, the editor access will be removed. </span>
        <br/>
        <span class="note">3. You'll not be able to start the project once marked as completed. </span>
      </p>
 <div class="pa-5">
       <v-btn
       x-large
        class="mr-4"
        color="mylightbutton"
         :disabled="currentProjectStatus == 'Completed'"
        @click="projectStatusUpdate"
         >
        {{title}}
      </v-btn>
         
        <p v-if="currentProjectStatus == 'Completed'"> <br/> Your project is complete. Please contact <a href="mailto:admin@opticvyu.com">admin@opticvyu.com</a>  if you wish to resume the project again.</p>
      </div>
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
 import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "InteriorProjectStatus",
  data() {
    return { 
            valid:true,
            checkboxactive : false,
            preloader: false,
            alert: false,
            alertmessage: '',
            snackbar: false,
            text: "Something went wrong, please try again.", 
            Local :locals,  
            lang : {}, 
            title : 'Start the project',
            title1 : 'Mark Project Completion',
            title2 : 'Project Completed',
            currentProject :{},
            useraccess : false,
            interior_status : 'Running',
            currentProjectStatus :''
     };
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
     this.userdata = JSON.parse(localStorage.getItem('userdata'))

      this.currentProjectStatus = this.currentProject.interior_status;
     
      if(this.userdata.user_code == 2){
          this.useraccess = true 
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
       if(this.currentProject.interior_status == 'not started'){
         this.title  =  'START 360° MONITORING FOR THIS PROJECT';
       } else if(this.currentProject.interior_status == 'Running'){
          this.title  =  'MARK PROJECT COMPLETION & STOP BILLING';
       }
       else if(this.currentProject.interior_status == 'Completed'){
          this.title  =  'Project Completed';
       }
  },
  methods: {
       projectStatusUpdate(){
         
        this.currentProjectStatus = this.currentProject.interior_status;
        var msg = "";
         if(this.currentProjectStatus == 'not started'){

              this.interior_status = 'Running';
              msg = "Do you want to begin the project's 360-degree monitoring? If so, make sure that you have thoroughly read the notes before beginning project monitoring."

         }else if(this.currentProjectStatus == 'Running'){

              this.interior_status = 'Completed';
               msg = "Do you want to stop the project's 360-degree monitoring? If so, make sure that you have thoroughly read the notes before stopping project monitoring."

         }

        var ss =  confirm(msg);

        if(ss){
             this.preloader = true;
         axios({
                    url: '/interior-projects-status-update/'+ this.currentProject.id,
                    method: 'post',
                    data: {
                        interior_status: this.interior_status
                    }
                })
                .then(resp => {
                  console.log("asjkldfklf",resp);
                    
                this.alert = true;
                this.alertmessage = "Project status updated!";
                this.getCurrentProject()
         }).catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
         })
        }
      },
       getCurrentProject(){
       
        let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      
        var projid = currentProject.id;

       axios({
                    url: "/projec/"+projid,
                    method: "get",
                })
                .then((resp) => {
                    console.log("project update date", resp.data);
                   // this.initialSetup(resp.data);
                   localStorage.setItem("currentProject", JSON.stringify(resp.data));
                this.preloader =  false;
                    setTimeout(() => {
                        location.reload(true);
                    }, 2000); 
                     
                     
                })
                .catch((err) => {
                    console.log("project update date",err);
                  
                });
    },

     daydiff(){
                    const date1 = new Date(this.currentProject.interior_start);
                    const date2 = new Date();

                    const diffTime = Math.abs(date2 - date1);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    return diffDays;
     }

  }
};
</script>
<style>
.projectHistory{
    position: absolute;
    right: 10px;
    font-weight: 500;
}
</style>


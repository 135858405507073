<template>
  <div>
   <!--  <Preloader v-if="preloader" /> -->
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="0" @click="setrouter('/dashboard/reports/instant-report')">
          {{lang.InstantReport}}
        </v-tab>
        <v-tab key="1" @click="setrouter('/dashboard/reports/schedule-report')">
          {{lang.ScheduleReport}}
        </v-tab>
        <v-tab key="2" @click="setrouter('/dashboard/reports/report-demo')">
         {{lang.ReportDemo}}
        </v-tab>
      </v-tabs>
      <v-card flat>
            
            <div class="videostyle">
              <video
                class="videostyle"
                controls="true"
               >
               <source src="https://opticvyu-temp.s3.amazonaws.com/Gallery/OpticVyu+Repots+Tool.mp4" type="video/mp4">
               </video>
             </div>
          </v-card>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
//import Preloader from "../../../components/PreLoader";
import { locals } from "../../utils/locals";
export default {
  components: {
 // Preloader,
  },
  name: "schedule_report",
  data() {
    return {
      //preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      tab: 2,
       Local :locals,  
        lang : {},
    };
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 
    this.getPorjects();
    this.initViewer();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //console.log("Current data get", this.$store.state.currentCamera);
      this.camers = [];
      this.current_cam = this.$store.state.currentCamera;

      //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
      // this.img_interval  = this.current_cam.image_interval;
    },
  },
  computed: {
    params() {
      return {};
    },
    param_add() {
      return {};
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      //this.preloader = false
    },
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });

          this.getCamerasbyProject(this.items[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
  },
};
</script>
<style>
.videostyle {
  margin: auto;
  display: block;
  width: 100%;
}
video {
  max-width: 100%;
  height: 85.6vh;
}
</style>

<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                <template>
                  
                    
                    
                       <h3 class="mySearchInventoryddd"> {{ topname }} </h3>
                        <v-btn
      class="ma-2"
     color="mylightbutton"
     @click="goback"
    >
      {{lang.goback}}
       <v-icon right>mdi-undo-variant</v-icon>
        
    </v-btn>
                       <v-btn color="#f8bb03" class="rightbutons" @click="addnewItem"> {{lang.AddNew}} </v-btn>
                     
                    
                    <v-data-table :headers="headers" :search="search" :items="taskdata" :items-per-page="100" :footer-props="footerpro" class="elevation-1 tavbtable">

                        <template v-slot:item.actions="{ item }">

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" class="mr-2 ">
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>
                                    <h5>{{lang.Remark}} </h5>
                                    <p>{{item.remark }}</p>
                                    <h5>{{lang.Discription}} </h5>
                                    <p>{{item.desc}}</p>
                                </span>
                            </v-tooltip>


                                <v-btn
                               
                                fab
                                dark
                                icon
                                small
                                color="black"
                                @click="updateInventory(item)"
                                >
                                <v-icon dark>
                                mdi-pencil-box-multiple
                                </v-icon>
                                </v-btn>

                                <v-btn
                                
                                fab
                                dark
                                icon
                                small
                                color="black"
                                @click="removeInventory(item.id)"
                                >
                                <v-icon dark>
                                mdi-delete
                                </v-icon>
                                </v-btn>

                        </template>
                        <template v-slot:item.create_at="{ item }">
                            <p>{{ dateFormate(item.create_at) }}</p>
                        </template>

                    </v-data-table>

                </template>

            </v-card>
        </v-col>
    </v-row>
    
   <div class="footer">
	 <a class="submmitinventory"  v-if="!submit_inventorys_status" @click="submit_inventorys" id="submiadd"> {{lang.submit}}  </a> 
	 <a class="submmitinventory" v-if="submit_inventorys_status" @click="goback"> {{lang.GoToInventory}}  </a>
	</div>

    <v-dialog transition="dialog-bottom-transition" max-width="800" color="amber accent-3" v-model="updateInvetory">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.UpdateInventory}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewsTask">

                    <v-container>
                        <v-row>
                            <v-col cols="4" sm="4">
                                <v-select required :rules="requerdRules" :items="headings" ref="material_heading" v-model="updateData.material_heading_id" :label="lang.MaterialHeading" item-value='id' item-text='material_heading' @change="getMaterialSubHeading($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required :rules="requerdRules" :items="subheadings" v-model="updateData.sub_heading_id" :label="lang.MaterialSubHeading" item-value='id' item-text='subheading' ref="subheading" @change="getMaterialName($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required  :rules="requerdRules" :items="materialnames" v-model="updateData.material_name_id" :label="MaterialName" item-value='id' item-text='materila_name' ref="materila_name" @change="selectMaterial($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules" v-model="updateData.unit" :label="lang.Unit" readonly></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="4">
                                <v-text-field  :rules="requerdRules" v-model="updateData.dc_no" :label="lang.DCNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules" v-model="updateData.purchase_order_number" :label="lang.PONo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules" v-model="updateData.vehicle_no" :label="lang.VehicleNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                               <v-select required :rules="requerdRules" :items="suppliers" v-model="updateData.supplier_name" :label="lang.SupplierName" item-value="supplier_name" item-text="supplier_name"></v-select>
                             </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="updateData.desc" label="Description" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules"  type="number"  v-model="updateData.rate" :label="lang.Rate" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field :rules="requerdRules"  type="number"  v-model="updateData.quantity" :label="lang.Quantity" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="updateData.remark" :label="lang.Remark"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    Update <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="updateInvetory = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="800" color="amber accent-3" v-model="addInvetory">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.NewInventory}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewsInventory">

                    <v-container>
                        <v-row>
                            <v-col cols="4" sm="4">
                                <v-select required :items="headings"  :rules="requerdRules" ref="material_heading" v-model="newData.material_heading_id" :label="lang.MaterialHeading" item-value='id' item-text='material_heading' @change="addgetMaterialSubHeading($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required :items="subheadings" :rules="requerdRules"  v-model="newData.sub_heading_id" :label="lang.MaterialSubHeading" item-value='id' item-text='subheading' ref="subheading" @change="addgetMaterialName($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-select required :items="materialnames" :rules="requerdRules" v-model="newData.material_name_id" :label="lang.MaterialName" item-value='id' item-text='materila_name' ref="materila_name" @change="addselectMaterial($event)" persistent-hint return-object single-line></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.unit" :rules="requerdRules" :label="lang.Unit" required readonly></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.dc_no"  :rules="requerdRules" :label="lang.DCNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.purchase_order_number" :rules="requerdRules" :label="lang.PONo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.vehicle_no" :rules="requerdRules" :label="lang.VehicleNo" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                               <v-select required :rules="requerdRules" :items="suppliers" v-model="newData.supplier_name" :label="lang.SupplierName" item-value="supplier_name" item-text="supplier_name"></v-select>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.desc" label="Description" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.rate" :rules="requerdRules"  type="number" :label="lang.Rate" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.quantity" :rules="requerdRules"  type="number"  :label="lang.Quantity" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4">
                                <v-text-field v-model="newData.remark" :label="lang.Remark"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                 Submit <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="addInvetory = false" color="mydarkbutton">
                                    {{lang.Cancel}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

    

    <v-snackbar v-model="alert" top right timeout="3000" :type="alertType">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import { locals } from "../../utils/locals";
export default {
    components: {
        Preloader
    },

    computed: {
       
    },
    name: "EditTask",
    data() {
        return {
            requerdRules: [
            v => !!v || this.lang.required,
            ],
             numberOnlyRules: [
                v => !!v || this.lang.required,
                v => /^[0-9]*[1-9][0-9]*$/.test(v) || this.lang.integetrequre,
            ],
            alert: false,
            updateInvetory: false,
            alertmessage: '',
            alertType: "error",
            preloader: false,
            project_name: 'ProjectName',
            projectid: 0,
            search: '',
            taskdata: [],
          
            userdata: {},
            headers: [{
                    text: 'Material Name ',
                    align: 'start',
                    value: 'material_name',
                    width: 170,
                },
                {
                    text: 'Material Heading',
                    value: 'material_heading',
                    width: 170,
                },
                {
                    text: 'Sub Heading',
                    value: 'sub_heading',
                    width: 150,
                },
                {
                    text: 'DC No/Weight Slip',
                    value: 'dc_no',
                    width: 180,
                },
                {
                    text: 'PO No ',
                    value: 'purchase_order_number',
                    width: 150,
                },
                {
                    text: 'Unit',
                    value: 'unit',
                    width: 150,
                },
                {
                    text: 'Quantity',
                    value: 'quantity',
                    width: 150,
                },
                {
                    text: 'Rate',
                    value: 'rate',
                    width: 150,
                },
                {
                    text: 'Asset Type',
                    value: 'asset_type',
                    width: 150,
                },
                {
                    text: 'Supplier Name',
                    value: 'supplier_name',
                    width: 170,
                },
                {
                    text: 'Vehicle No.',
                    value: 'vehicle_no',
                    width: 150,
                },
                {
                    text: 'User',
                    value: 'UserName',
                    width: 150,
                },
                {
                    text: 'Contact No',
                    value: 'contactNo',
                    width: 150,
                },
                {
                    text: 'Date',
                    value: 'create_at',
                    width: 200,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    width: 250,
                }
            ],
                cumulative_json_fields : {
                    'Material Name' : 'material_name',
                    'Material Heading': 'material_heading',
                    'Sub Heading' : 'sub_heading',
                    Unit:'unit',
                    Quantity: 'quantity',
                    Rate:'rate',
                    'Asset Type' :'asset_type',
                    
                   },
            
            
            footerpro: {
                itemsPerPageOptions: [10, 50, 100, -1],
            },
            
            headings: [],
            subheadings: [],
            materialnames: [],
            send: false,
            valid: false,
            updateData: [],
            newData : {
            username: "",
            asset_type: "",
            contactNo: "",
            dc_no: "",
            desc: "",
            entry_id: "",
            material_heading: "",
            material_heading_id: "",
            material_name: "",
            material_name_id: "",
            projectid: "",
            purchase_order_number: "",
            quantity: "",
            rate: "",
            remark: "",
            sub_heading: "",
            sub_heading_id: "",
            supplier_name: "",
            unit: "",
            vehicle_no: ""
            },
            addInvetory : false,
            submit_inventorys_status :false,
            topname: '',
            suppliers : {},
            Local :locals,  
            lang : {},
        };
    },
    created() {

        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        this.userdata = JSON.parse(localStorage.getItem('userdata'));

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;
        this.project_name = currentProject.project_name;
        this.initViewer();
        if(currentProject.material == 1){
          this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
        if (this.userdata.material_access == 1) {
            this.user_material_access = true
        }else{
                 this.$router.push('/')
        }
        //  if(this.userdata.free_access == 0){
        //   this.useraccess = true
        // }else{
        //   this.$router.push('/')
        // }
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
            this.project_name = currentProject.project_name;
            this.getTaskData();
             this.getSupplier();
        }
    },
    // mounted() {
    //     this.userdata = JSON.parse(localStorage.getItem('userdata'));
    //     if (this.userdata.open_access == 1) {
    //         this.$router.push('/dashboard')
    //     }
    // },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
             

            // console.log("init Viewer");
            this.preloader = true;
            this.getTaskData();
            this.getSupplier();
        },

        getTaskData() {
            this.taskdata = [];
            this.preloader = true;
            // var projectid = '372';
             var entery_id = this.$route.params.id;
            var filter = '{"where": { "entry_id": "'+entery_id+'"},"order": "id DESC"}' ;
           var urlfilter = encodeURI(filter);
            axios({
                    url: 'material/get_enventory',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = resp.data;
                       
                    var a = 1;  
                     resp.data.forEach(val => {
                          if(a == 1){
                            this.newData.dc_no = val.dc_no;
                            this.newData.vehicle_no = val.vehicle_no;
                            this.newData.supplier_name =  val.supplier_name;
                            this.newData.entry_id = val.entry_id;
                            this.newData.projectid = this.projectid;
                            this.topname = val.material_name+" - "+val.supplier_name
                          }
                       a++
                     });
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        dateFormate(d) {

            var n = new Date(d).toLocaleString('en-GB', {
                timeZone: 'Asia/Kolkata'
            });

            return n;
        },

          updateInventory(item) {
            this.getMaterialHeading();
            console.log(item);
            this.updateData = item;
            this.updateInvetory = true;

            console.log(this.updateData);

        },
        getMaterialHeading() {

            var projectid = this.projectid;
            var mhfilter = '{"where": {"projectid":' + projectid + '}}';
            var urlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/heading',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.headings = JSON.parse(resp.data.success);
                    this.getMaterialSubHeading();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        getMaterialSubHeading() {

            // var optionText = event.target.options[event.target.options.selectedIndex].text;

            //console.log(optionText);
            console.log(this.updateData.material_heading_id);
            var id;
            if (this.updateData.material_heading_id.id) {
                id = this.updateData.material_heading_id.id;
                this.updateData.material_heading = this.updateData.material_heading_id.material_heading;

            } else {
                id = this.updateData.material_heading_id;
            }

            var mhfilter = '{"where": { "material_heading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/subheading',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.subheadings = JSON.parse(resp.data.success);
                    this.getMaterialName()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        getMaterialName() {
            //   var id = this.updateData.sub_heading_id;

            console.log(this.updateData.sub_heading_id);
            var id;
            if (this.updateData.sub_heading_id.id) {
                id = this.updateData.sub_heading_id.id;
                this.updateData.sub_heading = this.updateData.sub_heading_id.subheading;

            } else {
                id = this.updateData.sub_heading_id;
            }

            var mhfilter = '{"where": { "subheading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/materials',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    console.log("materials", resp);
                    this.materialnames = JSON.parse(resp.data.success);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        selectMaterial() {
            //var id = this.updateData.material_name_id;

            var id;
            if (this.updateData.material_name_id.id) {
                id = this.updateData.material_name_id.id;
                this.updateData.material_name = this.updateData.material_name_id.materila_name;

            } else {
                id = this.updateData.material_name_id;
            }
            console.log("materialid", id)
            axios({
                    url: 'material/unitmaterial',
                    method: 'get',
                    params: {
                        materialid: id
                    },
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.updateData.unit = resp.data.unit;
                    this.updateData.asset_type = resp.data.asset_type;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        submitNewsTask() {
            this.send = true;
            var data = this.updateData;

            if (data.editreq == 1) {
                data.editreq = 0;
                data.editnote = data.editnote + "-- Update by " + this.userdata.firstname + " " + this.userdata.lastname;
            }

            data.update = 1;
            data.status = 1

            console.log("form data", data);

            axios({
                    url: 'material/newinventory',
                    method: 'get',
                    params: data,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.updateInvetory = false;

                    this.alert = true;
                    this.alertmessage = this.lang.InventoryUpdateSuccessfully;
                    this.alertType = "success"
                    this.getTaskData();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
       removeInventory(id){
               console.log(id);
                 
            var conf = confirm(this.lang.Confirmremovethisitem);
                   
                    if(conf){
                           axios({
                    url: '/material/inventory/remove',
                    method: 'get',
                    params: {
                        id: id
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = resp.data;
                    this.getTaskData();
                     this.alert = true;
                    this.alertmessage = this.lang.InventoryDeletedSuccessfully;
                    this.alertType = "success"
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })  
                    }
        }, 
        addnewItem(){
                this.addgetMaterialHeading();
                this.addInvetory = true;
        },
          addgetMaterialHeading() {

            var projectid = this.projectid;
            var mhfilter = '{"where": {"projectid":' + projectid + '}}';
            var urlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/heading',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    console.log("headings--", resp);
                    this.headings = JSON.parse(resp.data.success);
                    this.addgetMaterialSubHeading();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        addgetMaterialSubHeading() {

            // var optionText = event.target.options[event.target.options.selectedIndex].text;

            //console.log(optionText);
            console.log(this.newData);
            var id;
            if (this.newData.material_heading_id.id) {
                id = this.newData.material_heading_id.id;
                this.newData.material_heading = this.newData.material_heading_id.material_heading;

            } else {
                id = this.newData.material_heading_id;
            }

            var mhfilter = '{"where": { "material_heading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/subheading',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    console.log("headings", resp);
                    this.subheadings = JSON.parse(resp.data.success);
                    this.addgetMaterialName()
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        addgetMaterialName() {
            //   var id = this.updateData.sub_heading_id;

            console.log(this.newData.sub_heading_id);
            var id;
            if (this.newData.sub_heading_id.id) {
                id = this.newData.sub_heading_id.id;
                this.newData.sub_heading = this.newData.sub_heading_id.subheading;

            } else {
                id = this.newData.sub_heading_id;
            }

            var mhfilter = '{"where": { "subheading_id": "' + id + '"}}';
            var mhurlfilter = encodeURI(mhfilter);
            axios({
                    url: 'material/materials',
                    method: 'get',
                    params: {
                        f: mhurlfilter
                    }
                })
                .then(resp => {
                    console.log("materials", resp);
                    this.materialnames =  JSON.parse(resp.data.success);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        addselectMaterial() {
            //var id = this.updateData.material_name_id;

            var id;
            if (this.newData.material_name_id.id) {
                id = this.newData.material_name_id.id;
                this.newData.material_name = this.newData.material_name_id.materila_name;

            } else {
                id = this.newData.material_name_id;
            }
            console.log("materialid", id)
            axios({
                    url: 'material/unitmaterial',
                    method: 'get',
                    params: {
                        materialid: id
                    },
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.newData.unit = resp.data.unit;
                    this.newData.asset_type = resp.data.asset_type;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
         submitNewsInventory() {
            this.send = true;
            var data = this.newData;
            

            
                data.editreq = 0;
                data.editnote = '';
                data.status = 0
                data.usename = this.userdata.firstname + " " + this.userdata.lastname

            axios({
                    url: 'material/newinventory',
                    method: 'get',
                    params: data,
                })
                .then(resp => {
                    console.log("materialsUnit", resp);
                    this.send = false;
                    this.addInvetory = false;

                    this.alert = true;
                    this.alertmessage = this.lang.InventoryAddedSuccessfully;
                    this.alertType = "success"
                    this.getTaskData();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
          goback(){
        this.$router.push("/dashboard/material/draft")
    },
     submit_inventorys(){
            this.preloader = true;
            var entry_id = this.$route.params.id;
            var filter = '{"where": {"id": "'+entry_id+'"}}' ;  
             var urlfilter = encodeURI(filter);
            axios({
                    url: '/material/get-entery',
                    method: 'get',
                    params: {
                        f: urlfilter
                    }
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);

                    var response  = resp.data[0];

                var vei = response['vei'];
                var vbi = response['vbi'];
                var dci = response['dci'];
                var vnpi = response['vnpi'];
                var vui = response['vui'];
			
			console.log(vei,vbi,dci,vnpi,vui);

            
                    if(vei == 1 && vbi == 1 && dci == 1 && vnpi == 1){

                            this.taskdata.forEach(a => {
                                    this.updateStatus(a.id);
                                
                            });

                            this.updateEnter();

                    }else{
                            alert(this.lang.mobileapp)
                            // this.alert = true;
                            // this.alertmessage = "Please upload the images from the mobile app to submit inventories.";
                            // this.alertType = "error"
                    }
                   
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
     },
      updateStatus(id) {
            axios({
                    url: '/material/inventory/update',
                    method: 'get',
                    params: {id : id},
                })
                .then(resp => {
                    console.log(resp);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
      updateEnter() {
            var entry_id = this.$route.params.id;
            axios({
                    url: '/material/entry/update',
                    method: 'get',
                    params: {id : entry_id}
                })
                .then(resp => {
                    console.log(resp);
                     this.submit_inventorys_status = true;
                    this.alert = true;
                    this.alertmessage = this.lang.InventorySubmitSuccessfull;
                    this.alertType = "success"
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
       getSupplier(){
            this.preloader = true;
                 var projectid = this.projectid;
                //  var filter = '{"where":{ "project_id": '+projectid+'}}';
                //  var urlfilter = encodeURI(filter);
        
             axios({
                    url: '/material/suppliers',
                    method: 'get',
                   params: {f:projectid}
                })
                .then(resp => {
                    console.log("Suppliers", resp);
                     this.preloader = false;
                     this.suppliers = resp.data; 
                   
                })
                .catch(err => {
                    console.log(err);
                     this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },   
    }
};
</script>

<style>
.remark {
    position: absolute;

    right: 0;
}

.remarkbox {
    position: relative;

}

.mySearchInventoryddd {
   float: left;
    width: calc(100% - 245px);
    text-align: center;
    line-height: 45px;
}
/* .rightbutons {
  margin-top: 9px;
} */
.right_aligh {
    top: -51px;
    right: 13px;
    position: relative;
    float: right;
}
.center{
    text-align:center;
}
.cumulativebox{
    min-height: 450px;
    width:100%;
    text-align:center;
}
.daterangepicker {
    min-width: 750px !important;
}
.vue-daterange-picker.v-text-field__slot {
    width: 96%;
    margin-bottom: 6px;
}
.daterangepicker .btn-primary, .daterangepicker .btn-success {
    background-color: #565656;
    color: #fff;
}
.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    background: #000;
    left: 0;
    padding: 13px;
}
.submmitinventory {
    font-size: 14px;
    background: #fdc001;
    color: #000;
    padding: 12px 44px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
}
.submmitinventory:hover {
    color: #000;
}
.tavbtable .v-data-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.75rem;
    padding: 0 8px;
}
</style>

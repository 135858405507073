<template>
    <div>
      <Preloader v-if="preloader" />
      <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
<v-card>
    <v-card-title class="reportinfo">
            Edited Videos List
        </v-card-title>
        <!-- <v-card flat> -->
          <v-snackbar v-model="snackbar" center top timeout="10000" type="success">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ lang.Ok }}
        </v-btn>
      </template>
    </v-snackbar>
      <v-container fluid>
    <v-row>
      <!-- Dynamically add cards -->
      <v-col v-for="(editedVideo, index) in editedVideosList" :key="index" cols="12" md="4">
        <v-card class="mx-auto" max-width="344" shaped>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-1">
                {{ formatDate(editedVideo.created_at) }}
              </div>
              <v-list-item-subtitle class="text-overline mb-1" style="color: black;">
                {{ editedVideo.remarks }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <!-- <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar> -->
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text @click="openInNewTab(editedVideo.url)">
              Watch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-card>
    </div>
  </template>
  
  <script>
  
  import axios from "axios";
  import Preloader from "../../components/PreLoader";
  import { locals } from "../../utils/locals";
  import rotate from '../../assets/rotedmobilepsd.jpg';
  export default {
    components: {
      Preloader,
    },
    name: "latest_visit_report",
    data() {
      return {
        rotate: rotate,
        currentProject: {},
        current_cam: {},
        preloader: false,
        alert: false,
        alertmessage: "",
        alertType: "",
        tab: 1,
        snackbar: false,
        text: "Something went wrong. Please reload and try again.",
        valid: true,
        Local :locals,  
        lang : {},
        latestVisitReportStatus: 0,
        checkbox: false,
        editedVideosList: [],
      };
    },
    mounted() {
       var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];
  
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.current_cam = this.$store.state.currentCamera;
      this.userdata = JSON.parse(localStorage.getItem('userdata'))
       if(this.userdata.free_access == 0){
            this.useraccess = true
          }else{
            this.$router.push('/')
      }
      this.fetchEditedVideosList();
        },
    methods: {
      fetchEditedVideosList() {
        axios({
          url:
            "/drone/"+ this.currentProject.id + "/get_edited_videos",
          method: "POST",
          data: {user_id: this.userdata.id},
        }).then((resp) => {
          if (resp.data.success) {
            this.editedVideosList = resp.data.data;
          }
          else {
            this.snackbar = true;
            this.text = "No edited videos found.";
          }
        }).catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = "Something went wrong. Please reload page and try again.";
        });
      },
      openInNewTab(url) {
      window.open(url, '_blank').focus();
    },
    formatDate(dateString) {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}-${months[monthIndex]}-${year} ${hours}:${minutes}:${seconds}`;
    },
    },
  };
  </script>
  <style>
  .reportinfo {
    font-size: larger;
    font-weight: 500;
    color: #ffc400;
  }
  .v-card {
  margin-bottom: 20px; /* Adjust spacing between cards */
}
  </style>
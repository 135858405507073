<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                <div class="stepper_outer">
                    <!-- <div class="steper_item active" @click="setrouter('/dashboard/planning/uploade-task')">
                        <div class="itcons">
                            <v-icon>mdi-cloud-upload </v-icon>
                        </div>
                        <span class="steperItem"> Upload Task </span>
                    </div> -->
                    <div class="steper_item " @click="setrouter('/dashboard/planning/edit-task')">
                        <div class="itcons">
                             <span class="steperItem"> Edit Task </span>
                            <v-icon>mdi-square-edit-outline </v-icon>
                        </div>
                       
                    </div>
                    <div class="steper_item" @click="setrouter('/dashboard/planning/compare-events')">
                        <div class="itcons">
                            <span class="steperItem"> Compare Events </span>
                            <v-icon>mdi-compare </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item " @click="setrouter('/dashboard/planning/event-chart')">
                        <div class="itcons">
                            <span class="steperItem"> Event Chart </span>
                            <v-icon>mdi-chart-gantt </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item" @click="setrouter('/dashboard/planning/edit-task')">
                        <div class="itcons">
                            <span class="steperItem"> Event Timelapse </span>
                            <v-icon>mdi-play-circle </v-icon>
                        </div>
                        
                    </div>

                </div>

                <v-alert dense text :type="alertType" v-if="alert">
                    {{ alertmessage }}
                </v-alert>

                <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="submit">

                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <input type="file" @change="onChange" />

                                 <xlsx-read :file="file" class="hide">
                                    <xlsx-json :sheet="selectedSheet">
                                        <template #default="{collection}">
                                            <div ref="filedata">
                                                {{ collection }}
                                            </div>
                                        </template>
                                    </xlsx-json>
                                </xlsx-read>
                            </v-col>
                            <v-col cols="12" md="12">
                                <p> Upload Excel (.xls) exported from Primavera / Microsoft project etc. </p>
                            </v-col>
                            <v-col cols="12" md="12">

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    submit
                                </v-btn>

                            </v-col>
                            <v-col cols="12" md="12">
                                <a href="/OpticVyu_sample_planning_data_sheet.xls" style="color:#000; text-decoration:none">
                                    <h3 style="color:#000; text-decoration:none">
                                        <v-icon>mdi-download-circle </v-icon>
                                        Download Sample.xls file
                                    </h3>
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <!-- <input type="file" @change="onChange" />
      <xlsx-read :file="file">
        <xlsx-json :sheet="selectedSheet">
          <template #default="{collection}">
            <div>
              {{ collection }}
            </div>
          </template>
        </xlsx-json>
      </xlsx-read> -->

            </v-card>
        </v-col>

    </v-row>

</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import { read, utils } from 'xlsx'
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";

export default {
    components: {
        Preloader,
        XlsxRead,
        XlsxJson
    },
    name: "UploadeTasks",
    data() {
        return {
            valid: true,
            file: null,
            projectid: 0,
            preloader: false,
            alert: false,
            alertmessage: '',
            alertType: ''
        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;

        this.initViewer();
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
        }
    },
    computed: {

    },
    methods: {
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = false
        },

        onChange(event) {
            //this.file = event.target.files ? event.target.files[0] : null;

             var oFile = event.target.files ? event.target.files[0] : null;
              var sFilename = oFile.name;

                console.log(sFilename);

            var reader = new FileReader();
      //  var result = {};

        reader.onload = function (e) {
            var data = e.target.result;
            data = new Uint8Array(data);
            var workbook = read(data, {type: 'array'});
            console.log(workbook);
            var result = {};
            workbook.SheetNames.forEach(function (sheetName) {
                var roa = utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
                if (roa.length) result[sheetName] = roa;
            });
            // see the result, caution: it works after reader event is done.
            console.log(result);
        };
        reader.readAsArrayBuffer(oFile);
        },
        setrouter: function (url) {
            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
            }

            //console.log(url);
        },
        async submit() {
            let childEl = this.$refs.filedata.innerHTML;
            // console.log(childEl);
            var userdata = {
                filedata: childEl
            }
            axios({
                    url: '/project/' + this.projectid + '/uplaod-task',
                    data: userdata,
                    method: 'POST'
                })

                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {

                        this.alert = true;
                        this.alertmessage = 'Your task uploaded succesfully.';
                        this.alertType = "success"

                    } else {

                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error"
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
    }
};
</script>

<style>

</style>

<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
      {{lang.ChangeYourPassword}}
    </v-card-title>
          
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
  >
      
      
        <v-text-field
          v-model="oldpassword"
          :label="lang.OldPassword"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          required
          :rules="oldpasswordrule"
        ></v-text-field>
    
          <!-- regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ -->
      
        <v-text-field
          v-model="newpassword"
          :label="lang.NewPassword"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          ref="password"
          :rules="newpasswordrules"
          required
        ></v-text-field>
    
        <v-text-field
          v-model="confpassword"
          
          :label="lang.ConfirmPassword"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          data-vv-as="password"
          required
          :rules="confirmpasswordrules"
        ></v-text-field>
    
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        {{lang.submit}}
      </v-btn>
      <v-btn @click="clear"
       color="mydarkbutton"
      >
        {{lang.clear }}
      </v-btn>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar :activelink="activelink"/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "ChangePassword",
  data() {
    return { 
      valid: true,
       oldpassword: '',
        oldpasswordrule: [
        v => !!v || this.lang.required
      ],
       newpassword: '',
       newpasswordrules: [
        v => !!v || this.lang.required,
        v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) || this.lang.passwordvalid,
      ],
       confpassword: '',
       confirmpasswordrules: [
        v => !!v || this.lang.required,
        v => v == this.newpassword  || this.lang.passwordsame,
      ],
      preloader: false,
        show1: false,
         show2: false,
          show3: false,
          alert: false,
          alertmessage: '',
          alertType: '', 
          activelink :'',
           Local :locals,  
        lang : {}, 
        
     };
  },
  mounted() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.initViewer();
      this.activelink = this.$route.path;
  },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
    
  }
  },
    computed: {
    params() {
      return {
        oldpassword: this.oldpassword,
        newpassword: this.confpassword
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.login(this.params) // action to login
      }
    },
    login(pasword) {
        axios({ url: '/user/passwordchange', data: pasword, method: 'POST' })
          .then(resp => {
                   // console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
          })
      },
      clear () {
        this.oldpassword = ''
        this.newpassword = ''
        this.confpassword = ''
      },
  }
};
</script>
<style>
</style>


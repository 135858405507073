<template>
  <v-card height="91.5vh">
    <v-navigation-drawer absolute permanent right width="100%">
      <template v-slot:prepend>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="amber accent-3">
              <span class="white--text headline"
                >{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                >{{ userdata.firstname }}
                {{ userdata.lastname }}</v-list-item-title
              >
              <v-list-item-subtitle>{{
                userdata.user_code == 2
                  ? lang.SuperAdmin
                  : userdata.user_code == 1
                  ? lang.User
                  : lang.Admin
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red lighten-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="logout()"
                  >
                    mdi-logout
                  </v-icon>
                </template>
                <span>{{lang.Logout}} </span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
       
      <v-list dense>
        
        <v-list-item-group color="amber accent-3">
           <v-list-item  v-if="!useraccesssuperadmin">
                            <v-list-item-icon>
                                <v-icon>mdi-home-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                  <v-list-item-title> Update Project Status </v-list-item-title>
                                  <span :class="currentProject.interior_status"> <span class="heighliter">&nbsp;</span>  {{this.currentProject.interior_status}}</span>
                            </v-list-item-content>
                        </v-list-item>
           <v-list-item 
                           v-if="useraccesssuperadmin &&  activemm =='interior'"
                           :color="'/dashboard/interior/settings/project-status' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/dashboard/interior/settings/project-status')">
                            <v-list-item-icon>
                                <v-icon>mdi-home-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title> Update Project Status </v-list-item-title>
                                <span :class="currentProject.interior_status"> <span class="heighliter">&nbsp;</span>  {{this.currentProject.interior_status}}</span>
                            </v-list-item-content>
                        </v-list-item>
          <v-list-item
            @click="
              setrouter(
                '/dashboard/' + activemodule + 'settings/change-password'
              )
            "
            
          >
          <!-- v-if="const_cam_access" -->
            <v-list-item-icon>
              <v-icon>mdi-lock-reset</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{lang.UpdatePassword}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
                v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/' + activemodule + '/settings/profile' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/' + activemodule + 'settings/profile')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.Profile }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          <v-list-item
            @click="
              setrouter('/dashboard/' + activemodule + 'settings/adduser')
            "
            v-if="useraccess &&  nofreeaccess"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.AddUser}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="nofreeaccess"
            @click="
              setrouter('/dashboard/' + activemodule + 'settings/manage-user')
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.Users}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="useraccesssuperadmin"
            @click="
              setrouter('/dashboard/' + activemodule + 'settings/user-logs')
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-target-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>  {{lang.UserLogs}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="setrouter('/dashboard/settings/schedule-email')"
            v-if="useraccess && const_cam_access"
          >
            <v-list-item-icon>
              <v-icon>mdi-email-sync</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.EmailTrigger}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="setrouter('/dashboard/settings/camera-notifications')"
            v-if="useraccess && nofreeaccess && const_cam_access"
          >
            <v-list-item-icon>
              <v-icon>mdi-bell-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.CameraOffalert}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item
                v-if="useraccess && nofreeaccess && current_cam.ppe == 1"
                :color="
                  '/dashboard/settings/ai-data-schedule-report' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/ai-data-schedule-report')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-chart-pie</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> PPE Schedule Report </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
          <v-list-item
            @click="setrouter('/dashboard/settings/camerainfo')"
            v-if="useraccess && nofreeaccess && const_cam_access"
          >
            <v-list-item-icon>
              <v-icon>mdi-cctv</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.CameraStatus }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          

          <v-list-item
            @click="setrouter('/dashboard/settings/camera/Cam_Name_and_Tag')"
            v-if="useraccess && nofreeaccess && const_cam_access"
          >
            <v-list-item-icon>
              <v-icon>mdi-camera</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.CameraNameAndTag }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> 

          <v-list-item v-if="useraccess && nofreeaccess" :color="
                              '/dashboard/settings/open-access-url' == activelink
                                ? activecolor
                                : 'grey darken-3'
                            " @click="
                setrouter('/dashboard/settings/open-access-url')
                            ">
            <v-list-item-icon>
              <v-icon>mdi-link-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ lang.createOpenAccessLinkNavButton }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            @click="conditionsetrouter('/dashboard/settings/create-timelapse')"
            v-if="nofreeaccess && const_cam_access"
          >
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.CreateCustomTimelapse}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item
            v-if="billingaccess && nofreeaccess && const_cam_access && zoho_id"
            @click="setrouter('/dashboard/settings/billing')"
          >
            <v-list-item-icon>
              <v-icon>mdi-list-status</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.Billing }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            v-if="useraccess && current_cam.bim == 1 && const_cam_access"
            @click="setrouter('/dashboard/settings/bim_rendering')"
          >
            <v-list-item-icon>
              <v-icon>mdi-image-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.ManageBIMRenderings}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item
            v-if="useraccess && nofreeaccess && const_cam_access"
            @click="
              setrouter('/dashboard/settings/integration/procore_connection')
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-toy-brick-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.Integration}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item   v-if="procoreshare" @click="setrouter('/dashboard/settings/procore_connection')" >
          <v-list-item-icon>
            <v-icon>mdi-connection</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Connect With Procore </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           <v-list-item  @click="setrouter('/dashboard/settings/procore_update')" >
          <v-list-item-icon>
            <v-icon>mdi-connection</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Procore Settings  </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
          <v-list-item @click="setrouter('/dashboard/'+ activemodule +'settings/security/two-factor-auth-setup')">
            <v-list-item-icon>
              <v-icon>mdi-security</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.TwoFactorAuthentication}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/settings/unsubscribe-email-notification')">
            <v-list-item-icon>
              <v-icon>mdi-email-off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Unsubscribe Email Notification</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="support_dialog">
            <v-list-item-icon>
              <v-icon>mdi-face-agent</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ lang.Support }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="setrouter('/dashboard/settings/camera/configuration')"
            v-if="useraccess && nofreeaccess && const_cam_access && camconfig_access"
           style="background-color: #e3e1e1;"
          >
            <v-list-item-icon>
              <v-icon>mdi-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{lang.CameraConfiguration }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
         

           <v-list-item
            @click="setrouter('/dashboard/settings/check_camera/installation')"
            v-if="useraccess && nofreeaccess && const_cam_access && camconfig_access"
            style="background-color: #e3e1e1;"
            >
            <v-list-item-icon>
              <v-icon>mdi-check-all</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{lang.ProjectInfo}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

           <!-- <v-list-item
            @click="setrouter('/dashboard/settings/ecam-setup')"
            v-if="useraccess && ecamaccess && const_cam_access"
             style="background-color: #e3e1e1;"
            >
            <v-list-item-icon>
              <v-icon>mdi-sticker-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Setup New Project/Camera</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item v-if="hasCRMAccess" :color="
                              '/dashboard/settings/crm' == activelink
                                ? activecolor
                                : 'grey darken-3'
                            " 
                            style="background-color: #e3e1e1;"
                            @click="
                setrouter('/dashboard/settings/crm')
                            ">
            <v-list-item-icon>
              <v-icon>mdi-ticket-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ lang.CRM }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item v-if="hasDailyReportAccess" :color="
                              '/dashboard/settings/daily-report' == activelink
                                ? activecolor
                                : 'grey darken-3'
                            " 
                            style="background-color: #e3e1e1;"
                            @click="
                setrouter('/dashboard/settings/daily-report')
                            ">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ lang.DailyReport }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

        </v-list-item-group>
         <Languages/>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="support_popup"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> {{lang.ContactSupport}} </v-toolbar>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitquery"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="support_subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="supportmsg"
                    :label="lang.Message"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send }}
                    <v-progress-circular
                      v-if="sup_send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="support_popup = false" color="mydarkbutton">
                    {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
//import axios from 'axios'
import axios from "axios";
import Languages from "./Headers/LanguageSelecter"
import { locals } from "../utils/locals";
import VueSweetalert2 from "vue-sweetalert2";
export default {
      components: {
       Languages
    },
  data: () => ({
    userdata: {},
    useraccess: false,
    activecolor: "amber accent-3",
    current_cam: {},
    billingaccess: false,
    currentProject: {},
    procoreshare: true,
    activelink: "",
    procore_url: "/dashboard/settings/integration/procore_connection",
    nofreeaccess: false,
    const_cam_access: true,
    activemodule: "",
    zoho_id: false,
    support_popup: false,
    support_subject: "",
    supportmsg: "",
    sup_project: "",
    sup_org: "",
    sup_city: "",
    sup_camid: "",
    sup_camidd: "",
    sup_send: false,
    valid: true,
    fullusername: "",
    useremailadd: "",
    usermobile: "",
    camconfig_access: false,
    Local :locals,  
    lang : {}, 
    ecamaccess: false,
    activemm : '',
    hasCRMAccess:false,
    hasDailyReportAccess:false,
  }),
  computed: {
    queryparams() {
      return {
        to: "admin@opticvyu.com",
        message: this.supportmsg,
        subject: this.support_subject,
        //attachment: this.source[0].url,
        project: this.sup_project,
        org: this.sup_org,
        city: this.sup_city,
        camera_id: this.sup_camid + " (" + this.sup_camidd + ")",
        username: this.fullusername,
        useremail: this.useremailadd,
        usercontact: this.usermobile,
      };
    },
    //isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  created() {
     var selectleng = this.$cookies.get('currentLanguage');

       this.lang = locals[selectleng];
    var rout = this.$route.name;
    this.moduleChecker(rout);

    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

    this.zoho_id = this.currentProject.zoho_id == "" ? false : true;

    if (this.currentProject.procore_proj_id) {
      this.procoreshare = false;
    }

    this.current_cam = this.$store.state.currentCamera;
    // console.log("current cam", this.current_cam);

    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.user_code == 2) {
      this.useraccesssuperadmin = true;
    }
    if (this.userdata.ecam == 1) {
      this.ecamaccess = true;
    }
    //console.log("Setting Sidebar", this.$route.path);

    if (this.userdata.billing_access == 1) {
      this.billingaccess = true;
    }
    if (this.userdata.free_access == 0) {
      this.nofreeaccess = true;
    }
    if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
      this.hasCRMAccess = true;
    }
    if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
      this.hasDailyReportAccess = true;
    }
    var pop = localStorage.getItem("procer_project");

   // console.log("pop", pop);
    if (pop > 0) {
      this.procore_url = "/dashboard/settings/integration/procore_update";
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.current_cam = this.$store.state.currentCamera;
      // console.log(this.current_cam);
      this.zoho_id = this.currentProject.zoho_id == "" ? false : true;
    },
    $route: function () {
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.current_cam = this.$store.state.currentCamera;
      this.zoho_id = this.currentProject.zoho_id == "" ? false : true;
      this.sup_project = this.currentProject.project_name;
      this.sup_org = this.currentProject.company;
      this.sup_city = this.currentProject.city;
      this.sup_camidd = this.$store.state.currentCamera.camera_id;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
      //console.log("opticvyu user", this.userdata.opticvyu_user);
      if(this.userdata.opticvyu_user == 1){
            this.camconfig_access = true;
      }else{
           this.camconfig_access = false;
      }
    },
  },
  mounted() {
    this.initViewer();
  },
  methods: {
     initViewer() {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.sup_project = currentProject.project_name;
      this.sup_org = currentProject.company;
      this.sup_city = currentProject.city;
      this.sup_camidd = this.$store.state.currentCamera.camera_id;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
     // console.log("opticvyu user", this.userdata.opticvyu_user);
      if(this.userdata.opticvyu_user == 1){
            this.camconfig_access = true;
      }else{
           this.camconfig_access = false;
      }
    },
    setrouter: function (url) {
     // console.log(url);
      if (this.$route.path !== url) {
        this.$router.push(url);
        //   console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    conditionsetrouter: function(url){
          this.showmobilemenu = false;
      if (this.$route.path !== url) {
        if(this.useraccess){
            this.$router.push(url);
            this.drawer = false;
            //console.log("current router ", this.$route.path);
            this.activelink = this.$route.path;
        }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      }
    },
    logout: function () {
      //console.log("logout butten pressed");
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    Authentication: function () {
      window.open("https://www.opticvyu.com/", "_blank");
    },
    moduleChecker(rout) {
      var currentmodule = rout.split("_");

     // console.log("sitebar setting module", currentmodule);

     this.activemm = currentmodule[0];
      if (
        currentmodule[0] == "material" ||
        currentmodule[0] == "drone" ||
        currentmodule[0] == "interior"
      ) {
        this.const_cam_access = false;
        this.activemodule = currentmodule[0] + "/";
      } else {
        this.const_cam_access = true;
        this.activemodule = "";
      }
    },
    support_dialog() {
      this.support_popup = true;
      // this.params.attachment = this.source[0].url;
    },
    async submitquery() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.support_query(this.queryparams); // action to login
      }
    },
    support_query(sharedata) {
      //  console.log("data", sharedata);
      this.sup_send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-support-request",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.support_popup = false;
          this.sup_send = false;
        })
        .catch((err) => {
          console.log(err);
          this.sup_send = false;
          this.snackbar = true;
          this.text =this.lang.EmailFaild;
          this.preloader = false;
        });
    },
  },
};
</script>
<style>
</style>
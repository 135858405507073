<template>
<div>
    <Preloader v-if="preloader" />
    <div class="pa-5">

        <v-row>
            <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
                <h2 class="hetitle">{{lang.Graphs}}</h2>
            </v-col>

            <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                <!-- <ejs-daterangepicker :change="onValueChange" :minDays="min" :maxDays="max" :strictMode="mode" :placeholder="this.lang.selectRange">

                </ejs-daterangepicker> -->
                <p style="color:#d4425b;font-size:11px"> {{lang.max7}} </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="paiseries.length == 0">
                <p style="color:red;text-align:center"> {{lang.last7days}} </p>
            </v-col>
            <v-col cols="6">
                <div id="chartbar" v-if="showChart">
                    <apexchart type="bar" height="350" :options="chartOptions"  @click="clickonTopchart" :series="series"></apexchart>
                </div>
            </v-col>
            <v-col cols="6">
                <div id="chart" v-if="showChart">
                    <apexchart type="pie" width="480" :options="paichartOptions" :series="paiseries"></apexchart>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <br />
        
            <v-row>
                <v-col cols="12">
                    <h2 class="hetitle">{{lang.AIData}}</h2>
                    <v-divider></v-divider>
                </v-col>

                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="aidate" label="Date" :rules="firstnamerules" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @change="get_AI_Data" v-model="aidate" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>
            
                <v-col class="d-flex" cols="12" xs="6" sm="1" md="1" lg="1" xl="1">
                    <v-btn tile :disabled="!valid" type="submit" color="mylightbutton">
                        <v-icon>
                            mdi-check
                        </v-icon>

                    </v-btn>
                </v-col>

            </v-row>
        
          
            <v-row>
                 <v-col cols="12">
                     <div id="chartbar" v-if="preshowChart">
                       <apexchart type="bar" height="350" :options="prechartOptions" :series="preseries"  @click="clickHandler"></apexchart>
                        <p style="color:red;text-align:center" v-if="ai_data_images.length == 0">
                        {{lang.clicktogetrelated}}
                      </p>
                      </div>
                 </v-col>
                  <!-- <v-col  cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="ai_data_images.length > 0">
                    <h2 class="hetitle">AI Images</h2>
                    <v-divider></v-divider>
                </v-col> -->
                 <!-- <v-form ref="aI_data_form" v-model="valid" lazy-validation @submit.prevent="get_AI_Data" v-if="ai_data_images.length > 0">
                   <v-row>
               

                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                    <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="aidate" label="Date" :rules="firstnamerules" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="aidate" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" xs="6" sm="3" md="3" lg="3" xl="3">
                    <v-select :items="presets" label="PTZ Position" v-model="presetid"></v-select>
                </v-col>
                <v-col class="d-flex" cols="4" sm="4">

                    <v-select :items="listtype" label="Images with" @change="selectImageType" v-model="image_type" :disabled="ai_data_images.length > 0 ? false : true"></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" xs="6" sm="1" md="1" lg="1" xl="1">
                    <v-btn tile :disabled="!valid" type="submit" color="mylightbutton">
                        <v-icon>
                            mdi-check
                        </v-icon>

                    </v-btn>
                </v-col>

            </v-row>
        </v-form> -->
        </v-row>
        
            <v-row>
                    
                <v-col v-for="(image, index) in ai_data_images" :key="index" class="d-flex child-flex camimage" cols="3">
                    <v-img :src="image.image_name" aspect-ratio="1.8" class="grey lighten-2" @click="openimage(image,index)">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                        <div class="menufooter ai">
                            <v-btn class="mx-2" fab x-small color="#fff" :title="lang.PersonsWithRequiredEquipment">
                                <v-icon color="#1ba745"> mdi-shield-check</v-icon>
                            </v-btn>
                            {{ image.persons_with_required_equipment }}
                            &nbsp;
                            <v-btn class="mx-2" fab x-small color="#fff" :title="lang.PersonsWithoutRequiredEquipment">
                                <v-icon color="#d23e3e"> mdi-shield-off</v-icon>
                            </v-btn>
                            {{ image.persons_without_required_equipment }}
                            &nbsp;
                            <!-- <v-btn class="mx-2" fab x-small title="Person Unrecognizable" color="#fff">
                                <v-icon color="#f8bb03"> mdi-crosshairs-question </v-icon>
                            </v-btn>
                            {{ image.persons_indeterminate }} -->

                        </div>
                    </v-img>
                </v-col>
            </v-row>
        

        <v-zoomer class="zzoomerfull" v-if="dialog">
            <img :src="img" @load="loaded" style="object-fit: contain; width: 100%; height: 100%;">
        </v-zoomer>
        <v-btn icon @click="preImage" class="left arrow" v-if="cimi > 1 && dialog">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

    <v-btn icon @click="nextImage" class="right arrow" v-if="cimi < ai_data_images.length-1 && dialog">
        <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
        <div class="aiinfobox" v-if="dialog">
            <p>
                <v-icon color="#1ba745"> mdi-shield-check</v-icon> &nbsp; {{lang.PersonsWithRequiredEquipment}}: {{personsWithRequiredPPET}}
            </p>
            <p>
                <v-icon color="#d23e3e"> mdi-shield-off</v-icon> &nbsp; {{lang.PersonsWithoutRequiredEquipment}}: {{WithoutRequiredPPET}}
            </p>
            <!-- <p>
                <v-icon color="#deb63d"> mdi-crosshairs-question </v-icon> &nbsp; {{lang.PersonUnrecognizable}}: {{UnrecognizedT}}
            </p> -->
            <p>
                <v-icon light> mdi-account-multiple-plus </v-icon> &nbsp; {{lang.PersonsIdentified}}: {{personsWithRequiredPPET + WithoutRequiredPPET }}
            </p>
        </div>
        <v-icon v-if="dialog" class="closebuttons" @click="dialog = false">mdi-window-close</v-icon>
    </div>
</div>
</template>

<script>
//import Vue from 'vue';
import axios from 'axios';
import Preloader from '../../components/PreLoader';
import VueApexCharts from 'vue-apexcharts'
import { locals } from "../../utils/locals";
// import {
//     DateRangePickerPlugin
// } from '@syncfusion/ej2-vue-calendars';
//import lodash from 'lodash'
//Vue.use(DateRangePickerPlugin);

//var self = this
export default {
    components: {
        Preloader,
        apexchart: VueApexCharts
    },
    name: "AiData",
    data() {
        return {
            currentcam: {},
            preloader: true,
            showChart: false,
            preshowChart : false,
            currentProject: {},
            records: {},
            series: [{
                name: 'Unprotected',
                data: [0]
            }, {
                name: 'Protected ',
                data: [0]
            }],
             preseries: [{
                name: 'Unprotected',
                data: [0]
            }, {
                name: 'Protected ',
                data: [0]
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                },

                colors: ['#e23b3b', '#00e396'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: '(People)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " People"
                        }
                    }
                },
            },

             prechartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }

                },

                colors: ['#e23b3b', '#00e396'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: '(People)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " People"
                        }
                    }
                },
                
            },

            paiseries: [],
            paichartOptions: {
                chart: {
                    width: 480,
                    type: 'pie',
                },
                labels: [ 'Protected', 'Unprotected'],
                colors: ['#00e396', '#e23b3b', ],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            dates: [],

            time3: '',
            max: '8',
            min: '1',
            waterMark: 'Select a Range',
            mode: true,
            menu2: false,
            menu3 : false,
            presets: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            listtype: ['Persons With Required PPE', 'Persons Without Required PPE','All'],
            aidate: '',
            firstnamerules: [(v) => !!v || "This Field is required"],
            valid: true,
            presetid: '',
            image_type: 'All',
            ai_data_images: [],
            dialog: false,
            img: '',
            personsWithRequiredPPET: 0,
            WithoutRequiredPPET: 0,
            UnrecognizedT: 0,
            selectbarIndex: 0,
            selectbar : 0,
            presetlist : [],
            cimi : 0,
            alldata : [],
             Local :locals,  
            lang : {},
        }
    },
    mounted() {

        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        this.currentcam = this.$store.state.currentCamera;

        document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                this.dialog = false;
            }
        })
        this.userdata = JSON.parse(localStorage.getItem('userdata'))
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        if (this.currentcam.ppe == 0) {
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.initViewer();
        this.presets = this.$store.state.currentCamera.preset_ids.split(",");
    },
    watch: {
        '$store.state.currentCamera': function () {

            this.currentcam = this.$store.state.currentCamera;

            if (this.currentcam.ppe == 0) {
                this.$router.push('/dashboard');
            }

            console.log("this.currentcam =", this.currentcam);
            this.getCurrentProjectData('');

            this.presets = this.$store.state.currentCamera.preset_ids.split(",");
             
             this.initViewer();
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        params() {
            return {
                aidate: this.aidate,
                presetid: this.presetid ? this.presetid : 1 ,
                image_type: this.image_type
            }
        }
    },
    methods: {

        clickHandler(event, chartContext, config){
        this.get_AI_Images(config.dataPointIndex, config.seriesIndex)
                       //   console.log(config.seriesIndex);
                       //   console.log(config.dataPointIndex); 

                        //  this.selectbar = config.dataPointIndex
                         // this.selectbarIndex = config.seriesIndex
        },
         clickonTopchart(event, chartContext, config){
           
            // console.log("sdds",config.dataPointIndex);

         var d = this.alldata[config.dataPointIndex];
            console.log("ddd",d.filterdate);

            this.aidate = d.filterdate;

            this.get_AI_Data();
            
              var v = this;


            setTimeout(function () {
                v.get_AI_Images(0, 0)
            }, 8000);
              //this.get_All_AI_Images(config.dataPointIndex, config.seriesIndex)
         },

        initViewer() {
            //console.log("init Viewer");
            this.currentcam = this.$store.state.currentCamera;
            this.preloader = true
            this.getCurrentProjectData('')

        },
        openimage(data,index) {
            this.preloader = true;
            this.dialog = true;
            this.img = data.processed_image_path;
            this.personsWithRequiredPPET = data.persons_with_required_equipment;
            this.WithoutRequiredPPET = data.persons_without_required_equipment;
            this.UnrecognizedT = data.persons_indeterminate;
            this.cimi = index
        },
        loaded() {
            var v = this;

            setTimeout(function () {
                v.preloader = false;
            }, 10);
        },
        selectImageType(event) {

            this.preloader = true;

            console.log("event", event, this.main);

            if (this.image_type == 'Persons With Required PPE') {

                this.ai_data_images = this.main.filter(function (item) {

                    return item.persons_with_required_equipment > 0

                });

            } else if (this.image_type == 'Persons Without Required PPE') {

                this.ai_data_images = this.main.filter(function (item) {

                    return item.persons_without_required_equipment > 0

                });

            } else if (this.image_type == 'Unrecognized Persons') {

                this.ai_data_images = this.main.filter(function (item) {

                    return item.persons_indeterminate > 0

                });

            } else {

                this.ai_data_images = this.main;

            }

            //console.log("filter data", this.ai_data_images);

            let self = this;
            setTimeout(function () {
                self.preloader = false
            }, 2000);

        },
         get_AI_Data() {
             this.preshowChart = false;
            let currentcam = this.currentcam.camera_id;

           // const valid = this.$refs.aI_data_form.validate();
            

                console.log("use data", this.params);
                this.preloader = true;
                axios({
                        url: '/ai/' + currentcam + '/get-ai-pre-record',
                        //  url: '/ai/812/get-ai-images-record',
                        method: 'GET',
                        params: this.params
                    })
                    .then(resp => {
                        console.log("resp", resp.data.success);
                        
                    this.prebarchardData(resp.data.success);
                        let self = this;
                        setTimeout(function () {
                            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                            self.preloader = false
                        }, 2000);

                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })

            
        },
        
         get_AI_Images(index, type) {

            let currentcam = this.currentcam.camera_id;

          
         //  console.log("this.prechartOptions",this.prechartOptions)
               console.log("type",type)
               console.log("index",index)

                  console.log("this.presetlist[index]",this.presetlist);
               var a = this.presetlist[index].split('pr');

                
              

               this.presetid = a[1];

            var presetid  =  this.presetlist[index];

             if (type == 1){
                 
                 this.image_type = 'Persons With Required PPE' 

            } else if (type == 0) {

                this.image_type = 'Persons Without Required PPE'

            } else if (type == 2) {
                this.image_type = 'Unrecognized Persons'
            }

             console.log(" this.image_type",this.image_type);

            var data = {
                aidate: this.aidate,
                presetid: presetid,
                image_type: this.image_type
            }



           

                console.log("use data", data);
                this.preloader = true;
                axios({
                        url: '/ai/' + currentcam + '/get-ai-images-record',
                        //  url: '/ai/812/get-ai-images-record',
                        method: 'GET',
                        params: data
                    })
                    .then(resp => {
                        console.log("resp", resp.data.success);
                        // this.ai_data_images = resp.data.success;

                        this.main = resp.data.success;
                        var data = resp.data.success;

                        data.forEach((item, index) => {

                           // data[index].persons_indeterminate = JSON.parse(item.persons_indeterminate).length;
                            data[index].persons_with_required_equipment = JSON.parse(item.persons_with_required_equipment).length;
                            data[index].persons_without_required_equipment = JSON.parse(item.persons_without_required_equipment).length;

                        });

                        if (this.image_type == 'Persons With Required PPE') {

                            this.ai_data_images = data.filter(function (item) {

                                return item.persons_with_required_equipment > 0

                            });

                        } else if (this.image_type == 'Persons Without Required PPE') {

                            this.ai_data_images = data.filter(function (item) {

                                return item.persons_without_required_equipment > 0

                            });

                        } else if (this.image_type == 'Unrecognized Persons') {

                            this.ai_data_images = data.filter(function (item) {

                                return item.persons_indeterminate > 0

                            });

                        } else {
                            this.ai_data_images = data;
                        }

                        let self = this;
                        setTimeout(function () {
                            window.scrollTo(0, 1100);
                            self.preloader = false
                        }, 2000);

                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })

            
        },
        onValueChange: function (args) {

            // var ss =  args.value.toLocaleDateString();

            if (args.value) {

                this.showChart = false;
                this.preloader = true

                this.getCurrentProjectData(args);
            }

        },

        getCurrentProjectData(args) { // Moduletype variable store value of marial, interior or constructuion cameras.

            // args.value[0].toLocaleDateString(), args.value[1].toLocaleDateString()

            var start = '';
            var end = '';
            let currentcam = this.currentcam.camera_id;

            var dataurl = '/ai/' + currentcam + '/seven-days-record'; //'http://ai.opticvyu.com:3000/ppe-f-cw/'+currentcam+'/1'

            if (args) {

                console.log("args", args);

                var datas = args.text.split(' - ');

                //   $start = '2021-09-01';

                var s = datas[0].split('/');

                var e = datas[1].split('/');

                start = s[2] + "-" + s[0] + "-" + s[1];

                end = e[2] + "-" + e[0] + "-" + e[1];

                console.log(start);
                console.log(end);

                //      var Xmas95 = new Date(args.value[0]);

                //  console.log(new Intl.DateTimeFormat('en-US').format(Xmas95));

                // console.log("Selected Value: " , args.value[0].toLocaleDateString(), args.value[1].toLocaleDateString());

                // start = args.value[0];
                // end = args.value[1];

                //  dataurl = 'http://ai.opticvyu.com:3000/ppe-f-cd/'+currentcam+'/'+start+'/'+end;  
            }

            // var commondate = [];
            axios({
                    url: dataurl,
                    method: 'GET',
                    params: {
                        start: start,
                        end: end,
                    }
                })
                .then(resp => {
                    
                    // console.log("resp -->> ", resp.data.success);

                    // var ss = resp.data.success;

                    // 

                  //  var total_indeterminate = 0;
                    var total_persons_with_required_equipment = 0;
                    var total_persons_without_required_equipment = 0;

                    // console.log( "==" ,total_indeterminate );

                    resp.data.success.forEach(item => {

                      //  total_indeterminate += JSON.parse(item.persons_indeterminate).length;
                        total_persons_with_required_equipment += JSON.parse(item.persons_with_required_equipment).length;
                        total_persons_without_required_equipment += JSON.parse(item.persons_without_required_equipment).length;

                        this.paiseries = [ total_persons_with_required_equipment, total_persons_without_required_equipment];

                    });
                  
                    this.barchardData(resp.data.success);
                   
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                })
        },
        barchardData(data) {

            var updated = Object.values(data.reduce((obj, item) => {
                item.persons_indeterminate = JSON.parse(item.persons_indeterminate).length;
                item.persons_without_required_equipment = JSON.parse(item.persons_without_required_equipment).length;
                item.persons_with_required_equipment = JSON.parse(item.persons_with_required_equipment).length;

                //console.log(item);

                var key = item.filterday + item.filterdate
                if (!obj[key]) {
                    obj[key] = Object.assign(item)
                    //  console.log("dfaf",obj[key]);
                } else {
                    obj[key].persons_indeterminate += item.persons_indeterminate;
                    obj[key].persons_without_required_equipment += item.persons_without_required_equipment;
                    obj[key].persons_with_required_equipment += item.persons_with_required_equipment;
                }
                return obj
            }, {}))
            
            this.alldata = updated;
            console.log(updated)
            this.columchartData(updated);
          

        },
        prebarchardData(data) {

            console.log("prdaa",data)

            var updated = Object.values(data.reduce((obj, item) => {
                item.persons_indeterminate = JSON.parse(item.persons_indeterminate).length;
                item.persons_without_required_equipment = JSON.parse(item.persons_without_required_equipment).length;
                item.persons_with_required_equipment = JSON.parse(item.persons_with_required_equipment).length;

                //console.log(item);

                var key = item.preset_id
                if (!obj[key]) {
                    obj[key] = Object.assign(item)
                    //  console.log("dfaf",obj[key]);
                } else {
                    obj[key].persons_indeterminate += item.persons_indeterminate;
                    obj[key].persons_without_required_equipment += item.persons_without_required_equipment;
                    obj[key].persons_with_required_equipment += item.persons_with_required_equipment;
                }
                return obj
            }, {}))

            console.log(updated)
          
            this.precolumchartData(updated);

        },
        precolumchartData(a) {

            console.log("item predetection", a);
            var total_persons_indeterminate = [];
            var total_persons_with_required_equipment = [];
            var total_persons_without_required_equipment = [];
            var xaxis = [];
            var presetids = [];
            a.forEach(item => {
                //    console.log("item.persons_indetermina",item.persons_indeterminate);
                total_persons_indeterminate.push(item.persons_indeterminate);
                total_persons_with_required_equipment.push(item.persons_with_required_equipment);
                total_persons_without_required_equipment.push(item.persons_without_required_equipment);

                 presetids.push(item.preset_id);

                 var a = item.preset_id.split('pr');

                 var m =  "Preset "+a[1]; 

                xaxis.push(m);
            })
            this.presetlist = presetids;

            // console.log("total_indeterminate", total_persons_indeterminate);

            this.preseries = [{
                name: 'Unprotected',
                data: total_persons_without_required_equipment
            }, {
                name: 'Protected ',
                data: total_persons_with_required_equipment
            }];

            this.prechartOptions.xaxis.categories = xaxis;
            console.log("xaxis category", xaxis);

            //    console.log("this.chartOptions",this.series);
            let self = this;
            setTimeout(function () {
                   self.prechartOptions.xaxis.categories = xaxis;
                self.preshowChart = true;
                self.preloader = false
            }, 2000);

        },
        columchartData(a) {

            console.log("item.persons_indetermina__", a);
            var total_persons_indeterminate = [];
            var total_persons_with_required_equipment = [];
            var total_persons_without_required_equipment = [];
            var xaxis = [];
            a.forEach(item => {
                //    console.log("item.persons_indetermina",item.persons_indeterminate);
                total_persons_indeterminate.push(item.persons_indeterminate);
                total_persons_with_required_equipment.push(item.persons_with_required_equipment);
                total_persons_without_required_equipment.push(item.persons_without_required_equipment);

                var Xmas95 = new Date(item.filterdate);

                var options = {
                    weekday: 'short'
                };
                // console.log(new Intl.DateTimeFormat('en-US', options).format(Xmas95));

                xaxis.push(new Intl.DateTimeFormat('en-US', options).format(Xmas95));
            })

            // console.log("total_indeterminate", total_persons_indeterminate);

            this.series = [{
                name: 'Unprotected',
                data: total_persons_without_required_equipment
            }, {
                name: 'Protected ',
                data: total_persons_with_required_equipment
            }];

            this.chartOptions.xaxis.categories = xaxis;
            console.log("xaxis category", xaxis);

            //    console.log("this.chartOptions",this.series);
            let self = this;
            setTimeout(function () {
                self.showChart = true;
                self.preloader = false
            }, 2000);

        },
        preImage(){

         var dataindex =  this.cimi - 1 
         
           var data = this.ai_data_images[dataindex];
            this.openimage(data, dataindex);

        },
        nextImage(){
            var dataindex =  this.cimi + 1 
         
           var data = this.ai_data_images[dataindex];
            this.openimage(data, dataindex);
        }
    }
};
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.wrapper {
    max-width: 250px;
    margin: 0 auto;
}

.zzoomerfull {
    width: 100%;
    height: 100VH;
    border: solid 1px silver;
    position: fixed;
    top: 0;
    left: 0;
    background: #515b61b0 !IMPORTANT;
    ;
    DISPLAY: BLOCK;
}

.closebuttons {
    position: fixed !important;
    right: 10px;
    top: 50px;
    z-index: 999999;
    color: #fff !important;
    cursor: pointer;
}

.aiinfobox {
    position: fixed !important;
    right: 40px;
    top: 80px;
    z-index: 10;
    color: #000 !important;
    background: #ffffff9c;
    padding: 15px;
    display: flex;
    overflow: auto;
    flex-direction: column;
    border-radius: 5px;
    transition: 0.5s;
}

.aiinfobox:hover {
    transition: 0.5s;
    color: #000 !important;
    background: #ffffff;
    box-shadow: 2px 2px 2px #000;
}

.split360 {
    display: block;
    overflow: auto;
    height: 50vh;
    /*width: 48%;
  height: 50%;
  background: #000000ab;

  left: 20px;
  z-index: 1; */
}

span.camname {
    position: absolute;
    left: 20px;
    background: #02020236;
    padding: 3px 7px;
    border-radius: 3px;
    top: 20px;
    z-index: 2;
    color: #fff;
}

.camimage {
    position: relative;
}

.camimage .v-responsive__content {
    cursor: pointer;
}

.menufooter.ai {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: #00000075;
    padding: 6px 0;
    font-weight: bold;
    color: #fff;
}

h2.hetitle {
    background: #f8bb03;
    padding: 0px 15px;
    text-align: center;
    font-size: 17px;
    box-shadow: -2px 7px 16px 0px #0000004a;
}
#chartbar{
  cursor:pointer;
}
</style>

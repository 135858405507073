import { render, staticRenderFns } from "./project_category_info.vue?vue&type=template&id=c85e9dce"
import script from "./project_category_info.vue?vue&type=script&lang=js"
export * from "./project_category_info.vue?vue&type=script&lang=js"
import style0 from "./project_category_info.vue?vue&type=style&index=0&id=c85e9dce&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div style="max-width=100%;">
    <v-row>
      <v-col cols="12" md="3" sm ="3" out-line>
        <!------------ Desktop view content of action button--------->
        <v-card height="100vh" class="pa-2 desktop">
                <v-card>
                  <div
                    v-ripple="{ center: true }"
                    class="text-center elevation-1 pa-0 headline desktop">
                    <p style="font-size: 20px;">
                    {{lang.Albums}}

                    <v-btn color="warning" class="mr-1 white--text" fab small outlined  style="left: 22%;" title="Add New Album" @click="open_add_dir()">
                                  <v-icon dark>
                                      mdi-folder-plus
                                  </v-icon>
                                  </v-btn>
                          </p>        
                  </div>
              </v-card>
                <v-row class="desktop">
                  <v-col cols="12" md="12" class="dir_scroll vhfix">
                    <v-alert dense text :type="alertType" v-if="alert">
                      {{ alertmessage }}
                    </v-alert>
                    <v-col
                      cols="12"
                      md="12"
                      class="c_pointer"
                      v-for="r in mresponse"
                      :key="r.id"
                      @click="
                        getmobiledata(r)
                      "
                    >
                      <v-icon class="diricon">mdi mdi-folder</v-icon>
                      <span> {{ r.dir_name }} </span>
                    </v-col>
                  </v-col>
                </v-row>
        </v-card>
        <!------------ Tab view content of action button--------->
        <v-card height="100vh" class="pa-2 tabonly">
              <v-card>
                <div
                  v-ripple="{ center: true }"
                  class="text-center elevation-1 pa-0 headline tabonly">
                  <p style="font-size: 20px;">
                  {{lang.Albums}}

                  <v-btn color="warning" class="mr-1 white--text" fab small outlined  style="left: 13%;" title="Add New Album" @click="open_add_dir()">
                                <v-icon dark>
                                    mdi-folder-plus
                                </v-icon>
                                </v-btn>
                        </p>        
                </div>
            </v-card>
              <v-row class="tabonly">
                <v-col cols="12" md="12" class="dir_scroll vhfix">
                  <v-alert dense text :type="alertType" v-if="alert">
                    {{ alertmessage }}
                  </v-alert>
                  <v-col
                    cols="12"
                    md="12"
                    class="c_pointer"
                    v-for="r in mresponse"
                    :key="r.id"
                    @click="
                      getmobiledata(r)
                    "
                  >
                    <v-icon class="diricon">mdi mdi-folder</v-icon>
                    <span> {{ r.dir_name }} </span>
                  </v-col>
                </v-col>
              </v-row>
        </v-card>
        <!------------ Mobile view content of action button------------------>
       <!--  <div
              v-ripple="{ center: true }"
              class="text-center elevation-1 pa-0 headline mobileonly">
              <v-row class="mobileonly"> -->
            <v-row class="mobileonly">
                <v-col cols="9" md="9" sm ="9">
                    <v-select :items="mres_dir_name" class="ma-2 filterb"  item-text="dir_name" item-value="dir_name" small label="Select Album" dense solo v-model="selected_dir" @change="getmobiledata(event)" return-object single-line title="Select Album"></v-select>
                  </v-col>
                  <v-col cols="3" md="3" sm ="3" style="position: absolute;top: -1%;right: -5%;">
                  <v-btn color="warning" class="mr-1 white--text" fab small outlined  style="left: 22%;" title="Add New Album" @click="open_add_dir()">
                             <v-icon dark>
                                mdi-folder-plus
                            </v-icon>
                            </v-btn>
                   </v-col>       
            <!-- </div> -->
            </v-row>
      </v-col>

      <v-col cols="12" md="9" sm ="9" out-line>
        <v-snackbar v-model="snackbar" right top timeout="3000" type="success">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
             {{ lang.Ok }}
            </v-btn>
          </template>
        </v-snackbar>
    <!------------ Mobile & tab view content of action button--------->
          <v-row class="mobileonly">
            <v-col cols="12" md="12" sm ="12">
                <v-btn v-if="diropened" block color='#ffc400' class="ma-2 filterb mobileonly" @click="openUpload()" title="Upload Images and Video">
                          {{lang.UploadFiles}}
                          <v-icon right dark>
                              mdi-file-image
                          </v-icon>
                      </v-btn>
              </v-col>
          </v-row>  

           <v-row class="tabonly">
            <v-col cols="12" md="12" sm ="12">
                <v-btn v-if="diropened" block color='#ffc400' class="ma-2 filterb tabonly" @click="openUpload()" title="Upload Images and Video">
                          {{lang.UploadFiles}}
                          <v-icon right dark>
                              mdi-file-image
                          </v-icon>
                      </v-btn>
              </v-col>
          </v-row>  
         
    <!------------ desktop content of action button--------->
        <v-card style="top: -0.5%;" class="desktop">
          <div
            v-ripple="{ center: true }"
            class="text-center elevation-1 pa-0 headline content">
          <v-row>
              <v-col cols="12" md="3" sm ="3" style="height: 8vh;">
                <v-btn text x-small color="primary" v-if="dir_name" @click="reloadpage" style="float: left; position: relative;bottom: -25px;" title="Current project">
                  {{this.project}}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn plain x-small color="primary" v-if="dir_name" title="Current directory" style="float: left;margin-left: -10px; position: relative;bottom: -25px;">
                {{(dir_name.substring(0,13)+"..")}}
                </v-btn>
              </v-col>
              
              <v-col cols="6" md="3" sm ="3">
                <p style="font-size: 18px;position: relative;top: 83%;margin-top: -5%;left: 50%;" title="Download OpticVyu app from playstore and upload project photos captured through mobile">
                 {{lang.ImagesVideos}}
                    <!-- <span class="info_rec" @click="show = !show">
                      <v-tooltip bottom v-model="show">
                        <template v-slot:activator="{ on, attrs }"> -->
                          <v-icon color="primary" dark v-bind="attrs" v-on="on" :title="lang.mobilessupload">
                            mdi-progress-question
                          </v-icon>
                        <!-- </template>
                        <span
                          >{{lang.mobilessupload}}
                        </span>
                      </v-tooltip> 
                    </span>-->
                   </p> 
              </v-col>
              <v-col cols="6" md="6" sm ="6" style="height: 8vh;position: relative;bottom: -6px;"> 
                <v-select v-if="diropened" :items="uniquedates" class="midropdatess ma-2 filterb desktop" small label="Filter" dense solo @change="selectDate" style="margin-top: 0% !important; padding: 3px 14px;"></v-select>
             <!--  </v-col>
              <v-col cols="3" md="3" style="margin-top: -0.5%;">  -->
                 <v-btn v-if="diropened" small color='#ffc400' class="ma-2 filterb desktop" @click="openUpload()" title="Upload Images and Video" style="float: right;">
                    {{lang.UploadFiles}}
                    <v-icon right dark>
                        mdi-file-image
                    </v-icon>
                </v-btn>
                
                
              </v-col>
           </v-row> 
          
          </div>
        </v-card>
        <!------- desktop content of action button end here---------->.

        
        <v-alert dense text :type="alertType" v-if="alert">
          {{ alertmessage }}
        </v-alert>
        <v-row>
       
          <v-col cols="6" md="3" sm ="3" v-for="r in sortedItems" :key="r.id">
            <br />
            <v-hover>
              <template v-slot:default="{ hover }">
                    <v-card
                      class="mx-auto"
                      max-width="344"
                    >
                      <v-img
                        :src="r.img_url_thumb" @error="r.img_url_thumb=r.img_url"
                        aspect-ratio="1.7"
                        max-height="250"
                        max-width="344"
                        class="c_pointer"
                        v-if="!r.image_name.startsWith('vid')"
                        @click="openimage(r.img_url)"
                      ></v-img>
                      <v-img
                        src="https://i.makeagif.com/media/8-02-2015/r4NhcH.gif"
                        aspect-ratio="1.7"
                        max-height="250"
                        max-width="250"
                        v-if="r.image_name.startsWith('vid')"
                        class="c_pointer"
                        @click="openvideo(r.img_url)"
                      ></v-img>
                      <div class="mi_overlay_bottom"> <p style="margin: 0;">{{r.updated_att}}</p></div>
            <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#036358"
                  style="height:20%"
                >
                <p style="margin: 0;">{{r.user_name}} </p>
                  <!-- <v-btn v-if="!r.image_name.startsWith('vid')" @click="openimage(r.img_url)">See image</v-btn>
                  <v-btn v-if="r.image_name.startsWith('vid')" @click="openvideo(r.img_url)">See Video</v-btn> -->
                </v-overlay>
              </v-fade-transition>
               </v-card>
              </template>
            </v-hover>
            
          </v-col>
          <div class="uploadMedia1" v-if="uploadMedia">
            <div class="uploadBox">
              <v-btn icon fab light large @click="closeUpload" class=" mx-2 closeMedia">
                <v-icon right dark>
                    mdi-close
                </v-icon>
            </v-btn>
           <!--  <v-btn :color="activet == 'normal'? '#ffc400':'#000'" :class="`ma-2 filterb ${activet == 'normal'? 'Black--text':'white--text'}`" @click="uploadimg()"> -->
            <v-btn :color="activet == 'normal'? '#ffc400':'#000'" :class="`ma-2 filterb ${activet == 'normal'? 'Black--text':'white--text'}`" @click="uploadimg()">
                    {{lang.NormalImage}}
                    <v-icon right dark>
                        mdi-camera
                    </v-icon>
                </v-btn>
             <!--  <v-btn :color="activet == 'video'? '#ffc400':'#000'" :class="`ma-2 filterb  ${activet == 'video'? 'Black--text':'white--text'}`" @click="uploadvdo()"> -->
              <v-btn :color="activet == 'video'? '#ffc400':'#000'" :class="`ma-2 filterb  ${activet == 'video'? 'Black--text':'white--text'}`"  @click="uploadvdo()">
                    {{lang.Video}}
                    <v-icon right dark>
                        mdi-play
                    </v-icon>
                </v-btn>
            <div class="dropzone">
                <div :class="activet == 'normal'? 'show':'hide'">
                    <vue-dropzone ref="myVueDropzone" id="mdrop1"
                    :options="dropOptions" v-on:vdropzone-thumbnail="thumbnail" v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-error="checkerror" v-on:vdropzone-success="successmsg"></vue-dropzone>
                   
                </div>
                 <div :class="activet == 'video'? 'show':'hide'">
                   <v-file-input id="fileItem" prepend-icon="mdi-video" class="droppzone" label="Click to upload Normal Videos" accept="video/mp4,video/x-m4v,video/*" v-model="vidoesss" @change="videosAdded"></v-file-input>
                </div> 
            </div>
          </div>
        </div>
        </v-row>
        <v-zoomer class="zzoomerfull" v-if="dialog">
          <img :src= "img"
            style="object-fit: contain; width: 100%; height: 100%"
          />
        </v-zoomer>
        <v-icon v-if="dialog" class="closebuttons" @click="closetoggle"
          >mdi-window-close</v-icon
        >
        <v-card class="zzoomerfull" v-if="dialog1">
          <!--   <img :src="vidurl"
            style="object-fit: contain; width: 100%; height: 100%"
          /> -->
          <video
            class="videostyle"
            controls="true"
            style="object-fit: contain; width: 100%; height: 100%"
          >
            <source :src="vidurl" />
            Your browser does not support HTML5 video.
          </video>
          <!--  <iframe
            class="videostyle"
            style="object-fit: contain; width: 100%; height: 100%"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2uycjF6obCM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> -->
        </v-card>
        <v-icon v-if="dialog1" class="closebuttons" @click="closetoggle1"
          >mdi-window-close</v-icon
        >
      </v-col>
      <Preloader v-if="preloader" />
    </v-row>
    <v-dialog
        v-model="new_dir"
        max-width="500px"
         persistent
      >
        <v-card>
          <v-card-title>
            Add New Album
          </v-card-title>
          <v-card-text>
            
           <v-text-field
                  label="Enter Album Name*"
                  required
                  v-model="new_dir_name" 
                ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="new_dir = false"
            >
              Close
            </v-btn>
             <v-btn
                color="blue darken-1"
                text
                @click="save_directory()"
            >
            Save
          </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="progress" v-if="showprogrss" style="z-index: 100">
        <v-progress-circular :rotate="-90" :size="100" :width="15" :value="uploadPercentage" color="primary">
            {{ uploadPercentage }}
        </v-progress-circular>
        <v-btn color="red" @click="reloadpage">
            {{lang.Cancel}}
        </v-btn>
    </div>
  </div>
</template>   
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueSweetalert2 from "vue-sweetalert2";
import { locals } from "../../utils/locals";
import vueDropzone from "vue2-dropzone";
import vthumbnail from '../../assets/video_thumbnail.jpg'
export default {
  components: {
    Preloader,
    vueDropzone,
  },
  name: "MobileUpload",
  data() {
    return {
      preloader: true,
      mresponse: {},
      mres_dir_name:[],
      selected_dir:'',
      passdata:'',
      miresponse: {},
      dirdata: {},
      content: "",
      created: "",
      dir_name: "",
      img_src: "",
      imagetag: true,
      vidthumb: false,
      dialog: false,
      img: "",
      dialog1: false,
      vidurl: "",
      username: "",
      useremail: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      alert: false,
      uploadMedia: false,
      dropzone_box: false,
      activet:'normal',
      vidoesss:'',
      diropened:false,
      show: false,
        Local :locals,  
        lang : {},
        new_dir:false,
        new_dir_name:'',
        projectid:'',
        pro_dir: '',
        thumbnailimage: [],
        indd: 0,
        dir_id:'',
        u_id:'',
        c_at:'',
        uniquedates: {},
        showprogrss: false,
        uploadPercentage: 0,
        value: 0,
      dropOptions: {
                url: "https://api.opticvyu.com/api/mobile_upload/upload-media",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('token')
                },
                paramName: "file",
                maxFilesize: 100, // MB
                acceptedFiles: ".jpeg,.jpg,.png",
                maxFiles: 25,
                chunkSize: 500, // Bytes
                thumbnailMethod: "contain",
                thumbnailWidth: 200,
                thumbnailHeight: 150,
                autoProcessQueue: false,
                parallelUploads: 1,
                addRemoveLinks: true,
                dictDefaultMessage: "Click to upload Images <br/> ----------------- <br/>Drag & Drop",
            },
      dropOptionsvideo: {
                url: "https://api.opticvyu.com/api/mobile_upload/upload-media",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('token')
                },
                paramName: "file",
                maxFilesize: 1024, // MB
                acceptedFiles: ".mp4,.mkv,.avi",
                maxFiles: 4,
                timeout: 3000000,
                chunkSize: 500, // Bytes
                //timeout : 3000000,
                dictDefaultMessage: "Click to upload Videos <br/> ----------------- <br/>Drag & Drop",
            },
            
    };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    this.initViewer();
    this.getmobileupload_dir();
    //this.getmobiledata();
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getmobileupload_dir();
      //this.getmobiledata();
      console.log("camera parameters", this.currentcam);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      console.log('currentProject', currentProject);
      this.projectid = currentProject.id;
      this.pro_dir = currentProject.pro_dir;
      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
    },
  },
  computed: {
    sortedItems() {
      if(this.miresponse.length > 0){
      console.log("images", this.miresponse);
                    return this.miresponse.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                }else{
                  return false;
                }
    } 

  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
      this.preloader = false;

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.current_cam = this.$store.state.currentCamera;
      //console.log('camera parameters',  this.currentcam);
    },
    getmobileupload_dir() {
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      // console.log("current Camera", this.currentcam);
      axios({
        url:
          "project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/mobile-uploads",
        method: "GET",
      })
        .then((resp) => {
          console.log(resp.data.success);
          this.mresponse = resp.data.success;
          this.mres_dir_name = resp.data.success.filter(function (item) {
              console.log("filter data mres", item);
            return item.dir_name;
          }); 
          console.log("dir response", this.diropened);
          if (resp.data.success[0]) {
            this.alert = false;
            this.username = "";
            this.useremail = "";
            this.created = "";
            this.dir_name = "";
          } else {
            /*  this.snackbar = true;
                this.text = `No directory added under this project.`; */
            this.alert = true;
            this.alertmessage = this.lang.nodirectry;
            this.alertType = "error";
            this.username = "";
            this.useremail = "";
            this.created = "";
            this.dir_name = "";
          }
          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getmobiledata(data) {
      console.log("selected item", this.selected_dir);
      //window.location.href="/dashboard"
      this.passdata = this.selected_dir || data;
      this.dir_name = this.selected_dir.dir_name || data.dir_name;
      this.dir_id = this.selected_dir.id || data.id;
      this.u_id = this.selected_dir.user_id || data.user_id;
      this.c_at = this.selected_dir.created_at || data.created_at;
      this.currentcam = this.$store.state.currentCamera;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      this.preloader = true;
      this.dirdata = {
        id: this.dir_id,
        user_id: this.u_id,
        proj_dir: this.currentProject.pro_dir,
        dname: this.dir_name,
      };
      //var camid = this.currentcam.camera_id;
      console.log("current dir", this.dirdata);
      axios({
        url:
          "project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/get-mobile-uploads",
        data: this.dirdata,
        method: "POST",
      })
        .then((resp) => {
          //console.log(resp.data.success);
          this.miresponse = resp.data.success.imgs;
          console.log("img fun", this.miresponse);
          this.saved = resp.data.success.imgs;
          this.filterdate(this.miresponse);
          this.diropened = true;
          console.log("img response", this.diropened);
          this.content = resp.data.success.userd[0].email;

          var len = 0;
          if (this.miresponse != null) {
            len = this.miresponse.length;
          }
          if (len > 0) {
            this.username =
              resp.data.success.userd[0].firstname +
              " " +
              resp.data.success.userd[0].lastname;
            this.useremail = resp.data.success.userd[0].email;
            this.created = created_at;
            this.dir_name = directory_name;
            for (var i = 0; i < len; i++) {
              //this.$refs.custome_timelapsvideo.src = resp.data.success.archive_timelapse.data[i].timelapse_url;

              this.img_src = resp.data.success.imgs[i].img_url;
              //this.$refs.mobile_upload_image.src =  resp.data.success.imgs[i].img_url;
              console.log("imgsrc", resp.data.success.imgs[i].img_url);
            }
            var v = this;
            //this.preloader = false;
            setTimeout(function () {
              v.preloader = false;
            }, 5000);
          } else {
            this.preloader = false;
            this.snackbar = true;
             this.username = "";
            this.useremail = "";
            this.created = "";
            this.dir_name = directory_name;
            this.text = this.lang.noImages;
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    filterdate(m) {

            var a = ['All']

            m.forEach(i => {
                a.push(i.up_date);
            });

            let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
            //  console.log(unique);

            this.uniquedates = unique.sort(function (a, b) {
                var dateA = new Date(a),
                    dateB = new Date(b)
                return dateA - dateB //sort by date ascending
            })
        },
    selectDate(d) {

            this.preloader = true;

             console.log("slect date fun",d);
            //this.refresh++;
            var _self = this;

            if (d == 'All') {
                console.log("slect date if",d);
                _self.miresponse = [];
                setTimeout(() => {
                    _self.miresponse = _self.saved;
                    _self.preloader = false;
                }, 500)

            } else {
               console.log("slect date if",d);
                _self.miresponse = [];
                setTimeout(() => {
                    _self.miresponse = _self.saved.filter(function (item) {
                        return item.up_date == d;
                    }, 500)
                    _self.preloader = false;
                });
            }
        },
    openimage(url) {
      this.dialog = true;
      this.img = url;
    },
    closetoggle() {
      this.dialog = false;
    },
    openvideo(url) {
      this.dialog1 = true;
      this.vidurl = url;
    },
    closetoggle1() {
      this.dialog1 = false;
    },
    openUpload() {
        this.uploadMedia = true;
    },
     sendingEvent(file, xhr, formData) {

             this.userdata = JSON.parse(localStorage.getItem('userdata'));
              let currentProject = JSON.parse(localStorage.getItem("currentProject"));
              console.log('currentProject', currentProject);
            this.projectid = currentProject.id;
            this.pro_dir = currentProject.pro_dir;
            this.project = currentProject.project_name;
     
           var username =  this.userdata.firstname+" "+this.userdata.lastname;
           this.u_id = this.userdata.id;
           console.log("username", username);
        
            console.log("formData sending event", formData);
            formData.append("uploadedby", username);
            formData.append("user_email", this.userdata.email);
            formData.append("proj_dir", this.pro_dir);

            formData.append("project_id", this.projectid);
            formData.append("dir_id", this.dir_id);
            formData.append("dir_name", this.dir_name);
            formData.append("thumbnail", this.thumbnailimage[this.indd]);
            console.log("formdata indd", this.thumbnailimage);

           
            
            formData.append("type", this.activet);
             this.indd++;
             

            if (this.bulkupload) {
                this.uploadImages();
            }
        },
        videosendingEvent(file, xhr, formData) {
             this.userdata = JSON.parse(localStorage.getItem('userdata'));
            console.log("d", file);
            console.log("xhr", xhr);
            // console.log("formData", formData);
            formData.append("proj_dir", this.prefix);

            formData.append("project_id", this.projectid);

            formData.append("thumbnail", this.thumbnailimage[this.indd]);

           
            formData.append("type", this.activet);
              formData.append("uploadedby", this.userdata.firstname+" "+this.userdata.lastname);
        },
        thumbnail(file, dataUrl) {
            console.log("data url", file, dataUrl);
            this.thumbnailimage.push(dataUrl);

            console.log("this.thumbnailimage g", this.thumbnailimage);
           //this.imagesExtractMethos(file);

            //   radius funtion.
             //this.$refs.myVueDropzone.processQueue()
            var _self = this;
            setTimeout(() => {
                 _self.$refs.myVueDropzone.processQueue();
                
            }, 5000);
        },
        uploadImages() {
            var acceptedFiles = []
            if (this.activet == "normal") {
                acceptedFiles = this.$refs.myVueDropzone.getAcceptedFiles();
            } else if (this.activet == "panaroma") {
                acceptedFiles = this.$refs.myVueDropzone1.getAcceptedFiles();
            }

            console.log("fff", acceptedFiles.length);
            if (acceptedFiles.length == this.indd)
                var _self = this;
            setTimeout(() => {
               // this.review = true;
                _self.alert = true;
                _self.alertmessage = this.lang.reviewImagesandSubmit;
                _self.alertType = "error"

            }, 3000)

            //  this.$refs.myVueDropzone.processQueue();
            // this.$refs.myVueDropzone1.processQueue();    
        },
        checkerror(errorMessage){
          console.log("checking error", errorMessage);
        },
        successmsg(file, response){
              console.log("checking msg", response);
              this.snackbar = true;
              this.text = "Image uploaded successfully.";
             // this.thumbnailimage = [];
              var _self = this;
              setTimeout(() => {
                console.log("checking msg", response);
               // this.review = true;
                 this.uploadMedia = false;
                this.getmobiledata(this.passdata)
            }, 5000)

        },
    closeUpload() {
        this.bulkupload = false;
            this.uploadMedia = false;
            
            this.updateItems = [];

             if (this.activet == "normal") {
                 this.$refs.myVueDropzone.removeAllFiles();
            } else if (this.activet == "panaroma") {
                this.$refs.myVueDropzone1.removeAllFiles();
            }
          
           // this.this.review = false;
           console.log("updateItems",this.updateItems);
    },
    videosAdded() {
            //this.preloader = true;
            var fileItem = document.getElementById('fileItem');
            var files = fileItem.files;
            var file = files[0];

            this.value = 0;

            console.log("---", file);

            if (file.size > 0) {

                var _self = this;
                setTimeout(() => {
                    _self.getUrl(file);
                    _self.value = 20;
                    _self.showprogrss = true;
                }, 100);
                
            }
        },
        getUrl(file) {
            //console.log("File",  file);
            //  console.log("File Type", file.type);
            let currentProject = JSON.parse(localStorage.getItem("currentProject"));
              console.log('currentProject', currentProject);
            this.projectid = currentProject.id;
            this.pro_dir = currentProject.pro_dir;
            this.value = 30;
            axios({
                    url: 'mu/video/pre-signed-url',
                    method: 'post',
                    data: {
                        filename: file.name,
                        filetype: file.type,
                        proj_dir: this.pro_dir,
                        dir_name:this.dir_name,
                        project_id: this.projectid
                    }
                })
                .then(resp => {
                    this.value = 50;
                    //console.log("URL api data................", resp.data.success);
                    var action = resp.data.success;
                    this.sendVideo(action, file);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        sendVideo(action, file) {
            this.value = 70;
            var options = {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: progressEvent => {
                    var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
                    this.uploadPercentage = complete
                }
            };
            delete axios.defaults.headers.common["Authorization"]
            axios.put(action, file, options).then(resp => {

                console.log("Upload resp", resp);

                this.value = 90;

                const token = localStorage.getItem("token")
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token

                this.saveDataUploadData(action);

            }).catch(err => {
                console.log(err);
                this.alert = true;
                this.alertmessage = this.lang.SomethingWentWrong;
                this.alertType = "error"
                this.preloader = false
            })
        },
        saveDataUploadData(videoPath) {

            var path = videoPath.split('?');
             var username =  this.userdata.firstname+" "+this.userdata.lastname;
          
            axios({
                    url: '/mobile_upload/upload-video-media',
                    method: 'post',
                    data: {
                        proj_dir: this.pro_dir,
                        project_id: this.projectid,
                        media: path[0],
                        dir_id: this.dir_id,
                        dir_name: this.dir_name,
                        user_email: this.userdata.email,
                        uploadedby : username,
                    }
                })
                .then(resp => {
                  
                    this.value = 100;
                    console.log("URL api data................", resp);

                    this.showprogrss = false;
                    this.value = 0;
                    this.vidoesss = '';
                    this.snackbar = true;
                    this.text = "Video uploaded successfully.";
                    this.uploadMedia = false;
                    this.getmobiledata(this.passdata)
                })
                .catch(err => {
                    console.log(err);
                    this.snackbar = true;
                    this.text = this.lang.SomethingWentWrong;;
                    this.preloader = false
                    this.showprogrss = false;
                })
        },
        reloadpage() {
            this.$router.go();
        },
    uploadimg() {
        this.activet= 'normal';
    },
    uploadvdo() {
        this.activet= 'video';
    },
    /* imageUrlAlt(event, r){
      alert("hello");
        event.target.src = r.img_url;
    }, */
    open_add_dir(){
      this.new_dir = true;
    },
    save_directory(){
      this.preloader = true;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      console.log("get the dir name", this.new_dir_name);
       console.log("get the dir name", this.currentProject);
       if (this.new_dir_name){
                 this.preloader = true;
                    console.log("testing variable");
                axios({
                    url: '/project/' + currentProject.id +'/mobile-upload/add_dir',
                    method: 'post',
                    data: {
                        proj_dir: this.currentProject.proj_dir,
                        projectname: this.project_name,
                        project_id: this.projectid,
                        userid: this.userdata.id,
                        usermail:this.userdata.email,
                        newdir_name:this.new_dir_name,
                        
                    }
                })
                .then((resp) => {
                     console.log("dir added", resp.data.success);
                    if (resp.data.success){
                       this.preloader = false;
                        this.new_dir = false;
                   this.snackbar = true;
                  this.text = 'New Directory added successfully.';
                      setTimeout(()=>{
                       // this.getCartimg();
                       //this.$router.go();
                       this.getmobileupload_dir();
                    }, 2000);
                    }else{
                      this.preloader = false;
                      this.snackbar = true;
                      this.text = resp.data.error;
                      setTimeout(()=>{
                        //this.$router.go();
                        this.getmobileupload_dir();
                      }, 2000);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = "Something is going wrong. Please try again.";
                    this.alertType = "error";
                    this.preloader = false;
                    this.new_dir = false;
                })
        
              }else{
                this.preloader = false;
                this.snackbar = true;
                this.text = "Please enter directory name";
              }

    },
  },
};
</script>
<style>
.diricon {
  font-size: 62px !important;
  color: #ffc400 !important;
}
.c_pointer {
  cursor: pointer;
}
.content {
  margin-top: 0.5%;
}
.minfo {
  color: #f8bb03;
}
.minfoSpan {
  position: absolute;
  right: 2%;
  top: 6%;
}

#mdrop1,
#mdrop2,
#mdrop3 {
    height: 430px;
    padding: 40px;
    background: #efeeea;
}
#mdrop1 .dz-preview,
#mdrop2 .dz-preview,
#mdrop3 .dz-preview {
    width: 160px;
}
.dir_scroll {
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}
.midropdatess{
  float: right;
  width: 150px;
}
.v-progress-circular {
    margin: 1rem;
}

.progress {
    position: fixed;
    margin: 0;
    display: flex;
    text-align: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0000000d;
}
.mi_overlay_bottom{
  background: #00000069;
    color: #ffffff;
    text-align: center;
    font-weight: bolder;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 4px 0;
   }
.vhfix::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
.vhfix::-webkit-scrollbar-track {
  background: rgb(229 228 225);        /* color of the tracking area */
}
.vhfix::-webkit-scrollbar-thumb {
  background-color: #292929;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #292929;  /* creates padding around scroll thumb */
}
.uploadMedia1 {
    display: block;
    width: 100%;
    height: 100%;
    padding: 3% 0;
    background: #000000ab;
    position: fixed;
    top: 20px;
    left: 0;
}
</style>
<template>
    <div>
        <!-- <Preloader v-if="preloader" /> -->
        <div class="maincard">

            <div class="card-box">

                <div class="haadtileff">

                
                     <p class="poppins-semibold projecttitle" >  <v-list-item-avatar color="#333399"
                v-on="on"
                size="29" 
                class="backbutton"
                @click="goback"
                >
               <v-icon dark>
                mdi-arrow-left
              </v-icon>
              </v-list-item-avatar> <span @click="goback">Projects</span>  <span class="bredcrum"> / {{projectname}} </span> </p>

                </div>
                <div class="filter">
                </div>
                <div class="card-inner-box">
                    <div class="module-row">
                      <div class="module-item" :class="currentProject.const_camera == 1?'active':'deactive'">
                           <div class="inneritem-module" >
                            <img :src="image3" class="iconmodule">
                            <p class="modulenamet poppins-medium"> Artificial Intelligence Based Cameras</p>
                            <p class="detailsl poppins-light"> For Construction Analytics </p> 
                           
                            <span v-if="camerspopup.length > 0 && currentProject.const_camera == 1">
                <span v-for=" (cc, cindex ) in camerspopup.slice(0, 5)" class="popcamitemtt" :key="cc.camera_id">
                  <v-icon  :title="`CAM `+ (cindex+1)" @click="selectConstructionwithCame(cc.camera_id,  cindex, 'single')"> mdi-camera-image </v-icon>
               </span>
               <span v-if="camerspopup.length > 1" class="popcamitemtt" style="color:#fff;position:relative;top:0px; font-weight:bold">
                <span class="poppins-medium" @click="selectConstructionwithCame('', 0, 'all')"> All </span>
                 <!-- <button class="moduleb tbutton poppins-medium"  v-if="currentProject.const_camera == 1"  @click="selectConstruction()"> View All  </button> -->
            </span>
             </span>
                        </div>
                      </div>

                      <div class="module-item" :class="currentProject.interior == 1?'active':'deactive'">
                           <div class="inneritem-module" >
                            <img :src="image2" class="iconmodule">
                            
                            <p class="modulenamet poppins-medium"> 360° Photo/Video tool for Digital Surveillance </p>
                            <p class="detailsl poppins-light">For Digital Surveillance </p> 
                            <button class=" moduleb tbutton poppins-medium" :class="currentProject.interior == 1?'active':'deactive'" v-if="currentProject.interior == 1"  @click="selectInterior()"> View All  </button>
                           </div>
                      </div>

                      <div class="module-item" :class="currentProject.linear_monitoring == 1?'active':'deactive'">
                           <div class="inneritem-module">
                            <img :src="image1" class="iconmodule">
                          
                            <p class="modulenamet poppins-medium"> Construction Drone Photography  </p>
                            <p class="detailsl"> For Progress Monitoring  </p> 
                            <button class="moduleb tbutton poppins-medium"  :class="currentProject.linear_monitoring == 1?'active':'deactive'"  @click="selectLinear()" v-if="currentProject.linear_monitoring == 1"> View All </button>
                           </div>
                      </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
//import Preloader from '../../components/PreLoader'
import image1 from  "../../assets/tata/dronicon.png";
import image2 from  "../../assets/tata/interioricon.png";
import image3 from  "../../assets/tata/constructionicon.png";
import axios from "axios";
 
export default {

    name: "Module",
    data() {
        return {
            image1:image1,
        image2:image2,
        image3:image3,
        activeproarray:[],
        items : [],
        projectname : '',
        currentProject : '',
        apdata : '',
        camerspopup : [],
        camers :[],
        ap: "",
        ac: "",
        i: 0
        }
    },
    created() {

        this.getPorjects();
        
    },
    mounted() {
        this.getPorjects();
    },
    methods: {
        getPorjects: function () {
      this.activeproarray = [];
      
      axios({
        url: "/project",
        method: "GET",
      })
        .then((resp) => {
          //this.userdata = JSON.parse(localStorage.getItem('userdata'))
          // console.log("userdata=>>>", this.userdata);
         
          

         // console.log("current Projectssss", this.activeproarray);
         var p = new URL(location.href).searchParams.get("p");

          this.ap = p;

          if (this.ap) {
            var v = this;
            this.apdata = resp.data.success.filter(function (item) {
              return item.id == v.ap;
            });

             console.log(" this.apdata ",  this.apdata );

            this.initialSetup(this.apdata[0]);
            
            this.projectname = this.apdata[0].project_name;
            this.currentProject = this.apdata[0];
          
          } 
          console.log("items.length", this.items.length);
         
        })
        .catch((err) => {
          console.log(err);
        });
    },
        initialSetup(initialidata) {
       console.log("initialidata", initialidata);
      localStorage.setItem("currentProject", JSON.stringify(initialidata));
      //this.getCurrentProjectData('init');
     this.getCurrentProjectCams(this.apdata[0].id)
     },
     selectConstruction() {
        window.location.href = "/dashboard";
    },
    selectInterior(){
        window.location.href = "/dashboard/user-interior-view";
      
    },
     selectLinear(){

        window.location.href = "/dashboard/gps-map-view"
 
        }, 
        goback(){
            window.location.href = "/tpls"
        },
        getCurrentProjectCams(id) {
                   
              
                   if(id != this.cid){
       
                 this.cid = id;
                   
               //  console.log("mouse over",id);
                  
             //console.log(this.currentProject);
            
             this.camerspopup = [];
             axios({
               url: "/camera/" + id,
               method: "GET",
             })
               .then((resp) => {
       
                //   var a = 0;
                //  var b = 1;
                 this.camerspopup = resp.data.success.filter(function (item) {
              
                   return item.camera_active == 1;
                 }); 
                 
               })
               .catch((err) => {
                 console.log(err);
                 this.preloader = false;
               });
                   }
           },
           selectConstructionwithCame: function (camid, cindex, movetype ) {
        // console.log("camid",projectdata);
          this.ac = camid;
           this.i = cindex;
       // localStorage.setItem("projectIndex", index);
      
      
        this.$store.dispatch("curentModule", "construction_camera");
        

      this.getCurrentProjectData(movetype);
    },
    getCurrentProjectData(init) {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      // console.log(this.currentProject);

         if(init == "all"){
             window.location.href = "/dashboard/splitscreen";
           } 

      this.camers = {};
      axios({
        url: "/camera/" + currentProject.id,
        method: "GET",
      })
        .then((resp) => {
          var currentcam = localStorage.getItem("currentCamIndex");
          this.preloader = false;
          var a = 0;
          var b = 1;
          
         

          this.camers = resp.data.success.filter(function (item) {
            if (item.cam_name == "ov") {
            item.cam_name = "";
          } else {
            console.log("camname defined", item.cam_name);
          }
            item.camName = item.pro_cam_serial + " " + item.cam_name;
            item.camNames = "CAM " + b + " " + item.cam_name;
            //this.sup_camid = "CAM " + b + " " + item.cam_name;
            item.iindex = a;
            a++;
            b++;
            return item.camera_active == 1;
          }); 

          //console.log("Cameras", this.camers);

          if (this.ac) {

             // console.log("this.ac",this.ac);
            var v = this;
            // var x = 0;
            this.acdata = this.camers.filter(function (item) {
              return item.camera_id == v.ac;
            });
            localStorage.setItem("cid", this.acdata[0].camera_id);
            this.setCamera(this.acdata[0]);
            localStorage.setItem("currentCamIndex", this.i);
            this.camid = parseInt(this.i);
            // console.log("this.acdata ",this.acdata, x);
          } else {
            if (currentcam === null) {
              //  console.log("in if");
              localStorage.setItem("cid", this.camers[0].camera_id);
              this.setCamera(this.camers[0]);

              localStorage.setItem("currentCamIndex", 0);
              this.camid = 0;
            } else {
              // console.log("currentcam",currentcam);
              localStorage.setItem("cid", this.camers[currentcam].camera_id);
              localStorage.setItem("currentCamIndex", currentcam);
              this.setCamera(this.camers[currentcam]);

              this.camid = parseInt(currentcam);

              //   console.log("in else > ", this.camers );

              // this.preloader = false;

              if (currentcam === "undefined") {
                //   console.log("in else >>>> ", this.camers[currentcam]);
                localStorage.setItem("cid", this.camers[0].camera_id);
                this.setCamera(this.camers[0]);

                localStorage.setItem("currentCamIndex", 0);
                this.camid = 0;
              }
            }
          
          }
          
           window.location.href = "/dashboard";
          //console.log("This is current camera", this.camers[0]);
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    setCamera(data) {
      this.$store
        .dispatch("setCamera", data)
        .then(() => console.log("camera set data"))
        .catch((err) => console.log(err));
    },
    }
}
</script>
<style>
.bredcrum{
  color:#B5B5B5;
}
.module-item {
    border-radius: 21px;
    width: 33.33%;
    display: inline-block;
    padding: 26px;
    cursor: pointer;
    transition: 0.5s;
}

.inneritem-module {
    width: 100%;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    display: block;
    text-align: center;
    padding: 37px;
    min-height: 284px;
}
.module-item:hover .inneritem-module {
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 30px #33339926;
transition: 0.5s;
}
.moduleb{
    opacity: 0;
    transition: 0.5s;
}
.module-item:hover .moduleb {
    opacity: 1;
    transition: 0.5s;
}
.module-row {
    padding: 30px;
    background: #fff;
    border-radius: 14px;
    display: flex;
}

img.iconmodule {
    width: 75px;
    margin: auto;
    text-align: revert;
}
p.detailsl {
    font-size: 18px;
    font-family : "NexaRustScriptL";
    color: #333399;

}
.moduleb{
    background: #333399 0% 0% no-repeat padding-box;
    border-radius: 13px;
    padding: 5px 12px;
}
.deactive {
    opacity: 0.5;
    pointer-events: none;
}
.backbutton {
    /* position: absolute; */
    /* top: 23px; */
    left: -7px;
    cursor: pointer;
    margin: 0;
}
.haadtileff {
    width: 40%;
    float: left;
}
.popcamitemtt {
    margin: 0 2px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    transition: 0.5s;
}
.popcamitemtt span, .popcamitemtt .v-icon {
    color: #000 !important;
}
.popcamitemtt:hover {
    box-shadow: 0 0 20px 0px #ffc400;
    border: 1px solid #ffc400;
    background: #000;
    transition: 0.5s;
}
.popcamitemtt:hover {
    box-shadow: 0 0 20px 0px #33339a;
    border: 1px solid #33339a;
    background: #33339a;
    transition: 0.5s;
}
.popcamitemtt:hover .v-icon {
    color: #ffffff !important;
}
.popcamitemtt:hover span {
    color: #fff !important;
}
</style>
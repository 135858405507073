<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class=""
          
        >
   <v-btn
      color="#f8bb03"
      class="black--text procore tab active"
     @click="setrouter('/dashboard/settings/integration/procore_connection')"
    >
      {{lang.ConnectwithProcore }}
    
    </v-btn>

    <v-btn
      color="#f8bb03 "
      class="black--text bimclass tab"
      @click="setrouter('/dashboard/settings/integration/bim360')"
    >
      {{lang.ConnectWithBim360}}
    </v-btn>

  

      <div  class="pa-5" v-if="procorProjects.length == 0"> {{lang.procoreconnection}} </div>
          

       <div class="procorebuttons" v-if="procorProjects.length == 0">
          
            <a  v-if="procorProjects.length == 0"  class="procoreconnect" :href="`https://login.procore.com/oauth/authorize?response_type=code&client_id=ac5040078c47759818c833aabc328ddc237142d001de0f9ef6f5281276fdaaa5&
redirect_uri=${redirect_url}`"
                  > {{lang.ConnectwithProcore}} </a>
          </div>
           
          
             
           <div v-if="procorProjects.length > 0" class="projectsbox">
               <v-row align="center">
                 <div  class="pa-6"> 

                  <v-btn
         class="mt-5"
       color="success"
    >
     {{lang.ConnectwithProcore}} 
    
    </v-btn>    
          
     <p  class="mt-5">
        For more information on OpticVyu - Procore Integration, click <a href="https://blog.opticvyu.com/opticvyu-procore-integration-steps/" target="_blank">here.</a>
     </p>
     
    </div>
    
      </v-row>
            <table v-if="projectSearchdata.length > 0" class="table">
                <tr>
                   <th> {{ lang.procoreconnection }}</th>
                   <th> {{ lang.opticvyuProject }} </th>
                   <th> {{ lang.Action }} </th>
                </tr>
               <tr v-for="(i, index ) in projectSearchdata" :key="i.id">
                   <td ><strong>{{i.project_name}}</strong></td>
                   <td>
                  <select class="form-control selectoptns" @change="slectedProjects($event)">
                  <option value="" selected disabled> {{lang.selectprocoreproject}} </option>
                  <option v-for="procore in procorProjects" :value="index+'_'+i.id+'_'+procore.project_id+'_'+procore.company_id" :key="procore.id">{{procore.company_name}} - {{ procore.projet_name }}</option>
                  </select>
                   </td>
                   <td>
                    <v-switch @change="remove(index)"  v-model="switches" v-if="switches.includes(index+'_'+i.id)" :value="index+'_'+i.id"></v-switch>
                   </td>
               </tr>
            </table>

                    
         </div>
             <div class="procorebuttonss" v-if="switches.length > 0 ">
            <v-btn
      class="ma-2 procorec"
      :loading="loading"
      :disabled="loading"
      @click="connectingProccess"
      
    >
      {{lang.configprocore}}
    </v-btn>
    </div>
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
      <v-snackbar
      v-model="snackbar"
      top
      right
      timeout="3000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar
  },
  name: "Procoreconnection",
  data() {
    return {
         preloader: false,
         company :'',  
         code : '',
         projectSearchdata : [],
         procorProjects : [],
         switch1 : false,
         connections : [],
         switches : [],
         loading : false,
         coonecount : 0,
         count : 0,
         procore_url: process.env.VUE_APP_PROCORE_URL,
         redirect_url: process.env.VUE_APP_PROCORE_REDIRECT_URL,
         refreshtoken : '',
         companies : {},
         company_selected  :{},
         snackbar : false,
         text : '',
         Local :locals,  
         lang : {}, 
          
         
     };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.initViewer();

     this.userdata = JSON.parse(localStorage.getItem('userdata')); 
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
  },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
    
  }
  },
    computed: {
  
  }, 
  methods: {
   
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
      
     //  console.log(this.$router.history.current.path);
     var currentProject = JSON.parse(localStorage.getItem('currentProject'));
        var p = localStorage.getItem('procer_project');
        console.log(p);
         if(currentProject.procore_status == 1){
             var url = "/dashboard/settings/integration/procore_update";
              this.$router.push(url);
         } 
    
      var code =localStorage.getItem('pc_token');
       var refreshtoken =localStorage.getItem('pc_refreshtoken');

     //  console.log(code);
          this.refreshtoken = refreshtoken;
          this.code = code;
          

         //  console.log(procore_cid);
            this.getCompany();

          

    },
    select_comapnay(value){

      console.log("this.company_selected", this.company_selected);
      console.log("selected company", value);
      localStorage.setItem('company_id',value);

      this.company = value;
       this.GetProcoreProjects();
        this.getPorjects();
    },
    getCompany(){
              this.preloader = true;
            var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: '/procore/getCompany',
        data : {token:this.code}
      };
      axios(config)
       .then(resp => {

            console.log(resp.data);
   
            this.companies = resp.data;
             this.getPorjects();
             resp.data.forEach(c => {

               if(c.is_active){
                     this.GetProcoreProjects(c);
                     
               }
               
             });

          //      if(resp.data[0].is_active){
          //      localStorage.setItem('company_id', resp.data[0].id);
          //      this.company_selected = resp.data[0];

          //      this.company = localStorage.getItem('company_id');

          //  if(this.company){ 
          //      this.GetProcoreProjects();
          //      this.getPorjects();
          //  }
            
          //    }
           })
          .catch(err => {
            console.log(err);
            this.preloader = false
          })
    },

    GetProcoreProjects(company){
       
        
       // console.log(this.code);

       

         var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: '/procore/getproject',
        data : {token:this.code, company:company.id} 
      };
      axios(config)
       .then(resp => {
          
                this.preloader = false;
               console.log("Procore projects", resp.data);

              //this.procorProjects = resp.data;
               
               resp.data.forEach(proj => {
                    this.procorProjects.push({
                     project_id: proj.id, 
                     projet_name: proj.display_name,
                     company_id: company.id, 
                     company_name: company.name
                  });
               });

                 console.log(this.procorProjects);
           })
          .catch(err => {
            console.log(err);
            this.preloader = false
          })
    },
    getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                // if(item.procore_status == 1){

                //      //localStorage.setItem('procer_project', item.id);
                //       var url = "/dashboard/settings/integration/procore_update";
                //       this.$router.push(url); 

                // }
                return item.project_active == 1;         
              });

              this.projectSearchdata = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              })
              console.log(this.projectSearchdata);
          })
          .catch(err => {
            console.log(err);
          })
      },
      slectedProjects(event){
         // console.log("ok");
          var data = event.target.options[event.target.options.selectedIndex].value;

           var res = data.split("_");
           var a  = {"opticp":res[1], "procorep":res[2], "procore_c_id": res[3]};
           this.connections[res[0]] = a;
           var ind = res[0]+"_"+res[1];
           this.switches.push(ind);
             
           console.log("slectedProjects",this.connections);
  
      },
      remove(item){
    
              delete this.connections[item];
            
      },
      connectingProccess(){
            this.loading = true;
            this.preloader = true;
                 var cc = 1;
             this.connections.forEach( ()=> {

                 
                   cc++;

                   this.count = cc;
             })


            console.log("slectedProjects",this.connections);
          var coonecount = 1;

              var d = new Date();
              var n = d.getTime(); 

            this.connections.forEach(item => {

                  console.log("Selected project item", item);

                 var d = {
                     
                     "proj_id": item.opticp,
                     "procore_proj_id": item.procorep,
                     "procore_token": this.code,
                     "refresh_token": this.refreshtoken,
                     "procore_comp_id": item.procore_c_id, 
                     "procore_unick_id": n,
                 };

                    axios({ url: '/project/'+item.opticp+'/procore_project_update', params : d, method: 'GET' })
          .then(resp => {
                console.log(resp);
                this.getCammeras(item.opticp, item.procorep, item.procore_c_id);
               

          })
          .catch(err => {
            console.log(err);
          })
               coonecount++;

           this.coonecount = coonecount;
            });
      },
       getCammeras(opticvyu_p_id, procore_proj_id, procore_c_id){
       axios({ url: '/camera/'+opticvyu_p_id, method: 'GET' })
          .then(resp => {
               
               

               var camdata = resp.data.success;
                var kk = (Math.random() + 1).toString(36).substring(7);

              //   var date = new date();
              //  var milliseconds = date.getTime(); 
               
                var impconfig = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/image_categories?project_id='+procore_proj_id,
        data : {
            "image_category": {
              "name": "Important-"+kk
            }
          },
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(impconfig)
       .then(resp => {
               
                   console.log("imp album data ", resp);  

                    this.getParentFolder(resp, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                    
                })    

         })
       },
       getParentFolder(impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id){

            var impconfig = {
        method: 'GET',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/folders?project_id='+procore_proj_id,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(impconfig)
       .then(resp => {
               
                   console.log("imp album data ", resp);  

                   // this.addFolder(resp, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                    this.addFolder(resp, impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                    
                }) 

           
       },
       addFolder(pfresp, impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id){

            // var date = new date();
            // var milliseconds = date.getTime(); 

            var j = (Math.random() + 1).toString(36).substring(7);

            var impconfig = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/folders?project_id='+procore_proj_id,
          data : {
            "folder": {
              "name": "OpticVyu Camera Reports-"+j,
              "parent_id" : pfresp.id
            }
          },
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(impconfig)
       .then(resp => {
               
                   console.log("imp album data ", resp);  

                   // this.addFolder(resp, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                    this.camloop(resp,impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id);
                    
                }) 

           
       },
       camloop(folder,  impdata, camdata, procore_proj_id, opticvyu_p_id, procore_c_id){

           //console.log("camloop running",camdata);
             var b = 1;
              


            camdata.forEach(a => {

            //    var date = new date();
             var mm = (Math.random() + 1).toString(36).substring(7);

              console.log("camloop running", a);


                         if(a.camera_active){
                             
                               var config = {
        method: 'POST',
        // url: 'https://sandbox.procore.com/vapid/projects?company_id=30751',
        url: this.procore_url+'/image_categories?project_id='+procore_proj_id,
        data : {
             "image_category": { 
              "name": "Timeline Cam-"+b+"-"+mm
            }
          },
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.code,
          'Procore-Company-Id': procore_c_id
        }
      };
      axios(config)
       .then(resp => {
               
                   console.log("Timelapse album data",resp);    
                   
                   this.addAlbum(folder, resp ,impdata, a, opticvyu_p_id,procore_proj_id, procore_c_id)
                })
                 b++;
               }
            });

       },
      addAlbum(folder, albumdata,impdata, camdata, opticvyu_p_id, procore_proj_id, procore_c_id){
           
                     console.log("folder data", folder.data.id)
              
               var dd = {
                     "opticvyu_pro_id": opticvyu_p_id,
                     "procore_comp_id":  procore_c_id,
                     "procore_proj_id": procore_proj_id,
                     "cam_id": camdata.camera_id,
                     "imp_album_id": impdata.data.id,
                     "timeline_album_id" : albumdata.data.id,
                     "folder_id" : folder.data.id
                 };

                    axios({ url: '/project/'+opticvyu_p_id+'/add_procore_album', params : dd, method: 'GET' })
          .then(resp => {
                console.log(resp);
                console.log("save album data ----> ", this.count, this.coonecount );

                if(this.count == this.coonecount){
                            this.loading = false;
                            this.preloader = false;
                            this.text = this.lang.procoreConnectionSuccess;
                            this.snackbar = true;
                             var currentProject = JSON.parse(localStorage.getItem('currentProject'));
                           currentProject.procore_status = 1;
                           var initialidata =  currentProject;
                           localStorage.setItem("currentProject", JSON.stringify(initialidata));
                          this.$router.push("/dashboard/settings/integration/procore_update");
                          // window.location.reload(true)
                }
          })
          .catch(err => {
            console.log("save album error",err);
          })
      },
      setrouter: function (url) {
        if(this.$route.path !== url){
             this.$router.push(url)
          //console.log("current router ", this.$route.path);
      //  this.activelink = this.$route.path;
        }
         
          //console.log(url);
      }
  }
};
</script>
<style>
.procorebuttons {
    
    margin: 50px;
    height: 49vh;
}
.procorebuttonss{
  text-align: center;
}
a.procoreconnect {
    text-decoration: none;
    text-align: center;
    padding: 23px 14px;
    display: block;
    background: #ffc400;
    width: 198px;
    margin: auto;
    color: #000;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0px 2px 19px 0px #0000006e;
    border: 1px solid #efb804;
}
.table{
  max-width: 1200px;
  margin:auto;
}
.table, td, th {  
  text-align: left;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 5px 15px;
}
.selectoptns {
    border: 1px solid #ccc;
    width: 250px;
    padding: 6px;
}
.projectsbox {
    height: 78vh;
    overflow: auto;
        overflow-x: hidden;
}
.procorec {
    text-align: center;
    background-color: #ffc400 !important;
    color: #000 !important;
    margin: auto !important;
}
.tab {
    width: 50%;
    margin: 0;
    border-radius: 0;
}
.tab.active {
 border-bottom: 2px solid #000;
}
</style>


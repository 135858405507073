<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="pa-5">
      <v-row>
        <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
          <h2 class="hetitle">{{ lang.Graphs }}</h2>
        </v-col>
        <v-col class="datepicker" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
        
            <!-- <ejs-daterangepicker
            :change="chartDataExport"
            :minDays="min"
            :maxDays="max"
            :strictMode="mode"
          >
          </ejs-daterangepicker> -->

          <date-range-picker
          @update="chartDataExport"
          :ranges = "false"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
    v-model="reportdate"
    :date-format="dateFormat"
  ></date-range-picker>
        
          
          <p style="color:#d4425b;font-size:11px">
            Download AQI Report by Selecting Date
          </p>
          <v-dialog v-model="timeslotloader" persistent width="300">
            <v-card :color="activecolor" dark>
              <v-card-text>
                {{ lang.Pleasestandby }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col class="datepicker" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
          
            <!-- <ejs-daterangepicker
            :change="onValueChange"
            :minDays="min"
            :maxDays="max"
            :strictMode="mode"
            :placeholder="lang.selectRange"
          >
          </ejs-daterangepicker> -->
          <date-range-picker
          @update="onValueChange"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      v-model="chartdate"
      :date-format="dateFormat"
  ></date-range-picker>
          
          <p style="color:#d4425b;font-size:11px">{{ lang.max7 }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="result == 0">
          <p style="color:red;text-align:center">{{ lang.last7days }}</p>
        </v-col>
        <v-col cols="12">
          <div id="chartbar" v-if="showChart">
            <apexchart
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-col>
        <v-divider></v-divider>
        <br />
        <v-col cols="12">
          <div id="chart" v-if="showChart">
            <apexchart
              type="bar"
              height="350"
              :options="pm10chartOptions"
              :series="pm10series"
            ></apexchart>
          </div>
        </v-col>

        <v-col cols="12">
          <div id="chart" v-if="showChart">
            <apexchart
              type="bar"
              height="350"
              :options="pm25chartOptions"
              :series="pm25series"
            ></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <br />
      <table class="table panel panel-default table-hover">
        <thead>
          <tr class="active">
            <th class="col-md-1 text-center">AQI (µg/m3)</th>
            <th class="col-md-2 text-center">{{ lang.Remark }}</th>
            <th class="col-md-1">{{ lang.ColorCode }}</th>
            <th class="col-md-7">{{ lang.PossibleHealthImpacts }}</th>
            <th class="col-md-1"></th>
          </tr>
        </thead>
        <tbody class="aqi-desc-container">
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">0-50</td>
            <td class="col-md-2   text-center text-middle">{{ lang.Good }}</td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(0, 176, 80);"
            ></td>
            <td class="col-md-8 desc">{{ lang.Minimalimpact }}</td>
          </tr>
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">51-100</td>
            <td class="col-md-2   text-center text-middle">
              {{ lang.Satisfactory }}
            </td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(146, 208, 80);"
            ></td>
            <td class="col-md-8 desc">{{ lang.Minorbreathing }}</td>
          </tr>
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">101-200</td>
            <td class="col-md-2   text-center text-middle">
              {{ lang.Moderate }}
            </td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(255, 255, 0);"
            ></td>
            <td class="col-md-8 desc">{{ lang.breathing1 }}</td>
          </tr>
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">201-300</td>
            <td class="col-md-2   text-center text-middle">{{ lang.poor }}</td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(255, 153, 0);"
            ></td>
            <td class="col-md-8 desc">{{ lang.breathing2 }}</td>
          </tr>
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">301-400</td>
            <td class="col-md-2   text-center text-middle">
              {{ lang.VeryPoor }}
            </td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(255, 0, 0);"
            ></td>
            <td class="col-md-8 desc">{{ lang.breathing3 }}</td>
          </tr>
          <tr class="" style="">
            <td class="col-md-1 aqi-value text-center text-middle">401-500</td>
            <td class="col-md-2   text-center text-middle">
              {{ lang.Severe }}
            </td>
            <td
              class="col-md-1 color-code"
              style="background-color: rgb(192, 0, 0);"
            ></td>
            <td class="col-md-8 desc">{{ lang.breathing4 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
//import Vue from "vue";
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueApexCharts from "vue-apexcharts";
import { locals } from "../../utils/locals";
//import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
// import downloadexcel from "vue-json-excel";
// import JsonExcel from "vue-json-excel";
//import lodash from 'lodash'
//Vue.use(DateRangePickerPlugin);
export default {
  components: {
    Preloader,
    // downloadexcel,
    apexchart: VueApexCharts,
    DateRangePicker
  },
  name: "AqiData",
  data() {
    return {
      Local: locals,
        lang: {},
      currentcam: {},
      preloader: false,
      showChart: true,
      preshowChart: false,
      currentProject: {},
      records: {},

      dates: [],

      time3: "",
      max: "360",
      min: "1",
      waterMark: "Select a Range",
      mode: true,
      menu2: false,
      aidate: "",
      firstnamerules: [v => !!v || "This Field is required"],
      valid: true,
      presetid: "",
      image_type: "All",
      ai_data_images: [],
      dialog: false,
      img: "",
      personsWithRequiredPPET: 0,
      WithoutRequiredPPET: 0,
      UnrecognizedT: 0,
      selectbarIndex: 0,
      selectbar: 0,
      series: [
        {
          name: "PM10",
          data: []
        },
        {
          name: "PM2.5",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        stroke: {
          width: [3, 3],
          curve: "straight"
        },
        labels: [],
        title: {
          text: "AQI Data (µg/m3)"
        },
        xaxis: {},
        colors: ["#FF1654", "#247BA0"]
      },
      result: 0,
      pm10series: [
        {
          name: "AQI",
          data: []
        }
      ],
      pm10chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "bottom" // top, center, bottom
            }
          }
        },
        colors: [
          function({ value }) {
            //  console.log(value);
            if (value >= 0 && value < 51) {
              return "#00b050";
            } else if (value >= 51 && value < 101) {
              return "#92d050";
            } else if (value >= 101 && value < 201) {
              return "#ffff00";
            } else if (value >= 201 && value < 301) {
              return "#ff9900";
            } else if (value >= 301 && value < 401) {
              return "#ff0000";
            } else if (value >= 401) {
              return "#c00000";
            } else {
              return "#c00000";
            }
          }
        ],
        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true
          }
        },
        title: {
          text: "AQI PM10 (µg/m3)",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444"
          }
        }
      },

      pm25series: [
        {
          name: "Inflation",
          data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }
      ],
      pm25chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "bottom" // top, center, bottom
            }
          }
        },
        colors: [
          function({ value }) {
            // console.log(value);
            if (value >= 0 && value < 51) {
              return "#00b050";
            } else if (value >= 51 && value < 101) {
              return "#92d050";
            } else if (value >= 101 && value < 201) {
              return "#ffff00";
            } else if (value >= 201 && value < 301) {
              return "#ff9900";
            } else if (value >= 301 && value < 401) {
              return "#ff0000";
            } else if (value >= 401) {
              return "#c00000";
            } else {
              return "#c00000";
            }
          }
        ],
        dataLabels: {
          enabled: false
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },

          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true
          }
        },
        title: {
          text: "AQI PM 2.5 (µg/m3)",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444"
          }
        }
      
      },
      json_fields: {
        ID: "id",
        CamID: "camid",
        PM25: "PM25",
        PM10: "PM10",
        "Updated-at": "created_at"
      },
      chartExportData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],

      timeslotloader: false,
      activecolor: "amber accent-3",
      reportdate: {},
      chartdate: {},
     
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    this.currentcam = this.$store.state.currentCamera;

    document.body.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        this.dialog = false;
      }
    });
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    if (this.currentcam.aqi == 0) {
      this.$router.push("/dashboard");
    }
  },
  created() {
    this.initViewer();
  },
  watch: {
    "$store.state.currentCamera": function() {
      this.currentcam = this.$store.state.currentCamera;

      if (this.currentcam.aqi == 0) {
        this.$router.push("/dashboard");
      }

      // console.log("this.currentcam =", this.currentcam);
      //this.getCurrentProjectData('');

      // this.presets = this.$store.state.currentCamera.preset_ids.split(",");

      this.initViewer();
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    params() {
      return {
        aidate: this.aidate,
        presetid: this.presetid,
        image_type: this.image_type
      };
    }
  },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
     
      CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 2);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

           this.reportdate = {
            startDate : start,
            endDate : end
           }
            this.chartdate = {
              startDate : start,
               endDate : end
            }

      },
    initViewer() {
      //console.log("init Viewer");

         this.CurrentDateFunction();


      this.currentcam = this.$store.state.currentCamera;
      this.preloader = true;

      this.getCurrentProjectData("");
    },

    onValueChange: function(args) {
      // var ss =  args.value.toLocaleDateString();

       console.log(args);

      if (args.startDate) {
        this.showChart = false;
        this.preloader = true;

        this.getCurrentProjectData(args);
      }
    },

    getCurrentProjectData(args) {
      // Moduletype variable store value of marial, interior or constructuion cameras.

      // args.value[0].toLocaleDateString(), args.value[1].toLocaleDateString()
      this.showChart = false;
      var start = "";
      var end = "";
      let currentcam = this.currentcam.camera_id;

      var dataurl = "/aiq/" + currentcam + "/seven-days-record"; //'http://ai.opticvyu.com:3000/ppe-f-cw/'+currentcam+'/1'

      if (args) {
       
       var checkstart = new Date(this.chartdate.startDate);
       var checkend = new Date(this.chartdate.endDate);


        start = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
        end = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

        let Difference_In_Time =  checkend.getTime() - checkstart.getTime();

// Calculating the no. of days between
// two dates
   let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

   if(Difference_In_Days > 7){
    

        var today = new Date(this.chartdate.startDate);
          today.setDate(today.getDate() + 7);

          end = new Date(today).toISOString().slice(0, 10);

          this.chartdate.endDate = end;

          alert("Only 7 Days data between("+ start +" to  "+end+")  will show's on chart" );
               
   }
        //console.log ("Total number of days between dates:\n" +  checkstart.toDateString() + " and " + checkend.toDateString() + " is: " + Difference_In_Days + " days");
      
         

        //  console.log(start);
        //  console.log(end);
      } else {
        const yourDate = new Date();
        var sd = yourDate.toISOString().split("T")[0];

        //console.log("ddddd",sd);
        end = sd;

        var dateOffset = 24 * 60 * 60 * 1000 * 2; //1 days
        var myDate = new Date();
        myDate.setTime(myDate.getTime() - dateOffset);

        var ed = myDate.toISOString().split("T")[0];
        start = ed;

        console.log("start", start);
      }

      axios({
        url: dataurl,
        method: "GET",
        params: {
          start: start,
          end: end
        }
      })
        .then(resp => {
          var mp10s = [];
          var mp25s = [];
          var itsms = [];

          console.log("resp -->> ", resp.data.success);

          this.result = resp.data.success.length;

          resp.data.success.forEach(item => {
            //  console.log("item index",index , "-->" , item.pm10);

            mp25s.push(item.pm25);
            mp10s.push(item.pm10);

            var Xmas95 = new Date(item.created_at);

            var options = {
              timeStyle: "short",
              dateStyle: "short"
            };

            //  console.log(new Intl.DateTimeFormat('en', options).format(Xmas95));
            itsms.push(new Intl.DateTimeFormat("en", options).format(Xmas95));
            // itsms.push(index);
          });

          this.series = [
            {
              name: "PM10",
              data: mp10s
            },
            {
              name: "PM2.5",
              data: mp25s
            }
          ];

          this.chartOptions.labels = itsms;

          // pm10 data //

          this.pm10series = [
            {
              name: "Aqi",
              data: mp10s
            }
          ];

          // console.log("smp10s",mp10s)

          this.pm10chartOptions.xaxis.categories = itsms;

          ///

          // pm25 data //

          this.pm25series = [
            {
              name: "Aqi",
              data: mp25s
            }
          ];

          this.pm25chartOptions.xaxis.categories = itsms;

          ///

          var self = this;
          setTimeout(function() {
            self.showChart = true;
            self.preloader = false;
          }, 2000);
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },

    async chartDataExport(args) {
      this.timeslotloader = true;

       console.log("args -> ",args);

      

      var start = "";
      var end = "";
      let currentcam = this.currentcam.camera_id;
      // var datas = args.text.split(" - ");
      // var s = datas[0].split("/");
      // var e = datas[1].split("/");
      // start = s[2] + "-" + s[0] + "-" + s[1];
      // end = e[2] + "-" + e[0] + "-" + e[1];

       start = new Date(this.reportdate.startDate).toISOString().slice(0, 10);
        end = new Date(this.reportdate.endDate).toISOString().slice(0, 10);

      await axios
        .get("/aiq/" + currentcam + "/seven-days-record", {
          params: { start: start, end: end }
        })
        .then(response => {
          let currentProject = JSON.parse(
            localStorage.getItem("currentProject")
          );
          var arrData = response.data.success;
          var CSV = "";
          for (var i = 0; i < arrData.length; i++) {
            delete arrData[i].id;
            delete arrData[i].camid;
            var row = "";
            for (var index in arrData[i]) {
              var key = Object.keys(arrData[i]);
              if (i == 0) {
                for (var indexKeys in key) {
                  row += '"' + key[indexKeys] + '",';
                }
                break;
              } else {
                row += '"' + arrData[i][index] + '",';
              }
            }
            CSV += row + "\r\n";
          }
          if (CSV == "") {
            alert("Data not available for selected date range.");
            this.timeslotloader = false;
            return;
          }
          var fileName =
            currentProject.project_name +
            "-" +
            this.currentcam.camName +
            "-" +
            "AQI_";
          fileName += start + "_TO_" + end.replace(/ /g, "_");
          var uri = "data:text/CSV;charset=utf-8," + escape(CSV);
          var link = document.createElement("a");
          link.href = uri;
          link.style = "visibility:hidden";
          link.download = fileName + ".csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.timeslotloader = false;
        });
    }
  }
};
</script>

<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.zzoomerfull {
  width: 100%;
  height: 100vh;
  border: solid 1px silver;
  position: fixed;
  top: 0;
  left: 0;
  background: #515b61b0 !IMPORTANT;
  display: BLOCK;
}

.closebuttons {
  position: fixed !important;
  right: 10px;
  top: 50px;
  z-index: 999999;
  color: #fff !important;
  cursor: pointer;
}

.aiinfobox {
  position: fixed !important;
  right: 40px;
  top: 80px;
  z-index: 10;
  color: #000 !important;
  background: #ffffff9c;
  padding: 15px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  border-radius: 5px;
  transition: 0.5s;
}

.aiinfobox:hover {
  transition: 0.5s;
  color: #000 !important;
  background: #ffffff;
  box-shadow: 2px 2px 2px #000;
}

.split360 {
  display: block;
  overflow: auto;
  height: 50vh;
  /*width: 48%;
  height: 50%;
  background: #000000ab;

  left: 20px;
  z-index: 1; */
}

span.camname {
  position: absolute;
  left: 20px;
  background: #02020236;
  padding: 3px 7px;
  border-radius: 3px;
  top: 20px;
  z-index: 2;
  color: #fff;
}

.camimage {
  position: relative;
}

.camimage .v-responsive__content {
  cursor: pointer;
}

.menufooter.ai {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #00000075;
  padding: 6px 0;
  font-weight: bold;
  color: #fff;
}

h2.hetitle {
  background: #f8bb03;
  padding: 0px 15px;
  text-align: center;
  font-size: 17px;
  box-shadow: -2px 7px 16px 0px #0000004a;
}
#chartbar {
  cursor: pointer;
}
.downloadbutons {
  background: #ffc400;
  color: #211a00;
  display: inline-block;
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  border-radius: 4px;
  line-height: 36px;
  font-size: 0.875rem;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.vue-daterange-picker {
    min-width: 200px !important;
}

.datepicker .daterangepicker {
    min-width: 549px !important;
}

</style>

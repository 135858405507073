<template>
    <div>
      <Preloader v-if="preloader" />
  
      <v-row>
        
        <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
          <v-card class="minheights">
            <v-card dark flat>
              <v-card-title class="pa-2 amber accent-3">
            Project
              </v-card-title>
            </v-card>

            <v-snackbar
              v-model="snackbar"
              center
              top
              timeout="3000"
              type="success"
            >
              {{ text }}
  
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  Ok
                </v-btn>
              </template>
            </v-snackbar>
            
            <div class="form" v-for="(cat, p ) in jsondata" :key="cat.cat">
                <label class="lable">Category</label>   
                <input v-model="cat.cat.name" class="formfiled subcate" type="text" placeholder="Category Name"> <button type="button" class="removebutn"  @click="removecate(p)"> <v-icon dark>
                mdi-delete-forever
              </v-icon> </button>
               
                <div v-for="(field, c) in cat.subcate" :key="field.value">
                <label class="lable"> Sub Category {{ c + 1 }}</label>
                <input class="formfiled subcate"  v-model="field.value" type="text" placeholder="Sub Category Name">
                <button type="button" class="removebutn"  @click="updateField(p,c)">Remove</button>
                <label class="lable">Select Projects</label>
                <v-select
            v-model="field.fieldselectedProject"
            :items="items"
            chips
            item-text="project_name"
            item-value="id"
            multiple
            solo
          ></v-select>
             
                </div>
                <button type="button" class="addbuton" @click="addFieldupdate(p)"> Add Sub Category + </button>
                <!--<br>
                <button type="submit" @click="submit" class="submitbuton">Submit</button> -->
           </div>
              <div class="form">
                <label class="lable">Category</label>
                <input v-model="category" class="formfiled" type="text" placeholder="Category Name">
               
                <div v-for="(field, index) in formFields" :key="index">
                <label class="lable"> Sub Category {{ index + 1 }}</label>
                <input class="formfiled subcate"  v-model="field.value" type="text" placeholder="Sub Category Name">
                <button type="button" class="removebutn"  @click="removeField(index)">Remove</button>
                <label class="lable">Select Projects</label>
                <v-select
            v-model="field.fieldselectedProject"
            :items="items"
            chips
            item-text="project_name"
            item-value="id"
            multiple
            solo
          ></v-select>
               
                </div>
                <button type="button" class="addbuton" @click="addField"> Add Sub Category + </button>
               <br>
                <button type="submit" @click="submit(0)" class="submitbuton">Submit</button> 
                <button type="submit" @click="submit(1)" class="submitbuton">Update</button>
           </div>
          </v-card>
        </v-col>
        <v-col cols="3" md="3" out-line class="setting_sidebar">
          <Sidebar />
        </v-col>
      </v-row>
      <v-snackbar
            v-model="alert"
            right
            top
            timeout="3000"
            type="success"
          >
            {{ alertmessage }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="alert = false">
              Ok
              </v-btn>
            </template>
          </v-snackbar>
    </div>
  </template>
  <script>
  import axios from "axios";
  import Preloader from "../../../components/PreLoader";
  import Sidebar from "../../../components/SettingSidebar";

  
  
  
  export default {
    components: {
      Preloader,
      Sidebar
    },
    name: "project cate",
    data() {
      return {
       
        preloader: false,
        items: {},
       
        snackbar: false,
        text: "Something went wrong. Please reload and try again.",
        alert: false,
        alertmessage: "",
        alertType: "",
        updatecamdata: {},
        dialog: false,
        userdata:"",
        currentProject :'',
        formFields: [],
        category :[],
        jsondata:[]
       
      };
    },
    mounted() {
      
      this.getPorjects();
     
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      if (this.userdata.open_access == 1) {
        this.$router.push("/dashboard");
      }
     
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.getCatgory();
     
    },
  
    watch: {
      "$store.state.currentCamera": function() {
       
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
        // this.img_interval  = this.current_cam.image_interval;
        console.log("watch userdata", this.userdata);
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      }
    },
    methods: {
      getPorjects() {
        axios({ url: "/project", method: "GET" })
          .then(resp => {
            console.log("Projects", resp.data.success);
            // this.items = resp.data.success.filter(function(item) {
            //   return item.project_active == 1;
            // });
            this.items = resp.data.success;
            //this.getCamerasbyProject(this.items[0]);
            
          })
          .catch(err => {
            console.log(err);
          });
      },

      addField() {
      this.formFields.push({ value: '', checked: false });
    },
    addFieldupdate(p){
        this.jsondata[p].subcate.push({ value: '', checked: false });
    },
    removeField(index) {
      this.formFields.splice(index, 1);
      
    },
    updateField (p,c){
        var a = this.jsondata[p].subcate.splice(c, 1);
         console.log("remove item", a);
    },
    removecate(p){
         var cc = confirm("Are you sure to remove this category.");
         if(cc){

             var a = this.jsondata.splice(p, 1);
             console.log("remove item", a);
             this.alert = true;
              this.alertmessage = "Category remove Succesfuly."
            }
    },
    submit(v){

           console.log("category",this.category);
           console.log("formFields",this.formFields);

           var checkblank = false;

this.jsondata.filter(item => {

    console.log(item);
    if(item.cat.name == ""){
       checkblank = true;
    }
    if(item.subcate.length == 0){
        checkblank = true;
    }
    if(item.subcate.length > 0){
        if(item.subcate[0].fieldselectedProject.length == 0){
            checkblank = true; 
        }
    }
 
});
     if(!checkblank){
               if(v == 0){
           this.jsondata.push({
               cat: {name : this.category, checked: false},
               subcate:this.formFields
             });
           }

           console.log(this.jsondata);

           ///var cb = this.jsondata.includes(''); // true

           


           //console.log("blank check",cb);

         
            

              
               
               var ss = JSON.stringify(this.jsondata);
               
              // console.log(ss);
               
               var data = {
                   orgid: this.currentProject.organization_id,
                   catdata : ss
                }
                
                axios({ url: "/project/category", method: "post",data : data })
                .then(resp => {
                    console.log("Projects", resp); 
                    this.alert = true;
                    this.alertmessage = "Category submitted successfully."
                })
                .catch(err => {
                    console.log(err);
                });
              
          if(v == 0){
              this.category = "";
              this.formFields = [];
            }
        }else{
             this.alert = true;
             this.alertmessage = "All fields requerd."
        }
        
    },

    getCatgory(){
        var data = {
            orgid: this.currentProject.organization_id
           }

           axios({ url: "project/get/category", method: "get",params : data })
          .then(resp => {
            console.log("Projects cate deta", resp); 
            var json = JSON.parse(resp.data.success.catdata);
            this.jsondata = json;
            console.log("json",json);
          })
          .catch(err => {
            console.log(err);
          });
    }
    }
  };
  </script>
  <style>
   .form {
    background: #e9e9e9;
    padding: 15px;
    margin: 15px;
    border-radius: 5px;
}
input.formfiled {
    width: 100%;
    border: 1px solid #fff;
    padding: 5px;
    border: 2px solid #fff;
    border-radius: 6px;
    background: #fff;
}
label.lable {
    width: 100%;
    display: block;
    margin: 6px 0;
}
button.removebutn {
    color: #fff;
    background: #ff4f4f;
    padding: 3px 7px;
    border-radius: 5px;
    margin-left: 11px;
}
.addbuton {
    color: #000;
    background: #53d475;
    padding: 3px 7px;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 10px;
    box-shadow: 0 0 15px 0px #0000006b;
}
.submitbuton {
    color: #000;
    background: #f8bb03;
    padding: 3px 7px;
    border-radius: 5px;
    margin-right: 11px;
    margin-top: 14px;
    box-shadow: 0 0 15px 0px #0000006b;
}
input.formfiled.subcate {
    width: calc(100% - 100px) !important;
}
  </style>
  
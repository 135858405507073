<template>
    <div>
      <Preloader v-if="preloader" />
        <template>
        <v-card class="walkthrougth">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
          >
          <template v-slot:item.actions="{ item }">
                <v-btn
                fab
                dark
                icon
                small
                color="black"
                @click="updateWalkthrogh(item)"
                >
                <v-icon dark>
                mdi-pencil-box-multiple
                </v-icon>
                </v-btn>

                <v-btn
                dark
                class="mr-2"
                small
                color="red"
                @click="discardWalkthrough(item)"
                >
                 Discard 
                </v-btn>

                <v-btn
                light
                small
                color="#f8bb03"
                @click="reporocessWalkthrough(item)"
                >
                Reprocess
                </v-btn>
            </template>
        </v-data-table>
        </v-card>
        </template>
       
      <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}
  
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="alert = false">
            {{ lang.Close }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  //import ForgeVuer from 'forge-vuer';
  
  import axios from "axios";
  import Preloader from "../../components/PreLoader";
  //import $ from 'jquery'
  // import * as THREE from 'three';
  import { locals } from "../../utils/locals";
  
  export default {
    components: {
      Preloader
      // ForgeVuer
    },
    name: "walkthrough",
  
    data() {
      return {
        preloader: false,
        Local: locals,
        lang: {},
        currentProject: {},
        userdata: {},
        alertmessage: "",
        alert: false,

        search: '',
        headers: [
        {
            text: 'Walkthrough ID',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Project Name',
            value: 'project_name',
          },
          { text: 'Drawing Name', value: 'drawingname' },
          { text: 'Create Date', value: 'created' },
          { text: 'Email', value: 'email' },
          {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    width: 250,
                }
        ],
        desserts: []
      };
    },
    mounted() {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      if (this.userdata.open_access == 1) {
        this.$router.push("/dashboard");
      }
      var selectleng = this.$cookies.get("currentLanguage");
      this.lang = locals[selectleng];
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
   
      this.projectid = this.currentProject.id;
      // if(this.currentProject.autodesk_urn){
      //            console.log("mounted test");
      // }
    },
    created() {
      console.log("df");
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      
      this.projectid = this.currentProject.id;
      this.currentZoneName = localStorage.getItem("currentZoneName");
      this.getwalkthrough();
    },
    watch: {
      
      "$store.state.currentZone": function() {
        // console.log("Current data get",this.$store.state.currentCamera)
        this.zoneid = this.$store.state.currentZone;
        this.currentZoneName = localStorage.getItem("currentZoneName");
        console.log("current zone", this.zoneid);
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
        this.autodesk_viewer = this.currentProject.autodesk_viewer;
        this.projectid = this.currentProject.id;
        if (this.currentProject.autodesk_urn) {
          console.log("mounted test");
        }
      }
    },
    methods: {
      getwalkthrough() {
            axios({
                    url: '/interior/get/walkthrough',
                    method: 'get',
                    params : {
                      projectid : this.projectid,
                    }
                })
                .then((res) => {
                    console.log("seponce",res);
                  this.desserts = res.data;
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        updateWalkthrogh(item){
          console.log(item);

          this.$router.push({
                name: 'interior_walkthrough_route',
                params: {
                    did: item.drawingid,
                    wid: item.id,
                }
            });
        },
        discardWalkthrough(item){
          
          var a = confirm("Are you sure you want to discard the walkthrough?");
          if(a){

          axios({
                    url: '/interior/status/discard',
                    method: 'post',
                    data : {id : item.id, status: -1}
                })
                .then(() => {
                    console.log("status update successfully");
                    this.alert= true;
                    this.alertmessage= "Walkthrough Discarded.."
                    this.getwalkthrough();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
              }

        },
        reporocessWalkthrough(item){

          var a = confirm("Are you sure you want to reprocess the walkthrough?");

          if(a){

    axios({
          url: '/interior/status/discard',
          method: 'post',
          data : {id : item.id, status: 0}
      })
      .then(() => {
          console.log("status update successfully");
          this.alert= true;
          this.alertmessage= "Walkthrough Reprocessed.."
          this.getwalkthrough();
      })
      .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error"
          this.preloader = false
      })
       }

     } 
    }
  };
  </script>
  
  <style>
    .walkthrougth .v-data-footer {
    position: flex;
    bottom: 0;
    width: 100%;
    background: #fff;
    left: 0;
}
  </style>
  
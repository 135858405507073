<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card class="pa-5">
          <v-card-title class="center"> Add New Camera </v-card-title>
          <v-btn class="secondcolor black-text newecambuttons" dark v-bind="size" v-on="on" @click="setrouter('/dashboard/settings/ecam-setup')"> Add New Project And Camera  </v-btn>
         <validation-observer ref="projectform">
                    <v-form @submit.prevent="orgRegistration">
                        <validation-provider v-slot="{ errors }" name="cameracode" rules="required">
                            <v-text-field v-model="cameracode" :error-messages="errors" label="Camera Code" required clearable></v-text-field>
                        </validation-provider>

                        <div class="text-center">
                            <v-btn class="secondcolor black-text" type="submit"  dark v-bind="size" v-on="on" > Submit </v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                </v-card>
                    </v-col>
                     <v-col class="setting_sidebar" cols="3" md="3" out-line>
                     <Sidebar />
                 </v-col>
                
                    <v-snackbar v-model="error" :multi-line="multiLine" top right timeout="3000" type="success">
                    {{ errorMessage }}

                    <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="error = false">
                    {{lang.Close}}
                    </v-btn>
                    </template>
                    </v-snackbar>
                </v-row>
  </div>
</template>
<script>
 import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";

import {
  
    ValidationProvider,
    ValidationObserver,
} from "vee-validate";
export default {
  components: {
    Preloader,
    Sidebar,
      ValidationProvider,
        ValidationObserver,
  },
  name: "newEcamsetup",
  data() {
    return {
     snackbar: false,
     text: "Something went wrong. Please reload and try again.",
     Local :locals,  
      lang : {},
       cameracode : '',
        currentProject : '',
        projectname : '',
        preloader : false,
        error : false,
        errorMessage : ''

    };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
 
     console.log("userdata" ,this.userdata);

     if((this.userdata.user_code == 2 || this.userdata.user_code == 0 ) && this.userdata.ecam == 1){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
     async orgRegistration() {
            const valid = await this.$refs.projectform.validate();
      if (valid) { 
     
      this.preloader= true;
      //console.log(this.email);

      var data = {
            orgid: this.currentProject.organization_id,
            camcode : this.cameracode,
            project_name : this.currentProject.project_name,
      }
       var projectId =  this.currentProject.id;

      axios({ url: "/only_e_cam_setup/"+projectId, data: data, method: "POST" })
        .then((response) => {
          var v = this;
          console.log("response", response.data.success);
          if (response.data.success) {
            this.error = true;
            this.type = "success";
            this.errorMessage = "Congratulations! Your new camera register successfully.";
            this.classs= "mt-5";
              setTimeout(function () {
                  v.$router.push('/login')
              }, 3000);
          }
          this.preloader= false;
        })
        .catch((err) => {
          console.log(err);
          this.error = true;
          this.errorMessage = "Somethings went wrong pleas Check Camera Code.";
          this.type = "error";
          this.classs= "mt-5";
          this.preloader= false;
        });
      }
    }, 
      setrouter: function (url) {
      
      if (this.$route.path !== url) {
        this.$router.push(url);
      }

      //console.log(url);
    },
  }
};
</script>
<style>

</style>


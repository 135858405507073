<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>

    <v-row>
      <div cols="12" md="6">
        <div class="generateButton">
          <v-btn @click="submit" :disabled="selectedMarkers.length <= 0">
            Generate Report
          </v-btn>
        </div>
        <v-col cols="12" md="12" v-if="loopcout && galleryview">
          <gmap-map map-type-id="satellite" :center="center" :zoom="zoom" :class="`mapite ${mapToggle ? 'min' : 'max'} ${selectedMedia == 'Drone Video' ? 'fifty' : 'full'
      }`
      " ref="mapRef">
            <!-- <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                
              </gmap-info-window> -->

            <span> <gmap-marker :key="i" v-for="(m, i) in markers" :position="m.position" :clickable="true"
                :icon="markerr(i)" @click="toggleInfoWindow(m, i)"></gmap-marker> </span>
          </gmap-map>
        </v-col>
      </div>
    </v-row>

    <v-snackbar v-model="alert" top right timeout="10000" type="success">
      {{ alertmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import exifr from "exifr";
// import carousel from "vue-owl-carousel";
import vthumbnail from "../../assets/video_thumbnail.png";
import icon from "../../assets/Dot-Icon-Blue.png";
import iconactive from "../../assets/Dot-Icon-BlueActive.png";
import red from "../../assets/red.png";
import green from "../../assets/green.png";
import yellow from "../../assets/yellow.png";
import grey from "../../assets/grey.png";
import black from "../../assets/black.png"
import pink from "../../assets/pink.png";
import { locals } from "../../utils/locals";
import rotate from '../../assets/rotedmobilepsd.jpg';
// import $ from "jquery";
export default {
  components: {
    Preloader,
    // VPannellum: VuePannellum,
    // carousel
  },
  name: "CompareEvent",
  data() {
    return {
      rotate: rotate,
      preloader: false,
      files: [],
      dropOptions: {
        url: "https://api.opticvyu.com/api/project/gps/image-upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        paramName: "file",
        maxFilesize: 500, // MB
        acceptedFiles: ".jpeg,.jpg,.png",
        maxFiles: 4,
        chunkSize: 500, // Bytes
        thumbnailMethod: "contain",
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        autoProcessQueue: false,
        dictDefaultMessage:
          "Click to upload Images  <br />----------------- <br/>Drag & Drop"
      },
      thumbnailimage: "",
      pro_dir: "",
      projectid: "",
      userdata: {},
      imagesExtract: "",
      currentvisitId: "",
      visitdata: "",
      galleryview: true,
      uploadzone: false,
      visitDatepopup: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      images: [],
      loopcout: false,
      infoOptions: {
        content: "",
        backgroundColor: '#2ffff3',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      dats: [],
      center: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoom: 5,
      markers: [],
      currentPlace: null,
      coi: 0,
      valid: true,
      currentMidx: null,
      fulllImage: false,
      liveimage: "",
      cimi: 0,
      alertmessage: "",
      alert: false,
      refresh3: 0,
      sliderloader: true,
      latesttrue: false,
      down: false,
      mapToggle: false,
      selectedMedia: "Drone Images",
      mapMarkerActive: iconactive,
      mapMarker: icon,
      selectedKey: null,
      selectedMarker: null,
      activePosition: 0,
      vthumbnail: vthumbnail,
      infolist: [],
      Local: locals,
      lang: {},
      dialog: false,
      zonesFirstRow: {},
      zonesSecondRow: {},
      firstRowzoneid: 0,
      secondRowzoneid: 0,
      projid: "",
      zonename: "",
      items: [],
      rules: {
        required: (value) => !!value || "This field is required.",
        url: (value) => {
          // A simple regex to validate URLs
          const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          return regex.test(value) || "Invalid URL.";
        },
      },
      isFormValid: false,
      droneDataShare: false,
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      send: false,
      timelapseUrl: "",
      shareImageLat: "",
      shareImageLong: "",
      routedata: {},
      subject: '',
      email: '',
      red: red,
      yellow: yellow,
      pink: pink,
      grey: grey,
      green: green,
      black: black,
      selectedMarkers: [],
      selected: false,

    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    // if (this.userdata.open_access == 1) {
    //   this.$router.push("/dashboard");
    // }
    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.pro_dir = currentProject.pro_dir;
    this.initViewer();
    var _self = this;
    document.body.addEventListener("keyup", e => {
      if (e.keyCode === 27) {
        _self.fullscreenexit();
        // this.mediatypeset("Drone Video");
      }
    });
    this.project = currentProject.project_name;
  },
  watch: {
    "$store.state.currentvisitId": function () {
      this.preloader = true;
      this.latesttrue = false;
      var _self = this;
      setTimeout(() => {
        _self.currentvisitId = _self.$store.state.currentvisitId;
        console.log(_self.currentvisitId, "_self.currentvisitId");
        // this.$refs.myVueDropzone.removeAllFiles();
        _self.initViewer();
        _self.sliderloader = false;

        _self.preloader = false;
      }, 5000);
    },
    $route: function () {
      //console.log(to);
      var hash = window.location.hash;
      console.log(hash);
      var result = hash.substring(1);
      this.openbyDrop(result);
    },
  },

  methods: {
    initViewer() {
      //   this.preloader = true;
      console.log("Page Load", this.currentvisitId);
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("this.visitdata", this.visitdata);
      this.getImages();
      this.alertmessage = "Select 1, 2, or 4 markers to generate slide show";
      this.alert = true;
    },
    sendingEvent(file, xhr, formData) {
      console.log("d", file);
      // formData.append("project_id", "2");
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("xhr", xhr);

      formData.append("latitude", this.imagesExtract.latitude);

      formData.append("longitude", this.imagesExtract.longitude);

      formData.append("gps_date_stamp", this.imagesExtract.GPSDateStamp);

      formData.append("gps_time_stamp", this.imagesExtract.GPSTimeStamp);

      formData.append("project_id", this.projectid);
      formData.append("proj_dir", this.pro_dir);
      formData.append("thumbnail", this.thumbnailimage);
      formData.append("user_id", this.userdata.id);
      formData.append("visit_date", this.visitdata.visit_date);
      formData.append("visit_id", this.visitdata.id);

      console.log("formData", formData);
    },
    async thumbnail(file, dataUrl) {
      this.thumbnailimage = dataUrl;
      var s = await exifr.parse(file);
      this.imagesExtract = s;
      console.log(
        s.latitude,
        s.longitude,
        s.GPSDateStamp,
        s.GPSTimeStamp,
        s.Make,
        s.Model,
        s.Software,
        file.name
      );


      this.$refs.myVueDropzone.processQueue();
    },
    getImages() {
      // this.markers = [];
      this.refresh3++;
      console.log("currentvisitId", this.currentvisitId);
      this.marker = [];
      this.loopcout = false;
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + this.visitdata.id,
        method: "GET"
      })
        .then(resp => {
          console.log("visit data resp", resp);
          if (resp.data.success.length > 0) {
            console.log(" if in visit data resp", resp);
            var _self = this;
            this.dats = resp.data.success.filter(function (item) {
              console.log(
                "item.mediatype",
                item.mediatype,
                _self.selectedMedia
              );

              return item.mediatype == _self.selectedMedia;
            });
            console.log("visit data resp[0]", this.dats[0]);
            this.images = this.dats;
            this.coi = this.dats.length;

            var n = 0;
            /// var sa = '';
            this.dats.forEach((i, index) => {
              // console.log("==>", i.latitude, i.longitude);

              if (i.latitude && i.longitude) {
                this.markers[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > Open Image </a>'
                };
              }

              n++;
              var _self = this;
              if (this.coi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.dats[0].latitude);
                  var lng = parseFloat(this.dats[0].longitude);
                  console.log("this.markers", _self.markers);
                  _self.center = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoom = 15;
                  _self.latesttrue = true;
                  _self.sliderloader = false;
                }, 2000);
              }
            });
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = "Images not available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
        });
    },
    toggleInfoWindow: function (marker, idx) {

      const index = this.selectedMarkers.findIndex(marker => marker.idx === idx);
      if (index !== -1) {
        // Marker is already selected, so unselect it
        this.selectedMarkers.splice(index, 1);
      } else {
        // Marker is not selected, so select it
        this.selectedMarkers.push({ idx: idx, lat: parseFloat(marker.position.lat.toFixed(4)), lng: parseFloat(marker.position.lng.toFixed(4)) });
        this.markerr(marker, idx);
      }

      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    submit() {
      if (this.selectedMarkers.length == 1 || this.selectedMarkers.length == 2 || this.selectedMarkers.length == 4) {
        console.log('Submitted');
        this.alert = true;
        this.alertmessage = "Slide show generation is in progress & will be emailed to you soon.";
        axios({
          url: "https://www.opticvyu.com/api/droneSlideShow",
          method: "post",
          data: {
            latLongs: this.selectedMarkers,
            user_email: this.userdata.email,
            project_id: this.projectid,
            selected_visit_date: this.visitdata.visit_date,
          }
        })
          .then(resp => {
            if (resp) {
              console.log("report created successfully.");
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.alertmessage = "You can only select 1, 2, or 4 markers";
        this.alert = true;
      }
    },
    openbyDrop(index) {
      this.fulllImage = true;
      this.mapToggle = true;
      this.cimi = index;
      this.liveimage = this.images[index].image_path;
    },
    downUp() {
      this.down = !this.down;
    },
    mediatypeset(c) {
      console.log(c);
      this.latesttrue = false;

      this.selectedMedia = c;
      var _self = this;
      setTimeout(() => {
        _self.getImages();
      }, 1000);
    },
    markerr(i) {
      if (this.selectedMarkers.some(marker => marker.idx === i)) {
        return this.mapMarkerActive;
      } else {
        return this.mapMarker;
      }
    },

    setrouter: function (url) {
      this.showmobilemenu = false;
      if (this.$route.path !== url) {
        this.$router.push(url)
        this.activelink = this.$route.path;
      }
    },
  }
};
</script>

<style>
.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 99999;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

.mapite.min:hover {
  top: 0px;
  left: 0px;
  opacity: 1;
  width: 700px;
  z-index: 99999;
  height: 100vh;
  transition: 0.5s;
}


.fullviewbox.fifty {
  width: 50%;
  right: 0;
  left: 50%;
  background: #ffffff;
}

.mapite.min.fifty {
  width: 50%;
  left: 0;
  height: 100%;
  top: 0;
}


.vue-map-container {
  position: absolute !important;
}

.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 99999;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

.generateButton {
  position: fixed;
  z-index: 1;
  top: 111px;
  left: 11px;
}
</style>
<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
      {{ this.lang.TwoFactorAuthentication}}
    </v-card-title>
          <v-card-subtitle>
      {{lang.twofactoreAuthentication}}
    </v-card-subtitle>
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
        <v-col
          cols="12"
          md="6"
        >
         <v-checkbox
      v-model="checkboxactive"
      :label="checkboxactive == 1 ?  lang.ClicktoInActive : lang.ClicktoActive"
    ></v-checkbox>
      
        <v-text-field
          v-model="email"
          :label="lang.Email"
          disabled
          :rules="emailrules"
        ></v-text-field>
       </v-col>
       <v-col
          cols="12"
          md="12"
        >
       <v-btn
        class="mr-4"
        type="submit"
        
        color="mylightbutton"
      >
        {{lang.submit}}
      </v-btn>

       <v-btn @click="Reset"
       color="mydarkbutton"
      >
       {{ lang.Reset }}
      </v-btn>
      </v-col>
      </v-row>
    </v-container>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
        class="setting_sidebar"
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "two_factor_auth",
  data() {
    return { 
       valid: true,
        checkboxactive : false,
        email : '',
        emailrules: [
        v => !!v || this.lang.required,
        v => /.+@.+\..+/.test(v) || this.lang.EmailValid
      ],
       
         preloader: false,
          alert: false,
          alertmessage: '',
          alertType: '',
          current_cam : {},
          Local :locals,  
         lang : {}, 
        
     };
  },
  mounted() {

      var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
    
     this.initViewer();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    //  if(this.userdata.free_access == 0){
    //       this.useraccess = true
    //     }else{
    //       this.$router.push('/')
    //     }
        console.log("user data", this.userdata);
    this.email  = this.userdata.email;
    this.checkboxactive  =  this.userdata.User2F_auth == 1 ? true: false; 
    this.current_cam = this.$store.state.currentCamera;
  },
  watch: {
  '$store.state.currentCamera': function() {
    
     //console.log("Current data get",this.$store.state.currentCamera)

     this.current_cam = this.$store.state.currentCamera;

     //this.checkboxactive  =  this.current_cam.notification_active == 1 ? true: false; 
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    console.log("user data", this.userdata);
     this.email  = this.userdata.email;
     this.checkboxactive  =  this.userdata.User2F_auth == 1 ? true: false; 
  }
  },
    computed: {
    params() {
      return {
       
         notification_email : this.email,
         notification_active : this.checkboxactive ? 1 : 0, 
       
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.add_2fa_Settings(this.params) // action to login
      }
    },
    add_2fa_Settings(userdata) {
        
        // console.log(userdata)

        axios({ url: '/camera/'+this.current_cam.camera_id+'/update_2fa', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      },

      Reset(){
        var userdata = {
           notification_email : this.email,
           notification_active : 0, 
          }
         axios({ url: '/camera/'+this.current_cam.camera_id+'/update_2fa', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = this.lang.Resetsuccesful;
                    this.alertType = "success";
                    this.checkboxactive = 0;

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
          })
      }
     
  }
};
</script>
<style>
</style>


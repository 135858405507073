<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="amber accent-3"
            fixed-tabs
            dark
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1"> {{lang.Favourites}} </v-tab>
            <!-- <v-tab href="#tab-2"> Saved Annotations </v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-snackbar
                v-model="snackbar"
                top
                right
                timeout="3000"
                type="success"
              >
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    {{lang.Ok}}
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card flat class="mx-auto my-12" max-width="90%">
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="4"
                    class="col_margin"
                    v-for="r in Fresponse"
                    :key="r.id"
                  >
                    <v-card class="pa-2" outlined tile>
                     <!--  <div class="fav360" v-if="checkcam360">
                        <v-pannellum
                          :src="r.urls"
                         @click="openimage(r.urls)"
                        ></v-pannellum>
                      </div>
                      <v-card-text v-if="checkcam360" class="caption360">
                       <div>
                          <b>Image Captured Time:</b> {{ r.capture_time }}
                          <br />
                          <b>Image Added Time:</b> {{ r.added_time }}
                        </div>
                      </v-card-text>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions v-if="checkcam360">
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          @click="remove_favourite(r.id)"
                          class="btnaction360"
                        >
                          Remove Favourite
                        </v-btn>
                      </v-card-actions> -->
                      <v-img
                        :src="r.urls"
                        aspect-ratio="1.7"
                        max-width="100%"
                        class="c_pointer"
                        @click="openimage(r.urls)"
                       
                      ></v-img>
                      <v-card-text>
                        <!-- <div class="my-4 text-subtitle-1">
                       Captured Image Time: {{r.capture_time}}
                      </div>
                          <div class="my-4 text-subtitle-1">
                         Added Time: {{r.added_time}} HRS
                        </div> -->
                        <div>
                          <b>{{lang.ImageCapturedTime}}:</b> {{ r.capture_time }}
                          <br />
                          <b>{{lang.ImageAddedTime}}:</b> {{ r.added_time }}
                        </div>
                      </v-card-text>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions>
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          @click="remove_favourite(r.id)"
                        >
                          {{lang.RemoveFavorite}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>

                <v-zoomer class="zzoomerfull" v-if="dialog && !checkcam360">
                  <img
                    :src="img"
                    style="object-fit: contain; width: 100%; height: 100%"
                  />
                </v-zoomer>
                <div v-if="dialog && checkcam360" class="fullviewbox360">
                <v-pannellum class="fullscreen" :src="img"></v-pannellum>
                </div>
                <v-icon v-if="dialog" class="closebuttons" @click="closetoggle"
                  >mdi-window-close</v-icon
                >
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat> </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
//import Sidebar from '../../../components/SettingSidebar'
import VuePannellum from "vue-pannellum";
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
    // Sidebar
   'VPannellum': VuePannellum,
  },
  name: "favourite_list",
  data() {
    return {
      preloader: true,
      currentcam: {},
      tab: null,
      currentProject: {},
      alert: false,
      Fresponse: {},
      dialog: false,
      img: "",
      snackbar: false,
      text: `Something went wrong. Please try again!`,
      checkcam360: false,
       Local :locals,  
        lang : {},
    };
  },
  computed: {},
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    this.getFavouriteList();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getFavouriteList();
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    getFavouriteList() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentcam = this.$store.state.currentCamera;
      this.currentProject = currentProject;

      axios({
        url:
          "/project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/favourite_list",
        method: "GET",
      })
        .then((resp) => {
          //console.log("favourite_resp=>", resp);
          this.preloader = false;
          this.Fresponse = resp.data.success.data;
          console.log("favouritelist=>", this.Fresponse);
          if (this.currentcam.cam_360 == 1) {
            this.checkcam360 = true;
          } else {
            this.checkcam360 = false;
          }
          if (resp.data.success.data[0]) {
            //console.log("favouritelist=>", this.Fresponse);
            this.alert = false;
          } else {
            /* this.snackbar = true;
            this.text = "No favourite image available, please add from dashboard.";  */
            this.alert = true;
            this.alertmessage =
              "No favorite images found. Visit dashboard & click on the favorite tool to save images for future reference.";
            this.alertType = "info";
          }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    openimage(url) {
      this.dialog = true;
      this.img = url;
    },
    closetoggle() {
      this.dialog = false;
    },
    remove_favourite(fav_id) {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentcam = this.$store.state.currentCamera;
      this.currentProject = currentProject;
      var rm_para = {
        f_id: fav_id,
      };
      axios({
        url:
          "/project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/favourite",
        params: rm_para,
        method: "get",
      })
        .then((resp) => {
          console.log(resp);
          //this.snackbar = true;
          if (resp.data.success == "image removed from favourite") {
            this.snackbar = true;
            this.text = this.lang.favouriteremove;
          } else {
            this.snackbar = true;
            this.text = this.lang.favoriterror; //"Getting error while removing image from favorite.";
          }
          this.getFavouriteList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.col_margin {
  margin-bottom: 1%;
}
.fav360 {
  display: block;
  width: 100%;
  height: 123%;
  background: #000000ab;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 1; */
}
.btnaction360{
  background: #292929ad;
    bottom: -48px;
    right: 4%;
}
.caption360{
  position: inherit;
  background: #f5f5dc7d;
}
.fullviewbox360 {
   display: block;
    width: 90%;
    height: 90%;
    background: #000000ab;
    position: fixed;
    top: 8%;
    left: 5%;
    z-index: 9999;
}
</style>


<template>
<div>
    <Preloader v-if="preloader" />

    <v-row>
        <v-col cols="12" md="12">
            <v-card class="pa-5 formcard">

                <div class="stepper_outer">
                    <!-- <div class="steper_item " @click="movetotab('/dashboard/planning/uploade-task')">
                        <div class="itcons">
                            <v-icon>mdi-cloud-upload </v-icon>
                        </div>
                        <span class="steperItem"> Upload Task </span>
                    </div> -->
                    <div class="steper_item active" @click="movetotab('/dashboard/planning/edit-task')">
                        <div class="itcons">
                            <span class="steperItem"> {{lang.EditTask}} </span>
                            <v-icon>mdi-square-edit-outline </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item " @click="movetotab('/dashboard/planning/compare-events')">
                        <div class="itcons">
                            <span class="steperItem"> {{lang.CompareEvents}} </span>
                            <v-icon>mdi-compare </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item " @click="movetotab('/dashboard/planning/event-chart')">
                        <div class="itcons">
                            <span class="steperItem"> {{lang.EventChart}} </span>
                            <v-icon>mdi-chart-gantt </v-icon>
                        </div>
                        
                    </div>
                    <div class="steper_item" @click="movetotab('/dashboard/planning/event-timelapse')">
                        <div class="itcons">
                            <span class="steperItem"> {{lang.EventTimelapse}} </span>
                            <v-icon>mdi-play-circle </v-icon>
                        </div>
                        
                    </div>

                </div>

                <!-- <v-alert dense text :type="alertType" v-if="alert">
                    {{ alertmessage }}
                </v-alert> -->
                  <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="lang.Search"
        single-line
        hide-details
        class="mySearch"
      ></v-text-field>
                
                <download-excel
  class="btn ma-2 Block--text rightbutons downloadbutons"
  :data="taskdata"
  :fields="json_fields"
  :title="project_name"
  worksheet="Tasks"
  :name="`${project_name}.xls`"
>
   {{ lang.Export }}       <v-icon right dark color="#000">
                        mdi-arrow-down-bold
                    </v-icon>         
</download-excel>
                 <v-btn color="#ffc400" class="ma-2 Block--text rightbutons" @click="importFile =  true">
                    {{lang.Import }} 
                   <v-icon right dark>
                        mdi-arrow-up-bold
                    </v-icon>
                </v-btn>
                 <v-btn color="#ffc400" class="ma-2 Block--text rightbutons"
                 @click="addNewTask()">
                    {{ lang.AddNew }}  
                    <v-icon right dark>
                        mdi-clipboard-plus
                    </v-icon>
                </v-btn>
                
                <template>
                   
                    <v-data-table :headers="headers" :search="search" :items="taskdata" :items-per-page="50" :footer-props="footerpro" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="newbimboxopen(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </template>

            </v-card>
        </v-col>

    </v-row>

    <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newbimbox">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> Update Task </v-toolbar>
                <v-form ref="formBIM" v-model="valid" lazy-validation @submit.prevent="submitUpdateTask">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="taskname" :label="lang.TaskName" required></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="startdatamenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="startdate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="startdate" @input="startdatamenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->
                        <v-datetime-picker 
                            :label="lang.startDateTime" 
                            v-model="startdate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>

                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="enddatemenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" color="amber accent-3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="enddate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="enddate" @input="enddatemenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->
                                <v-datetime-picker 
                             :label="lang.endDatetime"  
                           v-model="enddate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="actualstartdatemenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" color="amber accent-3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="actualstartdate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="actualstartdate" @input="actualstartdatemenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->
                                <v-datetime-picker 
                           :label="lang.SelectDatetime" 
                           v-model="actualstartdate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="actualenddatemenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" color="amber accent-3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="actualenddate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="actualenddate" @input="actualenddatemenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->
                                <v-datetime-picker 
                           :label="lang.SelectDatetime" 
                           v-model="actualenddate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="newbimbox = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>

     <v-dialog transition="dialog-bottom-transition" max-width="600" color="amber accent-3" v-model="newtaskForm">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.AddNewTask}} </v-toolbar>
                <v-form ref="formNewTask" v-model="valid" lazy-validation @submit.prevent="submitNewsTask">

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field v-model="taskname" :label="lang.TaskName" required></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="startdatamenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="startdate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="startdate" @input="startdatamenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->
                                <v-datetime-picker 
                           :label="lang.startDateTime" 
                           v-model="startdate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>

                            <v-col cols="6" sm="6">
                                <!-- <v-menu v-model="enddatemenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" color="amber accent-3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="enddate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="enddate" @input="enddatemenu = false" color="amber accent-3"></v-date-picker>
                                </v-menu> -->

                                 <v-datetime-picker 
                           :label="lang.endDatetime" 
                           v-model="enddate" 
                            
                            :date-picker-props="dateProps"
                            :time-picker-props="timeProps"
                            time-format="HH:mm:ss"
                            color="#292929"
                        >
                                <template slot="dateIcon" color="#292929">
                                 <v-icon color="#292929">mdi-calendar-range </v-icon>
                                </template>
                                <template slot="timeIcon" color="#292929"> 
                                <v-icon color="#292929">mdi-clock-time-five </v-icon>
                        </template>
                                </v-datetime-picker>
                            </v-col>
                            

                            <v-col cols="12">
                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.Send}} <v-progress-circular v-if="send" indeterminate color="green"></v-progress-circular>
                                </v-btn>
                                <v-btn @click="newtaskForm = false" color="mydarkbutton">
                                    {{lang.Cancel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
           <v-snackbar
      v-model="alert"
      top
      right
      timeout="3000"
      type="success"
    >
     {{ alertmessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          
          @click="alert = false"
        >
          {{lang.Close}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog transition="dialog-bottom-transition" max-width="800" color="amber accent-3" v-model="importFile">
        <template>
            <v-card>
                <v-toolbar color="amber accent-3" dark> {{lang.ImportExcelFile}} </v-toolbar>
                    <v-form ref="form" class="centerform" v-model="valid" lazy-validation @submit.prevent="submit">

                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <input type="file" @change="onChange" />

                                 <xlsx-read :file="file" class="hide">
                                    <xlsx-json :sheet="selectedSheet">
                                        <template #default="{collection}">
                                            <div ref="filedata">
                                                {{ collection }}
                                            </div>
                                        </template>
                                    </xlsx-json>
                                </xlsx-read>
                            </v-col>
                            <v-col cols="12" md="12" style="text-align: left; 
    margin-left: 50px;
"> 
                               
                                 <h4>{{lang.Steps}}.</h4>
                                
<p>{{lang.stepOne}}</p>
<p>{{lang.stepTwo}} </p>
<p>{{lang.stepThree}}</p>
                            </v-col>
                            <v-col cols="12" md="12">

                                <v-btn class="mr-4" type="submit" :disabled="!valid" color="mylightbutton">
                                    {{lang.submit}}
                                </v-btn>

                            </v-col>
                            <v-col cols="12" md="12">
                                <a href="/OpticVyu_sample_data_sheet.xls" style="color:#000; text-decoration:none">
                                    <h3 style="color:#000; text-decoration:none">
                                        <v-icon>mdi-download-circle </v-icon>
                                       {{ lang.Downloadfile}}
                                    </h3>
                                </a>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
import JsonExcel from "vue-json-excel";
import DatetimePicker from 'vuetify-datetime-picker'
import { locals } from "../../../utils/locals";
Vue.use(DatetimePicker)
export default {
    components: {
        Preloader,
        "downloadExcel" : JsonExcel,
          XlsxRead,
        XlsxJson,
        
    },
    name: "EditTask",
    data() {
        return {
            preloader: false,
            file: null,
            project_name : 'ProjectName',
            projectid: 0,
             search: '',
            newbimbox: false,
            taskname: '',
            startdate: null,
            startdatamenu: false,
            enddate: null,
            enddatemenu: false,
            actualstartdatemenu: false,
            actualstartdate: null,
            actualenddatemenu: false,
            actualenddate: null,
            updateTaskId: '',
            send: false,
            menu2: false,
            newtaskForm:  false,
            importFile : false,
            alert : false,
                textFieldProps: {
                appendIcon: 'event'
                },
                dateProps: {
                headerColor: '#ffc400'
                },
                timeProps: {
                useSeconds: false,
                ampmInTitle: false,
                headerColor: '#ffc400',
                format: '24hr',
                allowedMinutes:[0]
                },
            headers: [{
                    text: 'Task Name',
                    align: 'start',
                    value: 'task_name',
                },
                {
                    text: 'Start Date',
                    value: 'base_start_date'
                },
                {
                    text: 'End Date',
                    value: 'base_end_date'
                },
                {
                    text: 'Actual Start Date',
                    value: 'actual_start_date'
                },
                {
                    text: 'Actual End Date',
                    value: 'actual_end_date'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false
                }
            ],
             footerpro: {
                itemsPerPageOptions: [50 ,100, 150, -1],
              },
            taskdata: [],

            json_fields: {
      "Task Name": "task_name",
      "Start Date": "base_start_date",
      "End Date": "base_end_date",
       "Actual Start Date": "actual_start_date",
       "Actual End Date": "actual_end_date",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
     Local :locals,  
      lang : {},

        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
         var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];

         this.headers = [{
                    text: this.lang.TaskName,
                    align: 'start',
                    value: 'task_name',
                },
                {
                    text: this.lang.StartDate,
                    value: 'base_start_date'
                },
                {
                    text: this.lang.EndDate,
                    value: 'base_end_date'
                },
                {
                    text: this.lang.ActualStartDate,
                    value: 'actual_start_date'
                },
                {
                    text: this.lang.ActualEndDate,
                    value: 'actual_end_date'
                },
                {
                    text: this.lang.Actions,
                    value: 'actions',
                    sortable: false
                }
            ]

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));

        this.projectid = currentProject.id;
        this.initViewer();
    },
    watch: {
        '$store.state.currentCamera': function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));
            this.projectid = currentProject.id;
             this.project_name = currentProject.project_name;
            this.getTaskData();
        }
    },
    computed: {

    },
    methods: {
       allowedStep: m => m % 10 === 0,
        validate() {
            this.$refs.form.validate()
        },
        initViewer() {
            // console.log("init Viewer");
            this.preloader = true;
            this.getTaskData();
        },
        movetotab: function (url) {
            if (this.$route.path !== url) {
                this.$router.push(url)
                //console.log("current router ", this.$route.path);
                this.activelink = this.$route.path;
            }

            //console.log(url);
        },
        getTaskData() {
            this.preloader = true;
            axios({
                    url: '/project/' + this.projectid + '/get-task ',
                    method: 'GET'
                })
                .then(resp => {
                    this.preloader = false;
                    console.log("Resp", resp);
                    this.taskdata = resp.data.success;
                })
                .catch(err => {
                    console.log(err);
                    this.preloader = false;
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                })
        },
        newbimboxopen(data) {
            this.newbimbox = true;
            console.log(data);

            this.taskname = data.task_name;
            this.startdate = data.base_start_date;
            this.enddate = data.base_end_date;
            this.actualstartdate = data.actual_start_date;
            this.actualenddate = data.actual_end_date;
            this.updateTaskId = data.id;

        },
        addNewTask(){
          this.newtaskForm = true;
        },
        submitUpdateTask() {

            this.send = true;

            //  var d1 = new Date(this.startdate).toISOString().substr(0,19);
            //  var d2 = new Date(this.enddate).toISOString().substr(0,19);
            //  var d3 = new Date(this.actualstartdate).toISOString().substr(0,19);
            //  var d4 = new Date(this.actualenddate).toISOString().substr(0,19);

               var d1 = this.formater(this.startdate);
                var d2 = this.formater(this.enddate);
                var d3 = this.formater(this.actualstartdate);
                var d4 = this.formater(this.actualenddate);
 
            //console.log(d);

            let formData = new FormData();

            formData.append("tn", this.taskname);
            formData.append("id", this.updateTaskId);
            formData.append("d1", d1);
            formData.append("d2", d2);
            formData.append("d3", d3);
            formData.append("d4", d4);

         

            console.log("formdata", this.startdate);

            axios.post('/project/' + this.projectid + '/update_task', formData)
                .then(resp => {
                    console.log("Task update resonse", resp);

                    this.send = false;
                     this.alert =  true; 
                    this.alertmessage = this.lang.RecordUpdatesuccessfully;
                    this.newbimbox = false;
                    this.getTaskData();
                }) 
                .catch(err => {
                    this.send = false;
                    this.newbimbox = false;
                    console.log("Update Task Error", err)
                })
        },
        formater(rowdata){
                
                var dateUTC = new Date(rowdata);
                        dateUTC = dateUTC.getTime() 
                    var dateIST = new Date(dateUTC);
                    
                   var d1 =  dateIST.setHours(dateIST.getHours()); 
                 
                   var date = new Date(d1);
         
         d1 = date.getFullYear()+
           "-"+(this.addZero(date.getMonth()+1))+
           "-"+this.addZero(date.getDate())+
          " "+this.addZero(date.getHours())+
          ":"+this.addZero(date.getMinutes())+
          ":"+this.addZero(date.getSeconds());

          return d1;
        },
        submitNewsTask() {

            this.send = true;
            let formData = new FormData();

                // console.log(this.startdate);
                // console.log(this.enddate);

                var d1 = this.formater(this.startdate);
                var d2 = this.formater(this.enddate);

            formData.append("tn", this.taskname);
            formData.append("d1", d1);
            formData.append("d2", d2);
            
            console.log("formdata", formData);
             console.log("formdata", d1);
              console.log("formdata", d2);

            axios.post('/project/' + this.projectid + '/save_task', formData)
                .then(resp => {
                    console.log("Task update resonse", resp);

                    this.send = false;
                    this.newtaskForm = false;
                    this.alert =  true; 
                    this.alertmessage = this.lang.NewEventsuccess;
                    this.getTaskData();
                })
                .catch(err => {
                    this.send = false;
                    this.newtaskForm = false;
                    console.log("Update Task Error", err);
                    this.alert =  true; 
                    this.alertmessage = this.lang.SomethingWentWrong;
                })
        },
            addZero(i) {
            if (i < 10) {
            i = "0" + i;
            }
            return i;
            },
        onChange(event) {
            this.file = event.target.files ? event.target.files[0] : null;

        },
         async submit() {
            let childEl = this.$refs.filedata.innerHTML;
            // console.log(childEl);
            var userdata = {
                filedata: childEl
            }
            axios({
                    url: '/project/' + this.projectid + '/uplaod-task',
                    data: userdata,
                    method: 'POST'
                })

                .then(resp => {
                    console.log("Resp", resp);
                    if (resp.data.success) {

                        this.alert = true;
                        this.alertmessage = this.lang.taskUplaod;
                        this.alertType = "success"
                         this.getTaskData();
                         this.importFile = false

                    } else {

                        this.alert = true;
                        this.alertmessage = resp.data.error;
                        this.alertType = "error"
                         this.importFile = false
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
    }
};
</script>

<style>
.stepper_outer {
    display: flex;
    width: 100%;
    flex-: center;
    justify-content: space-between;
}

.steper_item {
    display: inline-flex;
    flex-direction: column;
    text-align: justify;
    z-index: 6;
   width: 95%;
    cursor:pointer;
     font-weight: 500;
}

.itcons {
    width: 95%;
    height: 50px;
    /* font-size: 33px; */
    border-radius:0;
    background: #fff;
    color: #000;
    text-align: center;
    line-height: 47px;
    margin: auto;
    margin-bottom: 11px;
    border: 2px solid #292929;
    transition: 0.5s;
    border-radius:4px;
}
.itcons i {
    color: #292929 !important;
}

.active .itcons {
   
    height: 50px;
    background: #292929;
    border: 2px solid #292929;
    box-shadow: 0 0 15px 5px #0000005c;
    transition: 0.5s;
    line-height: 47px;
}

.active .itcons i {
    color: #fff !important;
}

.active .steperItem {
    color: #fff !important;
    text-shadow: 0px 1px 20px #292929;
    font-weight: 500;
}

.stepper_outer {
    padding: 0 19px;
    position: relative;
}

.stepper_outer:after {
    /* content: "";
    display: block;
    height: 2px;
    background: #000;
    width: 88%;
    position: absolute;
    left: 58px;
    top: 26px;
    z-index: 1; */
}

.centerform {
    text-align: center;
    padding: 51px;
}

input[type="file"] {
    background: #ffc400;
    padding: 5px 17px;
    text-align: center;
}

.formcard {
    min-height: 92vh;
}
.mySearch {
    float: left;
    width: calc(100% - 448px);
}
.downloadbutons {
    background: #ffc400;
    color: #211a00;
    display: inline-block;
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    border-radius: 4px;
    line-height: 36px;
    font-size: 0.875rem;
    white-space: nowrap;
    font-weight: 500;
    letter-spacing: 1px;
    cursor:pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.itcons i {
    position: relative;
    left: 8px;
    top: -2px;
}
</style>

<template>
    <div>
    <div class="tataheader">
        <div class="tatalogo d4">
            <img class="tata logo_tt" :src="Tatalogonew" alt="tata projects"/>
        </div>
        <div class="trinetralogo d4">
            <img class="trinetra logo_tt" :src="Trinetra_Logo" alt="trinetra"/>
        </div>
        <div class="opticvyulogo d4">
            <span class="powerdby poppins-light">Powerd By</span>
            <img class="opticvyu logo_tt" :src="logo" alt="OpicVyu"/>
            <span class="loginbutton poppins-semibold"> Login </span>
        </div>
    </div>
    <div class="banner">
        <image src="../../assets/tata/Dib-Noida-airport-CEO-QA_PIC-A-2.-NIA-scaled.jpg"/>
    </div>
     <div class="constainer">
        <div class="trow">
          <div class="about tcol">
            <img :src="Trinetra_Logo"/>
          </div>
          <div class="about-text tcol">
            <p class="poppins-medium">Trinetra offers three digital solutions (i.e., its three digital eyes) to assist in accomplishing project objectives.</p>
          </div>
        </div>
     </div>
    </div>
</template>
  <script>
 import Trinetra_Logo from "../../assets/tata/Trinetra_Logo_final@2x.png";
 import logo from "../../assets/tata/OpticVyu_logo.png";
 import Tatalogonew from "../../assets/tata/Tatalogonew@2x.png";
  export default {
       name: "LandingPage",
        data: () => ({
            Trinetra_Logo : Trinetra_Logo,
            logo : logo,
            Tatalogonew : Tatalogonew
        }),
    created() {
      //  console.log("language");
    },
    methods: {

    }
  };
  </script>
  <style>
/* .tataheader {
    display: flex;
}
.d4{
    width :33%;
}
.d1{
    width :100%;
}
.d2{
    width :50%;
}
.d3{
    width :24%;
}
.logo_tt{

}
.trinetralogo {
    text-align: center;
}
img.trinetra.logo_tt {
    
    height: 77px;
    top: 6px;
}
.tata.logo_tt{
    width: 149px;
height: 77px;
top: 6px;
left: 50px;
}

img.opticvyu.logo_tt {
    width: 119px;
    height: 31px;
    top: 36px;
}
.logo_tt {
    position: relative;
}
.opticvyulogo.d4 {
    text-align: right;
}
.loginbutton {
    
    background: url(../../assets/tata/icons/feather-arrow-up-right@2x.png)#333399 80% 55% no-repeat padding-box;
    opacity: 1;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    padding: 6px 29px 6px 14px;
    border-radius: 14px;
    position: relative;
    top: 24px;
    left: -40px;
    margin-left: 59px;
    background-size: 9px;
}
.powerdby {
    font-size: 10px;
    position: relative;
    left: 53px;
}

.banner{
    width:100%;
    height: calc(100vh - 83px);
    background: url(../../assets/tata/Dib-Noida-airport-CEO-QA_PIC-A-2.-NIA-scaled.jpg);
    background-position: center center;
    background-size: 100% 120%;
    background-repeat: no-repeat;
    margin-top:5px;
}
.constainer{

    width:80%;
    margin: auto;
    max-width:1366px;

}
.tcol{
    display: block;
    float:left;
}
.about.tcol {
    width: 33%;
    padding: 15px;
}
.about-text.tcol {
    width: 66%;
} */

  </style>
<template>
  <div>
    <Preloader v-if="preloader" />
    <v-card>
      <v-tabs v-model="tab" background-color="amber accent-3" fixed-tabs dark>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1"> {{lang.DefaultTimelapse}} </v-tab>

        <v-tab  @click="setrouter_custom" v-if="custometab">
          {{lang.CustomTimelapse }}
        </v-tab>
        <v-tab @click="setrouter_archive">
          {{lang.ArchiveTimelapse}}
        </v-tab>
        <v-tab @click="setrouter" class="createtab">
          {{lang.CreateTimelapse}}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-snackbar
              v-model="snackbar"
              right
              top
              timeout="3000"
              type="success"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  {{lang.Ok}}
                </v-btn>
              </template>
            </v-snackbar>
            <div class="videostyle">
              <Pano class="mypano360d" v-if="checkcam360"  @on-load="onLoad" type='video' :source="timelapsurl"></Pano>
              <video
                class="videostyle"
                controls="true"
                ref="timelapsvideo"
                v-if="normalvideo"
              >
              </video>
              <!-- <v-btn
      class="mx-2 mixoperation download"
      fab
      dark
      small
      color="#ff5566"
      @click ="downloadTimelaps"
    >
      <v-icon dark>
        mdi-download-circle
      </v-icon>
    </v-btn> -->

              <v-btn
                class="mx-2 mixoperation share btndisplay"
                fab
                dark
                small
                color="#ff5566"
                @click="openbox"
              >
                <v-icon dark> mdi-share-variant </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>
        
      </v-tabs-items>
    </v-card>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="sharebox"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark>
            {{lang.ShareByEmail}}
            <span
              class="btn btn-info text-white copy-btn ml-auto"
              @click.stop.prevent="copyTestingCode"
            >
              <v-btn depressed color="primary"> {{lang.Copyurl}} </v-btn>
              <input type="hidden" id="testing-code" :value="timelapsurl" />
            </span>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.Send}}
                    <v-progress-circular
                      v-if="send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="sharebox = false" color="mydarkbutton">
                    {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
</div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import VueSweetalert2 from "vue-sweetalert2";
import { Pano } from 'vuejs-vr'
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
    Pano
  },
  name: "Timelaps",
  data() {
    return {
      preloader: true,
      regulertimelaps: "",
      currentcam: {},
      tab: null,
      details: {},
      response: {},
      custometab: true,
      sharebox: false,
      custometimelapsbox: false,
      movetimelapsebox: false,
      email: "",
      message: "",
      subject: "",
      emailrules: [
        (v) => !!v || this.lang.required,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      valid: true,
      timelapsurl: "",
      custometimelapsurl: "",
      send: false,
      useraccess: false,
      project: "",
      org: "",
      city: "",
      ct_name: "",
      ct_id: "",
      caption: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      checkcam360: false,
      normalvideo : true,
      timelapsvideourl:'',
      Local :locals,  
        lang : {},
      
    };
  },

  mounted() {
    var selectleng = this.$cookies.get('currentLanguage');
      this.lang = locals[selectleng]; 

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    this.initViewer();
    this.getTimelapsVideos();
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
    this.currentcam = this.$store.state.currentCamera;
    /*  if(this.currentcam.cam_360 == '1'){
          this.checkcam360 = true;
      }else{
        this.checkcam360 = false;
      } */
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      console.log('watch currentcamera', this.currentcam);
     /*  if(this.currentcam.cam_360 == '1'){
          this.checkcam360 = true;
      }else{
        this.checkcam360 = false;
      } */

      this.getTimelapsVideos();
      //console.log("camera parameters", this.currentcam);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      //console.log('currentProject', currentProject);

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
    },
  },
  computed: {
    params() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.timelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramscustome() {
      return {
        to: this.email,
        message: "",
        subject: this.subject,
        attachment: this.custometimelapsurl,
        project: this.project,
        org: this.org,
        city: this.city,
      };
    },
    paramsmovearchive() {
      return {
        c_dir: this.currentcam.camera_prefix,
        vid: this.ct_name,
        ct_id: this.ct_id,
        st_dt: this.details.start_date + this.details.start_time,
        end_dt: this.details.end_date + this.details.end_time,
        speed: this.details.timelapse_fps,
        quality: this.details.quality,
        cam_id: this.currentcam.camera_id,
        //usr: '',
        caption: this.caption,
        //t_ad_edit : this.details.Advance Editing,
        //t_frame : this.details.timelapse_fps,
        // t_smooth : this.details.timelapse_fps,
        //t_deflicker : this.details.timelapse_fps,
      };
    },
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
      this.preloader = false;

      let currentProject = JSON.parse(localStorage.getItem("currentProject"));

      this.project = currentProject.project_name;
      this.org = currentProject.company;
      this.city = currentProject.city;
      this.current_cam = this.$store.state.currentCamera;
      //console.log('camera parameters',  this.currentcam);
    },
    getTimelapsVideos() {
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = false;
      //var camid = this.currentcam.camera_id;
      // console.log("current Camera", this.currentcam);
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/list/timelapse",
        method: "GET",
      })
        .then((resp) => {
          console.log(resp.data.success);
          this.response = resp.data.success;
            var _self = this;
          if(this.currentcam.cam_360 == '1'){
            console.log("360pano", resp.data.success.timelapse.url);
            this.checkcam360 = true;
            this.normalvideo = false;
         }else{
              console.log("normal vid", resp.data.success.timelapse.url);
              this.checkcam360 = false;
              this.normalvideo = true;
               setTimeout(() => {
                 _self.$refs.timelapsvideo.src = resp.data.success.timelapse.url;
               }, 500);
          }
        //  this.$refs.timelapsvideo.src = resp.data.success.timelapse.url;
          this.timelapsurl = resp.data.success.timelapse.url;
          if (resp.data.success.custom_timelapse == "Not enabled.") {
            this.custometab = false;
          } else {
            this.custometab = true;
            //this.$refs.custome_timelapsvideo.src =
              resp.data.success.custom_timelapse.url;
            this.custometimelapsurl = resp.data.success.custom_timelapse.url;
            this.details = resp.data.success.custom_timelapse.details;
            this.ct_name = resp.data.success.custom_timelapse.ct_name;
            this.ct_id = resp.data.success.custom_timelapse.ct_id;
          }

          // this.$refs.timelapsvideo.play()
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadTimelaps() {
      const myImage = this.response.timelapse.url;

      var res = myImage.split("?");

      var filenames = res[0].split("/");

      var filename = filenames[filenames.length - 1];

      // console.log(filename);

      //  console.log("Edit data", myImage);
      var a = document.createElement("a"); //Create <a>
      a.href = "data:video/mp4," + myImage; //Image Base64 Goes here
      a.download = filename; //File name Here
      a.target = "_blank";
      a.download;
      a.click(); //Downloaded file
    },
    downloadCustomeTimelaps() {
      const myImage = this.response.custom_timelapse.url;

      var res = myImage.split("?");

      var filenames = res[0].split("/");

      var filename = filenames[filenames.length - 1];

      //console.log(filename);

      //  console.log("Edit data", myImage);
      var a = document.createElement("a"); //Create <a>
      a.href = "data:video/mp4," + myImage; //Image Base64 Goes here
      a.download = filename; //File name Here
      a.target = "_blank";
      a.download;
      a.click(); //Downloaded file
    },
    setrouter: function () {
      if(this.useraccess){
          this.$router.push("/dashboard/settings/create-timelapse");
       }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      //console.log(url);
    },
    setrouter_archive: function () {
        if(!this.userdata.open_access == 1){
          this.$router.push("/dashboard/timelapse/archive-timelapse");
       }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      // this.$router.push("/dashboard/timelapse/archive-timelapse");
      //console.log(url);
    },
    setrouter_custom: function () {
      this.$router.push("/dashboard/timelapse/custom-timelapse");
      //console.log(url);
    },

    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharetimelaps(this.params); // action to login
      }
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        //alert("timelapse url was copied " + msg);
        this.snackbar = true;
        this.text = this.lang.copymsg;
      } catch (err) {
        // alert("Oops, unable to copy");
        this.snackbar = true;
        this.text = this.lang.SomethingWentWrong;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    sharetimelaps(sharedata) {
      console.log("data", sharedata);
      this.send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-with-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.sharebox = false;
          this.send = false;
        })
        .catch((err) => {
          console.log(err);
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.EmailFaild;
        });
    },
    openbox() {
      this.sharebox = true;
    },
    openboxSecond() {
      this.custometimelapsbox = true;
    },
    openboxthird() {
      this.movetimelapsebox = true;
    },
    async submitcustometimelaps() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.sharecustometimelaps(this.paramscustome); // action to login
      }
    },
    sharecustometimelaps(sharedata) {
      console.log("data", sharedata);
      this.send = true;

      axios({
        url: "http://secure.opticvyu.com/api/send-with-link",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.custometimelapsbox = false;
          this.send = false;
        })
        .catch((err) => {
          console.log(err);
          this.send = false;
          this.snackbar = true;
          this.text = this.lang.EmailFaild;
        });
    },
    async submitmovetimelaps() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.movecustometimelapse(this.paramsmovearchive); // action to login
      }
    },
    movecustometimelapse(movedata) {
      console.log("data", movedata);
      //this.send = true;

      axios({
        url: "/camera/" + this.currentcam.camera_id + "/move/archive_timelapse",
        data: movedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.movetimelapsebox = false;
          //this.send = false;
          if (resp.data.success) {
            this.$swal({
              icon: "info",
              title: "success",
              html: this.lang.copysuccess,
              showConfirmButton: true,
            });
          } else {
            this.$swal({
              icon: "info",
              title: "error",
              html: this.lang.SomethingWentWrong,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          //this.send = false;
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
          this.$swal({
            icon: "info",
            title: "error",
            html: this.lang.SomethingWentWrong,
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>
<style>
.videostyle {
  margin: auto;
  display: block;
  width: 100%;
}
video {
  max-width: 100%;
  height: 85.6vh;
}
.detailsBox {
  position: absolute;
  right: 0;
  top: 0;
  background: #ebe9e9;
  box-shadow: 0 0 5px 3px #ccc;
  border-radius: 5px;
  padding: 6px 20px;
  opacity: 0.5;
}

.detailsBox:hover {
  opacity: 1;
}
.mixoperation {
  position: absolute;
}
.download {
  top: 36%;
}
.share {
  top: 45%;
}
.move {
  top: 35%;
}
.createtab {
  color: #7e7a7a !important;
}
.mypano360d{
    height:500px !important;
    width: 100%;
}
.btndisplay{
  z-index: 1;
  display: block;
}
</style>


<template>
    <div class="container">
      <div
        class="box"
        :style="boxStyle"
        @mousedown="startDragging"
        @mouseup="stopDragging"
        @mousemove="dragging"
        @mouseleave="stopDragging"
        @wheel="handleWheelZoom"
      >
        <div class="content">
          <!-- Your box content goes here -->
          <h1>Zoom and Drag Content</h1>
          <p>This box can be zoomed (with mouse wheel) and dragged.</p>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      zoomLevel: 1,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      translateX: 0,
      translateY: 0,
    };
  },
  computed: {
    boxStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`,
        cursor: this.isDragging ? "grabbing" : "grab",
      };
    },
  },
  methods: {
    // Zoom in/out by wheel scroll
    handleWheelZoom(event) {
      event.preventDefault();
      const zoomChange = event.deltaY > 0 ? -0.1 : 0.1;
      if (this.zoomLevel + zoomChange >= 0.1 && this.zoomLevel + zoomChange <= 3) {
        this.zoomLevel += zoomChange;
      }
    },
    startDragging(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX - this.translateX;
      this.dragStartY = event.clientY - this.translateY;
    },
    stopDragging() {
      this.isDragging = false;
    },
    dragging(event) {
      if (this.isDragging) {
        this.translateX = event.clientX - this.dragStartX;
        this.translateY = event.clientY - this.dragStartY;
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  width: 400px;
  height: 300px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow: hidden;
  user-select: none;
  position: relative;
}

.content {
  padding: 20px;
  font-size: 16px;
}

button {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
}
</style>
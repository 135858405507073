<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="vhfix">
          <v-card-title>
            {{ lang.UserLogs }}
          </v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
              <!-- <ejs-daterangepicker
                :change="onValueChange" 
                :minDays="min"
                :maxDays="max"
                :strictMode="mode"
                :placeholder="lang.selectRange"
              >
              </ejs-daterangepicker> -->
              <date-range-picker
          @update="onValueChange"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
      v-model="chartdatevalue"
      :date-format="dateFormat"
  ></date-range-picker>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            sort-by="firstname"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:item.user_type="{ item }">
              <v-chip :color="getColor(item.user_type)" dark>
                {{
                  item.user_type == 1
                    ? lang.User
                    : item.user_type == 0
                    ? lang.Admin
                    : lang.SuperAdmin
                }}
              </v-chip>
            </template>
          </v-data-table>

          <v-btn @click="getUserLogs('')" v-if="desserts.length == 0">
            {{ lang.Reset }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
//import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";

export default {
  components: {
    Preloader
  },
  name: "UserLogs",
  data() {
    return {
      preloader: false,
      items: {},
      search: "",

      headers: [
        {
          text: "Project",
          align: "start",
          value: "project_name"
        },
        { text: "Module", value: "project_type" },
        { text: "Page", value: "visit_page" },
        { text: "Date", value: "created_at" }
      ],
      current_cam: "",
      currentProject: "",
      desserts: [],
      max: "8",
      min: "1",
      waterMark: "Select a Range",
      currentcam: "",
      Local: locals,
      lang: {},
      chartdatevalue : {}
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    (this.headers = [
      {
        text: this.lang.Project,
        align: "start",
        value: "project_name"
      },
      { text: this.lang.Module, value: "project_type" },
      { text: this.lang.Page, value: "visit_page" },
      { text: this.lang.Date, value: "created_at" }
    ]),
      (this.currentProject = JSON.parse(
        localStorage.getItem("currentProject")
      ));
    // this.getUserLogs();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    if (this.userdata.user_code == 2) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
  },
  watch: {
    "$store.state.currentCamera": function() {
      this.currentcam = this.$store.state.currentCamera;
      console.log("Current data get");
       this.CurrentDateFunction();
      this.getUserLogs();
    }
  },
  created() {
    this.CurrentDateFunction();
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.currentcam = this.$store.state.currentCamera;
    this.getUserLogs();
  },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
     
      CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 7);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

             this.chartdatevalue = {
                startDate : start,
               endDate : end
             }

      },
    onValueChange: function(args) {
      if (args.startDate) {
        this.getUserLogs(args);
      }
    },
    getColor(calories) {
      if (calories == 0) return "#ec665cfc";
      else if (calories == 1) return "#8bc34ac2";
      else return "#ff9800";
    },
    getUserLogs: function(args) {
      this.preloader = true;
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      console.log("user Logs Dataaa- ");
      this.current_cam = this.$store.state.currentCamera;
      var data = {};
      if (args) {
        console.log("args", args);

        var  start = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
        var  end = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

        console.log(start);
        console.log(end);
        data = {
          cam_id: this.current_cam.camera_id,
          start: start,
          end: end,
          project_type: "Cunstruction Camera"
        };
      } else {
        data = {
          cam_id: this.current_cam.camera_id,
          project_type: "Cunstruction Camera"
        };
      }

      axios({
        url: `/user-logs/${this.currentProject.id}`,
        method: "GET",
        params: data
      })
        .then(resp => {
          console.log("User Logs", resp.data.success);
          this.desserts = resp.data.success;
          this.preloader = false;
        })
        .catch(err => {
          console.log("user Logs error", err);
          this.preloader = false;
        });
    }
  }
};
</script>
<style>
.vhfix {
  max-height: 91vh;
  overflow: auto;
}
.myeditform .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.myeditform .v-card__text {
  padding-bottom: 0 !important;
}
</style>

<template>
<div>
    <Preloader v-if="preloader" />
     <v-snackbar v-model="alert"  top right timeout="3000" type="success">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
      <div class="pa-5">
        <v-row>
            <v-col  cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <h2 :class="`hetitlec ${ showlinechart ? 'active':''}`"  @click="showlinechartfun">Line Chart</h2>
            </v-col>
             <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <h2 :class="`hetitlec ${ showHeatChart ? 'active':''}`" @click="heatChartData('person')">Heatmap</h2>
            </v-col>

             <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <h2 class="hetitlec" @click="setrouter('/dashboard/ai-reports')">Reports</h2>
            </v-col>
              
            
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-select
                    :items="selectoptions"
                    label="Select Date"
                    @change="Onedaydat"
                    v-model="selecteditem"
                    dense
                    ></v-select>
            </v-col>
               <div class="helpinfo">
                <v-icon dark class="helps" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                <p>AI-detection accuracy depends on many parameters. Detection is possible for objects up to 200 metres away; however, high accuracy is expected for <br />
                 <br />
                 <ul>
                   <li>Persons &#60; 40 metres away. </li>
                   <li>Excavators, dump-trucks, car, loaders, rollers, small telehandlers &#60; 60 metres away. </li>
                   <li>Cranes, large telehandlers, cranes &#60; 80 metres away. </li>
                   <li>High accuracy is expected with 24MP cameras. 4MP & 8MP cameras detect lesser objects with poor accuracy.</li>
                 </ul>
                <!-- <span> &#9673;  </span>
                <br />
                <span> &#9673; </span>
                <br />
                <span> &#9673; </span> -->
               </p>
              </div>
            <v-col  class="cdaterangepicker datepicker" cols="12" xs="12" sm="2" md="2" lg="2" xl="2" v-if="showDatepicker">
                <!-- <ejs-daterangepicker :change="onValueChange" :minDays="minday" :maxDays="maxday" :max="max"  :strictMode="mode" placeholder="Custom Date Range">
                </ejs-daterangepicker> -->
              <date-range-picker
              @update="onValueChange"
              :ranges = "false"
              opens="left"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              v-model="chartdate"
              :date-format="dateFormat"
              ></date-range-picker>
                <p style="color:#d4425b;font-size:11px"> {{lang.max7}} </p>
            </v-col>

            
        </v-row>
        <v-row>
          
               <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <div id="chartbar" v-if="showChart">
                     <v-row>
                      <v-col cols="3" xs="4" sm="2" md="2" lg="1" xl="1" v-if="showlinechart">
                        <v-checkbox
                        v-model="activeperson"
                        class="ckeck"
                        label="Person"
                        @change="checkFilter()"
                        ></v-checkbox>
                     </v-col>
                      <v-col cols="3" xs="4" sm="2" md="2" lg="2" xl="2">
                        <v-select
                         :items="items"
                          label="Select Vehicle"
                            item-text="title"
                            item-value="text"
                            v-model="activeVehicle"
                            @change="checkFilter()"
                                return-object
                                single-line
                          
                          dense
                        ></v-select>
                       
                     </v-col>
                      <v-col cols="3" xs="4" sm="2" md="2" lg="2" xl="2"  v-if="!showlinechart">
                            <v-select
                              :items="watheritems"
                                label="Select Weather"
                                  item-text="title"
                                  item-value="text"
                                  v-model="heatmapactiveweather"
                                  @change="changeweatherforheatmap()"
                                      return-object
                                      single-line
                                
                                dense
                              ></v-select>
                         </v-col>
                  
                      </v-row> 
                      <div class="max">
                   <apexchart v-if="showlinechart && loadigndata" ref="lineChart" type="line" height="350"  @click="clickHandler"  :options="chartOptions" :series="series"></apexchart>
                       <v-row>
                        <v-col cols="12" xs="12" sm="6" md="2" lg="2" xl="2">
                          <h4  v-if="showlinechart && weatherloadigndata"> Weather Data  </h4>
                             <div   v-if="showlinechart && weatherloadigndata" class="helpinfoweather">
                <v-icon dark class="weatherhelp" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                <p> Data is accessed through OpenWeatherMap service. In order to get exact site data, update the site latitude - longitude. <br> 
                <span class="hetitlec active"  @click="setrouter('/dashboard/settings/profile')" > Update Site Location </span> 
                </p>
              </div>
                        </v-col>     
                       <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3" v-if="showlinechart">
                        <v-select
                         :items="watheritems"
                          label="Select Weather"
                            item-text="title"
                            item-value="text"
                            v-model="activeweather"
                            @change="checkweatherFilter()"
                                return-object
                                single-line
                          
                          dense
                        ></v-select>
                           </v-col>
                           
                        </v-row>     
                    
                   <apexchart v-if="showlinechart && weatherloadigndata" ref="weatherChart" type="line" height="350"    :options="weatherOptions" :series="weatherseries"></apexchart>
                     
                      <v-row>
                        <v-col cols="12" xs="12" sm="6" md="2" lg="2" xl="2">
                           <h4  v-if="showlinechart && sqiloadigndata"> Air Quality Index  </h4>
                             <div class="helpinfoaqi" v-if="showlinechart && sqiloadigndata">
                <v-icon dark class="aqihelp" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                <p> Data is accessed through OpenWeatherMap service. In order to get exact site data, update the site latitude - longitude.<br> 
                 <span class="hetitlec active"  @click="setrouter('/dashboard/settings/profile')" > Update Site Location </span> </p>
                 
               </div>
                        </v-col>     
                       </v-row>
                      
                   
                     <apexchart v-if="showlinechart && sqiloadigndata" ref="aqiChart" type="line" height="350"   :options="aqiOptions" :series="aqiseries"></apexchart>
                    <!-- <p class="weathernote" v-if="showlinechart"> (Max. 7 days weather data can be displayed at a time) </p> -->
                       <apexchart v-if="showHeatChart" type="heatmap" height="550"  @click="clickHeatChart"  :options="heatchartOptions" :series="heatseries"></apexchart>
                        <h4  v-if="shoWeatherHeatChart && showHeatChart"> {{weatherheatchartOptions_title}} </h4>

                       <apexchart v-if="shoWeatherHeatChart && showHeatChart" type="heatmap" height="550" :options="weatherheatchartOptions" :series="weatherheatseries"></apexchart>
                     
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>          
        </div>

        <div class="pop" v-if="openImage">
         <v-btn icon color="#000" @click="openImage = false" class="oveicon clsoe">
            <v-icon>mdi-close</v-icon>
        </v-btn>
          
          <v-zoomer :zoomingElastic="false" ref="zoomer"  style="width: 100%; height: 99vh; top: -47px" pivot="image-center">
              <img  
              id="plan2" 
              :src="openImage_url"
              @load="loaded" 
              style="object-fit: contain; width: 100%; height: 100%"
              />
        </v-zoomer>
        </div>
          <v-btn color="#292929" @click="heatImagesDatas = []" v-if="heatImagesDatas.length > 0" class="timelineclose">
            <v-icon color="#ffffff">mdi-close</v-icon>
        </v-btn>
        <carousel class="timelinec" :key="refresh"  :responsive="{0:{items:2,nav:false},600:{items:3,nav:true},1000:{items:8,nav:true, slideBy:7}}" :autoplay="false"  :dots="false" :margin="8" :navText="['', '']" v-if="heatImagesDatas.length > 0 && (selecteditem == 'Last 30 Days' || selecteditem == 'Custom Date Range' ) && diffDays > 10 ">
            <div v-for="(image, index) in heatImagesDatas" :key="index" @click="selectThumainal(index)">
                <div class="fixImageBox">
                   <img :src="image.thumb" />
                  </div>
                <p class="disply_date">{{ timefrmat(image.image_creation_time)}}</p>
            </div>
        </carousel>
         <v-snackbar v-model="imageloaded"  @click="goDown" bottom right timeout="10000" type="success" max-width="100" width="150">
         Image Loading
        <template>
            <v-btn icon  large color="#fff" @click="goDown" class="oveicon clsoe">
            <v-icon  x-large>mdi-arrow-down-bold-circle</v-icon>
        </v-btn>
        </template>
    </v-snackbar>

         <v-btn icon large color="#292929" @click="backtoTop" class="backtotop" title="Back To Top">
            <v-icon large>mdi-arrow-up-bold-circle</v-icon>
        </v-btn>
 </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Preloader from '../../components/PreLoader';
import VueApexCharts from 'vue-apexcharts'
import { locals } from "../../utils/locals";

import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
 import  SelectorPack  from '@recogito/annotorious-selector-pack';
 import ShapeLabelsFormatter from '@recogito/annotorious-shape-labels'
 import VueZoomer from "vue-zoomer";
 import carousel from "vue-owl-carousel";
//import lodash from 'lodash'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueZoomer);
//var self = this
export default {
    components: {
        Preloader,
        apexchart: VueApexCharts,
        carousel,DateRangePicker
    },
    name: "AiData",
    data() {
        return {
          chartdate : {},
             mode: true,
            showChart: true,
            currentcam: {},
            preloader: false,
            Local :locals,  
            lang : {},
            series: [
            {
              name: "Person",
              data: []
            },
            {
              name: 'Vehicle',
              data: []
            }
          ],
          weatherseries: [
            {
              name: "clouds",
              data: []
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
               toolbar: {
                        show: false
                    },
              zoom: {
                enabled: false
              },
            },
            colors: ['#f8bb03', '#000000'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [3,3],
              curve: 'smooth',
              dashArray: [0, 0]
            },
            title: {
              text: '',
              align: 'left'
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: [],
               tickPlacement: 'between',
               tickAmount: 25,
                labels: {
          show: true,
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
                }
            },
          yaxis: {
              labels: {
              formatter: function(val) {
                return Math.floor(val)
              }
              }
          },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },

          }, // chart option end 

          weatherOptions: {
            chart: {
              height: 350,
              type: 'line',
               toolbar: {
                        show: false
                    },
              zoom: {
                enabled: false
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [3,3,3,3],
              curve: 'smooth',
              dashArray: [0, 0, 0,0]
            },
            title: {
              text: '',
              align: 'center'
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: [],
               minHeight: 120,
               tickPlacement: 'between',
               tickAmount: 25,
          labels: {
              show: true,
              trim: false,
              rotate: 0,
              minHeight: 40,
              hideOverlappingLabels: true,
          }
            },
             yaxis: {
              labels: {
              formatter: function(val) {
                return Math.floor(val)
              }
              }
           },
            tooltip: {
              y: [
                
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },

          }, // weather chart option end 
           aqiseries : [
            {
              name: "PM2.5",
              data: []
            },
             {
              name: "PM10",
              data: []
            },
             {
              name: "CO",
              data: []
            },
             {
              name: "NO",
              data: []
            },
             {
              name: "NO2",
              data: []
            },
             {
              name: "O3",
              data: []
            },
             {
              name: "SO2",
              data: []
            }
            
          ],
           sqiloadigndata : false,
           aqiOptions : {
             chart: {
              height: 350,
              type: 'line',
               toolbar: {
                        show: false
                    },
              zoom: {
                enabled: false
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [3,3,3,3],
              curve: 'smooth',
              dashArray: [0, 0, 0,0]
            },
            title: {
              text: '',
              align: 'center'
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: [],
               minHeight: 120,
               tickPlacement: 'between',
               tickAmount: 25,
          labels: {
              show: true,
              trim: false,
              rotate: 0,
              minHeight: 40,
              hideOverlappingLabels: true,
          }
            },
             yaxis: {
              labels: {
              formatter: function(val) {
                return Math.floor(val)
              }
              }
           },
            tooltip: {
              y: [
                
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },
          }, // aqi chart option end 
          
           personsArray: [],
           vehicleArray: [],
           selectoptions :  ['Today', 'This Week', 'Last 30 Days', 'Custom Date Range'],
           selecteditem : 'This Week',
                items: [
                         { title: 'Person', text: 'person'},
                         { title: 'All Construction Vehicles', text: 'construction_vehicles'},
                        { title: 'Bus', text: 'bus'},
                        { title: 'Car', text: 'car'},
                        { title: 'Crane', text: 'crane'},
                        { title: 'Concrete Mixer', text: 'concrete_mixer'},
                        { title: 'Dump Truck', text: 'dump_truck'},
                        { title: 'Excavator', text: 'excavator' },
                        { title: 'Loader', text: 'loader'},
                        { title: 'Pile Driver', text: 'pile_driver' },
                        { title: 'Rollers', text: 'rollers'},
                        { title: 'Telehandler', text: 'telehandler'}
                      ],  
              selectedVehicle: '',
              charData : {},
              diffDays : 0,
              maxday: '31',
              minday: '1',
        
              max :  new Date(new Date().getFullYear(), new Date().getMonth()+1,0),
               heatseries: [
                {
                name: '00',
                data: []
               },
               {
              name: '01',
              data: []
               },
            {
              name: '02',
              data: []
            },
             {
              name: '03',
              data:  []
             },
             {
              name: '04',
              data:  []
             },
             {
              name: '05',
              data:  []
             },
             {
              name: '06',
              data:  []
             },
             {
              name: '07',
              data:  []
             },
             {
              name: '08',
              data:  []
             },
             {
              name: '09',
              data:  []
             },
             {
              name: '10',
              data:  []
             },
             {
              name: '11',
              data:  []
             },
             {
              name: '12',
              data:  []
             },
             {
              name: '13',
              data:  []
             },
             {
              name: '14',
              data:  []
             },
             {
              name: '15',
              data:  []
             },
             {
              name: '16',
              data:  []
             },
             {
              name: '17',
              data:  []
             },
             {
              name: '18',
              data:  []
             },
             {
              name: '19',
              data:  []
             },
             {
              name: '20',
              data:  []
             },
             {
              name: '21',
              data:  []
             },
             {
              name: '22',
              data:  []
             },
             {
              name: '23',
              data:  []
             }
          ],

          heatchartOptions: {
            chart: {
              height: 350,
              type: 'heatmap',
              toolbar: {
                        show: false
                    }
            },
            
             xaxis: {
              type: 'category',
              categories: [],
               tickPlacement: 'between',
            },
            yaxis: {
                 title: {
                   text: "Hours"
                 }
            },
            plotOptions: {
              heatmap: {
                shadeIntensity: 1,
                radius: 2,
                useFillColorAsStroke: false,
                
                colorScale: {
                  ranges: [{
                      from: 40,
                      to: 100,
                      name: 'Extreme',
                      color: '#FA5733'
                    },
                    {
                      from: 15,
                      to: 40,
                      name: 'High',
                      color: '#FA9533'
                    },
                    {
                      from: 5,
                      to: 15,
                      name: 'Medium',
                      color: '#FACC33'
                    },
                    {
                      from: 0,
                      to: 5,
                      name: 'Low',
                      color: '#F9FF95'
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 1
            },
            title: {
              text: ''
            },
          },

           weatherheatseries : '',

           weatherheatchartOptions: {
             
            chart: {
              height: 350,
              type: 'heatmap',
              toolbar: {
                        show: false
                    }
            },
            
             xaxis: {
              type: 'category',
              categories: [],
               tickPlacement: 'between',
             
            },
            yaxis: {
                 title: {
                   text: "Hours"
                 }
            },
            plotOptions: {
              heatmap: {
                shadeIntensity: 1,
                radius: 2,
                useFillColorAsStroke: false,
                
                colorScale: {
                  ranges: [{
                      from: 40,
                      to: 100,
                      name: 'Extreme',
                      color: '#FA5733'
                    },
                    {
                      from: 15,
                      to: 40,
                      name: 'High',
                      color: '#FA9533'
                    },
                    {
                      from: 5,
                      to: 15,
                      name: 'Medium',
                      color: '#FACC33'
                    },
                    {
                      from: 0,
                      to: 5,
                      name: 'Low',
                      color: '#F9FF95'
                    }
                  ]
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 1
            }
          },

            showlinechart: true,
            loadigndata :  false,
            weatherloadigndata :  false,
            showHeatChart: false,
            shoWeatherHeatChart : false,
            heatchartTitle : 'Person',
            openImage_url : '',
            openImage : false,
             anno: null,
             meta : {},
             activeperson : true,
             activeVehicle :  { title: 'All Construction Vehicles', text: 'construction_vehicles'},
             heatImagesDatas : [],
             refresh : 1,
             alertmessage : '',
             alert:'',
             currentProject : '',
             watheritems: [
                { title: 'Clouds (%)', text: 'clouds'}, { title: 'Wind (meter/sec)', text: 'wind'}, { title: 'Rain(mm)', text: 'rain'}, { title: 'Snow(mm)', text: 'snow'}, { title: 'Temperature (°C)', text: 'temp'}
             ],
             wetherData : '',
             showDatepicker : false,
             activeweather : 'clouds',
             heatmapactiveweather : { title: 'Clouds (%)', text: 'clouds'},
             chartdates : [],
             imageloaded : false,
             loopcollection : [],
             aqiloopcollection : [],
             weatherheatchartOptions_title : 'Weather Heatmap',
             aqiloadigndata : false,
             formattedDate:"",
             currentProjectTimezone:"",


        }
    
    },
    mounted() {
        var selectleng = this.$cookies.get('currentLanguage');
        this.lang = locals[selectleng];

        this.currentcam = this.$store.state.currentCamera;
        this.currentProjectTimezone= JSON.parse(localStorage.getItem("currentProject")).timezone;


        document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                this.dialog = false;
            }
        })
        this.userdata = JSON.parse(localStorage.getItem('userdata'))
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
        this.calculateDate();
         this.Onedaydat('This Week');
        
    },
    created() {

         this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
         this.calculateDate();
         this.CurrentDateFunction();
        this.presets = this.$store.state.currentCamera.preset_ids.split(",");
    },
    watch: {
        '$store.state.currentCamera': function (c) {

            console.log(c);

            this.currentcam = this.$store.state.currentCamera;
            this.calculateDate();
            this.CurrentDateFunction();
            // if (this.currentcam.ppe == 0) {
            //     this.$router.push('/dashboard');
            // }

           // console.log("this.currentcam =", this.currentcam);
           // this.getCurrentProjectData('');
           
            this.presets = this.$store.state.currentCamera.preset_ids.split(",");
              this.Onedaydat('This Week');
             
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        params() {
            return {
                aidate: this.aidate,
                presetid: this.presetid ? this.presetid : 1,
                image_type: this.image_type
            }
        }
    },
    methods: {
      calculateDate() {
        let localDate = this.calculateLocalDate(this.currentProjectTimezone);
        this.formattedDate = this.formatLocalDate(localDate);
        // You can use localDate in any further logic as needed
      },
      calculateLocalDate(timezoneOffset) {
        // Calculate current date in UTC
        let utcCurrentDate = new Date();

        // Calculate local date based on timezone offset
        let localOffset = utcCurrentDate.getTimezoneOffset() / 60; // Convert minutes to hours
        let offsetDiff = timezoneOffset - localOffset;
        let localTime = utcCurrentDate.getTime() + (offsetDiff * 60 * 60 * 1000);
        return new Date(localTime);
      },
      formatLocalDate(date) {
        // Function to format date as dd/mm/yyyy
        let day = date.getDate();
        let month = date.getMonth() + 1; // Months are zero indexed
        let year = date.getFullYear();

        // Ensure two digit format
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${day}-${month}-${year}`;
      },
      dateFormat(classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
      CurrentDateFunction() {
        // var today = new Date();
        // today.setDate(today.getDate() - 7);
        // var dd = String(today.getDate()).padStart(2, '0');
        // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var yyyy = today.getFullYear();
        // var start = mm+'-' +dd+'-'+yyyy;

        // Convert originalDate string to a Date object
        let dateParts = this.formattedDate.split('-');
        let originalDateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // Corrected order and month indexing

        // Subtract daysToSubtract days from originalDateObj
        let resultDateObj = new Date(originalDateObj);
        resultDateObj.setDate(originalDateObj.getDate() - 6);

        // Format resultDateObj back into mm-dd-yyyy format
        let dd = resultDateObj.getDate();
        let mm = resultDateObj.getMonth() + 1; // Months are 0-indexed
        let yyyy = resultDateObj.getFullYear();

        // Ensure mm and dd are two digits
        if (mm < 10) {
          mm = '0' + mm;
        }
        if (dd < 10) {
          dd = '0' + dd;
        }

        var start = `${mm}-${dd}-${yyyy}`;

        //     var date = new Date();

        //     var edd = String(date.getDate()).padStart(2, '0');
        // var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var eyyyy = date.getFullYear();
        // var end = emm+'-'+edd+'-'+eyyyy;
        // Assuming this.formattedDate is in 'yyyy-mm-dd' format
        let endDateParts = this.formattedDate.split('-'); // Split the date string
        let year = endDateParts[2];
        let month = endDateParts[1];
        let day = endDateParts[0];

        // Ensure month and day are two digits
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        // Format the date into mm-dd-yyyy
        var end = `${month}-${day}-${year}`;

        this.reportdate = {
          startDate: start,
          endDate: end
        }
        this.chartdate = {
          startDate: start,
          endDate: end
        }

      },
         getAnnotations(){
               this.checkAnnotaionImage();
         },
         checkAnnotaionImage() {
         
             var img = document.getElementById("plan2");

            // var mwidth = 380;
            // var mheight = 252;

             //"xywh=pixel:804.95849609375,721.810546875,502.2828369140625,558.8311767578125"
            // class_id center_x center_y bbox_width bbox_height
            // 5 0.408 0.30266666666666664 0.104 0.15733333333333333
     //{"class_id":"2","center_x":"0.7325","center_y":"0.437375","box_width":"0.007","box_height":"0.01775"}
             //console.log(" item.body[0].value",  item.body[0].value);  

            var mwidth = img.naturalWidth;
            var mheight = img.naturalHeight;
           var value = '';
           var vvdata = [];

           
           this.meta.forEach(item => {

            if(item.class_id == 0){
                value = 'car';
              }else  if(item.class_id == 1){
                value = 'bus';
              }else  if(item.class_id == 2){
                value = 'person';
              }else  if(item.class_id == 3){
                value = 'excavator';
              }else  if(item.class_id == 4){
                
                value = 'telehandler';
              }else  if(item.class_id == 5){
              
                 value = 'loader';
              }else  if(item.class_id == 6){
                
                 value = 'dump_truck';
              }else  if(item.class_id == 7){
               
                 value = 'concrete_mixer';
              }
              else  if(item.class_id == 8){
               
                value = 'pile_diver';
              }
              else  if(item.class_id == 9){
                
                value = 'crane';
              }
              else  if(item.class_id == 10){
                
                 value = 'rollers';
              }
              else  if(item.class_id == 11){
                
                value = 'helmet';
             }
             else  if(item.class_id == 12){
                
                value = 'jacket';
             }
             else  if(item.class_id == 13){
                
                value = 'belt';
             }
             else  if(item.class_id == 14){
                
                value = 'pile';
             }
             else  if(item.class_id == 15){
                
                value = 'pile_cap';
             }
             else  if(item.class_id == 16){
                
                value = 'pier_cage';
             }
             else  if(item.class_id == 17){
                
                value = 'pier';
             }
             else  if(item.class_id == 18){
                
                value = 'pier_cap';
             }
             else  if(item.class_id == 19){
                
                value = 'deck';
             }

                        var w = item.box_width * mwidth;
                        var h = item.box_height * mheight;

                      //   console.log("x,y,h,w",x,y,h,w);

                    var x = ((2 * item.center_x * mwidth) - w)/2;
                    var y = ((2 * item.center_y * mheight) - h)/2;

                  var r = (Math.random() + 1).toString(36).substring(7);
                  vvdata.push({
        "@context": "http://www.w3.org/ns/anno.jsonld",
        "type": "Annotation",
        "body": [
            {
                "type": "TextualBody",
                "value": value,
                "purpose": "tagging"
            }
        ],
        "target": {
            "source": this.openImage_url,
            "selector": {
                "type": "FragmentSelector",
                "conformsTo": "http://www.w3.org/TR/media-frags/",
                "value": "xywh=pixel:"+x+","+y+","+w+","+h
            }
        },
        "id": "#"+r
        });

        });

        
        this.anno = new Annotorious({
            image: document.getElementById("plan2"),
             formatter: ShapeLabelsFormatter(),
            readOnly: true
            
        });

        //   var myJsonString = JSON.parse(vvdata);
        //  console.log("anno", myJsonString);
         
       
         SelectorPack(this.anno); 
          
        console.log("vvdata",vvdata);
          var eded = this.anno.setAnnotations(vvdata);
          console.log("serwer",eded);
        //  setTimeout(() => {
        //      // var eded =  this.anno.loadAnnotations(vvdata);
              
           
        //        
        //  }, 5000);
        
      },

          clickHandler(event, chartContext, config){

              this.openImage = false;
    
         //console.log(config.dataPointIndex, config.seriesIndex);
         
         var d = this.charData[config.dataPointIndex];
                // console.log("dataPointIndex", (this.selecteditem == 'This Month' || this.selecteditem == 'Custom Date Range' ) && this.diffDays > 10);      
                 if((this.selecteditem == 'Last 30 Days' || this.selecteditem == 'Custom Date Range' ) && this.diffDays > 10){
            
                      this.oneMonthImages(chartContext, config);

                 }else{
                   this.getPointImage(d.id);  // function calling for point related image.
                 }
        },
        getPointImage(id){
               let currentcam = this.currentcam.camera_id;
               this.preloader = true;
                axios({
                        url: '/ai/custom-ai-f-by-id/' + currentcam,
                        method: 'GET',
                         params: {
                            id : id
                        }
                    })
                    .then(resp => {
                      //  console.log("resp daily data response", resp.data.success);
                        var data = resp.data.success;

                        this.meta = JSON.parse(data[0].metadata);
                       
                       
                          
                          this.getSingUrl(data[0].images3url);

                     
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false;
                    })
             
        },
        getSingUrl(key){
             
            // console.log(key);
           
            axios({
                    url: "/get_sign_url_by_key?key="+key+"&exp=30minutes&project_id="+this.currentProject.id,
                    method: "GET",
                })
                .then((resp) => {
                     //   console.log(resp.data.success);
                       
                         this.openImage_url = resp.data.success.url;
                        this.openImage = true;
                     
                        
                      //  setTimeout(() => {
                          
                      //     this.checkAnnotaionImage();
                      //    // window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                           
                      //  },3000);
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false;
                    })
        },
      
        Onedaydat(val){  // geting one day data for inital chart load

                if(val == 'Custom Date Range'){

                  this.showDatepicker = true;

                }else{
                  this.showDatepicker = false;
                  this.selecteditem = val;
                  //  console.log(this.selecteditem);
                  var today = new Date();
                  // var week = new Date();

                  if (this.selecteditem == 'This Week') {

                    //       week.setDate(week.getDate()-6);

                    //     var dd = String(today.getDate()).padStart(2, '0');
                    //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    //   var yyyy = today.getFullYear();


                    //    var wd = String(week.getDate()).padStart(2, '0');
                    //   var wm = String(week.getMonth() + 1).padStart(2, '0'); //January is 0!
                    //   var wyyy = week.getFullYear();

                    //  var startw = wm + '/' + wd + '/' + wyyy;
                    //  var endw = mm + '/' + dd + '/' + yyyy;
                    let dateParts = this.formattedDate.split('-');
                    let originalDateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // Corrected order and month indexing

                    // Subtract daysToSubtract days from originalDateObj
                    let resultDateObj = new Date(originalDateObj);
                    resultDateObj.setDate(originalDateObj.getDate() - 6);

                    // Format resultDateObj back into mm-dd-yyyy format
                    let dd = resultDateObj.getDate();
                    let mm = resultDateObj.getMonth() + 1; // Months are 0-indexed
                    let yyyy = resultDateObj.getFullYear();

                    // Ensure mm and dd are two digits
                    if (mm < 10) {
                      mm = '0' + mm;
                    }
                    if (dd < 10) {
                      dd = '0' + dd;
                    }

                    var startw = `${mm}/${dd}/${yyyy}`;
                    let endDateParts = this.formattedDate.split('-'); // Split the date string
                    let year = endDateParts[2];
                    let month = endDateParts[1];
                    let day = endDateParts[0];

                    // Ensure month and day are two digits
                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    // Format the date into mm-dd-yyyy
                    var endw = `${month}/${day}/${year}`;
                    var datadd = startw + " - " + endw; //11/14/2022 - 11/14/2022
                    today = { text: datadd };

                  } if (this.selecteditem == 'Last 30 Days') {
                    //        week.setDate(week.getDate()-30);

                    //   var _wd = String(week.getDate()).padStart(2, '0');
                    //   var _wm = String(week.getMonth() + 1).padStart(2, '0'); //January is 0!
                    //   var _wyyy = week.getFullYear();

                    //  var _startw = _wm + '/' + _wd + '/' + _wyyy;

                    //   var mod = String(today.getDate()).padStart(2, '0');
                    //   var momm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    //   var moyyyy = today.getFullYear();

                    //  var start = _startw;
                    //  var end = momm + '/' + mod + '/' + moyyyy;
                    let dateParts = this.formattedDate.split('-');
                    let originalDateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // Corrected order and month indexing

                    // Subtract daysToSubtract days from originalDateObj
                    let resultDateObj = new Date(originalDateObj);
                    resultDateObj.setDate(originalDateObj.getDate() - 30);

                    // Format resultDateObj back into mm-dd-yyyy format
                    let dd = resultDateObj.getDate();
                    let mm = resultDateObj.getMonth() + 1; // Months are 0-indexed
                    let yyyy = resultDateObj.getFullYear();

                    // Ensure mm and dd are two digits
                    if (mm < 10) {
                      mm = '0' + mm;
                    }
                    if (dd < 10) {
                      dd = '0' + dd;
                    }

                    var start = `${mm}/${dd}/${yyyy}`;
                    let endDateParts = this.formattedDate.split('-'); // Split the date string
                    let year = endDateParts[2];
                    let month = endDateParts[1];
                    let day = endDateParts[0];

                    // Ensure month and day are two digits
                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    // Format the date into mm-dd-yyyy
                    var end = `${month}/${day}/${year}`;

                    var datam = start + " - " + end; //11/14/2022 - 11/14/2022
                    today = { text: datam };

                  } if (this.selecteditem == 'Today') {
                    //  var d = String(today.getDate()).padStart(2, '0');
                    // var m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    // var yyy = today.getFullYear();

                    // today = m + '/' + d + '/' + yyy;
                    let endDateParts = this.formattedDate.split('-'); // Split the date string
                    let year = endDateParts[2];
                    let month = endDateParts[1];
                    let day = endDateParts[0];

                    // Ensure month and day are two digits
                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;

                    // Format the date into mm-dd-yyyy
                    var todayDate = `${month}/${day}/${year}`;
                    var datad = todayDate + " - " + todayDate; //11/14/2022 - 11/14/2022
                    today = { text: datad };
                  }

                  this.showChart = false;
                  this.loader = true;
                  this.getCurrentProjectData(today);
                }
        },
           onValueChange: function (args) {
            // var ss =  args.value.toLocaleDateString();

            if (args.startDate) {

                this.showChart = false;
                this.preloader = true

                this.getCurrentProjectData(args);
            }

        },

        getCurrentProjectData(args) { 
          let dates = args['text'].split('-'); // Split the date string
          let startD = dates[0].trim();
          let endD = dates[1].trim();
          let startDParts = startD.split('/'); // Split the date string
          let year = startDParts[2];
          let month = startDParts[0];
          let day = startDParts[1];

          // Ensure month and day are two digits
          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;

          // Format the date into mm-dd-yyyy
          var start = `${year}-${month}-${day}`;
          let endDParts = endD.split('/'); // Split the date string
          let endDateyear = endDParts[2];
          let endDatemonth = endDParts[0];
          let endDateday = endDParts[1];

          // Ensure month and day are two digits
          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;

          // Format the date into mm-dd-yyyy
          var end = `${endDateyear}-${endDatemonth}-${endDateday}`;
          this.charData = '';
          // var start = '';
          // var end = '';
          let currentcam = this.currentcam.camera_id;

          if (args) {

            //          var checkstart = new Date(this.chartdate.startDate);
            //  var checkend = new Date(this.chartdate.endDate);


            //   start = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
            //   end = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

            // let Difference_In_Time =  start - end;



            // Calculating the no. of days between
            // two dates
            // Assuming start and end are JavaScript Date objects
            let startDate = new Date(start);
            let endDate = new Date(end);

            console.log("startDate",startDate,"endDate",endDate);
            let Difference_In_Time = endDate.getTime() - startDate.getTime();
            // Calculate the difference in days
            let diffDays = Difference_In_Time / (1000 * 3600 * 24);

            if (diffDays > 7 && this.diffDays < 30) {


              console.log("condition match",diffDays, this.diffDays);


              var today = new Date(this.chartdate.startDate);
              today.setDate(today.getDate() + 7);

              end = new Date(today).toISOString().slice(0, 10);

              this.chartdate.endDate = end;

             // alert("Only 7 Days data between(" + start + " to  " + end + ")  will show's on chart");

            }

            this.getWatherData(start, end, diffDays);

            this.getAqiData(start, end, diffDays); 
                

           
               // console.log("use data", this.params);
                this.preloader = true;
                setTimeout(() => {

                  axios({
                        url: '/ai/custom-ai-f-date-range/' + currentcam ,
                        method: 'GET',
                         params: {
                        start: start,
                        end: end,
                        diffDays : diffDays
                    }
                    })
                    .then(resp => {
                         this.charData = resp.data.success;
                         setTimeout(() => {
                          this.chartDataMaker(resp.data.success,diffDays);
                         }, 2000);
                        
                      
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })
                  
                }, 2000);
                
            
           }
       },
       generateDayWiseTimeSeries: function(baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
      }
      return series;
    },
       chartDataMaker(data,diffDays){
         this.loadigndata = false;
         var parsons = [];
         var vehicles = [];
         var xaxis = [];
         this.chartdates = [];

              //  var options = {
              //       dateStyle: "short",
              //       timeStyle: "short"
              //   };
                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;
           // var dd = '';
             data.forEach(item  => {
                 
                 parsons.push(item.person);
                 vehicles.push(item.construction_vehicles);
                       
                      if(diffDays > 7){
                           var Xm = new Date(item.date_hours+":00:00");
                           //console.log("date hourse ", Xm);
                         
                             var dated = new Intl.DateTimeFormat('en-GB', options).format(Xm).split(', ');
                          //  if(dated[0] == dd){
                          //      xaxis.push(dated[1]);
                          // }else{
                          //        dd = dated[0];
                          //         xaxis.push(dated[0]);
                          // }

                          //  if(index % 12 == 0){
                          //    xaxis.push('');
                          // }else{
                          
                             xaxis.push(dated);
                         // };
                           
                          this.chartdates.push(item.date_hours);
                      }else{
                           Xmas95 = new Date(item.image_creation_time);
                        //  var d =  this.generateDayWiseTimeSeries(Xmas95.getTime(), 115, { min: 30, max: 90 });
                        
                          var datep = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');
                           //  console.log("date funtion",datep);
                          // if(datep[0] == dd){
                          //      xaxis.push(datep[1]);
                          // }else{
                          //        dd = datep[0];
                          //         xaxis.push(datep[0]);
                          // }
                             xaxis.push(datep);
                        
                     //   xaxis.push(new Intl.DateTimeFormat('en-GB', options).format(Xmas95));
                      }

                // console.log("this.chartdates", this.chartdates);
          
                
                  
                      
             });
           

              this.series = [
            {
              name: "Person",
              data: parsons
            },
            {
              name: 'Vehicle',
              data: vehicles
            }
          ];
            this.chartOptions.xaxis.categories = xaxis;

           //  console.log(xaxis);


         setTimeout(() => {
            this.showChart = true;
            this.loadigndata = true;
            console.log("louncher");
            this.preloader = false;
         }, 5000);   
             
          
        },
         selctedVehicle(vehivleType, vtitle){
            
             if(this.showHeatChart){
                this.itemwishHeatChart(vehivleType,vtitle);
            }else{
                this.itemwishChart(vehivleType,vtitle);
              }
               
         },
         
         selctedWeather(vehivleType, vtitle){
            
             this.itemwishChart(vehivleType,vtitle);
         },

         checkFilter(){
            this.activeperson;
            this.activeVehicle;
            console.log(this.activeVehicle);
             this.selctedVehicle(this.activeVehicle.text, this.activeVehicle.title);
           if(!this.activeperson){
            this.$refs.lineChart.hideSeries('Person');
           }else{
             this.$refs.lineChart.showSeries('Person');
           }
        
         },
         checkweatherFilter(){
            console.log(this.activeweather);
           //  this.selctedWeather(this.activeweather.text, this.activeweather.title);
              this.$refs.weatherChart.hideSeries('Clouds (%)');
          this.$refs.weatherChart.hideSeries('Wind (meter/sec)');
          this.$refs.weatherChart.hideSeries('Temperature (°C)');
          this.$refs.weatherChart.hideSeries('Rain(mm)');
          this.$refs.weatherChart.hideSeries('Snow(mm)');
          var t =this.activeweather.title;
          console.log(t);
          this.$refs.weatherChart.showSeries(t);
         },
         itemwishChart(vt,vtitle){

         var parsons = [];
         var vehicles = [];
         var xaxis = [];

                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;

             this.charData.forEach(item => {
                parsons.push(item.person);
                 vehicles.push(item[vt]);

                
                      if(this.diffDays > 10){
                          xaxis.push(item.date_hours);
                      }else{
                           Xmas95 = new Date(item.image_creation_time);
                           xaxis.push(new Intl.DateTimeFormat('en-GB', options).format(Xmas95));
                      }
                

               
                // console.log(new Intl.DateTimeFormat('en-US', options).format(Xmas95));

                

             });


                if(!this.activeperson){
                  this.series = [
                            {
                            name: vtitle,
                            data: vehicles
                            }
              ]
                }else{
                    this.series = [
                    {
                    name: "Person",
                    data: parsons
                    },
                    {
                    name: vtitle,
                    data: vehicles
                    }
                    ]
                }
            

          
            this.chartOptions.xaxis.categories = xaxis;


         setTimeout(() => {
            this.showChart = true;
            this.preloader = false
         }, 3000);   

         },
           generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    },
    itemwishHeatChart(vehivleType,vtitle){
        this.heatchartTitle = vtitle;
        this.heatChartData(vehivleType);

    },
     changeweatherforheatmap(){
       this.preloader = true;
        console.log("heatmapactiveweather", this.heatmapactiveweather);
           this.shoWeatherHeatChart =  false;
        this.loopcollection;
        
         this.weatherheatseries =[
                {
                name: '00',
                data: []
               },
               {
              name: '01',
              data: []
               },
            {
              name: '02',
              data: []
            },
             {
              name: '03',
              data:  []
             },
             {
              name: '04',
              data:  []
             },
             {
              name: '05',
              data:  []
             },
             {
              name: '06',
              data:  []
             },
             {
              name: '07',
              data:  []
             },
             {
              name: '08',
              data:  []
             },
             {
              name: '09',
              data:  []
             },
             {
              name: '10',
              data:  []
             },
             {
              name: '11',
              data:  []
             },
             {
              name: '12',
              data:  []
             },
             {
              name: '13',
              data:  []
             },
             {
              name: '14',
              data:  []
             },
             {
              name: '15',
              data:  []
             },
             {
              name: '16',
              data:  []
             },
             {
              name: '17',
              data:  []
             },
             {
              name: '18',
              data:  []
             },
             {
              name: '19',
              data:  []
             },
             {
              name: '20',
              data:  []
             },
             {
              name: '21',
              data:  []
             },
             {
              name: '22',
              data:  []
             },
             {
              name: '23',
              data:  []
             }
          ];

           setTimeout(() => {
       //  console.log("after",this.charData);
           var xaxis = [];
                var options = {
                    dateStyle: "short",
                    timeStyle: "short"
                };
               //  var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                console.log("loopcollection in weather", this.loopcollection);
        
         this.loopcollection.forEach(item => {

             

                      var Xmas95 = new Date(item.dt*1000);
                        
                      var h = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');
                         

               // const h = item.date_hours.split(" ");
                if(!xaxis.includes((h[0]))){
                    xaxis.push(h[0]);
                    // xaxis.push(new Intl.DateTimeFormat('en-GB').format(h[0]));
                }
                  var index = parseInt(h[1]);
                 // console.log("index", index);
                  var type =  this.heatmapactiveweather.text;
                 // console.log("item",item[type]);
                  this.weatherheatseries[index].data.push(item[type]);
              //  for (let index = 0; index < 24; index++) {
               
              //         if(parseInt(h[1]) == index){
              //           this.heatseries[index].data.push(item[type]);
              //     }
              //  }
         })
          console.log("xaxis", xaxis);
          console.log("this.weatherheatseries",this.weatherheatseries);
           this.weatherheatchartOptions.xaxis.categories = xaxis;
          this.weatherheatchartOptions_title = this.heatmapactiveweather.title + ' Heatmap';
          this.preloader =  false;
          this.showHeatChart =  true;
          this.shoWeatherHeatChart =  true;
         },25000); 

    },
     heatChartData(type){


      this.diffDays = 30;
      
     
      this.heatseries =[
                {
                name: '00',
                data: []
               },
               {
              name: '01',
              data: []
               },
            {
              name: '02',
              data: []
            },
             {
              name: '03',
              data:  []
             },
             {
              name: '04',
              data:  []
             },
             {
              name: '05',
              data:  []
             },
             {
              name: '06',
              data:  []
             },
             {
              name: '07',
              data:  []
             },
             {
              name: '08',
              data:  []
             },
             {
              name: '09',
              data:  []
             },
             {
              name: '10',
              data:  []
             },
             {
              name: '11',
              data:  []
             },
             {
              name: '12',
              data:  []
             },
             {
              name: '13',
              data:  []
             },
             {
              name: '14',
              data:  []
             },
             {
              name: '15',
              data:  []
             },
             {
              name: '16',
              data:  []
             },
             {
              name: '17',
              data:  []
             },
             {
              name: '18',
              data:  []
             },
             {
              name: '19',
              data:  []
             },
             {
              name: '20',
              data:  []
             },
             {
              name: '21',
              data:  []
             },
             {
              name: '22',
              data:  []
             },
             {
              name: '23',
              data:  []
             }
          ];

          this.heatImagesDatas=[];
          this.openImage = false;
          this.selectoptions = ['Last 30 Days', 'Custom Date Range'];
          this.showHeatChart =  false;
          
         this.showlinechart = false;
         this.preloader =  true;
         if(type == 'person'){
             this.activeVehicle = { title: 'Person', text: 'person'};
         }
       //  console.log(type);
        // var time = 20000;
         if(this.diffDays == 30){

        console.log("diffdays",this.diffDays);
          console.log("here in");

             this.Onedaydat('Last 30 Days');
         //     time = 20000;

         }

         
//console.log("before",this.charData);
       // console.log(time);
         setTimeout(() => {
       //  console.log("after",this.charData);
         var xaxis = [];
             var options = {
                    dateStyle: "short",
                };
       //  console.log("chart data for heat map", this.charData);

         var indexrrzy = [];
        this.charData.forEach(item => {

           // console.log("item.image_creation_time", item);
             
                const h = item.date_hours.split(" ");

                  var xx = new Date(h[0]);

                 var df = new Intl.DateTimeFormat('en-GB',options).format(xx);
                if(!xaxis.includes(df)){
                  // xaxis.push(h[0]);
                //     var xx = new Date(h[0]);

                //  var df = new Intl.DateTimeFormat('en-GB',options).format(xx);
                 //     console.log("ddff",df);
                   xaxis.push(df);
                    indexrrzy = [];
                }

             
                var index = parseInt(h[1]);


                
                 if(!indexrrzy.includes(index)){
                 
                  indexrrzy.push(index);


                //  console.log("indexrrzy", indexrrzy);

                  this.heatseries[index].data.push(item[type]);
                 }

                
              //  for (let index = 0; index < 24; index++) {
               
              //         if(parseInt(h[1]) == index){
              //           this.heatseries[index].data.push(item[type]);
              //     }
              //  }
         })
        
         this.heatchartOptions.xaxis.categories = xaxis;
        //  this.preloader =  false;

       // console.log(" this.heatseries",this.heatseries);
        
          this.showHeatChart =  true;
            this.changeweatherforheatmap();
         }, 20000); 
        
        },
        showlinechartfun(){
         // console.log("click");
          this.preloader = true;
          this.heatImagesDatas = [];
          this.openImage = false;
           this.selectoptions =  ['Today', 'This Week', 'Last 30 Days', 'Custom Date Range'];
          this.showHeatChart =  false;
          this.showlinechart = true;
        },

        oneMonthImages(chartContext, config){  // get images for 1 month chart data
            this.heatImagesDatas = [];
            //  this.openImage = false;
            //http://ai.opticvyu.com:3000/custom-ai-f-time-range-with-metadata/867/2022-11-20%2008%3A00%3A00/2022-11-20%2020%3A20%3A59
            //  console.log(config.dataPointIndex, config.seriesIndex);
           
              //var data =  this.chartOptions.xaxis.categories[config.dataPointIndex]; // date get from heat chart categories;
              var data =  this.chartdates[config.dataPointIndex]; 
                // console.log(this.chartdates);
                //  console.log(data);
    // //          var ss =config.seriesIndex > 9 ? "" + config.seriesIndex: "0" + config.seriesIndex;
    // //         //  2022-11-20 03:00:00 2022-11-20 03:59:59
    // // //     /2022-11-20%2003%3A00%3A00/2022-11-20%2003%3A59%3A59
           
           var starttime = data+":00:00";
             var endtime = data+":59:59";
 
            starttime =  encodeURI(starttime);
             endtime =  encodeURI(endtime);

            //  starttime = starttime.replace(" ", "%2520");
            //   endtime = endtime.replace(" ", "%2520");

             /// console.log(starttime,endtime);
                let currentcam = this.currentcam.camera_id;
                this.preloader = true;
                axios({
                        url: '/ai/custom-ai-f-time-range-with-metadata/' + currentcam,
                        method: 'GET',
                         params: {
                         starttime: starttime,
                         endtime: endtime
                     }
                    })
                    .then(resp => {
                        //console.log("resp ==", resp.data.success);
                         

                        var heatImagesData = resp.data.success;

                        if(heatImagesData.length == 0){
                          this.alert = true;
                          this.alertmessage = "Image Not found.";
                        }


                        heatImagesData.forEach((item, index) => {

                          //  heatImagesData[index]['thunb'] = "";

                            // var images3url =  this.getThumbnailSingUrl(item.images3url);
                          var p =   item.images3url.split('/');

                            var p1 = "thumb."+p[1];
                            var p0 = p[0];

                             var thumburl = p0+"/"+p1;
                             // console.log(index);
                                 this.getThumbnailSingUrl(thumburl).then(data => { heatImagesData[index]['thumb'] = data}).catch(err => console.log(err))
                                 this.getThumbnailSingUrl(item.images3url).then(data => { heatImagesData[index].images3url = data}).catch(err => console.log(err))
                              
                        })
                        
                         //console.log(heatImagesData);
                           
                             this.refresh++;

                             setTimeout(() => {
                                this.heatImagesDatas = heatImagesData;
                                  this.preloader = false;
                                    window.scrollTo(0,15000);
                             }, 3000);
                              setTimeout(() => {
                                    window.scrollTo(0,document.body.scrollHeight || document.documentElement.scrollHeight);
                             }, 3500);

                            
                           
                      
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })
        },

        clickHeatChart(event, chartContext, config){  // get heat cart images

            //  this.openImage = false;
            //http://ai.opticvyu.com:3000/custom-ai-f-time-range-with-metadata/867/2022-11-20%2008%3A00%3A00/2022-11-20%2020%3A20%3A59
             // console.log(config.dataPointIndex, config.seriesIndex);
           
             var datas =  this.heatchartOptions.xaxis.categories[config.dataPointIndex]; // date get from heat chart categories;
             var ss =config.seriesIndex > 9 ? "" + config.seriesIndex: "0" + config.seriesIndex;
            //  2022-11-20 03:00:00 2022-11-20 03:59:59
    //     /2022-11-20%2003%3A00%3A00/2022-11-20%2003%3A59%3A59

             var dp =  datas.split('/');

                 var data = dp[2]+'-'+dp[1]+'-'+dp[0];
                  

               console.log(data);
           
             var starttime = data+" "+ss+":00:00";
             var endtime = data+" "+ss+":59:59";

               console.log(starttime,endtime);

             starttime =  encodeURI(starttime);
             endtime =  encodeURI(endtime);

              console.log(starttime,endtime);
                let currentcam = this.currentcam.camera_id;
                this.preloader = true;
               
                axios({
                        url: '/ai/custom-ai-f-time-range-with-metadata/' + currentcam,
                        method: 'GET',
                         params: {
                         starttime: starttime,
                         endtime: endtime
                     }
                    })
                    .then(resp => {
                        console.log("resp", resp.data.success);
                        

                        var heatImagesData = resp.data.success;

                        if(heatImagesData.length == 0){
                          this.alert = true;
                          this.alertmessage = "Image Not found.";
                        }


                        heatImagesData.forEach((item, index) => {

                          //  heatImagesData[index]['thunb'] = "";

                            // var images3url =  this.getThumbnailSingUrl(item.images3url);
                          var p =   item.images3url.split('/');

                            var p1 = "thumb."+p[1];
                            var p0 = p[0];

                             var thumburl = p0+"/"+p1;
                             // console.log(index);
                                 this.getThumbnailSingUrl(thumburl).then(data => { heatImagesData[index]['thumb'] = data}).catch(err => console.log(err))
                                 this.getThumbnailSingUrl(item.images3url).then(data => { heatImagesData[index].images3url = data}).catch(err => console.log(err))
                              
                        })
                          this.refresh++;
                        // console.log(heatImagesData);

                         setTimeout(() => {
                          
                            this.heatImagesDatas = heatImagesData;
                              this.preloader = false;
                               window.scrollTo(0,15000);
                         }, 3000);  
                           

                           
                          
                      
                    })
                    .catch(err => {
                        console.log(err);
                        this.preloader = false
                    })
        },
        getThumbnailSingUrl(key){  // get image thumbnail and sign url  

                //console.log(key);
                var url = "/get_sign_url_by_key?key="+key+"&exp=30minutes&project_id="+this.currentProject.id;

                const promise = axios.get(url)

                // using .then, create a new promise which extracts the data
                const dataPromise = promise.then((resp) => resp.data.success.url)

                // return it
                return dataPromise

        },
        selectThumainal(index){
              this.openImage = false;
              this.preloader = true;
            console.log("fgf",index);
            
            this.openImage_url = this.heatImagesDatas[index].images3url;
                        
                        
                         this.meta = JSON.parse(this.heatImagesDatas[index].metadata);
                       //  console.log(this.meta);
                        setTimeout(() => {
                           this.openImage = true;
                       }, 500);
                      //  setTimeout(() => {
                      //      this.openImage = true;
                          
                      //     this.checkAnnotaionImage();
                      //    // window.scrollTo(0,10000);
                           
                      //  }, 2000);

        },
        timefrmat(time){

           var tt = time.split('T');
           
           //  console.log(tt);

             return tt[1];
        },
         getAqiData(starttime,endtime ,diffDays){
             this.sqiloadigndata =  false;
              var loop = 1;

              if(diffDays > 7){

                loop = diffDays / 7; 

                 loop =  Math.round(loop);
                  if(diffDays == 30 || diffDays == 28 || diffDays == 29 || diffDays == 31 ){
                     loop = 5;
                  }
            
              }

              this.aqiloopcollection = [];

               for(var i = 0; i < loop; i++){
                
                  this.aqiloopfunction(starttime,endtime,i,loop);
               }

         },
         aqiloopfunction(starttime,endtime,i,loop){

            console.log("aqiloopcollection out");
               
                var date1 = starttime+" 00:00:00";
              var date2 = endtime+" 23:59:59";

                var datels = new Date(date1);
                //var datele = endtime;
                   var multip  = i;
                    if(i > 0){
                     
                     date1 = datels.setDate(datels.getDate() + 6*multip);
                         
                         console.log("data", date1);
                         //  date2 = dated+" 23:59:59";
                         // date1 = dated+" 00:00:00";
                        
                    }



                 date1 = new Date(date1);
                 date2 = new Date(date2);

                // console.log(date1, date2);

            var starttimes =   Math.floor(date1.getTime() / 1000);
             var endtimes =   Math.floor(date2.getTime() / 1000);

             //  console.log("starttime,endtime",starttimes,endtimes);

     var projectid = this.currentProject.id;
           axios({
                        url: '/ai/aqidata/' + projectid,
                        method: 'GET',
                         params: {
                         starttime: starttimes,
                         endtime: endtimes
                     }
                    })
                    .then(resp => {
                        //console.log("wather data", resp.data.success.list);
                      this.aqiloopcollection =  this.aqiloopcollection.concat(resp.data.success.list);

                         if(loop == i+1){

                 console.log("aqiloopcollection ", this.aqiloopcollection);

                   this.aqiloopcollection.sort(function(a,b){
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return  new Date(a.dt) - new Date(b.dt);
                    });

                // this.wetherData = this.aqiloopcollection;
                 this.aqiDataMaker(this.aqiloopcollection);
               }
                       
                    }) .catch(err => {
                        console.log(err);
                       // this.preloader = false
                    })
 
           },
           aqiDataMaker(data){
          this.aqiloadigndata =  false;
          var pm25 = [];
          var pm10 = [];
          var co = [];
          var no = [];
          var no2 = [];
          var o3 = [];
          var so2 = [];
          var nh3 = [];

         var xaxis = [];

              //  var options = {
              //       dateStyle: "short",
              //       timeStyle: "short"
              //   };
                 var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
               
                 var Xmas95;
               // var cxt = '';
             data.forEach(item => {

                          pm25.push(item.components.pm2_5);
                          pm10.push(item.components.pm10)

                          nh3.push(item.components.nh3)
                          co.push(item.components.co)
                          so2.push(item.components.so2)
                          o3.push(item.components.o3)
                          no.push(item.components.no)
                          no2.push(item.components.no2)
                     
                          Xmas95 = new Date(item.dt*1000);
                        
                           var dated = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');
                        
                           xaxis.push(dated);

             });

            this.aqiseries = [
           {
              name: "PM2.5",
              data: pm25
            },
             {
              name: "PM10",
              data: pm10
            },
             {
              name: "CO",
              data: co
            },
             {
              name: "NO",
              data: no
            },
             {
              name: "NO2",
              data: no2
            },
             {
              name: "O3",
              data: o3
            },
             {
              name: "SO2",
              data: so2
            } 
          ];
            this.aqiOptions.xaxis.categories = xaxis;
           

         setTimeout(() => {
            
           
            this.preloader = false

               
              this.sqiloadigndata =  true;
              
         }, 10000);         
          
        },

        getWatherData(starttime,endtime ,diffDays){ // getting wather data for charts
    //         var data =  this.heatchartOptions.xaxis.categories[config.dataPointIndex]; // date get from heat chart categories;
    //          var ss =config.seriesIndex > 9 ? "" + config.seriesIndex: "0" + config.seriesIndex;
    //         //  2022-11-20 03:00:00 2022-11-20 03:59:59
    // //     /2022-11-20%2003%3A00%3A00/2022-11-20%2003%3A59%3A59
           
             // console.log("starttime,endtime",starttime,endtime)
                var loop = 1;

              if(diffDays > 7){

                loop = diffDays / 7; 

                 loop =  Math.round(loop);
                  if(diffDays == 30 || diffDays == 28 || diffDays == 29 || diffDays == 31 ){
                     loop = 5;
                  }
            
              }

              this.loopcollection = [];

               for(var i = 0; i < loop; i++){
                
                  this.watherloopfunction(starttime,endtime,i,loop);

            
               }
   
        },
           watherloopfunction(starttime,endtime,i,loop){
               
                var date1 = starttime+" 00:00:00";
              var date2 = endtime+" 23:59:59";

                var datels = new Date(date1);
                //var datele = endtime;
                   var multip  = i;
                    if(i > 0){
                     
                     date1 = datels.setDate(datels.getDate() + 6*multip);
                         
                         console.log("data", date1);
                         //  date2 = dated+" 23:59:59";
                         // date1 = dated+" 00:00:00";
                        
                    }



                 date1 = new Date(date1);
                 date2 = new Date(date2);

                // console.log(date1, date2);

            var starttimes =   Math.floor(date1.getTime() / 1000);
             var endtimes =   Math.floor(date2.getTime() / 1000);

             //  console.log("starttime,endtime",starttimes,endtimes);

     var projectid = this.currentProject.id;
           axios({
                        url: '/ai/watherdata/' + projectid,
                        method: 'GET',
                         params: {
                         starttime: starttimes,
                         endtime: endtimes
                     }
                    })
                    .then(resp => {
                        //console.log("wather data", resp.data.success.list);
                      this.loopcollection =  this.loopcollection.concat(resp.data.success.list);

                         if(loop == i+1){

                 console.log("loopcollection out", this.loopcollection);

                   this.loopcollection.sort(function(a,b){
                      // Turn your strings into dates, and then subtract them
                      // to get a value that is either negative, positive, or zero.
                      return  new Date(a.dt) - new Date(b.dt);
                    });

                 this.wetherData = this.loopcollection;
                 this.weatherDataMaker(this.loopcollection);
               }
                       
                    }) .catch(err => {
                        console.log(err);
                       // this.preloader = false
                    })
 
           },
         weatherDataMaker(data){
          this.weatherloadigndata =  false;
          var wind = [];
          var temp = [];
          var clouds = [];
          var rain = [];
          var snow = [];

         var xaxis = [];

              //  var options = {
              //       dateStyle: "short",
              //       timeStyle: "short"
              //   };
                 var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
               
                 var Xmas95;
               // var cxt = '';
             data.forEach(item => {
             //  console.log("item  ii", item);
              // item.wind = 0;
              // item.clouds = 0;
              // item.rain = 0;
              // item.snow = 0;
             
             
                  if("wind" in item ){
                      if(item.wind !== undefined){

                        item.wind = item.wind.speed;
                       }else{
                         item.wind = 0;
                      }
                    }else{
                     item.wind = 0;
                  }
                   if("clouds" in item){
                     if(item.clouds !== undefined){

                       item.clouds = item.clouds.all;
                       }else{
                        item.clouds = 0;
                      }
                     
                    }else{
                    item.clouds = 0;
                    }
                  if("rain" in item){
                      if(item.rain !== undefined){
                     item.rain = item.rain['1h'];
                      }
                      else{
                          item.rain = 0;
                      }
                  }else{
                      item.rain = 0;
                  }
                  if("snow" in item){
                       if(item.snow !== undefined ){
                         item.snow = item.snow['1h'];
                      }else{
                          item.snow = 0;
                      }
                  }else{
                     item.snow = 0;
                  }
                     item.temp = item.main.temp;
                  
               
                  wind.push(item.wind);
                  clouds.push(item.clouds);
                  rain.push(item.rain);
                  snow.push(item.snow);
                  temp.push(item.temp)

                
                          Xmas95 = new Date(item.dt*1000);
                        
                           var dated = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');
                          //  if(dated[0] == cxt){
                          //      xaxis.push('');
                          // }else{
                          //        cxt = dated[0];
                          //         xaxis.push(dated[0]);
                          // }

                           xaxis.push(dated);

               
                // console.log(new Intl.DateTimeFormat('en-US', options).format(Xmas95));

                

             });

            this.weatherseries = [
            {
              name: "Clouds (%)",
              data: clouds
            },
             {
              name: "Wind (meter/sec)",
              data: wind
            },
             {
              name: "Snow(mm)",
              data: snow
            },
             {
              name: "Rain(mm)",
              data: rain
            },
             {
              name: "Temperature (°C)",
              data: temp
            }
          ];
            this.weatherOptions.xaxis.categories = xaxis;
           

         setTimeout(() => {
            
            this.showChart = true;
            this.preloader = false
            //  this.$refs.weatherChart.hideSeries('Wind (meter/sec)');
            // this.$refs.weatherChart.hideSeries('Temperature (°C)');
            //  this.$refs.weatherChart.hideSeries('Rain(mm)');
            //   this.$refs.weatherChart.hideSeries('Snow(mm)');

             console.log("this.weatherseries",this.weatherseries);
               
              this.weatherloadigndata =  true;
              
         }, 10000);         
          
        },
        loaded() {
            var v = this;
            setTimeout(function () {
                v.checkAnnotaionImage();
                v.preloader = false;
                v.imageloaded = true;
            }, 10);
        },
        goDown(){
             this.imageloaded = false;
              window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        },
        backtoTop(){
            window.scrollTo(0,0);
        },
         setrouter: function (url) {
          if (this.$route.path !== url) {
            this.$router.push(url);
          }
      },

    } // end of mathos

};
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";



h2.hetitlec {
    background: #000 !important;
    color: #fff;
    padding: 0px 15px;
    text-align: center;
    font-size: 17px;
   
}
h2.hetitlec.active {
    background: #f8bb03 !important;
    color: #000;
     box-shadow: -2px 7px 16px 0px #0000004a;
}
#chartbar{
  cursor:pointer;
}

 /* this new ai page style */

 .fiterdata{
  background:#000;
  color: #fff;
  cursor: pointer;
 }
 h2.hetitlec.fiterdata{
    background: #000;
 }
 .hetitlec{
    cursor:pointer;
 }
 .pop{
   width:100%;
   height:auto;
   background:#fff;
   z-index:2;
   position:relative;
 }
 .car .a9s-inner{
   stroke-width:1;
   stroke:#FF5233;
 }
  .bus .a9s-inner{
   stroke-width:1;
   stroke:#FFE333;
 }
   .person .a9s-inner{
   stroke-width:1;
   stroke:#71FF33;
 }
 .excavator .a9s-inner{
   stroke-width:1;
   stroke:#33FFD1;
 }
 .telehandler .a9s-inner{
   stroke-width:1;
   stroke:#3361FF ;
 }
 .loader .a9s-inner{
   stroke-width:1;
   stroke:#9933FF;
 }
 .dump_truck .a9s-inner{
   stroke-width:1;
   stroke:#FF33CE;
 }
 .concrete-mixer .a9s-inner{
   stroke-width:1;
   stroke:#5E213D;
 }
  .pile_diver .a9s-inner{
   stroke-width:1;
   stroke:#D9711A ;
 }
  .crane .a9s-inner{
   stroke-width:1;
   stroke:#3A6C4A;
 }
  .rollers .a9s-inner{
   stroke-width:1;
   stroke:#085400;
 }
 .pile .a9s-inner{
   stroke-width:1;
   stroke:#003454;
 }
 .pile_cap .a9s-inner{
   stroke-width:1;
   stroke:#3c0054;
 }
 .pier_cage .a9s-inner{
   stroke-width:1;
   stroke:#542700;
 }
 .pier .a9s-inner{
   stroke-width:1;
   stroke:#274f63;
 }
 .deck .a9s-inner{
   stroke-width:1;
   stroke:#2a0054;
 }
 .pier_cap .a9s-inner{
   stroke-width:1;
   stroke:#298e70;
 }
 
 .a9s-shape-label{
  opacity:0;
  transition:0.5s;
 }
  .a9s-annotation:hover .a9s-shape-label{
  opacity:1;
  transition:0.5s;
 }
 .v-input--selection-controls.ckeck  {
     margin-top: 0px;
    }
  p.weathernote {
    position: relative;
    top: 0px;
    right: 0px;
     text-align: center;
}
.helps {
    position: absolute !important;
    right: 6px;
    top: 20px;
    z-index:5;
    cursor:pointer;
}
.helpinfo p{
       position: absolute;
    right: 32px;
    width: 350px;
    background: #fff;
    top: 36px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition:0.5s;

}
.helpinfo:hover p{
  display:block;
  transition:0.5s;
}
.timelineclose {
    position: absolute;
    left: -2px;
    bottom: 118px !important;
    z-index: 50;
}
.backtotop{
    position: absolute;
    right: 36px;
    bottom: 10px !important;
    cursor:pointer;
    background:#fff;
    z-index: 999;
}
html {
  scroll-behavior: smooth;
}
.charthite{
   height: 550px;
}

.helpinfoaqi {
    position: relative;
}
.helpinfoweather{
   position:relative;
}
.helpinfoaqi p {
     position: absolute;
    left: 145px;
    width: 306px;
    background: #fff;
    top: -2px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition: 0.5s;
}

.helpinfoaqi:hover p{
  display:block;
  transition:0.5s;
}

.weatherhelp {
    position: relative !important;
    left: 121px;
    top: -28px;
    z-index: 5;
    cursor: pointer;
}
.helpinfoweather{
   position:relative;
}
.helpinfoweather p {
    position: absolute;
    left: 126px;
    width: 350px;
    background: #fff;
    top: 0px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition: 0.5s;
}

.helpinfoweather:hover p{
  display:block;
  transition:0.5s;
}

.helpinfo p{
       position: absolute;
    right: 32px;
    width: 350px;
    background: #fff;
    top: 36px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition:0.5s;

}
.helpinfo:hover p{
  display:block;
  transition:0.5s;
}

 span.hetitlec {
    background: #000 !important;
    color: #fff;
    padding: 5px 15px;
    text-align: center;
    font-size: 17px;
    border-radius: 2px;
    margin-top : 10px;
    display:inline-block;
   
}
span.hetitlec.active {
    background: #f8bb03 !important;
    color: #000;
     box-shadow: -2px 7px 16px 0px #0000004a;
}
.aqihelp {
    position: relative !important;
    left: 128px;
    top: -28px;
    z-index: 5;
    cursor: pointer;
}
.helpinfoaqi {
    position: relative;
}
.cdaterangepicker {
    float: right;
    position: absolute;
    right: 26px;
    top: 53px;
}
</style>

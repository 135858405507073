<template>
<div>
    <Preloader v-if="preloader" />
    <v-col cols="12" md="12" class="tohed">
    </v-col>
    <div class="megaMenus" :class="drawingToggle ? 'opendrawer' : 'closedrawer'">
    <v-row>
    <v-btn fab x-small color="#fff" class="closeDrower">
    <v-icon dark>
    mdi-close
    </v-icon>
    </v-btn>
    <v-col v-for="n in drawings" :key="n.drawingid" :class="`d-flex child-flex drawingitem mega ${n.drawingid == activeDrawing.drawingid ? 'selected' : ''}`" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
    <v-img :src="n.thumbnail" aspect-ratio="1.5" class="black lighten-2" @click="goToDrawing(n)">
    <div class="drawingname">
    {{ n.name }}
    <span class="progrssss">( {{ n.progress }}%)</span>
    </div>
    <div class="drawingname dfooter">
    <v-btn class="mx-2" fab x-small color="#fff">
    <v-icon dark>
    mdi-axis-z-rotate-clockwise
    </v-icon>
    </v-btn>
    {{ n.numberofrotet }}
    <v-btn class="mx-2" fab x-small color="#fff">
    <v-icon dark>
    mdi-camera
    </v-icon>
    </v-btn>
    {{ n.numberofimages }}
    <v-btn class="mx-2" fab x-small color="#fff">
    <v-icon dark>
    mdi-play
    </v-icon>
    </v-btn>
    {{ n.numberofvideos }}
    </div>
    </v-img>
    </v-col>
    </v-row>
    </div>
    <v-row>
        <v-col cols="12" md="12" v-if="!showadd">
            <div class="topbr">

                <v-btn class="ma-2 add newmodelzone" small v-if="userdata.user_code == 2" @click="addNewModel" color="mylightbutton">
                    Add New Model in {{ currentZoneDate.zonename }}
                </v-btn>
                <v-btn class="ma-2 add connectWithCordinate" small v-if="userdata.user_code == 2 && docloaded" @click="connectWithCordinate" color="mylightbutton">
                    Connect With Coordinates
                </v-btn>
            </div>
            <v-form ref="uploadDrawing" v-model="valid" lazy-validation @submit.prevent="submitDrawingform">
                <div class="fileuploadZone" v-if="NoHaveDocId">
                    <h3>Upload Source File to OSS</h3>
                    <div class="uploadx">
                        <v-file-input truncate-length="41" :rules="requerdRules" v-model="files" placeholder="Select zip file to upload" @change="handleFileUpload()" accept=".zip,.rvt,.nwd,.nwc"></v-file-input>

                        <p>
                            Note: Only .rvt, .nwd, .nwc & .zip file (.zip with iam file) are
                            supported.
                        </p>
                        <v-btn class="mr-4" type="submit" :disabled="uploadOn" color="mylightbutton">
                            Upload
                        </v-btn>
                    </div>
                </div>
            </v-form>
            <!-- <forge-vuer @modelLoaded="handleProgressUpdated" v-if="docloaded" @documentLoadError="documentLoadError" :getAccessToken="myGetTokenMethodAsync" :urn="myObjectUrn" /> -->
            <div id="forgeViewerzone" v-if="docloaded"></div>
            <div class="loaderInfo" v-if="uploadingStart">
                <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
                <h4>{{ progree }}</h4>
                <p class="mgss">{{ msg }}</p>
            </div>
        </v-col>
        <v-col cols="12" md="12" v-if="showadd">
            <div class="msgbox">
                <p>
                    You can integrate BIM model for comparing site images with BIM 3D
                    model. Contact
                    <a href="mailto:admin@opticvyu.com">admin@opticvyu.com </a> for more
                    information
                </p>
            </div>
        </v-col>
    </v-row>
    <v-snackbar v-model="alert" top right timeout="3000">
        {{ alertmessage }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="alert = false">
                {{ lang.Close }}
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

<script>
//import ForgeVuer from 'forge-vuer';

import axios from "axios";
import Preloader from "../../components/PreLoader";
//import $ from 'jquery'
// import * as THREE from 'three';
import {
    locals
} from "../../utils/locals";

export default {
    components: {
        Preloader
        // ForgeVuer
    },
    name: "autodeskZone",

    data() {
        return {
            preloader: false,
            myToken: "",
            myObjectUrn: "",
            docloaded: false,
            NoHaveDocId: true,
            uploadingStart: false,
            files: [],
            valid: false,
            Local: locals,
            lang: {},
            progree: 0,
            msg: "File Uploading...",
            projectid: 0,
            requerdRules: [v => !!v || this.lang.required],
            uploadOn: true,
            currentProject: {},
            userdata: {},
            alertmessage: "",
            alert: false,
            autodesk_viewer: 0,
            showadd: true,
            viewer: "",
            currentZoneDate: "Zone Name",
            drawings: {},
            currentDrawingName: 'Select Drawing',
            drawingToggle: false,
            activeDrawing: {}
        };
    },
    mounted() {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        if (this.userdata.open_access == 1) {
            this.$router.push("/dashboard");
        }
        var selectleng = this.$cookies.get("currentLanguage");
        this.lang = locals[selectleng];
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
        this.autodesk_viewer = this.currentProject.autodesk_viewer;
        this.projectid = this.currentProject.id;
        // if(this.currentProject.autodesk_urn){
        //            console.log("mounted test");
        // }
        this.getDrawings();
    },
    created() {
        console.log("df");
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
        this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
        this.autodesk_viewer = this.currentProject.autodesk_viewer;
        this.projectid = this.currentProject.id;
        this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));
        
        this.getToken();
        this.getDrawings();
    },
    watch: {
        "$store.state.currentCamera": function () {
            this.getToken();
        },
        "$store.state.currentZone": function () {
            // console.log("Current data get",this.$store.state.currentCamera)
            this.zoneid = this.$store.state.currentZone;
            this.currentZoneDate = JSON.parse(localStorage.getItem("currentZoneData"));
            console.log("current zone", this.zoneid);
            this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
            this.autodesk_viewer = this.currentProject.autodesk_viewer;
            this.projectid = this.currentProject.id;
             this.getToken(); 
             this.getDrawings();
           
        },
            '$store.state.drawingToggle': function () {

            this.drawingToggle = this.$store.state.drawingToggle;
            // console.log(this.drawingToggle);

            }
    },
    methods: {
        validate() {
            this.$refs.uploadDrawing.validate();
        },
        // documentLoadError(eventData) {
        //     console.log(`Progress: ${eventData}`);
        //     this.docloaded = false;
        //     this.NoHaveDocId = true;
        // },
        handleProgressUpdated: function () {
            console.log("Progress");
        },
        getToken() {
            console.log("first", this.autodesk_viewer);
            //  var self = this;

            if (this.autodesk_viewer == 1) {
                this.showadd = false;

                var _self = this;

                axios({
                        url: "/interior/autodesk-token",
                        method: "post"
                    })
                    .then(resp => {
                        this.myToken = resp.data.access_token;
                        console.log("token", this.myToken);

                        if (this.currentZoneDate.autodesk_urn) {
                            this.docloaded = true;
                            this.NoHaveDocId = false;
                            this.myObjectUrn = this.currentZoneDate.autodesk_urn;
                       

                        const Autodesk = window.Autodesk;
                        // var viewer;
                        var options = {
                            env: "AutodeskProduction2",
                            api: "streamingV2", // for models uploaded to EMEA change this option to 'streamingV2_EU'
                            getAccessToken: function (onTokenReady) {
                                var token = resp.data.access_token;
                                var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
                                onTokenReady(token, timeInSeconds);
                            }
                        };

                        console.log("options", options);

                        Autodesk.Viewing.Initializer(options, function () {
                            _self.preloader = true;
                            var htmlDiv = document.getElementById("forgeViewerzone");
                            _self.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                            var startedCode = _self.viewer.start();
                            if (startedCode > 0) {
                                console.error(
                                    "Failed to create a Viewer: WebGL not supported."
                                );
                                return;
                            }

                            console.log("Initialization complete, loading a model next...");

                            var documentId = "urn:" + _self.currentZoneDate.autodesk_urn;
                            console.log("documentId", documentId);
                            Autodesk.Viewing.Document.load(
                                documentId,
                                onDocumentLoadSuccess,
                                onDocumentLoadFailure
                            );

                            function onDocumentLoadSuccess(viewerDocument) {
                                var defaultModel = viewerDocument
                                    .getRoot()
                                    .getDefaultGeometry(); //viewerDocument.getRoot().getDefaultGeometry();
                                //(viewableId ? doc.getRoot().findByGuid(viewableId) : doc.getRoot().getDefaultGeometry())
                                console.log("defaultModel", defaultModel);

                                _self.viewer.loadDocumentNode(viewerDocument, defaultModel);
                               
                                // _self.viewer.addEventListener(
                                //     Autodesk.Viewing.SELECTION_CHANGED_EVENT
                                // );
                                
                                 setTimeout(() => {
                                    _self.onDocumentLoadS();
                                    _self.preloader = false;
                                 }, 10000);
                                
                                
                            }

                           

                            function onDocumentLoadFailure() {
                                console.error("Failed fetching Forge manifest");
                            }
                        });
                         }else{
                            this.docloaded = false;
                            this.NoHaveDocId = true;
                            this.preloader = false;
                        }

                        
                    })
                    .catch(err => {
                        console.log(err);
                        this.alert = true;
                        this.alertmessage = this.lang.SomethingWentWrong;
                        this.alertType = "error";
                        this.preloader = false;
                    });
            } else {
                this.showadd = true;
            }
        },
        onDocumentLoadS() {
            var df =  this.viewer.setActiveNavigationTool('bimwalk');
                                 console.log("df",df);
        },
        onDocumentLoadFailure() {
            console.error("Failed fetching Forge manifest");
        },
        handleFileUpload() {
            // this.file = this.files[0];
            console.log("file", this.files);
            this.uploadOn = false;

            this.createBucket();
        },
        createBucket() {
            let bucketname = this.currentProject.pro_dir.toLowerCase() + this.currentZoneDate.zoneid + "buckert";
            var data = {
                bucketKey: bucketname,
                access: "full",
                policyKey: "transient"
            };

            const config = {
                headers: {
                    Authorization: "Bearer " + this.myToken
                }
            };

            axios
                .post("https://developer.api.autodesk.com/oss/v2/buckets", data, config)
                .then(resp => {
                    console.log(resp);
                })
                .catch(err => {
                    console.log(err);
                    // this.alert = true;
                    //this.alertmessage = this.lang.SomethingWentWrong;
                    // this.alertType = "error"
                    //this.preloader = false
                    //this.uploadingStart = false
                });
        },
        submitDrawingform() {
            this.uploadingStart = true;

            var _self = this;

            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log(percentCompleted);

                    _self.progree = percentCompleted + "%";
                },
                headers: {
                    Authorization: "Bearer " + _self.myToken
                }
            };
            // let bucketname = this.currentProject.pro_dir.toLowerCase();

            //let bucketname = this.currentProject.pro_dir.toLowerCase() + "bucker";
            let bucketname = this.currentProject.pro_dir.toLowerCase() + this.currentZoneDate.zoneid + "buckert";
            axios
                .put(
                    "https://developer.api.autodesk.com/oss/v2/buckets/" +
                    bucketname +
                    "/objects/" +
                    this.files.name,
                    this.files,
                    config
                )
                .then(resp => {
                    console.log("upload response", resp);
                    this.fileTranslation(resp);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                    this.preloader = false;
                    this.uploadingStart = false;
                });
        },
        fileTranslation(resp) {
            var encodedStr = btoa(resp.data.objectId);
            console.log("encodedStr", encodedStr);
            var p = resp.data.objectKey;

            console.log("file type", p);

            var rootFilename = p;

            var data = {};

            if (p.includes(".zip")) {
                rootFilename = p.replace(".zip", ".iam");

                console.log("rootFilename 1", rootFilename);

                data = {
                    input: {
                        urn: encodedStr,
                        rootFilename: rootFilename,
                        compressedUrn: true
                    },
                    output: {
                        destination: {
                            region: "us"
                        },
                        formats: [{
                            type: "svf2",
                            views: ["2d", "3d"]
                        }]
                    }
                };
            } else if (p.includes(".rvt")) {
                // rootFilename =  p.replace('.rvt', '.iam');

                data = {
                    input: {
                        urn: encodedStr
                    },
                    output: {
                        destination: {
                            region: "us"
                        },
                        formats: [{
                            type: "svf",
                            views: ["2d"]
                        }]
                    }
                };

                console.log("rootFilename 2", rootFilename);
            } else if (p.includes(".nwd")) {
                // rootFilename =  p.replace('.rvt', '.iam');

                data = {
                    input: {
                        urn: encodedStr
                    },
                    output: {
                        destination: {
                            region: "us"
                        },
                        formats: [{
                            type: "svf",
                            views: ["2d"]
                        }]
                    }
                };

                console.log("rootFilename 2", rootFilename);
            }
            else if (p.includes(".nwc")) {
                // rootFilename =  p.replace('.rvt', '.iam');

                data = {
                    input: {
                        urn: encodedStr
                    },
                    output: {
                        destination: {
                            region: "us"
                        },
                        formats: [{
                            type: "svf",
                            views: ["2d"]
                        }]
                    }
                };

                console.log("rootFilename 2", rootFilename);
            }

            // console.log("rootFilename 3",rootFilename);

            console.log("tvdata", data);
            axios
                .post(
                    "https://developer.api.autodesk.com/modelderivative/v2/designdata/job",
                    data, {
                        headers: {
                            Authorization: "Bearer " + this.myToken,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(resp => {
                    console.log("Tranlation response", resp);

                    console.log(resp.data.urn);
                    this.myObjectUrn = resp.data.urn;
                    this.checkProgressStatus(resp.data.urn);
                    this.storeUrnTokenInProject(resp.data.urn);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                    this.preloader = false;
                    this.uploadingStart = false;
                });
        },
        storeUrnTokenInProject(urn) {
            console.log("Store token in db is pandding.");

            var data = {
                urn: urn,
                zoneid: this.currentZoneDate.zoneid
            };

            axios
                .post("/interior/autodesk-token-update-zone/" + this.projectid, data)
                .then(resp => {
                    console.log("urn Token update in DB", resp);
                   this.getCurrentProject();
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                    this.preloader = false;
                    this.uploadingStart = false;
                });
        },
        checkProgressStatus(token) {
            var _self = this;
            //console.log("Store token in db is pandding.");

            axios
                .get(
                    "https://developer.api.autodesk.com/modelderivative/v2/designdata/" +
                    token +
                    "/manifest", {
                        headers: {
                            Authorization: "Bearer " + this.myToken
                        }
                    }
                )
                .then(resp => {
                    console.log("Translation Status", resp);

                    if (resp.data.progress == "complete") {
                        this.progree = resp.data.progress;
                        this.msg = "File Translation is " + resp.data.status;

                        setTimeout(() => {
                            _self.docloaded = true;
                            _self.NoHaveDocId = false;
                            _self.uploadingStart = false;
                        }, 5000);
                    } else {
                        this.progree = resp.data.progress;
                        (this.msg = "File Translation is " + resp.data.status),
                        setTimeout(() => {
                            _self.checkProgressStatus(token);
                        }, 5000);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                    this.preloader = false;
                    this.uploadingStart = false;
                });
        },
        loadModel() {
            console.log("Model loading start.");
        },
        addNewModel() {
            var a = confirm("Are you sure to upload the new model? The existing model will be replaced by the new model after successful uploading.");
            if (a) {
                this.docloaded = false;
                this.NoHaveDocId = true;
                this.deleteBucket();
            }
        },
        deleteBucket() {
            let bucketname = this.currentProject.pro_dir.toLowerCase() + this.currentZoneDate.zoneid + "bucker";
            axios
                .delete(
                    "https://developer.api.autodesk.com/oss/v2/buckets/" + bucketname, {
                        headers: {
                            Authorization: "Bearer " + this.myToken
                        }
                    }
                )
                .then(resp => {
                    console.log("Bucket Status", resp);
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error";
                    this.preloader = false;
                });
        },
        getCurrentProject() {
            let currentProject = JSON.parse(localStorage.getItem("currentProject"));

            var projid = currentProject.id;

            axios({
                    url: "/projec/" + projid,
                    method: "get"
                })
                .then(resp => {
                    console.log("project update date", resp.data);
                    // this.initialSetup(resp.data);
                    localStorage.setItem("currentProject", JSON.stringify(resp.data));

                    this.$router.go();
                })
                .catch(err => {
                    console.log("project update date", err);
                }); 
        },
        viewerInitialized(cc) {
            console.log("viewerInitialized:", cc);
        },
        connectWithCordinate() {
            console.log("Add Coordinate");
            //this.firstparsion();
            this.$router.push({
                name: "interior_biminteriorview"
            });
        },
        getDrawings() {
            this.$store.dispatch('drawingname', 'Loading...');

            // if(this.zoneid == 0){
            //     this.zoneMessage = true;
            // }

            axios({
                url: '/interior/project/' + this.projectid + '/get-drawings',
                method: 'get',
                params: {
                    zone: this.zoneid
                }
            })
                .then(resp => {
                    // console.log("headings", resp);
                    this.drawings = resp.data.success;
                    // console.log("dfasfasdf", resp.data)

                    if (resp.data.success.length > 0) {
                        console.log("is In", resp.data.success);
                        //  console.log("is In");
                        this.zoneMessage = false;

                        this.getDrawing(resp.data.success[0].drawingid);
                        this.getdrawingid = resp.data.success[0].drawingid;
                        console.log("check drawing id", this.getdrawingid);
                    } else {
                        this.zoneMessage = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.alert = true;
                    this.alertmessage = this.lang.SomethingWentWrong;
                    this.alertType = "error"
                    this.preloader = false
                })
        },
        goToDrawing(n) {
            this.getdrawingid = n.drawingid;
            console.log("check drawing id", this.getdrawingid);
            this.currentDrawingName = n.name;
            this.radius = n.point_size;

            this.activeDrawing = n;
           
            this.getDrawing(n.drawingid);

            this.drawingToggle = false;
            //this.latesttrue = true;
            this.refresh++;
            this.refresh3++;

            this.$store.dispatch('openDrawing', false);
            this.$store.dispatch('drawingname', n.name);

        },
    }
};
</script>

<style>
.forge-vuer-container {
    width: 100%;
    height: 100%;
    position: absolute !important;
    /* display: block; */
    /* box-sizing: border-box; */
}

.fileuploadZone {
    width: 50%;
    height: 73vh;
    background: #fff;
    box-shadow: 0 0 20px 2px #ccc;
    text-align: center;
    margin: auto;
    position: relative;
    top: 5%;
    border-radius: 5px;
    padding: 15px;
}

.uploadx {
    display: block;
    position: relative;
    top: 33%;
}

.newmodel {
    position: relative;
    /* left: 5px;
top: 10px; */
    z-index: 9;
}

.newmodelzone {
    position: relative;
    /* left: 170px;
top: 10px; */
    z-index: 9;
}

.connectWithCordinate {
    position: relative;
    /* left: px;
top: 10px; */
    z-index: 9;
}

.msgbox {
    width: 600px;
    text-align: center;
    position: relative;
    margin: auto;
    background: #ccc;
    padding: 50px;
    top: 100px;
    border-radius: 5px;
    font-size: 24px;
}

.topbr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
div#forgeViewerzone {
    height: 90vh;
}
</style>

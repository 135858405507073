<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col     cols="12"
           xs="12"
           sm="3"
           md="3"
           lg="3"
           xl="3">
<!-----------------For Desktop view-------------------------------->
        <v-card class="vhfix desktop">
          <v-card-title>Select Project</v-card-title>
          <v-list class="desktop">
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :class="item.id == active_proid ?'myactiaveitem':''"
                active-class="actiaveitem"
                color="amber accent-3"
                @click="getCamerasbyProject(item)"
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.project_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
<!-----------------For Mobile view-------------------------------->
        <v-card
          class="vhfix mobileonly">
            <v-select :items="items" class="ma-2 filterb"  item-text="project_name" item-value="project_name" small label="Select Project" dense solo v-model="selected_project" @change="getCamerasbyProject($event)" return-object single-line title="Select Project"></v-select>
        </v-card>
<!-----------------For Tab view-------------------------------->         
         <v-card
          class="vhfix tabonly">
                <v-card-title>
                      Select Proj
                </v-card-title>
              <v-list class="tabonly">
                <v-list-item-group color="primary">
              <v-list-item  v-for="(item, i) in items"
                  :key="i"
                  :class="item.id == active_proid ?'myactiaveitem':''"
                  active-class="actiaveitem" color="amber accent-3"   @click="getCamerasbyProject(item)" > 
              <template >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.project_name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>  
            </v-list-item-group>
            </v-list> 
        </v-card> 
      </v-col>
<!--------------General view for all device------------------------------------>
      <v-col cols="12"
           xs="12"
           sm="9"
           md="6"
           lg="6"
           xl="6">
        <v-card class="minheights">
          <v-card dark flat>
            <v-card-title class="pa-2 amber accent-3">
              <h3 class="title font-weight-light text-center grow">
                {{ CurrentPname }} - {{lang.EditCameraNameandTag}}
              </h3>
            </v-card-title>
          </v-card>
          <v-snackbar
            v-model="snackbar"
            center
            top
            timeout="3000"
            type="success"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <!--  <v-row>
            <v-col cols="12" sm="3" md="3"></v-col>

            <v-col cols="12" sm="3" md="3" class="ii_margin">Camera Name</v-col>
            <v-col cols="12" sm="3" md="3" class="bi_margin">Camera Tag</v-col>
            <v-col cols="12" sm="3" md="3">
              <span class="info_rec" style="float: right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-progress-question
                    </v-icon>
                  </template>
                  <span
                    >Camera name shall appear on the dropdown of camera option
                    and Camera tag shall appear on the bottom left of dashboard
                    image.</span
                  >
                </v-tooltip>
              </span></v-col
            >
          </v-row> -->
          <!-- <v-row class="pt-1" v-for="(item, i) in camers" :key="i">
            <v-col cols="12" sm="2" md="2">
              <strong>CAM {{ i + 1 }}</strong> <br />
            </v-col>
           

            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="item.cam_name"
                label="Camera Name"
                title="Camera Name"
                clearable
                maxlength="25"
              ></v-text-field>
             
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="item.status"
                label="Camera Tag"
                title="Camera Tag"
                clearable
                maxlength="25"
              ></v-text-field>
            </v-col>
            <v-col class="" cols="12" sm="3" md="3">
              <v-btn small color="success" v-on:click="submit_req(item)">
                Submit
              </v-btn>
              <v-btn
                small
                color="success"
                v-on:click="reset_req(item)"
                style="margin-left: 5%"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row v-if="camers.length > 1">
            <v-col cols="12" md="6" v-for="(item, i) in camers" :key="i">
              <v-card class="pa-5">
                <v-card-title> {{item.pro_cam_serial}}</v-card-title>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.cam_name"
                    :label="lang.CameraName"
                    :title="lang.CameraName"
                    clearable
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.status"
                    :label="lang.CameraTag"
                    :title="lang.CameraTag"
                    clearable
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-btn small color="success" v-on:click="submit_req(item)">
                  {{lang.submit}}
                </v-btn>
                <v-btn
                  small
                  color="success"
                  v-on:click="reset_req(item)"
                  style="margin-left: 5%"
                >
                  {{lang.Reset }}
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="camers.length == 1">
            <v-col cols="12" md="12" v-for="(item, i) in camers" :key="i">
              <v-card class="pa-5">
                <v-card-title> {{item.pro_cam_serial}}</v-card-title>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.cam_name"
                    :label="lang.CameraName"
                    :title="lang.CameraName"
                    clearable
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.status"
                    :label="lang.CameraTag"
                    :title="lang.CameraTag"
                    clearable
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-btn small color="success" v-on:click="submit_req(item)">
                  {{lang.submit}}
                </v-btn>
                <v-btn
                  small
                  color="success"
                  v-on:click="reset_req(item)"
                  style="margin-left: 5%"
                >
                  {{lang.Reset }}
                </v-btn>
              </v-card>
            </v-col>
          </v-row> 
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar,
  },
  name: "CamNameTag",
  data() {
    return {
      preloader: false,
      items: {},
      selected_project:'',
      camers: [],
      camdata: [],
      CurrentPname: "",
      checkboxactive: false,
      img_interval: "",
      param_up: {},
      counter: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      alert: false,
      alertmessage: "",
      alertType: "",
      updatecamdata: {},
      dialog: false,
      simdialog: false,
      sim_cam_id: "",
      sim_all_data: {},
      addcamnote: "",
      notecam_id: "",
      currentprojectid: "",
      addsimnote: "",
      addsimdata: "",
      alotsim: "",
      checkusage: false,
      op_usage: [
        { op_usage: "free", title: "free" },
        { op_usage: "used", title: "used" },
      ],
      usage_status: "",
      updated_by: "",
      simprovider: "",
      withwhom: "",
      enablesave: false,
      currentProject: "",
      active_proid:"",
      Local :locals,  
        lang : {}, 
        camera_name:'',
    };
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
    this.getPorjects();
    this.current_cam = this.$store.state.currentCamera;
     this.camera_name = this.current_cam.pro_cam_serial;
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    //console.log("userdata", this.userdata.email);
    this.updated_by = this.userdata.email;
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
     this.active_proid = this.currentProject.id;
  },
  watch: {
    "$store.state.currentCamera": function () {
      //console.log("Current data get", this.$store.state.currentCamera);
      this.camers = [];
      this.current_cam = this.$store.state.currentCamera;
       this.camera_name = this.current_cam.pro_cam_serial;
      this.getPorjects();
      //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
      // this.img_interval  = this.current_cam.image_interval;
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
     this.active_proid = this.currentProject.id;
    },
  },
  computed: {
    params() {
      return {
        interval_update: this.img_interval,
        reboot_update: this.checkboxactive ? 1 : 0,
      };
    },
  },
  methods: {
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });

          //this.getCamerasbyProject(this.items[0]);
          this.getCamerasbyProject(this.currentProject);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCamerasbyProject(projectId) {
      this.preloader = true;
      this.active_proid = projectId.id || this.selected_project.id;
      // console.log(projectId);
      this.CurrentPname = projectId.project_name || this.selected_project.project_name;
      this.currentprojectid = projectId.id || this.selected_project.id;
      this.camers = {};
      this.camdata = [];
      axios({ url: "/camera/" + this.active_proid, method: "GET" })
        .then((resp) => {
          //console.log('get cameras by project', resp.data.success);
          this.camers = resp.data.success.filter(function (item) {
            if (item.cam_name == "ov") {
              item.cam_name = "";
            } else {
              console.log("camname defined", item.cam_name);
            }
            return item.camera_active == 1;
          });
          var len = 0;
          if (resp.data.success != null) {
            len = resp.data.success.length;
          }
          if (len > 0) {
            //console.log("count", resp.data.success.length);
            for (var i = 0; i < len; i++) {
              if (
                this.current_cam.camera_id == resp.data.success[i].camera_id
              ) {
                var camnumber = i + 1;
                console.log(camnumber);
              }
              this.checkboxactive =
                resp.data.success[i].reboot == 1 ? true : false;
              this.img_interval = resp.data.success[i].image_interval;
            }
          }
          // console.log("Cameras", this.camers);
          var a = 1;
          this.camers.forEach((cam) => {
            this.getLattersImage(a, cam);
            //this.counter++;

            a++;
          });
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getLattersImage() {
      // console.log("lates images cam data",camdata);
    },

    submit_req(item) {
      var pid = this.currentprojectid;
      this.param_up = {
        cam_id_up: item.camera_id,
        status: item.status,
        cam_name: item.cam_name,
      };
      //console.log("data to update", this.param_up);
      axios({
        url: "/camera/" + item.camera_id + "/update_camera_nameandtag",
        data: this.param_up,
        method: "POST",
      })
        .then((resp) => {
          //console.log("result", resp.data.success);
          if (resp.data.success) {
            this.snackbar = true;
            this.text = this.lang.camsetting;
            /* setTimeout(() => {
              location.reload();
            }, 3000); */
            //this.getCamerasbyProject(this.currentprojectid);
            console.log("check project id", pid)
          } else {
            this.snackbar = true;
            this.text = this.lang.camfialdsetting;
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;

          /*  setTimeout(() => {
            location.reload();
          }, 3000); */
        });
    },
    reset_req(item) {
      this.param_up = {
        cam_id_up: item.camera_id,
        status: "",
        cam_name: "ov",
      };
      //console.log("data to update", this.param_up);
      axios({
        url: "/camera/" + item.camera_id + "/update_camera_nameandtag",
        data: this.param_up,
        method: "POST",
      })
        .then((resp) => {
          //console.log("result", resp.data.success);
          if (resp.data.success) {
            this.snackbar = true;
            this.text = this.lang.camsetting;
            setTimeout(() => {
              location.reload();
            }, 3000);
          } else {
            this.snackbar = true;
            this.text = this.lang.camfialdsetting;
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;

          /*  setTimeout(() => {
            location.reload();
          }, 3000); */
        });
    },
  },
};
</script>
<style>
.vhfix {
  overflow: auto;
}
.minheights {
  min-height: 91vh;
}
.alignbox {
  margin-top: -3%;
}
.mx-2 {
  font-size: 10px !important;
}
.username {
  color: #f3bd08;
  font-weight: 500;
  font-size: 16px;
}
.update_date {
  text-align: right;
  margin-right: 3%;
}
.p_username {
  margin-left: 4%;
}
.p_note {
  margin-left: 4%;
}
.btn_margin {
  margin-left: -3%;
}
.pt-1 {
  /* margin-top: 2%; */
  margin-left: 2%;
  margin-bottom: 4%;
}
.sub_btn_margin {
  margin-top: -28%;
}
.ii_margin {
  margin-left: -4%;
}
.bi_margin {
  margin-left: -2%;
}
.freesimnostyle {
  background-color: #4caf50 !important;
}
.usedsim {
  background: #d88585;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}
.freesim {
  background: #9cdf9c;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}
.myactiaveitem{
  color: #ffc400 !important;
    caret-color: #ffc400 !important;
    background-color: #fff8e1 !important;
}
</style>


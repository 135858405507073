<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="pa-5">

      <v-row>
        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
          <h3 :class="`hetitle barchart ${ barchartActive ? 'active':''}`" @click="barchartload"> Bar Chart </h3>
        </v-col>

        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
          <h3 :class="`hetitle barchart ${ linechartActive ? 'active':''}`" @click="linechartload"> Line Chart </h3>
        </v-col>

       

        <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
          <h3 :class="`hetitle ${ alertActive ? 'active':''}`" @click="reportAlertImages"> Alert Images </h3>
        </v-col>

        <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
          <h3 class="hetitle" @click="setrouter('/dashboard/ai-ppe-reports')"> Reports </h3>
        </v-col>

        <v-col class="datepicker cvc" cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
          <!-- <ejs-daterangepicker :change="onValueChange" :minDays="min" :maxDays="max" :strictMode="mode" :placeholder="this.lang.selectRange">
    
                    </ejs-daterangepicker> -->
          <date-range-picker @update="onValueChange" :ranges="false"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" v-model="reportdate" opens="left"
            :date-format="dateFormat"></date-range-picker>
          <p style="color:#d4425b;font-size:11px"> {{ lang.max7 }} </p>
          
        </v-col>
        <div class="helpinfo">
                <v-icon dark class="helps" color="rgb(43 43 43)" style="font-size: 2rem !important">
                    mdi-help-circle
                </v-icon>
                <p>PEE detection accuracy is best when the person is within 15-20 meters from the camera. <br />
               </p>
              </div>
      </v-row>
      <v-row>

        <v-col cols="6" v-if="showChart && barchartActive">
          <apexchart type="radialBar" height="390" :options="chartOptions" :series="series"></apexchart>
          <h4 class="chart_title"> Overall PPE Compliance Summary </h4>
        </v-col>
        <v-col cols="6">
          <div id="chart" v-if="showChart && barchartActive">
            <apexchart type="bar" height="350" @click="clickHandlerpreset" :options="stepchartOptions" :series="barseries">
            </apexchart>
            <h4 class="chart_title"> Daily PPE Compliance Overview  </h4>
            <!-- <apexchart type="pie" width="480" :options="paichartOptions" :series="paiseries"></apexchart> -->
          </div>
        </v-col>
       
        <v-col cols="12" v-if="presetshowChart && barchartActive">
          <apexchart type="bar" height="450" @click="presetclickHandler" :options="presetstepchartOptions" :series="presertbarseries"></apexchart>
          <h4 class="chart_title"> PPE Compliance - Location wise </h4>
        </v-col>
        <v-col cols="12" v-if="lineshowChart && linechartActive">
          <v-col cols="12" class="filerdropdownforline">
           <v-select 
        :items="imagesType"
        label="Filter Parsons"
        v-model="selectdlineTypem"
        small
        @change="selectlinetype($event)"
        solo
        outlined
        color="white"
      ></v-select> 
    </v-col>
          <apexchart ref="lineChart" type="line" height="350"  @click="clickLineHandler"  :options="linechartOptions" :series="lineseries"></apexchart>
          <h4 class="chart_title"> Daily PPE Compliance Overview  </h4>
        </v-col>
      </v-row> 
     <v-row>   
   <div class="imageslist" v-if="selectpresetImage.length > 0 && barchartActive">
       <v-col cols="12" class="filerdropdown">
           <v-select 
        :items="imagesType"
        label="Filter Parsons"
        v-model="selectdImageType"
        small
        @change="selectdImageTypefunction($event)"
        solo
        outlined
        color="white"
      ></v-select> 
    </v-col>
  </div>
  </v-row>
  <v-row v-if="barchartActive || linechartActive">
        <v-col v-for="(image, index) in filterimages" :key="index" class="d-flex child-flex camimage" cols="3">
           
                    <v-img :src="image.thumb" v-if="image.thumb" aspect-ratio="1.8" class="grey lighten-2" @click="openimage(image,4)">
                     
                        <!-- <span class="imagetime">{{ getTime(image.image_creation_time) }}</span> -->
                      </v-img>
                        <div class="menufooter ai mi">
                            <!-- <v-btn class="mx-1" @click="openimage(image, 0)" fab x-small color="#fff" title="Total Person Detected">
                                <v-icon color="#1ab7ea"> mdi-shield-check</v-icon>
                            </v-btn>
                            {{ parsone_count_all(image)}} -->
                           
                            <v-btn class="mx-1" @click="openimage(image, 1)" fab x-small color="#fff" title="Protected Persons">
                                <v-icon color="#00e396"> mdi-shield-check</v-icon>
                            </v-btn>
                             {{ image.total_p_j_h}}
                           
                            <v-btn class="mx-1" @click="openimage(image, 2)" fab x-small color="#fff" title="Person without Helmet">
                                <v-icon color="#f8bb03"> mdi-shield-check</v-icon>
                            </v-btn>
                             {{ image.total_person_wo_h }}
                            
                            <v-btn class="mx-1"  @click="openimage(image, 3)" fab x-small color="#fff" title="Person without Jacket">
                                <v-icon color="#fc7e40"> mdi-shield-check</v-icon>
                            </v-btn>
                             {{ image.total_person_wo_j}}
                            
                            <v-btn class="mx-1" @click="openimage(image, 4)" fab x-small color="#fff" title="Unprotected Persons ">
                                <v-icon color="#e23b3b"> mdi-shield-off</v-icon>
                            </v-btn>
                            {{ image.total_person_u }}
                        </div>
                    
                </v-col>
              </v-row>
            <v-zoomer class="zzoomerfull" v-if="dialog">
               <img
            id="plan3"
            :src="img"
            @load="loaded"
            style="object-fit: contain; width: 100%; height: 100%"
          />
         
            </v-zoomer>
            <div class="menufooter mifull" v-if="dialog">
                            <!-- <v-btn class="mx-1" @click="openimageframe(currentimagedata, 0)" fab x-small color="#fff" title="Total Person Detected">
                                <v-icon color="#1ab7ea"> mdi-shield-check</v-icon>
                            </v-btn>
                            {{ parsone_count_all(currentimagedata)}} -->
                           
                            <v-btn class="mx-1" @click="openimageframe(currentimagedata, 1)" fab x-small color="#fff" title="Protected Persons">
                                <v-icon color="#00e396"> mdi-shield-check</v-icon>
                            </v-btn>
                            
                             {{ fparsone_protected}}
                           
                            <v-btn class="mx-1" @click="openimageframe(currentimagedata, 2)" fab x-small color="#fff" title="Person without Helmet">
                                <v-icon color="#f8bb03"> mdi-shield-check</v-icon>
                            </v-btn>
                           
                             {{ fparsone_withoout_healmet}}
                            
                            <v-btn class="mx-1"  @click="openimageframe(currentimagedata, 3)" fab x-small color="#fff" title="Person without Jacket">
                                <v-icon color="#fc7e40"> mdi-shield-check</v-icon>
                            </v-btn>
                             
                             {{ fparsone_without_jacket}}
                            
                            <v-btn class="mx-1" @click="openimageframe(currentimagedata, 4)" fab x-small color="#fff" title="Unprotected Persons">
                                <v-icon color="#e23b3b"> mdi-shield-off</v-icon>
                            </v-btn>
                           
                            {{ fparsone_unprotected}}

                            &nbsp;   <v-icon color="#fff" large> mdi-drag-vertical-variant </v-icon>

                            <v-btn class="mx-1"  @click="openimageframe(currentimagedata, 'onlyhealmet')" fab x-small color="#fff" title="Helmets">
                                <v-icon color="#002b36"> mdi-account-hard-hat</v-icon>
                            </v-btn>
                             
                             {{ helmetids.length}}
                            
                            <v-btn class="mx-1" @click="openimageframe(currentimagedata, 'onlyjacket')" fab x-small color="#fff" title="Jackets">
                                <v-icon color="#ff5f1f"> mdi-tshirt-v</v-icon>
                            </v-btn>
                           
                            {{ jacketids.length }}
                        </div>
            <v-icon v-if="dialog" class="closebuttons" @click="dialog = false">mdi-window-close</v-icon>

      
      <v-row v-if="alertActive && showImage">
        <v-col cols="12">
          <h4 > Images for "{{titleline}}" </h4>
        </v-col>
        <br>
        <v-col v-for="(image, index) in alertimagesArray" :key="index" class="d-flex child-flex camimage" cols="3">
           
           <v-img :src="image.thumb" v-if="image.thumb" aspect-ratio="1.8" class="grey lighten-2" @click="openimageAlert(image,4)">
            
               <!-- <span class="imagetime">{{ getTime(image.image_creation_time) }}</span> -->
             </v-img>
               <div class="menufooter ai mi">
                   <!-- <v-btn class="mx-1" @click="openimage(image, 0)" fab x-small color="#fff" title="Total Person Detected">
                       <v-icon color="#1ab7ea"> mdi-shield-check</v-icon>
                   </v-btn>
                   {{ parsone_count_all(image)}} -->
                
                  
                   <v-btn class="mx-1" @click="openimageAlert(image, 2)" fab x-small color="#fff" title="Person without Helmet">
                       <v-icon color="#f8bb03"> mdi-shield-check</v-icon>
                   </v-btn>
                    {{ image.without_helmet }}
                   
                   <v-btn class="mx-1"  @click="openimageAlert(image, 3)" fab x-small color="#fff" title="Person without Jacket">
                       <v-icon color="#fc7e40"> mdi-shield-check</v-icon>
                   </v-btn>
                    {{ image.without_jacket}}
                   
                   <v-btn class="mx-1" @click="openimageAlert(image, 4)" fab x-small color="#fff" title="Unprotected Persons ">
                       <v-icon color="#e23b3b"> mdi-shield-off</v-icon>
                   </v-btn>
                   {{ image.total_unprotected }}
               </div>
           
       </v-col>
        </v-row>
    </div>
  </div>
</template>

<script>
//import Vue from 'vue';
import axios from 'axios';
import Preloader from '../../components/PreLoader';
import VueApexCharts from 'vue-apexcharts'
import { locals } from "../../utils/locals";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import thumb from "../../assets/thumbnail.png"

import { Annotorious } from "@recogito/annotorious";
import "@recogito/annotorious/dist/annotorious.min.css";
import SelectorPack from "@recogito/annotorious-selector-pack";
import ShapeLabelsFormatter from "@recogito/annotorious-shape-labels";
import { setTimeout } from 'core-js';


// import {
//     DateRangePickerPlugin
// } from '@syncfusion/ej2-vue-calendars';
//import lodash from 'lodash'
//Vue.use(DateRangePickerPlugin);

//var self = this
export default {
  components: {
    Preloader,
    apexchart: VueApexCharts,
    DateRangePicker
  },
  name: "AiData",
  data() {
    return {
      thumb: thumb,
      reportdate: {
        startDate: "",
        endDate: ""
      },
      dates: ['01-01-2021', '01-01-2025'],
      currentcam: {},
      preloader: true,
      showChart: false,
      preshowChart: false,
      currentProject: {},
      records: {},
      series: [100, 100, 100, 100, 100],
      chartOptions: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                show: true,
                formatter: function (val) {
                  return val + "%"; // Custom formatter for the value
                }
              }
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              margin: 8,
              fontSize: '16px',
              formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
              },
            },
          }
        },
        colors: ['#1ab7ea', '#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
        labels: ['Total Person Detected', 'Protected Persons', 'Person with Helmet', 'Person with Jacket', 'Unprotected Persons'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },
      time3: '',
      max: '8',
      min: '1',
      waterMark: 'Select a Range',
      mode: true,
      menu2: false,
      menu3: false,


      aidate: '',
      firstnamerules: [(v) => !!v || "This Field is required"],
      valid: true,
      presetid: '',
      image_type: 'All',
      ai_data_images: [],
      dialog: false,
      img: '',
      personsWithRequiredPPET: 0,
      WithoutRequiredPPET: 0,
      UnrecognizedT: 0,
      selectbarIndex: 0,
      selectbar: 0,
      presetlist: [],
      cimi: 0,
      alldata: [],
      Local: locals,
      lang: {},

      barseries:[ {
                name: 'Protected Persons',
                data: []
              },
              {
                name: 'Person without Helmet',
                data: []
              },
              {
                name: 'Person Without Jacket',
                data: []
              },
              {
                name: 'Unprotected Persons',
                data: []
              }
         ],
      presertbarseries : [{
name: 'Protected Persons',
data: []
},
{
name: 'Person without Helmet',
data: []
},
{
name: 'Person Without Jacket',
data: []
},
{
name: 'Unprotected Persons',
data: []
}
],
     // colors: ['#1ab7ea', '#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
      //  labels: ['All Parsone Detected', 'Protected Parsone', 'Parsone with Helmet', 'Parsone with Jacket', 'Unprotected Persone'],

       lineseries: [{
                name: 'Total Person Detected ',
                data: [0]
            }, {
                name: 'Protected Persons',
                data: [0]
            },
            {
                name: 'Person with Helmet',
                data: [0]
            },
            {
                name: 'Person with Jacket ',
                data: [0]
            },
            {
                name: 'Unprotected Persons',
                data: [0]
            }
          ],
      barchartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['1 May', '2 May', '3 May', '4 May', '5 May', '6 May', '7 May'],
        },
        yaxis: {
          title: {
            text: '$ (Parsons)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " Parsons"
            }
          }
        }
      },
      colors: ['#1ab7ea', '#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
      stepseries: [{
        name: 'PRODUCT A',
        data: [44, 55, 41, 67, 22, 43]
      }, {
        name: 'PRODUCT B',
        data: [13, 23, 20, 8, 13, 27]
      }, {
        name: 'PRODUCT C',
        data: [11, 17, 15, 15, 21, 14]
      }, {
        name: 'PRODUCT D',
        data: [21, 7, 25, 13, 22, 8]
      }],
      stepchartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        colors: ['#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: false,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      datawithdate: [],
      presetids: [],
      presets: [],

      presetstepchartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        colors: ['#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: false,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      presetshowChart : false,
      selectpresetImage : [],
      meta : {},
      selectedtype : 0,
      linechartOptions: {
            chart: {
              height: 350,
              type: 'line',
               toolbar: {
                        show: false
                    },
              zoom: {
                enabled: false
              },
            },
            colors: ['#1ab7ea', '#00e396', '#f8bb03', '#fc7e40', '#e23b3b'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: [2,2,2,2,2],
              curve: 'smooth',
              dashArray: [0,0,0,0,0]
            },
            title: {
              text: '',
              align: 'left'
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: [],
               tickPlacement: 'between',
               tickAmount: 25,
                labels: {
          show: true,
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
                }
            },
          yaxis: {
              labels: {
              formatter: function(val) {
                return Math.floor(val)
              }
              }
          },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            },

          },
          lineshowChart : false,
          currentimagedata : '',
          linechartdata : {},
          imagesType : ['Unprotected Persons', 'Person Without Healmet', 'Person without Jacket', 'Protected Persons'],
          selectdImageType : 'Unprotected Persons',
          filterimages : '',
          helmetids : '',
          jacketids : '',
          imagesppedata : {},
          barchartdata :[],
          bardate : [],
          loopdata : [],
          presetDate : '',
          fparsone_withoout_healmet : 0,
          fparsone_protected : 0,
          fparsone_without_jacket :0,
          fparsone_unprotected :0,
          linechartActive : false,
          barchartActive : true,
          selectdlineTypem : 'Unprotected Persons',
          alertActive: false,
          alertimagesArray : [],
          showImage : false,
          instant_applicants : [],
          titleline : ''
    }
  },
  mounted() {

    this.currentProject = JSON.parse(localStorage.getItem('currentProject'));

    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];

    this.currentcam = this.$store.state.currentCamera;

    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.dialog = false;
      }
    })
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
        if (this.userdata.open_access == 1) {
            this.$router.push('/dashboard')
        }
        if (this.currentcam.ppe == 0) {
       this.$router.push('/dashboard');
        }
  },
  created() {
    this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
    this.initViewer();
    //this.presets = this.$store.state.currentCamera.preset_ids.split(",");
  },
  watch: {
    '$store.state.currentCamera': function () {

      this.currentcam = this.$store.state.currentCamera;

      // if (this.currentcam.ppe == 0) {
      //     this.$router.push('/dashboard');
      // }

      // console.log("this.currentcam =", this.currentcam);
      this.getCurrentProjectData('');

      this.presets = this.$store.state.currentCamera.preset_ids.split(",");

      this.initViewer();
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    params() {
      return {
        aidate: this.aidate,
        presetid: this.presetid ? this.presetid : 1,
        image_type: this.image_type
      }
    }
  },
  methods: {
    selectdImageTypefunction(i){
       console.log(i, this.selectdImageType);

      //  var parsone_protected = this.parsone_protected(p);
      //  var parsone_with_healmet = this.parsone_with_healmet(p);
      //  var parsone_with_jacket = this.parsone_with_jacket(p);
      //  var parsone_unprotected = this.parsone_unprotected(p);
      this.filterimages = [];

     var filteritem = this.selectpresetImage;

       if(this.selectdImageType == 'Person without Jacket'){
        this.filterimages =  filteritem.filter(function(item){
          return item.total_person_wo_j > 0;         
        })
       }else if(this.selectdImageType == 'Person Without Healmet'){
        this.filterimages = filteritem.filter(function(item){
            console.log(item);
          return item.total_person_wo_h > 0;         
        })
       }else if(this.selectdImageType == 'Unprotected Persons'){
        this.filterimages =  filteritem.filter(function(item){
          return item.total_person_u > 0;         
        })
       }else if(this.selectdImageType == 'Protected Persons'){
        this.filterimages =  filteritem.filter(function(item){
          return item.total_p_j_h > 0;         
        })
       }

    },
    CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 7);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

           this.reportdate = {
            startDate : start,
            endDate : end
           }
        
      },
    getTime(t){

        var time = t.split("T");

        return time[1];
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() >= new Date()
      }
      return classes
    },

    clickonTopchart(event, chartContext, config) {

      // // console.log("sdds",config.dataPointIndex);

      var d = this.alldata[config.dataPointIndex];
    //  // console.log("ddd", d.filterdate);

      this.aidate = d.filterdate;

      this.get_AI_Data();

      var v = this;


      setTimeout(function () {
        v.get_AI_Images(0, 0)
      }, 8000);
      //this.get_All_AI_Images(config.dataPointIndex, config.seriesIndex)
    },

    initViewer() {
      //// console.log("init Viewer");
      this.currentcam = this.$store.state.currentCamera;
      this.preloader = true
      
      this.CurrentDateFunction();
       if(this.barchartActive){
        this.getCurrentProjectData('');
        this.getSevenDaysImagesData('');
       }else{
        this.linechartDataMaker('');
       }
    },
    linechartload(){
      this.linechartActive = true;
      this.barchartActive = false;
      this.alertActive = false;
      this.initViewer()
    },
     barchartload(){
      this.barchartActive = true;
      this.linechartActive = false;
      this.alertActive = false;
      this.initViewer()
     },
    openimageframe(data, index){

       console.log('data == .',data);

       this.openimage(data, index); 

      // if(this.alertActive){

      //   this.openimageAlert(data, index); 

      // }else{

      //   this.openimage(data, index); 
      // }
       

    },
    openimage(data, index) {

      
      this.preloader = false;

      let currentcam = this.currentcam.camera_id;
      axios({
        url: 'ov-ai-with-id/'+currentcam,
        //  url: '/ai/812/get-ai-images-record',
        method: 'GET',
        params: {
           "recordid" : data.id,
        }
      })
        .then(resp => {
          console.log("resp id", resp.data.success);

            var imageidData = resp.data.success;

            this.imagesppedata = JSON.parse(resp.data.success.ppe_result);

            console.log(this.imagesppedata);

           var string = imageidData.metadata;

           console.log("meta",string);

           var jsonString = string.replace(/'/g, '"');

// Step 2: Add double quotes around keys
jsonString = jsonString.replace(/(\w+)(?=\s*:)/g, '"$1"');

try {
    // Parse the JSON string
    var metaresult = JSON.parse(jsonString);
    console.log(metaresult);
} catch (error) {
    console.error('Invalid JSON string:', error);
}

        //     var metaJsonStr = JSON.stringify(meta);

        // // Parse the JSON string back to an object
        // var metaresult = JSON.parse(metaJsonStr);

        // fparsone_withoout_healmet : 0,
        //   fparsone_protected : 0,
        //   fparsone_without_jacket :0,
        //   fparsone_unprotected

        var p = imageidData;
        var harray = [];
        var jarray = [];
        var unprotected = [];

            if (this.isValidJSON(p.person)) {

              unprotected = JSON.parse(p.person);

              console.log("unprotected",unprotected);

            var pcount = unprotected.length;

            this.fparsone_unprotected = pcount

            } 

            if (this.isValidJSON(p.persons_h)) {

             harray = JSON.parse(p.persons_h);

             console.log("harray",harray);
          
            }

            if (this.isValidJSON(p.persons_j)) {
               jarray = JSON.parse(p.persons_j);
               console.log("jarray",jarray);
            }

            var mprotectedp = jarray.filter(element => harray.includes(element));

 console.log("line mprotectedp", mprotectedp);
// // console.log("mergedArr",parsones,protectedp);

this.fparsone_protected = mprotectedp.length;

            let arr1 = [...jarray, ...unprotected];
            var withoout_healmet = [...new Set(arr1)];

               withoout_healmet = withoout_healmet.filter(element => !mprotectedp.includes(element));

            console.log("withoout_healmet",withoout_healmet);

            this.fparsone_withoout_healmet = withoout_healmet.length;


            let arr2 = [...harray, ...unprotected];
            var without_jacket = [...new Set(arr2)];

            without_jacket = without_jacket.filter(element => !mprotectedp.includes(element));

            console.log("without_jacket",without_jacket);

            this.fparsone_without_jacket = without_jacket.length;
          var helmets = [];
          var jackets = [];

           console.log("metaresult",metaresult);

           for (var key in metaresult) {

              console.log("key",key);

              helmets.push(metaresult[key]['h']);
              jackets.push(metaresult[key]['j']);

           }

           
           console.log("helmetids",helmets);

             var _self  = this;

           setTimeout(function () {
                      _self.helmetids = helmets.flat();
                     _self.jacketids = jackets.flat();

                    console.log("helmetids", _self.helmetids);
                    console.log("helmetids",_self.jacketids);
           
   
     _self.currentimagedata = imageidData;

         _self.meta = metaresult;

        _self.selectedtype = index;

        
          _self.getImagesignUrl(data);

         // console.log(_self.meta);
    
        }, 2000);
                    

       })
         .catch(err => {
                     console.log(err);
                    this.preloader = false
       })
    },
    getImagesignUrl(data){

      axios({
        url: '/get_sign_url_by_key',
        //  url: '/ai/812/get-ai-images-record',
        method: 'GET',
        params: {
           "key" : data.path,
            "exp" : "30 mins",
            "project_id" : this.currentProject.id
        }
      })
        .then(resp => {
          // console.log("resp", resp.data.success.url);

            this.dialog = true;
            this.img = resp.data.success.url;
        })
        .catch(err => {
          console.log(err);
          this.preloader = false
        })
    },
    checkAnnotaionImage(){

      var p = this.currentimagedata;
    
      var vvdata = [];

                //  var parray = [];
                    // var harray = [];
                    // var jarray = [];

                    var persons = [];

                    var  value = "persond";

                     console.log("p data",p);

                    var withhealmat = JSON.parse(p.persons_h);
                    var withjacket = JSON.parse(p.persons_j);
                    var unprotected = JSON.parse(p.person);
                  


                    if(this.selectedtype == 0){

                      let arr1 = [...withhealmat, ...withjacket, ...unprotected];
                      persons = [...new Set(arr1)]

                    }

                    if(this.selectedtype == 1){

                      // let arr2 = [...withhealmat, ...withjacket];
                      //  persons = [...new Set(arr2)]
                        persons = withhealmat.filter(value => withjacket.includes(value));

                       console.log("persons",persons);

                       value = "protected_parson";

                    }
                    if(this.selectedtype == 2){


                      
                      var protectedar = withhealmat.filter(value => withjacket.includes(value));

                    
                      let arr6 = [...withjacket, ...unprotected];
                      var withoout_healmet = [...new Set(arr6)];

                       console.log("withoout_healmet", withoout_healmet);

                      persons = withoout_healmet.filter(element => !protectedar.includes(element));


            
                      value = "with_helmet";

                      }

                      if(this.selectedtype == 3){

                       // let arr3 = [...withhealmat, ...withjacket];
                      var mprotectedp =  withhealmat.filter(value => withjacket.includes(value));

                        
                        value = "with_jacket";

                        let arr4 = [...withhealmat, ...unprotected];
                        var withoout_jacket = [...new Set(arr4)];

                        persons = withoout_jacket.filter(element => !mprotectedp.includes(element));

                      }
                      if(this.selectedtype == 4){

                        persons = unprotected;
                        value = "unprotected";

                      }

                      if(this.selectedtype == 'onlyhealmet'){

                        persons = this.helmetids;

                        value = "helmetc";
 
                      }
                       if(this.selectedtype == 'onlyjacket'){

                        persons = this.jacketids;

                        value = "jacketc";
                        
                       } 

    
        console.log("this.selectedtype",persons);

       // console.log("ddd-",this.meta);

       persons.forEach(a => {

         // console.log("a", a);

        if(a >= 0) {

         // console.log("mm",this.meta[a]);


           var item;

          var  x_min ;
          var  y_min;
          var  x_max ;
          var  y_max;

            console.log("Condition", (this.selectedtype == 'onlyhealmet' || this.selectedtype == 'onlyjacket'));
      
         if(this.selectedtype == 'onlyhealmet' || this.selectedtype == 'onlyjacket'){
           
          item = this.imagesppedata[a];

           console.log("item",item);

             x_min = item[0];
             y_min = item[1];
             x_max = item[2];
             y_max = item[3];
          }else{

           item = this.meta[a];
           x_min = item.person[0];
           y_min = item.person[1];
           x_max = item.person[2];
           y_max = item.person[3];

          }

           var x = x_min ;
           var y = y_min;
           var  w = (x_max - x_min);
           var  h = (y_max - y_min);

        // console.log("x,y,h,w",x,y,h,w);

    
        var r = (Math.random() + 1).toString(36).substring(7);
        vvdata.push({
          "@context": "http://www.w3.org/ns/anno.jsonld",
          type: "Annotation",
          body: [
            {
              type: "TextualBody",
              value: value,
              purpose: "tagging"
            }
          ],
          target: {
            source: this.img,
            selector: {
              type: "FragmentSelector",
              conformsTo: "http://www.w3.org/TR/media-frags/",
              value: "xywh=pixel:" + x + "," + y + "," + w + "," + h
            }
          },
          id: "#" + r
        });
       }
      });
    

      this.anno = new Annotorious({
        image: document.getElementById("plan3"),
        formatter: ShapeLabelsFormatter(),
        drawOnSingleClick: false,
        readOnly: true,
        widgets: [
          "COMMENT",
          {
            widget: "TAG",
            vocabulary: [
              "car",
              "bus",
              "person",
              "excavator",
              "telehandler",
              "loader",
              "dump_truck",
              "concrete_mixer",
              "pile_diver",
              "crane",
              "rollers",
              'helmet','jacket','belt','pile','pile_cap','pier_cage','pier','pier_cap','deck'
            ]
          }
        ]
      });

      //   var myJsonString = JSON.parse(vvdata);
      //  // console.log("anno", myJsonString);

      SelectorPack(this.anno);

      // console.log("vvdata", vvdata);
      var eded = this.anno.setAnnotations(vvdata);
       console.log("serwer", eded);
      //  setTimeout(() => {
      //      // var eded =  this.anno.loadAnnotations(vvdata);

      //
      //  }, 5000);
    },
    
    onValueChange: function (args) {


      // console.log("args", args);
      this.showChart = false;

      // var ss =  args.value.toLocaleDateString();

      if (args) {
        this.showChart = false;
        this.preloader = true
        if(this.barchartActive){
        this.getCurrentProjectData(args);
        this.getSevenDaysImagesData(args);
       }else{
        this.linechartDataMaker(args);
       }

      }

    },
    sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
   },
    async getSevenDaysImagesData(args) {
      this.preloader = true
      var start = '';
      var end = '';
      this.stepchartOptions.xaxis.categories = [];
      this.barchartdata = [];
      this.bardate = [];
     // this.barseries[0].data = [];
      this.barseries[0].data = [];
      this.barseries[1].data = [];
      this.barseries[2].data = [];
      this.barseries[3].data = [];
      // var peset = '1'
      var _self =  this;
      var startdisply = '';
      if (args) {

        console.log("first Loop ");

        const startDate = new Date(args.startDate);
        const endDate = new Date(args.endDate);

          // Calculate the difference in milliseconds between dt2 and dt1
          const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day

const diffInTime = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
const diffInDays = diffInTime / oneDay; // Convert milliseconds to days

console.log("diffInDays", diffInDays, endDate); // Output: 3

        // console.log("data formate", start,end);

      //  getTotalbyDate(start,end);

      var today; 

     

      for(var j = 0; j <= diffInDays; j++){


   console.log("j",j);

  const startDate = new Date(args.endDate);

 startDate.setDate(startDate.getDate() - j);
var add = String(startDate.getDate()).padStart(2, '0');
var amm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
var ayyyy = startDate.getFullYear();
 start = ayyyy+'-'+amm+'-'+add+'%2000%3A00';
 startdisply = ayyyy+'-'+amm+'-'+add;
 end = ayyyy+'-'+amm+'-'+add+'%2023%3A59';
/// 2024-05-15 2024-05-22

 console.log("startdate ppp", start, end);

            _self.getTotalbyDate(start,end,startdisply);

           if(j == diffInDays){
          
               setTimeout(() => {
                 console.log("this.barseries", _self.barseries);
                 console.log("this.bardate", _self.bardate);
                 _self.showChart = true;
                 _self.preloader = false;
                
              }, 5000);
             }

             await this.sleep(1000);  

}
      } else{
            //console.log("startdate,",start, end);
            console.log("Second Loop ");
            for(var i = 1; i <= 7; i++){
             today = new Date();
            today.setDate(today.getDate() - i);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
           // var start = mm+'-' +dd+'-'+yyyy;

             start = yyyy+'-'+mm+'-'+dd+'%2000%3A00';

             startdisply = yyyy+'-'+mm+'-'+dd;

             end = yyyy+'-'+mm+'-'+dd+'%2023%3A59';

            
                _self.getTotalbyDate(start, end, startdisply);
    
                
             if( i == 7){

              setTimeout(() => {
                 console.log("this.barseries", _self.barseries);
                 console.log("this.bardate", _self.bardate);
                 _self.showChart = true;

                 _self.preloader = false;
                
              }, 10000);
             }
             await this.sleep(1000);
            }
      }
    
    },
    getTotalbyDate(start,end,startdisply){

     


              // var total_person = [];
              // var total_person_h = [];
              // var total_person_j = [];
              // var total_person_u = [];
              // var total_p_j_h = [];

      let currentcam = this.currentcam.camera_id;

var dataurl = '/new-ai-ppe/' + currentcam + '/seven-days-record'; //'http://ai.opticvyu.com:3000/ppe-f-cw/'+currentcam+'/1'
 
      axios({
        url: dataurl,
        method: 'GET',
        params: {
          start: start,
          end: end
        }
      })
        .then(resp => {


      

         console.log("resp -->> ", resp.data.success);

            var r = resp.data.success;

            //this.barseries[0].data.push(r.total_person);
            this.barseries[1].data.push(r.total_person_wo_h);
            this.barseries[2].data.push(r.total_person_wo_j);
            this.barseries[3].data.push(r.total_person_u);
            this.barseries[0].data.push(r.total_p_j_h);

            //  this.bardate.push(start);

             console.log("x xasis date", startdisply);

              this.stepchartOptions.xaxis.categories.push(startdisply);

              // this.barseries = [{
              //   name: 'All Detectec Parsons',
              //   data: total_person
              // }, {
              //   name: 'Protecte Parsons',
              //   data: total_p_j_h
              // },
              // {
              //   name: 'Parson with Helmet',
              //   data: total_person_h
              // },
              // {
              //   name: 'Parson With Jacket',
              //   data: total_person_j
              // },
              // {
              //   name: 'Unprotected Parsons',
              //   data: total_person_u
              // }
              //]

             

         // this.barchartDataMaker(da);
         // this.linechartDataMaker(da);
         // this.preloader = false;

        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        })
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    clickLineHandler(event, chartContext, config){

      // console.log("Line si", config);
      // console.log("Line dpi", config.dataPointIndex);

      var data = this.linechartdata[config.dataPointIndex];

      this.openimage(data, 4); 

    },
    linechartDataMaker(args){


      var start = '';
      var end = '';
     
      if (args) {
        start = new Date(this.reportdate.startDate).toISOString().slice(0, 10);
        end = new Date(this.reportdate.endDate).toISOString().slice(0, 10);
      }

      let currentcam = this.currentcam.camera_id;

      var dataurl = 'ov-ai-f-person-h-j-camid-date-range/'+currentcam;

      axios({
        url: dataurl,
        method: 'GET',
        params: {
          start: start,
          end: end,
        }
      })
        .then(resp => {

          this.lineseries = [];

         // console.log("line chart data", resp.data.success);

           var data = resp.data.success;
           this.lineshowChart = false;

              var xaxis = [];
              var parsones = [];
              var protected_persone = [];
              var withhealmat = [];
              var withjacket = [];
              var unprotected = [];

                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;

                  this.linechartdata = data;

                  data.forEach((p,index )=> {

                    Xmas95 = new Date(p.image_date);
                   
                  var dated = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');

                   xaxis.push(dated);
                   parsones.push(p.total_person);
                   protected_persone.push(p.total_p_j_h);
                   withhealmat.push(p.total_person_wo_h);
                   withjacket.push(p.total_person_wo_j);
                   unprotected.push(p.total_person_u);

                   if(index == (data.length - 1)){

                    this.linechartOptions.xaxis.categories = xaxis;

                    //{
            //     name: 'Total Person Detected',
            //     data: parsones
            // }, {
            //     name: 'Protected Persons',
            //     data: protected_persone
            // },
            // {
            //     name: 'Person without Helmet',
            //     data: withhealmat
            // },
            // {
            //     name: 'Person without Jacket',
            //     data: withjacket
            // },

                   this.lineseries = [
                {
                name: 'Unprotected Persons',
                data: unprotected
            }
          ];

           var _self = this;

          setTimeout(() => {


               _self.preloader = false;
           
               _self.lineshowChart = true;
                
              }, 2000);
           
          
        }

     })
          // console.log("click");

        }).catch(err => {
          console.log(err);
          this.preloader = false;
        })

    },
    selectlinetype(){

      this.preloader = true;


     var linetype = this.selectdlineTypem;

          this.lineshowChart = false;

              var xaxis = [];
              var parsones = [];
              var protected_persone = [];
              var withhealmat = [];
              var withjacket = [];
              var unprotected = [];

                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;

                 var data = this.linechartdata;

                 data.forEach((p,index )=> {

                    Xmas95 = new Date(p.image_date);
                   
                  var dated = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');

                   xaxis.push(dated);
                   parsones.push(p.total_person);
                   protected_persone.push(p.total_p_j_h);
                   withhealmat.push(p.total_person_wo_h);
                   withjacket.push(p.total_person_wo_j);
                   unprotected.push(p.total_person_u);

                   if(index == (data.length - 1)){

                    this.linechartOptions.xaxis.categories = xaxis;

                    //{
            //     name: 'Total Person Detected',
            //     data: parsones
            // }, {
            //     name: 'Protected Persons',
            //     data: protected_persone
            // },
            // {
            //     name: 'Person without Helmet',
            //     data: withhealmat
            // },
            // {
            //     name: 'Person without Jacket',
            //     data: withjacket
            // },
           // imagesType : ['Unprotected Persons', 'Person Without Healmet', 'Person without Jacket', 'Protected Persons'],
           this.linechartOptions.colors = ['#1ab7ea', '#00e396', '#f8bb03', '#fc7e40', '#e23b3b'];
           if(linetype == 'Unprotected Persons'){
            this.linechartOptions.colors = ['#e23b3b'];
             this.lineseries = [
                {
                name: 'Unprotected Persons',
                data: unprotected
              }
             ]
            }

            if(linetype == 'Person Without Healmet'){
              this.linechartOptions.colors = ['#f8bb03'];
             this.lineseries = [
                {
                name: 'Person Without Healmet',
                data: withhealmat
              }
             ]
            }

            if(linetype == 'Person without Jacket'){
              this.linechartOptions.colors = ['#fc7e40'];
             this.lineseries = [
                {
                name: 'Person without Jacket',
                data: withjacket
              }
             ]
            }

            if(linetype == 'Protected Persons'){
              this.linechartOptions.colors = ['#00e396'];
             this.lineseries = [
                {
                name: 'Protected Persons',
                data: protected_persone
              }
             ]
            }

                   

           var _self = this;

          setTimeout(() => {


               _self.preloader = false;
           
               _self.lineshowChart = true;
                
              }, 500);
            }
        });  

    },
    linechartDataMakerold(data){

        console.log("click");

      this.lineshowChart = false;

                var parsones = [];
                var withhealmat = [];
                var withjacket = [];
                var unprotected = [];
                var protectedp = [];
               var xaxis = [];

                // var options = {
                //     timeStyle: "short",
                //     dateStyle: "short"
                //   };
                var options = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
                 var Xmas95;

                  this.linechartdata = data;

                  // var parray = [];
                  // var harray = [];
                  // var jarray = [];

                 data.forEach((p,index )=> {

                  var parsone_protected = this.parsone_protected(p);
       var parsone_with_healmet = this.parsone_with_healmet(p);
       var parsone_with_jacket = this.parsone_with_jacket(p);
       var parsone_unprotected = this.parsone_unprotected(p);

       data[index]['parsone_protected'] = parsone_protected;
       data[index]['parsone_with_healmet'] = parsone_with_healmet;
       data[index]['parsone_with_jacket'] = parsone_with_jacket;
       data[index]['parsone_unprotected'] = parsone_unprotected;

                 

                  Xmas95 = new Date(p.image_creation_time);
                   // console.log("p.image_creation_time",p.image_creation_time);
                  var dated = new Intl.DateTimeFormat('en-GB', options).format(Xmas95).split(', ');

                   // console.log("line dated",dated);

                   xaxis.push(dated);

                
                  if (this.isValidJSON(p.persons)) {

                    var parray = JSON.parse(p.persons);

                    var pcount = parray.length;

                    // console.log("line pcount", pcount);


                    unprotected.push(pcount);
                  } 

                  if (this.isValidJSON(p.persons_h)) {

                   var harray = JSON.parse(p.persons_h);
                    var hcount = harray.length;

                    // console.log("line hcount", hcount);

                    withhealmat.push(hcount);
                  }

                  if (this.isValidJSON(p.persons_j)) {
                   var jarray = JSON.parse(p.persons_j);

                    var jcount = jarray.length;

                    // console.log("line jcount", jcount);

                    withjacket.push(jcount);
                  }

                  let arr1 = [...harray, ...jarray, ...parray];
                  var mparsones = [...new Set(arr1)]

                   //console.log("line mparsones", mparsones);

                  var countallparsons = mparsones.length;

                  // console.log("line countallparsons", countallparsons);

                    
                  

                  parsones.push(countallparsons);

                  // let parsonessum = 0;

                  //   //  console.log("parsone", parsones);

                  //   // iterate over each item in the array
                  //   for (let i = 0; i < parsones.length; i++) {
                  //     console.log("parsonessum ",i," ==> ",parsones[i]);
                  //   parsonessum += parsones[i];
                  //   console.log(parsonessum);
                    
                  //   }
                     

                  // let arr2 = [...harray, ...jarray];
                  // var mprotectedp = [...new Set(arr2)]

                  var mprotectedp = jarray.filter(element => harray.includes(element));

                  // console.log("line mprotectedp", mprotectedp);
                  // // console.log("mergedArr",parsones,protectedp);

                  var countprotected = mprotectedp.length;

                  // console.log("line countprotected", countprotected);

                  protectedp.push(countprotected);

                 })

                 this.lineseries = [{
                name: 'Total Person Detected',
                data: parsones
            }, {
                name: 'Protected Persons',
                data: protectedp
            },
            {
                name: 'Person with Helmet',
                data: withhealmat
            },
            {
                name: 'Person with Jacket',
                data: withjacket
            },
            {
                name: 'Unprotected Persons',
                data: unprotected
            }
          ];
          this.linechartOptions.xaxis.categories = xaxis;

          setTimeout(() => {
            this.lineshowChart = true;
       
         }, 5000);   
      
    },
    barchartDataMaker(data) {

      const uniqueDates = {};


      // var a = data.length;

      var finaldata = [];
      //const filteredData = [];

      // Iterate through each object in the JSON data
      data.forEach((obj, index) => {
        const date = obj.image_creation_time.split('T')[0]; // Extract date from 'image_creation_time'

        // Check if the date is not already added
        if (!uniqueDates[date]) {
          // Add the date to the uniqueDates object
          uniqueDates[date] = true;
          finaldata[date] = [];
          // Add the object to the filtered data
          // filteredData.push(obj);
        }

        //  // console.log("item s",)
        if (index == (data.length - 1)) {

          data.forEach((obj, b) => {
            //// console.log("obj",obj);
            var date = obj.image_creation_time.split('T')[0];

            //// console.log("uniqueDates.date",uniqueDates[date]);
            if (uniqueDates[date]) {
              // console.log(uniqueDates[date]);
              finaldata[date].push(obj);
            }
            // // console.log("finaldata",finaldata);

            //// console.log("finaldata ", b,data.length);

            if (b == (data.length - 1)) {

              // // console.log("Loop execute");

              // // console.log("finaldata",uniqueDates);

              this.datawithdate = finaldata;

              //  var bardata = 

              var parsoneslast = [];
              var withhealmatlast = [];
              var withjacketlast = [];
              var unprotectedlast = [];
              var protectedplast = [];

              var bardate = [];

              for (var key in uniqueDates) {

                var parsones = [];
                var withhealmat = [];
                var withjacket = [];
                var unprotected = [];
                var protectedp = [];


                // console.log(" -===", finaldata[key]);

                finaldata[key].forEach(p => {

                  // // console.log();
                  //// console.log("--",this.isValidJSON(p.persons));
                  var parray = [];
                  var harray = [];
                  var jarray = [];
                  //var pparray = [];
                  if (this.isValidJSON(p.persons)) {

                    parray = JSON.parse(p.persons);

                    var pcount = parray.length;

                    // console.log("pcount", pcount);

                    if(pcount > 0){
                      unprotected.push(pcount);
                        }
                    
                  } 

                  if (this.isValidJSON(p.persons_h)) {

                    harray = JSON.parse(p.persons_h);
                    var hcount = harray.length;

                    // console.log("hcount", hcount);

                       if(hcount > 0){
                          withhealmat.push(hcount);
                        }

                    
                  } 

                  if (this.isValidJSON(p.persons_j)) {
                    jarray = JSON.parse(p.persons_j);

                    var jcount = jarray.length;

                    // console.log("jcount", jcount);
                               if(jcount > 0){
                                 withjacket.push(jcount);
                               }
                   
                  } 

                  let arr1 = [...jarray, ...harray, ...parray];
                  var mparsones = [...new Set(arr1)]

                 //  console.log("mparsones", mparsones);

                  var countallparsons = mparsones.length;

                  // console.log("countallparsons", countallparsons);

                  parsones.push(countallparsons);

                  //console.log("jarray", jarray);
                 // console.log("harray", harray);

                  // let arr2 = [...jarray, ...harray];
                  // var mprotectedp = [...new Set(arr2)];

                  var mprotectedp = jarray.filter(element => harray.includes(element));

                  // console.log("mprotectedp", mprotectedp);
                  // // console.log("mergedArr",parsones,protectedp);

                  var countprotected = mprotectedp.length;

                  //console.log("countprotected", countprotected);

                  protectedp.push(countprotected);

                })

                let unprotectedsum = 0;

                // iterate over each item in the array
                for (let i = 0; i < unprotected.length; i++) {
                  unprotectedsum += unprotected[i];
                }

                let withhealmatsum = 0;

                // iterate over each item in the array
                for (let i = 0; i < withhealmat.length; i++) {
                  withhealmatsum += withhealmat[i];
                }


                let withjacketsum = 0;

                // iterate over each item in the array
                for (let i = 0; i < withjacket.length; i++) {
                  withjacketsum += withjacket[i];
                }

                let protectedpsum = 0;

                // iterate over each item in the array
                for (let i = 0; i < protectedp.length; i++) {
                  protectedpsum += protectedp[i];
                }


                let parsonessum = 0;

                //  console.log("parsone", parsones);

                // iterate over each item in the array
                for (let i = 0; i < parsones.length; i++) {
                  parsonessum += parsones[i];
                }

                //  // console.log("parsones-.",key, unprotected,withhealmat,withjacket,protectedp,parsones);

                // console.log("parsones-.", key, unprotectedsum, withhealmatsum, withjacketsum, protectedpsum, parsonessum);

                // var  parsoneslast = [];
                //   var  withhealmatlast = [];
                //   var  withjacketlast = [];
                //   var  unprotectedlast = [];
                //  var  protectedplast = [];

                parsoneslast.push(parsonessum);
                withhealmatlast.push(withhealmatsum);
                withjacketlast.push(withjacketsum);
                unprotectedlast.push(unprotectedsum);
                protectedplast.push(protectedpsum);

                bardate.push(key);


              }

              this.stepchartOptions.xaxis.categories = bardate;

              this.barseries = [{
                name: 'Protected Persons',
                data: protectedplast
              },
              {
                name: 'Person without Helmet',
                data: withhealmatlast
              },
              {
                name: 'Person Without Jacket',
                data: withjacketlast
              },
              {
                name: 'Unprotected Persons',
                data: unprotectedlast
              }
              ]

              this.showChart = true;
            }
          })
        }
      });

      //// console.log("filteredData",filteredData);
      // console.log("uniqueDates", uniqueDates);
      // console.log("uniqueDates", finaldata);


    },
    getCurrentProjectData(args) { // Moduletype variable store value of marial, interior or constructuion cameras.

      // args.value[0].toLocaleDateString(), args.value[1].toLocaleDateString()

      var start = '';
      var end = '';
      let currentcam = this.currentcam.camera_id;

      var dataurl = '/new-ai-ppe/' + currentcam + '/seven-days-record'; //'http://ai.opticvyu.com:3000/ppe-f-cw/'+currentcam+'/1'

      if (args) {
        start = new Date(this.reportdate.startDate).toISOString().slice(0, 10);
        end = new Date(this.reportdate.endDate).toISOString().slice(0, 10);
      }

      // var commondate = [];
      axios({
        url: dataurl,
        method: 'GET',
        params: {
          start: start,
          end: end,
        }
      })
        .then(resp => {

           console.log("Dial response", resp.data.success);
          this.prdata = resp.data.success;
          var a = resp.data.success;

          this.chartOptions.labels = ['Total Person Detected  (' + a.total_person + ')', 'Protected Persons (' + a.total_p_j_h + ')', 'Person without Helmet (' + a.total_person_wo_h + ')', 'Person without Jacket (' + a.total_person_wo_j + ')', 'Unprotected Persons (' + a.total_person_u + ')'];

          var total_person = ((a.total_person / a.total_person) * 100).toFixed(2);

          var total_p_j_h = ((a.total_p_j_h / a.total_person) * 100).toFixed(2);

          var total_person_wo_h = ((a.total_person_wo_h / a.total_person) * 100).toFixed(2);

          var total_person_wo_j = ((a.total_person_wo_j / a.total_person) * 100).toFixed(2);

          var total_person_u = ((a.total_person_u / a.total_person) * 100).toFixed(2);


          this.series =
            [
              total_person,
              total_p_j_h,
              total_person_wo_h,
              total_person_wo_j,
              total_person_u
            ];

          // this.paiseries = [ total_persons_with_required_equipment, total_persons_without_required_equipment];
          
         
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        })
    },
    clickHandlerpreset(event, chartContext, config) {

      console.log("preset", this.presets);
      

      this.preloader = true;

      this.presertbarseries = [{
name: 'Protected Persons',
data: []
},
{
name: 'Person without Helmet',
data: []
},
{
name: 'Person Without Jacket',
data: []
},
{
name: 'Unprotected Persons',
data: []
}
];

      console.log("si", config.seriesIndex);
      console.log("dpi", config.dataPointIndex);

     var date = this.stepchartOptions.xaxis.categories[config.dataPointIndex];


      this.presetDate = date;

       console.log("config.seriesIndex", date);
     // this.preloader = true;
      this.presetshowChart = false;

      let currentcam = this.currentcam.camera_id;


 var start = date+'%2000%3A00';

  var end = date+'%2023%3A59';

      var dataurl = '/ov-ai-f-total-person-h-j-camid-date-range-preset/'+currentcam;

      axios({
        url: dataurl,
        method: 'GET',
        params: {
          start: start,
          end: end,
        }
      })
        .then(resp => {
           console.log("Dial response", resp.data.success);

           var data = resp.data.success;

           if(data.length <= 1){

              this.preloader = false;
              this.signlepresetclickHandler();
             
           }else{

           data.forEach((a,index )=> {

            this.presetstepchartOptions.xaxis.categories.push(a.preset_id);

            this.presertbarseries[0].data.push(a.total_p_j_h);
            this.presertbarseries[1].data.push(a.total_person_wo_h);
            this.presertbarseries[2].data.push(a.total_person_wo_j);
            this.presertbarseries[3].data.push(a.total_person_u);
            
           //this.presetstepchartOptions.xaxis.categories = unique;

            if(index == (data.length - 1)){
              this.presetshowChart = true;
              this.preloader = false;
            }
          });
        }

        }).catch(err => {
          console.log(err);
          this.preloader = false;
        })

    },
    clickHandler(event, chartContext, config) {

      this.preloader = true;

      this.presetshowChart = false;

      this.presetstepchartOptions.xaxis.categories = [];

      this.presertbarseries = [];

      var presetidsarray = [];
      // console.log("si", config.seriesIndex);
      // console.log("dpi", config.dataPointIndex);

      var datas = this.stepchartOptions.xaxis.categories[config.dataPointIndex];

      

      this.datawithdate[datas].forEach((a, i) => {

        if (a.preset_id) {

          presetidsarray.push(parseInt(a.preset_id));

        }

        console.log("datas", presetidsarray);

        if (i == this.datawithdate[datas].length - 1) {

          // var myArray = ['a', 1, 'a', 2, '1'];

          let unique = [...new Set(presetidsarray)];

          // console.log("unique", unique); // unique is ['a', 1, 2, '1']

          if (unique.length == 0) {
            this.preloader = false;
          }

          this.presetids = unique;

          this.presetbars = [];


          unique.forEach((u, ind) => {
            this.presetbars[u] = [];

            if (ind == unique.length - 1) {

              var parsoneslast = [];
              var withhealmatlast = [];
              var withjacketlast = [];
              var unprotectedlast = [];
              var protectedplast = [];

              this.datawithdate[datas].forEach((a, df) => {

                if (a.preset_id) {
                  this.presetbars[a.preset_id].push(a);
                }

                if (df == this.datawithdate[datas].length - 1) {
                 // // console.log("Loop Done", this.presetbars);

                  //this.preloader = false;

                  this.presets = unique;

                  var parsones = [];
                  var withhealmat = [];
                  var withjacket = [];
                  var unprotected = [];
                  var protectedp = [];

                  this.presetbars.forEach(b => {

                 //   // console.log("preset bardata", b, bi);




                   

                    b.forEach(p => {

                      //  var parray = [];
                      //  var harray = [];
                      //  var jarray = [];

                       console.log("p",p);

                      if (this.isValidJSON(p.persons)) {

                       var parray = JSON.parse(p.persons);

                        var pcount = parray.length;

                       console.log("pcount", pcount);


                        unprotected.push(pcount);
                      } 
                      if (this.isValidJSON(p.persons_h)) {

                        var harray = JSON.parse(p.persons_h);
                        var hcount = harray.length;

                    console.log("hcount", hcount);

                        withhealmat.push(hcount);
                      } 

                      if (this.isValidJSON(p.persons_j)) {
                       var jarray = JSON.parse(p.persons_j);

                        var jcount = jarray.length;

                       console.log("jcount", jcount);

                        withjacket.push(jcount);
                      } 

                      let arr1 = [...parray, ...jarray, ...harray];
                      var mparsones = [...new Set(arr1)]

                  //    // console.log("mparsones", mparsones);

                      var countallparsons = mparsones.length;

                    console.log("countallparsons", countallparsons);

                      parsones.push(countallparsons);

                      console.log("jarray", jarray, "harray", harray);

                      var mprotectedp = jarray.filter(element => harray.includes(element));

                  console.log("preset mprotectedp", mprotectedp);
                      // // console.log("mergedArr",parsones,protectedp);

                      var countprotected = mprotectedp.length;;

                    //  // console.log("countprotected", countprotected);

                      protectedp.push(countprotected);

                    })

                    let unprotectedsum = 0;

                    // iterate over each item in the array
                    for (let i = 0; i < unprotected.length; i++) {
                      unprotectedsum += parseInt(unprotected[i]);
                    }

                    let withhealmatsum = 0;

                    // iterate over each item in the array
                    for (let i = 0; i < withhealmat.length; i++) {
                      withhealmatsum += parseInt(withhealmat[i]);
                    }


                    let withjacketsum = 0;

                    // iterate over each item in the array
                    for (let i = 0; i < withjacket.length; i++) {
                      withjacketsum += parseInt(withjacket[i]);
                    }

                    let protectedpsum = 0;

                    // iterate over each item in the array
                    for (let i = 0; i < protectedp.length; i++) {

                       // console.log(parseInt(protectedp[i]);

                      protectedpsum += parseInt(protectedp[i]);

                  //    // console.log("dds", protectedpsum);
                    }


                    let parsonessum = 0;

                    // iterate over each item in the array
                    for (let i = 0; i < parsones.length; i++) {
                      parsonessum += parseInt(parsones[i]);
                    }

                    protectedplast.push(protectedpsum);
                    parsoneslast.push(parsonessum);
                    withhealmatlast.push(withhealmatsum);
                    withjacketlast.push(withjacketsum);
                    unprotectedlast.push(unprotectedsum);
                   



                  })


                  this.presetstepchartOptions.xaxis.categories = unique;

                  this.presertbarseries= [{
                  name: 'Protecte Parsons',
                  data: protectedplast
                  },
                  {
                  name: 'Parson with Helmet',
                  data: withhealmatlast
                  },
                  {
                  name: 'Parson With Jacket',
                  data: withjacketlast
                  },
                  {
                  name: 'Unprotected Parsons',
                  data: unprotectedlast
                  }
                  ]
                   var _self = this;
                    setTimeout(function () {

                      _self.presetshowChart = true;
                      _self.preloader = false;
         
                   }, 500);

                } else {
                  this.preloader = false;
                }

              })
            }
          })
        }
      });
    },
    signlepresetclickHandler(event, chartContext, config){

this.preloader = true;

var presetid = 1;

console.log("presetid",presetid);

let currentcam = this.currentcam.camera_id;


var start = this.presetDate+'%2000%3A00';

var end = this.presetDate+'%2023%3A59';


var dataurl = '/ov-ai-f-total-person-h-j-camid-date-range-with-presset/'+currentcam;

axios({
  url: dataurl,
  method: 'GET',
  params: {
    start: start,
    end: end,
    preset : presetid
  }
})
.then(resp => {
   console.log("preset images", resp.data.success);
 
   // preImagesdata[index]['thumb'] = resp.data.success.url;

  var preImagesdata = resp.data.success;
            preImagesdata.forEach((p, index )=> {


    var t = p.path.split("/20");

    var thumbname = t[0]+"/thumb.20"+t[1];

    axios({
  url: '/get_sign_url_by_key',
  //  url: '/ai/812/get-ai-images-record',
  method: 'GET',
  params: {
     "key" : thumbname,
      "exp" : "30 mins",
      "project_id" : this.currentProject.id
  }
})
  .then(resp => {
      
    preImagesdata[index]['thumb'] = resp.data.success.url;

  //  console.log("preImagesdata",preImagesdata)
       
  })
  .catch(err => {
    console.log(err);
   
     })

     
   if((preImagesdata.length - 1) == index ){

     console.log(("this cundition", preImagesdata.length - 1) == index);

     var _self = this;

    var time = preImagesdata.length * 200;

    setTimeout(function () {

      console.log("preImagesdata",preImagesdata);

      _self.selectpresetImage = preImagesdata;

      _self.filterimages =  _self.selectpresetImage.filter(function(item){
        return item.total_person_u > 0;         
      });
      _self.preloader = false
      window.scrollTo(0, 1200);
      
    }, time);

       
   }

 });

 this.selectedtype = config.seriesIndex;

    })
       
 
  .catch(err => {
    console.log(err);
   
  })



},
    presetclickHandler(event, chartContext, config){

      this.preloader = true;

      var presetIndex = config.dataPointIndex;

      console.log("presepresetIndextid",presetIndex);

     // var preImagesdata = this.presetbars[presetIndex];

     var presetid = this.presetstepchartOptions.xaxis.categories[presetIndex];

      console.log("presetid",presetid);

    let currentcam = this.currentcam.camera_id;


    var start = this.presetDate+'%2000%3A00';

    var end = this.presetDate+'%2023%3A59';


    var dataurl = '/ov-ai-f-total-person-h-j-camid-date-range-with-presset/'+currentcam;

      axios({
        url: dataurl,
        method: 'GET',
        params: {
          start: start,
          end: end,
          preset : presetid
        }
      })
      .then(resp => {
         console.log("preset images", resp.data.success);
       
         // preImagesdata[index]['thumb'] = resp.data.success.url;

        var preImagesdata = resp.data.success;
                  preImagesdata.forEach((p, index )=> {


          var t = p.path.split("/20");

          var thumbname = t[0]+"/thumb.20"+t[1];

          axios({
        url: '/get_sign_url_by_key',
        //  url: '/ai/812/get-ai-images-record',
        method: 'GET',
        params: {
           "key" : thumbname,
            "exp" : "30 mins",
            "project_id" : this.currentProject.id
        }
      })
        .then(resp => {
            
          preImagesdata[index]['thumb'] = resp.data.success.url;

        //  console.log("preImagesdata",preImagesdata)
             
        })
        .catch(err => {
          console.log(err);
         
           })

           
         if((preImagesdata.length - 1) == index ){

           console.log(("this cundition", preImagesdata.length - 1) == index);

           var _self = this;

          var time = preImagesdata.length * 200;

          setTimeout(function () {

            console.log("preImagesdata",preImagesdata);

            _self.selectpresetImage = preImagesdata;

            _self.filterimages =  _self.selectpresetImage.filter(function(item){
              return item.total_person_u > 0;         
            });
            _self.preloader = false
            window.scrollTo(0, 1200);
            
          }, time);

             
         }

       });

       this.selectedtype = config.seriesIndex;

          })
             
       
        .catch(err => {
          console.log(err);
         
        })



    },
     presetclickHandlerold(event, chartContext, config){
      // console.log("si", config.seriesIndex);
      // console.log("dpi", config.dataPointIndex);

       this.preloader = true;

       this.selectpresetImage = [];

     var presetIndex = this.presetids[config.dataPointIndex];

      var preImagesdata = this.presetbars[presetIndex];

       // console.log("preImagesdata",preImagesdata);
       preImagesdata.forEach((p, index )=> {


          var t = p.path.split("/20");

          var thumbname = t[0]+"/thumb.20"+t[1];
          // console.log("thumbname",thumbname);
 
       
       var parsone_protected = this.parsone_protected(p);
       var parsone_with_healmet = this.parsone_with_healmet(p);
       var parsone_with_jacket = this.parsone_with_jacket(p);
       var parsone_unprotected = this.parsone_unprotected(p);

       preImagesdata[index]['parsone_protected'] = parsone_protected;
       preImagesdata[index]['parsone_with_healmet'] = parsone_with_healmet;
       preImagesdata[index]['parsone_with_jacket'] = parsone_with_jacket;
       preImagesdata[index]['parsone_unprotected'] = parsone_unprotected;


       axios({
        url: '/get_sign_url_by_key',
        //  url: '/ai/812/get-ai-images-record',
        method: 'GET',
        params: {
           "key" : thumbname,
            "exp" : "30 mins",
            "project_id" : this.currentProject.id
        }
      })
        .then(resp => {
            
          preImagesdata[index]['thumb'] = resp.data.success.url;
             
        })
        .catch(err => {
          console.log(err);
         
        })

         console.log((preImagesdata.length - 1) == index);
         if((preImagesdata.length - 1) == index ){

           var _self = this;

           var time = preImagesdata.length * 200;

          setTimeout(function () {

            console.log("preImagesdata",preImagesdata);

            _self.selectpresetImage = preImagesdata;

            _self.filterimages =  _self.selectpresetImage.filter(function(item){
              return item.parsone_unprotected > 0;         
            });
            _self.preloader = false
            window.scrollTo(0, 1200);
            
          }, time);

             
         }

       });

       this.selectedtype = config.seriesIndex;

    
    },
    loaded() {
            var v = this;
            setTimeout(function () {
                v.preloader = false; 
                v.checkAnnotaionImage();
            }, 1000);
        },
        parsone_count_all(i){

                  var withhealmat = JSON.parse(i.persons_h)
                  var withjacket = JSON.parse(i.persons_j);
                  var unprotected = JSON.parse(i.persons);

           let arr1 = [...withhealmat, ...withjacket, ...unprotected];
         var  persons = [...new Set(arr1)];

            
           return persons.length; 
        },

        parsone_protected(i){

    var withhealmat =  JSON.parse(i.persons_h);
    var withjacket =  JSON.parse(i.persons_j);

    var persons = withhealmat.filter(element => withjacket.includes(element));

    return persons.length; 
},
      parsone_with_healmet(i){
        var withhealmat = JSON.parse(i.persons_h);
      
        return withhealmat.length; 
      },
      parsone_with_jacket(i){
        var withjacket = JSON.parse(i.persons_j);
      
        return withjacket.length; 
      },
      parsone_unprotected(i){

       // console.log("click data", i);
        var unprotected = JSON.parse(i.persons);
      
        return unprotected.length; 
      },
      setrouter: function (url) {
          if (this.$route.path !== url) {
            this.$router.push(url);
          }
      },
      reportAlertImages(){

        this.preloader = true;
        this.showImage = false;

        var projectid  = this.currentProject.id;
        var camid = this.currentcam.camera_id; 

        this.linechartActive = false;
        this.barchartActive = false;
        this.alertActive = true;
        this.getInstantReport();

        
        
        axios({
        url: 'ppe-ai-alert-images',
        method: 'GET',
        params: {
          camid: camid,
          projectid: projectid,
        }
      })
        .then(resp => {

           console.log("resp",resp);

          var alertimages = resp.data.success;

          alertimages.forEach((p, index )=> {


                var t = p.imagepath.split("/20");

                var thumbname = t[0]+"/thumb.20"+t[1];
                // console.log("thumbname",thumbname);

                axios({
                url: '/get_sign_url_by_key',
                //  url: '/ai/812/get-ai-images-record',
                method: 'GET',
                params: {
                "key" : thumbname,
                  "exp" : "30 mins",
                  "project_id" : projectid
                }
                })
                .then(resp => {
                  
                  alertimages[index]['thumb'] = resp.data.success.url;
                  
                })
                .catch(err => {
                console.log(err);

                })

               // console.log((alertimages.length - 1) == index);
                if((alertimages.length - 1) == index ){

                var _self = this;

                 this.alertimagesArray = alertimages;

                var time = alertimages.length * 100;

                setTimeout(function () {
              
                   _self.showImage =  true;
                  _self.preloader = false;
                  
                }, time);
                
               }

                });

        }).catch(err => {
        
          console.log(err);
         
        })

      },
      openimageAlert(data, index) {

         console.log("alert image data", data);

this.preloader = false;

let currentcam = this.currentcam.camera_id;
axios({
  url: 'ov-ai-with-id/'+currentcam,
  //  url: '/ai/812/get-ai-images-record',
  method: 'GET',
  params: {
     "recordid" : data.imageid,
  }
})
  .then(resp => {
    console.log("resp id", resp.data.success);

      var imageidData = resp.data.success;

      this.imagesppedata = JSON.parse(resp.data.success.ppe_result);

      console.log(this.imagesppedata);

     var string = imageidData.metadata;

     console.log("meta",string);

     var jsonString = string.replace(/'/g, '"');

// Step 2: Add double quotes around keys
jsonString = jsonString.replace(/(\w+)(?=\s*:)/g, '"$1"');

try {
// Parse the JSON string
var metaresult = JSON.parse(jsonString);
console.log(metaresult);
} catch (error) {
console.error('Invalid JSON string:', error);
}

  //     var metaJsonStr = JSON.stringify(meta);

  // // Parse the JSON string back to an object
  // var metaresult = JSON.parse(metaJsonStr);

  // fparsone_withoout_healmet : 0,
  //   fparsone_protected : 0,
  //   fparsone_without_jacket :0,
  //   fparsone_unprotected

  var p = imageidData;
  var harray = [];
  var jarray = [];
  var unprotected = [];

      if (this.isValidJSON(p.person)) {

        unprotected = JSON.parse(p.person);

        console.log("unprotected",unprotected);

      var pcount = unprotected.length;

      this.fparsone_unprotected = pcount

      } 

      if (this.isValidJSON(p.persons_h)) {

       harray = JSON.parse(p.persons_h);

       console.log("harray",harray);
    
      }

      if (this.isValidJSON(p.persons_j)) {
         jarray = JSON.parse(p.persons_j);
         console.log("jarray",jarray);
      }

      var mprotectedp = jarray.filter(element => harray.includes(element));

console.log("line mprotectedp", mprotectedp);
// // console.log("mergedArr",parsones,protectedp);

this.fparsone_protected = mprotectedp.length;

      let arr1 = [...jarray, ...unprotected];
      var withoout_healmet = [...new Set(arr1)];

         withoout_healmet = withoout_healmet.filter(element => !mprotectedp.includes(element));

      console.log("withoout_healmet",withoout_healmet);

      this.fparsone_withoout_healmet = withoout_healmet.length;


      let arr2 = [...harray, ...unprotected];
      var without_jacket = [...new Set(arr2)];

      without_jacket = without_jacket.filter(element => !mprotectedp.includes(element));

      console.log("without_jacket",without_jacket);

      this.fparsone_without_jacket = without_jacket.length;
    var helmets = [];
    var jackets = [];

     console.log("metaresult",metaresult);

     for (var key in metaresult) {

        console.log("key",key);

        helmets.push(metaresult[key]['h']);
        jackets.push(metaresult[key]['j']);

     }

     
     console.log("helmetids",helmets);

       var _self  = this;

     setTimeout(function () {
                _self.helmetids = helmets.flat();
               _self.jacketids = jackets.flat();

              console.log("helmetids", _self.helmetids);
              console.log("helmetids",_self.jacketids);
     

_self.currentimagedata = imageidData;

   _self.meta = metaresult;

  _self.selectedtype = index;

  
    _self.getImagesignUrlalert(data);

   // console.log(_self.meta);

  }, 2000);
              

 })
   .catch(err => {
               console.log(err);
              this.preloader = false
 })
},
getImagesignUrlalert(data){

axios({
  url: '/get_sign_url_by_key',
  //  url: '/ai/812/get-ai-images-record',
  method: 'GET',
  params: {
     "key" : data.imagepath,
      "exp" : "30 mins",
      "project_id" : this.currentProject.id
  }
})
  .then(resp => {
    // console.log("resp", resp.data.success.url);

      this.dialog = true;
      this.img = resp.data.success.url;
  })
  .catch(err => {
    console.log(err);
    this.preloader = false
  })
},
getInstantReport(){
                
                this.cid = localStorage.getItem('cid');
             var params = {
                  cam_id : this.cid,
                  project_id : this.currentProject.id
               };

               axios({ url: "/get/ai/ai-ppe-conditional-instant-report", params : params, method: 'get' })
          .then(resp => {
                      console.log("conditional-instant-report",resp.data);

                //         conditions : this.applicants,
                //  report_type : this.recuring_selecttype,
                //  emails : this.recuring_emails,
                //  camera_id : this.currentcam.camera_id,
                //  project_id : this.currentProject.id,
                //  night_images : this.instant_applicant_night_images

            
                  if(resp.data){
                     
                      this.instant_applicants = JSON.parse(resp.data.conditions);

                      if(this.instant_applicants.length == 0){

                        this.instant_applicants = [{  
                       object:'',
                       condition: '',
                        qty:''
                     }]
                      }else{

                        var object = this.instant_applicants[0].object;
                        var condition = this.instant_applicants[0].condition;
                        var qty = this.instant_applicants[0].qty;


                        if(condition == 'gtr'){

                          condition = ' greater than or equal to ';

                        }else{

                           condition = ' less than or equal to ' 
                        }


                        this.titleline =  object + ' is' +condition + qty;

                        //console.log("t",this.titleline); 

                      }
                     
                      
                     // this.preloader = false;
                    }

          })
          .catch(err => {
            console.log(err);
             this.preloader = false
          })
         },
  }
};
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

.zzoomerfull {
  width: 100%;
  height: 100VH;
  border: solid 1px silver;
  position: fixed;
  top: 0;
  left: 0;
  background: #515b61b0 !IMPORTANT;
  ;
  DISPLAY: BLOCK;
}

.closebuttons {
  position: fixed !important;
  right: 10px;
  top: 50px;
  z-index: 999999;
  color: #fff !important;
  cursor: pointer;
}

.aiinfobox {
  position: fixed !important;
  right: 40px;
  top: 80px;
  z-index: 10;
  color: #000 !important;
  background: #ffffff9c;
  padding: 15px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  border-radius: 5px;
  transition: 0.5s;
}

.aiinfobox:hover {
  transition: 0.5s;
  color: #000 !important;
  background: #ffffff;
  box-shadow: 2px 2px 2px #000;
}

.split360 {
  display: block;
  overflow: auto;
  height: 50vh;
  /*width: 48%;
      height: 50%;
      background: #000000ab;
    
      left: 20px;
      z-index: 1; */
}

span.camname {
  position: absolute;
  left: 20px;
  background: #02020236;
  padding: 3px 7px;
  border-radius: 3px;
  top: 20px;
  z-index: 2;
  color: #fff;
}

.camimage {
  position: relative;
}

.camimage .v-responsive__content {
  cursor: pointer;
}

.menufooter.ai {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #00000075;
  padding: 6px 0;
  font-weight: bold;
  color: #fff;
}

h2.hetitle {
  background: #f8bb03;
  padding: 0px 15px;
  text-align: center;
  font-size: 17px;
  box-shadow: -2px 7px 16px 0px #0000004a;
}

#chartbar {
  cursor: pointer;
}
.menufooter.ai.mi {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: calc(100% - 25px);
    background: #00000075;
    padding: 6px 0;
    font-weight: bold;
    color: #fff;
}

.menufooter.mifull {
    position: fixed;
    bottom: 0;
    text-align: center;
    width: calc(100%);
    background: #0000009e;
    padding: 6px 0;
    font-weight: bold;
    color: #fff;
    z-index: 99;
}
.imageslist {
    position: relative;
    width: 100%;
    height: 50px;
}

.filerdropdown {
    width: 250px;
    display: block;
    position: relative;
    float: right;
    right: 0;
    top: -25px;
}
.personall .a9s-inner {
    stroke-width: 1;
    stroke: #000000;
  }

  .protected_parson .a9s-inner {
    stroke-width: 1;
    stroke: #71ff33;
  }

  .with_helmet .a9s-inner {
    stroke-width: 1;
    stroke: #ffc400;
  }

  .with_jacket .a9s-inner {
    stroke-width: 1;
    stroke: #ff6d33;
  }

  .unprotected .a9s-inner {
    stroke-width: 1;
    stroke: #e12121;
  }

  .helmetc .a9s-inner {
    stroke-width: 1;
    stroke: #ffffff;
  }
  .jacketc .a9s-inner {
    stroke-width: 1;
    stroke: #e95a01;
  }
  h3.hetitle {
    text-align: center;
    background: #121212;
    color : #ffff;
  padding: 0px 15px;
  text-align: center;
  font-size: 17px;
  box-shadow: -2px 7px 16px 0px #0000004a;
  cursor: pointer;
}
h3.hetitle.active {
    background: #e7b921;
    color:#000;
}
h4.chart_title {
    text-align: center;
}
.filerdropdownforline.col.col-12 {
    width: 250px;
    position: fixed;
    top: 92px;
}
.datepicker.cvc {
    height: 50px;
}

</style>
<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
        <v-card class="vhfix">
          <v-card-title> {{ lang.SelectProject }}</v-card-title>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :class="item.id == active_proid ? 'myactiaveitem' : ''"
                active-class="actiaveitem"
                color="amber accent-3"
                @click="getCamerasbyProject(item)"
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.project_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="9" md="6" lg="6" xl="6">
        <v-card class="minheights">
          <v-card dark flat>
            <v-card-title class="pa-2 amber accent-3">
              <h3 class="title font-weight-light text-center grow">
                {{ CurrentPname }} - {{ lang.ProjectInfo }}
              </h3>
            </v-card-title>
          </v-card>
          <v-snackbar
            v-model="snackbar"
            center
            top
            timeout="3000"
            type="success"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    :counter="10"
                    :label="lang.UploadSpeed"
                    v-model="uploadspeed"
                    :rules="[v => !!v || lang.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-switch
                    v-model="ovppt"
                    :label="lang.OpticVyuPresentation"
                  ></v-switch>
                </v-col>
                <!--  <v-col cols="12" sm="2" md="2">
                  <v-switch v-model="prostatus" label="Status" :rules="[v => !!v || 'Item is required']"
                    required></v-switch>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="lang.DateofInstallation"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || lang.required]"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        {{ lang.Cancel }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(date)"
                      >
                        {{ lang.Ok }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="formheading">{{
                  lang.Name
                }}</v-col>
                <v-col cols="12" sm="3" md="3" class="formheading">{{
                  lang.Designation
                }}</v-col>
                <v-col cols="12" sm="3" md="3" class="formheading">{{
                  lang.ContactNumber
                }}</v-col>
                <v-col cols="12" sm="3" md="3" class="formheading">{{
                  lang.Email
                }}</v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p1name"
                    :rules="nameRules"
                    :counter="20"
                    :label="lang.Person1"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p1designation"
                    :rules="nameRules"
                    :counter="20"
                    :label="lang.Designation"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p1constactNumber"
                    :counter="15"
                    :error-messages="errors"
                    :label="lang.ContactNumber"
                    :rules="mobileRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p1email"
                    :rules="emailRules"
                    :label="lang.Email"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p2name"
                    :counter="20"
                    :label="lang.Person2"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p2designation"
                    :counter="20"
                    :label="lang.Designation"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="phoneNumber"
                    :rules="{
                      required: true,
                      digits: 10
                    }"
                  >
                    <v-text-field
                      v-model="p2constactNumber"
                      :counter="10"
                      :error-messages="errors"
                      :label="lang.ContactNumber"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p2email"
                    :label="lang.Email"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p3name"
                    :counter="20"
                    :label="lang.Person3"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p3designation"
                    :counter="20"
                    :label="lang.Designation"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="phoneNumber"
                    :rules="{
                      required: true,
                      digits: 10
                    }"
                  >
                    <v-text-field
                      v-model="p3constactNumber"
                      :counter="10"
                      :error-messages="errors"
                      :label="lang.ContactNumber"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p3email"
                    :label="lang.Email"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p4name"
                    :counter="20"
                    :label="lang.Person4"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p4designation"
                    :counter="20"
                    :label="lang.Designation"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="phoneNumber"
                    :rules="{
                      required: true,
                      digits: 10
                    }"
                  >
                    <v-text-field
                      v-model="p4constactNumber"
                      :counter="10"
                      :error-messages="errors"
                      :label="lang.ContactNumber"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="p4email"
                    :label="lang.Email"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="a1name"
                    :rules="nameRules"
                    :counter="20"
                    :label="lang.AccountPerson"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="a1designation"
                    :rules="nameRules"
                    :counter="20"
                    :label="lang.Designation"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="a1constactNumber"
                    :counter="15"
                    :error-messages="errors"
                    :label="lang.ContactNumber"
                    :rules="mobileRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="a1email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    counter
                    :label="lang.InstallationNote"
                    :rules="[v => !!v || lang.required]"
                    :value="installnotes"
                    v-model="installnotes"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="ov_er_email"
                    :label="lang.OVEnginner"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6"></v-col>
                <v-col cols="12" sm="6" md="6" style="text-align: right;">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    color="mylightbutton"
                    :disabled="!valid"
                  >
                    {{ lang.submit }}
                  </v-btn>
                  <v-btn @click="clear" color="mydarkbutton">
                    {{ lang.clear }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { required, digits } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode } from "vee-validate";
import { locals } from "../../../utils/locals";
setInteractionMode("eager");
extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

export default {
  components: {
    Preloader,
    Sidebar,
    ValidationProvider
    //ValidationObserver,
  },
  name: "cam_configuration",
  data() {
    return {
      valid: true,
      preloader: false,
      items: {},
      camers: [],
      camdata: [],
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      alert: false,
      alertmessage: "",
      alertType: "",
      updatecamdata: {},
      dialog: false,

      date: null,
      menu1: false,
      currentprojectid: "",
      CurrentPname: "",
      uploadspeed: "",
      ovppt: "",
      prostatus: "",
      doi: "",
      p1name: "",
      p1designation: "",
      p1email: "",
      p1constactNumber: "",
      p2name: "",
      p2designation: "",
      p2email: "",
      p2constactNumber: "",
      p3name: "",
      p3designation: "",
      p3email: "",
      p3constactNumber: "",
      p4name: "",
      p4designation: "",
      p4email: "",
      p4constactNumber: "",
      a1name: "",
      a1designation: "",
      a1email: "",
      a1constactNumber: "",
      installnotes: "",
      ovengineer: "",
      ov_er_email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      nameRules: [
        v => !!v || "Field is required",
        v => (v && v.length <= 20) || "Item must be less than 20 characters"
      ],
      mobileRules: [
        v => !!v || "Field is required",
        v =>
          (v && v.length >= 10 && v.length <= 15) ||
          "Item must be less than 15 and atleast 10 digit",
        v => /^\d+$/.test(v) || "This field only accept numbers"
      ],
      currentProject: "",
      active_proid: "",
      Local: locals,
      lang: {}
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.getPorjects();
    this.current_cam = this.$store.state.currentCamera;
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    //console.log("userdata", this.userdata.email);
    this.updated_by = this.userdata.email;
    // if (this.userdata.free_access == 0 && this.userdata.opticvyu_user == 1) {
    //   this.useraccess = true;
    // } else {
    //   this.$router.push("/");
    // }
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.active_proid = this.currentProject.id;
  },

  watch: {
    "$store.state.currentCamera": function() {
      //console.log("Current data get", this.$store.state.currentCamera);
      this.camers = [];
      this.current_cam = this.$store.state.currentCamera;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
      // this.img_interval  = this.current_cam.image_interval;
      console.log("watch userdata", this.userdata);
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    }
  },
  computed: {
    params() {
      return {
        project_id: this.currentprojectid,
        project_name: this.CurrentPname,
        uploadspeed: this.uploadspeed,
        ovppt: this.ovppt ? 1 : 0,
        prostatus: this.prostatus ? 1 : 0,
        doi: this.doi,
        doidate: this.date,
        p1name: this.p1name,
        p1designation: this.p1designation,
        p1email: this.p1email,
        p1contact: this.p1constactNumber,
        p2name: this.p2name,
        p2designation: this.p2designation,
        p2email: this.p2email,
        p2contact: this.p2constactNumber,
        p3name: this.p3name,
        p3designation: this.p3designation,
        p3email: this.p3email,
        p3contact: this.p3constactNumber,
        p4name: this.p4name,
        p4designation: this.p4designation,
        p4email: this.p4email,
        p4contact: this.p4constactNumber,
        a1name: this.a1name,
        a1designation: this.a1designation,
        a1email: this.a1email,
        a1contact: this.a1constactNumber,
        installnotes: this.installnotes,
        ovengineer: this.userdata.firstname + " " + this.userdata.lastname,
        ov_er_email: this.userdata.email
      };
    }
  },
  methods: {
    getPorjects: function() {
      axios({ url: "/project", method: "GET" })
        .then(resp => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function(item) {
            return item.project_active == 1;
          });

          //this.getCamerasbyProject(this.items[0]);
          this.getCamerasbyProject(this.currentProject);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getCamerasbyProject(projectId) {
      this.preloader = true;
      this.active_proid = projectId.id;
      // console.log(projectId);
      this.CurrentPname = projectId.project_name;
      this.currentprojectid = projectId.id;

      this.camers = {};
      this.camdata = [];
      axios({ url: "/camera/" + projectId.id, method: "GET" })
        .then(resp => {
          //console.log('get cameras by project', resp.data.success);
          this.camers = resp.data.success.filter(function(item) {
            return item.camera_active == 1;
          });
          var len = 0;
          if (resp.data.success != null) {
            len = resp.data.success.length;
          }
          if (len > 0) {
            //console.log("count", resp.data.success.length);
            for (var i = 0; i < len; i++) {
              if (
                this.current_cam.camera_id == resp.data.success[i].camera_id
              ) {
                var camnumber = i + 1;
                console.log(camnumber);
              }
              this.checkboxactive =
                resp.data.success[i].reboot == 1 ? true : false;
              this.img_interval = resp.data.success[i].image_interval;
            }
          }
          // console.log("Cameras", this.camers);
          var a = 1;
          this.camers.forEach(cam => {
            this.getLattersImage(a, cam);
            //this.counter++;

            a++;
          });
          this.preloader = false;
          this.getinstall_info();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getLattersImage() {
      // console.log("lates images cam data",camdata);
    },
    validate() {
      this.$refs.form.validate();
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.update_install_info(this.params); // action to login
      }
    },
    update_install_info() {
      //console.log("data to update", this.param_add);
      //camera_idd = this.notecam_id;
      axios({
        url: "/project/" + this.currentprojectid + "/update_install_info",
        data: this.params,
        method: "POST"
      })
        .then(resp => {
          if (resp.data.success) {
            this.alert = true;
            this.alertmessage = resp.data.success;
            this.alertType = "success";
            this.snackbar = true;
            this.text = resp.data.success;
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
            this.snackbar = true;
            this.text = resp.data.error;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getinstall_info() {
      axios({
        url: "/project/" + this.currentprojectid + "/get_install_info",
        method: "GET"
      })
        .then(resp => {
          console.log("getinstall data", resp.data.success);
          var len = 0;
          if (resp.data.success != null) {
            len = resp.data.success.length;
          }
          if (len > 0) {
            console.log(
              "installation_date",
              resp.data.success[0].date_of_install
            );
            this.uploadspeed = resp.data.success[0].upload_speed;
            this.date = resp.data.success[0].date_of_install;
            if (resp.data.success[0].ov_dash_presentation == 1) {
              this.ovppt = true;
            }
            if (resp.data.success[0].status == 1) {
              this.prostatus = true;
            }
            this.p1name = resp.data.success[0].p1_name;
            this.p1designation = resp.data.success[0].p1_designation;
            this.p1email = resp.data.success[0].p1_email;
            this.p1constactNumber = resp.data.success[0].p1_phone;
            this.p2name = resp.data.success[0].p2_name;
            this.p2designation = resp.data.success[0].p2_designation;
            this.p2email = resp.data.success[0].p2_email;
            this.p2constactNumber = resp.data.success[0].p2_phone;
            this.p3name = resp.data.success[0].p3_name;
            this.p3designation = resp.data.success[0].p3_designation;
            this.p3email = resp.data.success[0].p3_email;
            this.p3constactNumber = resp.data.success[0].p3_phone;
            this.p4name = resp.data.success[0].p4_name;
            this.p4designation = resp.data.success[0].p4_designation;
            this.p4email = resp.data.success[0].p4_email;
            this.p4constactNumber = resp.data.success[0].p4_phone;
            this.a1name = resp.data.success[0].account_a1_name;
            this.a1designation = resp.data.success[0].a1_designation;
            this.a1email = resp.data.success[0].a1_email;
            this.a1constactNumber = resp.data.success[0].a1_phone;
            this.installnotes = resp.data.success[0].install_notes;
            this.ov_er_email = resp.data.success[0].ov_email;
          } else {
            this.uploadspeed = "";
            this.date = "";
            this.ovppt = "";
            this.prostatus = "";
            this.p1name = "";
            this.p1designation = "";
            this.p1email = "";
            this.p1constactNumber = "";
            this.p2name = "";
            this.p2designation = "";
            this.p2email = "";
            this.p2constactNumber = "";
            this.p3name = "";
            this.p3designation = "";
            this.p3email = "";
            this.p3constactNumber = "";
            this.p4name = "";
            this.p4designation = "";
            this.p4email = "";
            this.p4constactNumber = "";
            this.a1name = "";
            this.a1designation = "";
            this.a1email = "";
            this.a1constactNumber = "";
            this.installnotes = "";
            this.ov_er_email = "";
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clear() {
      this.uploadspeed = "";
      this.date = "";
      this.ovppt = "";
      this.prostatus = "";
      this.p1name = "";
      this.p1designation = "";
      this.p1email = "";
      this.p1constactNumber = "";
      this.p2name = "";
      this.p2designation = "";
      this.p2email = "";
      this.p2constactNumber = "";
      this.p3name = "";
      this.p3designation = "";
      this.p3email = "";
      this.p3constactNumber = "";
      this.p4name = "";
      this.p4designation = "";
      this.p4email = "";
      this.p4constactNumber = "";
      this.a1name = "";
      this.a1designation = "";
      this.a1email = "";
      this.a1constactNumber = "";
      this.installnotes = "";
    }
  }
};
</script>
<style>
.vhfix {
  overflow: auto;
}
.minheights {
  min-height: 91vh;
}
.alignbox {
  margin-top: -3%;
}
.mx-2 {
  font-size: 10px !important;
}
.username {
  color: #f3bd08;
  font-weight: 500;
  font-size: 16px;
}
.update_date {
  text-align: right;
  margin-right: 3%;
}
.p_username {
  margin-left: 4%;
}
.p_note {
  margin-left: 4%;
}
.btn_margin {
  margin-left: -3%;
}
.pt-1 {
  /* margin-top: 2%; */
  margin-left: 2%;
  margin-bottom: 4%;
}
.sub_btn_margin {
  margin-top: -28%;
}
.ii_margin {
  margin-left: -4%;
}
.bi_margin {
  margin-left: -2%;
}
.freesimnostyle {
  background-color: #4caf50 !important;
}
.usedsim {
  background: #d88585;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}
.freesim {
  background: #9cdf9c;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}
.formheading {
  text-align: center;
  color: #292929;
  font-weight: 600;
}
.myactiaveitem {
  color: #ffc400 !important;
  caret-color: #ffc400 !important;
  background-color: #fff8e1 !important;
}
</style>

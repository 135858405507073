<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card class="pa-5">
          <v-card-title>
            {{ lang.PPEAIDataScheduleReport }}
          </v-card-title>
          <v-snackbar
            v-model="snackbar"
            centered
            top
            timeout="3000"
            type="success"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="checkboxactive"
                    label="Notification Active"
                  ></v-checkbox>

                  <p class="reportinfo">
                    {{ lang.Reporttype }}
                    <span class="info_rec">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-progress-question
                          </v-icon>
                        </template>
                        <span
                          >1. {{ lang.DaiDay }}<br />
                          2. {{ lang.DaiDay1 }}<br />
                        </span>
                      </v-tooltip>
                    </span>
                  </p>
                  <v-radio-group v-model="reporttype" column>
                    <v-radio
                      :label="lang.Dailyreport"
                      color="amber accent-3"
                      value="Daily"
                    ></v-radio>
                    <v-radio
                      :label="lang.Weeklyreport"
                      color="amber accent-3"
                      value="Weekly"
                    ></v-radio>
                  </v-radio-group>

                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    type="email"
                    :rules="emailrules"
                    multiple
                    required
                  ></v-text-field>
                  <!-- <input type="email" v-model="email" label="Email" :rules="emailrules" multiple required> -->
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{ lang.submit }}
                  </v-btn>

                  <v-btn class="mr-4" @click="resetform" color="mylightbutton">
                    {{ lang.Reset }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";

export default {
  components: {
    Preloader,
    Sidebar
  },
  name: "PpeSheduleRepost",
  data() {
    return {
      valid: true,

      email: "",
      emailrules: [
        v => !!v || this.lang.required,
        v =>
          /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(
            v
          ) || this.lang.EmailValid
      ],

      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      current_cam: "",
      snackbar: false,
      text: "Something went wrong, please try again.",
      reporttype: "",
      checkboxactive: false,
      currentProject: {},
      Local: locals,
      lang: {}
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];

    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
      this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push("/dashboard");
    }
    this.current_cam = this.$store.state.currentCamera;
  },
  watch: {
    "$store.state.currentCamera": function() {
      //console.log("Current data get",this.$store.state.currentCamera)

      this.current_cam = this.$store.state.currentCamera;

      this.getdata();
    }
  },
  computed: {
    params() {
      return {
        emails: this.email,
        status: this.checkboxactive ? 1 : 0,
        user_id: this.userdata.id,
        cam_id: this.current_cam.camera_id,
        project_id: this.currentProject.id,
        reporttype: this.reporttype
      };
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      this.getdata();
      this.preloader = false;
    },
    async submit() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.addNotificaitonSettings(this.params); // action to login
      }
    },
    addNotificaitonSettings(userdata) {
      this.current_cam = this.$store.state.currentCamera;
      // console.log(userdata)
      if (this.checkboxactive == 0) {
        this.snackbar = true;
        this.text = this.lang.ppeReport;
        console.log("no active notification");
      } else {
        this.snackbar = true;
        this.text = this.lang.ppeActive;
        console.log("active notification");
      }
      axios({
        url: "/ai/" + this.current_cam.camera_id + "/schedule-repost",
        params: userdata,
        method: "GET"
      })
        .then(resp => {
          //   console.log("Resp", resp);
          if (resp.data.success) {
            this.alert = true;
            this.alertmessage = resp.data.success;
            this.alertType = "success";
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    },
    resetform() {
      var data = {
        emails: "null",
        status: 0,
        user_id: this.userdata.id,
        cam_id: this.current_cam.camera_id,
        project_id: this.currentProject.id,
        reporttype: "null"
      };

      this.checkboxactive = 0;
      this.current_cam = this.$store.state.currentCamera;
      // console.log(userdata)
      if (this.checkboxactive == 0) {
        this.snackbar = true;
        this.text = this.lang.ppeReport;
        console.log("no active notification");
      } else {
        this.snackbar = true;
        this.text = this.lang.ppeActive;
        console.log("active notification");
      }
      axios({
        url: "/ai/" + this.current_cam.camera_id + "/schedule-repost",
        params: data,
        method: "GET"
      })
        .then(resp => {
          //   console.log("Resp", resp);
          if (resp.data.success) {
            this.alert = true;
            this.alertmessage = resp.data.success;
            this.alertType = "success";
            this.getdata();
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    },
    getdata() {
      this.current_cam = this.$store.state.currentCamera;

      axios({
        url: "/ai/" + this.current_cam.camera_id + "/get-schedule-repost",
        method: "get"
      })
        .then(resp => {
          console.log("dddd", resp.data);

          this.email = resp.data.emails;
          this.checkboxactive = resp.data.status == 0 ? false : true;
          this.reporttype = resp.data.reporttype;
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
        });
    }
  }
};
</script>
<style></style>

<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  mounted() {
    let recaptcMeta = document.createElement("meta");
    recaptcMeta.setAttribute("name", "viewport");
    recaptcMeta.setAttribute(
      "content",
      "width=device-width, minimum-scale=1.0, initial-scale=1, user-scalable=no"
    );
    document.head.appendChild(recaptcMeta);

    let recaptchaScript = document.createElement("link");
    recaptchaScript.setAttribute(
      "href",
      "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
    );
    recaptchaScript.setAttribute("rel", "stylesheet");
    recaptchaScript.setAttribute("type", "text/css");
    document.head.appendChild(recaptchaScript);

    let recaptchacss = document.createElement("script");
    recaptchacss.setAttribute(
      "src",
      "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js"
    );
    document.head.appendChild(recaptchacss);
  }
};
</script>

<template>
     <div class="tfooter">
      
      <v-btn
  color="#fff"
  elevation="2"
  @click="support_dialog"
  small
  class="poppins-medium menstie ma-2" 
> Support </v-btn>
      <v-menu 
      top offset-y >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#fff"
          
          v-bind="attrs"
          v-on="on"
          small
          class="poppins-medium menstie ma-2"
        >
        User manual
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in manuals"
          :key="index"
        >
          <v-list-item-title @click="setrouter(item.url)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu  
      top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#fff"
          
          v-bind="attrs"
          v-on="on"
          small
          class="poppins-medium menstie ma-2"
        >
        Specifications sheet
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in specs"
          :key="index"
        >
          <v-list-item-title @click="setrouter(item.url)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
   
<v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="support_popup"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> Contact Support </v-toolbar>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitquery"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="support_subject"
                    label="Subject"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="supportmsg"
                    label="Message"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    Send
                    <v-progress-circular
                      v-if="sup_send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="support_popup = false" color="mydarkbutton">
                      Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>  
    </div>
</template>
<script>
import axios from "axios";
 export default {
    data: () => ({
        support_popup: false,
    support_subject: "",
    supportmsg: "",
    sup_project: "",
    sup_org: "",
    sup_city: "",
    sup_camid: "",
    sup_camidd: "",
    sup_send: false,
    valid: true,
    specs: [
        { title: 'Artificial Intelligence Powered Cameras', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_TimelapseCamera_Specs.pdf'},
        { title: '360° Photo/Video Tool', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_360ConstructionMonitoring_Specs.pdf'},
        { title: 'Construction Drone Photography', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_ConstructionDrones_Specs.pdf'}
      ],
      manuals: [
        { title: 'Artificial Intelligence Powered Cameras', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_TimelapseCamera_UserManual.pdf'},
        { title: '360° Photo/Video Tool', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_360ConstructionMonitoring_UserManual.pdf'},
        { title: 'Construction Drone Photography', url: 'https://opticvyu-temp.s3.amazonaws.com/Trinetra/OpticVyu_ConstructionDrones_UserManual.pdf'}
      ],
      offset: true,
      userdata : '',
      fullusername : '',
      useremailadd : '',
      usermobile : ''
    }),
    created() {
 
        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
        this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;

    },
    mounted() {


        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
        this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;

    },
    computed: {
    queryparams() {
      return {
        to: "lakhan123kewat@gmail.com",
        message: this.supportmsg,
        subject: this.support_subject,
        //attachment: this.source[0].url,
        project: 'Tata Projects',
        org: "Tata",
        city: '-',
        camera_id: "0",
        username:this.fullusername,
        useremail:this.useremailadd,
        usercontact:this.usermobile,
      };
    },

      size() {
      const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
        return size ? { [size]: true } : {}
    }
    //isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
    methods: {
        async submitquery() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.support_query(this.queryparams); // action to login
      }
    },
        support_query(sharedata) {
      //  console.log("data", sharedata);
      this.sup_send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-support-request",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.support_popup = false;
          this.sup_send = false;
        })
        .catch((err) => {
          console.log(err);
          this.sup_send = false;
          this.snackbar = true;
          this.text = `Email delivery faild.`;
          this.preloader = false;
        });
    },
    support_dialog() {
      this.support_popup = true;
      // this.params.attachment = this.source[0].url;
    },
     setrouter: function (url) {
        window.open(url, '_blank').focus();
        },
    }
 }
</script>
<style scoped>
 .tfooter {
    background: #339;
    text-align: center;
    color: #fff;
    margin-top: 50px;
    padding: 15px;
}
.menstie{
    cursor: pointer;
}
.v-list-item{
 cursor: pointer;
}

</style>
<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-tabs
            v-model="tabindex"
            background-color="amber accent-3"
            fixed-tabs
            dark
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab id="customtimelapse" href="#tab-1">
             {{lang.OneTimeTimelapse}}
            </v-tab>
            <v-tab id="recurringtimelapse" href="#tab-2">
              {{lang.RecurringTimelapse}}
            </v-tab>

            <v-tab
              @click="setrouter('/dashboard/settings/create-advance-timelapse')"
            >
             {{lang.AdvanceTimelapse}}
            </v-tab>

            <v-tab
              @click="setrouter('/dashboard/settings/create-project-timelapse')"
              v-if="camers.length > 1"
            >
              {{lang.ProjectTimelapse}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabindex">
            <v-tab-item value="tab-2">
              <v-card flat>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
                <v-snackbar
                  v-model="snackbar"
                  :multi-line="multiLine"
                  top
                  centered
                  timeout="5000"
                  type="success"
                >
                  {{ text }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="setrouter_reload()"
                    >
                      {{lang.Reload}}
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-form ref="form" @submit.prevent="addrecuring">
                  <v-card class="pa-5">
                    <v-radio-group v-model="recurringtype" column>
                      <v-radio
                        :label="lang.Daily"
                        color="amber accent-3"
                        value="1 day"
                      ></v-radio>
                      <v-radio
                        :label="lang.Weekly"
                        color="amber accent-3"
                        value="1 week"
                      ></v-radio>

                      <v-radio
                        :label="lang.Monthly"
                        color="amber accent-3"
                        value="1 month"
                      ></v-radio>
                    </v-radio-group>

                    <v-row>
                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="menu"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time1"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          color="amber accent-3"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="time1"
                              :label="lang.StartTime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              format="24hr"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu2"
                            v-model="time1"
                            full-width
                            format="24hr"
                            @click:minute="$refs.menu.save(time1)"
                            color="amber accent-3"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="menu3"
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time2"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          color="amber accent-3"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="time2"
                              :label="lang.Endtime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu3"
                            v-model="time2"
                            full-width
                            color="amber accent-3"
                            format="24hr"
                            @click:minute="$refs.menu3.save(time2)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="speed"
                          v-model="fps"
                          :label="lang.TimelapseSpeed"
                          item-text="title"
                          item-value="speed"
                        ></v-select>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="quality"
                          v-model="resolution"
                          :label="lang.TimelapseQuality"
                          item-text="title"
                          item-value="quality"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn class="mr-4" type="submit" color="mylightbutton">
                      {{lang.submit }}
                    </v-btn>
                    <v-btn @click="clear" color="mydarkbutton"> {{lang.Reset}} </v-btn>
                    <span class="info_rec">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-progress-question
                          </v-icon>
                        </template>
                        <span
                          >{{lang.info_rec}}</span
                        >
                      </v-tooltip>
                    </span>
                  </v-card>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
                <v-snackbar
                  v-model="snackbar"
                  :multi-line="multiLine"
                  top
                  centered
                  timeout="5000"
                  type="success"
                >
                  {{ text }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="setrouter_reload()"
                    >
                      {{lang.Reload}}
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-form ref="onetimeform" @submit.prevent="addOnetime">
                  <v-card class="pa-5">
                    <v-row>
                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="startDatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="startdate"
                              :label="lang.StartDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="startdate"
                            @input="startDatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="enddatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          color="amber accent-3"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="enddate"
                              :label="lang.EndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="enddate"
                            @input="enddatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="starttimemenu"
                          v-model="starttimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="starttimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          color="amber accent-3"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="starttimetime"
                              :label="lang.StartTime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              format="24hr"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            color="amber accent-3"
                            v-if="starttimemenu"
                            v-model="starttimetime"
                            full-width
                            format="24hr"
                            @click:minute="
                              $refs.starttimemenu.save(starttimetime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="endtimemenu"
                          v-model="endtimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="endtimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          color="amber accent-3"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="endtimetime"
                              :label="lang.Endtime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="endtimemenu"
                            v-model="endtimetime"
                            full-width
                            color="amber accent-3"
                            format="24hr"
                            @click:minute="$refs.endtimemenu.save(endtimetime)"
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="speed"
                          v-model="fps"
                          :label="lang.TimelapseSpeed"
                          item-text="title"
                          item-value="speed"
                        ></v-select>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="quality"
                          v-model="resolution"
                          :label="lang.TimelapseQuality"
                          item-text="title"
                          item-value="quality"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn class="mr-4" type="submit" color="mylightbutton">
                      {{lang.submit}}
                    </v-btn>
                    <v-btn @click="clear" color="mydarkbutton"> {{lang.Reset}} </v-btn>
                    <span class="info_rec">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark v-bind="attrs" v-on="on">
                            mdi-progress-question
                          </v-icon>
                        </template>
                        <span
                          >{{lang.Infovidoss}}</span
                        >
                      </v-tooltip>
                    </span>
                  </v-card>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <span class="info_rec">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-progress-question
                    </v-icon>
                  </template>
                  <span
                    >Advanced: Use blending or stabilization for advanced
                    time-lapse video customization.<br />
                    <strong>Advanced blending option</strong>:-<br /><i>
                      &nbsp;&nbsp;&nbsp;"Frame blending: average" will blend two
                      consecutive frames while &nbsp;&nbsp;&nbsp;"frame
                      blending: overlap" will overlap consecutive images to
                      increase video sharpness.<br />
                      &nbsp;&nbsp;&nbsp;"Video smoothing" option creates a
                      smooth transition between the consecutive frames depending
                      on the smoothing level selected<br />
                      &nbsp;&nbsp;&nbsp;"Deflicker" will remove extra dark or
                      light images from the video.</i
                    ><br />
                    <strong>Stabilization option</strong> will stabilize the
                    video in case the camera is installed on unsteady support
                    like tower create, slender pole, etc.
                  </span>
                </v-tooltip>
              </span>
              <v-card flat>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
                <v-form ref="advanceform" @submit.prevent="submitadvancetime">
                  <v-card class="pa-5">
                    <v-row>
                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="ad_startDatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="ad_startdate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="ad_startdate"
                            @input="ad_startDatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="ad_enddatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          color="amber accent-3"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="ad_enddate"
                              :label="lang.EndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="ad_enddate"
                            @input="ad_enddatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="ad_starttimemenu"
                          v-model="ad_starttimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="starttimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          color="amber accent-3"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="ad_starttimetime"
                              :label="lang.Starttime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              format="24hr"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            color="amber accent-3"
                            v-if="ad_starttimemenu"
                            v-model="ad_starttimetime"
                            full-width
                            format="24hr"
                            @click:minute="
                              $refs.ad_starttimemenu.save(ad_starttimetime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="ad_endtimemenu"
                          v-model="ad_endtimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="ad_endtimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          color="amber accent-3"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="ad_endtimetime"
                              :label="lang.Endtime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="ad_endtimemenu"
                            v-model="ad_endtimetime"
                            full-width
                            color="amber accent-3"
                            format="24hr"
                            @click:minute="
                              $refs.ad_endtimemenu.save(ad_endtimetime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="speed"
                          v-model="ad_fps"
                          :label="lang.TimelapseSpeed"
                          item-text="title"
                          item-value="speed"
                        ></v-select>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="quality"
                          v-model="ad_resolution"
                          :label="lang.TimelapseQuality"
                          item-text="title"
                          item-value="quality"
                        ></v-select>
                      </v-col>
                      <v-col cols="11" sm="10">
                        <v-select
                          :items="advance"
                          v-model="advance_op"
                          :label="lang.TimelapseAdvancesettings"
                          item-text="title"
                          item-value="advance"
                          @change="selectop(advance_op)"
                        ></v-select>
                      </v-col>

                      <!-- <v-col cols="11" sm="10" v-if="advance_op === 'blending'"> -->
                      <v-col cols="11" sm="5" v-if="advance_op === 'blending'">
                        <v-select
                          :items="ad_frame"
                          v-model="frame_op"
                          :label="lang.Selectframeblending"
                          item-text="title"
                          item-value="ad_frame"
                        ></v-select>
                      </v-col>
                      <v-col cols="11" sm="5" v-if="advance_op === 'blending'">
                        <v-select
                          :items="ad_smoothing"
                          v-model="smoothing_op"
                          :label="lang.SelectVideoSmoothing"
                          item-text="title"
                          item-value="ad_smoothing"
                        ></v-select>
                      </v-col>
                      <v-col cols="11" sm="5" v-if="advance_op === 'blending'">
                        <v-select
                          :items="ad_deflicker"
                          v-model="deflicker_op"
                          :label="lang.SelectDeflicker"
                          item-text="title"
                          item-value="ad_deflicker"
                        ></v-select>
                      </v-col>
                      <v-col cols="11" sm="5" v-if="advance_op === 'blending'">
                        <v-text-field
                          v-model="ad_text"
                          :label="lang.EnterOverlayText"
                        ></v-text-field>
                      </v-col>
                      <!-- </v-col> -->
                    </v-row>
                    <v-btn class="mr-4" type="submit" color="mylightbutton">
                      {{lang.submit}}
                    </v-btn>
                    <v-btn @click="clearadvance" color="mydarkbutton">
                      {{lang.Reset}}
                    </v-btn>
                  </v-card>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <span class="info_rec">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-progress-question
                    </v-icon>
                  </template>
                  <span
                    >Project time-lapse: To view time-lapse videos of multiple
                    cameras in one video, use this option. <br />Minimum 2 and
                    maximum 4 cameras can be selected for creating a project
                    time-lapse video.</span
                  >
                </v-tooltip>
              </span>
              <v-card flat>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
                <v-form ref="projectform" @submit.prevent="submitprojecttime">
                  <v-card class="pa-5">
                    <v-row>
                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="pro_startDatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="pro_startdate"
                              :label="lang.StartDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="pro_startdate"
                            @input="pro_startDatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          v-model="pro_enddatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          color="amber accent-3"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pro_enddate"
                              :label="lang.EndDate"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="amber accent-3"
                            v-model="pro_enddate"
                            @input="pro_enddatemenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="pro_starttimemenu"
                          v-model="pro_starttimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="pro_starttimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          color="amber accent-3"
                        >
                          <template
                            v-slot:activator="{ on, attrs }"
                            color="amber accent-3"
                          >
                            <v-text-field
                              v-model="pro_starttimetime"
                              :label="lang.Starttime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              format="24hr"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            color="amber accent-3"
                            v-if="pro_starttimemenu"
                            v-model="pro_starttimetime"
                            full-width
                            format="24hr"
                            @click:minute="
                              $refs.pro_starttimemenu.save(pro_starttimetime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="pro_endtimemenu"
                          v-model="pro_endtimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="pro_endtimetime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          color="amber accent-3"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="pro_endtimetime"
                              :label="lang.Endtime"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="pro_endtimemenu"
                            v-model="pro_endtimetime"
                            full-width
                            color="amber accent-3"
                            format="24hr"
                            @click:minute="
                              $refs.pro_endtimemenu.save(pro_endtimetime)
                            "
                          ></v-time-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="speed"
                          v-model="pro_fps"
                          :label="lang.TimelapseSpeed"
                          item-text="title"
                          item-value="speed"
                        ></v-select>
                      </v-col>

                      <v-col cols="11" sm="5">
                        <v-select
                          :items="quality"
                          v-model="pro_resolution"
                          :label="lang.TimelapseQuality"
                          item-text="title"
                          item-value="quality"
                        ></v-select>
                      </v-col>
                      <v-col cols="11" sm="10">
                        <v-select
                          :items="pro_advance"
                          v-model="pro_advance_op"
                          :label="lang.TimelapseAdvancesettings"
                          item-text="title"
                          item-value="pro_advance"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="11"
                        sm="5"
                        v-if="pro_advance_op === 'blending'"
                      >
                        <v-select
                          :items="ad_frame"
                          v-model="pro_frame_op"
                          :label="lang.Selectframeblending"
                          item-text="title"
                          item-value="ad_frame"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="5"
                        v-if="pro_advance_op === 'blending'"
                      >
                        <v-select
                          :items="ad_smoothing"
                          v-model="pro_smoothing_op"
                          :label="lang.SelectVideoSmoothing"
                          item-text="title"
                          item-value="ad_smoothing"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="5"
                        v-if="pro_advance_op === 'blending'"
                      >
                        <v-select
                          :items="ad_deflicker"
                          v-model="pro_deflicker_op"
                          :label="lang.SelectDeflicker"
                          item-text="title"
                          item-value="ad_deflicker"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="11"
                        sm="5"
                        v-if="pro_advance_op === 'blending'"
                      >
                        <v-text-field
                          v-model="pro_text"
                          :label="lang.EnterOverlayText"
                        ></v-text-field>
                      </v-col>
                      <!-- {{choosecamera.length}} -->
                      <v-col
                        cols="3"
                        sm="3"
                        v-for="c in camers"
                        :key="c.cameraid"
                      >
                        <v-checkbox
                          v-model="choosecamera"
                          :label="c.camName"
                          color="orange"
                          :value="c.camera_id"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-btn class="mr-4" type="submit" color="mylightbutton">
                      {{lang.submit}}
                    </v-btn>
                    <v-btn @click="clearproject" color="mydarkbutton">
                      {{lang.Reset }}
                    </v-btn>
                  </v-card>
                </v-form>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import VueSweetalert2 from "vue-sweetalert2";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar,
  },
  name: "Customiztimelpas",
  data() {
    return {
      preloader: true,

      currentcam: {},
      tabindex: null,
      tabs: ["#tab-1", "#tab-2"],
      time1: null,
      time2: null,
      timelapsdata: {},
      starttimetime: null,
      endtimetime: null,

      ad_starttimetime: null,
      ad_endtimetime: null,
      pro_starttimetime: null,
      pro_endtimetime: null,

      startdate: null,
      enddate: null,

      ad_startdate: null,
      ad_enddate: null,
      pro_startdate: null,
      pro_enddate: null,

      menu2: false,
      menu3: false,

      starttimemenu: false,
      endtimemenu: false,

      startDatemenu: false,
      enddatemenu: false,

      ad_starttimemenu: false,
      ad_endtimemenu: false,

      ad_startDatemenu: false,
      ad_enddatemenu: false,

      pro_starttimemenu: false,
      pro_endtimemenu: false,

      pro_startDatemenu: false,
      pro_enddatemenu: false,

      speed: [
        { speed: 24, title: "Fast" },
        { speed: 15, title: "Medium" },
        { speed: 10, title: "Slow" },
      ],
      quality: [
        { quality: "1280x720", title: "SD Video(720p)" },
        { quality: "1920x1080", title: "HD Video(1080p)" },
      ],
      advance: [
        { advance: "blending", title: "Time-lapse Blending" },
        { advance: "Stabilization", title: "Time-lapse Stabilization" },
        {
          advance: "deflicker_Stabilization",
          title: "Time-lapse Stabilization with deflicker",
        },
      ],
      pro_advance: [
        { pro_advance: "blending", title: "Time-lapse Blending" },
        { pro_advance: "none", title: "No advance customization" },
      ],
      ad_frame: [
        { ad_frame: "None", title: "None" },
        { ad_frame: "Average", title: "Average" },
        { ad_frame: "Overlay", title: "Overlay" },
      ],
      ad_smoothing: [
        { ad_smoothing: "None", title: "None" },
        { ad_smoothing: "Low", title: "Low" },
        { ad_smoothing: "Medium", title: "Medium" },
        { ad_smoothing: "High", title: "High" },
      ],
      ad_deflicker: [
        { ad_deflicker: "Yes", title: "Yes" },
        { ad_deflicker: "NO", title: "No" },
      ],
      recurringtype: "",
      currentProject: {},
      alert: false,
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      multiLine: false,
      fps: "",
      resolution: "",
      ad_fps: "",
      ad_resolution: "",
      pro_fps: "",
      pro_resolution: "",
      advance_op: "",
      frame_op: "",
      smoothing_op: "",
      deflicker_op: "",
      ad_text: "",
      pro_advance_op: "",
      pro_frame_op: "",
      pro_text: "",
      pro_deflicker_op: "",
      pro_smoothing_op: "",
      camers: [],
      countcameras :[],
      choosecamera: [],
      Local :locals,  
      lang : {},  
    };
  },
  computed: {
    params() {
      return {
        type: this.recurringtype,
        proj_id: this.currentProject.id,
        start_time: this.time1,
        end_time: this.time2,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
    },

    onetimeparmas() {
      return {
        type: "",
        proj_id: this.currentProject.id,
        start_date: this.startdate,
        end_date: this.enddate,
        start_time: this.starttimetime,
        end_time: this.endtimetime,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
    },

    projecttimeparmas() {
      return {
        type: "project_timelapse",
        proj_id: this.currentProject.id,
        camers: this.choosecamera,
        start_date: this.pro_startdate,
        end_date: this.pro_enddate,
        start_time: this.pro_starttimetime,
        end_time: this.pro_endtimetime,
        timelapse_fps: this.pro_fps,
        ct_resolution: this.pro_resolution,
        ad_custom: this.pro_advance_op,
        frame_blending: this.pro_frame_op,
        smoothing: this.pro_smoothing_op,
        deflicker: this.pro_deflicker_op,
        overlay_text: this.pro_text,
      };
    },

    adancetimeparmas() {
      return {
        type: "advance",
        proj_id: this.currentProject.id,
        start_date: this.ad_startdate,
        end_date: this.ad_enddate,
        start_time: this.ad_starttimetime,
        end_time: this.ad_endtimetime,
        timelapse_fps: this.ad_fps,
        ct_resolution: this.ad_resolution,
        ad_custom: this.advance_op,
        frame_blending: this.frame_op,
        smoothing: this.smoothing_op,
        deflicker: this.deflicker_op,
        overlay_text: this.ad_text,
      };
    },
  },
  mounted() {
     var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    this.getCurrentProjectData();
    this.gettimeplaseSettings();
    this.gettimeplaseStatus_queue();
    this.tabindex = this.tabs.findIndex((tab) => tab === this.$route.hash);
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
      if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera);
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.projectid = currentProject.id;
      this.currentcam = this.$store.state.currentCamera;
      this.getCurrentProjectData();
      this.gettimeplaseSettings();
      this.gettimeplaseStatus_queue();
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    setrouter: function (url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
        //   console.log("current router ", this.$route.path);
        //this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    setrouter_reload: function () {
      //this.$router.push("/dashboard/settings/create-project-timelapse");
      //this.$router.go();
      window.location.reload(true);
      //console.log(url);
    },
    async submit() {
      //const valid = await this.$refs.form.validate()

      this.addrecuring(this.params); // action to login
    },
    async submitontime() {
      //const valid = await this.$refs.form.validate()

      this.addOnetime(this.onetimeparmas); // action to login
    },
    async submitadvancetime() {
      //const valid = await this.$refs.form.validate()

      this.addAdvancetime(this.adancetimeparmas); // action to login
    },
    async submitprojecttime() {
      //const valid = await this.$refs.form.validate()

      this.addProjecttime(this.projecttimeparmas); // action to login
    },
    advance_note: function () {
      this.$swal({
        icon: "info",
        title: "Note",
        html: this.lang.upto8,
         showConfirmButton: true,
      });
    },
    project_note: function () {
      this.$swal({
        icon: "info",
        title: "Note",
        html: this.lang.projectnote1,
          //"1. Project Time-lapse may take up to 12 hours. You will be notified once it is ready.",
        showConfirmButton: true,
      });
    },

    addrecuring() {
      // console.log(timelapsdata)
      var cttype = this.recurringtype;
      var rec_time1 = this.time1;
      var rec_time2 = this.time2;
      var rec_fps = this.fps;
      var rec_resolution = this.resolution;

      if (!cttype) {
        this.alert = true;
        this.alertmessage =  this.lang.reccuringtimelapsecheck//"Please check recurring timelapse type";
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }

      if (!rec_time1 || !rec_time2) {
        this.alert = true;
        this.alertmessage = this.lang.alerrecurring;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (!rec_fps || !rec_resolution) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired3;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }

      this.timelapsdata = {
        type: this.recurringtype,
        proj_id: this.currentProject.id,
        start_time: this.time1,
        end_time: this.time2,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/timelapse/custom",
        data: this.timelapsdata,
        method: "POST",
      })
        .then((resp) => {
          //console.log("Resp", resp);
          if (resp.data.success) {
            /* this.alert = true;
            this.alertmessage =
              "Recurring time-lapse setting saved successfully";
            this.alertType = "success"; */
            this.$swal({
              icon: "success",
              title: "Success",
              html: this.lang.recurringsave, //"Recurring time-lapse setting saved successfully",
              showConfirmButton: true,
            });
            this.gettimeplaseStatus_queue();
          } else {
            /* this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error"; */
            this.$swal({
              icon: "error",
              title: "Error",
              html: this.lang.timelapsenotsave,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          /*  this.alert = true;
          this.alertmessage =
            "Something went wrong. Please reload page and try again.";
          this.alertType = "error"; */
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },

    addOnetime() {
      //console.log(timelapsdata)
      var stdate = this.startdate;
      var eddate = this.enddate;
      var sttime = this.starttimetime;
      var edtime = this.endtimetime;
      var ctfps = this.fps;
      var ctresolution = this.resolution;
      var CurrentDate = new Date();
      if (eddate > CurrentDate) {
        this.alert = true;
        this.alertmessage = this.lang.Adalertmessage;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (!stdate || !eddate || !sttime || !edtime) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (!ctfps || !ctresolution) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired3;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      if (stdate > eddate || sttime > edtime) {
        this.alert = true;
        this.alertmessage = this.lang.adrequired2;
        this.alertType = "error";

        setTimeout(() => {
          this.alert = false;
        }, 3000);
        return;
      }
      this.timelapsdata = {
        type: "onetime",
        proj_id: this.currentProject.id,
        start_date: this.startdate,
        end_date: this.enddate,
        start_time: this.starttimetime,
        end_time: this.endtimetime,
        timelapse_fps: this.fps,
        ct_resolution: this.resolution,
      };
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/timelapse/custom",
        data: this.timelapsdata,
        method: "POST",
      })
        .then((resp) => {
          //console.log("Resp", resp);
          if (resp.data.success) {
            /* this.alert = true;
            this.alertmessage =
              "One time Custom time-lapse is being processed. You will be notified through the email once it is ready";
            this.alertType = "success"; */
            this.$swal({
              icon: "success",
              title: "Success",
              html:
                this.lang.timelapsebingpressed,
              showConfirmButton: true,
            });
            this.gettimeplaseStatus_queue();
          } else {
            /* this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error"; */
            this.$swal({
              icon: "error",
              title: "Error",
              html: this.lang.timelapsenotsave,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          /* this.alert = true;
          this.alertmessage =
            "Something went wrong. Please reload page and try again.";
          this.alertType = "error"; */
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },

    getCurrentProjectData() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      // console.log(this.currentProject);
      this.drawer = false;
      this.camers = {};
      axios({ url: "/camera/" + currentProject.id, method: "GET" })
        .then((resp) => {
          //console.log("camrs==================>",resp);
          this.preloader = false;
          var a = 0;
          var b = 1;
          this.camers = resp.data.success.filter(function (item) {
            item.camName = "CAM " + b;
            item.iindex = a;
            a++;
            b++;

            return item.camera_active == 1;
          });
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },

    gettimeplaseSettings() {
      //var currentdt = new Date();
      console.log("currentcam", this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/timelapse/custom",
        method: "GET",
      })
        .then((resp) => {
          // console.log("Resp", resp);

          if (resp.data.success) {
            var res = resp.data.success;
            this.recurringtype = res.type;

            this.startdate = res.start_date;
            this.enddate = res.end_date;
            this.starttimetime = res.start_time;
            this.endtimetime = res.end_time;
            this.fps = res.timelapse_fps;
            this.resolution = res.ct_resolution;

            this.time1 = res.start_time;
            this.time2 = res.end_time;
          } else if (
            resp.data.error ==
            "No existing custom timelapse found for this camera."
          ) {
            var res = "";
            this.recurringtype = "";

            this.startdate = "";
            this.enddate = "";
            this.starttimetime = "";
            this.endtimetime = "";
            this.fps = "";
            this.resolution = "";

            this.time1 = "";
            this.time2 = "";
          }
        })
        .catch((err) => {
          console.log(err);
          /* this.alert = true;
          this.alertmessage =
            "Something went wrong please reload page and try again.";
          this.alertType = "error"; */
        });
    },
    clear() {
      axios({
        url:
          "/camera/" + this.currentcam.camera_id + "/reset_timelapse_setting",
        method: "GET",
      }).then((resp) => {
        if (resp.data.success) {
          /* this.alert = true;
          this.alertmessage = resp.data.success;
          this.alertType = "success"; */
          this.$swal({
            icon: "success",
            title: "Success",
            html: this.lang.timelapsenotexit,
            showConfirmButton: true,
          });
          this.startdate = "";
          this.enddate = "";
          this.starttimetime = "";
          this.endtimetime = "";
          this.fps = "";
          this.resolution = "";
          this.recurringtype = "";
          this.time1 = "";
          this.time2 = "";
        } else {
          /*  this.alert = true;
          this.alertmessage = "Something went wrong please try again.";
          this.alertType = "error"; */
          this.$swal({
            icon: "error",
            title: "Error",
            html: this.lang.custometimelapseresrt,
            showConfirmButton: true,
          });
        }
      });
    },
     gettimeplaseStatus_queue() {
      //var currentdt = new Date();
      console.log("currentcam", this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      axios({
        url: "/camera/" + this.currentcam.camera_id + "/customtimelapse/get_queue",
        method: "GET",
      })
        .then((resp) => {
          console.log("Resp", resp);
          
          if (resp.data.success) {
            if(resp.data.success == 1){
              this.alert = true;
              this.alertmessage = "Your custom time-lapse is being processed. You'll soon receive the time-lapse link on your registered email.";
              this.alertType = "info";
           }else {
                this.alert = true;
              this.alertmessage = "Your custom time-lapse is in queue: position #"+resp.data.success+". It will be processed shortly & you'll be notified soon via email.";
              this.alertType = "info";
            }
              console.log("yes resp", resp.data);
          } else {
            this.alert = false;
            console.log("no resp", resp.data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.alert = false;
        });
    },
    clearadvance() {},
    clearproject() {},
  },
};
</script>
<style>
.info_rec {
  position: relative;
  left: 2%;
}
.mdi-progress-question {
  font-size: 28px !important;
}
</style>


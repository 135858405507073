<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="10" md="10" class="et_img">
        <v-card flat class="mx-auto my-12" max-width="100%">
          <v-alert dense text :type="alertType" v-if="alert" style="text-align: center;margin: 10%;">
            {{ alertmessage }}
          </v-alert>
           <v-col v-for="et in ETresponse"
              :key="et.id"
              :value="et.image_id"
              :id="et.image_id">
          <v-timeline align-top dense>
           
            <v-timeline-item
              
              color="#343a42"
              icon="mdi-tag"
              fill-dot
              
            >
              <v-card color="#343a42" dark>
                <v-card-title class="text-h6">
                  {{ et.description }}
                </v-card-title>
                <span class="et_user">{{ et.user }}</span>
                <div class="et_date">{{ et.imgdate }}</div>
                <v-card-text class="white text--primary">
                  <v-img
                    :src="et.image"
                    aspect-ratio="1.7"
                    max-width="100%"
                    class="c_pointer"
                    @click="openimage(et.image)"
                    ></v-img>

                  <!--  <v-btn :color="item.color" class="mx-0" outlined>
                    Button
                  </v-btn> -->
                </v-card-text>
              </v-card>
            </v-timeline-item>
            
          </v-timeline>
          </v-col>
          <v-zoomer class="zzoomerfull" v-if="dialog">
            <img 
              :src="imgpop"
              style="object-fit: contain; width: 100%; height: 100%"
            />
          </v-zoomer>
          <v-icon v-if="dialog" class="closebuttons" @click="closetoggle"
            >mdi-window-close</v-icon
          >
        </v-card>
      </v-col>
      <v-col cols="2" md="2">
        <v-card
          v-if="display_block"
          flat
          class="mx-auto my-12"
          max-width="100%"
          style="position: fixed; top: 0%"
        >
          <v-card-title class="text-h6">
            {{lang.EventTimeline}}
          </v-card-title>
          <v-card-text class="white text--primary etlist">
            <v-list>
              <v-list-item-group v-model="model" color="amber accent-3">
                <v-list-item class="etlist"
                  v-for="(etn, i) in years"
                  :key="i"
                 >
                  <v-list-item-content>
                    <v-list-item-title class="yearli" v-text="etn"  @click="selectYear(etn)">
                    </v-list-item-title>
                    <ul
                      class="monthul"
                      v-for="etnm in currentMonths"
                      :key="etnm.year"
                    >
                      <li class="monthli" v-if="etn == etnm.year">
                        <a :href='"#"+etnm.month_tag'>
                        {{ etnm.month }}
                        </a>
                      </li>
                    </ul>
                    <!-- <v-list-item
                  class="monthul"
                  v-for="etnm in currentMonths"
                  :key="etnm.year"
                >
                  <v-list-item-content v-if="etn == etnm.year">
                    <v-list-item-title class="monthli" v-text="etnm.month">
                      
                    </v-list-item-title>
                      
                  </v-list-item-content>
                 
                </v-list-item> -->
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="etlist">
                  <v-list-item-content>
                    <v-list-item-title
                      class="yearli"
                      @click="getEventTimeline()"
                      >{{lang.ShowAll}}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- <ul class="yearul">
              <li class="yearli" v-for="(etn,i) in years" :key="i" @click="selectYear(etn)">
                {{ etn }}
                <ul
                  class="monthul"
                  v-for="etnm in currentMonths"
                  :key="etnm.year"
                >
                  <li class="monthli"  v-if="etn == etnm.year && etnm.month">
                    {{ etnm.month }}
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="yearul">
              <li class="yearli" @click="getEventTimeline()">Show All</li>
            </ul> -->
            <!-- <ul class="menu">
              <a id="selPrevScr"> Prev </a>
              <a id="selNextScr"> Next </a>
            </ul> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
import { locals } from "../../utils/locals";
export default {
  components: {
    Preloader,
    // Sidebar
  },
  name: "EventTimeline",
  /*  data: () => ({
    items: [
      {
        color: "red lighten-2",
        icon: "mdi-star",
      },
      {
        color: "purple darken-1",
        icon: "mdi-book-variant",
      },
      {
        color: "green lighten-1",
        icon: "mdi-airballoon",
      },
      {
        color: "indigo",
        icon: "mdi-buffer",
      },
    ],
  }), */
  data() {
    return {
      preloader: true,
      currentcam: {},
      tab: null,
      currentProject: {},
      alert: false,
      ETresponse: {},
      ETnav: {},
      etyear: "",
      dialog: false,
      imgpop: "",
      snackbar: false,
      text: `Something went wrong. Please try again!`,
      color: "purple darken-1",
      icon: "mdi-book-variant",
      display_block: true,
      years: [],
      myTimelinedata: {},
      currentMonths: {},
      modal: "",
         Local :locals,  
        lang : {},

    };
  },
  computed: {},
  mounted() {

    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];

    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.initViewer();
    this.getEventTimeline();
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //this.preloader = true
      // console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
      this.getEventTimeline();
    },
  },
  methods: {
    initViewer() {
      // console.log("init Viewer");
      this.preloader = false;
    },
    getEventTimeline() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentcam = this.$store.state.currentCamera;
      this.currentProject = currentProject;

      axios({
        url:
          "/project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/gettagevents",
        method: "GET",
      })
        .then((resp) => {
          if(resp.data.errormsg){
              console.log("error msg",resp.data);
                    this.alert = true;
                    this.alertmessage = resp.data.errormsg;
                    this.alertType = "error"
                    this.preloader = false
                    this.ETresponse = '';
          }else{
            this.alert = false;
          //console.log("favourite_resp=>", resp);
          this.preloader = false;
          this.ETresponse = resp.data.success.uevents;
          console.log("ETresponse=>>", this.ETresponse);
          this.ETnav = resp.data.success.nav;
          console.log("ETnav=>>", this.ETnav);

          var output = resp.data.success.nav;
          var a = [];
          var i= 1;
          for (var prop in output) {
            a.push({
              month: output[prop].Month,
              year: output[prop].Year,
              month_tag: output[prop].Month_no,
              imgindex: i++,
            });
          }

          this.myTimelinedata = a;

          var _self = this;

          setTimeout(() => {
            a.forEach((val) => {
              if (!_self.years.includes(val.year)) {
                _self.years.push(val.year);
              }
            });

            console.log("years", this.years);
          }, 1000);
          }
          //   console.log("eventlist=>", this.ETresponse);
          //   var len = 0;
          //   var yr = '';
          //   if (this.ETnav != null) {
          //     len = this.ETnav.length;
          //     console.log("year", resp.data.success.nav2);
          //   }
          //    for (var i = 0; i < len; i++) {
          //       //this.$refs.custome_timelapsvideo.src = resp.data.success.archive_timelapse.data[i].timelapse_url;
          //       yr = resp.data.success.nav[i].year
          //       console.log("year", yr);

          //     }
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    selectYear(y) {
      this.currentMonths = this.myTimelinedata.filter(function (item) {
        return item.year == y;
      });
      if(this.currentMonths.length >1){
        console.log("currentMonths", this.currentMonths);
      }
      
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentcam = this.$store.state.currentCamera;
      this.currentProject = currentProject;
      var rm_para = {
        dt: y,
      };
      axios({
        url:
          "project/" +
          currentProject.id +
          "/camera/" +
          this.currentcam.camera_id +
          "/getnavtag",
        params: rm_para,
        method: "get",
        /*  headers: {
          'X-CSRF-TOKEN':document.querySelector('meta[name="_token"]').getAttribute('content')
        }, */
        contentType: "application/json",
      })
        .then((resp) => {
          if(resp.data.errormsg){
              console.log("error msg",resp.data);
                    this.alert = true;
                    this.alertmessage = resp.data.errormsg;
                    this.alertType = "error"
                    this.preloader = false
                    this.ETresponse = '';
          }else{
            this.alert = false;
          console.log("nav data", resp);
          this.ETresponse = resp.data;
          //console.log("getnavresp==>", this.ETresponse);
              if(this.ETresponse.length >1){
                console.log("getnavresp==>", this.ETresponse);
              }
          }
        })
        .catch((err) => {
          console.log("Report Error", err);
          this.snackbar = true;
          this.text = this.lang.SomethingWentWrong;
        });
    },

    openimage(url) {
      this.dialog = true;
      this.imgpop = url;
      this.display_block = false;
    },
    closetoggle() {
      this.dialog = false;
      this.display_block = true;
    },
  },
};
</script>
<style>
.et_img {
  margin-top: -4%;
}
.et_date {
  text-align: end;
  margin-top: -3%;
  margin-right: 1%;
}
.et_user {
  position: absolute;
  top: 0%;
  right: 1%;
}
.yearul {
  list-style-type: none;
}
.yearli {
  font-size: 18px;
  cursor: pointer;
  margin-top: 7%;
  background: #f8bb03;
    color: #000;
    padding: 6px 15px;
}
.monthli {
  font-size: 20px;
  cursor: pointer;
  /* text-align: center;
  margin-top: 5%; */
}
.monthul {
  /* margin-left: 18%; */
  list-style-type: none;
}
#selNext,
#selPrev,
#selNextScr,
#selPrevScr {
  color: #fed136;
  cursor: pointer;
}
li.monthli:hover {
  webkit-box-shadow: 0px 0px 5px 0px rgba(254, 209, 54, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(254, 209, 54, 1);
  box-shadow: 0px 0px 5px 0px rgb(254 209 54);
}
li.monthli.active {
  background-color: rgba(254, 209, 54, 0.3);
}
.etlist{
  padding: 0 !important;
  margin-top: -10%;
}
li.monthli a {
    padding: 9px 15px;
    display: block;
    text-decoration: none;
    background: #F0F2F2;
    color: #050505 !important;
    font-size: 15px;
}
</style>
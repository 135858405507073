import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/styles.scss";
import "./assets/styles.scss";
import Axios from "axios";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VDateRangePicker from "v-date-range-picker";
import $ from "jquery";
import VueCookies from "vue-cookies";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.use(VueCookies);

Vue.prototype.$http = Axios;
Vue.use(VDateRangePicker);
Vue.use(require("vue-moment"));

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC4Kv9QiLIdKfBOsj7xPD1XYwQCgbzrlzY", //"AIzaSyBbhSB-0kAkAKmsf4iQKSvT_jN3TqOy2jM",
    libraries: "places" // necessary for places input
  }
});
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};
Vue.use(VueSweetalert2, options);
const token = localStorage.getItem("token");
Vue.prototype.$http.defaults.baseURL = "https://api.opticvyu.com/api";
//Vue.prototype.$http.defaults.baseURL = "https://api.opticvyu.com/api";
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

$(".map").maphilight({
  alwaysOn: true
});

<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" md="9">
        <v-card class="pa-5">
          <v-card-title> {{lang.AddNewUser}} </v-card-title>

          <v-alert dense text :type="alertType" v-if="alert">

            <v-row align="center" >
        <v-col class="grow">
          {{ alertmessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn  v-if="userinfo"
            @click="userstatus">{{lang.Checkinfo}}</v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn  v-if="manageuser"
            @click="manageusers"> {{lang.ManageUser}}</v-btn>
        </v-col>
      </v-row>
          </v-alert>
           <v-snackbar
            v-model="snackbar"
            center
            top
            timeout="3000"
            type="success"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{lang.Ok}}
              </v-btn>
            </template>
          </v-snackbar>
         <!--  <v-btn
            v-if="userinfo"
            @click="userstatus"
            color="mydarkbutton"
            class="userinfonote"
          >
            Check info
          </v-btn> -->
          <div class="notification" v-if="tpl"> 
             
             <p>  Only Superadmin can add user to any TPL project. <br>
              Please contact TPL superadmin at <strong><a href="mailto:botuv@tataprojects.com">botuv@tataprojects.com</a></strong></p>

          </div> 
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
            v-if="!tpl"
          >
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="firstname"
                    :label="lang.FirstName"
                    required
                    :rules="firstnamerules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="lastname"
                    :label="lang.LastName"
                    :rules="firstnamerules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    :label="lang.Email"
                    required
                    :rules="emailrules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="constactNumber"
                    :label="lang.ContactNumber"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="e7"
                    :items="projects"
                    :label="lang.Projects"
                    item-text="project_name"
                    item-value="id"
                    multiple
                    chips
                    :hint='lang.selectproject'
                    persistent-hint
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-col cols="12" md="12">
                  <v-radio-group v-model="usertype" row>
                    <v-radio :label="lang.MakeNormalUser" value="1"></v-radio>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-progress-question
                        </v-icon>
                      </template>
                      <span
                        >{{lang.Privileges}}:- {{lang.AddUser}}, {{lang.ChangePassword}},<br />
                        {{lang.SeeExistingUsers}}</span
                      >
                    </v-tooltip>
                    <span class="pa-2"></span>
                    <v-radio :label="lang.MakeAdmin" value="0"></v-radio>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-progress-question
                        </v-icon>
                      </template>
                      <span
                        >{{lang.Privileges}} :- {{lang.AddUser}}, {{lang.ChangePassword}},<br />
                        {{lang.SeeExistingUsers}}, {{lang.EmailScheduler}},<br />
                        {{lang.CameraTag}}, {{lang.CameraInfo}},<br />
                        {{lang.CameraNotification}}, {{lang.CustomizeTimelapseVideo }}
                      </span>
                    </v-tooltip>
                  </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="12">
                    <lable> Two Factor Authentication </lable>
                    <v-radio-group v-model="tfa" row>
                      <v-radio label="ON" value="1"></v-radio>
                    <span class="pa-2"></span>
                       <v-radio label="OFF" value="0"></v-radio>
                  </v-radio-group>
                  </v-col>
                </v-col>
                <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="!valid"
                  color="mylightbutton"
                >
                  {{lang.submit}}
                </v-btn>
                <v-btn @click="clear" color="mydarkbutton"> {{lang.Cancel}} </v-btn>
              </v-row>
            </v-container>
          </v-form>
<v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{lang.Userinfo}}</span>
               
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                     >
                     <v-row>
                      <v-col cols="12" sm="6" md="6" class="username">
                            <p class="p_username">{{lang.Name}}: {{username}}</p>
                          </v-col>
                        <v-col cols="12" sm="6" md="6" class="username">
                            <p class="p_username">{{lang.CreatedDate}}: {{createdat}}</p>
                          </v-col>
                        <v-col cols="12" sm="6" md="6" class="username">
                            <p class="p_username">{{lang.Contact}}: {{contactno}}</p>
                          </v-col>
                        <v-col cols="12" sm="6" md="6" class="username">
                            <p class="p_username">{{lang.Status }}: {{ustatus}}</p>
                          </v-col>
                          </v-row>
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="4" md="4" class="heading">
                            <p class="p_username">{{lang.ProjectName}}</p>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="heading">
                            <p class="p_username">{{lang.Company}}</p>
                          </v-col>
                          <v-col cols="4" sm="4" md="4" class="heading">
                            <p class="p_username">{{lang.City}}</p>
                          </v-col>
                         </v-row> 
                          <v-row v-for="u in userproject"
                      :key="u.id">
                          <v-col cols="12" sm="4" md="4">
                            <p class="p_username">{{u.proj_name}}</p>
                          </v-col>
                          <v-col cols="4" sm="4" md="4">
                            <p class="p_username">{{u.company}}</p>
                          </v-col>
                          <v-col cols="4" sm="4" md="4">
                            <p class="p_username">{{u.city}}</p>
                          </v-col>
                          
                        </v-row>
                        <br />
                      </v-card>
                    </v-col>
                  
                   
                  </v-row>
                </v-container>
              </v-card-text>
               <v-col cols="12" sm="6">
                  <v-select
                    v-model="e77"
                    :items="projects"
                    :label="AddMorePorject"
                    item-text="project_name"
                    item-value="id"
                    multiple
                    chips
                    hint="Select more Projects for user"
                    persistent-hint
                  >
                  </v-select>
                </v-col>
              <v-card-actions>
                <v-btn
                  class="mr-4"
                  color="mylightbutton"
                  v-on:click="add_more_proj()"
                >
                  {{lang.submit}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  {{ lang.Close }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
       
        </v-card>
      </v-col>
      <v-col class="setting_sidebar" cols="3" md="3" out-line>
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar
  },
  name: "Adduser",
  data() {
    return {
      valid: true,
      firstname: "",
      lastname: "",
      firstnamerules: [(v) => !!v || this.lang.required],
      email: "",
      emailrules: [
        (v) => !!v || this.lang.EmailValid,
        (v) => /.+@.+\..+/.test(v) || this.lang.EmailValid,
      ],
      constactNumber: "",
      preloader: false,
      alert: false,
      alertmessage: "",
      alertType: "",
      e7: [],

      usertype: "1",
      projects: {},
      useraccess: false,
      file: null,
      userinfo: false,
      dialog: false,
      userproject:{},
     username: '',
     createdat: '',
     contactno: '',
     ustatus: '',
     manageuser : false,
     param_up:{},
     snackbar: false,
    text: "Something went wrong. Please reload and try again.",
    Local :locals,  
      lang : {},
      tataacces : false,
      tfa : "0",
      tpl : false
    };
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
         this.lang = locals[selectleng];

    this.getPorjects();

    this.userdata = JSON.parse(localStorage.getItem("userdata"));
 
     console.log("userdata" ,this.userdata);

     if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
    if (this.userdata.free_access == 0) {
      this.useraccess = true;
    } else {
         console.log("Redirect to dashboard");
      ///this.$router.push("/");
    }
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    this.initViewer();
  },
  watch: {
    "$store.state.currentCamera": function () {
      // console.log("Current data get",this.$store.state.currentCamera)
    },
  },
  computed: {
    params() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        contact: this.constactNumber,
        project: this.e7[0],
        project_ids: this.e7.toString(),
        user_code: this.usertype,
        cctvtimelapse_user: 0,
        currentProject: {},
        added_by: this.userdata.email,
        tfa : this.tfa
        
      };
    },
   
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    initViewer() {
      // console.log("init Viewer");
      var chenal_partner = window.location.hostname;
  
      
        if(chenal_partner == "tpl.opticvyu.com"){
          this.tpl = true;
            if(this.userdata.user_code == 2) {
              this.tpl = false;
            }else {
              this.tpl = true;
            } 
         }
    
     

      this.preloader = false;
    },
    async submit() {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      const valid = await this.$refs.form.validate();
       this.userdata = JSON.parse(localStorage.getItem("userdata"));
      if (valid) {

          this.params.cont_camera_aceess = 0;
          this.params.material_access = 0;
          this.params.interior_access = 0;

        if(this.userdata.cont_camera_aceess == 1){
          this.params.cont_camera_aceess = this.userdata.cont_camera_aceess;
       }else if(this.userdata.interior_access == 1){
           this.params.interior_access = this.userdata.interior_access  
        }else if(this.userdata.material_access == 1){
            this.params.material_access = this.userdata.material_access
        }
        console.log("use data", this.params);

        this.adduser(this.params); // action to login
      }
    },
    adduser(userdata) {
      this.preloader = true;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      //console.log("projectdata=>>>", this.currentProject);

      axios({ url: "/users", data: userdata, method: "POST" })
        .then((resp) => {
          // console.log("Resp", resp);
          if (resp.data.success) {
            this.alert = true;
              this.preloader = false;
            if(this.userdata.cont_camera_aceess == 1 && this.userdata.material_access == 1 && this.userdata.interior_access == 1){
                 
                   this.alertmessage =  this.lang.usermsgone //"Access given for construction camera. To manage access for Interior Monitoring & Material tracking, go to manage users";        
                     this.manageuser = true;
            }
           if(this.userdata.cont_camera_aceess == 0 && this.userdata.material_access == 1 && this.userdata.interior_access == 1){
                 
                   this.alertmessage =  this.lang.usermsgtwo // "Access given for Interior monitoring module. To manage access for Material tracking, go to manage users";
                    this.manageuser = true;
            }
            if(this.userdata.cont_camera_aceess == 0 && this.userdata.material_access == 1 && this.userdata.interior_access == 0){
                 
                    this.alertmessage =  this.lang.usermsgthree //"User added successfully. Login instructions have been emailed to the new user.";
                     this.manageuser = true;
            }
            if(this.userdata.cont_camera_aceess == 1 && this.userdata.material_access == 0 && this.userdata.interior_access == 0){
                 
                    this.alertmessage =  this.lang.usermsgthree//"User added successfully. Login instructions have been emailed to the new user.";
            }
            if(this.userdata.cont_camera_aceess == 0 && this.userdata.material_access == 0 && this.userdata.interior_access == 1){
                 
                    this.alertmessage =  this.lang.usermsgthree//"User added successfully. Login instructions have been emailed to the new user.";
            }
          
            this.alertType = "success";
           
            this.clear();
          } else {
            console.log("error msg", resp.data);
              this.alert = true;
           // this.alertmessage = this.lang.usererrorp1 +' ' +this.email +' '+this.lang.usererrorp2;
           this.alertmessage = resp.data.error;
            this.alertType = "error";
            this.preloader = false;
            if (resp.data.errorr == 1) {
              this.userinfo = true;
              console.log("userinfo", this.userinfo);
            }else{
              this.userinfo = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clear() {
      this.firstname = "";
      this.lastname = "";
      this.email = "";
      this.constactNumber = "";
      this.e7 = "";
      this.usertype = "";
    },
    manageusers(){
          this.$router.push('/dashboard/settings/manage-user')
    },
    userstatus() {
      this.dialog = true;
      var userparam = {
        emailadd: this.email,
      };
      axios({
        url:
          "/user/list/userprojects",
        params: userparam,
        method: "POST",
      }).then((resp) => {
        console.log("userinfo Resp", resp.data.userinfo);
        this.userproject = resp.data.success;
        this.username = resp.data.userinfo.firstname+' '+resp.data.userinfo.lastname;
        this.createdat = resp.data.userinfo.created_at;
        this.contactno = resp.data.userinfo.contactNo;
        if(resp.data.userinfo.active == '1'){
          this.ustatus = 'Active User';
        }else{
          this.ustatus = 'Inactive User';
        }
       
      })
        .catch((err) => {
          console.log(err);
        });
    },
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.projects = resp.data.success.filter(function (item) {
           return item.project_active == 1;
           });
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //    onChange(event) {
    //   this.file = event.target.files ? event.target.files[0] : null;
    //   console.log(this.file);
    // },
    add_more_proj() {
      this.preloader = true;
      this.param_up = {
        email: this.email,
        project: this.e77[0],
        project_ids: this.e77.toString(),
      };
      console.log("checking add more projects", this.param_up);
       axios({
        url:
          "/user/add_more_projects",
        params: this.param_up,
        method: "POST",
      }).then((resp) => {
        this.preloader = false;
          //console.log("result", resp.data.success);
          if (resp.data.success) {
            this.snackbar = true;
            this.text = resp.data.success;
           /*  setTimeout(() => {
              location.reload();
            }, 3000); */
          } else {
            this.snackbar = true;
            this.text = resp.data.error;
            /* setTimeout(() => {
              location.reload();
            }, 3000); */
          }
        }).catch((err) => {
          this.preloader = false;
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
         /*  setTimeout(() => {
            location.reload();
          }, 3000); */
        });
    },
  },
};
</script>
<style>
.userinfonote {
  position: relative;
  left: 85%;
  margin-top: -13%;
  font-size: 12px !important;
}
.heading {
  color: #252525;
  font-weight: 500;
  font-size: 16px;
}
.username {
  color: #f3bd08;
  font-weight: 500;
  font-size: 16px;
}
.notification {
    padding: 15px;
    margin: 15px;
    border: 2px solid #ff5454;
}
</style>


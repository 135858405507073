<template>
    <div>
      <Preloader v-if="preloader" />
      <v-row>
        <v-col cols="12" md="9">
          <v-card class="pa-5">
            <v-card-title> Unsubscribe Email Notification </v-card-title>
            <v-snackbar v-model="snackbar" center top timeout="3000" type="success">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                  {{ lang.Ok }}
                </v-btn>
              </template>
            </v-snackbar>
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
              <v-container>
                <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-checkbox
                v-model="autoEmailVal"
                label="Auto Email"
                color="amber"
                :true-value="1" :false-value="0"
              ></v-checkbox>
              <v-checkbox
                v-model="ppeEmailVal"
                label="PPE Email"
                color="amber"
                :true-value="1" :false-value="0"
              ></v-checkbox>
              <v-checkbox
                v-model="reportEmailVal"
                label="Report Email"
                color="amber"
                :true-value="1" :false-value="0"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-checkbox
                v-model="cameraNotificationEmailVal"
                label="Camera Notification Email"
                color="amber"
                :true-value="1" :false-value="0"
              ></v-checkbox>
              <v-checkbox
                v-model="interiorReportEmailVal"
                label="Interior Report Email"
                color="amber"
                :true-value="1" :false-value="0"
              ></v-checkbox>
            </v-col>
            <v-btn class="mr-4" type="submit" color="mylightbutton">
                  Save Changes
                </v-btn>
          </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
        <v-col class="setting_sidebar" cols="3" md="3" out-line>
          <Sidebar />
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  import axios from "axios";
  import Preloader from "../../../components/PreLoader";
  import Sidebar from "../../../components/SettingSidebar";
  import { locals } from "../../../utils/locals";
  export default {
    components: {
      Preloader,
      Sidebar
    },
    name: "Adduser",
    data() {
      return {
        valid: true,
        email: "",
        preloader: false,
        projects: {},
        dialog: false,
        param_up: {},
        snackbar: false,
        snackbarError: false,
        text: "",
        snackbarErrorText: "",
        Local: locals,
        lang: {},
        autoEmailVal:1,
        ppeEmailVal:1,
        reportEmailVal:1,
        cameraNotificationEmailVal:1,
        interiorReportEmailVal:1
      };
    },
    mounted() {
      var selectleng = this.$cookies.get("currentLanguage");
      this.lang = locals[selectleng];
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
        this.useraccess = true;
      } else {
        this.$router.push("/");
      }
      if (this.userdata.free_access == 0) {
        this.useraccess = true;
      } else {
        console.log("Redirect to dashboard");
      }
      this.getEmailSubscriptionInfo();
    },
    methods: {
          getEmailSubscriptionInfo() {
              axios({ url: "userEmailNotificationPreferences/"+ this.userdata.id, method: "GET" })
                  .then(resp => {
                    if(resp.data.success){
                    this.autoEmailVal = resp.data.data[0].auto_email;
                    this.ppeEmailVal = resp.data.data[0].ppe_email;
                    this.reportEmailVal = resp.data.data[0].report_email;
                    this.cameraNotificationEmailVal = resp.data.data[0].camera_notification_email;
                    this.interiorReportEmailVal = resp.data.data[0].interior_email;
                    }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
          submit() {
              this.preloader = true;
              axios({
                  url: "userEmailNotificationPreferences/"+ this.userdata.id,
                  params: {email:this.userdata.email, auto_email: this.autoEmailVal, ppe_email: this.ppeEmailVal, report_email: this.reportEmailVal, camera_notification_email: this.cameraNotificationEmailVal, interior_email: this.interiorReportEmailVal},
                  method: "POST"
              })
                  .then(resp => {
                      if (resp.data.success) {
                          this.preloader = false;
                      } else {
                          this.alertmessage = "Unable to update data";
                          this.alertType = "error";
                          this.preloader = false;
                      }
                  })
                  .catch(err => {
                      this.preloader = false;
                      console.log(err);
                  });
          },
    }
  };
  </script>
  
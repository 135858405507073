<template>
    <div>
      <Preloader v-if="preloader" />
  
      <v-snackbar v-model="alert" top right timeout="3000" type="success">
        {{ alertmessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="alert = false">
            {{ lang.Close }}
          </v-btn>
        </template>
      </v-snackbar>
      <div>
     
        <div class="pop" v-if="openImage">
          <!-- <v-btn icon color="#000" @click="openImage = false" class="oveicon clsoe">
              <v-icon>mdi-close</v-icon>
          </v-btn> -->
          <v-btn
            class="mx-1 dd oveicon clsoe"
            v-if="userdata.opticvyu_user == 1"
            fab
            dark
            x-small
            color="#000000"
            title="Save Annotation"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark color="#ffffff" @click="getAnnotationData">
              mdi-vector-square
            </v-icon>
          </v-btn>
          <v-zoomer
            :zoomingElastic="false"
            ref="zoomer"
            style="width: 100%; height: 99vh; top: -47px"
            pivot="image-center"
          >
            <img
              id="plan4"
              :src="openImage_url"
              @load="loaded"
              style="object-fit: contain; width: 100%; height: 100%"
            />
          </v-zoomer>
         
        </div>
        <div class="text-center paginations pjson">
      <!-- <v-pagination
        v-model="page"
        length="135"
        total-visible="10"
        @input="getFilterdata()"
      ></v-pagination> -->
    <!-- 1  <button class="arrowr" @click="prevPage" :disabled="currentPage === 1">  Previous </button> {{ currentPage }}
    <button class="arrowr" @click="nextPage" :disabled="currentPage === totalPages"> Next  </button> {{ totalPages }}({{ paginatedData.length }})
   -->
    </div>
     {{ heatImagesDatas.length }} Images 
        <carousel
          class="timelinec"
          :key="refresh"
          :responsive="{
            0: { items: 2, nav: false },
            600: { items: 3, nav: true },
            1000: { items: 8, nav: true, slideBy: 7 }
          }"
          :autoplay="false"
          :dots="false"
          :margin="8"
          :navText="['', '']"
          v-if="heatImagesDatas.length > 0"
        >
          <div
            v-for="(image, index) in heatImagesDatas"
            :key="index"
            @click="selectThumainal(index)"
          >
            <div
              class="`fixImageBox ppebox"
            >
              <img :src="image1" width="50px"/>
            </div>
            <p class="disply_date">{{ image.id }}</p>
          </div>
        </carousel>
       
        <v-snackbar
          v-model="imageloaded"
          @click="goDown"
          bottom
          right
          timeout="10000"
          type="success"
          max-width="100"
          width="150"
        >
          Timeline Loading.
          <template>
            <v-btn icon large color="#fff" @click="goDown" class="oveicon clsoe">
              <v-icon x-large>mdi-arrow-down-bold-circle</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
  
        <v-btn
          icon
          large
          color="#292929"
          @click="backtoTop"
          class="backtotop"
          title="Back To Top"
        >
          <v-icon large>mdi-arrow-up-bold-circle</v-icon>
        </v-btn>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import axios from "axios";
  import Preloader from "../../components/PreLoader";
  import { locals } from "../../utils/locals";
  import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
  import { Annotorious } from "@recogito/annotorious";
  import "@recogito/annotorious/dist/annotorious.min.css";
  import SelectorPack from "@recogito/annotorious-selector-pack";
  import ShapeLabelsFormatter from "@recogito/annotorious-shape-labels";
  import VueZoomer from "vue-zoomer";
  import carousel from "vue-owl-carousel";
  import image1 from "../../assets/ov.png";
  import jsondata from "../../assets/jsonminifier.json";
  //import lodash from 'lodash'
  Vue.use(DateRangePickerPlugin);
  Vue.use(VueZoomer);
  //var self = this
  export default {
    components: {
      Preloader,
      carousel
    },
    name: "Aiadminsedit",
    data() {
      return {
        jsonData : jsondata,
        page : 100,
        mode: true,
        showChart: true,
        currentcam: {},
        preloader: false,
        Local: locals,
        lang: {},
        image1 : image1,
        series: [
          {
            name: "Person",
            data: []
          },
          {
            name: "Vehicle",
            data: []
          }
        ],
        weatherseries: [
          {
            name: "clouds",
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          colors: ["#f8bb03", "#000000"],
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3, 3],
            curve: "smooth",
            dashArray: [0, 0]
          },
          title: {
            text: "",
            align: "left"
          },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return (
                val +
                " - " +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                ""
              );
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: []
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return Math.floor(val);
              }
            }
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function(val) {
                    return val;
                  }
                }
              },
              {
                title: {
                  formatter: function(val) {
                    return val;
                  }
                }
              },
              {
                title: {
                  formatter: function(val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: "#f1f1f1"
          }
        }, // chart option end
  
        weatherOptions: {
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3, 3, 3, 3],
            curve: "smooth",
            dashArray: [0, 0, 0, 0]
          },
          title: {
            text: "",
            align: "center"
          },
          legend: {
            tooltipHoverFormatter: function(val, opts) {
              return (
                val +
                " - " +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                ""
              );
            }
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          xaxis: {
            categories: []
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return Math.floor(val);
              }
            }
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function(val) {
                    return val;
                  }
                }
              }
            ]
          },
          grid: {
            borderColor: "#f1f1f1"
          }
        }, // weather chart option end
        personsArray: [],
        vehicleArray: [],
        selectoptions: [
          "Today",
          "This Week",
          "Last 30 Days",
          "Custom Date Range"
        ],
        selecteditem: "",
        items: [
          { title: "Person", text: "person" },
          { title: "All Construction Vehicles", text: "construction_vehicles" },
          { title: "Bus", text: "bus" },
          { title: "Car", text: "car" },
          { title: "Crane", text: "crane" },
          { title: "Dump Truck", text: "dump_truck" },
          { title: "Excavator", text: "excavator" },
          { title: "Loader", text: "loader" },
          { title: "Pile Driver", text: "pile_driver" },
          { title: "Rollers", text: "rollers" },
          { title: "Telehandler", text: "telehandler" },
          { title: "Helmet", text: "helmet" },
          { title: "Jacket", text: "jacket" },
          { title: "Belt", text: "belt" },
          { title: "Pile", text: "pile" },
          { title: "Pile Cap", text: "pile_cap" },
          { title: "Pier Cage", text: "pier_cage" },
          { title: "Pier", text: "pier" },
          { title: "pier_cap", text: "pier Cap" },
          { title: "Deck", text: "deck" }
  
        ],
        selectedVehicle: "",
        charData: {},
        diffDays: 0,
        max: "31",
        min: "1",
        heatseries: [
          {
            name: "00",
            data: []
          },
          {
            name: "01",
            data: []
          },
          {
            name: "02",
            data: []
          },
          {
            name: "03",
            data: []
          },
          {
            name: "04",
            data: []
          },
          {
            name: "05",
            data: []
          },
          {
            name: "06",
            data: []
          },
          {
            name: "07",
            data: []
          },
          {
            name: "08",
            data: []
          },
          {
            name: "09",
            data: []
          },
          {
            name: "10",
            data: []
          },
          {
            name: "11",
            data: []
          },
          {
            name: "12",
            data: []
          },
          {
            name: "13",
            data: []
          },
          {
            name: "14",
            data: []
          },
          {
            name: "15",
            data: []
          },
          {
            name: "16",
            data: []
          },
          {
            name: "17",
            data: []
          },
          {
            name: "18",
            data: []
          },
          {
            name: "19",
            data: []
          },
          {
            name: "20",
            data: []
          },
          {
            name: "21",
            data: []
          },
          {
            name: "22",
            data: []
          },
          {
            name: "23",
            data: []
          }
        ],
        heatchartOptions: {
          chart: {
            height: 350,
            type: "heatmap",
            toolbar: {
              show: false
            }
          },
  
          xaxis: {
            type: "category",
            categories: []
          },
          yaxis: {
            title: {
              text: "Hours"
            }
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 1,
              radius: 2,
              useFillColorAsStroke: false,
  
              colorScale: {
                ranges: [
                  {
                    from: 40,
                    to: 100,
                    name: "Extreme",
                    color: "#FA5733"
                  },
                  {
                    from: 15,
                    to: 40,
                    name: "High",
                    color: "#FA9533"
                  },
                  {
                    from: 5,
                    to: 15,
                    name: "Medium",
                    color: "#FACC33"
                  },
                  {
                    from: 0,
                    to: 5,
                    name: "Low",
                    color: "#F9FF95"
                  }
                ]
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1
          },
          title: {
            text: ""
          }
        },
        showlinechart: true,
        showHeatChart: false,
        heatchartTitle: "Person",
        openImage_url: "",
        openImage: false,
        anno: null,
        meta: {},
        activeperson: true,
        activeVehicle: {
          title: "All Construction Vehicles",
          text: "construction_vehicles"
        },
        heatImagesDatas: [],
        refresh: 1,
        alertmessage: "",
        alert: "",
        currentProject: "",
        watheritems: [
          { title: "Clouds (%)", text: "clouds" },
          { title: "Wind (meter/sec)", text: "wind" },
          { title: "Rain(mm)", text: "rain" },
          { title: "Snow(mm)", text: "snow" },
          { title: "Temperature (°C)", text: "temp" }
        ],
        wetherData: "",
        showDatepicker: false,
        activeweather: "clouds",
        chartdates: [],
        imageloaded: false,
        filtedates: [
          {
            dates: "2022-12-15"
          }
        ],
        userdata: "",
        filterdata : '',
        mpage : 0,
         itemsPerPage: 10,
         currentPage: 1,
         pageindex : 0
      };
    },
    mounted() {
     var ccv =  localStorage.getItem("cpagej");
     console.log("ccv",ccv);
      if(ccv !== null){
        this.page = parseInt(localStorage.getItem("cpagej"));
        
      }else{
        localStorage.setItem("cpagej",1);
        this.page = 1;
  
      }
     
      //this.getFilterdata();
      
      var selectleng = this.$cookies.get("currentLanguage");
      this.lang = locals[selectleng];
  
      this.currentcam = this.$store.state.currentCamera;
  
      document.body.addEventListener("keyup", e => {
        if (e.keyCode === 27) {
          this.dialog = false;
        }
      });
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      if (this.userdata.open_access == 1) {
        this.$router.push("/dashboard");
      }
    },
    created() {
      var ccv =  localStorage.getItem("cpage");
     console.log("ccv",ccv);
      if(ccv !== null){
        this.page = parseInt(localStorage.getItem("cpage"));
        
      }else{
        localStorage.setItem("cpage",1);
        this.page = 1;
  
      }
      this.getFilterdata();
     
  
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
  
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
    },
    watch: {
      "$store.state.currentCamera": function() {
        this.currentcam = this.$store.state.currentCamera;
      }
    },
    computed: {
      dateRangeText() {
        return this.dates.join(" ~ ");
      },
      params() {
        return {
          aidate: this.aidate,
          presetid: this.presetid ? this.presetid : 1,
          image_type: this.image_type
        };
      }
    },
    methods: {
    //   prevPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;

    //      console.log("page index", this.pageindex);
    //      var index = this.currentPage * this.pageindex;
    //     this.selectThumainal(index);
    //   }
    // },
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //     var index = this.currentPage * this.pageindex;
    //     console.log("page index", index);
    //     this.selectThumainal(index);
    //   }
    // },
      // getAnnotations() {
      //   this.checkAnnotaionImage();
      // },
      checkAnnotaionImage() {
       var img = document.getElementById("plan4");
       var mwidth = img.naturalWidth;
       var mheight = img.naturalHeight;
        var  value = "person";
        var vvdata = [];
  
        this.meta.forEach(item => {
         
          if (item.class_id == 0) {
            value = "car";
          } else if (item.class_id == 1) {
            value = "bus";
          } else if (item.class_id == 2) {
            value = "person";
          } else if (item.class_id == 3) {
            value = "excavator";
          } else if (item.class_id == 4) {
            value = "telehandler";
          } else if (item.class_id == 5) {
            value = "loader";
          } else if (item.class_id == 6) {
            value = "dump_truck";
          } else if (item.class_id == 7) {
            value = "concrete_mixer";
          } else if (item.class_id == 8) {
            value = "pile_diver";
          } else if (item.class_id == 9) {
            value = "crane";
          } else if (item.class_id == 10) {
            value = "rollers";
          }else if (item.class_id == 11) {
            value = "helmet";
          }else if (item.class_id == 12) {
            value = "jacket";
          }else if (item.class_id == 13) {
            value = "belt";
          }
          else  if(item.class_id == 14){
                  
                  value = 'pile';
               }
               else  if(item.class_id == 15){
                  
                  value = 'pile_cap';
               }
               else  if(item.class_id == 16){
                  
                  value = 'pier_cage';
               }
               else  if(item.class_id == 17){
                  
                  value = 'pier';
               }
               else  if(item.class_id == 18){
                  
                  value = 'pier_cap';
               }
               else  if(item.class_id == 19){
                  
                  value = 'deck';
               }
  
    
          // let x = item[0];
          // let y = item[1];
          // var w = item[2];
          // var h = item[3];
  
          //  console.log(item[0],item[1],item[2],item[3], mwidth,mheight);
  
          var w = item.box_width * mwidth;
           var h = item.box_height * mheight;

        //   console.log("x,y,h,w",x,y,h,w);

        var x = (2 * item.center_x * mwidth - w) / 2;
        var y = (2 * item.center_y * mheight - h) / 2;
  
          console.log("x,y,h,w",x,y,h,w);
  
          var r = (Math.random() + 1).toString(36).substring(7);
          vvdata.push({
            "@context": "http://www.w3.org/ns/anno.jsonld",
            type: "Annotation",
            body: [
              {
                type: "TextualBody",
                value: value,
                purpose: "tagging"
              }
            ],
            target: {
              source: this.openImage_url,
              selector: {
                type: "FragmentSelector",
                conformsTo: "http://www.w3.org/TR/media-frags/",
                value: "xywh=pixel:" + x + "," + y + "," + w + "," + h
              }
            },
            id: "#" + r
          });
        });
  
        this.anno = new Annotorious({
          image: document.getElementById("plan4"),
          formatter: ShapeLabelsFormatter(),
          drawOnSingleClick: false,
          widgets: [
            "COMMENT",
            {
              widget: "TAG",
              vocabulary: [
                "car",
                "bus",
                "person",
                "excavator",
                "telehandler",
                "loader",
                "dump_truck",
                "concrete_mixer",
                "pile_diver",
                "crane",
                "rollers",
                'helmet','jacket','belt','pile','pile_cap','pier_cage','pier','pier_cap','deck'
              ]
            }
          ]
        });
  
        //   var myJsonString = JSON.parse(vvdata);
        //  console.log("anno", myJsonString);
  
        SelectorPack(this.anno);
  
        console.log("vvdata", vvdata);
        var eded = this.anno.setAnnotations(vvdata);
        console.log("serwer", eded);
        //  setTimeout(() => {
        //      // var eded =  this.anno.loadAnnotations(vvdata);
  
        //
        //  }, 5000);
      },
  
      // getDates() {
      //   // console.log(key);
  
      //   axios({
      //     url: "/get/ai/all-annotation-date",
      //     method: "GET"
      //   })
      //     .then(resp => {
      //       console.log("dates", resp.data.success);
      //       this.filtedates = resp.data.success;
      //       // this.heatImagesDatas = resp.data.success;
  
      //       this.selecteditem = this.filtedates[0];
      //       setTimeout(() => {
      //         var dd = this.filtedates[0];
      //         this.selecteditem = dd.dates;
      //         console.log("this.selecteditem", this.selecteditem);
      //         this.getImages(this.filtedates[0].dates, 0);
      //       }, 1000);
      //       setTimeout(() => {
      //         this.selectThumainal(0);
      //         this.activeindex = 0;
      //       }, 2000);
      //       this.preloader = false;
      //     })
      //     .catch(err => {
      //       console.log(err);
      //       this.preloader = false;
      //     });
      // },
      getFilterdata() {
        console.log("page",this.page);
  
      //  localStorage.setItem("cpage",this.page);
    
        axios({
          url: "get/ai/image/externaljson",
          method: "GET"
        })
          .then(resp => {
             console.log("resp getFilterdata",resp.data.success);
             this.filterdata = resp.data.success;
             this.getImages();
          })
          .catch(err => {
            console.log(err);
            this.preloader = false;
          });
      },
      DirectPagejunp(){
  
       // this.mpage;
  
        console.log("dfjgjkdf",this.mpage);
         this.page = parseInt(this.mpage);
  
         this.getFilterdata();
  
      },
      getImages() {
        var  step = 0;
        this.heatImagesDatas = [];
      
           // console.log("Images", resp.data.success);
  
            // Example usage:
          
  
            const arr1 = this.jsonData
            const arr2 = this.filterdata;
  
  
            console.log("filterdatafilterdata ",arr2)
  
              function filterUniquePersons(arr1, arr2) {
              // Create a Set to store unique names from arr2
              const namesSet = new Set(arr2.map(obj => obj.image_s3url));
  
             
              console.log(namesSet);
  
              // Filter arr1 to include only objects with names not present in arr2
  
              
              return arr1.filter(obj => !namesSet.has(obj.images3url));
              }
           
               var _self = this;
             
                    const filteredArray1 = filterUniquePersons(arr1, arr2);
                    _self.heatImagesDatas = filteredArray1;
                
             
  
            if (step == 0) {
              _self.selectThumainal(0);
              _self.activeindex = 0;
            } else {
              _self.selectThumainal(_self.activeindex);
            }
  
            _self.openImage = false;
            _self.preloader = false;
       
        
      },
  
      selectDate(vv) {
        console.log(vv);
        this.selecteditem = vv;
        this.refresh++;
        this.getImages(vv, 0);
      },
  
      selectThumainal(index) {
        console.log(index);
        this.openImage = false;
        this.activeindex = index;
        this.preloader = true;
        console.log("fgf", index);

       var imagex = this.heatImagesDatas[index].images3url;

       var projectid = this.heatImagesDatas[index].projectid;
       

        axios({
        url: '/get_sign_url_by_key',
        //  url: '/ai/812/get-ai-images-record',
        method: 'GET',
        params: {
           "key" : imagex, //data.path,
            "exp" : "30 mins",
            "project_id" : projectid
        }
      })
        .then(resp => {
          console.log("resp", resp.data.success.url);

            this.dialog = true;
            this.openImage_url =  resp.data.success.url;
            this.openImage = true;
             this.meta = JSON.parse(this.heatImagesDatas[index].metadata);
          
        })
        .catch(err => {
          console.log(err);
          this.preloader = false
        })

      },
      loaded() {
        var v = this;
           
        setTimeout(function() {

         
          v.preloader = false;
          v.imageloaded = true;
          v.checkAnnotaionImage();
        }, 100);
      },
      goDown() {
        this.imageloaded = false;
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight
        );
      },
      backtoTop() {
        window.scrollTo(0, 0);
      },
  
      getAnnotationData() {
        this.preloader = true;
        var cc = this.anno.getAnnotations();
        console.log("getAnnotationData", cc);
  
        var data = [];
        var img = document.getElementById("plan4");
  
        // var mwidth = 380;
        // var mheight = 252;
  
        var mwidth = img.naturalWidth;
        var mheight = img.naturalHeight;
  
        console.log("mwidth", mwidth);
  
        console.log("mheight", mheight);
  
        var car = 0;
        var bus = 0;
        var person = 0;
        var excavator = 0;
        var telehandler = 0;
        var loader = 0;
        var dump_truck = 0;
        var concrete_mixer = 0;
        var pile_diver = 0;
        var crane = 0;
        var rollers = 0;
  
          var helmet = 0;
          var jacket = 0;
          var belt = 0;
  
          var pile = 0;
          var pile_cap = 0;
          var pier_cage = 0;
          var pier = 0;
          var pier_cap = 0;
          var deck  = 0;
  
        var sourceurl = "";
  
        cc.forEach((item, index) => {
          //"xywh=pixel:804.95849609375,721.810546875,502.2828369140625,558.8311767578125"
          // class_id center_x center_y bbox_width bbox_height
          // 5 0.408 0.30266666666666664 0.104 0.15733333333333333
  
          //console.log(" item.body[0].value",  item.body[0].value);
  
          if (index == 0) {
            var key = item.target.source;
  
            const keypart = key.split("?");
            var partOne = keypart[0];
            const subpart = partOne.split(".net/");
  
            console.log("subpart", subpart[1]);
  
            sourceurl = subpart[1];
          }
  
          var class_id = 0;
          if (item.body[0].value == "car") {
            class_id = 0;
            car = car + 1;
          } else if (item.body[0].value == "bus") {
            class_id = 1;
            bus = bus + 1;
          } else if (item.body[0].value == "person") {
            class_id = 2;
            person = person + 1;
          } else if (item.body[0].value == "excavator") {
            class_id = 3;
            excavator = excavator + 1;
          } else if (item.body[0].value == "telehandler") {
            class_id = 4;
            telehandler = telehandler + 1;
          } else if (item.body[0].value == "loader") {
            class_id = 5;
            loader = loader + 1;
          } else if (item.body[0].value == "dump_truck") {
            class_id = 6;
            dump_truck = dump_truck + 1;
          } else if (item.body[0].value == "concrete_mixer") {
            class_id = 7;
            concrete_mixer = concrete_mixer + 1;
          } else if (item.body[0].value == "pile_diver") {
            class_id = 8;
            pile_diver = pile_diver + 1;
          } else if (item.body[0].value == "crane") {
            class_id = 9;
            crane = crane + 1;
          } else if (item.body[0].value == "rollers") {
            class_id = 10;
            rollers = rollers + 1;
          }else  if(item.body[0].value == 'helmet'){
                  class_id = 11;
                  helmet = helmet+1;
                }
                else  if(item.body[0].value == 'jacket'){
                  class_id = 12;
                  jacket = jacket+1;
                }
                else  if(item.body[0].value == 'belt'){
                  class_id = 13;
                  belt = belt+1;
                } else  if(item.body[0].value == 'pile'){
                  class_id = 14;
                  pile = pile+1;
                }
  
                else  if(item.body[0].value == 'pile_cap'){
                  class_id = 15;
                  pile_cap = pile_cap+1;
                }
  
                else  if(item.body[0].value == 'pier_cage'){
                  class_id = 16;
                  pier_cage = pier_cage+1;
                }
                else  if(item.body[0].value == 'pier'){
                  class_id = 17;
                  pier = pier+1;
                }
  
                else  if(item.body[0].value == 'pier_cap'){
                  class_id = 18;
                  pier_cap = pier_cap+1;
                }
                else  if(item.body[0].value == 'deck'){
                  class_id = 19;
                  deck = deck+1;
                }
  
          let text = item.target.selector.value;
          const myArray = text.split(":");
          var cord = myArray[1];
          const m = cord.split(",");
  
          console.log("m", m);
  
          var cx = parseFloat(m[0]);
          var cy = parseFloat(m[1]);
          var cw = parseFloat(m[2]);
          var ch = parseFloat(m[3]);
  
          //    var cx = 232;
          //    var cy =  132.5;
          //    var cw =  264;
          //    var ch =  229;
  
          var x = (cx + cw / 2) / mwidth;
  
          var y = (cy + ch / 2) / mheight;
  
          var boxW = cw / mwidth;
  
          var boxH = ch / mheight;
  
          var ss = {
            class_id: class_id,
            center_x: x,
            center_y: y,
            box_width: boxW,
            box_height: boxH
          };
  
          data.push(ss);
        });
  
        console.log("car count", car);
        console.log(data);
  
        var addeby = this.userdata.firstname + " " + this.userdata.lastname;
        //var id = this.heatImagesDatas[this.activeindex].id;
        var savedata = {
          image_s3url: sourceurl,
          metadata: JSON.stringify(data),
          car: car,
          bus: bus,
          person: person,
          excavator: excavator,
          telehandler: telehandler,
          loader: loader,
          dump_truck: dump_truck,
          concrete_mixer: concrete_mixer,
          pile_diver: pile_diver,
          crane: crane,
          rollers: rollers,
          helmet : helmet,
          jacket : jacket,
          belt : belt,
          image_height: mheight,
          image_width: mwidth,
          addedby: addeby,
          pile : pile,
          pile_cap: pile_cap,
          pier_cage : pier_cage,
          pier : pier,
          pier_cap:pier_cap,
          deck: deck,
        };
  
        axios({
          url: "add/ai/add-ppe-annotation",
          method: "post",
          data: savedata
        })
          .then(resp => {
            console.log(resp);
  
            this.activeanotate = !this.activeanotate;
            console.log(" this.activeanotate", this.activeanotate);
            this.anno.clearAnnotations();
            this.alert = true;
            this.alertmessage = "Annotation saved successfully.";
            this.anno.destroy();
            this.preloader = false;
  
            this.getFilterdata();
          })
          .catch(err => {
            this.preloader = false;
            console.log(err);
            //this.snackbar = true;
            //this.$router.go()
          });
  
        //console.log("savedata", savedata);
      }
    } // end of mathos
  };
  </script>
  
  <style>
  @import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
  @import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
  
  h2.hetitlec {
    background: #000 !important;
    color: #fff;
    padding: 0px 15px;
    text-align: center;
    font-size: 17px;
  }
  h2.hetitlec.active {
    background: #f8bb03 !important;
    color: #000;
    box-shadow: -2px 7px 16px 0px #0000004a;
  }
  #chartbar {
    cursor: pointer;
  }
  
  /* this new ai page style */
  
  .fiterdata {
    background: #000;
    color: #fff;
    cursor: pointer;
  }
  h2.hetitlec.fiterdata {
    background: #000;
  }
  .hetitlec {
    cursor: pointer;
  }
  .pop {
    width: 100%;
    height: auto;
    min-height: 600px;
    background: #fff;
    z-index: 2;
    position: relative;
  }
  .car .a9s-inner {
    stroke-width: 1;
    stroke: #ff5233;
  }
  .bus .a9s-inner {
    stroke-width: 1;
    stroke: #ffe333;
  }
  .person .a9s-inner {
    stroke-width: 1;
    stroke: #71ff33;
  }
  .excavator .a9s-inner {
    stroke-width: 1;
    stroke: #33ffd1;
  }
  .telehandler .a9s-inner {
    stroke-width: 1;
    stroke: #3361ff;
  }
  .loader .a9s-inner {
    stroke-width: 1;
    stroke: #9933ff;
  }
  .dump_truck .a9s-inner {
    stroke-width: 1;
    stroke: #ff33ce;
  }
  .concrete-mixer .a9s-inner {
    stroke-width: 1;
    stroke: #5e213d;
  }
  .pile_diver .a9s-inner {
    stroke-width: 1;
    stroke: #d9711a;
  }
  .crane .a9s-inner {
    stroke-width: 1;
    stroke: #3a6c4a;
  }
  .rollers .a9s-inner {
    stroke-width: 1;
    stroke: #085400;
  }
  
  .helmet .a9s-inner {
    stroke-width: 1;
    stroke: #242b45;
  }
  
  .belt .a9s-inner {
    stroke-width: 1;
    stroke: #452442;
  }
  
  .jacket .a9s-inner {
    stroke-width: 1;
    stroke: #452442;
  }
  
  .a9s-shape-label {
    opacity: 0;
    transition: 0.5s;
  }
  .a9s-annotation:hover .a9s-shape-label {
    opacity: 1;
    transition: 0.5s;
  }
  .v-input--selection-controls.ckeck {
    margin-top: 0px;
  }
  p.weathernote {
    position: relative;
    top: 0px;
    right: 0px;
    text-align: center;
  }
  .helps {
    position: absolute !important;
    right: 6px;
    top: 20px;
    z-index: 5;
    cursor: pointer;
  }
  .helpinfo p {
    position: absolute;
    right: 32px;
    width: 350px;
    background: #fff;
    top: 36px;
    z-index: 2;
    display: none;
    box-shadow: 0 0 16px 6px #ccc;
    padding: 25px;
    transition: 0.5s;
  }
  .helpinfo:hover p {
    display: block;
    transition: 0.5s;
  }
  .timelineclose {
    position: absolute;
    left: -2px;
    bottom: 118px !important;
    z-index: 50;
  }
  .backtotop {
    position: absolute;
    right: 36px;
    bottom: 10px !important;
    cursor: pointer;
    background: #fff;
    z-index: 999;
  }
  html {
    scroll-behavior: smooth;
  }
  .fixImageBox.acttiveitem {
    border: 7px solid #f8bb03;
  }
  .dateSelecter {
    position: absolute;
    top: 17px;
    left: 0px;
    z-index: 3;
    width: 120px;
    background: #ffffff;
  }
  .ppebox {
      border: 1px solid #f7f5f1;
  }
  .ppebox img {
    width :50px !important;
     margin :auto;
  }
  .fixImageBox.ppebox {
      vertical-align: text-bottom;
      padding-top: 19px;
  }
  .pjson {
    background: #ccc;
}
button.arrowr {
    background: #000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
}
  </style>
  